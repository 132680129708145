import React from "react";

import {EkoCard, EkoCardBody} from "../../../../../_metronic/helpers";
import {EkoTable, TableHeader} from "../../../../shared/components/table";
import Loading from "../../../../shared/components/Loading";
import {EkoCardHeader, EkoCardToolbar} from "../../../../shared/components/card";
import {useProductionOperations} from "../hooks/use-list-production-operations";


const ProductionOperationList: React.FC = () => {
    const {
        isInitialLoading,
        data: list,
        isError
    } = useProductionOperations()

    if (isInitialLoading) {
        return <Loading />;
    }

    if (isError) {
        return <>ERROR!</>;
    }

    return <>
        <EkoCard>
            <EkoCardHeader title={'Productie handelingen'}>
                <EkoCardToolbar>
                </EkoCardToolbar>
            </EkoCardHeader>
            <EkoCardBody>
                <EkoTable>
                    <TableHeader>
                        <th>Code</th>
                        <th>Naam</th>
                        <th>Gemiddelde tijdsduur</th>
                    </TableHeader>
                    <tbody>
                    {(!list || list.length === 0) &&
                        <tr>
                            <td colSpan={5} className="text-center">Geen gegevens</td>
                        </tr>}
                    {list && list.map((data) => <tr key={data.id}>
                        <td className="fit-content">
                           {data.code}
                        </td>
                        <td>
                            {data?.title}
                        </td>
                        <td className="fit-content">
                            {data.averageDuration} minuten
                        </td>
                        <td className="fit-content">
                        </td>
                    </tr>)}
                    </tbody>
                </EkoTable>
            </EkoCardBody>
        </EkoCard>
    </>
}

export default ProductionOperationList;
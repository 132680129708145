import React, {useContext, useId} from 'react';
import {
    CompartmentLayoutTypeCategory,
    ConfiguratorCompartmentLayoutHefSchuif,
    ConfiguratorCompartmentProfiles, ConfiguratorNeutPosition,
    HefSchuifInnerDoorPosition,
    HefSchuifSchemaCode,
    HefSchuifSlidingDirection,
    RabbetPosition,
    SillConfigurationCompartment,
    SillConfigurationHefSchuifLayoutSchemaA,
    SillConfigurationHefSchuifLayoutSchemaC,
    SillConfigurationHefSchuifLayoutSchemaD,
    SillConfigurationHefSchuifLayoutSchemaE,
} from '../../../modules/api-client/generated';
import {useFormikContext} from "formik";
import {
    CompartmentProfileEditor,
    CompartmentProfileEditorMode
} from "./CompartmentProfileEditor";
import {CompartmentEditorContext, lrToInsideOutside, lsRsToInsideOutside} from "./CompartmentEditorContextProvider";
import {HefSchuifFixedDoorBarSegmentRow} from "./HefSchuifFixedDoorBarSegmentRow";
import {HefSchuifSlidingRailRow} from "./HefSchuifSlidingRailRow";
import {HefSchuifSlidingRailTypeRow} from "./HefSchuifSlidingRailTypeRow";
import {HefSchuifSlidingDoorBarFieldRow} from "./HefSchuifSlidingDoorBarFieldRow";
import {HefSchuifFixedDoorBarRaisedRimRow} from "./HefSchuifFixedDoorBarRaisedRimRow";


interface HefSchuifCompartmentEditorFieldsProps {
    sillId: string;
    neutPosition: ConfiguratorNeutPosition;
    profileWidth: number;
    compartmentLayout: ConfiguratorCompartmentLayoutHefSchuif;
    profiles: ConfiguratorCompartmentProfiles | null;
}

export const HefSchuifCompartmentEditorFields: React.FC<HefSchuifCompartmentEditorFieldsProps> = (props) => {

        const schemaCode = props.compartmentLayout.schemaCode;

        return <div className="d-grid gap-4">

            {schemaCode === HefSchuifSchemaCode.A && <HefSchuifEditorFieldsSchemaA sillId={props.sillId}
                                                                                   neutPositionId={props.neutPosition.id}
                                                                                   profileWidth={props.profileWidth}
                                                                                   profiles={props.profiles!}
            />}

            {schemaCode === HefSchuifSchemaCode.C && <HefSchuifEditorFieldsSchemaC sillId={props.sillId}
                                                                                   neutPositionId={props.neutPosition.id}
                                                                                   profileWidth={props.profileWidth}
                                                                                   profiles={props.profiles!}
            />}
            {schemaCode === HefSchuifSchemaCode.D && <HefSchuifEditorFieldsSchemaD sillId={props.sillId}
                                                                                   neutPositionId={props.neutPosition.id}
                                                                                   profileWidth={props.profileWidth}
                                                                                   profiles={props.profiles!}
            />}
            {schemaCode === HefSchuifSchemaCode.E && <HefSchuifEditorFieldsSchemaE sillId={props.sillId}
                                                                                   neutPositionId={props.neutPosition.id}
                                                                                   profileWidth={props.profileWidth}
                                                                                   profiles={props.profiles!}
            />}
        </div>
    }
;


interface HefSchuifEditorFieldsProps {
    sillId: string;
    neutPositionId: string;
    profileWidth: number;
    // schemaSelect: React.ReactNode;
    profiles: ConfiguratorCompartmentProfiles;
}

type HefSchuifEditorFieldsSchemaA = HefSchuifEditorFieldsProps & {
    // defaults: SillConfigurationHefSchuifDefaultSchemaA
}

type HefSchuifEditorFieldsSchemaC = HefSchuifEditorFieldsProps & {
    // defaults: SillConfigurationHefSchuifDefaultSchemaC
}

type HefSchuifEditorFieldsSchemaD = HefSchuifEditorFieldsProps & {
    // defaults: SillConfigurationHefSchuifDefaultSchemaD
}

type HefSchuifEditorFieldsSchemaE = HefSchuifEditorFieldsProps & {
    // defaults: SillConfigurationHefSchuifDefaultSchemaE
}

const HefSchuifEditorFieldsSchemaA: React.FC<HefSchuifEditorFieldsSchemaA> = (props) => {

    const id = useId();

    const {values, setValues} = useFormikContext<SillConfigurationCompartment>();

    const {
        profiles,
        setProfiles,
        flipProfiles,
        makeFavoriteProfile,
        removeFavoriteProfile
    } = useContext(CompartmentEditorContext);

    const hefSchuifLayoutA = values.hefSchuifLayout! as SillConfigurationHefSchuifLayoutSchemaA;

    const onProfilesChange = async (profiles: ConfiguratorCompartmentProfiles) => {

        setProfiles(profiles);

        await setValues(current => {

            const {
                inside,
                outside
            } = lsRsToInsideOutside(hefSchuifLayoutA.slidingDirection, profiles.left, profiles.right);

            const hefSchuifLayout = {
                ...current.hefSchuifLayout! as SillConfigurationHefSchuifLayoutSchemaA,
                profileIdInside: inside.profileInfo.id,
                profileIdOutside: outside.profileInfo.id
            };

            return {...current, hefSchuifLayout: hefSchuifLayout}
        });
    };

    const changeSlidingDirection = async (slidingDirection: HefSchuifSlidingDirection) => {

        await setValues(current => {

            const hefSchuifLayout = current.hefSchuifLayout! as SillConfigurationHefSchuifLayoutSchemaA;

            if (slidingDirection !== hefSchuifLayout.slidingDirection) {
                flipProfiles();
            }

            return {
                ...current,
                hefSchuifLayout: {
                    ...hefSchuifLayout,
                    slidingDirection: slidingDirection
                }
            }
        });
    }

    const rabbetPosition = hefSchuifLayoutA.slidingDirection === HefSchuifSlidingDirection.LeftSliding
        ? {left: RabbetPosition.Inside, right: RabbetPosition.Outside}
        : {left: RabbetPosition.Outside, right: RabbetPosition.Inside};


    const profileInfos = [];
    if (profiles?.left) profileInfos.push(profiles.left.profileInfo);
    if (profiles?.right) profileInfos.push(profiles.right.profileInfo);

    const inner = profileInfos.find(x => x.id == hefSchuifLayoutA.profileIdInside);
    const outer = profileInfos.find(x => x.id == hefSchuifLayoutA.profileIdOutside);

    const promptPrimaryRailCenter = inner && !inner.rabbet;
    const promptDoorBarRaisedRimCenter = outer && !outer.rabbet;
    const promptDoorBarRaisedRimWidth = outer && !outer.rabbet;

    return <>

        <div className="row">
            <div className="col-6">
                <label htmlFor={`${id}_hefSchuifLayout_slidingDirection`} className='form-label'>
                    <small className='text-uppercase text-muted'>Schuifrichting</small>
                </label>
                <select className="form-select" id={`${id}_hefSchuifLayout_slidingDirection`}
                        value={hefSchuifLayoutA.slidingDirection}
                        onChange={async (e) => await changeSlidingDirection(e.target.value as HefSchuifSlidingDirection)}>
                    <option value={HefSchuifSlidingDirection.LeftSliding}>Links schuivend</option>
                    <option value={HefSchuifSlidingDirection.RightSliding}>Rechts schuivend</option>
                </select>
            </div>
        </div>

        <CompartmentProfileEditor sillId={props.sillId}
                                  neutPositionId={props.neutPositionId}
                                  compartmentLayoutTypeCodeCategory={CompartmentLayoutTypeCategory.HefSchuif}
                                  compartmentLayoutTypeCode={values.compartmentLayoutTypeCode}
                                  profileWidth={props.profileWidth}
                                  profiles={props.profiles}
                                  rabbetPosition={rabbetPosition}
                                  onChange={onProfilesChange}
        />

        <HefSchuifSlidingRailTypeRow fieldName={'hefSchuifLayout.railType'}/>

        {promptPrimaryRailCenter &&
            values.hefSchuifLayout?.railType && <HefSchuifSlidingRailRow
                fieldName={'hefSchuifLayout.slidingRail'}
                label="Looprail"
                promptCenter={promptPrimaryRailCenter}
            />
        }
        <HefSchuifFixedDoorBarSegmentRow fieldName={'hefSchuifLayout.fixedDoorBar'}/>

        <HefSchuifFixedDoorBarRaisedRimRow fieldName={'hefSchuifLayout.fixedDoorBar.raisedRim'}
                                           promptCenter={promptDoorBarRaisedRimCenter}
                                           promptWidth={promptDoorBarRaisedRimWidth}/>
    </>
}

const HefSchuifEditorFieldsSchemaC: React.FC<HefSchuifEditorFieldsSchemaC> = (props) => {

    const {values, setValues} = useFormikContext<SillConfigurationCompartment>();

    const {profiles, setProfiles, makeFavoriteProfile, removeFavoriteProfile} = useContext(CompartmentEditorContext);

    const hefSchuifLayoutC = values.hefSchuifLayout! as SillConfigurationHefSchuifLayoutSchemaC;

    const onProfilesChange = async (profiles: ConfiguratorCompartmentProfiles) => {

        setProfiles(profiles);

        await setValues(current => {
            const hefSchuifLayout = {
                ...current.hefSchuifLayout! as SillConfigurationHefSchuifLayoutSchemaC,
                profileIdOutside: profiles.right.profileInfo.id
            };

            return {...current, hefSchuifLayout: hefSchuifLayout}
        });
    };

    const profileInfos = [];
    if (profiles?.left) profileInfos.push(profiles.left.profileInfo);
    if (profiles?.right) profileInfos.push(profiles.right.profileInfo);

    const outer = profileInfos.find(x => x.id == hefSchuifLayoutC.profileIdOutside);

    const promptDoorBarRaisedRimCenter = outer && !outer.rabbet;
    const promptDoorBarRaisedRimWidth = outer && !outer.rabbet;


    return <>

        <CompartmentProfileEditor sillId={props.sillId}
                                  neutPositionId={props.neutPositionId}
                                  compartmentLayoutTypeCodeCategory={CompartmentLayoutTypeCategory.HefSchuif}
                                  compartmentLayoutTypeCode={values.compartmentLayoutTypeCode}
                                  profileWidth={props.profileWidth}
                                  mode={CompartmentProfileEditorMode.Symmetrical}
                                  profiles={props.profiles}
                                  rabbetPosition={{
                                      left: RabbetPosition.Outside,
                                      right: RabbetPosition.Outside
                                  }}
                                  onChange={onProfilesChange}
        />

        <HefSchuifSlidingRailTypeRow fieldName={'hefSchuifLayout.railType'}/>

        {values.hefSchuifLayout?.railType && <HefSchuifSlidingRailRow
            fieldName={'hefSchuifLayout.slidingRail'}
            label="Looprail"
            promptCenter={true}/>
        }
        <HefSchuifFixedDoorBarSegmentRow fieldName={'hefSchuifLayout.fixedDoorBar.left'} label="Lat vastedeur links"/>

        <HefSchuifFixedDoorBarSegmentRow fieldName={'hefSchuifLayout.fixedDoorBar.right'} label="Lat vastedeur rechts"/>

        <HefSchuifFixedDoorBarRaisedRimRow fieldName={'hefSchuifLayout.fixedDoorBar.raisedRim'}
                                           promptCenter={promptDoorBarRaisedRimCenter}
                                           promptWidth={promptDoorBarRaisedRimWidth}/>
    </>
}

const HefSchuifEditorFieldsSchemaD: React.FC<HefSchuifEditorFieldsSchemaD> = (props) => {
    const id = useId();

    const {values, setValues} = useFormikContext<SillConfigurationCompartment>();

    const {
        profiles,
        setProfiles,
        flipProfiles,
    } = useContext(CompartmentEditorContext);

    const hefSchuifLayoutD = values.hefSchuifLayout! as SillConfigurationHefSchuifLayoutSchemaD;

    const changeInnerDoorPosition = async (innerDoorPosition: HefSchuifInnerDoorPosition) => {

        await setValues(current => {

            const hefSchuifLayout = current.hefSchuifLayout! as SillConfigurationHefSchuifLayoutSchemaD;

            if (innerDoorPosition !== hefSchuifLayout.innerDoorPosition) {
                flipProfiles();
            }

            return {
                ...current,
                hefSchuifLayout: {
                    ...hefSchuifLayout,
                    innerDoorPosition: innerDoorPosition
                }
            }
        });
    }

    const rabbetPosition = hefSchuifLayoutD.innerDoorPosition === HefSchuifInnerDoorPosition.Left
        ? {left: RabbetPosition.Inside, right: RabbetPosition.Outside}
        : {left: RabbetPosition.Outside, right: RabbetPosition.Inside};

    const profileInfos = [];
    if (profiles?.left) profileInfos.push(profiles.left.profileInfo);
    if (profiles?.right) profileInfos.push(profiles.right.profileInfo);


    const inner = profileInfos.find(x => x.id == hefSchuifLayoutD.profileIdInside);
    const outer = profileInfos.find(x => x.id == hefSchuifLayoutD.profileIdOutside);

    const promptPrimaryRailCenter = inner && !inner.rabbet;
    const promptSecondaryRailCenter = outer && !outer.rabbet;


    const onProfilesChange = async (profiles: ConfiguratorCompartmentProfiles) => {

        setProfiles(profiles);

        await setValues((current: SillConfigurationCompartment) => {

            const {
                inside,
                outside
            } = lrToInsideOutside(hefSchuifLayoutD.innerDoorPosition, profiles.left, profiles.right);

            const hefSchuifLayout: SillConfigurationHefSchuifLayoutSchemaD = {
                ...current.hefSchuifLayout! as SillConfigurationHefSchuifLayoutSchemaD,
                profileIdInside: inside.profileInfo.id,
                profileIdOutside: outside.profileInfo.id,
            };
            return {...current, hefSchuifLayout: hefSchuifLayout}
        });
    };

    return <>

        <div className="row">
            <div className="col-6">
                <label htmlFor={`${id}_hefSchuifLayout_innerDoorPosition`} className='form-label'>
                    <small className='text-uppercase text-muted'>Positie binnendeur</small>
                </label>
                <select className="form-select" id={`${id}_hefSchuifLayout_innerDoorPosition`}
                        value={hefSchuifLayoutD.innerDoorPosition}
                        onChange={async (e) => await changeInnerDoorPosition(e.target.value as HefSchuifInnerDoorPosition)}>
                    <option value={HefSchuifInnerDoorPosition.Left}>Links</option>
                    <option value={HefSchuifInnerDoorPosition.Right}>Rechts</option>
                </select>
            </div>
        </div>

        <CompartmentProfileEditor sillId={props.sillId}
                                  neutPositionId={props.neutPositionId}
                                  compartmentLayoutTypeCodeCategory={CompartmentLayoutTypeCategory.HefSchuif}
                                  compartmentLayoutTypeCode={values.compartmentLayoutTypeCode}
                                  profileWidth={props.profileWidth}
                                  profiles={props.profiles}
                                  rabbetPosition={rabbetPosition}
                                  onChange={onProfilesChange}
        />
        <HefSchuifSlidingRailTypeRow fieldName={'hefSchuifLayout.railType'}/>


        {promptPrimaryRailCenter &&
            values.hefSchuifLayout?.railType && <HefSchuifSlidingRailRow
                fieldName={'hefSchuifLayout.slidingRail'}
                label="Looprail #1"
                promptCenter={promptPrimaryRailCenter}
            />
        }

        {promptSecondaryRailCenter &&
            <HefSchuifSlidingRailRow
                fieldName={'hefSchuifLayout.secondarySlidingRail'}
                label="Looprail #2"
                promptCenter={promptSecondaryRailCenter}
            />
        }
    </>
}

const HefSchuifEditorFieldsSchemaE: React.FC<HefSchuifEditorFieldsSchemaE> = (props) => {

    const id = useId();

    const {values, setValues} = useFormikContext<SillConfigurationCompartment>();

    const {
        profiles,
        setProfiles,
        flipProfiles,
        makeFavoriteProfile,
        removeFavoriteProfile
    } = useContext(CompartmentEditorContext);

    const hefSchuifLayoutE = values.hefSchuifLayout! as SillConfigurationHefSchuifLayoutSchemaE;

    const profileInfos = [];
    if (profiles?.left) profileInfos.push(profiles.left.profileInfo);
    if (profiles?.right) profileInfos.push(profiles.right.profileInfo);

    const inner = profileInfos.find(x => x.id == hefSchuifLayoutE.profileIdInside);
    const outer = profileInfos.find(x => x.id == hefSchuifLayoutE.profileIdOutside);

    const promptPrimaryRailCenter = !!(inner && !inner.rabbet && hefSchuifLayoutE.railType);
    const promptDoorBarRaisedRimCenter = outer && !outer.rabbet;
    const promptDoorBarRaisedRimWidth = outer && !outer.rabbet;

    const onProfilesChange = async (profiles: ConfiguratorCompartmentProfiles) => {

        setProfiles(profiles);

        await setValues(current => {

            const {
                inside,
                outside
            } = lsRsToInsideOutside(hefSchuifLayoutE.slidingDirection, profiles.left, profiles.right);

            const hefSchuifLayout = {
                ...current.hefSchuifLayout! as SillConfigurationHefSchuifLayoutSchemaE,
                profileIdInside: inside.profileInfo.id,
                profileIdOutside: outside.profileInfo.id
            };

            return {...current, hefSchuifLayout: hefSchuifLayout}
        });
    };

    const changeSlidingDirection = async (slidingDirection: HefSchuifSlidingDirection) => {

        await setValues(current => {

            const hefSchuifLayout = current.hefSchuifLayout! as SillConfigurationHefSchuifLayoutSchemaA;

            if (slidingDirection !== hefSchuifLayout.slidingDirection) {
                flipProfiles();
            }

            return {
                ...current,
                hefSchuifLayout: {
                    ...hefSchuifLayout,
                    slidingDirection: slidingDirection
                }
            }
        });
    }

    const rabbetPosition = hefSchuifLayoutE.slidingDirection === HefSchuifSlidingDirection.LeftSliding
        ? {left: RabbetPosition.Inside, right: RabbetPosition.Outside}
        : {left: RabbetPosition.Outside, right: RabbetPosition.Inside};


    // const promptSecondarySlidingRailLength = !!hefSchuifLayoutE.railType;
    const promptSecondarySlidingRailLength = false;

    return <>

        <div className="row">
            <div className="col-6">
                <label htmlFor={`${id}_hefSchuifLayout_slidingDirection`} className='form-label'>
                    <small className='text-uppercase text-muted'>Schuifrichting</small>
                </label>
                <select className="form-select" id={`${id}_hefSchuifLayout_slidingDirection`}
                        value={hefSchuifLayoutE.slidingDirection}
                        onChange={async (e) => await changeSlidingDirection(e.target.value as HefSchuifSlidingDirection)}>
                    <option value={HefSchuifSlidingDirection.LeftSliding}>Links schuivend</option>
                    <option value={HefSchuifSlidingDirection.RightSliding}>Rechts schuivend</option>
                </select>
            </div>
        </div>

        <CompartmentProfileEditor sillId={props.sillId}
                                  neutPositionId={props.neutPositionId}
                                  profileWidth={props.profileWidth}
                                  compartmentLayoutTypeCodeCategory={CompartmentLayoutTypeCategory.HefSchuif}
                                  compartmentLayoutTypeCode={values.compartmentLayoutTypeCode}
                                  profiles={props.profiles}
                                  rabbetPosition={rabbetPosition}
                                  onChange={onProfilesChange}
        />

        <HefSchuifSlidingRailTypeRow fieldName={'hefSchuifLayout.railType'}/>


        {promptPrimaryRailCenter &&
            <HefSchuifSlidingRailRow
                fieldName={'hefSchuifLayout.slidingRail'}
                label="Looprail #1"
                promptCenter={promptPrimaryRailCenter}/>
        }

        <HefSchuifSlidingRailRow
            fieldName={'hefSchuifLayout.secondarySlidingRail'}
            label="Looprail #2"
            promptLength={promptSecondarySlidingRailLength}
            promptCenter={true}/>


        <HefSchuifSlidingDoorBarFieldRow fieldName={'hefSchuifLayout.slidingDoorBar'}
                                         label="Lat schuifdeur #2"
                                         promptLength={true}/>

        <HefSchuifFixedDoorBarSegmentRow fieldName={'hefSchuifLayout.fixedDoorBar'}/>

        <HefSchuifFixedDoorBarRaisedRimRow fieldName={'hefSchuifLayout.fixedDoorBar.raisedRim'}
                                           promptCenter={promptDoorBarRaisedRimCenter}
                                           promptWidth={promptDoorBarRaisedRimWidth}/>
    </>
}
import React, {FC, useEffect, useState} from "react";
import {
    PutProductionItemStatusRepresentation
} from "../../../../modules/api-client/generated";
import {usePutProductionItemFinished} from "../hooks/use-put-production-item-finished";
import {useNavigate, useParams} from "react-router-dom";
import PmRoutes, {PmRoutePath} from "../PmRoutes";
import Loading from "../../../../shared/components/Loading";

const Afmelden:FC = () => {

    const {id} = useParams<{ id: string }>()
    const navigate = useNavigate();
    // data mutations
    const { mutateAsync: mutateDraft } = usePutProductionItemFinished();
    useEffect(() => {
        async function draftOrder() {
            const command: PutProductionItemStatusRepresentation = {
                            productionItemIds: [id!],
                        }
            const draftOrder = await mutateDraft(command);

            navigate(PmRoutePath.link(PmRoutePath.afmeldenlanding));
        }
        draftOrder();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

return (<Loading message={'Afmelden...'}></Loading>);
}

export default Afmelden

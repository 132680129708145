import { useQueryClient} from "@tanstack/react-query";
import {toast} from "react-hot-toast"
import ApiClient from "../../../../../modules/api-client/ApiClient";
import {useEkosietMutation} from "../../../../../shared/hooks/useEkosietMutation";
import {NotFound, ValidationFailed} from "../../../../../modules/api-client/Responses";
import {AxiosResponse} from "axios";


export const useDeleteOrder = () => {
        const queryClient = useQueryClient()
        return useEkosietMutation<void, NotFound | ValidationFailed,string>
        ((orderId: string) => {

                return ApiClient.Sm.Order.smOrderIdDelete(orderId, undefined, undefined).then((response: AxiosResponse<void>) => response.data);
            },
            {
                onSuccess: (orderId) => {
                    toast.success("Order gearchiveerd.");
                    queryClient.invalidateQueries(['smOrderIdGet', orderId]);
                    queryClient.invalidateQueries(['OrderActionLog', orderId]).then();

                },
                onError: () => {
                    toast.error("Order archivering mislukt!");
                }
            }
        );
    }
;

import React from 'react';
import {useQuery} from '@tanstack/react-query';
import ApiClient from '../../../../modules/api-client/ApiClient';
import {useParams} from 'react-router-dom';
import {NeutDetails} from '../../../../modules/api-client/generated';
import {EkoCard, EkoCardBody} from '../../../../../_metronic/helpers';

import Loading from '../../../../shared/components/Loading';
import ConfiguredNeutSvg from '../../../../shared/components/ConfiguredNeutSvg';
import {PageTitle} from '../../../../../_metronic/layout/core';
import {EkosietCopyToClipboard} from '../../../../shared/components/EkosietCopyToClipboard';
import {EkoCardHeader} from '../../../../shared/components/card';
import {Mm} from '../../../../shared/components';

const NeutDetailPage: React.FC = () => {
	const {code} = useParams<{code: string}>();
	const {
		isInitialLoading,
		data: neutDetails,
		isError,
	} = useQuery<NeutDetails, TypeError, NeutDetails>(['ApiClient.Pim.Neuten.getNeutDetails', code], () => ApiClient.Pim.Neuten.getNeutDetails(code!).then((res) => res.data));

	if (isInitialLoading) {
		return <Loading />;
	}
	if (isError) {
		return <>ERROR!</>;
	}
	if (!neutDetails) return <></>;

	return (
		<>
			<PageTitle
			// toolbar={
			// 	<button className={'btn btn-primary btn-sm disabled'} onClick={() => 1}>
			// 		BMH downloaden
			// 	</button>
			// }
			>
				{`Neut ` + neutDetails.code}
			</PageTitle>

			<EkoCard className="mb-5">
				<EkoCardHeader title={'Details'} />
				<EkoCardBody>
					<div className={'d-flex'}>
						<div>
							<ConfiguredNeutSvg svg={neutDetails.svg} svgWidth={'250px'} />
						</div>
						<div className="flex-fill">
							<div className="row mb-4">
								<div className="col-3">
									<label className="form-label">Id</label>
								</div>
								<div className="col">
									{neutDetails.id} <EkosietCopyToClipboard text={neutDetails.id} />
								</div>
							</div>

							<div className="row mb-4">
								<div className="col-3">
									<label className="form-label">Code</label>
								</div>
								<div className="col">
									{neutDetails.code} <EkosietCopyToClipboard text={neutDetails.code} />
								</div>
							</div>

							<div className="row mb-4">
								<div className="col-3">
									<label className="form-label">Breedte</label>
								</div>
								<div className="col">
									<Mm value={neutDetails.width} />
								</div>
							</div>

							<div className="row mb-4">
								<div className="col-3">
									<label className="form-label">Diepte</label>
								</div>
								<div className="col">
									<Mm value={neutDetails.depth} />
								</div>
							</div>

							<div className="row mb-4">
								<div className="col-3">
									<label className="form-label">Hoogte</label>
								</div>
								<div className="col">
									<Mm value={neutDetails.height} />
								</div>
							</div>

							<div className="row mb-4">
								<div className="col-3">
									<label className="form-label">Ingespongen</label>
								</div>
								<div className="col">
									{!neutDetails.neut.isIndented && 'Nee'}
									{neutDetails.neut.isIndented && (
										<span>
											Binnen <Mm value={neutDetails.neut.indentInside} />, buiten <Mm value={neutDetails.neut.indentOutside} />
										</span>
									)}
								</div>
							</div>
							{/*<div className="row mb-4">*/}
							{/*	<div className="col-3">*/}
							{/*		<label className="form-label">JSON</label>*/}
							{/*	</div>*/}
							{/*	<div className="col">*/}
							{/*		<pre>{JSON.stringify(neutDetails, null, 2)}</pre>*/}
							{/*	</div>*/}
							{/*</div>*/}
						</div>
					</div>
				</EkoCardBody>
			</EkoCard>

			<EkoCard>
				<EkoCardHeader title={'Productie'} />
				<EkoCardBody>
					<div className="row mb-5">
						<div className="col-3">Instructies</div>
						<div className="col">
							{neutDetails.productionInstructions && (
								<div style={{whiteSpace: 'pre'}} className="font-monospace" dangerouslySetInnerHTML={{__html: neutDetails.productionInstructions ?? ''}}></div>
							)}

							{!neutDetails.productionInstructions && <span className="text-muted">Geen instructies</span>}
						</div>
					</div>
					<div className="row mb-5">
						<div className="col-3">Output WM50</div>
						<div className="col">
							<div className="d-flex">
								{neutDetails.parts.map((part, index) => (
									<div key={index} className="d-flex flex-column align-items-center">
										<h3>
											{neutDetails.parts.length == 1 && 'Neut'}
											{neutDetails.parts.length > 1 && (
												<>
													{index === 0 && 'Linkerdeel'}
													{index === 1 && neutDetails.parts.length === 2 && 'Rechterdeel'}
													{index === 1 && neutDetails.parts.length === 3 && 'Tussenstuk'}
													{index === 2 && 'Rechterdeel'}
												</>
											)}
										</h3>
										<ConfiguredNeutSvg svg={part.svg} svgWidth={'250px'} />
									</div>
								))}
							</div>
						</div>
					</div>
				</EkoCardBody>
			</EkoCard>
		</>
	);
};

export default NeutDetailPage;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { CustomerFilter } from '../../../crm/components/CustomerFilter';
import { ExpeditionDateFilter } from './ExpeditionDateFilter';
import { useEkosietFilter } from '../../../../../shared/context/FilterContext';
import { OrderCodeFilter } from '../../../sm/orders/components/OrderCodeFilter';
import { ExpeditionStatusFilter } from './ExpeditionStatusFilter';

const ExpeditionSearch: React.FC = () => {

    const { reset } = useEkosietFilter()

    return (
        <>
            <div className="row">
                <div className="col-lg-2 mb-lg-0 mb-6">
                    <label>Order Code</label>
                    <OrderCodeFilter fieldName='filterOrderCode' filterProperty='OrderCode' />
                </div>
                <div className="col-lg-4  mb-lg-0 mb-6">
                    <label>Gewenste leverdatum</label>
                    <ExpeditionDateFilter fieldName='filterExpeditionDate' />
                </div>
                <div className="col-lg-3  mb-lg-0 mb-6">
                    <label>Klant</label>
                    <CustomerFilter fieldName='filterCustomerId' />
                </div>
                <div className="col-lg-2  mb-lg-0 mb-6">
                    <label>Status</label>
                    <ExpeditionStatusFilter fieldName='filterExpeditionStatus' />
                </div>

                <div className="col-lg-1  mb-lg-0 mb-6">
                    <label></label>
                    <div className='fv-row mb-7'>
                        <button className="btn btn-secondary btn-secondary--icon" type='reset' onClick={reset}>
                            <span>
                                <i className="la la-close"></i>
                                <span>Reset</span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export { ExpeditionSearch }
import React from "react";
import {Navigate, Outlet, Route, Routes} from "react-router-dom";
import {ErrorBoundary, FallbackProps} from "react-error-boundary";
import axios from "axios";
import {BackofficeRoutePath} from "../../RoutePath";
import {UserListWrapper} from "./pages/UsersPage";
import {RolesListWrapper} from "./pages/RolesPage";
export const UmRoutePath = {

    userList: 'gebruikers',
    rolesList: 'rollen',

    link(path:string){
        return BackofficeRoutePath.um+path;
    }
};
const UmRoutes: React.FC = () => {

    return (
        <Routes>
            <Route element={<ErrorBoundary FallbackComponent={SmFallback}><Outlet/></ErrorBoundary>}>
                <Route path={UmRoutePath.userList} element={<UserListWrapper/>}/>
                <Route path={UmRoutePath.rolesList} element={<RolesListWrapper/>}/>
            </Route>
            <Route index element={<Navigate to={UmRoutePath.userList}/>}/>
        </Routes>
    );
}

const SmFallback: React.FC<FallbackProps> = (props) => {

    if (axios.isAxiosError(props.error)) {
        if (props.error.response?.status === 404) {
            return <>Not found</>;
        }
        if (props.error.response?.status === 500) {
            return <>Server error</>;
        }
    }

    return <>Error</>;
};

export default UmRoutes;
import React, {FC} from "react";
import {createPortal} from "react-dom";
import {Button, Modal} from "react-bootstrap";
import {modalsRoot} from "../../../../global-helpers";
import SpinnerButton from "../../../../shared/components/SpinnerButton";
import {usePostBatch, usePostBatchCreateFromFilter} from "../hooks/use-post-batch";
import {
    PostProductionBatchFromFilterRepresentation,
    PostProductionBatchRepresentation,
    ProductionBatchTypes
} from "../../../../modules/api-client/generated";
import * as Yup from "yup";
import {Field, Form, Formik, FormikHelpers} from "formik";
import {Simulate} from "react-dom/test-utils";

import TextField from "../../../../shared/components/TextField";
import {Switch} from "@headlessui/react";

export interface StartBatchModalProps {
    show: boolean;
    type: ProductionBatchTypes;
    onClose: () => void;
    onSuccess?: () => void;

    urgent: boolean;
    selectPage: boolean;
    selection: string[];
    selectionSize: number;

    selectAll: boolean
    selectionFilters: string[];
    isLoading: boolean;

}

const StartBatchModal: FC<StartBatchModalProps> = (
    {
        show,
        onClose,
        urgent,
        selectPage,
        selection,
        type,
        isLoading,
        selectionSize,
        selectAll,
        selectionFilters,
        onSuccess
    }
) => {

    const selectionMutation = usePostBatch();

    const selectAllMutation = usePostBatchCreateFromFilter();
    const initialValues: PostProductionBatchRepresentation = {
        items: selection,
        remark: undefined,
        title: "Batch",
        urgent: urgent,
        type: type
    }

    const schema = {
        remark: Yup.string().notRequired().nullable()
    }
    const formSchema = () => Yup.lazy(() => {
        return Yup.object().shape(schema)
    });

    //
    const onSubmit = (values: PostProductionBatchRepresentation, {setSubmitting}: FormikHelpers<PostProductionBatchRepresentation>) => {

        return new Promise<void>((resolve, reject) => {
            if (selectAll) {

                console.log(selectionFilters)

                const data:PostProductionBatchFromFilterRepresentation = {
                    remark: values.remark,
                    selectionFilters: selectionFilters,
                    title: values.title,
                    urgent: values.urgent,
                    type: values.type
                }

                selectAllMutation.mutate(data, {
                    onSuccess: () => {
                        resolve();
                        onClose()
                    },
                    onError: () => {
                        reject();
                    }
                });
            } else {
                selectionMutation.mutate(values, {
                    onSuccess: () => {
                        resolve();
                        onClose()
                    },
                    onError: () => {
                        reject();
                    }
                });
            }

        }).finally(() => setSubmitting(false));
    };

    if (!show) return null;

    return createPortal(
        <Modal
            tabIndex={-1}
            aria-hidden='true'
            show={show}
            size="lg"
            onHide={() => !isLoading ? onClose() : () => {
            }}
            animation={false}
            backdrop={selectionMutation.isLoading ? 'static' : true}>
            <Modal.Header closeButton>
                <Modal.Title>Batch aanmaken</Modal.Title>
            </Modal.Header>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={formSchema}>
                {({
                      getFieldProps,
                    values,
                    handleChange,
                      isSubmitting,
                  }) =>
                    (
                    <Form>

                        <Modal.Body>
                            <div className='row mb-4'>
                                <div className='col-4'>
                                    <div id="checkbox-group">Type batch</div>
                                </div>
                                <div className={'col'}>
                                    <div className={'alert alert-danger p-5'}>Let op ivm baknummers: <strong>MAAK</strong> en <strong>ACCEPTEER</strong> eerst neuten en latten batch voordat je de dorpelbatch aanmaakt!</div>
                                    <div role="group" aria-labelledby="checkbox-group">
                                        <label className={'me-3'}>
                                            <Field type="radio" name="type" value={ProductionBatchTypes.Neuten}
                                                   className={'me-1'}/>
                                            Neuten
                                        </label>
                                        <label className={'me-3'}>
                                            <Field type="radio" name="type" value={ProductionBatchTypes.Latten}
                                                   className={'me-1'}/>
                                            Latten
                                        </label>
                                        <label className={'me-3'}>
                                            <Field type="radio" name="type" value={ProductionBatchTypes.Dorpel}
                                                   className={'me-1'}/>
                                            Dorpels
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <TextField row={true} label={'Opmerking'} placeholder={'optioneel...'} name={'remark'}/>
                            <div className='row mb-4'>
                                <div className='col-4'>
                                    <label className='form-label' htmlFor='type'>Spoed</label>
                                </div>
                                <div className='col-8'>
                                    <div className='form-check form-switch'>
                                        <input className='form-check-input' type='checkbox' role='switch'
                                               name='urgent'
                                               value='true'
                                               onChange={handleChange}
                                               checked={values.urgent ? true:false}
                                               id='urgent' />
                                        <label className='form-check-label' htmlFor='urgent'>Spoed?</label>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="link" className="mx-4" onClick={() => onClose()}>Annuleren</Button>
                            <SpinnerButton type="submit" className="btn btn-primary"
                                           disabled={isLoading || !selectionSize}
                                           spinning={isSubmitting}>Batch aanmaken voor <strong>{selectionSize} dorpels</strong></SpinnerButton>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>,
        modalsRoot
    )
}

export {StartBatchModal}

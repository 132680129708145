import React from "react";
import {Field} from "formik";
import {SillDetails} from "../../../../modules/api-client/generated";
import {useQuery} from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import CustomSelect, {SelectOption, OptionsType} from "../../../../shared/components/CustomSelect";

type Props = {
    name: string;
    multi?: boolean;
    required?: boolean;
}
export const SillPicker: React.FC<Props> = ({name, multi, required}) => {

    let options: OptionsType<SelectOption> = [];

    const {
        data: list,
    } = useQuery<SillDetails[]>(['ApiClient.Pim.Sill.listSills'], () => ApiClient.Pim.Sill.listSills().then((res) => res.data));


    if (list) {
        options = list.map((item) => {
            return {value: item.id!, label: item.name!}
        })
    }


    return (
        <>
            <Field
                className="custom-select"
                name={name}
                options={options}
                component={
                    CustomSelect
                }
                placeholder={multi ? "Kies een of meerdere dorpels..." : 'Kies een dorpel'}
                isMulti={multi ?? false}
                isClearable={!required}
            />
        </>
    )
}
import React from "react";
import {NumericFormat} from "react-number-format";

export interface Props {
    value?: number | string | null;
}

const Euro: React.FC<Props> = ({value}) => {
    if(typeof value === "number"){
        value = value.toFixed(2)
    }
      return (
          <NumericFormat className={'text-nowrap'} decimalScale={2} value={value} displayType={'text'} thousandSeparator={true} prefix={'€ '} />
      )
    }
;

export default Euro;

import { FC, useEffect, useRef } from 'react'
import Select from "react-select"
import { FilterParameter, useEkosietFilter } from '../../../../../shared/context/FilterContext';
import {ExpeditionStates} from "../../../../../shared/components/ExpeditionStatus";

type Props = {
    fieldName: string;
}

export const ExpeditionStatusFilter: FC<Props> = (props: Props) => {

    const { setFilters, containsFilter, resetCount } = useEkosietFilter()
    const selectInputRef = useRef<any>();
    const stateOptions: any[] = ExpeditionStates.map((item: any) => {
        return { value: item.key, label: item.label }
    });

    useEffect(() => {
        if (resetCount) {
            selectInputRef.current.clearValue();
        }
    }, [resetCount])



    return (
        <>
            <Select
                ref={selectInputRef}
                placeholder="Selecteer een expeditie status"
                styles={{
                    // Fixes the overlapping problem of the component
                    menu: provided => ({ ...provided, zIndex: 9999 })
                  }}
                isMulti={true}
                isClearable={true}
                isSearchable={false}
                name={props.fieldName}
                options={stateOptions}
                onChange={(newValue) => {

                    if (newValue) {

                        const filterExpression = {
                            or: newValue.map(v => { return { ExpeditionStatus: v.value } })
                        };

                        const filter: FilterParameter = {
                            filterExpression: filterExpression,
                            filterName: props.fieldName,
                            values: newValue.map(v => v.value)
                        }

                        setFilters(props.fieldName, [filter])
                    } else {
                        if (containsFilter(props.fieldName)) {
                            setFilters(props.fieldName, [])
                        }
                    }
                }}
            />
        </>
    )
}

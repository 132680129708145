/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {
    CardsWidget7,
    CardsWidget17,
    CardsWidget20,
    EngageWidget10,
    ListsWidget26,
    ChartsWidget1,
    ChartsWidget3,
    ChartsWidget2,
    ChartsWidget8,
    ChartsWidget7,
    ChartsWidget6, TilesWidget1, TilesWidget2, TilesWidget3, TilesWidget4, TilesWidget5, MixedWidget1,
} from '../../../_metronic/partials/widgets'
import {ProfileDashboardWidget} from "../modules/pim/sponningen/ProfileDashboardWidget";
import {OrderListDashboardWidget} from "../modules/sm/orders/widgets/OrderListDashboardWidget";
import {WeeklyStatsTile} from '../modules/sm/components/WeeklyStatsTile';
import {YTDStatsTile} from "../modules/sm/components/YTDStatsTile";
import Snowfall from "../components/Christmas/Snowfall";
import {MonsterDorpelWidget} from "../../shared/components/misc/MonsterDorpelWidget";
import {DashboardWidget} from "../modules/mgt/components/DashboardWidget";
import {WeeklyProductionStatsTile} from "../modules/sm/components/WeeklyProductionStatsTile";

const Dashboard: FC = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>Dashboard</PageTitle>
            <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>

                <div className='col-md-6 col-lg-6 col-xl-6 mb-md-5 mb-xl-10'>
                    {/*<TilesWidget2 className={'h-lg-50 mb-10'}/>*/}
                    <DashboardWidget className={'mb-5'} color={'success'}/>
                    {/*<ChartsWidget6 className={'h-lg-50'}/>*/}
                </div>
                <div className='col-md-6 col-lg-6 col-xl-6 mb-md-5 mb-xl-10'>
                    <MonsterDorpelWidget className='h-lg-50'/>
                    <EngageWidget10 className='h-lg-50'/>
                    {/*<Snowfall />*/}
                </div>
            </div>
        </>
    )
}

export {Dashboard}

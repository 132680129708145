/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {IUpdatePassword, IUpdateEmail} from '../../../backoffice/modules/crm/components/SettingsModel'
import {updateEmp, updateUser} from '../../../backoffice/modules/um/users-list/core/_requests'
import {useQueryResponse} from '../../../backoffice/modules/um/users-list/core/QueryResponseProvider'
import toast from 'react-hot-toast';
import { useEkosietAuth0 } from '../core/useEkosietAuth0'

const emailFormValidationSchema = Yup.object().shape({
  newEmail: Yup.string()
    .email('Geen geldig e-mail')
    .min(3, 'Minimaal 3 karakters')
    .max(50, 'Maximaal 50 karakters')
    .required('Email is verplicht'),
})

const passwordFormValidationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(8, 'Minimaal 8 karakters')
    .required('Wachtwoord is verplicht')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      'Wachtwoord moet minimaal uit 8 karakters bestaan waarvan 1 hoofdletter, 1 kleine letter en 1 speciaal karakter (!@#$%^&*).'
    ),
  passwordConfirmation: Yup.string()
    .min(8, 'Minimaal 8 karakters')
    .required('Wachtwoord is verplicht')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      'Wachtwoord moet minimaal uit 8 karakters bestaan waarvan 1 hoofdletter, 1 kleine letter en 1 speciaal karakter (!@#$%^&*).'
    )
    .oneOf([Yup.ref('newPassword'), null], 'Wachtwoord moet overeenkomen'),
})

interface ISignInMethodProps {
  isEditModal?: boolean
  userData?: any
}
const EkosietSignInMethod: React.FC<ISignInMethodProps> = ({
  isEditModal,
  userData,  
}: ISignInMethodProps) => {
  const {refetch} = useQueryResponse();
  const {user, getAccessTokenSilently} = useEkosietAuth0();
  const userInfo = isEditModal ? userData ? userData : user : user;
    
  const updateEmail: IUpdateEmail = {
    newEmail: userInfo?.email || 'Voer nieuw mailadres in',
    id: isEditModal ? userInfo.userId : userInfo?.sub,
  }

  const updatePassword: IUpdatePassword = {
    newPassword: '',
    passwordConfirmation: '',
    id: isEditModal ? userInfo.userId : userInfo?.sub,
  }

  const [showEmailForm, setShowEmailForm] = useState<boolean>(false)
  const [showPasswordForm, setPasswordForm] = useState<boolean>(false)
  const [email, setEmail] = useState<IUpdateEmail>(updateEmail)
  const [loading1, setLoading1] = useState(false)

  const updateData = (data: any) => {
    return isEditModal ? updateUser(data) : updateEmp(data)
  }

  const formik1 = useFormik<IUpdateEmail>({
    initialValues: email,
    validationSchema: emailFormValidationSchema,
    onSubmit: (values) => {      
      try {        
        if (values.newEmail === userInfo.email ) {
          return
        }
  
        setLoading1(true)

        const data = {
          data: {
            userId: values.id,
            fullName: values.newEmail,
            email: values.newEmail,
            emailVerified: values.newEmail === userInfo.email ? userInfo.emailVerified : false,
          },
        }
        updateData(data)
          .then(() => {            
            toast.success('Wijzigen e-mail gelukt.')
            setLoading1(false)            
            setEmail({
              newEmail: values.newEmail,
              id: values.id,
            });            
            setShowEmailForm(false);
            setTimeout(() => refetch(), 1000);
            if (!isEditModal) {              
              toast.success('U dient opnieuw in te loggen.')
              setTimeout(() => getAccessTokenSilently({ignoreCache: true}), 2000);              
            }
          })
          .catch((err) => {
            if  (err?.response?.data?.detail) {
              toast.error('Wijzigen e-mail mislukt. Fout: ' + err?.response?.data?.detail)
            } else {
              toast.error('Wijzigen e-mail mislukt.');
            }
          })
      } catch (ex) {        
        console.error(ex)
      } finally {
        setLoading1(false)
        setShowEmailForm(false)
      }
    },
  })

  const [loading2, setLoading2] = useState(false)

  const formik2 = useFormik<IUpdatePassword>({
    initialValues: {
      ...updatePassword,
    },
    validationSchema: passwordFormValidationSchema,
    onSubmit: (values) => {
      setLoading2(true)
      try {
        const data = {
          data: {
            userId: values.id, 
            password: values.newPassword
          },
        }
        updateUser(data)
          .then(() => {
            toast.success('Wijzigen wachtwoord gelukt.')
            setLoading2(false)
            setPasswordForm(false)
            setTimeout(() => refetch(), 1000)
          })
          .catch((err) => {
            if  (err?.response?.data?.detail) {
            toast.error('Wijzigen wachtwoord mislukt. Fout: ' + err?.response?.data?.detail)
            } else {
              toast.error('Wijzigen wachtwoord mislukt.')
            }
          })
      } catch (ex) {
        console.error(ex)
      } finally {
        setLoading2(false)
        setPasswordForm(false)
      }
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      {!isEditModal && (
        <div
          className='card-header border-0'
          role='button'          
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Account</h3>
          </div>
        </div>
      )}

      <div id='kt_account_signin_method' className='collapse show'>
        <div className='card-body border-top p-9'>
          <div className='d-flex flex-wrap align-items-center'>
            <div id='kt_signin_email' className={' ' + (showEmailForm && 'd-none')}>
              <div className='fs-6 fw-bolder mb-1'>E-mail</div>
              <div className='fw-bold text-gray-600'>{email.newEmail}</div>
            </div>

            <div
              id='kt_signin_email_edit'
              className={'flex-row-fluid ' + (!showEmailForm && 'd-none')}
            >
              <form
                onSubmit={formik1.handleSubmit}
                id='kt_signin_change_email'
                className='form'
                noValidate
              >
                <div className='row mb-6'>
                  <div className='col-lg-6 mb-4 mb-lg-0'>
                    <div className='fv-row mb-0'>
                      <label htmlFor='emailaddress' className='form-label fs-6 fw-bolder mb-3'>
                        Nieuwe e-mail
                      </label>
                      <input
                        type='email'
                        className='form-control form-control-lg form-control-solid'
                        id='emailaddress'                        
                        {...formik1.getFieldProps('newEmail')}
                      />
                      
                      {!isEditModal && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>Na het wijzigen wordt u automatisch uitgelogd zodat u met uw nieuwe e-mail (gebruikersnaam) kan inloggen.</div>
                        </div>
                      )}

                      {formik1.touched.newEmail && formik1.errors.newEmail && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik1.errors.newEmail}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='d-flex'>
                  <button
                    id='kt_signin_submit'
                    type='submit'
                    className='btn btn-primary  me-2 px-6'
                  >
                    {!loading1 && 'Wijzig Email'}
                    {loading1 && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Wachten a.u.b...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                  <button
                    id='kt_signin_cancel'
                    type='button'
                    onClick={() => {
                      setShowEmailForm(false)
                    }}
                    className='btn btn-color-gray-400 btn-active-light-primary px-6'
                  >
                    Annuleren
                  </button>
                </div>
              </form>
            </div>

            <div id='kt_signin_email_button' className={'ms-auto ' + (showEmailForm && 'd-none')}>
              <button
                onClick={() => {
                  setShowEmailForm(true)
                }}
                className='btn btn-light btn-active-light-primary'
              >
                Wijzig E-mail
              </button>
            </div>
          </div>

          <div className='separator separator-dashed my-6'></div>

          <div className='d-flex flex-wrap align-items-center mb-10'>
            <div id='kt_signin_password' className={' ' + (showPasswordForm && 'd-none')}>
              <div className='fs-6 fw-bolder mb-1'>
                Wachtwoord
              </div>
              <div className='fw-bold text-gray-600'>************</div>
            </div>

            <div
              id='kt_signin_password_edit'
              className={'flex-row-fluid ' + (!showPasswordForm && 'd-none')}
            >
              <form
                onSubmit={formik2.handleSubmit}
                id='kt_signin_change_password'
                className='form'
                noValidate
              >
                <div className='row mb-1'>
                  <div className='col-lg-6'>
                    <div className='fv-row mb-0'>
                      <label htmlFor='newpassword' className='form-label fs-6 fw-bolder mb-3'>
                        Wachtwoord
                      </label>
                      <input
                        type='password'
                        className='form-control form-control-lg form-control-solid '
                        id='newpassword'
                        {...formik2.getFieldProps('newPassword')}
                      />
                      {formik2.touched.newPassword && formik2.errors.newPassword && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik2.errors.newPassword}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='col-lg-6'>
                    <div className='fv-row mb-0'>
                      <label htmlFor='confirmpassword' className='form-label fs-6 fw-bolder mb-3'>
                        Bevestig wachtwoord
                      </label>
                      <input
                        type='password'
                        className='form-control form-control-lg form-control-solid'
                        id='confirmpassword'
                        {...formik2.getFieldProps('passwordConfirmation')}
                      />
                      {formik2.touched.passwordConfirmation && formik2.errors.passwordConfirmation && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik2.errors.passwordConfirmation}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className='form-text mb-5'>
                  Wachtwoord moet minimaal uit 8 karakters bestaan waarvan 1 hoofdletter, 1 kleine letter en 1 speciaal karakter (!@#$%^&*).
                </div>

                <div className='d-flex'>
                  <button
                    id='kt_password_submit'
                    type='submit'
                    className='btn btn-primary me-2 px-6'
                  >
                    {!loading2 && 'Wijzig Wachtwoord'}
                    {loading2 && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Even wachten a.u.b.
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                  <button
                    onClick={() => {
                      setPasswordForm(false)
                    }}
                    id='kt_password_cancel'
                    type='button'
                    className='btn btn-color-gray-400 btn-active-light-primary px-6'
                  >
                    Annuleren
                  </button>
                </div>
              </form>
            </div>

            <div
              id='kt_signin_password_button'
              className={'ms-auto ' + (showPasswordForm && 'd-none')}
            >
              <button
                onClick={() => {
                  setPasswordForm(true)
                }}
                className='btn btn-light btn-active-light-primary'
              >
                Reset wachtwoord
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {EkosietSignInMethod}

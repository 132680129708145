/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from "react";
import {PageTitle} from "../../../../../_metronic/layout/core";
import {useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {CustomerSillStats, CustomerSillStats as x} from "../../../../modules/api-client/generated";
import ApiClient from "../../../../modules/api-client/ApiClient";
import Loading from "../../../../shared/components/Loading";
import {CustomerSillsStats} from "../../crm/components/CustomerSillsStats";
import {CustomerSalesStats} from "../../crm/components/CustomerSalesStats";
import {CustomerExpeditionStats} from "../../crm/components/CustomerExpeditionStats";
import {CustomerStats} from "../../crm/components/CustomerStats";
export function MgtCustomer() {


    return (
<CustomerStats/>
    )
}

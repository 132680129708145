import { FC, useEffect, useState } from 'react'
import { DateInput } from '../../../../../shared/components/date/DateInput';
import moment from 'moment';
import { FilterParameter, useEkosietFilter } from '../../../../../shared/context/FilterContext';
import classNames from "classnames";

type Props = {
    fieldName: string;
}

export const RealisedExpeditionDateFilter: FC<Props> = (props: Props) => {

    const { setFilters, containsFilter, resetCount } = useEkosietFilter()
    const [notSet, setNotSet] = useState<boolean>(false)
    const [fromDate, setFromDate] = useState<Date | undefined>()
    const [toDate, setToDate] = useState<Date | undefined>()
    const minDateToDate = fromDate ? moment(fromDate).add(1, 'days').toDate() : undefined;

    const updateNotSetFilter = (notSet: boolean) => {

        if (notSet) {
            const filterZsmExpression = { RealisedExpeditionDate: null }

            const filterZsm: FilterParameter = {
                filterExpression: filterZsmExpression,
                filterName: props.fieldName,
                values: [notSet]
            }

            setFilters(props.fieldName, [filterZsm]);
        } else {
            if (containsFilter(props.fieldName)) {
                setFilters(props.fieldName, []);
            }
        }
    }

    const updateDateFilter = (fromDateValue: Date | undefined, toDateValue: Date | undefined) => {

        if (fromDateValue && toDateValue) {

            const filterExpression = { RealisedExpeditionDate: { ge: fromDateValue, le: toDateValue } }

            const filter: FilterParameter = {
                filterExpression: filterExpression,
                filterName: props.fieldName,
                values: [fromDateValue, toDateValue]
            }

            setFilters(props.fieldName, [filter]);
        } else {
            if (containsFilter(props.fieldName)) {
                setFilters(props.fieldName, []);
            }
        }
    }

    const toggleNotSet = () => {

        setNotSet(!notSet);
        if (!notSet) {
            setFromDate(undefined);
            setToDate(undefined);
        }
        updateNotSetFilter(!notSet);
    }

    const setFromDateValue = (val: Date) => {
        setNotSet(false);
        setFromDate(val);
        updateDateFilter(val, toDate);
    }

    const setToDateValue = (val: Date) => {
        setNotSet(false);
        setToDate(val);
        updateDateFilter(fromDate, val);
    }

    const reset = () => {
        setFromDate(undefined);
        setToDate(undefined);
        setNotSet(false);
        if (containsFilter(props.fieldName)) {
            setFilters(props.fieldName, []);
        }
    }

    useEffect(() => {
        if (resetCount) {
            setFromDate(undefined);
            setToDate(undefined);
            setNotSet(false);
        }
    }, [resetCount])
    return (
        <>
                <div className="input-group ">
                    <DateInput value={fromDate} onChange={setFromDateValue} placeholder='van datum' dateFormat='dd-MM-yyyy' />
                    <DateInput value={toDate} onChange={setToDateValue} minDate={minDateToDate} placeholder='tot datum' dateFormat='dd-MM-yyyy' />
                    <div className="input-group-append  ms-1"  id="button-addon4">
                        <button type={'button'} className={`btn btn-outline-secondary btn-sm m-0`} onClick={toggleNotSet}>
                            {notSet ?
                                <i className="far fa-square-check"></i>
                                :
                                <i className="far fa-square"></i>
                            }
                            Onbekend
                        </button>
                        <button type='reset' className="btn btn-outline-secondary btn-sm  ms-1 " onClick={reset}>
                            <i className="la la-close me-0"></i>
                        </button>
                    </div>
                </div>

        </>
    )
}



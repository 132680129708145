/* eslint-disable react/jsx-no-target-blank */
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub';
import { SidebarMenuItem } from './SidebarMenuItem';
import { useEkosietAuth0 } from '../../../../../app/modules/auth0/core/useEkosietAuth0';
import { PimRoutePath } from '../../../../../app/backoffice/modules/pim/PimRoutes';
import { CustomerRoutePath } from '../../../../../app/customer-portal/routing/RoutePath';
import { BackofficeRoutePath } from '../../../../../app/backoffice/RoutePath';
import { CrmRoutePath } from '../../../../../app/backoffice/modules/crm/CrmRoutes';
import { SmRoutePath } from '../../../../../app/backoffice/modules/sm/SmRoutes';
import { PmRoutePath } from '../../../../../app/backoffice/modules/pm/PmRoutes';
import { MgtRoutePath } from '../../../../../app/backoffice/modules/mgt/MgtRoutes';
import { UmRoutePath } from '../../../../../app/backoffice/modules/um/UmRoutes';
import { EmRoutePath } from '../../../../../app/backoffice/modules/em/EmRoutes';
import { FmRoutePath } from '../../../../../app/backoffice/modules/fm/FmRoutes';
import { AdminRoutePath } from '../../../../../app/backoffice/modules/admin/AdminRoutes';
import { ImportRoutePath } from '../../../../../app/shared/import/routes/ImportRoutes';
import { ProductionStationMenuItem } from "./ProductionStationMenuItem";
import { stationLookup } from "../../../../../app/backoffice/modules/pm/models/stations";
import { ProductionItemOperations } from "../../../../../app/modules/api-client/generated";

const SidebarMenuMain = () => {


    const {
        isSysteemBeheerder,
        isProductieMedewerker,
        isKlant,
        isManagement,
        isWerkVoorbereider,
        isExpeditieMedewerker,
        isTransportMedewerker,
    } = useEkosietAuth0();

    return (
        <>

            {isKlant &&
                <>
                    <SidebarMenuItem
                        to={CustomerRoutePath.link(CustomerRoutePath.dashboard)}
                        icon='/media/icons/duotune/art/art002.svg'
                        title={'Dashboard'}
                        fontIcon='bi-app-indicator'
                    />

                    <SidebarMenuItem
                        to={CustomerRoutePath.import + ImportRoutePath.list}
                        title={'Importeren'}
                        icon='/media/icons/duotune/files/fil005.svg'
                    />
                    <SidebarMenuItem
                        to={CustomerRoutePath.link(CustomerRoutePath.orders)}
                        fontIcon='bi-archive'
                        title={'Bestellingen'}
                        icon='/media/icons/duotune/general/gen005.svg'
                    />


                    {/* {process.env.NODE_ENV === 'development' &&
                    <SidebarMenuItem
                        to={CustomerRoutePath.link(CustomerRoutePath.imports)}
                        fontIcon='bi-archive'
                        title={'Imports'}
                        icon='/media/icons/duotune/files/fil005.svg'
                    />
                    } */}

                    <SidebarMenuItem
                        to={CustomerRoutePath.link(CustomerRoutePath.account)}
                        icon='/media/icons/duotune/communication/com005.svg'
                        title={'Account'}
                        fontIcon='bi-app-indicator'
                    />
                </>
            }

            {isProductieMedewerker && (
                <>

                    {/*<SidebarMenuItem to={PmRoutePath.link(PmRoutePath.stationsDashboard)}*/}
                    {/*                 title='Dashboard'/>*/}

                    <div className='menu-item'>
                        <div className='menu-content pt-8 pb-2'>
                            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>BATCHES</span>
                        </div>
                    </div>

                    <SidebarMenuItem to={PmRoutePath.link(PmRoutePath.productionBatchStationList)}
                        title='Open batches' />

                    {/*<div className='menu-item'>*/}
                    {/*    <div className='menu-content pt-8 pb-2'>*/}
                    {/*        <span className='menu-section text-muted text-uppercase fs-8 ls-1'>DORPEL DETAILS</span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*<SidebarMenuItem to={PmRoutePath.link(PmRoutePath.productionItemOperationDetail)}*/}
                    {/*                 title='Productie details'/>*/}

                    <div className='menu-item'>
                        <div className='menu-content pt-8 pb-2'>
                            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>PRODUCTIE STATIONS</span>
                        </div>
                    </div>

                    <ProductionStationMenuItem to={PmRoutePath.link(PmRoutePath.lattenMaken)} stationCode={ProductionItemOperations.LattenGemaakt} />
                    <ProductionStationMenuItem to={PmRoutePath.link(PmRoutePath.sawmaster)} stationCode={ProductionItemOperations.DorpelProfielGezaagd} />
                    <ProductionStationMenuItem to={PmRoutePath.link(PmRoutePath.dorpelsBewerken)} stationCode={ProductionItemOperations.DorpelBewerkt} />
                    <ProductionStationMenuItem to={PmRoutePath.link(PmRoutePath.neutenMonteren)} stationCode={ProductionItemOperations.NeutenGemonteerd} />

                    <ProductionStationMenuItem to={PmRoutePath.link(PmRoutePath.sluitpottenGemonteerd)} stationCode={ProductionItemOperations.SluitpottenGemonteerd} />
                    <ProductionStationMenuItem to={PmRoutePath.link(PmRoutePath.kopisolatorenGemonteerd)} stationCode={ProductionItemOperations.KopisolatorenGemonteerd} />
                    <ProductionStationMenuItem to={PmRoutePath.link(PmRoutePath.manchettesGemonteerd)} stationCode={ProductionItemOperations.ManchettesGemonteerd} />
                    <ProductionStationMenuItem to={PmRoutePath.link(PmRoutePath.lattenGemonteerd)} stationCode={ProductionItemOperations.LattenGemonteerd} />
                    <ProductionStationMenuItem to={PmRoutePath.link(PmRoutePath.hefschuifVouwwand)} stationCode={ProductionItemOperations.HefschuifVouwwand} />
                    <ProductionStationMenuItem to={PmRoutePath.link(PmRoutePath.inpakken)} stationCode={ProductionItemOperations.Ingepakt} />
                    <ProductionStationMenuItem to={PmRoutePath.link(PmRoutePath.custom)} stationCode={ProductionItemOperations.Custom} />

                    {/*<div className='menu-item'>*/}
                    {/*    <div className='menu-content pt-8 pb-2'>*/}
                    {/*        <span className='menu-section text-muted text-uppercase fs-8 ls-1'>AFMELDEN</span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<SidebarMenuItem to={PmRoutePath.link(PmRoutePath.afmeldenlanding)} title='Afmelden'/>*/}

                </>
            )}

            {(isManagement || isSysteemBeheerder || isWerkVoorbereider) && (
                <>
                    {/*<SidebarMenuItem*/}
                    {/*    to={BackofficeRoutePath.dashboard}*/}
                    {/*    icon='/media/icons/duotune/art/art002.svg'*/}
                    {/*    title={'Dashboard'}*/}
                    {/*    fontIcon='bi-app-indicator'*/}
                    {/*/>*/}

                    <SidebarMenuItem
                        to={CrmRoutePath.link(CrmRoutePath.customers)}
                        icon='/media/icons/duotune/communication/com005.svg'
                        title='Klanten'
                        fontIcon='bi-layers'
                    />

                    <SidebarMenuItem
                        to={SmRoutePath.link(SmRoutePath.orderList)}
                        title='Orders'
                        fontIcon='bi-archive'
                        icon='/media/icons/duotune/general/gen005.svg'
                    />

                    <SidebarMenuItem
                        to={BackofficeRoutePath.import + ImportRoutePath.list}
                        fontIcon='bi-archive'
                        title={'Imports'}
                        icon='/media/icons/duotune/files/fil005.svg'
                    />

                    <SidebarMenuItem
                        to={PmRoutePath.link(PmRoutePath.planningDashboard)}
                        title='Planning'
                        fontIcon='bi-archive'
                        icon='/media/icons/duotune/general/gen014.svg'
                    />

                    <SidebarMenuItemWithSub
                        to={PmRoutePath.link(PmRoutePath.stationsDashboard)}
                        icon='/media/icons/duotune/art/art002.svg'
                        title='Productie'
                        fontIcon='bi-layers'>

                        <SidebarMenuItem to={PmRoutePath.link(PmRoutePath.stationsDashboard)}
                            title='Dashboard' />

                        {/*<SidebarMenuItem to={PmRoutePath.link(PmRoutePath.planningDashboard)}*/}
                        {/*                 title='Planning'/>*/}

                        {/*<SidebarMenuItem to={PmRoutePath.link(PmRoutePath.weekplanning)} title='Weekplanning'/>*/}

                        <SidebarMenuItem to={PmRoutePath.link(PmRoutePath.productionItemList)}
                            title='Batches aanmaken' />
                        <SidebarMenuItem to={PmRoutePath.link(PmRoutePath.productionBatchList)}
                            title='Batch overzicht' />
                        <SidebarMenuItem to={PmRoutePath.link(PmRoutePath.productionBatchStationList)}
                            title='Open batches' />
                        <SidebarMenuItem to={PmRoutePath.link(PmRoutePath.productionItemOperationDetail)}
                            title='Productie details' />


                        <SidebarMenuItemWithSub
                            to={'#'}
                            icon='/media/icons/duotune/general/gen025.svg'
                            title='Stations'
                            fontIcon='bi-layers'>
                            <ProductionStationMenuItem to={PmRoutePath.link(PmRoutePath.lattenMaken)} stationCode={ProductionItemOperations.LattenGemaakt} />
                            <ProductionStationMenuItem to={PmRoutePath.link(PmRoutePath.sawmaster)} stationCode={ProductionItemOperations.DorpelProfielGezaagd} />
                            <ProductionStationMenuItem to={PmRoutePath.link(PmRoutePath.dorpelsBewerken)} stationCode={ProductionItemOperations.DorpelBewerkt} />
                            <ProductionStationMenuItem to={PmRoutePath.link(PmRoutePath.neutenMonteren)} stationCode={ProductionItemOperations.NeutenGemonteerd} />

                            <ProductionStationMenuItem to={PmRoutePath.link(PmRoutePath.sluitpottenGemonteerd)} stationCode={ProductionItemOperations.SluitpottenGemonteerd} />
                            <ProductionStationMenuItem to={PmRoutePath.link(PmRoutePath.kopisolatorenGemonteerd)} stationCode={ProductionItemOperations.KopisolatorenGemonteerd} />
                            <ProductionStationMenuItem to={PmRoutePath.link(PmRoutePath.manchettesGemonteerd)} stationCode={ProductionItemOperations.ManchettesGemonteerd} />
                            <ProductionStationMenuItem to={PmRoutePath.link(PmRoutePath.lattenGemonteerd)} stationCode={ProductionItemOperations.LattenGemonteerd} />
                            <ProductionStationMenuItem to={PmRoutePath.link(PmRoutePath.hefschuifVouwwand)} stationCode={ProductionItemOperations.HefschuifVouwwand} />
                            <ProductionStationMenuItem to={PmRoutePath.link(PmRoutePath.inpakken)} stationCode={ProductionItemOperations.Ingepakt} />
                            <ProductionStationMenuItem to={PmRoutePath.link(PmRoutePath.custom)} stationCode={ProductionItemOperations.Custom} />

                            <SidebarMenuItem to={PmRoutePath.link(PmRoutePath.afmeldenlanding)} title='Afmelden' />

                        </SidebarMenuItemWithSub>
                    </SidebarMenuItemWithSub>

                    <SidebarMenuItemWithSub
                        to={EmRoutePath.link(EmRoutePath.expeditionList)}
                        icon='/media/icons/duotune/ecommerce/ecm006.svg'
                        title='Expeditie'
                        fontIcon='bi-layers'>

                        {/*<SidebarMenuItem to={EmRoutePath.link(EmRoutePath.expeditionList)} title='Overzicht'/>*/}
                        <SidebarMenuItem to={EmRoutePath.link(EmRoutePath.planning)} title='Planning' />
                        <SidebarMenuItem to={EmRoutePath.link(EmRoutePath.planningTransporteur)} title='Transport' />

                    </SidebarMenuItemWithSub>
                    {(isManagement || isSysteemBeheerder) &&
                        <>
                            <SidebarMenuItem
                                to={FmRoutePath.link(FmRoutePath.invoiceList)}
                                icon='/media/icons/duotune/finance/fin008.svg'
                                title='Financieel'
                                fontIcon='bi-layers'>
                            </SidebarMenuItem>
                            <SidebarMenuItemWithSub
                                to={'#'}
                                icon='/media/icons/duotune/graphs/gra004.svg'
                                title='Management'
                                fontIcon='bi-archive'>
                                <SidebarMenuItem to={MgtRoutePath.link(MgtRoutePath.dashboard)} title='Dashboard' />
                                <SidebarMenuItem to={MgtRoutePath.link(MgtRoutePath.crm)} title='Klanten' />
                                <SidebarMenuItem to={MgtRoutePath.link(MgtRoutePath.sales)} title='Sales' />
                                <SidebarMenuItem to={MgtRoutePath.link(MgtRoutePath.sillStats)} title='Dorpels' />
                                <SidebarMenuItem to={MgtRoutePath.link(MgtRoutePath.production)} title='Productie' />
                                <SidebarMenuItem to={MgtRoutePath.link(MgtRoutePath.expedition)} title='Expeditie' />
                            </SidebarMenuItemWithSub>

                        </>
                    }
                </>)
            }

            {
                (isExpeditieMedewerker) && (
                    <>
                        <div className='menu-item'>
                            <div className='menu-content pt-8 pb-2'>
                                <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Expeditie</span>
                            </div>
                        </div>
                        <SidebarMenuItem
                            icon='/media/icons/duotune/abstract/abs002.svg'
                            to={EmRoutePath.link(EmRoutePath.planning)}
                            title='Planning'
                        />
                    </>
                )
            }


            {
                (isTransportMedewerker) && (
                    <>
                        <div className='menu-item'>
                            <div className='menu-content pt-8 pb-2'>
                                <span className='menu-section text-muted text-uppercase fs-8 ls-1'>TRANSPORT</span>
                            </div>
                        </div>
                        <SidebarMenuItem
                            icon='/media/icons/duotune/abstract/abs002.svg'
                            to={EmRoutePath.link(EmRoutePath.planningTransporteur)}
                            title='Planning'
                        />
                    </>
                )
            }

            {
                (isManagement || isSysteemBeheerder || isWerkVoorbereider) && (
                    <>
                        <div className='menu-item'>
                            <div className='menu-content pt-8 pb-2'>
                                <span className='menu-section text-muted text-uppercase fs-8 ls-1'>BEHEER</span>
                            </div>
                        </div>

                        <SidebarMenuItem
                            icon='/media/icons/duotune/abstract/abs002.svg'
                            to={PimRoutePath.link(PimRoutePath.profiles)}
                            title='Profielen'
                        />
                        <SidebarMenuItem
                            icon='/media/icons/duotune/finance/fin001.svg'
                            to={PimRoutePath.link(PimRoutePath.configuredSills)}
                            title='Dorpels'
                        />
                        <SidebarMenuItem
                            icon='/media/icons/duotune/finance/fin001.svg'
                            to={PimRoutePath.link(PimRoutePath.neuten)}
                            title='Neuten'
                        />
                        <SidebarMenuItem
                            icon='/media/icons/duotune/finance/fin001.svg'
                            to={PimRoutePath.link(PimRoutePath.sills)}
                            title='Dorpel profielen'
                        />
                        <SidebarMenuItem
                            icon='/media/icons/duotune/ecommerce/ecm001.svg'
                            to={PimRoutePath.link(PimRoutePath.catalog)}
                            title='Catalogus'
                        />

                        <SidebarMenuItem
                            icon='/media/icons/duotune/general/gen049.svg'
                            to={UmRoutePath.link(UmRoutePath.userList)}
                            title='Gebruikersbeheer'
                        />

                        {/*<SidebarMenuItemWithSub*/}
                        {/*    to={'#'}*/}
                        {/*    icon='/media/icons/duotune/technology/teh001.svg'*/}
                        {/*    title='Import Configuratie'*/}
                        {/*    fontIcon='bi-archive'>*/}
                        {/*    <SidebarMenuItem to={ImportRoutePath.link(ImportRoutePath.importProfileMapping)} title='Profiel Mappings'/>*/}
                        {/*    <SidebarMenuItem to={ImportRoutePath.link(ImportRoutePath.importSluitpotMapping)} title='Sluitpot Mappings'/>*/}
                        {/*</SidebarMenuItemWithSub>*/}
                    </>
                )
            }

            {
                isSysteemBeheerder && <>

                    <SidebarMenuItem
                        to={BackofficeRoutePath.masterdata}
                        title='Stamgegevens'
                        fontIcon='bi-archive'
                        icon='/media/icons/duotune/art/art006.svg'
                    />
                    <SidebarMenuItem
                        icon='/media/icons/duotune/general/gen019.svg'
                        to={AdminRoutePath.link(AdminRoutePath.dashboard)}
                        title='Admin'
                    />
                </>
            }
        </>
    )
        ;
};

export { SidebarMenuMain };

import {createRoot} from 'react-dom/client'
// Axios
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from "@tanstack/react-query"
// Apps
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import {AppRoutes} from './app/routing/AppRoutes'
import {Auth0Provider} from '@auth0/auth0-react'
import {AUTH_AUD_URL, AUTH_CLIENT_ID, AUTH_DOMAIN} from './app/constants'
import {AxiosInterceptor} from './app/auth/core/AxiosInterceptor'
import React from "react";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
Chart.register(...registerables)

const {PUBLIC_URL} = process.env

const queryClient = new QueryClient()
const container = document.getElementById('root')

if (container) {
    createRoot(container).render(
        <QueryClientProvider client={queryClient}>
            <MetronicI18nProvider>
                <Auth0Provider
                    domain={AUTH_DOMAIN}
                    clientId={AUTH_CLIENT_ID}
                    redirectUri={window.location.origin + PUBLIC_URL}
                    audience={AUTH_AUD_URL}
                    useRefreshTokens={true}
                    cacheLocation='localstorage'>
                    <AxiosInterceptor>
                        <AppRoutes/>
                    </AxiosInterceptor>
                </Auth0Provider>
            </MetronicI18nProvider>
            <ReactQueryDevtools initialIsOpen={false}/>
        </QueryClientProvider>
    )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactElement } from 'react'
import { EkoCard, EkoCardBody } from "../../../../../../../_metronic/helpers";
import { Remark } from "./Remark";
import { OrderRemarkRepresentation } from "../../../../../../modules/api-client/generated";
import { AddRemarkForm } from "./forms/AddRemarkForm";

type Props = {
    remarks: Array<OrderRemarkRepresentation> | null | undefined
    orderId?: string
    setModalComponent: React.Dispatch<React.SetStateAction<ReactElement | undefined | null>>,
    setModalTitle: React.Dispatch<React.SetStateAction<string | undefined | null>>,
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>,
}
const OrderRemarks: React.FC<Props> = ({ remarks, orderId, setShowEditModal, setModalTitle, setModalComponent }) => {
    if (!remarks || !orderId) return (<></>)

    const addRemark = () => {
        setModalComponent(<AddRemarkForm orderId={orderId} handleClose={() => setShowEditModal(false)} />);
        setModalTitle('Opmerking toevoegen aan deze order');
        setShowEditModal(true);
    }
    const remarkContent: any[] = [];
    remarks?.forEach((remark, key) => {
        remarkContent.push(<Remark
            key={key}
            setModalTitle={setModalTitle}
            setModalComponent={setModalComponent}
            setShowEditModal={setShowEditModal}
            remark={remark}
        />)
    })
    return (
        <>
            <EkoCard className={'py-4 h-100'} flush={true}>

                <div className="card-header">
                    <div className="card-title">
                        <h2>Opmerkingen</h2>
                    </div>
                    <div className="card-toolbar">
                        <button type="button" className="btn btn-sm btn-light-info" onClick={addRemark}>
                            <i className="fas fa-plus pe-0"></i>
                        </button>
                    </div>
                </div>

                <EkoCardBody className={'pt-0'}>
                    {remarkContent}
                </EkoCardBody>
            </EkoCard>
        </>
    )
}

export { OrderRemarks }

import React, {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {usersColumns} from './columns/_columns'
import {User} from '../core/_models'
import {UsersListLoading} from '../components/loading/UsersListLoading'
import {UsersListPagination} from '../components/pagination/UsersListPagination'
import {EkoCard, EkoCardBody} from '../../../../../../_metronic/helpers'
import {EkoCardHeader} from "../../../../../shared/components/card/EkoCardHeader";
import {UsersListSearchComponent} from "../components/header/UsersListSearchComponent";
import {UsersListGrouping} from "../components/header/UsersListGrouping";
import {UsersListToolbar} from "../components/header/UserListToolbar";
import {useListView} from "../core/ListViewProvider";

const UsersTable = () => {
    const {selected} = useListView()
    const users = useQueryResponseData()
    const isLoading = useQueryResponseLoading()
    const data = useMemo(() => users, [users])
    const columns = useMemo(() => usersColumns, [])
    const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
        columns,
        data,
    })

    return (
        <EkoCard>
            <EkoCardHeader>
                <UsersListSearchComponent />
                {/* begin::Card toolbar */}
                <div className='card-toolbar'>
                    {/* begin::Group actions */}
                    {selected.length > 0 ? <UsersListGrouping /> : <UsersListToolbar />}
                    {/* end::Group actions */}
                </div>

            </EkoCardHeader>
            <EkoCardBody className='py-4'>

                <div className='table-responsive'>
                    <table
                        id='kt_table_users'
                        className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                        {...getTableProps()}
                    >
                        <thead>
                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                            {headers.map((column: ColumnInstance<User>) => (
                                <CustomHeaderColumn key={column.id} column={column}/>
                            ))}
                        </tr>
                        </thead>
                        <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                        {rows.length > 0 ? (
                            rows.map((row: Row<User>, i) => {
                                prepareRow(row)
                                return <CustomRow row={row} key={`row-${i}-${row.id}`}/>
                            })
                        ) : (
                            <tr>
                                <td colSpan={7}>
                                    <div
                                        className='d-flex text-center w-100 align-content-center justify-content-center'>
                                        Geen gebruikers gevonden
                                    </div>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
                <UsersListPagination/>
                {isLoading && <UsersListLoading/>}
            </EkoCardBody>
        </EkoCard>
    )
}

export {UsersTable}

import {useQueryClient, useMutation} from "@tanstack/react-query"
import {QUERIES} from '../../../../../../../_metronic/helpers'
import {deleteSelectedRoles} from '../../core/_requests'
import {useListView} from '../../core/ListViewProvider'
import {useAuth0RolesContext} from '../../core/QueryResponseProvider'
import {toast} from 'react-hot-toast'

const RolesListGrouping = () => {
  const {selected, clearSelected} = useListView()
  const queryClient = useQueryClient()
  const {query} = useAuth0RolesContext()
  
  const deleteSelectedItems = useMutation(() => deleteSelectedRoles(selected), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      toast.success("Succesvol verwijderd")
      queryClient.invalidateQueries([`${QUERIES.ROLES_LIST}-${query}`])
      clearSelected()
    },
  })

  return (
    <div className='d-flex justify-content-end align-items-center'>
      <div className='fw-bolder me-5'>
        <span className='me-2'>{selected.length}</span>{' '}
        geselecteerd
      </div>

      <button
        type='button'
        className='btn btn-danger'
        onClick={async () => await deleteSelectedItems.mutateAsync()}
      >
        Verwijder geselecteerde
      </button>
    </div>
  )
}

export {RolesListGrouping}

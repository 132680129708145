/* eslint-disable jsx-a11y/anchor-is-valid */
import {EkoCard, EkoCardBody} from '../../../_metronic/helpers'
import {PageTitle} from "../../../_metronic/layout/core";
import React from "react";
import {EkosietKlantenserviceWidget} from "../widgets/EkosietKlantenserviceWidget";

export function QuoteRequested() {
    return (
        <>
            <PageTitle >Offerte aangevraagd</PageTitle>
            <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
                <div className='col-md-6 col-lg-6 col-xl-6 mb-md-5 mb-xl-10'>
                    <EkoCard>
                        <EkoCardBody>
                            <p>
                                Bedankt voor uw aanvraag. We hebben de offerte naar uw mailadres verzonden. De offerte is 60 dagen geldig.
                            </p>
                            <p>

                            </p>
                            <p>
                                Met vriendelijke groet,<br/>
                                Ekosiet Products BV
                            </p>

                        </EkoCardBody>
                    </EkoCard>
                </div>
                <div className='col-xxl-6'>
                    <EkosietKlantenserviceWidget className=''/>
                </div>
            </div>

        </>
    )
}

import React from "react";
import { PageTitle } from "../../../../../_metronic/layout/core";
import { useParams } from "react-router-dom";
import { TransportPlanningByWeekAndOrder } from "../expedition/components/TransportPlanningByWeekAndOrder";

const TransportWeekPlanning: React.FC = () => {

    const { year, week } = useParams<{ year: string, week: string }>();

    return (
        <>
            <PageTitle>
                {`Transport - Week ${year}-${week}`}
            </PageTitle>
            
            <TransportPlanningByWeekAndOrder />
        </>
    )
}

export default TransportWeekPlanning

import {Button, Modal} from 'react-bootstrap';
import {NeutEditor, NeutEditorOptions} from './NeutEditor';
import React, {FC} from 'react';
import {SillConfigurationNeut} from '../../../modules/api-client/generated';
import {modalsRoot} from '../../../global-helpers';
import SpinnerButton from '../SpinnerButton';

type NeutModalProps = {
	show: boolean;
	title: string | null | undefined;
	handleClose: () => void;
	editorOptions: NeutEditorOptions;
	confirmBtnText?: string;
};

export const NeutModal: FC<NeutModalProps> = (props) => {
	return (
		<Modal
			container={modalsRoot}
			tabIndex={-1}
			aria-hidden="true"
			autoFocus={true}
			dialogClassName={'modal-dialog modal-lg modal-dialog-centered'}
			contentClassName={'shadow-lg'}
			show={props.show}
			size="lg"
			onHide={() => props.handleClose()}
			animation={false}
			backdrop={'static'}
		>
			<NeutModalContent {...props} />
		</Modal>
	);
};

const NeutModalContent: FC<NeutModalProps> = (props) => {
	const setResult = (value: SillConfigurationNeut | null) => {
		props.editorOptions.onChange(value);
		props.handleClose();
	};

	return (
		<NeutEditor {...props.editorOptions}>
			{({submit, isSubmitting, isInitializing, isOmitted, addNeut, content}) => (
				<>
					<Modal.Header closeButton>
						<Modal.Title>{props.title}</Modal.Title>
					</Modal.Header>
					<Modal.Body>{content}</Modal.Body>
					<Modal.Footer className="justify-content-between">
						<div>
							{props.editorOptions.canOmit && !isOmitted && (
								<Button type="button" variant="secondary" className="mx-4" onClick={() => setResult(null)}>
									Neut weglaten
								</Button>
							)}
						</div>
						<div>
							{!isOmitted && (
								<>
									<Button variant="link" className="mx-4" onClick={props.handleClose} disabled={isInitializing}>
										Annuleren
									</Button>
									<SpinnerButton type="submit" className="btn btn-primary" spinning={isSubmitting} disabled={isInitializing}>
										{props.confirmBtnText ?? 'Toepassen'}
									</SpinnerButton>
								</>
							) || <button type="button" className="btn btn-primary" onClick={() => addNeut()}>Neut toevoegen</button>}
						</div>
					</Modal.Footer>
				</>
			)}
		</NeutEditor>
	);
};

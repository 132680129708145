/* eslint-disable jsx-a11y/anchor-is-valid */
import {Link} from 'react-router-dom'
import {EkoCard, EkoCardBody} from '../../../_metronic/helpers'
import {OrderTable} from "../components/OrderTable";
import {PageTitle} from "../../../_metronic/layout/core";
import React from "react";
import {CustomerRoutePath} from "../routing/RoutePath";

export function Orders() {
    return (
        <>
            <PageTitle toolbar={
                <Link to={CustomerRoutePath.link(CustomerRoutePath.orderStart)} className='btn btn-primary align-self-center'>Nieuwe bestelling</Link>
            }>Bestellingen</PageTitle>
          <EkoCard>
            <EkoCardBody>
              <OrderTable items={100}/>
            </EkoCardBody>
          </EkoCard>
        </>
    )
}

import {useAuth0} from '../core/Auth'

export function Login() {
  const {loginWithRedirect} = useAuth0()
  return (
    <>      
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Inloggen</h1>        
      </div>           

      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          onClick={() => {
            loginWithRedirect({
              screen_hint: 'login',
            })
          }}
        >
          <span className='indicator-label'>Volgende</span>          
        </button>
      </div>
      
      <div className='text-gray-500 text-center fw-semibold fs-6'>
        Nog geen klant?{' '}        
        <a href='https://www.ekosiet.nl/kozijnindustrie/bestellen' className='link-primary'>
            Vraag een account hier aan.
        </a>      
      </div>
    </>
  )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import {EkoCard, EkoCardBody} from '../../../_metronic/helpers'
import {PageTitle} from "../../../_metronic/layout/core";
import React from "react";
import {EkosietKlantenserviceWidget} from "../widgets/EkosietKlantenserviceWidget";

export function OrderPlaced() {
    return (
        <>
            <PageTitle >Bedankt voor uw bestelling</PageTitle>
            <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
                <div className='col-md-6 col-lg-6 col-xl-6 mb-md-5 mb-xl-10'>
                    <EkoCard>
                        <EkoCardBody>
                            <p>
                                U ontvangt een bevestiging in uw mailbox. Controleer de opdrachtbevestiging goed en geef eventuele correcties zo spoedig mogelijk aan ons door.
                            </p>
                            <p>

                            </p>
                            <p>
                                Met vriendelijke groet,<br/>
                                Ekosiet Products BV
                            </p>

                        </EkoCardBody>
                    </EkoCard>
                </div>
                <div className='col-xxl-6'>
                    <EkosietKlantenserviceWidget className=''/>
                </div>
            </div>

        </>
    )
}

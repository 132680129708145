import {useQuery, useQueryClient} from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import {NotFound, ValidationFailed} from "../../../../modules/api-client/Responses";
import {useEkosietMutation} from "../../../../shared/hooks/useEkosietMutation";
import {
    CornerPiece,
     CreateCornerPieceCommand,
     UpdateCornerPieceCommand
} from "../../../../modules/api-client/generated";
import {FormValues} from "./CornerPieceUpsert";


export const useCornerPieceUpsertMutation = (id?: string | undefined) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<CornerPiece, NotFound | ValidationFailed, FormValues>((values) => {
            const command: CreateCornerPieceCommand | UpdateCornerPieceCommand = {
                code: values.code,
                active: values.active,
                name: values.name,
                glazingBarId: values.glazingBarId,
                width: values.width,
                contour: values.contour,
            };

            if (id) {
                return ApiClient.Pim.CornerPiece.pimCornerPiecesIdPut(id, undefined, command).then(response => response.data);
            }
            return ApiClient.Pim.CornerPiece.pimCornerPiecesPost(undefined, command).then(response => response.data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['pimCornerpieceIdGet', id]).then();
                queryClient.invalidateQueries(['pimCornerPiecesGet']).then();
            }
        }
    );
};

export const useCornerPieceQuery = (id: string | undefined) => {
    return useQuery(['pimCornerpieceIdGet', id],
        () => {
            return ApiClient.Pim.CornerPiece.pimCornerPiecesIdGet(id!).then(res => res.data);
        }, {
            useErrorBoundary: true,
            enabled: !!id,
        });
}


export const useCornerPieceDeleteMutation = (id: string) => {
    const queryClient = useQueryClient()
    return useEkosietMutation<void, NotFound | ValidationFailed, string>((id) => {
            return ApiClient.Pim.CornerPiece.pimCornerPiecesIdDelete(id, undefined).then(response => response.data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['pimCornerpieceIdGet', id]).then();
                queryClient.invalidateQueries(['pimCornerPiecesGet']).then();
            }
        }
    );
};




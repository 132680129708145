import React from 'react';
import {
    SillDetails,
    SillDetailsCompartmentLayout,
    SillDetailsNeutPositionCompartmentLayoutHefSchuifA,
    SillDetailsNeutPositionCompartmentLayoutStandard
} from "../../../../../modules/api-client/generated";
import {EkoCard, EkoCardBody, EkoCardHeader, EkoCardToolbar} from "../../../../../shared/components/card";
import {EkoTable, TableHeader} from "../../../../../shared/components/table";
import {Mm} from "../../../../../shared/components";
import {PimRoutePath} from "../../PimRoutes";
import {Link} from "react-router-dom";
import {useSillNeutPositionsManage} from "../hooks/use-sill-neut-positions-manage";
import {CurrentValue} from "../hooks/use-neut-position-compartment-layout-modal";

interface SillDetailsNeutPositionsCardProps {
    sill: SillDetails;
}

export const SillDetailsNeutPositionsCard: React.FC<SillDetailsNeutPositionsCardProps> = ({sill}) => {

    const {
        openAddModal,
        addModalElement,
        openEditModel,
        editModalElement,
        confirmRemove,
        getAvailableCompartmentLayouts,
        openCompartmentLayoutModal,
        compartmentLayoutModalElement,
        confirmRemoveCompartmentLayout
    } = useSillNeutPositionsManage(sill);

    return <EkoCard className={'mb-3'}>
        <EkoCardHeader title={'Neut posities'}>
            <EkoCardToolbar>
                <button type="button" className="btn btn-primary" onClick={openAddModal}>Toevoegen</button>
            </EkoCardToolbar>
        </EkoCardHeader>
        <EkoCardBody>

            {addModalElement}
            {editModalElement}
            {compartmentLayoutModalElement}

            <div className="row">
                <div className="col-12 col-md-6 col-xl-4">
                    <div className="mb-5">
                        {sill.compartmentOptions.neutPositions.map((p) => {

                            const availableCompartmentLayouts: SillDetailsCompartmentLayout[] = getAvailableCompartmentLayouts(p);

                            return <div key={`${p.indention.inside}-${p.indention.outside}`} className="mb-5">
                                <h2 className="border m-0 mb-4 py-4 px-3 bg-light d-flex justify-content-between align-items-center">
                                    <div>
                                        {p.name} <small className="text-muted"><Mm value={p.indention.inside}/> / <Mm
                                        value={p.indention.outside}/></small>
                                    </div>
                                    <div>
                                        <div className="dropdown">
                                            <a className="dropdown-toggle text-body px-2" href="#" role="button"
                                               data-bs-toggle="dropdown" aria-expanded="false">
                                            </a>

                                            <ul className="dropdown-menu dropdown-menu-end">
                                                <li>
                                                    <button type="button" className="dropdown-item"
                                                            onClick={() => openEditModel(p)}>
                                                        Wijzigen
                                                    </button>
                                                </li>

                                                <li className="dropdown-divider"></li>

                                                <li>
                                                    <button type="button" className="dropdown-item text-danger"
                                                            onClick={async () => await confirmRemove(p.id)}>
                                                        Verwijderen
                                                    </button>
                                                </li>
                                            </ul>

                                        </div>
                                    </div>
                                </h2>

                                <EkoTable>
                                    <TableHeader>
                                        <th>Vakindeling</th>
                                        <th>Standaard profiel</th>
                                        <th className="text-end">Profielen</th>
                                    </TableHeader>
                                    <tbody>
                                    {p.compartmentLayouts.map((cl) => {

                                        const search = new URLSearchParams();
                                        search.append('neutPositionId', p.id);
                                        search.append('compartmentLayoutTypeId', cl.compartmentLayoutTypeId);

                                        const compartmentLayout = sill.compartmentLayouts.find(x => x.id == cl.compartmentLayoutTypeId)!;

                                        const manageProfilesPath = {
                                            pathname: PimRoutePath.path(PimRoutePath.sillProfiles, {id: sill.id}),
                                            search: search.toString()
                                        };

                                        let value: CurrentValue;

                                        switch (cl.$type) {
                                            case 'standard': {
                                                value = {
                                                    $type: 'standard',
                                                    defaultProfile: (cl as SillDetailsNeutPositionCompartmentLayoutStandard).defaultProfile
                                                };
                                                break;
                                            }

                                            case 'hsa':
                                            case 'hsd':
                                            case 'hse': {
                                                value = {
                                                    $type: 'hsade',
                                                    defaultProfileInside: (cl as SillDetailsNeutPositionCompartmentLayoutHefSchuifA).defaultProfileInside,
                                                    defaultProfileOutside: (cl as SillDetailsNeutPositionCompartmentLayoutHefSchuifA).defaultProfileOutside
                                                };
                                                break;
                                            }
                                            case 'hsc': {
                                                value = {
                                                    $type: 'hsc',
                                                    defaultProfileOutside: (cl as SillDetailsNeutPositionCompartmentLayoutHefSchuifA).defaultProfileOutside
                                                };
                                                break;
                                            }

                                            default:
                                                throw new Error('Unknown compartment layout type');
                                        }


                                        return <tr key={cl.compartmentLayoutTypeId}>
                                            <td>{cl.compartmentLayoutTypeName}</td>
                                            <td>
                                                {'defaultProfile' in cl && <div>
                                                    {cl.defaultProfile.name}
                                                </div>}
                                                {'defaultProfileInside' in cl && <div>
                                                    {cl.defaultProfileInside.name}
                                                </div>}
                                                {'defaultProfileOutside' in cl && <div>
                                                    {cl.defaultProfileOutside.name}
                                                </div>}
                                            </td>
                                            <td className="text-end">
                                                <Link to={manageProfilesPath}>{cl.profileCount} profielen</Link>
                                            </td>
                                            <td>
                                                <div className="dropdown">
                                                    <a className="dropdown-toggle text-body px-2" href="#" role="button"
                                                       data-bs-toggle="dropdown" aria-expanded="false">
                                                    </a>

                                                    <ul className="dropdown-menu dropdown-menu-end">
                                                        <li>
                                                            <button type="button" className="dropdown-item"
                                                                    onClick={() => openCompartmentLayoutModal(p, compartmentLayout, value)}>
                                                                Wijzigen
                                                            </button>
                                                        </li>

                                                        <li className="dropdown-divider"></li>

                                                        <li>
                                                            <button type="button" className="dropdown-item text-danger"
                                                                    onClick={async () => await confirmRemoveCompartmentLayout(p,
                                                                        cl.compartmentLayoutTypeId)}>
                                                                Verwijderen
                                                            </button>
                                                        </li>
                                                    </ul>

                                                </div>
                                            </td>
                                        </tr>
                                    })}
                                    </tbody>
                                </EkoTable>

                                <div className="dropdown">
                                    <button className="btn btn-outline-primary btn-sm dropdown-toggle" role="button"
                                            data-bs-toggle="dropdown" aria-expanded="false">Vakindeling toevoegen
                                    </button>

                                    <ul className="dropdown-menu dropdown-menu-end">
                                        {availableCompartmentLayouts.map((cl) => {
                                            return <li key={cl.id}>
                                                <button type="button" className="dropdown-item"
                                                        onClick={() => {
                                                            openCompartmentLayoutModal(p, cl, null);
                                                        }}>
                                                    {cl.name}
                                                </button>
                                            </li>
                                        })}
                                    </ul>

                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </EkoCardBody>
    </EkoCard>
};




import {useQuery, useQueryClient} from "@tanstack/react-query";
import * as api from "../../../../modules/api-client/generated";
import ApiClient from "../../../../modules/api-client/ApiClient";
import {NotFound, ValidationFailed} from "../../../../modules/api-client/Responses";
import {useEkosietMutation} from "../../../../shared/hooks/useEkosietMutation";
import {
    CreateStopProfileCommand, UpdateStopProfileCommand
} from "../../../../modules/api-client/generated";
import {FormValues} from "./StopProfileUpsert";


export const useStopProfileUpsertMutation = (id?: string | undefined) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<api.StopProfile, NotFound | ValidationFailed, FormValues>((values) => {
            const command: CreateStopProfileCommand | UpdateStopProfileCommand = {
                code: values.code,
                active: values.active,
                name: values.name,
                height: values.height,
                minWidth: values.minWidth,
                defaultWidth: values.defaultWidth,
                mountingSlotPosition: values.mountingSlotPosition,
                stopPosition: values.stopPosition,
                rabbetWidth: values.rabbetWidth,
                compartmentLayoutTypeIds: values.compartmentLayoutTypeIds
            };

            if (id) {
                return ApiClient.Pim.StopProfile.pimStopProfilesIdPut(id, undefined, command).then(response => response.data);
            }
            return ApiClient.Pim.StopProfile.pimStopProfilesPost(undefined, command).then(response => response.data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['pimStopProfilesIdGet', id]).then();
                queryClient.invalidateQueries(['pimStopProfilesGet']).then();
            }
        }
    );
};

export const useStopProfileQuery = (id: string | undefined) => {
    return useQuery(['pimStopProfilesIdGet', id],
        () => {
            return ApiClient.Pim.StopProfile.pimStopProfilesIdGet(id!).then(res => res.data);
        }, {
            useErrorBoundary: true,
            enabled: !!id,
        });
}

export const useStopProfileDeleteMutation = (id: string) => {
    const queryClient = useQueryClient()
    return useEkosietMutation<void, NotFound | ValidationFailed, string>((id) => {
            return ApiClient.Pim.StopProfile.pimStopProfilesIdDelete(id, undefined).then(response => response.data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['pimStopProfilesIdGet', id]).then();
                queryClient.invalidateQueries(['pimStopProfilesGet']).then();
            }
        }
    );
};




import React from 'react';
import {Field} from 'formik';
import {useQuery} from '@tanstack/react-query';
import ApiClient from '../../../../modules/api-client/ApiClient';
import CustomSelect, {SelectOption, OptionsType} from '../../../../shared/components/CustomSelect';
import {FieldProps} from 'formik/dist/Field';
import {CompartmentLayoutType} from '../../../../modules/api-client/generated';
import {QueryKey} from "@tanstack/query-core/src/types";


export type CompartmentLayoutTypePickerOptions = {
    required?: boolean;
    disabled?: boolean;
    isMulti?: boolean;
    optionFilter?: (compartmentLayoutType: CompartmentLayoutType) => boolean;
    query?: CompartmentLayoutTypePickerQuery;
}

export type CompartmentLayoutTypePickerQuery = {
    fn: () => Promise<CompartmentLayoutType[]>;
    key: QueryKey;
}

const DefaultQuery: CompartmentLayoutTypePickerQuery = {
    fn: () => ApiClient.Pim.CompartmentLayoutType.pimCompartmentLayoutTypesGet().then((res) => res.data),
    key: ['ApiClient.Pim.CompartmentLayoutType.pimCompartmentLayoutTypesGet']
}

export const CompartmentLayoutTypePicker = ({
                                                required,
                                                disabled,
                                                isMulti,
                                                optionFilter,
                                                query
                                            }: CompartmentLayoutTypePickerOptions): React.ComponentType<FieldProps<string>> => {

    let options: OptionsType<SelectOption> = [];

    query ??= DefaultQuery;

    const {data: list} = useQuery<CompartmentLayoutType[]>(query.key, query.fn);

    if (list) {

        let l = list;
        if (optionFilter) {
            l = l.filter(optionFilter);
        }
        options = l.map((item) => {
            return {value: item.id!, label: item.name!};
        });
    }

    required ??= true;

    return (props: FieldProps<string>) => <CustomSelect<SelectOption>
        options={options} isMulti={isMulti ?? false} required={required}
        isClearable={!required}
        placeholder={'Selecteer...'} {...props} />;
};


export type CompartmentLayoutTypePickerFieldProps = {
    name: string;
    multi?: boolean;
    required?: boolean;
    sillId?: string;
}
export const CompartmentLayoutTypePickerField: React.FC<CompartmentLayoutTypePickerFieldProps> = ({
                                                                                                      name,
                                                                                                      multi, sillId,
                                                                                                      required,
                                                                                                  }) => {

    let options: OptionsType<SelectOption> = [];

    const {data: list} = useQuery<CompartmentLayoutType[]>(['pimCompartmentLayoutTypeGet'],
        () => ApiClient.Pim.CompartmentLayoutType.pimCompartmentLayoutTypesGet(sillId).then((res) => res.data));

    if (list) {
        options = list.map((item) => {
            return {value: item.id!, label: item.name!};
        });
    }

    return (
        <>
            <Field
                className='custom-select'
                name={name}
                options={options}
                component={
                    CustomSelect
                }
                placeholder={multi ? 'Selecteer vakindelingen...' : 'Selecteer een vakindeling'}
                isClearable={!required}
                isMulti={multi}
            />
        </>
    );
};
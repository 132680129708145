/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {NavLink, Outlet} from "react-router-dom";
import {EkoCard, EkoCardBody, EkoCardHeader} from "../../../../../shared/components/card";
import {CrmRoutePath} from "../../CrmRoutes";

export function CustomerImport() {
    return (
        <EkoCard>
            <EkoCardHeader title={'Import'}>
            </EkoCardHeader>
            <EkoCardBody>
                <div className={'row'}>
                    <div className={'col-2'}>
                        <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                            <li className="nav-item mt-2">
                                <NavLink to={CrmRoutePath.customerImportProfileMapping}
                                         className="nav-link text-active-primary ms-0 me-10 py-5">
                                    Profiel mapping
                                </NavLink>
                            </li>
                            <li className="nav-item mt-2">
                                <NavLink to={CrmRoutePath.customerImportSluitpotMapping}
                                         className="nav-link text-active-primary ms-0 me-10 py-5">
                                    Sluitpot mapping
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className={'col'}>
                        <Outlet/>
                    </div>
                </div>
            </EkoCardBody>
        </EkoCard>
    )
}


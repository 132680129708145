import {FC} from 'react'

type Props = {
  user_metadata?: {
    customerId?: string
    employeeId?: string
  }
}

const AccountTypeCell: FC<Props> = ({user_metadata}) => {

  let accountType = user_metadata?.customerId != null ? "klant" :  "medewerker" 

  return (
          <>
            <div
              className={`badge badge-light-${
                accountType === 'klant' ? 'primary' : accountType === 'medewerker' ? 'info' : 'danger'
              } fw-bolder text-black`}
              data-customer-id={user_metadata?.customerId}              
            >
              {accountType != null ? accountType : 'Geen Account Type'}
            </div>
          </>
        )
}

export {AccountTypeCell}

import {ID, Response} from '../../../../../../_metronic/helpers'
export type User = {
  userId?: ID
  name?: string
  fullName?: string
  picture?: string
  email?: string
  userMetadata?: {
    customerId?: string
  }  
  emailVerified?: boolean
  blocked?: boolean
  roleIds?: any  
  connection?: string
  lastLogin?: string
  two_steps?: boolean
  joined_day?: string
  online?: boolean
  newPassword?: string
  roles?: []
  passwordConfirmation?: string
  initials?: {
    label: string
    state: string
  },
  accountType?: 'customer' | 'employee'
  customerId?: string  
}

export type Role = {
  id?: string
  name?: string
  description?: string
}

export type UsersQueryResponse = Response<Array<User>>

export const initialUser: User = {  
  email: '',
  userMetadata: {
    customerId: undefined,    
  },
  accountType: "customer"  
}

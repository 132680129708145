/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { EkoCard, EkoCardBody } from "../../../_metronic/helpers";
import { OrderTable } from "../components/OrderTable";

type Props = {
    className: string
    limit?: number
    customerId?: string
}

const OrderListDashboardWidget: React.FC<Props> = ({ className, limit = 6, customerId }) => {
    return (
        <>
            <EkoCard>
                <div className='card-header border-0 '>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold text-dark'>Uw orders</span>
                    </h3>
                    <div className='card-toolbar'>
                    </div>
                </div>
                <EkoCardBody>
                    <OrderTable items={10} />
                </EkoCardBody>
            </EkoCard>
        </>

    )
}

export { OrderListDashboardWidget }

import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast"
import ApiClient from "../../../../../modules/api-client/ApiClient";
import {
    OrderRepresentation,
} from "../../../../../modules/api-client/generated";
import { useEkosietMutation } from "../../../../../shared/hooks/useEkosietMutation";
import { NotFound, ValidationFailed } from "../../../../../modules/api-client/Responses";

export const usePutQuoteAccepted = (id: string) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<OrderRepresentation, NotFound | ValidationFailed, undefined>(() => {
        return ApiClient.Sm.Order.smOrderIdAcceptQuotePut(id, undefined, undefined).then();
    },
    {
        onSuccess: () => {
            queryClient.invalidateQueries(['smOrderIdGet', id]).then();
            queryClient.invalidateQueries(['OrderActionLog', id]).then();
            toast.success("Offerte geaccepteerd");

        },
        onError: () => {
            toast.error("Mislukt!");
        }
    }
    );
};

import { FC, useState } from 'react';
import {
	SillConfigurationCompartment,
	SillConfigurationSluitpot,
} from '../../../modules/api-client/generated';
import { useFormikContext } from 'formik';
import { CompartmentEditorOptions } from './CompartmentEditor';
import { TableHeader } from '../table/TableHeader';
import { EkoTable } from '../table/EkoTable';
import { useConfirmDialog } from '../ConfirmDialog';
import { SluitpotEditorProps } from './SluitpotEditor';
import { SluitpotModal } from './SluitpotModal';

export type SluitpotTableProps = CompartmentEditorOptions & {
	baseEditorOptions: Omit<SluitpotEditorProps, 'onChange'>;
};

export const SluitpotTable: FC<SluitpotTableProps> = (props) => {
	const { values, setValues } = useFormikContext<SillConfigurationCompartment>();

	const confirm = useConfirmDialog();

	const [editting, setEditting] = useState<number | null>(null);

	const deleteSluitpot = async (index: number) => {
		await setValues((current) => {
			console.log(current.sluitpotten);

			if (!current.sluitpotten) return current;

			const update = { ...current };
			update.sluitpotten!.splice(index, 1);
			return update;
		});
	};

	const onDelete = async (index: number) => {
		await confirm({
			title: 'Sluitpot verwijderen',
			message: 'Je staat op het punt de sluitpot te verwijderen. Wil je doorgaan?',
			confirmText: 'Verwijderen',
			onConfirm: async () => await deleteSluitpot(index),
		});
	};

	const onChange = async (index: number, sluitpot: SillConfigurationSluitpot) => {
		await setValues((current) => {
			const update = { ...current };
			update.sluitpotten!.splice(index, 1, sluitpot);
			return update;
		});

		setEditting(null);
	};

	return (
		<>
			<EkoTable>
				<TableHeader>
					<th>Sluitpot</th>
					<th className="fit-content text-end">Positie</th>
					<th className="text-nowrap text-center">Beslag meeleveren?</th>
					<th></th>
				</TableHeader>
				<tbody>
					{values.sluitpotten &&
						values.sluitpotten.map((sluitpot, index) => (
							<tr key={`sluitpotten_table_row_${index}`}>
								<td>
									<div className="d-flex">
										<div style={{ height: '22px', width: '36px' }}>
											{props.sluitpotHardware[sluitpot.sluitpotBeslagId]?.imageUrl && (
												<img
													style={{ height: '100%', width: '100%', objectFit: 'contain' }}
													src={props.sluitpotHardware[sluitpot.sluitpotBeslagId]?.imageUrl ?? ''}
													alt=""
												/>
											)}
										</div>

										<span className="ms-2">{props.sluitpotHardware[sluitpot.sluitpotBeslagId]?.name}</span>
									</div>
								</td>
								<td className="fit-content text-end">
									X={sluitpot.position.x}, Y={sluitpot.position.y}mm
								</td>
								<td className="fit-content text-center">{sluitpot.includeHardware ? 'Ja' : 'Nee'}</td>
								<td className="fit-content text-end">
									<button type="button" className="btn btn-link btn-sm mx-1" onClick={() => setEditting(index)}>
										<i className="fas fa-pen"></i>
									</button>
									<SluitpotModal
										show={editting === index}
										handleClose={() => setEditting(null)}
										title="Sluitpot aanpassen"
										editorOptions={{
											...props.baseEditorOptions,
											value: sluitpot,
											onChange: (value) => onChange(index, value),
										}}
									/>
									<button type="button" className="btn btn-link btn-sm mx-1" onClick={() => onDelete(index)}>
										<i className="fas fa-trash"></i>
									</button>
								</td>
							</tr>
						))}
				</tbody>
			</EkoTable>
		</>
	);
};

import { CustomerModalForm } from './CustomerModalForm'
import { useGetCustomer } from '../hooks/use-get-customer'

type Props = {  
  customerId: string | undefined | null,
  handleClose: () => void,
}

const CustomerModalFormWrapper = ({customerId, handleClose}: Props) => {
  
  const {data, isLoading, error} = useGetCustomer(customerId || "")

  if (customerId) {    
    if (!isLoading && !error && data) {
      return <CustomerModalForm isCustomerLoading={isLoading} customer={data} handleClose={handleClose} />
    }    
  } else {
    return (
      <CustomerModalForm isCustomerLoading={false} customer={null} handleClose={handleClose} />
    )
  }

  return null;
}

export {CustomerModalFormWrapper}

import React from "react";


export interface MmProps {
    value: number | undefined | null;
}

export const Mm: React.FC<MmProps> = ({...props}) => {

    if (typeof props.value === 'undefined' || props.value === null) {
        return <></>;
    }

    return <>{props.value}<small>mm</small></>
};


import React from "react";
import {PageTitle} from "../../../../../_metronic/layout/core";

import {EkoCardBody} from "../../../../shared/components/card/EkoCardBody";
import {EkoCard} from "../../../../shared/components/card/EkoCard";
import {EkoTable} from "../../../../shared/components/table/EkoTable";
import {TableHeader} from "../../../../shared/components/table/TableHeader";

import {usePutBatchFinished} from "../hooks/use-put-batch-finished";
import {useListProductionBatchesOdata} from "../hooks/use-list-production-batches-odata";
import {ProductionBatchStatusCodes, ProductionBatchTypes} from "../../../../modules/api-client/generated";
import ProductionBatchStatus from "../../../../shared/components/ProductionBatchStatus";
import {usePutBatchStarted} from "../hooks/use-put-batch-start";
import ProductionBatchType from "../../../../shared/components/ProductionBatchType";
import SpinnerButton from "../../../../shared/components/SpinnerButton";
import UrgentBadge from "../../../../shared/components/UrgentBadge";

const ProductionBatchStationList: React.FC = () => {

    const {
        data: list,
    } = useListProductionBatchesOdata(150,0,undefined,"(Status eq 'Accepted') or (Status eq 'Started')");

    const batchFinished = usePutBatchFinished()
    const batchStarted = usePutBatchStarted()

    const start = (batchId: string) => {
        return new Promise<void>(() => {
            batchStarted.mutate(batchId, {});
        }).finally();
    }
    const finish = (batchId: string) => {
        return new Promise<void>(() => {
            batchFinished.mutate(batchId, {});
        }).finally();
    }

    return (
        <>
            <PageTitle>Batches</PageTitle>
            <EkoCard>
                <EkoCardBody>
                    {list?.value.length == 0 ?
                        <>Geen open batches!</>
                        :
                        <>
                            <EkoTable>
                                <TableHeader>
                                    <th className='min-w-40px'>Batch</th>
                                    <th className='min-w-40px'>Spoed</th>
                                    <th className='min-w-50px text-center'>Type</th>
                                    <th className='min-w-50px text-center'>Status</th>
                                    <th className='min-w-50px'></th>
                                    <th className='min-w-50px'></th>
                                </TableHeader>
                                <tbody>

                                {list?.value.map((batch) => {
                                    return (
                                        <tr key={batch.id}>
                                            <td>
                                                <h5>{batch.title} {batch.code}</h5>
                                                {batch.type == ProductionBatchTypes.Latten &&
                                                    <span className={'me-1'}>
                                                        {batch.lattenCount} latten
                                                    </span>
                                                }
                                                {batch.type == ProductionBatchTypes.Neuten &&
                                                    <span className={'me-1'}>
                                                        {batch.neutCount} neuten
                                                    </span>
                                                }
                                                {batch.type == ProductionBatchTypes.Dorpel &&
                                                    <span>{batch.itemsCount} dorpels</span>
                                                }
                                            </td>
                                            <td className='text-start'>
                                                <UrgentBadge value={batch.urgent}/>
                                            </td>
                                            <td className='text-center'>
                                                <ProductionBatchType type={batch.type}/>
                                            </td>
                                            <td className='text-center'>
                                                <ProductionBatchStatus status={batch.status}/>
                                            </td>
                                            <td>
                                                {batch.remark &&
                                                    <span>
                                                    &nbsp;| {batch.remark}
                                                </span>
                                                }
                                            </td>

                                            <td className='text-end'>
                                                {batch.status === ProductionBatchStatusCodes.Accepted &&
                                                    <SpinnerButton className={'btn btn-info me-3'}
                                                            onClick={() => window.confirm('Weet je het zeker?') && start(batch.id)}>Starten</SpinnerButton>
                                                }
                                                {batch.status !== ProductionBatchStatusCodes.Accepted && batch.status !== ProductionBatchStatusCodes.Drafted && batch.status !== ProductionBatchStatusCodes.Cancelled && batch.status !== ProductionBatchStatusCodes.Finished && batch.status !== ProductionBatchStatusCodes.Failed &&
                                                    <SpinnerButton className={'btn btn-success me-3'}
                                                            onClick={() => window.confirm('Weet je het zeker?') && finish(batch.id)}>Gereed
                                                        melden</SpinnerButton>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })}

                                </tbody>
                            </EkoTable>
                        </>
                    }

                </EkoCardBody>
            </EkoCard>
        </>
    )
}

export default ProductionBatchStationList

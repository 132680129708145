/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {
    ExpeditionStatusCodes, OrderStatusCodes,
} from "../../../../../../modules/api-client/generated";

import {
    usePutExpeditionCancelled,
    usePutExpeditionDelivered,
    usePutExpeditionInTransit,
    usePutExpeditionNotPlanned,
    usePutExpeditionPlanned,
    usePutExpeditionReadyForShipment
} from "../../hooks/use-put-expedition-status";
import {ExpeditionStatus} from "../../../../../../shared/components/ExpeditionStatus";

type Props = {
    orderId: string
    status?: string | null
    className?: string
}
const OrderExpeditionStatus: React.FC<Props> = ({status,className,orderId}) => {
    
    const notPlannedMutation = usePutExpeditionNotPlanned(orderId)
    const plannedMutation = usePutExpeditionPlanned(orderId)
    const inTransitMutation = usePutExpeditionInTransit()
    const deliveredMutation = usePutExpeditionDelivered()
    const cancelledMutation = usePutExpeditionCancelled(orderId)
    const readyForShipmentMutation = usePutExpeditionReadyForShipment()

    if (!status || !orderId) return (<></>)

    const setNotPlanned = () => {
        return new Promise<void>(() => {
            notPlannedMutation.mutate(undefined, {});
        }).finally();
    }
    const setPlanned = () => {
        return new Promise<void>(() => {
            plannedMutation.mutate(undefined, {});
        }).finally();
    }    
    const setInTransit = (orderId: string) => {
        return new Promise<void>(() => {
            inTransitMutation.mutate(orderId, {});
        }).finally();
    }
    const setDelivered = (orderId: string) => {
        return new Promise<void>(() => {
            deliveredMutation.mutate(orderId, {});
        }).finally();
    }

    const setReadyForShipment = (orderId: string) => {
        return new Promise<void>(() => {
            readyForShipmentMutation.mutate(orderId, {});
        }).finally();
    }

    const setCancelled = () => {
        return new Promise<void>(() => {
            cancelledMutation.mutate(undefined, {});
        }).finally();
    }

    return (
        <>
                <div className="dropdown me-3">
                    <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown"
                            aria-expanded="false">
                        <ExpeditionStatus status={status} textOnly/>
                    </button>
                    <ul className="dropdown-menu">
                        <li><a href='#' className={`dropdown-item btn btn-${status === ExpeditionStatusCodes.NotPlanned ? 'warning disabled' : 'light'}`} onClick={()=>setNotPlanned()}>Niet gepland</a></li>
                        <li><a href='#' className={`dropdown-item btn btn-${status === ExpeditionStatusCodes.Planned ? 'info disabled' : 'light'}`}  onClick={()=>setPlanned()}>Gepland</a></li>
                        <li><a href='#' className={`dropdown-item btn btn-${status === ExpeditionStatusCodes.ReadyForShipment ? 'warning disabled' : 'light'}`}  onClick={()=>setReadyForShipment(orderId)}>Klaar voor verzending</a></li>
                        <li><a href='#' className={`dropdown-item btn btn-${status === ExpeditionStatusCodes.InTransit ? 'dark disabled' : 'light'}`}  onClick={()=>setInTransit(orderId)}>Onderweg</a></li>
                        <li><a href='#' className={`dropdown-item btn btn-${status === ExpeditionStatusCodes.Delivered ? 'success disabled' : 'light'}`}  onClick={()=>setDelivered(orderId)}>Afgeleverd</a></li>
                        <li><a href='#' className={`dropdown-item btn btn-${status === ExpeditionStatusCodes.Cancelled ? 'danger disabled' : 'light'}`}  onClick={()=>setCancelled()}>Geannuleerd</a></li>
                    </ul>
                </div>

        </>
    )
}


export {OrderExpeditionStatus}

import { FC } from 'react'
import * as Yup from 'yup'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import { usePutExpeditionDate } from "../../../hooks/use-put-expedition-date";
import { PutExpeditionDateRepresentation } from '../../../../../../../modules/api-client/generated';
import moment from 'moment';
import { DateField } from '../../../../../../../shared/components/date/DateField';


type Props = {
    id: string
    expeditionDate?: Date | null
    handleClose: () => void,
}

type FormValues = Omit<PutExpeditionDateRepresentation, 'expeditionDate'> & {
    expeditionDate?: Date | null;
    specificDate: 'date' | 'asap'
};

const PutExpeditionDateForm: FC<Props> = ({ id, expeditionDate, handleClose }) => {

    const mutation = usePutExpeditionDate(id);

    const initialValues: FormValues = {
        expeditionDate: expeditionDate ? new Date(expeditionDate) : new Date(),
        specificDate: expeditionDate ? 'date' : 'asap',
    }

    const schema = {
        specificDate: Yup.string().nullable().notRequired(),
        expeditionDate: Yup.date().nullable().notRequired()
    }
    const formSchema = Yup.object().shape(schema);

    const onSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {

        return new Promise<void>((resolve, reject) => {
            
            let payload : PutExpeditionDateRepresentation = {
                expeditionDate: values.expeditionDate ? moment(values.expeditionDate).format(moment.HTML5_FMT.DATE) : null
            }

            mutation.mutate(payload, {
                onSuccess: (model) => {
                    resolve();
                    handleClose()
                },
                onError: () => {
                    reject();
                }
            });

        }).finally(() => setSubmitting(false));
    };

    return (
        <>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={formSchema}>
                {({
                    values,
                    handleChange,
                    setFieldValue,                    
                    isSubmitting,
                }) => (
                    <Form>

                        <div className='d-flex flex-column mb-7 fv-row'>
                            <label className='d-flex align-items-center form-label'>
                                <span>Gewenste leverdatum</span>
                            </label>
                            <div className="form-check">
                                <Field type="radio"
                                    name="specificDate"
                                    value="asap"
                                    className="form-check-input mb-3 mb-lg-0"
                                    id="rbDeliveryConditionAsap"
                                    style={{ marginTop: '0.7rem' }}
                                    onChange={(e: any)=> {
                                        handleChange(e);                                         
                                        setFieldValue("expeditionDate", null)
                                      }}
                                />

                                <label className="form-check-label form-control form-control-solid mb-3 mb-lg-0'"
                                    htmlFor="rbDeliveryConditionAsap">
                                    Zo snel mogelijk (afhankelijk van productie planning)
                                </label>
                            </div>

                            <div className="form-check">
                                <Field type="radio"
                                    name="specificDate"
                                    value="date"
                                    className="form-check-input mb-3 mb-lg-0"
                                    id="rbDeliveryConditionDate"
                                    style={{ marginTop: '0.7rem' }} 
                                    onChange={(e: any)=> {
                                        handleChange(e);                                         
                                        setFieldValue("expeditionDate", values.expeditionDate || new Date())
                                      }}
                                    />
                                <label className="form-check-label form-control form-control-solid mb-3 mb-lg-0'"
                                    htmlFor="rbDeliveryConditionDate">
                                    Specifieke leverdatum (In geval van een week, kies een datum in betreffende week)
                                </label>
                            </div>
                            
                            {values.specificDate === 'date' &&
                                <DateField fieldName='expeditionDate' />
                            }

                        </div>

                        {/* begin::Actions */}
                        <div className='text-center pt-5'>
                            <button
                                type='reset'
                                className='btn btn-light me-3'
                                data-kt-customer-modal-action='cancel'
                                data-dismiss="modal"
                                onClick={() => handleClose()}
                                disabled={isSubmitting}
                            >
                                Annuleren
                            </button>

                            <button
                                type='submit'
                                className='btn btn-primary'
                                data-kt-customer-modal-action='submit'
                                disabled={isSubmitting}
                            >
                                <span className='indicator-label'>Opslaan
                                </span>
                                {isSubmitting && (
                                    <span className='indicator-progress'>
                                        Even wachten a.u.b
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                        {/* end::Actions */}

                    </Form>
                )}
            </Formik>
        </>
    )
}

export { PutExpeditionDateForm }

import {useQuery, useQueryClient} from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import * as api from "../../../../modules/api-client/generated";
import {NotFound, ValidationFailed} from "../../../../modules/api-client/Responses";
import {RabbetFormValues} from "./RabbetUpsert";
import {useEkosietMutation} from "../../../../shared/hooks/useEkosietMutation";
import {CreateRabbetCommand, RabbetUpdateModel} from "../../../../modules/api-client/generated";


function retryQuery(failureCount: number, error: NotFound | any) {
    return false;
}

export const useRabbetUpsertMutation = (id?: string | undefined) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<api.Rabbet, NotFound | ValidationFailed, RabbetFormValues>(async (values) => {

        console.log(values);
            if (id) {
                const response = await ApiClient.Pim.Rabbet.updateRabbet(id, undefined, values as RabbetUpdateModel);
                return response.data;
            }

            const createCommand = values as CreateRabbetCommand;
            const response1 = await ApiClient.Pim.Rabbet.createRabbet(undefined, createCommand);
            return response1.data;
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['pimRabbetsIdGet', id]).then();
            }
        }
    );
};

export const useRabbetDeleteMutation = (id: string) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<void, NotFound | ValidationFailed, string>(async (id) => {
            await ApiClient.Pim.Rabbet.deleteRabbet(id, undefined)
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['pimRabbetsIdGet', id]).then();
            }
        }
    );
};

export const useRabbetQuery = (id: string | undefined) => {
    return useQuery<api.Rabbet>(
        ['ApiClient.Pim.Rabbet.getRabbet', id],
        async () => {
            const res = await ApiClient.Pim.Rabbet.getRabbet(id!);
            return res.data;
        }, {
            useErrorBoundary: true,
            enabled: !!id,
            retry: retryQuery
        });
}
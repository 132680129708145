import clsx from 'clsx';
import {Dispatch, FC, SetStateAction} from 'react'
import {useFormikContext, getIn} from "formik";
import {ContactPersonRepresentation, OrderContactPersonRepresentation} from '../../modules/api-client/generated';
import Select, {components, ControlProps} from "react-select"
import {useEkosietAuth0} from "../../modules/auth0/core/useEkosietAuth0";
import {useListCustomerContactPersons} from "../../backoffice/modules/crm/hooks/use-list-customer-contact-persons";
import {IAddOrderForm} from "../../backoffice/modules/sm/orders/new-order/types/IAddOrderForm";

type Props= {
  onSelect: (contactPerson?:ContactPersonRepresentation)=>void;
  customerId: string;
  selectedEmail?: string;
  fieldName?: string;
}


export const CustomerContactPersonPicker : FC<Props> = ({onSelect,fieldName,customerId,selectedEmail}) => {

  const {user} = useEkosietAuth0();
  const {data: contactPersons} = useListCustomerContactPersons(customerId);

  let options: any[] = [{ value: '', label: 'Voer een nieuw contactpersoon op...' }];
  let userContactPersonId:string|undefined;
  let selectedContactPersonId:string|undefined;

  if (contactPersons) {
    let customerContactPersonOptions = contactPersons.map((item: ContactPersonRepresentation) => {
      return { value: item.id, label: `${item.email === user?.email ? '[JIJ]: ' : ''}${item.firstName} ${item.lastName} (${item.email})` };

    });

    options = [...options, ...customerContactPersonOptions];
    if (user) {
      userContactPersonId = contactPersons.find((item: ContactPersonRepresentation) => item.email === user.email)?.id;
    }
    if (selectedEmail) {
      selectedContactPersonId = contactPersons.find((item: ContactPersonRepresentation) => item.email === selectedEmail)?.id;
    }
  }

  let selectedValue = options.find((option: any) => option.value === selectedContactPersonId || option.value === userContactPersonId);
  return (
    <>
            <Select
                className={
              clsx(
                  'mb-4',
                )}
                classNamePrefix="select"
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 7,
                  padding: 5,
                })}
                classNames={{
                  control: (state) =>
                    clsx(
                      'form-control p-2 border-secondary',
                    ),
                  singleValue: (state) =>
                  {
                    return clsx(
                      'text-gray-700',
                        userContactPersonId === selectedContactPersonId ? 'fw-bold' : ''
                    )
                  }
                }}

                value={selectedValue}
                placeholder="Selecteer een contactpersoon"
                isClearable={false}
                isSearchable={true}
                name={fieldName??'contactPersonId'}
                options={options}
                onChange={(newValue) => {
                  onSelect(newValue ? contactPersons?.find((item: ContactPersonRepresentation) => item.id === newValue.value) : undefined)
                }}
              />

    </>
  )
}

interface IAlertProps {
  title: string
  message: string
  status: string
}
const UserAlert = ({title, message, status}: IAlertProps) => {
  const style: React.CSSProperties = {
    position: 'relative',
    pointerEvents: 'auto',
    overflow: 'hidden',
    margin: '0 0 6px',
    padding: '15px 15px 15px 50px',
    width: '300px',
    borderRadius: '3px 3px 3px 3px',
  }

  let color = status !== '' ? status : 'primary'
  return (
    <div
      style={{
        position: 'fixed',
        zIndex: '99999',
        pointerEvents: 'none',
        right: '12px',
        bottom: '12px',
      }}
    >
      <div
        style={{...style}}
        className={`alert alert-dismissible bg-light-${color} d-flex flex-column flex-sm-row p-5 mb-10`}
      >
        <div className='d-flex flex-column text-primary pe-0 pe-sm-10'>
          <h5 className='mb-1'>{title}</h5>
          <span>{message}</span>
        </div>
      </div>
    </div>
  )
}

export {UserAlert}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactElement } from 'react'
import { EkoCard, EkoCardBody, KTSVG } from "../../../../../../../_metronic/helpers";
import {
    OrderStatusCodes,
    ProductionStatusCodes
} from "../../../../../../modules/api-client/generated";
import { formatDate } from "../../../../../../shared/components/date";
import { PutProductionDateForm } from "./forms/PutProductionDateForm";
import { ProductionItemTable } from "../../../../pm/components/ProductionItemTable";
import { FilterParameter } from "../../../../../../shared/context/FilterContext";
import buildQuery from "odata-query";
import {usePostRecreateProductionItems} from "../../hooks/use-post-recreate-production-items";
import SpinnerButton from "../../../../../../shared/components/SpinnerButton";
import {ProductionStatus} from "../../../../pm/components/ProductionStatus";
import {de} from "date-fns/locale";
import {useDeleteProductionItems} from "../../hooks/use-delete-production-items";

type Props = {
    orderId: string
    orderCode?: string
    productionDate?: Date | null
    sillCount: number
    productionItemCount: number
    productionStatus: ProductionStatusCodes
    status?: string
    setModalComponent: React.Dispatch<React.SetStateAction<ReactElement | undefined | null>>,
    setModalTitle: React.Dispatch<React.SetStateAction<string | undefined | null>>,
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>,
}

const ProductionDetails: React.FC<Props> = ({
    setShowEditModal,
    setModalTitle,
    setModalComponent,
    orderId,
    orderCode,
    productionDate,
    sillCount,
    productionItemCount,
    productionStatus,
    status
}) => {

    const recreateMutation = usePostRecreateProductionItems(orderId);
    const deleteMutation = useDeleteProductionItems(orderId);

    var editProductionDate = () => {
        setModalComponent(() => <PutProductionDateForm handleClose={() => setShowEditModal(false)}
            productionDate={productionDate} id={orderId} />)
        setModalTitle('Wijzig productiedatum')
        setShowEditModal(true)
    }
    const filterProperty = 'OrderCode'
    const expression = { [filterProperty]: { eq: orderCode } }
    const filters: FilterParameter[] = [{
        filterExpression: expression,
        filterName: filterProperty,
        values: [orderCode]
    }]
    const filter = {
        and: filters.map(v => { return v.filterExpression })
    };
    const filterQueryString = buildQuery({ filter })
    const filterQuery = filterQueryString.substring(9, filterQueryString.length);


    return (
        <>
            <EkoCard className={'py-4 h-100'} flush={true}>
                <div className="card-header">
                    <div className="card-title d-flex justify-content-between">
                        <h2>Productie</h2>
                    </div>
                    <div className="card-toolbar">
                        <ProductionStatus  status={productionStatus}/>
                    </div>
                </div>
                <EkoCardBody className={'pt-0'}>
                    <div className="table-responsive">
                        <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5">
                            <tbody className="fw-semibold text-gray-600">
                                <tr>
                                    <td className="text-muted">
                                        <div className="d-flex align-items-center">
                                            <KTSVG path={'/media/icons/duotune/general/gen014.svg'}
                                                className='svg-icon-2 me-2' />
                                            Productiedatum <br />
                                        </div>
                                    </td>
                                    <td className="fw-bold text-end">
                                        {status === OrderStatusCodes.Draft || status === OrderStatusCodes.Placed || status === OrderStatusCodes.Accepted ?
                                            <a href='#'
                                                onClick={() => editProductionDate()}>

                                                {!productionDate &&
                                                    <>Niet gepland</>
                                                }

                                                {productionDate &&
                                                    formatDate(productionDate)
                                                }
                                            </a>
                                            :
                                            <>
                                                {!productionDate &&
                                                    <>Niet gepland</>
                                                }
                                                {productionDate &&
                                                    formatDate(productionDate)
                                                }
                                            </>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-muted">
                                        <div className="d-flex align-items-center">
                                            <KTSVG path={'/media/icons/duotune/finance/fin001.svg'}
                                                className='svg-icon-2 me-2' />

                                            Aantal dorpels <br />
                                        </div>
                                    </td>
                                    <td className="fw-bold text-end">
                                        {sillCount}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-muted">
                                        <div className="d-flex align-items-center">
                                            <KTSVG path={'/media/icons/duotune/arrows/arr029.svg'}
                                                   className='svg-icon-2 me-2' />

                                            Hergenereren <br />
                                        </div>
                                    </td>
                                    <td className="fw-bold text-end">
                                        { status === OrderStatusCodes.Accepted && productionStatus === ProductionStatusCodes.NotStarted &&
                                            <SpinnerButton
                                                onClick={async () => window.confirm('Weet je het zeker?') &&
                                                    await deleteMutation.mutateAsync(undefined, undefined)}
                                                className='btn btn-sm btn-light-danger me-3'
                                                spinning={deleteMutation.isLoading}
                                                disabled={deleteMutation.isLoading}
                                            >
                                                Verwijderen
                                            </SpinnerButton>
                                        }
                                        { status === OrderStatusCodes.Accepted && productionStatus === ProductionStatusCodes.NotStarted &&
                                            <SpinnerButton
                                                onClick={async () => window.confirm('Weet je het zeker?') &&
                                                    await recreateMutation.mutateAsync(undefined, undefined)}
                                                className='btn btn-sm btn-light-info'
                                                spinning={recreateMutation.isLoading}
                                                disabled={recreateMutation.isLoading}
                                            >
                                                Hergenereren
                                            </SpinnerButton>
                                        }
                                    </td>
                                </tr>
                                {/*<tr>*/}
                                {/*    <td className="text-muted">*/}
                                {/*        <div className="d-flex align-items-center">*/}
                                {/*            <KTSVG path={'/media/icons/duotune/finance/fin001.svg'}*/}
                                {/*                   className='svg-icon-2 me-2' />*/}
                                {/*            Aantal productie items <br />*/}
                                {/*        </div>*/}
                                {/*    </td>*/}
                                {/*    <td className="fw-bold text-end">*/}
                                {/*        {productionItemCount}*/}
                                {/*    </td>*/}
                                {/*</tr>*/}
                            </tbody>
                        </table>
                    </div>
                    <ProductionItemTable filterQuery={filterQuery} sillCount={sillCount} />
                </EkoCardBody>
            </EkoCard>
        </>
    )
}

export { ProductionDetails }

import React, {useLayoutEffect, useState} from "react";
import {Formik, FormikHelpers, useFormikContext} from "formik";
import SubmitButton from "../../../../shared/components/SubmitButton";
import {ValidationErrors} from "../../../../modules/api-client/Responses";
import Loading from "../../../../shared/components/Loading";
import ImageDropzone from "../components/ImageDropzone";
import {Category} from "../../../../modules/api-client/generated";
import {useCategoryImageUpsertMutation, useCategoryQuery} from "./hooks";
import Resizer from "react-image-file-resizer";
export interface ImageFormValues {
    image?: File;
}

type Props = {
    handleClose: () => void,
    id: string,
}


const CategoryImageUpsert: React.FC<Props> = ({handleClose, id}) => {

    const {mutate, serverValidationErrors} = useCategoryImageUpsertMutation(id);

    const onSubmit = async (values: ImageFormValues, {setSubmitting}: FormikHelpers<ImageFormValues>) => {

        return new Promise<void>((resolve, reject) => {

            mutate(values, {
                onSuccess: () => {
                    resolve();
                    handleClose();

                },
                onError: () => {
                    reject();
                }
            });

        }).finally(() => setSubmitting(false));
    };


    const {data: category, isInitialLoading, isError} = useCategoryQuery(id);
    if (isInitialLoading) return <Loading/>
    if (isError) return (<>Error...</>)
    if (id && !category) return (<>'{id}' niet gevonden.</>)
    if (!category) return (<>'{id}' niet gevonden.</>)

    const initialValues: ImageFormValues = {
        image: undefined
    };

    return (
        <>
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                <CategoryImageUpsertForm id={id} serverValidationErrors={serverValidationErrors} category={category as Category}/>
            </Formik>
        </>

    );
}


interface CategoryImageUpsertFormProps {
    id: string | undefined;
    category: Category;
    serverValidationErrors: ValidationErrors | null;
}

export const CategoryImageUpsertForm: React.FC<CategoryImageUpsertFormProps> = (props) => {

    const resizeFile = (file:File) =>
        new Promise<string | File | Blob | ProgressEvent<FileReader>>((resolve) => {
            try {
                Resizer.imageFileResizer(
                    file,
                    450,
                    450,
                    "PNG",
                    100,
                    0,
                    (uri) => {
                        return resolve(uri);
                    },
                    "file",
                     450,
                     450,
                );
            } catch (err) {
            }
        });

    const {
        values, handleSubmit, handleChange
        , isSubmitting, errors, setFieldValue, setErrors
    } = useFormikContext<ImageFormValues>();
    const mergeServerErrors = () => {
        if (props.serverValidationErrors) {
            setErrors({...errors, ...props.serverValidationErrors});
        }
    }
    const toBase64:any = (file: Blob) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    }).then((res)=>setBase64(res));

    const [base64, setBase64] = useState<any>(null);

    useLayoutEffect(mergeServerErrors, [props.serverValidationErrors, errors, setErrors]);
    return (<form onSubmit={handleSubmit}>

        <div className="row mb-4">
            <div className="col-4">
                <label className="form-label">Afbeelding</label>
            </div>
            <div className="col-8">
                <ImageDropzone
                    onSelect={
                    (selected) => {
                        resizeFile(selected).then((res) => {
                            setFieldValue('image', res)
                            toBase64(res);
                        });
                    }
                }
                    preview={(base64) =>
                        <img src={`data:image/jpg;base64,` + base64} alt={'tx'}
                             className={'img-fluid'}/>
                    }
                    show={'drag'}>
                    {({openFileDialog}) => <>
                        <div
                            className={`form-control d-flex justify-content-center align-items-center ${values.image ? '' : 'cursor-pointer'}`}
                            onClick={() => {
                                if (!props.category.image) openFileDialog();
                            }}
                            onDoubleClick={openFileDialog}
                            style={{height: '200px'}}>

                            {values.image ?
                                    <img src={base64} alt={props.category.name}
                                         className={'img-fluid'} style={{maxHeight:'100%'}}/>
                            :
                                <>
                                    {props.category.image ?
                                        <img src={`data:image/jpg;base64,` + props.category.image} alt={props.category.name}
                                             className={'img-fluid'} style={{maxHeight:'100%'}}/>
                                        :
                                        <>
                                            <div
                                                className="d-flex flex-column justify-content-center align-items-center">
                                                <button type="button"
                                                        className="btn btn-light-primary me-3"
                                                        onClick={openFileDialog}><i
                                                    className="fa-regular fa-folder-open"></i> Blader
                                                </button>
                                                <div className="mt-2">of sleep een .jpg/.png bestand hier
                                                </div>
                                            </div>
                                        </>
                                    }
                                </>
                            }

                        </div>
                        {props.category.image &&
                            <div className="py-3 d-flex justify-content-start">
                                <button type="button" className="btn btn-light-primary me-3"
                                        onClick={openFileDialog}>Wijzig afbeelding
                                </button>
                            </div>
                        }
                    </>
                    }
                </ImageDropzone>
            </div>
        </div>

        <div className="row pt-5">
            <div className="offset-4 col-8 d-flex justify-content-end">
                <SubmitButton type="submit" className="btn btn-primary"
                              isSubmitting={isSubmitting}>Opslaan</SubmitButton>
            </div>
        </div>

    </form>);
}

export default CategoryImageUpsert;
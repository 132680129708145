import React, {FC} from "react";
import TextField from "../../../../../../shared/components/TextField";
import {useFormikContext} from "formik";


export interface OrderConfiguratorModalFinishProps {
    configurationId?: string | null;
}

const OrderConfiguratorModalFinish: FC<OrderConfiguratorModalFinishProps> = (props) => {

    const {getFieldProps} = useFormikContext();

    return <>
        <TextField type={'number'} label={'Aantal'} row={true} {...getFieldProps('quantity')} autoFocus />
        <TextField row={true} label={'Merk'} {...getFieldProps('merk')}/>
        <TextField row={true} label={'Opmerking'} {...getFieldProps('remark')}/>

    </>
}

export default OrderConfiguratorModalFinish;
import React, {FC, ReactElement, useState} from 'react'
import {
    OrderLineRepresentation,
    OrderLineType,
    OrderRepresentation
} from "../../../../../../../modules/api-client/generated";
import {OrderProductTablePicker} from "../OrderProductTablePicker";
import { OrderLineTable } from '../../../edit-order/components/OrderLineTable';
import { EditOrderModal } from '../../../edit-order/components/EditOrderModal';

type Props = {
    order: OrderRepresentation
    showConfiguratorModal?: React.MutableRefObject<((show: boolean) => void)|undefined>,    
}

const AdditionalProducts: FC<Props> = ({order}) => {

  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const [modalFullscreen, setModalFullscreen] = useState<true | string | 'sm-down' | 'md-down' | 'lg-down' | 'xl-down' | 'xxl-down'>('lg-down')
  const [modalTitle, setModalTitle] = useState<string | undefined | null>(null);
  const [modalComponent, setModalComponent] = useState<ReactElement | undefined | null>(null);
  
  const lines = order?.lines?.filter((l: OrderLineRepresentation) => l.type === OrderLineType.ProductLine);

  return (<>

  <EditOrderModal show={showEditModal} handleClose={() => setShowEditModal(false)}
                                    title={modalTitle}
                                    size={'lg'}
                                    fullscreen={modalFullscreen}
                                    component={modalComponent} 
                                    />
     
    <div className='w-100 extra-products'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark'>Aanvullende Producten</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          Kies hier extra producten.
        </div>
      </div>

        {lines && lines.length > 0 ?
            <div className={'mb-10'}>
                <OrderLineTable
                      orderId={order.id}
                      customerId={order.customer.id}
                      pricelistId={order.financial.priceList.id}
                      catalogId={order.catalog.id}
                      lines={lines} 
                      projectDiscount={0}
                      totalPriceNet={0}
                      totalPriceGross={0}
                      status={order.status}
                      productionStatus={order.production.status}
                      showConfiguratorModal={undefined}
                      setModalTitle={setModalTitle}
                      setModalComponent={setModalComponent}
                      setModalFullscreen={setModalFullscreen}
                      setShowEditModal={setShowEditModal}
                      isOrderEdit={false}
                />
            </div>
            :
            <></>
        }

      <div className='fv-row mb-10'>        
        <OrderProductTablePicker order={order} />
      </div>    
    </div>      
  </>
  )
}

export {AdditionalProducts}

import React from "react";
import {Field} from "formik";
import CustomSelect, {SelectOption, OptionsType} from "../../../shared/components/CustomSelect";
import {RabbetPosition} from "../../../modules/api-client/generated";

type Props = {
    name: string;
    multi?: boolean;
    required?: boolean;
}

export const RabbetPositionPicker: React.FC<Props> = ({name, multi, required = false, ...props}) => {

    let options: OptionsType<SelectOption> = [{
        label: 'Binnen',
        value: RabbetPosition.Inside
    },
        {
            label: 'Buiten',
            value: RabbetPosition.Outside
        }];

    return (
        <Field
            className="custom-select" name={name} options={options} component={CustomSelect}
            placeholder="-Selecteer-"
            isMulti={multi}
            isClearable={!required}/>
    )
}
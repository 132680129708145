/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { OrderContactPersonRepresentation } from "../../../../../../modules/api-client/generated";

type Props = {
    person?: OrderContactPersonRepresentation
}

const Person: React.FC<Props> = ({ person }) => {

    if (!person) return (<></>)

    return (
        <>
            <div>
                <span className={'fw-bold'}>{person.firstName} {person.lastName}</span>
                <br />
                {person.email}
                {person.telephone &&
                    <>
                        <br />
                        {person.telephone}
                    </>
                }
            </div>

        </>
    )
}

export { Person }

import {useQuery} from '@tanstack/react-query';
import ApiClient from '../../../modules/api-client/ApiClient';
import {AxiosError} from 'axios';
import {toast} from 'react-hot-toast';
import {isNotEmpty} from '../../../../_metronic/helpers';
import { ImportSluitpotMappingRepresentation } from '../../../modules/api-client/generated';


export const useGetCustomerSluitpotMappings = (customerId: string | undefined, enabled: boolean = true) => {
	const response = useQuery<Array<ImportSluitpotMappingRepresentation>, AxiosError, Array<ImportSluitpotMappingRepresentation>>(
		['aclImportMappingSluitpotCustomerCustomerIdGet', customerId],
		() => {
			return ApiClient.Acl.ImportMapping.aclImportMappingSluitpotCustomerCustomerIdGet(customerId!).then((res) => res.data);
		},
		{
			useErrorBoundary: true,
			enabled: enabled && isNotEmpty(customerId),			
		}
	);

	if (response.error) {
		var error = response.error;
		toast.error(error?.message);
	}

	return response;
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {ReactElement, useRef, useState} from 'react';
import {DeliveryConditions, OrderRepresentation, OrderStatusCodes} from '../../modules/api-client/generated';
import {useNavigate} from 'react-router-dom';
import {CustomerRoutePath} from '../routing/RoutePath';
import {EkoCard} from '../../shared/components/card/EkoCard';
import {OrderStatus} from '../../backoffice/modules/sm/orders/edit-order/components/OrderStatus';
import {EkoCardBody} from '../../shared/components/card/EkoCardBody';
import {formatDate} from '../../shared/components/date';
import {ConfirmDialog} from '../../shared/components/ConfirmDialog';
import {usePutQuoteIssued} from '../../backoffice/modules/sm/orders/hooks/use-put-quote-issued';
import {usePutQuoteRejected} from '../../backoffice/modules/sm/orders/hooks/use-put-quote-rejected';
import {usePutQuoteAccepted} from '../../backoffice/modules/sm/orders/hooks/use-put-quote-accepted';
import {usePutOrderPlaced} from '../../backoffice/modules/sm/orders/hooks/use-put-order-placed';
import {useDeleteOrder} from '../../backoffice/modules/sm/orders/hooks/use-delete-order';
import {usePutOrderCancelled} from '../../backoffice/modules/sm/orders/hooks/use-put-order-cancelled';

type Props = {
    order: OrderRepresentation;
    setModalComponent: React.Dispatch<React.SetStateAction<ReactElement | undefined | null>>;
    setModalTitle: React.Dispatch<React.SetStateAction<string | undefined | null>>;
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
};
const OrderActions: React.FC<Props> = ({order, setShowEditModal, setModalTitle, setModalComponent}) => {
    const navigate = useNavigate();
    const [isOrderMutating, setIsOrderMutating] = useState(false);
    const issueQuoteMutation = usePutQuoteIssued(order.id);
    const rejectQuoteMutation = usePutQuoteRejected(order.id);
    const acceptQuoteMutation = usePutQuoteAccepted(order.id);
    const placeOrderMutation = usePutOrderPlaced(order.id);
    const cancelOrderMutation = usePutOrderCancelled(order.id);
    const deleteOrderMutation = useDeleteOrder();
    const openOrderDeleteDialog = useRef<(show: boolean) => void>();
    const openCancelDeleteDialog = useRef<(show: boolean) => void>();

    if (!order) return <></>;

    const quoteExpiryDate = order.quoteExpiryDate ? new Date(order.quoteExpiryDate) : null;
    const hasMissingOrderData =
        !order.orderContact ||
        !order.financial ||
        !order.financial.contactPerson ||
        !order.financial.invoiceAddress ||
        !order.expedition ||
        !order.expedition.contactPerson ||
        (order.expedition.deliveryCondition === DeliveryConditions.Bezorging && !order.expedition.deliveryAddress);

    const issueQuote = async () => {
        if (isOrderMutating) return;
        setIsOrderMutating(true);
        await issueQuoteMutation.mutateAsync(undefined, {}).finally(() => setIsOrderMutating(false));
    };
    const rejectQuote = async () => {
        if (isOrderMutating) return;
        setIsOrderMutating(true);
        await rejectQuoteMutation.mutateAsync(undefined, {}).finally(() => setIsOrderMutating(false));
    };
    const acceptQuote = async () => {
        if (isOrderMutating) return;
        setIsOrderMutating(true);
        await acceptQuoteMutation.mutateAsync(undefined, {}).finally(() => setIsOrderMutating(false));
    };
    const placeOrder = async () => {
        if (isOrderMutating) return;
        setIsOrderMutating(true);
        await placeOrderMutation.mutateAsync(undefined, {}).finally(() => setIsOrderMutating(false));
    };

    const cancelOrder = async () => {
        if (isOrderMutating) return;
        setIsOrderMutating(true);
        await cancelOrderMutation.mutateAsync(undefined, {}).finally(() => setIsOrderMutating(false));
    };

    const archive = async () => {
        if (!order) return;
        if (isOrderMutating) return;
        setIsOrderMutating(true);
        await deleteOrderMutation.mutateAsync(order.id);
        navigate(CustomerRoutePath.link(CustomerRoutePath.dashboard));
    };

    return (
        <>


            <EkoCard className={'py-4  mb-5'} flush={true}>
                {/*begin::Card header*/}
                <div className="card-header">
                    <div className="card-title d-flex flex-column flex-xxl-row justify-content-between align-items-center w-100">
                        <h4>Orderstatus</h4>
                        <h2>
                            <OrderStatus status={order.status} className={'fs-4'}/>
                        </h2>
                    </div>
                </div>
                {/*end::Card header*/}
                <EkoCardBody className={'pt-0 '}>
                    {hasMissingOrderData &&
                        <div className={'alert alert-danger d-flex align-items-center'}>
                            <i className={'fas fa-exclamation-triangle text-danger me-2'}/>
                            Vul de ontbrekende gegevens in om een offerte aan te vragen of een order te plaatsen.
                        </div>
                    }

                    <div className={' d-flex flex-column flex-xl-row  w-100 justify-content-end'}>

                        {order.status === OrderStatusCodes.Draft && (
                            <>

                                {!hasMissingOrderData ? (
                                    <>
                                        <div className={'mb-3'}>
                                            <button disabled={isOrderMutating} onClick={() => issueQuote()}
                                                    className="btn btn-warning me-2">
                                                Offerte aanvragen
                                            </button>
                                            of{' '}
                                            <button disabled={isOrderMutating} onClick={() => placeOrder()}
                                                    className="btn btn-primary">
                                                Order plaatsen
                                            </button>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className={'mb-3'}>
                                            <button disabled={true} className="btn btn-warning me-2 disabled">
                                                Offerte aanvragen
                                            </button>
                                            of{' '}
                                            <button disabled={true} className="btn btn-primary disabled ">
                                                Order plaatsen
                                            </button>
                                        </div>
                                    </>
                                )}
                            </>
                        )}


                        {order.status === OrderStatusCodes.QuoteIssued && (
                            <>
                                <div className={'mb-3'}>
                                    <button disabled={isOrderMutating}
                                            onClick={() => window.confirm('Weet je het zeker?') && rejectQuote()}
                                            className="btn btn-danger me-2">
                                        Offerte afwijzen
                                    </button>

                                    <button disabled={isOrderMutating} onClick={() => acceptQuote()}
                                            className="btn btn-primary">
                                        Offerte accepteren
                                    </button>
                                </div>
                            </>
                        )}
                    </div>

                    {order.status === OrderStatusCodes.QuoteIssued && quoteExpiryDate && (
                        <>
                            <div className="d-flex align-items-center">Offerte geldig tot:</div>
                            <div
                                className="d-flex align-items-center justify-content-end">{formatDate(quoteExpiryDate)}</div>
                        </>
                    )}
                    {/*<div className="text-muted ">Gevarenzone</div>*/}
                                {order.status !== OrderStatusCodes.Completed && order.status !== OrderStatusCodes.Cancelled && order.status !== OrderStatusCodes.Accepted ? (
                                    <>
                                        <a href="#" onClick={() => openCancelDeleteDialog.current?.call(this, true)}
                                           className="btn btn-light-danger btn-sm">
                                            Order annuleren
                                        </a>
                                        <ConfirmDialog
                                            show={openCancelDeleteDialog}
                                            onConfirm={cancelOrder}
                                            onHide={() => openCancelDeleteDialog.current?.call(this, false)}
                                            dialogStyle="danger"
                                            title="Order Annuleren"
                                            message="Wil je doorgaan?"
                                            confirmText="Definitief annuleren"
                                        />
                                    </>
                                ) : (
                                    <>
                                        <a href="#" className="btn btn-light-danger btn-sm disabled">
                                            Order annuleren
                                        </a>
                                    </>
                                )}
                                {(order.status === OrderStatusCodes.Draft || order.status === OrderStatusCodes.Cancelled) && (
                                    <>
                                        <a href="#" onClick={() => openOrderDeleteDialog.current?.call(this, true)}
                                           className="btn btn-light-warning btn-sm ms-2">
                                            Order archiveren
                                        </a>
                                        <ConfirmDialog
                                            show={openOrderDeleteDialog}
                                            onConfirm={archive}
                                            onHide={() => openOrderDeleteDialog.current?.call(this, true)}
                                            dialogStyle="danger"
                                            title="Order archiveren"
                                            message="Wil je doorgaan?"
                                            confirmText="Definitief verwijderen"
                                        />
                                    </>

                                )}

                </EkoCardBody>
            </EkoCard>
        </>
    );
};

export {OrderActions};

import {useQueryClient} from "@tanstack/react-query";
import {useEkosietMutation} from "../../../../../shared/hooks/useEkosietMutation";
import * as api from "../../../../../modules/api-client/generated";
import {CreateSillCommand, SillDetails, UpdateSillRequest} from "../../../../../modules/api-client/generated";
import {NotFound, ValidationFailed} from "../../../../../modules/api-client/Responses";
import {SillFormValues} from "../SillUpsert";
import ApiClient from "../../../../../modules/api-client/ApiClient";

export const useSillUpsertMutation = (id?: string | undefined) => {

    const queryClient = useQueryClient();

    return useEkosietMutation<SillDetails, NotFound | ValidationFailed, SillFormValues>(async (values) => {
            const command: CreateSillCommand | UpdateSillRequest = {
                code: values.code,
                active: values.active,
                name: values.name,
                type: values.type as api.SillType,
                width: values.width as number,
                rabbetWidth: values.rabbetWidth as number,
                description: values.description || null,
                assemblyOptions: values.assemblyOptions,
                baseSill: values.baseSillId || '',
                extender: values.extenderId || null,
            };

            if (id) {
                const res = await ApiClient.Pim.Sill.updateSill(id, undefined, command);
                return res.data;
            } else {
                const res = await ApiClient.Pim.Sill.createSill(undefined, command);
                return res.data;
            }
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['ApiClient.Pim.Sill.getSill', id]).then();
            },
        },
    );
};
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import { toast } from "react-hot-toast"
import { AxiosError } from "axios";
import ApiClient from "../../../../modules/api-client/ApiClient";
import {CustomerListViewArrayODataValue} from "../../../../modules/api-client/generated";

export const useListCustomersOdata = ($top?:number|undefined, $skip?:number|undefined,$orderBy?: string,$filter?: string,$count:boolean|undefined = false, enabled: boolean | undefined = true)
    :
    UseQueryResult<CustomerListViewArrayODataValue, unknown> => {

    if ($filter === "") {
        $filter = undefined
    }

    const key = 'crmCustomerListOdataGet';

    const response = useQuery<CustomerListViewArrayODataValue,unknown,CustomerListViewArrayODataValue>(
        [key, $filter, $top, $skip, $count, $orderBy], () => {
        return ApiClient.Crm.Customer
            .crmCustomerListOdataGet($top,$skip,$orderBy, $filter, $count, undefined, undefined)
            .then(d => d.data);
    },
        { enabled: enabled }
    )

    if (response.error) {
        var error = response.error as AxiosError;
        toast.error(error?.message);
        // toast.error(error?.response?.data?.title || 'Onbekende fout!');
    }
    return response;
};

import React from "react";
import { Field } from "formik";
import { Catalog } from "../../../../modules/api-client/generated";
import { useQuery } from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import CustomSelect, { OptionsType, SelectOption } from "../../../../shared/components/CustomSelect";


type Props = {
    name: string;
    multi?: boolean;
    required?: boolean;
}

export const CatalogPicker: React.FC<Props> = ({ name, multi, required = false }) => {

    let options: OptionsType<SelectOption> = [];

    const {
        data: list,
    } = useQuery<Catalog[]>(['pimCatalogsGet'], () => ApiClient.Pim.Catalog.pimCatalogsGet().then((res) => res.data));


    if (list) {
        options = list.map((item) => {
            return { value: item.id!, label: item.name! }
        })
    }

    return (
        <>
            <Field
                className="custom-select"
                name={name}
                options={options}
                component={
                    CustomSelect
                }
                placeholder={multi ? "Kies een of meerdere catalogussen..." : 'Kies een catalogus'}
                isMulti={multi ?? false}
                isClearable={!required}
            />
        </>
    )
}
import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {NeutListItemArrayODataValue} from '../../../../modules/api-client/generated';
import ApiClient from '../../../../modules/api-client/ApiClient';

export const useListNeutenOdata = (
	$top?: number | undefined,
	$skip?: number | undefined,
	$orderBy?: string,
	$filter?: string,
	searchTerm?: string,
	$count: boolean | undefined = false,
	enabled: boolean | undefined = true
): UseQueryResult<NeutListItemArrayODataValue, unknown> => {
	if ($filter === '') {
		$filter = undefined;
	}

	if (searchTerm === '') {
		searchTerm = undefined;
	}

	const queryKey = ['Pim.Neuten.neutenList', $filter, searchTerm, $top, $skip, $count, $orderBy];

	return useQuery(
		queryKey,
		async () => {
			return await ApiClient.Pim.Neuten.neutenList($top, $skip, $orderBy, $filter, searchTerm, $count, undefined, undefined).then((x) => x.data);
		},
		{enabled: enabled}
	);
};

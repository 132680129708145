import clsx from 'clsx';
import { FC } from 'react'
import {useFormikContext, getIn} from "formik";
import { CustomerAddressRepresentation } from '../../modules/api-client/generated';
import Select from "react-select"

type Props= {    
  fieldName: string;  
  addresses?: Array<CustomerAddressRepresentation> | undefined
}

export const CustomerAddressPicker : FC<Props> = (props: Props) => {

  const formik = useFormikContext();

  let options: any[] = [{ value: '', label: 'Voer een nieuw adres...'}]
  if (props.addresses) {
    let customerAddressOptions = props.addresses.map((item: CustomerAddressRepresentation) => {
      return { value: item.id, label: `${item.contactPerson?.firstName} ${item.contactPerson?.lastName} (${item.street} ${item.houseNumber}${item.houseNumberAddition || ''}, ${item.postalCode} ${item.city})`}
    });

    options = [...options, ...customerAddressOptions]
  }

  let selectedValue = options.find((option: any) => option.value === getIn(formik.values, props.fieldName)) || options[1];

  return (
    <>
        <div className='fv-row mb-7'>                  
          <div>        
            <Select
                className={clsx(
                  'mb-3 mb-lg-0',
                  {'is-invalid': getIn(formik.touched, props.fieldName) && getIn(formik.errors, props.fieldName)},
                  {'is-valid': getIn(formik.touched, props.fieldName) && !getIn(formik.errors, props.fieldName)}
                )}
                classNamePrefix="select"
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 7,
                  padding: 5,
                })}
                classNames={{
                  control: (state) =>
                      clsx(
                          'form-control form-control p-2 border-secondary',
                      ),
                  singleValue: (state) =>
                  {
                    return clsx(
                        'text-gray-700',
                    )
                  }
                }}
                value={selectedValue}
                placeholder="Selecteer een adres of vul zelf een adres in"
                isClearable={true}
                isSearchable={true}
                name={props.fieldName}
                options={options}                
                onChange={(newValue) => {              
                  formik.setFieldValue(props.fieldName, newValue?.value)
                  formik.validateForm();
                }}
              />
          </div>

          {getIn(formik.touched, props.fieldName) && getIn(formik.errors, props.fieldName) && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{getIn(formik.errors, props.fieldName)}</span>
              </div>
            </div>
          )}

        </div>          
    </>
  )
}

import {FC} from 'react'
import * as Yup from 'yup'
import {Form, Formik, FormikHelpers} from 'formik'
import {
    DeliveryConditions,     
    PutExpeditionConditionRepresentation
} from '../../../../../../../modules/api-client/generated'
import {usePutExpeditionCondition} from "../../../hooks/use-put-expedition-condition";
import SelectField from "../../../../../../../shared/components/SelectField";

type Props = {
    id: string
    expeditionCondition?: DeliveryConditions
    handleClose: () => void,
}

const PutExpeditionConditionForm: FC<Props> = ({id, expeditionCondition, handleClose}) => {

    const mutation = usePutExpeditionCondition(id);

    const initialValues:PutExpeditionConditionRepresentation= {
        expeditionCondition: expeditionCondition || 'afFabriek'
    }

    const schema = {
        expeditionCondition: Yup.string()
            .oneOf(
                Object.values(DeliveryConditions).filter((item) => {
                    return isNaN(Number(item));
                }),
                'Kies leveringsconditie'
            )
            .required('Required'),
    }
    const formSchema = () => Yup.lazy((values) => {
        return Yup.object().shape(schema)
    });
    const onSubmit = (values: PutExpeditionConditionRepresentation, {setSubmitting}: FormikHelpers<PutExpeditionConditionRepresentation>) => {

        return new Promise<void>((resolve, reject) => {
            mutation.mutate(values, {
                onSuccess: (model) => {
                    resolve();
                    handleClose()
                },
                onError: () => {
                    reject();
                }
            });

        }).finally(() => setSubmitting(false));
    };


    return (
        <>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={formSchema}>
                {({
                      values,
                      handleChange,
                      handleSubmit,
                      getFieldProps,
                      isSubmitting,
                  }) => (
                    <Form>
                        {/* begin form element */}
                        <SelectField
                            label={'Leveringsconditie'}
                            {...getFieldProps('expeditionCondition')}
                        >
                            <option value={DeliveryConditions.AfFabriek}>Afhalen op fabriek</option>
                            <option value={DeliveryConditions.Bezorging}>Bezorging</option>
                        </SelectField>

                        {/* begin::Actions */}
                        <div className='text-center pt-5'>
                            <button
                                type='reset'
                                className='btn btn-light me-3'
                                data-kt-customer-modal-action='cancel'
                                data-dismiss="modal"
                                onClick={() => handleClose()}
                                disabled={isSubmitting}
                            >
                                Annuleren
                            </button>

                            <button
                                type='submit'
                                className='btn btn-primary'
                                data-kt-customer-modal-action='submit'
                                disabled={isSubmitting}
                            >
                                <span className='indicator-label'>Opslaan
                                </span>
                                {isSubmitting && (
                                    <span className='indicator-progress'>
                                        Even wachten a.u.b
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                        {/* end::Actions */}

                    </Form>
                )}
            </Formik>
        </>
    )
}

export {PutExpeditionConditionForm}

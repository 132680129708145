import { useQuery } from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import { ProductionItemGroupRepresentation } from "../../../../modules/api-client/generated";
import { AxiosResponse } from "axios";
export const useProductionItemGroupGetByCode = (code: string | undefined) => {
    return useQuery(['pmProductionItemGroupCodeGet', code],
        () => {
            return ApiClient.Pm.ProductionItemGroup.pmProductionItemGroupCodeCodeGet(code!)
                .then((res: AxiosResponse<ProductionItemGroupRepresentation>) => res.data);
        }, {
        useErrorBoundary: true,
        enabled: !!code,
    });
}
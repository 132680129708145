import { toast } from "react-hot-toast"
import { useEkosietMutation } from "../../../../shared/hooks/useEkosietMutation";
import { NotFound, ValidationFailed } from "../../../../modules/api-client/Responses";
import ApiClient from "../../../../modules/api-client/ApiClient";
import { useQueryClient } from "@tanstack/react-query";

export const usePutBatchStarted = () => {
    const queryClient = useQueryClient()

    return useEkosietMutation<void, NotFound | ValidationFailed, string>((id) => {
        return ApiClient.Pm.ProductionBatch.pmProductionBatchIdStartedPut(id, undefined).then();
    },
        {
            onSuccess: (data, variables) => {
                queryClient.invalidateQueries(['pmProductionBatchesListOdataGet'])
                queryClient.invalidateQueries(['pmProductionBatchGetId', variables]);
                toast.success("Batch gestart");
            },
            onError: () => {
                toast.error("Batch starten mislukt");
            }
        }
    );
};

import React from "react";
import {PageTitle} from "../../../../../_metronic/layout/core";
import {ProductionItemOperations} from "../../../../modules/api-client/generated";
import {ProductionOperation} from "../components/ProductionOperation";
import {stationLookup} from "../models/stations";

const Inpakken: React.FC = () => {
    var station = stationLookup.get(ProductionItemOperations.Ingepakt);
    if (!station) {
        throw new Error(`Station not found`);
    }
    return (
        <>
            <PageTitle
                color={station.color}
                breadcrumbs={[]}
            >
                {station.title}
            </PageTitle>
            <ProductionOperation scope={[`Ingepakt:i-0`,`Status:Started`]} operation={ProductionItemOperations.Ingepakt} />

        </>
    )
}

export default Inpakken

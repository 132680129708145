import {useQueryClient} from '@tanstack/react-query';
import {toast} from 'react-hot-toast';
import ApiClient from '../../../modules/api-client/ApiClient';
import {useEkosietMutation} from '../../hooks/useEkosietMutation';
import {ValidationFailed} from '../../../modules/api-client/Responses';
import {ImportType, ImportValidationResult} from '../../../modules/api-client/generated';

export type RevalidateImport = {
	importId: string;
	type: ImportType;
};

export const usePostRevalidateImport = () => {
	const queryClient = useQueryClient();

	return useEkosietMutation<ImportValidationResult, ValidationFailed, RevalidateImport>(
		(command) => {
			return ApiClient.Acl.ImportValidation.aclImportValidationRevalidateImportIdPost(command.importId, command.type).then((response) => response.data);
		},
		{
			onSuccess: (data, variables) => {
				queryClient.invalidateQueries(['aclImportValidationImportValidationIdGet']);
				queryClient.invalidateQueries(['aclImportValidationListImportIdGet']);
				queryClient.invalidateQueries(['aclImportIdGet']);
			},
			onError: () => {
				toast.error('Opnieuw valideren mislukt!');
			},
		}
	);
};

import React, {useLayoutEffect} from "react";
import {Formik, FormikHelpers, useFormikContext} from "formik";
import SubmitButton from "../../../../shared/components/SubmitButton";
import {ValidationErrors} from "../../../../modules/api-client/Responses";
import TextField from "../../../../shared/components/TextField";
import {useGlazingBarQuery, useGlazingBarUpsertMutation} from "./hooks";
import Loading from "../../../../shared/components/Loading";

export interface FormValues {
    code: string;
    active: boolean;
    name: string;
    width: number;
    height: number;
}

type Props = {
    handleClose: () => void,
    id?: string,
}


const GlazingBarUpsert: React.FC<Props> = ({handleClose,id}) => {

    const {mutate, serverValidationErrors} = useGlazingBarUpsertMutation(id);

    const onSubmit = (values: FormValues, {setSubmitting}: FormikHelpers<FormValues>) => {

        return new Promise<void>((resolve, reject) => {
            mutate(values, {
                onSuccess: () => {
                    resolve();
                    handleClose();

                },
                onError: () => {
                    reject();
                }
            });

        }).finally(() => setSubmitting(false));
    };


    const {data: data, isInitialLoading, isError} = useGlazingBarQuery(id);
    if (isInitialLoading) return <Loading />
    if (isError) return (<>Error...</>)
    if (id && !data) return (<>'{id}' niet gevonden.</>)

    const initialValues: FormValues = {
        code: data?.code ?? '',
        active: data?.active ?? false,
        name: data?.name ?? '',
        width: data?.width ?? 0,
        height: data?.height ?? 0,
    };

    return (
        <>
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                <DataUpsertForm id={id} serverValidationErrors={serverValidationErrors}/>
            </Formik>
        </>

    );
}


interface DataUpsertFormProps {
    id: string | undefined;
    serverValidationErrors: ValidationErrors | null;
}

export const DataUpsertForm: React.FC<DataUpsertFormProps> = (props) => {
    const {values, handleSubmit, handleChange, isSubmitting, errors, setErrors} = useFormikContext<FormValues>();
    const mergeServerErrors = () => {

        if (props.serverValidationErrors) {
            setErrors({...errors, ...props.serverValidationErrors});
        }
    }
    useLayoutEffect(mergeServerErrors, [props.serverValidationErrors, errors, setErrors]);
    return (<form onSubmit={handleSubmit}>



        <TextField row={true} name={'code'} label={'Unieke code'}/>

        <TextField row={true} name={'name'} label={'Naam'}/>
        <TextField row={true} type="number" name={'height'} label={'Hoogte'}/>
        <TextField row={true} type="number" name={'width'} label={'Breedte'}/>

        <div className="row mb-4">
            <div className="col-4">
                <label className="form-label" htmlFor="type">Actief</label>
            </div>
            <div className="col-8">
                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" role="switch"
                           name="active"
                           value="true"
                           onChange={handleChange}
                           checked={values.active}
                           id="active"/>
                    <label className="form-check-label" htmlFor="active">Actief</label>
                </div>
            </div>
        </div>

        <div className="row pt-5">
            <div className="offset-4 col-8 d-flex justify-content-end">
                <SubmitButton type="submit" className="btn btn-primary"
                              isSubmitting={isSubmitting}>Opslaan</SubmitButton>
            </div>
        </div>

    </form>);
}

export default GlazingBarUpsert;
import React, {useId} from "react";
import {useField} from "formik";
import FieldErrors from "../FieldErrors";
import {
    ConfiguredSillFixedDoorBarRaisedRim,
} from "../../../modules/api-client/generated";


export interface HefSchuifFixedDoorBarRaisedRimRowProps {
    fieldName: string;
    label?: string
    promptCenter?: boolean;
    promptWidth?: boolean;
}

export const HefSchuifFixedDoorBarRaisedRimRow: React.FC<HefSchuifFixedDoorBarRaisedRimRowProps> = (props) => {

    const id = useId();

    const [field, meta, helper] = useField<ConfiguredSillFixedDoorBarRaisedRim | undefined>(props.fieldName);

    const promptCenter = props.promptCenter ?? false;
    const promptWidth = props.promptWidth ?? false;

    const label = props.label ?? 'Opstaande rand';

    if (!promptCenter && !promptWidth) return null;

    return <>
        <div className="row">

            {promptCenter &&
                <div className={"col-4"}>
                    <label htmlFor={`${id}_length`} className='form-label'>
                        <small className='text-uppercase text-muted'>{label} hart</small>
                    </label>
                    <div className="input-group">
                        <input type="number"
                               id={`${id}_width`}
                               pattern={"[0-9]+([,\\.][0-9]+)?"}
                               name={`${props.fieldName}.center`}
                               value={field.value?.center ?? ''}
                               onChange={field.onChange}
                               onBlur={field.onBlur}
                               className="form-control"
                               min="0" step="0.1"
                               placeholder="Hart"
                               required/>
                        <span className="input-group-text">mm</span>
                    </div>
                    <small className="text-muted"><i>Vanuit binnenkant dorpel</i></small>
                    <FieldErrors field={`${props.fieldName}.center`}/>
                </div>
            }
            {promptWidth &&
                <div className="col-4">
                    <label htmlFor={`${id}_length`} className='form-label'>
                        <small className='text-uppercase text-muted'>{label} breedte</small>
                    </label>
                    <div className="input-group">
                        <input type="number"
                               id={`${id}_width`}
                               pattern={"[0-9]+([,\\.][0-9]+)?"}
                               name={`${props.fieldName}.width`}
                               value={field.value?.width ?? ''}
                               onChange={field.onChange}
                               onBlur={field.onBlur}
                               className="form-control"
                               min="0" step="0.1"
                               placeholder="Breedte"
                               required/>
                        <span className="input-group-text">mm</span>
                    </div>
                    <FieldErrors field={`${props.fieldName}.width`}/>
                </div>
            }
        </div>
    </>
}

import {useQueryClient} from "@tanstack/react-query";
import {toast} from "react-hot-toast"
import ApiClient from "../../../../../modules/api-client/ApiClient";
import { OrderDraftRepresentation } from "../../../../../modules/api-client/generated";
import {useEkosietMutation} from "../../../../../shared/hooks/useEkosietMutation";
import {NotFound, ValidationFailed} from "../../../../../modules/api-client/Responses";

export const usePostDraftOrder = () => {

        const queryClient = useQueryClient();

        return useEkosietMutation<OrderDraftRepresentation, NotFound | ValidationFailed, string>((customerId: string) => 
            {
                return ApiClient.Sm.Order.smOrderCustomerIdDraftPost(customerId).then(response => response.data);                
            },
            {
                onSuccess: (orderDraftRepresentation) => {                    
                    queryClient.invalidateQueries(['v1/sm/orders/list']).then();

                },
                onError: () => {
                    toast.error("Draft order aanmaken mislukt!");
                }
            }
        );
    }
;

import React from "react";
import * as api from "../../../../modules/api-client/generated";

export interface RabbetTypeTextProps {
    code: string | undefined;
}

const RabbetTypeText: React.FC<RabbetTypeTextProps> = (props) => {

    switch (props.code) {
        case api.RabbetType.Stomp: {
            return (<>Stomp</>);
        }
        case api.RabbetType.DraaiKiep: {
            return (<>Draai-/kiep</>);
        }
        case api.RabbetType.HefSchuif: {
            return (<>Hef-/schuif</>);
        }
        case api.RabbetType.Groef: {
            return (<>Groef</>);
        }
        case api.RabbetType.HoekProfile: {
            return (<>Hoekprofiel</>);
        }
        case api.RabbetType.DubbeleSponning: {
            return (<>Dubbele sponning</>);
        }
        case api.RabbetType.BinnendraaiendStomp: {
            return (<>Binnendraaiend stomp</>);
        }
        case api.RabbetType.VlakkedeurSponning: {
            return (<>Vlakkedeur sponning</>);
        }
    }

    return (<>{props.code}</>);
}

export default RabbetTypeText;
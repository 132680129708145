import React, {useCallback} from 'react';
import ApiClient from "../../../../../modules/api-client/ApiClient";
import {DownloadButton} from "../../../../../shared/components/DownloadButton";
import {mapDownload} from "../../../../../shared/hooks/useDownloadFile";

export const DownloadBmhButton: React.FC<{ title: string, filename: string, orderid: string, }> = ({
                                                                                                       title,
                                                                                                       filename,
                                                                                                       orderid,
                                                                                                   }) => {

    const downloadCallback = useCallback(() => {
        return ApiClient.Sm.Order.smOrderIdBmhxmlGet(orderid).then(mapDownload);
    }, []);

    return <DownloadButton title={title} filename={filename} downloadCallback={downloadCallback}/>
};
import {useQuery, useQueryClient} from "@tanstack/react-query";
import * as api from "../../../../modules/api-client/generated";
import ApiClient from "../../../../modules/api-client/ApiClient";
import {NotFound, ValidationFailed} from "../../../../modules/api-client/Responses";
import {useEkosietMutation} from "../../../../shared/hooks/useEkosietMutation";
import {
    CreateSillWrappingOptionCommand, UpdateSillWrappingOptionCommand
} from "../../../../modules/api-client/generated";
import {SillWrappingOptionFormValues} from "./WrappingOptionUpsert";


export const useSillWrappingOptionUpsertMutation = (id?: string | undefined) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<api.SillWrappingOption, NotFound | ValidationFailed, SillWrappingOptionFormValues>((values) => {
            const command: CreateSillWrappingOptionCommand | UpdateSillWrappingOptionCommand = {
                code: values.code,
                active: values.active,
                name: values.name,
            };

            if (id) {
                return ApiClient.Pim.WrappingOption.pimSillWrappingOptionsIdPut(id, undefined, command).then(response => response.data);
            }
            return ApiClient.Pim.WrappingOption.pimSillWrappingOptionsPost(undefined, command).then(response => response.data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['pimSillWrappingOptionsIdGet', id]).then();
                queryClient.invalidateQueries(['pimSillWrappingOptionsGet']).then();
            }
        }
    );
};

export const useSillWrappingOptionQuery = (id: string | undefined) => {
    return useQuery(['pimSillWrappingOptionsIdGet', id],
        () => {
            return ApiClient.Pim.WrappingOption.pimSillWrappingOptionsIdGet(id!).then(res => res.data);
        }, {
            useErrorBoundary: true,
            enabled: !!id,
        });
}

export const useSillWrappingOptionDeleteMutation = (id: string) => {
    const queryClient = useQueryClient()
    return useEkosietMutation<void, NotFound | ValidationFailed, string>((id) => {
            return ApiClient.Pim.WrappingOption.pimSillWrappingOptionsIdDelete(id, undefined).then(response => response.data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['pimSillWrappingOptionsIdGet', id]).then();
                queryClient.invalidateQueries(['pimSillWrappingOptionsGet']).then();
            }
        }
    );
};




import { Calendar } from "./calendar";

interface Props {
  value: Date;
  minDate?: Date;
  maxDate?: Date;  
  onChange: (val: Date) => void;
}

export const CalendarField = ({ value, minDate, maxDate, onChange }: Props) => {
  return (
    <>
      <Calendar
            value={value}
            onChange={(val) => {
              onChange(val);              
            }}
            minDate={minDate}
            maxDate={maxDate}
          />
    </>
  );
};

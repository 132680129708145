import {FC} from 'react'

type Props = {
  two_steps?: boolean
}

const UserTwoStepsCell: FC<Props> = ({two_steps}) => (
  <>
    {' '}
    {two_steps ? (
      <div className='badge badge-light-success fw-bolder'>Geverifieerd</div>
    ) : (
      <div className='badge badge-light-danger fw-bolder'>Niet Geverifieerd</div>
    )}
  </>
)

export {UserTwoStepsCell}

import React, {createContext, ReactNode, useCallback, useContext, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {modalsRoot} from '../../global-helpers';
import SpinnerButton from './SpinnerButton';

export type ConfirmDialogStyle = 'primary' | 'secondary' | 'warning' | 'danger';

export interface ConfirmDialogOptions {
	onConfirm: () => Promise<void>;
	onCancel?: () => Promise<void>;
	title?: string;
	message?: string;
	confirmText?: string;
	cancelText?: string;
	dialogStyle?: ConfirmDialogStyle;
}

export type ConfirmDialogProps = {
	show: boolean | React.MutableRefObject<((show: boolean) => void) | undefined>;
	onHide?: () => void;
	onConfirming?: () => void;
	onConfirmed?: () => void;
} & ConfirmDialogOptions;

export const DefaultProps: Partial<ConfirmDialogProps> = {
	title: 'Bevestigen',
	message: 'Weet je het zeker?',
	confirmText: 'Bevestigen',
	cancelText: 'Annuleren',
	dialogStyle: 'primary',
};

export const ConfirmDialog: React.FC<ConfirmDialogProps> = (props) => {
	props = Object.fromEntries(Object.entries(props).filter(([, v]) => v !== undefined)) as ConfirmDialogProps;

	props = {...DefaultProps, ...props};

	const [show, setShow] = useState<boolean>(() => (typeof props.show === 'boolean' ? props.show : false));

	const [confirming, setConfirming] = useState<boolean>(false);
	const [error, setError] = useState<any>(null);

	const close = async (confirm: boolean = false) => {
		if (confirm) {
			setConfirming(true);
			props.onConfirming?.call(this);
			try {
				await props.onConfirm();
				props.onConfirmed?.call(this);
			} catch (err) {
				setError(err);
				console.log(err);
			} finally {
				setConfirming(false);
			}
		} else {
			if (props.onCancel) {
				await props.onCancel();
			}
			setShow(false);
		}
	};

	const showCallback = useCallback(
		(show: boolean) => {
			setShow(show);
		},
		[setShow]
	);

	if (typeof props.show !== 'boolean') {
		props.show.current = showCallback;
	}

	return (
		<Modal container={modalsRoot} tabIndex={-1} aria-hidden="true" dialogClassName="modal-dialog" show={show} onHide={close} animation={false} scrollable={true} backdrop={true}>
			<Modal.Header closeButton>
				<Modal.Title>{props.title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{props.message}
				{error && <div className="alert alert-danger mt-3">{JSON.stringify(error?.message, null, 2)}</div>}
			</Modal.Body>
			<Modal.Footer className="justify-content-end">
				<div>
					<button type="button" className="btn btn-link me-5" onClick={() => close(false)}>
						{props.cancelText}
					</button>
					<SpinnerButton type="button" className={`btn btn-${props.dialogStyle}`} spinning={confirming} onClick={() => close(true)}>
						{props.confirmText}
					</SpinnerButton>
				</div>
			</Modal.Footer>
		</Modal>
	);
};

export type UseConfirmDialogResult = (options: ConfirmDialogOptions) => Promise<boolean>;

export function useConfirmDialog(): UseConfirmDialogResult {
	const {confirm} = useContext(ConfirmDialogContext);
	return confirm;
}

export interface ConfirmDialogContextValue {
	confirm: (options: ConfirmDialogOptions) => Promise<boolean>;
}

export const ConfirmDialogContext = createContext<ConfirmDialogContextValue>(undefined!);

type ConfirmDialogProviderProps = {
	children?: ReactNode | ReactNode[];
};

export const ConfirmDialogProvider: React.FC<ConfirmDialogProviderProps> = ({children}) => {
	const [current, setCurrent] = useState<React.ReactNode | null>(null);

	const confirm = (options: ConfirmDialogOptions): Promise<boolean> => {
		return new Promise<boolean>((resolve) => {
			const c = (
				<ConfirmDialog
					{...options}
					onConfirm={async () => {
						await options.onConfirm();
						resolve(true);
					}}
					onCancel={async () => {
						resolve(false);
					}}
					show={true}
				/>
			);

			setCurrent(c);
		}).finally(() => setCurrent(undefined));
	};

	const value: ConfirmDialogContextValue = {
		confirm: confirm,
	};

	return (
		<ConfirmDialogContext.Provider value={value}>
			{children}
			{current}
		</ConfirmDialogContext.Provider>
	);
};

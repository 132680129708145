/* eslint-disable jsx-a11y/anchor-is-valid */
import {EkoCard, EkoCardBody, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useEkosietAuth0} from "../../../modules/auth0/core/useEkosietAuth0";

type Props = {
    className: string
}

const MonsterDorpelWidget = ({className}: Props) => {
    const {isKlant} = useEkosietAuth0()
    return (
        <>
            <EkoCard>
                <EkoCardBody>
                    <div className='my-5'>
                        <div className='fs-2hx fw-bold text-center mx-3'>
                            <img
                                src={toAbsoluteUrl('/media/misc/BU-A67-114-4neuten-BI57-BU67-BI57.jpg')}
                                className='img-fluid'
                                alt=''
                            />
                        </div>
                    </div>
                </EkoCardBody>
            </EkoCard>
        </>
    )
}
export {MonsterDorpelWidget}

import {useGetCustomer} from '../hooks/use-get-customer'
import {FC, useState} from 'react'
import {CustomerAddressModalForm} from './CustomerAddressModalForm';
import {CustomerAddressRepresentation} from '../../../../modules/api-client/generated';
import {useDeleteCustomerAddress} from '../hooks/use-delete-customer-address';

type Props = {
    customerId: string | undefined | null,
    handleClose: () => void,
}

const CustomerAddressesModalBody: FC<Props> = ({customerId}: Props) => {

    const [existingAddress, setExistingAddress] = useState<CustomerAddressRepresentation | null>(null);
    const [deletedAddress, setDeletedCustomerAddress] = useState<CustomerAddressRepresentation | null>(null);
    const [addNewAddress, setAddNewAddress] = useState<boolean>(false);

    if (addNewAddress && existingAddress !== null) {
        setExistingAddress(null)
    }

    const {data, isLoading, error, refetch} = useGetCustomer(customerId || "")

    const editAddress = (address: CustomerAddressRepresentation) => {
        setExistingAddress(address);
        setDeletedCustomerAddress(null);
        setAddNewAddress(false);
    };

    const confirmDeleteAddress = (address: CustomerAddressRepresentation) => {
        setDeletedCustomerAddress(address);
        setExistingAddress(null);
        setAddNewAddress(false);
    };

    const mutateDeleteCustomerAddress = useDeleteCustomerAddress();

    const deleteAddress = async (address: CustomerAddressRepresentation) => {
        await mutateDeleteCustomerAddress([address.customerId || "", address.id || ""]);
        setDeletedCustomerAddress(null);
        refetch();
    };

    const onAddressPersisted = () => {
        setExistingAddress(null);
        setAddNewAddress(false);
        refetch();
    };

    if (customerId && !isLoading && !error && data) {

        let addressesCount = data?.addresses?.length;

        return (
            <>

                <div className={'row'}>
                    <div className={'col-4'}>
                        <div className=''>
                            {data?.addresses && data.addresses.map((address) => {
                                const id = `address-${address.id}`
                                return (
                                    <div className="bg-light mb-5 p-3" key={id}>
                                        <div className="d-flex justify-content-between align-items-start">
                                            <div>
                                                {address.contactPerson && <>{address.contactPerson?.firstName} {address.contactPerson?.lastName}<br/></>}
                                                {address.contactPerson && <>E.: {address.contactPerson?.email}
                                                    <br/>T.:{address.contactPerson?.telephone}<br/></>}
                                                <br/>
                                                {address.attention && <>{address.attention}<br/></>}
                                                {address.street} {address.houseNumber}{address.houseNumberAddition}<br/>
                                                {address.extraAddressLine && <>{address.extraAddressLine}<br/></>}
                                                {address.postalCode} {address.city} {address.country} {address.region && <>{address.region}</>}<br/>

                                                Leveringsadres: {(address.isDeliveryAddress) ? 'Ja' : 'Nee'}<br/>
                                                Factuuradres: {(address.isInvoicingAddress) ? 'Ja' : 'Nee'}
                                            </div>
                                            <div>
                                                <button className={'btn btn-sm btn-light me-2'}>
                                                    <i className="fa fa-pencil text-primary fa-lg"
                                                       aria-hidden="true" onClick={() => editAddress(address)}></i>
                                                </button>
                                                {((addressesCount !== undefined && addressesCount > 1) &&

                                                    <button className={'btn btn-sm btn-light'}>
                                                        <i className="fa fa-trash text-primary fa-lg"

                                                           aria-hidden="true"
                                                           onClick={() => confirmDeleteAddress(address)}></i>
                                                    </button>)}
                                            </div>
                                        </div>
                                        {deletedAddress && deletedAddress.id === address.id &&
                                            <div className="overlay theme-light-bg-body">
                                                <div className='center'>
                                                    <button
                                                        type='button'
                                                        className='btn btn-light me-3'
                                                        onClick={() => {
                                                            setDeletedCustomerAddress(null);
                                                        }}
                                                    >
                                                        Annuleren
                                                    </button>
                                                    <button
                                                        type='button'
                                                        className='btn btn-danger'
                                                        onClick={async () => await deleteAddress(address)}
                                                        disabled={addNewAddress}
                                                    >
                                                        Verwijderen
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                )
                            })
                            }
                        </div>
                        <button
                            type='button'
                            className='btn btn-primary'
                            onClick={() => {
                                setAddNewAddress(true);
                                setDeletedCustomerAddress(null);
                            }}
                            disabled={addNewAddress}
                        >
                            <i className={'fas fa-plus'}/>
                            Adres toevoegen
                        </button>
                    </div>
                    <div className={'col'}>
                        {((addNewAddress || existingAddress !== null) &&
                            <CustomerAddressModalForm
                                customerId={customerId}
                                existingAddress={existingAddress}
                                setExistingAddress={setExistingAddress}
                                addNewAddress={addNewAddress}
                                setAddNewAddress={setAddNewAddress}
                                onAddressPersisted={onAddressPersisted}
                            />
                        )}
                    </div>
                </div>
            </>
        )
    }

    return null;
}

export {CustomerAddressesModalBody}

/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'

type Props = {
  description: any
}

const RoleDescriptionCell: FC<Props> = ({description}) => {
  return (
    <>
      <div className='badge badge-light fw-bolder mr-2'>{description}</div>
    </>
  )
}

export {RoleDescriptionCell}

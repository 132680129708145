import {Link, useLocation} from 'react-router-dom'
import { KTSVG } from '../../../../../_metronic/helpers'
interface Props {  
  setCustomerId: React.Dispatch<React.SetStateAction<string | undefined | null>>,
  setShowCustomerModal: React.Dispatch<React.SetStateAction<boolean>>
}

const CustomerListHeader: React.FC<Props> = ({setCustomerId, setShowCustomerModal}: Props) => {
    const location = useLocation()
    const addCustomer = () => {
      setShowCustomerModal(true);
      setCustomerId(null);
    }
  return (
    <>
      <div className='card-header border-0 pt-6'>
        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
          <li className='nav-item'>
            <Link
              className={
                `nav-link text-active-primary me-6 ` +
                (location.pathname === '/b/crm/customers' && 'active')
              }
              to='/b/crm/customers'
            >
              Klanten
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              className={
                `nav-link text-active-primary me-6 ` +
                (location.pathname === '/b/crm/customers/archived' && 'active')
              }
              to='/b/crm/customers/archived'
            >
              Gearchiveerd
            </Link>
          </li>
        </ul>
      </div>
      <div className='card-header border-0 pt-6'>
        <div></div>
        {/* begin::Card toolbar */}
        <div className='card-toolbar'>
          {/* begin::Group actions */}
            <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
            {(location.pathname !== '/b/crm/customers/archived' &&
              <button type='button' className='btn btn-primary' onClick={addCustomer}>
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                Klant toevoegen
              </button>
              )
            }
            </div>
          </div>
          {/* end::Group actions */}

        {/* end::Card toolbar */}
      </div>
    </>
  )
}

export {CustomerListHeader}

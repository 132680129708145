import React from "react";
import {modalsRoot} from "../../../../../global-helpers";
import {Button, Modal} from "react-bootstrap";
import {Form, Formik} from "formik";
import SpinnerButton from "../../../../../shared/components/SpinnerButton";
import TextField from "../../../../../shared/components/TextField";
import {AddNeutPosition, SillDetails} from "../../../../../modules/api-client/generated";

interface AddNeutPositionModalProps {
    sill: SillDetails;
    onSave: SaveHandler;
    onClose: () => void;
    show: boolean;
}

export interface AddNeutPositionModalFormValues {
    name: string;
    indentInside?: number;
    indentOutside?: number;
}

export type SaveHandler = (neutPosition: AddNeutPosition) => Promise<void>;

export const useAddNeutPositionModal = (options: { sill: SillDetails, onSave: SaveHandler }) => {

    const [show, setShow] = React.useState(false);


    const onClose = () => {
        setShow(false);
    }

    const onSave = async (neutPosition: AddNeutPosition) => {
        console.log(neutPosition);
        await options.onSave(neutPosition)
        setShow(false);

        console.log('saved');
    }

    const element = <AddNeutPositionModal
        show={show}
        sill={options.sill}
        onClose={onClose}
        onSave={onSave}/>;

    return {
        show,
        open: () => setShow(true),
        modal: element
    }
}

export const AddNeutPositionModal: React.FC<AddNeutPositionModalProps> = (props) => {

    const onClose = () => {
        props.onClose();
    };
    const cancel = () => {
        props.onClose();
    };

    const onSubmit = async (values: AddNeutPositionModalFormValues): Promise<void> => {
        await props.onSave({
            name: values.name!,
            indention: {
                inside: Number(values.indentInside!),
                outside: Number(values.indentOutside!)
            }
        });
        props.onClose();
    };

    const initialValues: AddNeutPositionModalFormValues = {
        name: '',
        indentInside: undefined,
        indentOutside: undefined,
    }

    return <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({isSubmitting}) =>

            <Modal
                container={modalsRoot}
                tabIndex={-1}
                aria-hidden='true'
                autoFocus={true}
                dialogClassName={'modal-dialog'}
                scrollable={true}
                show={props.show}
                size='lg'
                onHide={onClose}
                animation={false}
                backdrop={'static'}>
                <Form>

                    <Modal.Header closeButton>
                        <Modal.Title>Neut positie toevoegen</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <TextField row={true} label={"Naam"} name={'name'} required autoFocus/>
                        <TextField row={true} label={"Inspringen binnen"} name={'indentInside'} required
                                   type="number"
                                   inputGroupSuffixText="mm"/>
                        <TextField row={true} label={"Inspringen buiten"} name={'indentOutside'} required
                                   type="number"
                                   inputGroupSuffixText="mm"/>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="link" className="mx-4" onClick={cancel}>
                            Annuleren
                        </Button>
                        <SpinnerButton type="submit" className="btn btn-primary" spinning={isSubmitting}
                                       disabled={isSubmitting}>
                            Toevoegen
                        </SpinnerButton>
                    </Modal.Footer>
                </Form>
            </Modal>
        }
    </Formik>
};

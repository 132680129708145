/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import {Modal} from 'react-bootstrap';
import {modalsRoot} from '../../../global-helpers';
import React, {PropsWithChildren} from 'react';
import {AllProfileFacets, ProfilePicker, ProfilePickerHandle, ProfilePickerProps, QueryCallback} from '../../../backoffice/modules/pim/profile/ProfilePicker';
import ApiClient from '../../../modules/api-client/ApiClient';
import ProfileMatchPreviewSvg from '../../components/ProfileMatchPreviewSvg';
import {CreateImportProfileMappingCommand, ImportProfileMappingRepresentation} from '../../../modules/api-client/generated';
import {usePostImportProfileMapping} from '../hooks/use-post-import-profile-mapping';
import {useDeleteImportProfileMapping} from '../hooks/use-delete-import-profile-mapping';

type ProfileMatcherModalProps = {
	profile: ImportProfileMappingRepresentation;
	show: boolean;
	handleClose: () => void;
};

const ProfileMapperModal = ({show, handleClose, children, profile}: PropsWithChildren<ProfileMatcherModalProps>) => {
	const profilePicker = React.createRef<ProfilePickerHandle>();
	var mutationPostImportMappingProfile = usePostImportProfileMapping();
	var mutationDeleteImportMapping = useDeleteImportProfileMapping();

	const queryCallback: QueryCallback = async (query) => {
		return await ApiClient.Pim.Configurator.searchProfiles(
						profile.sill.id!, 
						profile.neutPositionId!, 
						profile?.compartmentLayoutType.code!, 
						profile?.customer.id!, 
						undefined, 
						query.filters).then((res) => res.data
		);
	};

	const pickerPops: ProfilePickerProps = {
		query: queryCallback,
		multi: false,
		visibleFacets: AllProfileFacets,
		initialProfileId: profile?.mappedProfileId,
		onConfirm: async (selection) => {
			if (profile && selection && selection.length) {
				var command: CreateImportProfileMappingCommand = {
					kind: 'definitive', //todo get from form
					customerId: profile.customer.id!,
					compartmentLayoutTypeId: profile.compartmentLayoutType.id!,
					neutPositionId: profile?.neutPositionId!,
					sillId: profile.sill.id!,
					profileId: selection[0].id,
					profileGeometry: profile.profileGeometryAsString!,
				};

				await mutationPostImportMappingProfile.mutateAsync(command);

				profile.mappedProfileGeometryAsString = selection[0].geometry;
			}
		},
	};

	return (
		<Modal
			container={modalsRoot}
			tabIndex={-1}
			aria-hidden="true"
			autoFocus={true}
			className=""
			dialogClassName={'modal-dialog-scrollable'}
			contentClassName={'shadow-lg vh-100'}
			show={show}
			size="xl"
			fullscreen={true}
			onHide={handleClose}
			animation={false}
			backdrop={true}
		>
			<Modal.Header closeButton>
				<Modal.Title>
					Koppel profiel voor vak {profile.compartmentLayoutType.name} op dorpel {profile.sill.code}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<>
					<div className="row">
						<div className="col-4">
							<div className={'d-flex  flex-column justify-content-end text-center align-items-center sticky-top'}>
								<div>
									<ProfileMatchPreviewSvg profileA={profile.profileGeometryAsString} profileB={profile?.mappedProfileGeometryAsString} />
								</div>
								<div className={'d-flex'}>
									<div className="p-3 mx-3 text-wrap d-flex flex-column">
										Dorpel
										<h1>{profile.sill.code}</h1>
									</div>
									<div className="p-3 mx-3  text-wrap d-flex flex-column">
										Vakindeling
										<h1>{profile.compartmentLayoutType.name}</h1>
									</div>
								</div>

								<div className="p-3 mx-3 text-wrap d-flex flex-column">
									GA geometrie <h3>{profile.profileGeometryAsString}</h3>
								</div>
							</div>
						</div>
						<div className={'col'}>
							<div>
								<ProfilePicker ref={profilePicker} {...pickerPops} />
							</div>
						</div>
					</div>
				</>
			</Modal.Body>
			<Modal.Footer>
				<button type="button" className={`btn btn-light`} onClick={handleClose}>
					Sluit
				</button>
				{profile.mappedProfileId && (
					<button
						type="button"
						className={`btn btn-light me-2`}
						title="Verwijder mapping"
						onClick={async () => {
							if (profile.importProfileMappingId) {
								await mutationDeleteImportMapping.mutateAsync(profile.importProfileMappingId);
								handleClose();
							}
						}}
					>
						{mutationDeleteImportMapping.isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <i className="fas fa-trash"></i>}
						Reset koppeling
					</button>
				)}
				<button type="button" className={`btn btn-primary`} onClick={handleClose}>
					Koppel dit profiel
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export {ProfileMapperModal};

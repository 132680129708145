import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast"
import ApiClient from "../../../../../modules/api-client/ApiClient";
import { useEkosietMutation } from "../../../../../shared/hooks/useEkosietMutation";
import { NotFound, ValidationFailed } from "../../../../../modules/api-client/Responses";

export const usePutQuoteRejected = (id: string) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<void, NotFound | ValidationFailed, undefined>(() => {
        return ApiClient.Sm.Order.smOrderIdRejectQuotePut(id, undefined, undefined).then();
    },
    {
        onSuccess: () => {
            queryClient.invalidateQueries(['smOrderIdGet', id]).then();
            queryClient.invalidateQueries(['OrderActionLog', id]).then();

            toast.success("Offerte afgewezen");

        },
        onError: () => {
            toast.error("Offerte Afwijzen mislukt!");
        }
    }
    );
};

import React from 'react';
import {Field} from 'formik';
import {GlazingBar} from '../../../../modules/api-client/generated';
import {useQuery} from '@tanstack/react-query';
import ApiClient from '../../../../modules/api-client/ApiClient';
import CustomSelect, {SelectOption, OptionsType} from '../../../../shared/components/CustomSelect';
import {FieldProps} from 'formik/dist/Field';

export interface GlazingBarPickerSelectProps {
	name: string;
	multi?: boolean;
	required?: boolean;
}


export const GlazingBarPicker = ({required, isMulti}: {required?: boolean, isMulti?: boolean}): React.ComponentType<FieldProps<string>> => {

	let options: OptionsType<SelectOption> = [];

	const {data: list} = useQuery<GlazingBar[]>(['pimGlazingBarsGet'], () => ApiClient.Pim.GlazingBar.pimGlazingBarsGet().then((res) => res.data));


	if (list) {
		options = list.map((item) => {
			return {value: item.id!, label: item.name!};
		});
	}

	required ??= true;

	return (props: FieldProps<string>) => <CustomSelect<SelectOption> options={options} isMulti={isMulti ?? false} required={required} isClearable={!required}
														placeholder={'Selecteer...'} {...props} />;
};

export const GlazingBarPickerField: React.FC<GlazingBarPickerSelectProps> = ({name, multi, required}) => {

	let options: OptionsType<SelectOption> = [];

	const {
		data: list,
	} = useQuery<GlazingBar[]>(['pimGlazingBarsGet'], () => ApiClient.Pim.GlazingBar.pimGlazingBarsGet().then((res) => res.data));


	if (list) {
		options = list.map((item) => {
			return {value: item.id!, label: item.name!};
		});
	}

	return (
		<>
			<Field
				className='custom-select'
				name={name}
				options={options}
				component={
					CustomSelect
				}
				placeholder={multi ? 'Kies een of meerdere glaslatten...' : 'Kies een glaslat'}
				isMulti={multi}
				isClearable={!required}
			/>
		</>
	);
};


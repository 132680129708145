import clsx from 'clsx';
import {FC, useEffect, useRef} from 'react'
import {useListCustomers} from '../hooks/use-list-customers';
import {CustomerListRepresentation} from '../../../../modules/api-client/generated';
import Select from "react-select"
import { FilterParameter, useEkosietFilter } from '../../../../shared/context/FilterContext';

type Props = {
    fieldName: string;    
    onChange?: (value: any) => void;
    
}

export const CustomerFilter: FC<Props> = (props: Props) => {

    const { setFilters, containsFilter, resetCount } = useEkosietFilter()
    const selectInputRef = useRef<any>();

    let filterValue = {value: '', label: ''}
    
    const {
        data: customerListData,
        isLoading: isLoadingListCustomers,
        isError: isErrorListCustomers
    } = useListCustomers(undefined, false);

    let customerListOptions: any[] = []
    if (customerListData) {
        let customerOptions = customerListData.map((item: CustomerListRepresentation) => {
            return {value: item.id, label: item.companyName}
        });

        customerListOptions = [...customerListOptions, ...customerOptions]
    }

    useEffect(() => {        
        if (resetCount) {
            selectInputRef.current.clearValue();
        }
      }, [resetCount])
    
    return (
        <>
                    <Select
                        ref={selectInputRef}
                        styles={{
                            // Fixes the overlapping problem of the component
                            menu: provided => ({ ...provided, zIndex: 9999 })
                          }}
                        classNamePrefix="select"

                        placeholder="Selecteer een klant"
                        classNames={{
                            control: (state) =>
                                clsx(
                                    'form-control p-1 border-secondary',
                                ),
                            singleValue: (state) =>
                            {
                                return clsx(
                                    'text-gray-700',
                                )
                            }
                        }}
                        isClearable={true}
                        isSearchable={true}                        
                        name={props.fieldName}
                        options={customerListOptions}                        
                        onChange={(newValue) => {

                            if (newValue) {
                                const filter: FilterParameter = {
                                    filterExpression:  { CustomerId: { eq: { type: 'guid', value: newValue.value } } },
                                    filterName: props.fieldName,
                                    values: [newValue.value]
                                }

                                setFilters(props.fieldName, [filter])
                            } else {
                                if(containsFilter(props.fieldName)) {
                                    setFilters(props.fieldName, [])
                                }
                            }
                        }}
                    />
        </>
    )
}

import { useQuery } from "@tanstack/react-query";
import ApiClient from "../../../../../modules/api-client/ApiClient";
import { ExpeditionItemsRepresentation } from "../../../../../modules/api-client/generated";

export const useGetExpeditionItems = (orderId: string | undefined) => {
    return useQuery<ExpeditionItemsRepresentation, TypeError, ExpeditionItemsRepresentation>(
        ['emExpeditionExpeditionItemsOrderIdGet', orderId],
        () => {
            return ApiClient.Em.Expedition.emExpeditionExpeditionItemsOrderIdGet(orderId!).then((res) => res.data);
        }, {
        useErrorBoundary: true,
        enabled: !!orderId,
    });
}
import React, {useRef} from 'react';
import {Link, useParams, useSearchParams} from 'react-router-dom';
import {EkoCardHeader, EkoCardBody, EkoCard, EkoCardToolbar} from "../../../../shared/components/card";
import Loading from "../../../../shared/components/Loading";
import Error from "../../../../shared/components/Error";
import {EkoTable, TableHeader} from "../../../../shared/components/table";
import ProfilePreviewSvg from "../../../../shared/components/ProfilePreviewSvg";
import {PimRoutePath} from "../PimRoutes";
import {useSillProfiles} from "../sill/hooks/use-sill-profiles";
import {useConfirmDialog} from "../../../../shared/components/ConfirmDialog";
import {useProfilePickerModal} from "../profile/ProfilePickerModal";
import {AllProfileFacets, QueryCallback} from "../profile/ProfilePicker";
import {CompartmentLayoutTypeCode} from "../../../../modules/api-client/generated";
import ApiClient from "../../../../modules/api-client/ApiClient";
import BooleanBadge from "../../../../shared/components/BooleanBadge";

const SillProfilesPage: React.FC = () => {

    const {id} = useParams<{ id: string }>();
    const [searchParams] = useSearchParams();

    const neutPositionId = searchParams.get('neutPositionId');

    const compartmentLayoutTypeId = searchParams.get('compartmentLayoutTypeId')!;

    const compartmentLayoutTypeRef = useRef<CompartmentLayoutTypeCode | null>(null);

    const confirmDialog = useConfirmDialog();

    const {
        isInitialLoading,
        isError,
        profiles,
        addProfiles,
        removeProfiles
    } = useSillProfiles({
        sillId: id!,
        neutPositionId: neutPositionId!,
        compartmentLayoutTypeId: compartmentLayoutTypeId,
        onProfilesLoaded: (profiles) => {
            compartmentLayoutTypeRef.current = profiles.compartmentLayoutType.code;
        }
    });

    const queryCallback: QueryCallback = (query) =>
        ApiClient.Pim.SillProfiles.searchSillProfiles(id!, neutPositionId!, compartmentLayoutTypeId, undefined, true, query.filters).then((res) => res.data);

    const profilePickerModal = useProfilePickerModal({
        title: 'Selecteer profielen',
        confirmText: 'Toevoegen',
        pickerProps: {
            query: queryCallback,
            multi: true,
            visibleFacets: AllProfileFacets,
        },
        onSelected: async (selection) => {
            await addProfiles(selection.map(p => p.id));
        }
    });

    if (isInitialLoading || !profiles) return <Loading/>;

    if (isError) return <Error/>;

    const deleteProfile = async (profileId: string) => {
        await confirmDialog({
            onConfirm: async () => await removeProfiles([profileId]),
            title: 'Profiel verwijderen',
            message: 'Weet u zeker dat u dit profiel wilt verwijderen? Dit kan niet ongedaan worden gemaakt. Dit verwijdert ook eventuele Groeneveld neut vak profiel mappings!',
            dialogStyle: 'danger'
        });
    };

    return <>

        {profilePickerModal.element}

        <div className="mb-4">
            <Link to={PimRoutePath.path(PimRoutePath.sillDetails, {id: id})}>Terug naar Dorpel</Link>
        </div>

        <div className="row">
            <div className="col-12 col-xl-6 col-lg-8">
                <EkoCard className={'mb-3'}>
                    <EkoCardHeader
                        title={`Profielen ${profiles.sill.name} - ${profiles.neutPosition.name} - ${profiles.compartmentLayoutType.name}`}>
                        <EkoCardToolbar>
                            <button type="button" className="btn btn-primary btn-sm"
                                    onClick={() => profilePickerModal.open()}>Profielen toevoegen
                            </button>
                        </EkoCardToolbar>
                    </EkoCardHeader>
                    <EkoCardBody>
                        <EkoTable>
                            <TableHeader>
                                <th colSpan={2}>Profiel</th>
                                <th></th>
                                <th></th>
                            </TableHeader>
                            <tbody>
                            {profiles.profiles.map((p) => {
                                return <tr key={p.profileId}>
                                    <td className="fit-content">
                                        <div style={{width: '60px', height: '80px'}}>
                                            <ProfilePreviewSvg profileSvg={p.svgPreview}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div>{p.cornerProfileInsideName ?? 'Geen'}</div>
                                        <div>{p.rabbetName ?? 'Recht'}</div>
                                        <div>{p.cornerProfileOutsideName ?? 'Geen'}</div>
                                    </td>
                                    <td className='fit-content text-center'>
                                        <BooleanBadge value={p.active}
                                                      trueText="Actief"
                                                      falseText="Inactief"
                                                      falseClass={p.active ? 'bg-danger' : 'bg-warning'}/>
                                    </td>
                                    <td className="fit-content">

                                        <div className="dropdown">
                                            <a className="dropdown-toggle text-body px-2" href="#" role="button"
                                               data-bs-toggle="dropdown" aria-expanded="false">
                                            </a>

                                            <ul className="dropdown-menu dropdown-menu-end">
                                                <li>
                                                    <button type="button" className="dropdown-item text-danger"
                                                            onClick={async () => await deleteProfile(p.profileId)}>
                                                        Profiel verwijderen
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>

                                    </td>
                                </tr>
                            })}
                            </tbody>
                        </EkoTable>
                    </EkoCardBody>
                </EkoCard>
            </div>
        </div>

    </>
};

export default SillProfilesPage;

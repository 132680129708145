import {Chart, ChartOptions} from "chart.js";

export const CHART_COLORS = {
    red: 'rgb(255, 99, 132)',
    orange: 'rgb(255, 159, 64)',
    yellow: 'rgb(255, 205, 86)',
    green: 'rgb(75, 192, 192)',
    blue: 'rgb(54, 162, 235)',
    purple: 'rgb(153, 102, 255)',
    grey: 'rgb(201, 203, 207)'
};

export const StatsLineChartOptions: any  = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
            labels: {
                backgroundColor: '#9BD0F5',
                borderColor: '#36A2EB',
                color: '#fff',
            }
        },
        title: {
            display: false,
        },
    },
    scales: {
        x: {
            border: {
                display: false,
            },
            ticks: {
                display: false,
                color: '#ffffff',
            },
            grid: {
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
            }
        },
        y: {
            border: {
                display: false
            },
            ticks: {
                display: false,
                color: '#ffffff',
            },
            grid: {
                display: false,
            }
        }
    }
}



import {useQueryClient} from "@tanstack/react-query";
import {toast} from "react-hot-toast"
import ApiClient from "../../../../../modules/api-client/ApiClient";
import {
    OrderAddressRepresentation,    
    OrderRepresentation,
} from "../../../../../modules/api-client/generated";
import {useEkosietMutation} from "../../../../../shared/hooks/useEkosietMutation";
import {NotFound, ValidationFailed} from "../../../../../modules/api-client/Responses";

export enum OrderAddressTypes {
    financial="financial",
    expedition="expedition",
}

export const usePutOrderAddress = (id: string, type: OrderAddressTypes) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<OrderRepresentation, NotFound | ValidationFailed, OrderAddressRepresentation>((model: OrderAddressRepresentation) => {

            if (type === OrderAddressTypes.financial) {
                return ApiClient.Sm.Order.smOrderIdFinancialAddressPut(id, undefined, model).then(response => response.data);
            } else {
                return ApiClient.Sm.Order.smOrderIdExpeditionAddressPut(id, undefined, model).then(response => response.data);
            }
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['smOrderCustomerPut', id]).then();
                queryClient.invalidateQueries(['smOrderIdGet', id]).then();
                toast.success("Adres aangepast.");

            },
            onError: () => {
                toast.error("Adres wijzigen mislukt!");
            }
        }
    );
};

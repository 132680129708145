import React from "react";
import {Field} from "formik";
import {LProfile} from "../../../../modules/api-client/generated";
import {useQuery} from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import CustomSelect, {SelectOption, OptionsType} from "../../../../shared/components/CustomSelect";

export interface LProfilePickerProps {
    name: string;
    multi?: boolean;
    required?: boolean;
}

export const LProfilePicker: React.FC<LProfilePickerProps> = ({name, multi, required}) => {

    let options: OptionsType<SelectOption> = [];

    const {
        data: list
    } = useQuery<LProfile[]>(['pimLProfilesGet'], () => ApiClient.Pim.LProfile.pimLProfilesGet().then((res) => res.data));


    if (list) {
        options = list.map((item) => {
            return {value: item.id!, label: item.name!}
        })
    }

    return (
        <>
            <Field
                className="custom-select"
                name={name}
                options={options}
                component={CustomSelect}
                placeholder={multi ? "Kies een of meerdere L-profielen..." : 'Kies een L-profiel'}
                isMulti={multi}
                isClearable={!required}
            />
        </>
    )
}
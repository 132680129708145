import React, {ReactElement, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useMutation, useQuery} from '@tanstack/react-query';
import ApiClient from '../../../../modules/api-client/ApiClient';
import {Link, useSearchParams} from 'react-router-dom';

import {ProfileSearchResult, ProfileInfo} from '../../../../modules/api-client/generated';
import ActiveBadge from '../../../../shared/components/ActiveBadge';
import {EkoCard, EkoCardBody, KTSVG} from '../../../../../_metronic/helpers';
import {EkoTable, TableHeader} from '../../../../shared/components/table';
import BooleanBadge from '../../../../shared/components/BooleanBadge';
import ProfilePreviewSvg from '../../../../shared/components/ProfilePreviewSvg';
import {ProfileUpsert} from '../profile/ProfileUpsert';
import {PageTitle} from '../../../../../_metronic/layout/core';
import {ProfileEditModal} from '../profile/ProfileEditModal';
import ProfilesUpsert from '../profile/ProfilesUpsert';
import SpinnerButton from '../../../../shared/components/SpinnerButton';
import clsx from 'clsx';
import Loading from '../../../../shared/components/Loading';
import {BulkActivateModal, BulkActivateModalProps} from '../profile/BulkActivateModal';
import {BulkDeactivateModal, BulkDeactivateModalProps} from '../profile/BulkDeactivateModal';
import {toast} from 'react-hot-toast';
import {PimRoutePath} from '../PimRoutes';
import {useConfirmDialog} from '../../../../shared/components/ConfirmDialog';

const ProfileList: React.FC = () => {
    const [showUpsertModal, setShowUpsertModal] = useState<boolean>(false);
    const [modalTitle, setModalTitle] = useState<string | undefined | null>(null);
    const [modalComponent, setModalComponent] = useState<ReactElement | undefined | null>(null);

    const [activateModal, setActivateModal] = useState<Pick<BulkActivateModalProps, 'show'>>({
        show: false,
    });
    const [deactivateModal, setDeactivateModal] = useState<Pick<BulkDeactivateModalProps, 'show'>>({
        show: false,
    });

    const [selection, setSelection] = useState<Map<string, ProfileInfo>>(new Map<string, ProfileInfo>());

    const [active, setActive] = useState<boolean | undefined>();
    const [hasProgram, setHasProgram] = useState<boolean | undefined>();

    const [searchParams, setSearchParams] = useSearchParams();

    const [filters, setFilters] = useState<string[]>(() => {
        return searchParams.getAll('f');
    });

    const [profileList, setProfileList] = useState<ProfileSearchResult | null>(null);

    const {isInitialLoading, refetch, isError} = useQuery<ProfileSearchResult>(
        ['ApiClient.Pim.Profile.listProfiles', filters],
        () => ApiClient.Pim.Profile.listProfiles(undefined, filters, active, hasProgram).then((res) => res.data),
        {
            refetchOnWindowFocus: false,
            onSuccess: (data) => setProfileList(data),
        },
    );


    const reindexMutation = useMutation(
        async () => {
            await ApiClient.Pim.SystemAdmin.pimSystemReindexProfilesPost();
        },
        {
            onSuccess: async () => {
                await refetch();
            },
            onError: () => {
                toast.error('Fout bij het herindexeren van profielen');
            },
        },
    );

    const facets = useMemo(() => Object
            .values(profileList?.facets ?? {})
            .sort((a, b) => a.sortOrder - b.sortOrder)
            .filter(x => x.value !== 'RabbetSize' && x.value !== 'Turbohol' && x.value !== 'SillId'),
        [profileList]);

    const edit = (data: ProfileInfo) => {
        setModalComponent(<ProfileUpsert id={data.id!} handleClose={() => setShowUpsertModal(false)}/>);
        setModalTitle(data.name + ' aanpassen');
        setShowUpsertModal(true);
    };
    const bulkEdit = () => {
        setModalComponent(<ProfilesUpsert ids={Array.from(selection.keys())}
                                          handleClose={() => setShowUpsertModal(false)}/>);
        setModalTitle('Bulk aanpassen');
        setShowUpsertModal(true);
    };
    const handleSelection = (selected: boolean, profile: ProfileInfo) => {
        setSelection((selection) => {
            let newSelection = new Map(selection);
            if (selected) {
                newSelection.set(profile.id, profile);
            } else {
                newSelection.delete(profile.id);
            }

            return newSelection;
        });
    };

    const selectAll = useCallback(() => {
        setSelection(() => {

            let newSelection = new Map();

            profileList?.profiles.forEach((profile) => {
                if (!newSelection.has(profile.id)) {
                    newSelection.set(profile.id, profile);
                }
            });

            return newSelection;
        });
    }, [profileList, setSelection]);

    const allSelected = useMemo(() => profileList?.profiles.filter((x) => !selection.has(x.id)).length === 0, [selection, profileList]);
    const selectAllRef = useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;

    useEffect(() => {
        refetch();
    }, [active, hasProgram]);

    const selectNone = useCallback(() => {
        setSelection((selection) => {
            if (selection.size > 0) {
                return new Map<string, ProfileInfo>();
            }
            return selection;
        });
    }, [setSelection]);

    const toggleSelect = useCallback(() => (selectAllRef?.current?.checked ? selectAll() : selectNone()), [selectAllRef, selectAll, selectNone]);

    useEffect(() => {
        if (!selectAllRef?.current) return;

        const current = selectAllRef.current;

        current.addEventListener('change', toggleSelect);

        return () => {
            current.removeEventListener('change', toggleSelect);
        };
    }, [selectAllRef, toggleSelect]);

    useEffect(() => {
        if (!selectAllRef?.current) return;

        selectAllRef.current.indeterminate = !allSelected && selection.size > 0;
    }, [selection, allSelected, selectAllRef]);

    const onResetAllFilters = useCallback(async () => {
        setFilters([]);
        setSearchParams({});
        refetch().then();
        return false;
    }, [refetch, setSearchParams]);

    const onResetFilter = useCallback(
        async (tag: string) => {
            setFilters((filters) => {
                let newFilters = filters.filter((x) => !x.startsWith(`${tag}:`));
                const f: {} = newFilters.length === 0 ? {} : {f: newFilters};
                setSearchParams(f);
                return newFilters;
            });

            refetch().then();
        },
        [setFilters, refetch, setSearchParams],
    );

    const onSelectAllFilter = useCallback(
        async (tag: string) => {
            setFilters((newFilters) => {
                Object.values(profileList?.facets ?? [])
                    .filter((x) => x.value === tag)
                    .forEach((facet) => {
                        facet.labels.forEach((v) => newFilters.push(`${tag}:${v.value}`));
                    });
                const f: {} = newFilters.length === 0 ? {} : {f: newFilters};
                setSearchParams(f);
                return newFilters;
            });

            refetch().then();
        },
        [setFilters, refetch, setSearchParams],
    );

    const onFilterChange = useCallback(
        async (tag: string, bucketShortId: string, checked: boolean) => {
            setFilters((filters) => {
                const name = `${tag}:${bucketShortId}`;

                let list = filters;
                if (checked && filters.indexOf(name) === -1) {
                    list = [...filters, name];
                } else if (!checked && filters.indexOf(name) !== -1) {
                    list = filters.filter((x) => x !== name);
                }

                const f: {} = list.length === 0 ? {} : {f: list};
                setSearchParams(f);

                return list;
            });

            refetch().then();
        },
        [refetch, setSearchParams],
    );

    if (isInitialLoading && !profileList) {
        return <Loading/>;
    }

    if (isError) {
        return <>ERROR!</>;
    }

    return (
        <>
            <PageTitle
                toolbar={
                    <>
                        <div className='gap-2 d-flex'>
                            {/*<SpinnerButton*/}
                            {/*	onClick={async () => await generateMutation.mutateAsync(undefined, undefined)}*/}
                            {/*	className='btn btn-primary'*/}
                            {/*	spinning={generateMutation.isLoading}*/}
                            {/*	disabled={generateMutation.isLoading}*/}
                            {/*	title='Werk profielen bij o.b.v. wijzigingen in de sponningen.'>Synchroniseren</SpinnerButton>*/}

                            <SpinnerButton
                                onClick={async () => await reindexMutation.mutateAsync(undefined, undefined)}
                                className='btn btn-primary'
                                spinning={reindexMutation.isLoading}
                                disabled={reindexMutation.isLoading}>
                                Herindexeren
                            </SpinnerButton>

                            {/*<button onClick={add} className='btn btn-primary'>*/}
                            {/*	Toevoegen*/}
                            {/*</button>*/}

                            <div className='dropdown'>
                                <button type='button' className='btn btn-primary dropdown-toggle' role='button'
                                        data-bs-toggle='dropdown' aria-expanded='false'>
                                    Toevoegen
                                </button>

                                <ul className='dropdown-menu'>
                                    <li><Link to={PimRoutePath.link(PimRoutePath.profilesAdd)}
                                              className='dropdown-item'>Vakprofielen</Link></li>
                                    {/*<li><a className="dropdown-item" href="#">Another action</a></li>*/}
                                    {/*<li><a className="dropdown-item" href="#">Something else here</a></li>*/}
                                </ul>
                            </div>

                            <ProfileListExtraMenu/>
                        </div>
                    </>
                }
                breadcrumbs={[]}
            >
                Profielen
            </PageTitle>

            <ProfileEditModal show={showUpsertModal} component={modalComponent} title={modalTitle} size={'lg'}
                              handleClose={() => setShowUpsertModal(false)}/>

            <div className={'row'}>
                <div className={'col-12 col-lg-4'}>
                    <EkoCard>
                        <EkoCardBody>
                            <div className={'d-flex justify-content-between align-items-start mb-3'}>
                                <h3>Filters</h3>
                                {filters.length > 0 && (
                                    <button onClick={onResetAllFilters}
                                            className='btn btn-link btn-sm p-0 link-primary'>
                                        Reset alle filters
                                    </button>
                                )}
                            </div>
                            <div className='row'>
                                {facets.length > 0 && facets.map((t, index) => (
                                    <div key={`facet-${index}`} className='col-12 mb-10 d-flex flex-column '>
                                        <div className={'d-flex justify-content-between align-items-start'}>
                                            <label className='form-label text-nowrap'>{t.name}</label>
                                            <div>
                                                <button onClick={() => onSelectAllFilter(t.value)}
                                                        className='btn btn-link btn-sm p-0 link-primary'>
                                                    <KTSVG path={'/media/icons/duotune/general/gen043.svg'}
                                                           className=''/>
                                                </button>
                                                <button onClick={() => onResetFilter(t.value)}
                                                        className='btn btn-link btn-sm p-0 link-primary'>
                                                    <KTSVG path={'/media/icons/duotune/general/gen040.svg'}
                                                           className=''/>
                                                </button>
                                            </div>
                                        </div>
                                        <div className={'bg-light p-3 border-1 border-dark'}
                                             style={{maxHeight: '200px', overflowY: 'scroll'}}>
                                            {t.labels.map((b) => (
                                                <div key={b.value}>
                                                    <label
                                                        className='w-100 h-100 cursor-pointer text-nowrap d-flex justify-content-between align-items-center'>
															<span className='d-flex align-items-center'>
																<input
                                                                    className='form-check-input m-0 my-1'
                                                                    type='checkbox'
                                                                    checked={filters.indexOf(`${t.value}:${b.value}`) !== -1}
                                                                    onChange={(e) => onFilterChange(t.value, b.value, e.target.checked)}
                                                                    value={b.value}
                                                                    disabled={b.count === 0}
                                                                />
																<span
                                                                    className={`ms-2 ${b.count === 0 ? 'text-muted' : ''}`}>{b.name}</span>
															</span>
                                                        <small className='ms-2 text-muted'>({b.count})</small>
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </EkoCardBody>
                    </EkoCard>
                </div>
                <div className={'col-12 col-lg-8'}>
                    <EkoCard>
                        <EkoCardBody>
                            <div className={'d-flex align-items-center text-nowrap'}>
                                {profileList &&
                                    <div className='text-muted me-3'>{profileList.totalHits} profielen</div>}
                                <div className={'d-flex align-items-center justify-content-between w-100'}>
                                    {selection && (
                                        <div className='d-flex align-items-center  '>
                                            {selection.size > 0 && (
                                                <span onClick={() => setSelection(new Map())}>
													<KTSVG path={'/media/icons/duotune/general/gen040.svg'}
                                                           className='svg-icon-2'/>
												</span>
                                            )}
                                            <span className={'me-2'}> {selection.size} geselecteerd</span>

                                            <div>
                                                <BulkActivateModal
                                                    {...activateModal}
                                                    onClose={() =>
                                                        setActivateModal((current) => ({
                                                            ...current,
                                                            show: false,
                                                        }))
                                                    }
                                                    onSuccess={() => refetch()}
                                                    selectionFilters={filters}
                                                />

                                                <BulkDeactivateModal
                                                    {...deactivateModal}
                                                    onClose={() =>
                                                        setDeactivateModal((current) => ({
                                                            ...current,
                                                            show: false,
                                                        }))
                                                    }
                                                    onSuccess={() => refetch()}
                                                    selectionFilters={filters}
                                                />

                                                <button
                                                    type='button'
                                                    className='btn btn-light-primary btn-sm mx-1'
                                                    onClick={() =>
                                                        setActivateModal((c) => ({
                                                            ...c,
                                                            show: true,
                                                        }))
                                                    }
                                                >
                                                    Activeren
                                                </button>
                                                <button
                                                    type='button'
                                                    className='btn btn-light-primary btn-sm mx-1'
                                                    onClick={() =>
                                                        setDeactivateModal((c) => ({
                                                            ...c,
                                                            show: true,
                                                        }))
                                                    }
                                                >
                                                    Deactiveren
                                                </button>
                                            </div>

                                            {/*<div className="dropdown">*/}
                                            {/*    <button className={"btn btn-light-primary btn-sm dropdown-toggle"}*/}
                                            {/*            type="button"*/}
                                            {/*            data-bs-toggle="dropdown" aria-expanded="false">*/}
                                            {/*        Bulk actie(s)*/}
                                            {/*    </button>*/}
                                            {/*    <ul className="dropdown-menu">*/}
                                            {/*        <li className="dropdown-item" onClick={() => bulkEdit()}>Status</li>*/}
                                            {/*    </ul>*/}
                                            {/*</div>*/}
                                        </div>
                                    )}

                                    <div className={'d-flex'}>
                                        <button
                                            className={clsx('btn btn-sm me-3 ', {'btn-light': active === undefined}, {'btn-light-success': active}, {'btn-light-danger': active === false})}
                                            onClick={() => setActive(active === undefined ? true : active ? false : undefined)}
                                        >
                                            Actief/Inactief
                                        </button>
                                        <button
                                            className={clsx(
                                                'btn btn-sm me-3 ',
                                                {'btn-light': hasProgram === undefined},
                                                {'btn-light-success': hasProgram},
                                                {'btn-light-danger': hasProgram === undefined},
                                            )}
                                            onClick={() => setHasProgram(hasProgram === undefined ? true : hasProgram ? false : undefined)}
                                        >
                                            Met/zonder programma
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <EkoTable>
                                <TableHeader>
                                    <th className='p-0 fit-content' style={{height: '1px'}}>
                                        <label
                                            className='w-100 h-100 d-flex justify-content-center align-items-center cursor-pointer'>
                                            <input
                                                ref={selectAllRef}
                                                className='form-check-input m-0'
                                                type='checkbox'
                                                id={`profile-select-all`}
                                                checked={allSelected}
                                                onChange={(e) => (e.target.checked ? selectAll() : selectNone())}
                                            />
                                        </label>
                                    </th>
                                    <th></th>
                                    <th>Naam</th>
                                    <th>Programma</th>
                                    <th></th>
                                </TableHeader>
                                <tbody>
                                {(!profileList || profileList.profiles.length === 0) && (
                                    <tr>
                                        <td colSpan={5} className='text-center'>
                                            Geen gegevens
                                        </td>
                                    </tr>
                                )}

                                {profileList && profileList.profiles.map((profile) => (
                                    <tr key={profile.id}>
                                        <td className='p-0 fit-content' style={{height: '1px'}}>
                                            <label
                                                className='w-100 h-100 d-flex justify-content-center align-items-center cursor-pointer'>
                                                <input
                                                    className='form-check-input m-0'
                                                    type='checkbox'
                                                    id={`profile-${profile.id}`}
                                                    value={profile.id}
                                                    checked={selection.has(profile.id)}
                                                    onChange={(e) => handleSelection(e.target.checked, profile)}
                                                />
                                            </label>
                                        </td>

                                        <td className='fit-content user-select-none'>
                                            <div style={{height: '80px', width: '80px'}}>
                                                <ProfilePreviewSvg profileSvg={profile.svgPreview}/>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <a href={'#'} className={''} onClick={() => edit(profile)}>
                                                    {profile.name}
                                                </a>
                                            </div>
                                            <div>
                                                <small className='text-muted'>
                                                    {profile.code} | {profile.id}
                                                </small>
                                            </div>
                                        </td>
                                        <td className='fit-content text-center'>
                                            <a href={'#'} className={'text-muted'} onClick={() => edit(profile)}>
                                                <BooleanBadge value={profile.hasProgram}
                                                              falseClass={profile.active ? 'bg-danger' : 'bg-warning'}/>
                                            </a>
                                        </td>
                                        <td className='fit-content text-center'>
                                            <ActiveBadge value={profile}/>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </EkoTable>
                        </EkoCardBody>
                    </EkoCard>
                </div>
            </div>
        </>
    );
};

export default ProfileList;


const ProfileListExtraMenu: React.FC = () => {


    const generateAllConfirmDialog = useConfirmDialog();


    const generateMutation = useMutation(
        async () => {
            await ApiClient.Pim.ProfileGeneration.generate();
        },
        {
            onSuccess: async () => {
                // await refetch();
            },
            onError: () => {
                toast.error('Fout bij het synchroniseren van profielen');
            },
        },
    );

    const onGenerateClick = async () => {
        await generateAllConfirmDialog({
            onConfirm: () => generateMutation.mutateAsync(),
            title: 'Profielen genereren',
            // message : 'Werk profielen bij o.b.v. wijzigingen in de sponningen.'

        });
    };


    return <>
        <div className='dropdown'>
            <button type='button' className='btn btn-primary dropdown-toggle' role='button'
                    data-bs-toggle='dropdown' aria-expanded='false'>
            </button>

            <ul className='dropdown-menu'>
                <li>
                    <button type='button' className='dropdown-item' onClick={() => onGenerateClick()}>Profielen
                        synchroniseren (legacy)
                    </button>
                </li>
            </ul>
        </div>
    </>;
};
import {useQuery, useQueryClient} from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import {NotFound, ValidationFailed} from "../../../../modules/api-client/Responses";
import {useEkosietMutation} from "../../../../shared/hooks/useEkosietMutation";
import {
    ChangePriceCommand,
    CornerPieceProduct,
    GlazingBarProduct,
    GlazingProfileProduct,
    KopIsolatorSetProduct,
    ConfiguredNeutProduct, PriceChange,
    SillConfiguratorProduct,
    SillProduct,
    StandardProduct,
    StopProfileProduct,
    TransportProduct
} from "../../../../modules/api-client/generated";
import { isNotEmpty } from "../../../../../_metronic/helpers";



export const useCatalogItemUpsertMutation = (id?: string | undefined) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<StandardProduct |
        TransportProduct |
        GlazingBarProduct |
        GlazingProfileProduct |
        StopProfileProduct |
        SillProduct |
        SillConfiguratorProduct |
        CornerPieceProduct |
        KopIsolatorSetProduct |
        ConfiguredNeutProduct, NotFound | ValidationFailed, any>((command) => {


            if (id) {
                return ApiClient.Pim.CatalogItem.pimCatalogItemsIdPut(id, undefined, command).then(response => response.data);
            }
            return ApiClient.Pim.CatalogItem.pimCatalogItemsPost(undefined, command).then(response => response.data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['pimCatalogItemIdGet', id]).then();
                queryClient.invalidateQueries(['pimCatalogItemsGet']).then();
            }
        }
    );
};

export const useCatalogItemQuery = (id: string | undefined) => {
    return useQuery(['pimCatalogItemIdGet', id],
        () => {
            return ApiClient.Pim.CatalogItem.pimCatalogItemsIdGet(id!).then(res => res.data);
        }, {
            useErrorBoundary: true,
            enabled: !!id,
        });
}

export const usePriceMutation = () => {
    const queryClient = useQueryClient()
    return useEkosietMutation<PriceChange, NotFound | ValidationFailed, ChangePriceCommand>((command) => {
            return ApiClient.Sm.Pricing.smPricesPost(undefined, command).then(response => response.data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['pimPricesGet']).then();
                queryClient.invalidateQueries(['pimCatalogItemsGet']).then();
            }
        }
    );
};


export const useCatalogItemDeleteMutation = (id: string) => {
    const queryClient = useQueryClient()
    return useEkosietMutation<void, NotFound | ValidationFailed, string>((id) => {
            return ApiClient.Pim.CatalogItem.pimCatalogItemsIdDelete(id, undefined).then(response => response.data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['pimCatalogItemIdGet', id]).then();
                queryClient.invalidateQueries(['pimCatalogItemsGet']).then();
            }
        }
    );
};


export const useListStandardProductsByCatalogIdQuery = (catalogId: string | undefined) => {
    return useQuery<Array<StandardProduct>>(['pimCatalogItemsCatalogCatalogIdProductsGet', catalogId],
        () => {
            return ApiClient.Pim.CatalogItem
                    .pimCatalogItemsStandardProductsGet(catalogId).then(response => response.data)
                    ;
        }, {
            useErrorBoundary: true,
            enabled: isNotEmpty(catalogId),
        });
}
export const useListStandardProductsByCatalogIdAndCategoryQuery = (catalogId: string | undefined,category: string| undefined, priceListId :string|undefined =undefined) => {
    return useQuery<Array<StandardProduct>>(['pimCatalogItemsCategoryGet', catalogId!+category!+priceListId!],
        () => {
            return ApiClient.Pim.CatalogItem
                .pimCatalogItemsCategoryGet(category,catalogId,priceListId).then(response => response.data)
                ;
        }, {
            useErrorBoundary: true,
            enabled: isNotEmpty(catalogId),
        });
}
export const useListSillProductsQuery = () => {
    return useQuery<Array<StandardProduct>>(['pimCatalogItemsSillsGet'],
        () => {
            return ApiClient.Pim.CatalogItem
                .pimCatalogItemsSillsGet().then(response => response.data)
                ;
        }, {
            useErrorBoundary: true,
        });
}
import { Navigate, Route, Routes } from 'react-router-dom'
import { ForgotPassword } from './ForgotPassword'
import { Login } from './Login'
import { AuthLayout } from '../../pages/AuthLayout'
import Protected from '../../routing/ProtectedRoute'
import { useEkosietAuth0 } from '../../modules/auth0/core/useEkosietAuth0'
import { CustomerRoutePath } from "../../customer-portal/routing/RoutePath";
import { BackofficeRoutePath } from "../../backoffice/RoutePath";
import { PmRoutePath } from '../../backoffice/modules/pm/PmRoutes'
import {EmRoutePath} from "../../backoffice/modules/em/EmRoutes";

const LoginWidget = () => {

  const { isProductieMedewerker,isTransportMedewerker,isExpeditieMedewerker, isKlant, isAuthenticated } = useEkosietAuth0();
  const startUrl =
      isKlant ? CustomerRoutePath.dashboard :
          isProductieMedewerker ? PmRoutePath.link(PmRoutePath.stationsDashboard) :
          isTransportMedewerker ? EmRoutePath.link(EmRoutePath.planningTransporteur):
              isExpeditieMedewerker ? EmRoutePath.link(EmRoutePath.planning):
              BackofficeRoutePath.dashboard;
console.log(startUrl);
  if (!isAuthenticated) {
    return (
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path='login' element={<Login />} />
          <Route path='forgot-password' element={<ForgotPassword />} />
          <Route index element={<Login />} />
        </Route>
      </Routes>
    )
  } else {
    return (
      <Routes>
        <Route
          index
          element={
            <Protected>
              <Navigate to={startUrl} />
            </Protected>
          }
        />
      </Routes>
    )
  }
}

export { LoginWidget }

import {FC} from 'react'
import * as Yup from 'yup'
import {useFormik, getIn} from 'formik'
import clsx from 'clsx'

import {usePostCustomerAddress} from '../hooks/use-post-customer-address'
import {usePutCustomerAddress} from '../hooks/use-put-customer-address'
import {
    CustomerAddressRepresentation,
    PostCustomerAddressRepresentation,
    PutCustomerAddressRepresentation
} from '../../../../modules/api-client/generated'

const addressSchema =
    Yup.object().shape({

        street: Yup.string()
            .required('Straat is verplicht.')
            .max(200, 'Maximaal 200 karakters.')
        ,

        houseNumber: Yup.string()
            .required('Huisnummer is verplicht.')
            .max(5, 'Maximaal 5 karakters.')
            .matches(
                /^[0-9]*$/,
                'Alleen cijfers zijn toegestaan.'
            )
        ,

        houseNumberAddition: Yup.string()
            .max(4, 'Maximaal 4 karakters.')
        ,

        postalCode: Yup.string()
            .required("Postcode is verplicht.")
            .max(6, 'Maximaal 6 karakters.')
            .matches(/^[1-9][0-9]{3}?(?!sa|sd|ss)[a-z]{2}$/i,
                'Postcode moet bestaan uit 4 cijfers en twee letters zonder spatie (voorbeeld 1234AB)'
            )
        ,

        city: Yup.string()
            .required("Stad is verplicht.")
            .max(100, 'Maximaal 100 karakters.')
        ,

        country: Yup.string()
            .required('Land is verplicht.')
            .max(60, 'Maximaal 60 karakters.')
        ,

        attention: Yup.string()
            .max(50, 'Maximaal 50 karakters.')
        ,

        extraAddressLine: Yup.string()
            .max(200, 'Maximaal 200 karakters.')
        ,

        region: Yup.string()
            .max(100, 'Maximaal 100 karakters.')
        ,

        isDeliveryAddress: Yup.bool()
            .required('Leveradres is verplicht.')
        ,

        isInvoicingAddress: Yup.bool()
            .required('Factuuradres is verplicht.')
        ,

        contactPerson: Yup.object().shape({
            firstName: Yup.string()
                .required('Voornaam is verplicht.')
                .max(200, 'Maximaal 200 karakters.')
            ,

            lastName: Yup.string()
                .required('Achternaam is verplicht.')
                .max(200, 'Maximaal 200 karakters.')
            ,

            email: Yup.string()
                .required('E-mail is verplicht.')
                .email('Geen geldig e-mail')
                .max(255, 'Maximaal 255 karakters.')
            ,

            telephone: Yup.string()
                .max(25, 'Maximaal 25 karakters.')
        })
    });

type Props = {
    customerId: string | undefined;
    existingAddress: CustomerAddressRepresentation | undefined | null,
    setExistingAddress: React.Dispatch<React.SetStateAction<CustomerAddressRepresentation | null>>
    addNewAddress: boolean,
    setAddNewAddress: React.Dispatch<React.SetStateAction<boolean>>,
    onAddressPersisted: () => void
}

const CustomerAddressModalForm: FC<Props> = (
    {
        customerId,
        existingAddress,
        setExistingAddress,
        addNewAddress,
        setAddNewAddress,
        onAddressPersisted
    }) => {

    const isNewAddress = addNewAddress || existingAddress === null;

    type FormValues = Omit<PutCustomerAddressRepresentation, 'houseNumber'> &
        Omit<PostCustomerAddressRepresentation, 'houseNumber'>
        & {
        houseNumber: number | string;
    };

    const formInitialValues: FormValues = {
        id: existingAddress?.id || undefined!,
        customerId: customerId!,
        attention: existingAddress?.attention || '',
        city: existingAddress?.city || '',
        country: existingAddress?.country || 'NL',
        extraAddressLine: existingAddress?.extraAddressLine || '',
        houseNumber: existingAddress?.houseNumber || '',
        houseNumberAddition: existingAddress?.houseNumberAddition || '',
        postalCode: existingAddress?.postalCode || '',
        region: existingAddress?.region || '',
        street: existingAddress?.street || '',
        contactPerson: existingAddress?.contactPerson || {
            id: undefined!,
            customerId: customerId ?? undefined!,
            email: '',
            telephone: '',
            firstName: '',
            lastName: ''
        },
        isDeliveryAddress: existingAddress?.isDeliveryAddress || false,
        isInvoicingAddress: existingAddress?.isInvoicingAddress || false
    };

    const mutatePostCustomerAddress = usePostCustomerAddress();
    const mutatePutCustomerAddress = usePutCustomerAddress();

    const formik = useFormik<FormValues>({
        initialValues: formInitialValues,
        validationSchema: addressSchema,
        enableReinitialize: true,
        validateOnBlur: true,
        validateOnChange: false,
        onSubmit: async (values, {setSubmitting}) => {
            let payload: PostCustomerAddressRepresentation & PutCustomerAddressRepresentation = {
                ...values,
                houseNumber: +values.houseNumber
            };

            if (isNewAddress) {
                await mutatePostCustomerAddress(payload)
            } else {
                await mutatePutCustomerAddress(payload)
            }
            setSubmitting(false);
            onAddressPersisted();
        },
    });

    return (
        <>
            <form id='kt_modal_customer_address_form' className='form' onSubmit={formik.handleSubmit} noValidate>

                {/* begin::Scroll */}
                <div
                    className='d-flex flex-column scroll-y me-n7 pe-7'
                    id='kt_modal_customer_address_scroll'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-max-height='auto'
                    data-kt-scroll-dependencies='#kt_modal_customer_address_scroll'
                    data-kt-scroll-wrappers='#kt_modal_customer_address_scroll'
                    data-kt-scroll-offset='300px'
                >
                    <>
                        <div className='title m-0 p-0'>
                            <h3 className='fw-bolder'>Contactpersoon</h3>
                        </div>
                        <div className={'mb-10'}>
                            {/* begin::address.contactPerson.firstName */}
                            <div className='row mb-3'>
                                <div className='col-4'>
                                    <label htmlFor='contactPerson.firstName' className='required fw-bold fs-6 mb-2'>
                                        Naam
                                    </label>
                                </div>
                                <div className='col'>
                                    <input
                                        {...formik.getFieldProps('contactPerson.firstName')}
                                        type='text'
                                        className={clsx(
                                            'form-control form-control-solid mb-3 mb-lg-0',
                                            {'is-invalid': getIn(formik.touched, 'contactPerson.firstName') && getIn(formik.errors, 'contactPerson.firstName')},
                                            {'is-valid': getIn(formik.touched, 'contactPerson.firstName') && !getIn(formik.errors, 'contactPerson.firstName')}
                                        )}
                                        id='contactPerson.firstName'
                                        disabled={formik.isSubmitting}
                                    />
                                    {getIn(formik.touched, 'contactPerson.firstName') && getIn(formik.errors, 'contactPerson.firstName') && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span
                                                    role='alert'>{getIn(formik.errors, 'contactPerson.firstName')}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className='col'>
                                    <input
                                        {...formik.getFieldProps('contactPerson.lastName')}
                                        type='text'
                                        className={clsx(
                                            'form-control form-control-solid mb-3 mb-lg-0',
                                            {'is-invalid': getIn(formik.touched, 'contactPerson.lastName') && getIn(formik.errors, 'contactPerson.lastName')},
                                            {'is-valid': getIn(formik.touched, 'contactPerson.lastName') && !getIn(formik.errors, 'contactPerson.lastName')}
                                        )}
                                        id='contactPerson.lastName'
                                        disabled={formik.isSubmitting}
                                    />
                                    {getIn(formik.touched, 'contactPerson.lastName') && getIn(formik.errors, 'contactPerson.lastName') && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span
                                                    role='alert'>{getIn(formik.errors, 'contactPerson.lastName')}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* end::address.contactPerson.firstName */}


                            {/* begin::address.contactPerson.email */}
                            <div className='row mb-3'>
                                <div className='col-4'>
                                    <label htmlFor='contactPerson.email' className='required fw-bold fs-6 mb-2'>
                                        E-mail en Telefoonnmr
                                    </label>
                                </div>
                                <div className='col'>
                                    <input
                                        {...formik.getFieldProps('contactPerson.email')}
                                        type='text'
                                        className={clsx(
                                            'form-control form-control-solid mb-3 mb-lg-0',
                                            {'is-invalid': getIn(formik.touched, 'contactPerson.email') && getIn(formik.errors, 'contactPerson.email')},
                                            {'is-valid': getIn(formik.touched, 'contactPerson.email') && !getIn(formik.errors, 'contactPerson.email')}
                                        )}
                                        id='contactPerson.email'
                                        disabled={formik.isSubmitting}
                                    />
                                    {getIn(formik.touched, 'contactPerson.email') && getIn(formik.errors, 'contactPerson.email') && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{getIn(formik.errors, 'contactPerson.email')}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className='col'>
                                    <input
                                        {...formik.getFieldProps('contactPerson.telephone')}
                                        type='text'
                                        className={clsx(
                                            'form-control form-control-solid mb-3 mb-lg-0',
                                            {'is-invalid': getIn(formik.touched, 'contactPerson.telephone') && getIn(formik.errors, 'contactPerson.telephone')},
                                            {'is-valid': getIn(formik.touched, 'contactPerson.telephone') && !getIn(formik.errors, 'contactPerson.telephone')}
                                        )}
                                        id='contactPerson.telephone'
                                        disabled={formik.isSubmitting}
                                    />
                                    {getIn(formik.touched, 'contactPerson.telephone') && getIn(formik.errors, 'contactPerson.telephone') && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span
                                                    role='alert'>{getIn(formik.errors, 'contactPerson.telephone')}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* begin::address.attention */}
                        <div className='row mb-3'>
                            <div className='col-4'>
                                <label htmlFor='attention' className='fw-bold fs-6 mb-2'>
                                    Aanhef
                                </label>
                            </div>
                            <div className='col'>
                                <input
                                    {...formik.getFieldProps('attention')}
                                    type='text'
                                    className={clsx(
                                        'form-control form-control-solid mb-3 mb-lg-0',
                                        {'is-invalid': formik.touched.attention && formik.errors.attention},
                                        {'is-valid': formik.touched.attention && !formik.errors.attention}
                                    )}
                                    id='attention'
                                    disabled={formik.isSubmitting}
                                />
                                {formik.touched.attention && formik.errors.attention && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.attention}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* end::address.attention */}


                        {/* begin::address.street */}
                        <div className='row mb-3'>
                            <div className='col-4'>
                                <label htmlFor='street' className='required fw-bold fs-6 mb-2'>
                                    Adres
                                </label>
                            </div>
                            <div className='col'>
                                <input
                                    {...formik.getFieldProps('street')}
                                    type='text'
                                    className={clsx(
                                        'form-control form-control-solid mb-3 mb-lg-0',
                                        {'is-invalid': formik.touched.street && formik.errors.street},
                                        {'is-valid': formik.touched.street && !formik.errors.street}
                                    )}
                                    id='street'
                                    disabled={formik.isSubmitting}
                                />
                                {formik.touched.street && formik.errors.street && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.street}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='col'>
                                <input
                                    {...formik.getFieldProps('houseNumber')}
                                    type='text'
                                    className={clsx(
                                        'form-control form-control-solid mb-3 mb-lg-0',
                                        {'is-invalid': getIn(formik.touched, 'houseNumber') && getIn(formik.errors, 'houseNumber')},
                                        {'is-valid': getIn(formik.touched, 'houseNumber') && !getIn(formik.errors, 'houseNumber')}
                                    )}
                                    id='houseNumber'
                                    disabled={formik.isSubmitting}
                                />
                                {getIn(formik.touched, 'houseNumber') && getIn(formik.errors, 'houseNumber') && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{getIn(formik.errors, 'houseNumber')}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='col'>
                                <input
                                    {...formik.getFieldProps('houseNumberAddition')}
                                    type='text'
                                    className={clsx(
                                        'form-control form-control-solid mb-3 mb-lg-0',
                                        {'is-invalid': getIn(formik.touched, 'houseNumberAddition') && getIn(formik.errors, 'houseNumberAddition')},
                                        {'is-valid': getIn(formik.touched, 'houseNumberAddition') && !getIn(formik.errors, 'houseNumberAddition')}
                                    )}
                                    id='houseNumberAddition'
                                    disabled={formik.isSubmitting}
                                />
                                {getIn(formik.touched, 'houseNumberAddition') && getIn(formik.errors, 'houseNumberAddition') && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{getIn(formik.errors, 'houseNumberAddition')}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* end::address.street */}


                        {/* begin::address.extraAddressLine */}
                        <div className='row mb-3'>
                            <div className='col-4'>
                                <label htmlFor='extraAddressLine' className='fw-bold fs-6 mb-2'>
                                    Extra Regel
                                </label>
                            </div>
                            <div className='col'>
                                <input
                                    {...formik.getFieldProps('extraAddressLine')}
                                    type='text'
                                    className={clsx(
                                        'form-control form-control-solid mb-3 mb-lg-0',
                                        {'is-invalid': getIn(formik.touched, 'extraAddressLine') && getIn(formik.errors, 'extraAddressLine')},
                                        {'is-valid': getIn(formik.touched, 'extraAddressLine') && !getIn(formik.errors, 'extraAddressLine')}
                                    )}
                                    id='extraAddressLine'
                                    disabled={formik.isSubmitting}
                                />
                                {getIn(formik.touched, 'extraAddressLine') && getIn(formik.errors, 'extraAddressLine') && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{getIn(formik.errors, 'extraAddressLine')}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* end::address.houseNumberAddition */}

                        {/* begin::address.postalCode */}
                        <div className='row mb-3'>
                            <div className='col-4'>
                                <label htmlFor='postalCode' className='required fw-bold fs-6 mb-2'>
                                    Postcode Plaats
                                </label>
                            </div>
                            <div className='col'>
                                <input
                                    {...formik.getFieldProps('postalCode')}
                                    type='text'
                                    className={clsx(
                                        'form-control form-control-solid mb-3 mb-lg-0',
                                        {'is-invalid': getIn(formik.touched, 'postalCode') && getIn(formik.errors, 'postalCode')},
                                        {'is-valid': getIn(formik.touched, 'postalCode') && !getIn(formik.errors, 'postalCode')}
                                    )}
                                    id='postalCode'
                                    disabled={formik.isSubmitting}
                                />
                                {getIn(formik.touched, 'postalCode') && getIn(formik.errors, 'postalCode') && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{getIn(formik.errors, 'postalCode')}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='col'>
                                <input
                                    {...formik.getFieldProps('city')}
                                    type='text'
                                    className={clsx(
                                        'form-control form-control-solid mb-3 mb-lg-0',
                                        {'is-invalid': getIn(formik.touched, 'city') && getIn(formik.errors, 'city')},
                                        {'is-valid': getIn(formik.touched, 'city') && !getIn(formik.errors, 'city')}
                                    )}
                                    id='city'
                                    disabled={formik.isSubmitting}
                                />
                                {getIn(formik.touched, 'city') && getIn(formik.errors, 'city') && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{getIn(formik.errors, 'city')}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* end::address.postalCode */}

                        {/* begin::address.region */}
                        <div className='row mb-3'>
                            <div className='col-4'>
                                <label htmlFor='region' className='fw-bold fs-6 mb-2'>
                                    Regio
                                </label>
                            </div>
                            <div className='col'>
                                <input
                                    {...formik.getFieldProps('region')}
                                    type='text'
                                    className={clsx(
                                        'form-control form-control-solid mb-3 mb-lg-0',
                                        {'is-invalid': getIn(formik.touched, 'region') && getIn(formik.errors, 'region')},
                                        {'is-valid': getIn(formik.touched, 'region') && !getIn(formik.errors, 'region')}
                                    )}
                                    id='region'
                                    disabled={formik.isSubmitting}
                                />
                                {getIn(formik.touched, 'region') && getIn(formik.errors, 'region') && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{getIn(formik.errors, 'region')}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* end::address.region */}

                        {/* begin::address.country */}
                        <div className='row mb-3'>
                            <div className='col-4'>
                                <label htmlFor='country' className='required fw-bold fs-6 mb-2'>
                                    Land
                                </label>
                            </div>
                            <div className='col'>
                                <select
                                    {...formik.getFieldProps('country')}
                                    id="country"
                                    className={clsx(
                                        'form-control form-control-solid mb-3 mb-lg-0',
                                        {'is-invalid': getIn(formik.touched, 'country') && getIn(formik.errors, 'country')},
                                        {'is-valid': getIn(formik.touched, 'country') && !getIn(formik.errors, 'country')}
                                    )}
                                    data-kt-select2='true'
                                    value={formik.values.country !== '' && formik.values.country != null ? formik.values.country : 'NL'}
                                    onChange={(e) => {
                                        formik.setFieldValue("country", e.target.value);
                                        formik.validateField("country");
                                    }}
                                >
                                    <option value='NL'>Nederland</option>
                                    <option value='BE'>België</option>
                                    <option value='DE'>Duitsland</option>
                                    <option value='FR'>Frankrijk</option>
                                    <option value='GB'>Verenigd Koninkrijk</option>
                                    <option value='PL'>Polen</option>
                                </select>

                                {getIn(formik.touched, 'country') && getIn(formik.errors, 'country') && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{getIn(formik.errors, 'country')}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* end::address.country */}

                        {/* begin::address.isDeliveryAddress */}
                        <div className='row mb-3'>
                            <div className='col-4'>
                            </div>
                            <div className='col'>
                                <div className="form-check form-switch">
                                    {/* begin::Input */}
                                    <input
                                        {...formik.getFieldProps('isDeliveryAddress')}
                                        className={clsx(
                                            'form-check-input'
                                        )}
                                        type='checkbox'
                                        name='isDeliveryAddress'
                                        id='isDeliveryAddress'
                                        value={formik.values.isDeliveryAddress ? 'true' : 'false'}
                                        checked={formik.values.isDeliveryAddress ? true : false}
                                        disabled={formik.isSubmitting}
                                    />
                                    {/* end::Input */}

                                    {/* begin::Label */}
                                    <label className='required fw-bold fs-6 mb-2 form-check-label'
                                           htmlFor='isDeliveryAddress'>
                                        Afleveradres
                                    </label>
                                    {/* end::Label */}
                                </div>
                            </div>
                        </div>
                        {/* end::address.isDeliveryAddress */}

                        {/* begin::address.isInvoicingAddress */}
                        <div className='row mb-3'>
                            <div className='col-4'>
                            </div>
                            <div className='col'>

                                <div className="form-check form-switch">
                                    {/* begin::Input */}
                                    <input
                                        {...formik.getFieldProps('isInvoicingAddress')}
                                        className={clsx(
                                            'form-check-input'
                                        )}
                                        type='checkbox'
                                        name='isInvoicingAddress'
                                        id='isInvoicingAddress'
                                        value={formik.values.isInvoicingAddress ? 'true' : 'false'}
                                        checked={formik.values.isInvoicingAddress ? true : false}
                                        disabled={formik.isSubmitting}
                                    />
                                    {/* end::Input */}

                                    {/* begin::Label */}
                                    <label className='required fw-bold fs-6 mb-2 form-check-label'
                                           htmlFor='isInvoicingAddress'>
                                        Factuuradres
                                    </label>
                                    {/* end::Label */}
                                </div>
                            </div>
                        </div>
                        {/* end::address.isInvoicingAddress */}

                    </>
                </div>

                {/* begin::Actions */}
                <div className='text-center pt-15'>
                    <button
                        type='reset'
                        className='btn btn-light me-3'
                        data-kt-customer-address-modal-action='cancel'
                        data-dismiss="modal"
                        onClick={() => {
                            setAddNewAddress(false);
                            setExistingAddress(null);
                        }}
                        disabled={formik.isSubmitting}
                    >
                        Annuleren
                    </button>

                    <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-customer-address-modal-action='submit'
                        disabled={formik.isSubmitting}
                    >
            <span className='indicator-label'>
              {isNewAddress ? 'Toevoegen' : 'Wijzigen'}
            </span>
                        {formik.isSubmitting && (
                            <span className='indicator-progress'>
                Even wachten a.u.b
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
                        )}
                    </button>
                </div>
                {/* end::Actions */}

            </form>
        </>
    )
}

export {CustomerAddressModalForm}

import React, {createContext, useCallback, useContext, useId, useState} from "react";
import {Form, Formik, useFormikContext} from "formik";
import {ZijlichtConfiguration} from "../../../../modules/api-client/generated";
import {modalsRoot} from "../../../../global-helpers";
import {Button, Modal} from "react-bootstrap";
import SpinnerButton from "../../SpinnerButton";


export type ZijlichtEditorValue = {
    length: number;
}

export type UseZijlichtEditorModalOptions = {
    value: ZijlichtEditorValue
    onConfirm: (value: ZijlichtEditorValue) => Promise<void>;
}

export function useZijlichtEditorModal(options: UseZijlichtEditorModalOptions) {

    const [show, setShow] = useState(false);

    const open = () => setShow(true);
    const close = () => setShow(false);

    const onConfirm = useCallback(async (value: ZijlichtEditorValue) => {

        await options.onConfirm(value);
        close();

    }, [close, options.onConfirm]);

    const renderFormikElement = useCallback(() => {

        const context = {
            onConfirm,
            show,
            open,
            close
        };

        return <ZijlichtEditorModalContext.Provider value={context}>
            <ZijlichtEditorFormik initialValues={options.value}/>
        </ZijlichtEditorModalContext.Provider>


    }, [options.onConfirm, show]);

    const formikElement = renderFormikElement();

    return {
        open,
        formikElement,
    }
}

const ZijlichtEditorFormik: React.FC<{ initialValues: ZijlichtEditorValue }> = ({initialValues}) => {

    const {onConfirm} = useContext(ZijlichtEditorModalContext);

    return <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={values => onConfirm(values)}
        validateOnChange={false}
        validateOnBlur={false}>
        <ZijlichtEditorModal/>
    </Formik>;
}


const ZijlichtEditorModal: React.FC = () => {

    const {show, close} = useContext(ZijlichtEditorModalContext);

    const {isSubmitting} = useFormikContext<ZijlichtConfiguration>();

    return <Modal
        container={modalsRoot}
        tabIndex={-1}
        aria-hidden="true"
        autoFocus={true}
        className="configurator-zijlicht-modal"
        dialogClassName={'modal-dialog-centered'}
        contentClassName={'shadow-lg'}
        show={show}
        size="lg"
        onHide={close}
        animation={false}
        backdrop={'static'}>
        <Form>
            <Modal.Header>
                <Modal.Title>Zijlicht indeling</Modal.Title>
            </Modal.Header>
            <Modal.Body className="flex-fill">
                <ZijlichtEditorEditor/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" className="mx-4" onClick={close} disabled={isSubmitting}>
                    Annuleren
                </Button>
                <SpinnerButton type="submit" className="btn btn-primary" spinning={isSubmitting}>
                    Toepassen
                </SpinnerButton>
            </Modal.Footer>
        </Form>
    </Modal>
}


const ZijlichtEditorEditor: React.FC = () => {

    const id = useId();

    const {values, handleChange} = useFormikContext<ZijlichtConfiguration>();

    return <>
        <div className='col'>
            <label htmlFor={`${id}_width`} className='form-label'>
                <small className='text-uppercase text-muted'>Lengte</small>
            </label>
            <div className='input-group'>
                <input
                    type={'number'}
                    name={'length'}
                    className='form-control text-end'
                    value={values.length ?? ''}
                    onChange={handleChange}
                    required
                    min={0}
                    step={0.1}
                    placeholder='Lengte in mm'
                    autoFocus
                />
                <div className='input-group-text'>mm</div>
            </div>
        </div>
    </>
}

export type ZijlichtEditorModalContextValue = {
    // configuratorData: ConfiguratorData;
    onConfirm: (value: ZijlichtEditorValue) => Promise<void>;
    show: boolean;
    open: () => void;
    close: () => void;
}

export const ZijlichtEditorModalContext = createContext<ZijlichtEditorModalContextValue>(undefined!);

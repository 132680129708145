import React, { FC } from 'react'
import * as Yup from 'yup'
import { Form, Formik, FormikValues } from 'formik'
import { useNavigate } from 'react-router-dom';
import { CustomerPicker } from '../../../../crm/components/CustomerPicker';
import { SmRoutePath } from '../../../SmRoutes';

type Props = {
    handleClose: () => void,
}

type IFormValues = {
    customerId: string
}

const initialFormValues: IFormValues = {
    customerId: ''
}

const formSchema = Yup.object().shape({
    customerId: Yup.string().required('Klant is verplicht.')
});

const NewOrderCustomerForm: FC<Props> = ({ handleClose }) => {

    const navigate = useNavigate();

    const submitForm = (values: IFormValues, actions: FormikValues) => {    
        navigate(SmRoutePath.link(SmRoutePath.orderAddWithCustomerIdLink(values.customerId)));
        handleClose();
    }

    return (
        <>
            <Formik initialValues={initialFormValues} onSubmit={submitForm} validationSchema={formSchema}  >
                {({
                    values,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <Form className='d-flex align-items-start flex-column p-5 h-100 w-100' noValidate id='kt_create_new_order_for_customer_form'>

                        <div className='w-100'>
                            <div className='fv-row mb-10'>
                                <label className='form-label required'>Klant</label>
                                <CustomerPicker fieldName='customerId' value={values.customerId} />
                            </div>
                        </div>

                        {/* begin::Actions */}
                        <div className='text-center pt-15'>
                            <button
                                type='reset'
                                className='btn btn-light me-3'
                                data-kt-customer-modal-action='cancel'
                                data-dismiss="modal"
                                onClick={() => handleClose()}
                            >
                                Annuleren
                            </button>

                            <button
                                type='submit'
                                className='btn btn-primary'
                                data-kt-customer-modal-action='submit'
                                disabled={isSubmitting}
                            >
                                <span className='indicator-label'>Maak nieuwe bestelling</span>
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export { NewOrderCustomerForm }

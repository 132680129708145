import { useAuth0, User } from '@auth0/auth0-react';
import {
    ROLE_CLAIMS,
    ADMINISTRATIE_ROLE,
    CONTROLLER_ROLE,
    MANAGEMENT_ROLE,
    PRODUCTIEMEDEWERKER_ROLE,
    SYSTEEMBEHEERDER_ROLE,
    KLANT_ROLE,
    WERKVOORBEREIDER_ROLE,
    TRANSPORTMEDEWERKER_ROLE,
    CUSTOMERID_CLAIMS_URL, EXPEDITIEMEDEWERKER_ROLE
} from '../../../constants'
import { useGetCustomer } from '../../../backoffice/modules/crm/hooks/use-get-customer';

const medewerkerRoles = [
    ADMINISTRATIE_ROLE,
    CONTROLLER_ROLE,
    MANAGEMENT_ROLE,
    PRODUCTIEMEDEWERKER_ROLE, 
    SYSTEEMBEHEERDER_ROLE,             
    WERKVOORBEREIDER_ROLE,
    EXPEDITIEMEDEWERKER_ROLE,
    TRANSPORTMEDEWERKER_ROLE
];

const klantRoles = [KLANT_ROLE]; 

type EkosietUser = {
    ROLE_CLAIMS: Array<string>
} & User

function hasEmployeeRole(roles: Array<string>) : boolean {
    if (roles) {
        const containAll = medewerkerRoles.some(item => {
            return roles.includes(item);
        });
        return containAll;
    } 
    return false;
};

function hasKlantRole(roles: Array<string>) : boolean {    
    if (roles) {        
        const containAll = klantRoles.some(item => {
            return roles.includes(item);
        });
        return containAll;
    }
    return false;
}

function hasRole(roles: Array<string>, role: string) : boolean {    
    if (roles) {        
        return roles.includes(role);
    }
    return false;
}

export function useEkosietAuth0() {
    
    const{user, ...other} = useAuth0<EkosietUser>();
    const roles = user != null ? user[ROLE_CLAIMS] : null;
    const customerId = user != null && user[CUSTOMERID_CLAIMS_URL] ? String(user[CUSTOMERID_CLAIMS_URL]) : "";
    const customerData = useGetCustomer(customerId)
    const companyName = customerId ? customerData?.data?.companyName : "Onbekend"
    const isMedewerker = hasEmployeeRole(roles);
    const isKlant = hasKlantRole(roles);
    const isSysteemBeheerder = hasRole(roles, SYSTEEMBEHEERDER_ROLE);
    const isAdministratie = hasRole(roles, ADMINISTRATIE_ROLE);
    const isController = hasRole(roles, CONTROLLER_ROLE);
    const isManagement = hasRole(roles, MANAGEMENT_ROLE);
    const isWerkVoorbereider = hasRole(roles, WERKVOORBEREIDER_ROLE);
    const isExpeditieMedewerker = hasRole(roles, EXPEDITIEMEDEWERKER_ROLE);
    const isProductieMedewerker = hasRole(roles, PRODUCTIEMEDEWERKER_ROLE);
    const isTransportMedewerker = hasRole(roles, TRANSPORTMEDEWERKER_ROLE);
    return {
        isMedewerker, 
        isKlant, 
        isSysteemBeheerder,
        isExpeditieMedewerker,
        isAdministratie,
        isController, 
        isManagement, 
        isWerkVoorbereider, 
        isProductieMedewerker, 
        isTransportMedewerker,
        user, 
        customerId,
        companyName,
        customerData,
        ...other
    };
}
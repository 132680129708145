import {useQueryClient} from "@tanstack/react-query";
import {toast} from "react-hot-toast"
import ApiClient from "../../../../../modules/api-client/ApiClient";
import {
    OrderContactPersonRepresentation,
    OrderRepresentation,
    PutContactPersonRepresentation,
} from "../../../../../modules/api-client/generated";
import {useEkosietMutation} from "../../../../../shared/hooks/useEkosietMutation";
import {NotFound, ValidationFailed} from "../../../../../modules/api-client/Responses";

export enum OrderContactTypes {
    financial="financial",
    expedition="expedition",
    order="order",
}

export const usePutOrderContact = (id: string, type?: string) => {

    const queryClient = useQueryClient();

                
        return useEkosietMutation<OrderRepresentation, NotFound | ValidationFailed, PutContactPersonRepresentation>((model: PutContactPersonRepresentation) => {
            if (type === OrderContactTypes.financial) {
                return ApiClient.Sm.Order.smOrderIdFinancialContactPut(id, undefined, model).then(response => response.data);
            } else if (type === OrderContactTypes.expedition) {
                return ApiClient.Sm.Order.smOrderIdExpeditionContactPut(id, undefined, model).then(response => response.data);
            } else {                
                return ApiClient.Sm.Order.smOrderIdContactPut(id, undefined, model).then(response => response.data);
            }
        },
        {
            onSuccess: () => {                
                queryClient.invalidateQueries(['smOrderIdGet', id]).then();

            },
            onError: () => {
                toast.error("Contact wijzigen mislukt!");
            }
        }
    );
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import clsx from "clsx";
import { InvoiceStatusCodes } from '../../../../../modules/api-client/generated';

type Props = {
    status?: string | null
    className?: string
}
const InvoiceStatus: React.FC<Props> = ({status,className}) => {
    let color = 'light';
    let title = 'Onbekend';
        switch(status) {
            case InvoiceStatusCodes.Invoiced:
                color = 'primary'
                title = 'Gefactureerd'
                break;
            case InvoiceStatusCodes.NotInvoiced:
                color = 'warning'
                title = 'Niet gefactureerd'
                break;
            case InvoiceStatusCodes.Paid:
                color = 'success'
                title = 'Betaald'
                break;           
            default:
                color = 'dark'
        }

    return (
        <>
            <span className={clsx(
                'badge badge-light-'+color,
                className && className)}>
                {title}
            </span>
        </>
    )
}

const InvoiceStates = [
    {
        label: "Gefactureerd",
        value: InvoiceStatusCodes.Invoiced,
        key: "Invoiced"
    },
    {
        label: "Niet gefactureerd",
        value: InvoiceStatusCodes.NotInvoiced,
        key: "NotInvoiced"
    },
    {
        label: "Betaald",
        value: InvoiceStatusCodes.Paid,
        key: "Paid"
    }
]

export { InvoiceStatus, InvoiceStates }
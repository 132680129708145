import {Link, Outlet} from "react-router-dom";
import {MgtRoutePath} from "../MgtRoutes";
import {useQuery} from "@tanstack/react-query";
import {EmployeeRepresentation} from "../../../../modules/api-client/generated";
import ApiClient from "../../../../modules/api-client/ApiClient";
import Loading from "../../../../shared/components/Loading";
import React from "react";
import {useProductionOperations} from "../../pm/hooks/use-list-production-operations";

export function MgtProductionOperation() {
	const {
		isInitialLoading: isInitialLoadingProductionOperations,
		data: productionOperations,
		isError: isErrorProductionOperations
	} = useProductionOperations();

	const {
		isInitialLoading: isInitialLoadingEmployee,
		data: employees,
		isError: isErrorEmployee
		, error: errorEmployee
	} = useQuery<EmployeeRepresentation[]>(
		['employeeGetList'],
		() => ApiClient.Pm.Employee.getEmployeeList()
			.then((res) => res.data.sort((a, b) => a.employee.localeCompare(b.employee)))
	);

	if (isInitialLoadingEmployee || isInitialLoadingProductionOperations) {
		return <Loading/>;
	}

	if (isErrorEmployee || isErrorProductionOperations) {
		return <>{'ERROR!'  + errorEmployee}</>;
	}

	if (!employees || !productionOperations) {
		return <>Geen data gevonden!</>;
	}

	return (
		<>
			<div className={'row'}>
				<div className={'col-2'}>
					<Link to={MgtRoutePath.poDateLink('2024-10-11')}>Per Periode</Link>
                    <ul/>
					Per medewerker
					<ul>
						{employees?.map((employee) => (
							<li key={employee.employee}>
								<Link to={MgtRoutePath.poEmployeeLink(employee.employee)}>{employee.employee}</Link>
							</li>
						))}
					</ul>
					Per handeling
					<ul>
						{productionOperations?.map((op) => (
							<li key={op.code}>
								<Link to={MgtRoutePath.poOperationLink(op.code)}>{op.title}</Link>
							</li>
						))}
					</ul>
				</div>
				<div className={'col-10'}>
					<Outlet />
				</div>
			</div>
		</>
	);
}

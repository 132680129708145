import React from 'react';
import {BooleanText} from './BooleanText';


export interface BooleanBadgeProps {
	value: boolean | undefined | null;

	trueText?: string;
	falseText?: string;

	trueClass?: string;
	falseClass?: string;
}

const BooleanBadge: React.FC<BooleanBadgeProps> = ({...props}) => {

	if (typeof props.value === 'undefined' || props.value === null) {
		return <></>;
	}

	props.trueClass ??= 'bg-success';
	props.falseClass ??= 'bg-dark';

	return <span
		className={`badge ${props.value ? props.trueClass : props.falseClass}`}><BooleanText value={props.value}
																							 trueText={props.trueText} falseText={props.falseText} /></span>;
};

export default BooleanBadge;
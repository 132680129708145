import React from "react";
import {CatalogItemType} from "../../../../../modules/api-client/generated";

export interface Props {
    type?: CatalogItemType|string
}

const CatalogItemTypeLabel: React.FC<Props> = ({type}) => {
        let label;
        switch (type) {
            case CatalogItemType.Standard: {
                label = 'Standaard';
                break;
            }
            case CatalogItemType.Transport: {
                label = 'Transport';
                break;
            }
            case CatalogItemType.Sill: {
                label = 'Dorpel';
                break;
            }
            case CatalogItemType.Neut: {
                label = 'Neut';
                break;
            }
            case CatalogItemType.GlazingBar: {
                label = 'Glaslat';
                break;
            }
            case CatalogItemType.CornerPiece: {
                label = 'Glaslat hoekje';
                break;
            }
            case CatalogItemType.GlazingProfile: {
                label = 'Beglazingsprofiel';
                break;
            }
            case CatalogItemType.StopProfile: {
                label = 'Aanslagprofiel';
                break;
            }
            case CatalogItemType.KopIsolatorSet: {
                label = 'Kopisolatorset';
                break;
            }
            case CatalogItemType.NeutConfigurator: {
                label = 'Neut configurator';
                break;
            }
            case CatalogItemType.SillConfigurator: {
                label = 'Dorpel configurator';
                break;
            }
               case CatalogItemType.ConfiguredSill: {
                label = 'Dorpel configuratie';
                break;
            }
            default: {
                label = type
            }
        }
        return (
            <span className={'badge badge-secondary'}>{label}</span>
        )
    }
;

export default CatalogItemTypeLabel;

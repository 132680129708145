import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast"
import ApiClient from "../../../../../modules/api-client/ApiClient";
import {
    PutPlannedExpeditionDateRepresentation,
} from "../../../../../modules/api-client/generated";
import { useEkosietMutation } from "../../../../../shared/hooks/useEkosietMutation";
import { NotFound, ValidationFailed } from "../../../../../modules/api-client/Responses";

export const usePutPlannedExpeditionDate = (orderId: string) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<void, NotFound | ValidationFailed, PutPlannedExpeditionDateRepresentation>(
        (model: PutPlannedExpeditionDateRepresentation) => {
            return ApiClient.Em.Expedition.emExpeditionOrderIdPlannedExpeditionDatePut(orderId, undefined, model)
            .then(response => response.data);
        },
    {
            onSuccess: () => {
                queryClient.invalidateQueries(['smOrderIdGet', orderId]);
                queryClient.invalidateQueries(['emExpeditionOrderIdGet', orderId]);
                queryClient.invalidateQueries(['emExpeditionListOdataGet']);
                queryClient.invalidateQueries(['smOrderListOdataGet']);
                queryClient.invalidateQueries(['emExpeditionTransportPlanningByWeekGet']);
                queryClient.invalidateQueries(['emExpeditionTransportPlanningByOrderGet']);
                queryClient.invalidateQueries(['OrderActionLog', orderId]).then();

                toast.success("Geplande expeditiedatum aangepast.");

            },
            onError: () => {
                toast.error("Geplande expeditiedatum wijzigen mislukt!");
            }
        }
    );
};

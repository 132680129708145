import React from "react";
import { API_GATEWAY_BASE_URL } from "../../constants";

export interface SillContraSvgProps {
    profile?: string | null;
    profileSvg?: string | null;
    defaultNeutHeight?: number | null;
    svgWidth?: string;
    svgHeight?: string;
    cacheBuster?: number | string | null;
}

const SillContraSvg: React.FC<SillContraSvgProps> = (props) => {

    if (props.profileSvg) {
        return <div className="preview-svg"
            dangerouslySetInnerHTML={{ __html: props.profileSvg }} />
    }

    if (props.profile) {

        let imgSrc = props.profile ? `${API_GATEWAY_BASE_URL}/api/render/preview/sill-contra?geometry=${encodeURIComponent(props.profile)}` : null;

        if (imgSrc && props.cacheBuster) {
            imgSrc += `&cacheBuster=${props.cacheBuster}`;
        }

        if (imgSrc && props.defaultNeutHeight) {
            imgSrc += `&defaultNeutHeight=${props.defaultNeutHeight}`;
        }

        return <>{imgSrc && <img src={imgSrc} style={{
            width: "100%",
            height: "100%",
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "contain"
        }}
            alt=""
        />}
        </>;
    }
    return <></>;
};

export default SillContraSvg;

import React, {useCallback, useRef} from 'react';
import {useQuery} from '@tanstack/react-query';
import ApiClient from '../../../../modules/api-client/ApiClient';
import {useOutletContext} from 'react-router-dom';
import ActiveBadge from '../../../../shared/components/ActiveBadge';
import {EkoCard, EkoCardBody} from '../../../../../_metronic/helpers';
import {TableHeader} from '../../../../shared/components/table/TableHeader';
import {EkoTable} from '../../../../shared/components/table/EkoTable';
import {EkoCardHeader} from '../../../../shared/components/card/EkoCardHeader';
import {EkoCardToolbar} from '../../../../shared/components/card/EkoCardToolbar';
import {MasterdataModalContext} from '../../../pages/MasterData';
import SluitpotUpsert from './SluitpotUpsert';
import {ConfirmDialog} from '../../../../shared/components/ConfirmDialog';
import {Sluitpot} from '../../../../modules/api-client/generated';
import {useSluitpotDeleteMutation} from './hooks';
import Loading from '../../../../shared/components/Loading';
import SluitpotBeslagSvg from '../../../../shared/components/SluitpotBeslagSvg';


const SluitpotList: React.FC = () => {

	const {setShowModal, setModalTitle, setModalComponent} = useOutletContext<MasterdataModalContext>();

	const add = () => {
		setModalComponent(<SluitpotUpsert handleClose={() => setShowModal(false)} />);
		setModalTitle('Toevoegen');
		setShowModal(true);
	};
	const edit = (Sluitpot: Sluitpot) => {
		setModalComponent(<SluitpotUpsert id={Sluitpot.id} handleClose={() => setShowModal(false)} />);
		setModalTitle(Sluitpot.name + ' aanpassen');
		setShowModal(true);
	};

	const {
		isInitialLoading,
		data: list,
		isError,
	} = useQuery<Sluitpot[]>(['pimSluitpotsGet'], () => ApiClient.Pim.Sluitpot.pimSluitpotsGet().then((res) => res.data));

	if (isInitialLoading) {
		return <Loading />;
	}

	if (isError) {
		return <>ERROR!</>;
	}

	if (!list) return <></>;

	return <>

		<EkoCard>
			<EkoCardHeader title={'Sluitpot blokjes'}>
				<EkoCardToolbar>
					<button onClick={() => add()} className='btn btn-primary btn-sm'>Toevoegen</button>
				</EkoCardToolbar>
			</EkoCardHeader>
			<EkoCardBody>
				<EkoTable>
					<colgroup>
						<col width={'10%'}></col>
					</colgroup>
					<TableHeader>
						<th></th>
						<th>Code</th>
						<th>Naam</th>
						<th>Voor sluitpot</th>
						<th></th>
						<th>Operation</th>
						<th></th>
					</TableHeader>
					<tbody>
					{(!list || list.length === 0) &&
						<tr>
							<td colSpan={5} className='text-center'>Geen gegevens</td>
						</tr>}

					{list && list.map((data) => <tr key={data.id}>
						<td>
							<div>
								<SluitpotBeslagSvg contour={data.contour} />
							</div>
						</td>
						<td className='fit-content'>
							<a href='#' onClick={() => edit(data)}>{data.code}</a>
						</td>
						<td>
							<div>{data?.name}</div>
							<div className={'text-muted'}>
								{data?.height} mm | {data?.angle} graden
							</div>
						</td>
						<td>
							{data?.sluitpotBeslag.name}
						</td>
						<td className='fit-content'>
							<ActiveBadge value={data.active} />
						</td>
						<td className='fit-content'>
							{data?.operationName || <span className='text-muted d-block text-center'>-</span>}
						</td>
						<td className='fit-content'>
							<DeleteButton id={data.id!} />
						</td>
					</tr>)}
					</tbody>
				</EkoTable>
			</EkoCardBody>
		</EkoCard>
	</>;
};
const DeleteButton: React.FC<{id: string}> = ({id}) => {
	const openDeleteDialog = useRef<(show: boolean) => void>();

	const deleteMutation = useSluitpotDeleteMutation(id);
	const deleteCallback = useCallback(async () => {
		await deleteMutation.mutateAsync(id!);
	}, [deleteMutation, id]);
	return (
		<>
			<button type='button' className='btn btn-light btn-sm' disabled={false}
					onClick={() => openDeleteDialog.current?.call(this, true)}><i className={'fas fa-trash'}></i>
			</button>
			<ConfirmDialog show={openDeleteDialog}
						   onConfirm={deleteCallback}
						   dialogStyle='danger'
						   title='Verwijderen'
						   message='Wil je doorgaan?'
						   confirmText='Definitief verwijderen'

			/>
		</>

	);
};


export default SluitpotList;
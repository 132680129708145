import React, {FC, useContext, useState} from 'react';
import {
    CompartmentLayoutTypeCategory,
    CompartmentLayoutTypeCode,
    ConfiguratorCompartmentProfile,
    ConfiguratorCompartmentProfiles,
    RabbetPosition
} from '../../../modules/api-client/generated';
import ProfilePreviewSvg from "../ProfilePreviewSvg";
import {ProfileDisplayName} from "../../../backoffice/modules/pim/profile/ProfileDisplayName";
import {ProfilePickerModal} from "../../../backoffice/modules/pim/profile/ProfilePickerModal";
import {
    HefSchuifProfileFacets,
    ProfilePickerProps, QueryCallback, StandardProfileFacets
} from "../../../backoffice/modules/pim/profile/ProfilePicker";
import {CompartmentEditorContext} from "./CompartmentEditorContextProvider";


export const CompartmentProfileEditorMode = {
    Asymmetrical: 'asymmetrical',
    Symmetrical: 'symmetrical',
};

export type CompartmentProfileEditorMode = typeof CompartmentProfileEditorMode[keyof typeof CompartmentProfileEditorMode];


export interface CompartmentProfileEditorProps {
    sillId: string;
    neutPositionId: string
    compartmentLayoutTypeCodeCategory: CompartmentLayoutTypeCategory;
    compartmentLayoutTypeCode: CompartmentLayoutTypeCode;
    profileWidth: number;
    mode?: CompartmentProfileEditorMode;
    profiles: ConfiguratorCompartmentProfiles;
    onChange: (profile: ConfiguratorCompartmentProfiles) => void;
    rabbetPosition?: { left: RabbetPosition, right: RabbetPosition };
}

export const CompartmentProfileEditor: FC<CompartmentProfileEditorProps> = (props) => {

    const {
        searchProfiles,
        makeFavoriteProfile,
        removeFavoriteProfile,
        renameFavoriteProfile
    } = useContext(CompartmentEditorContext);


    const mode = props.mode ?? CompartmentProfileEditorMode.Asymmetrical;

    const [isProfileLeftModalOpen, setProfileLeftModalOpen] = useState(false);
    const [isProfileRightModalOpen, setProfileRightModalOpen] = useState(false);

    const onChangeLeft = (profile: ConfiguratorCompartmentProfile) => {

        if (mode === CompartmentProfileEditorMode.Asymmetrical) {
            props.onChange({
                left: profile,
                right: props.profiles.right
            });
        } else {
            props.onChange({
                left: profile,
                right: profile
            });
        }

        setProfileLeftModalOpen(false);
    };

    const onChangeRight = (profile: ConfiguratorCompartmentProfile) => {
        props.onChange({
            left: props.profiles.left,
            right: profile
        });
        setProfileRightModalOpen(false);
    };

    const visibleFacets = props.compartmentLayoutTypeCodeCategory === CompartmentLayoutTypeCategory.HefSchuif
        ? HefSchuifProfileFacets
        : StandardProfileFacets;

    const queryCallbackLeft: QueryCallback = (query) => searchProfiles(props.compartmentLayoutTypeCode, props.rabbetPosition?.left, query.filters);
    const queryCallbackRight: QueryCallback = (query) => searchProfiles(props.compartmentLayoutTypeCode, props.rabbetPosition?.right, query.filters);

    const profileLeftPickerProps: ProfilePickerProps = {
        onConfirm: async (selection) => {
            onChangeLeft({
                profileInfo: selection.pop()!
            });
        },
        query: queryCallbackLeft,
        visibleFacets: visibleFacets,
        onMakeFavorite: makeFavoriteProfile,
        onRemoveFavorite: removeFavoriteProfile,
        onRenameFavorite: renameFavoriteProfile
    };

    const profileRightPickerProps: ProfilePickerProps = {
        onConfirm: async (selection) => {
            onChangeRight({
                profileInfo: selection.pop()!
            });
        },
        query: queryCallbackRight,
        visibleFacets: visibleFacets,
        mirror: true,
        onMakeFavorite: makeFavoriteProfile,
        onRemoveFavorite: removeFavoriteProfile,
        onRenameFavorite: renameFavoriteProfile
    };

    const left = <div>
        <div className='d-flex gap-3'>
            <div>
                <div style={{height: '120px', width: '90px'}}>
                    <ProfilePreviewSvg profileSvg={props.profiles?.left?.profileInfo.svgPreview}/>
                </div>
            </div>
            {mode === CompartmentProfileEditorMode.Asymmetrical &&
                <div className={`d-flex flex-column w-100 fs-6`}>
                    <div className={''}>
                        {props.profiles?.left && <>
                            <ProfileDisplayName profile={props.profiles!.left.profileInfo}/>
                        </>}
                    </div>

                    <div className='mt-auto '>
                        <button type='button' className='btn btn-primary btn-sm'
                                onClick={() => setProfileLeftModalOpen(true)}>Profiel wijzigen
                        </button>
                    </div>
                </div>
            }
        </div>

        <ProfilePickerModal show={isProfileLeftModalOpen}
                            onHide={() => setProfileLeftModalOpen(false)}
                            title='Profiel kiezen'
                            pickerProps={profileLeftPickerProps}/>
    </div>


    const right = <div>
        <div className='d-flex gap-3'>
            {mode === CompartmentProfileEditorMode.Asymmetrical &&
                <div className={`d-flex flex-column w-100 fs-6 align-items-end`}>
                    <div>
                        {props.profiles?.right && <>
                            <ProfileDisplayName profile={props.profiles.right.profileInfo}/>
                        </>}
                    </div>
                    <div className='mt-auto '>
                        <button type='button' className='btn btn-primary btn-sm'
                                onClick={() => setProfileRightModalOpen(true)}>Profiel wijzigen
                        </button>
                    </div>
                </div>
            }
            <div>
                <div style={{height: '120px', width: '90px'}}>
                    <ProfilePreviewSvg profileSvg={props.profiles?.right?.profileInfo.svgPreview} mirror={true}/>
                </div>
            </div>
        </div>

        {mode === CompartmentProfileEditorMode.Asymmetrical &&
            <ProfilePickerModal show={isProfileRightModalOpen}
                                onHide={() => setProfileRightModalOpen(false)}
                                title='Profiel kiezen'
                                pickerProps={profileRightPickerProps}/>
        }
    </div>;

    return <>
        <div className={'row'}>
            {mode === CompartmentProfileEditorMode.Asymmetrical
                && <>
                    <div className={'col'}>
                        <label className='form-label'>
                            <small className='text-uppercase text-muted w-100 text-end'>
                                <span>Profiel Links</span>
                            </small>
                        </label>
                        <div className="border p-3 rounded">
                            {left}
                        </div>
                    </div>
                    <div className={'col'}>
                        <label className='form-label'>
                            <small className='text-uppercase text-muted w-100 text-end'>
                                <span>Profiel Rechts</span>
                            </small>
                        </label>
                        <div className="border p-3 rounded">
                            {right}
                        </div>
                    </div>
                </>
                || <>
                    <div className={'col'}>
                        <label className='form-label'>
                            <small className='text-uppercase text-muted w-100 text-end'>
                                <span>Profiel</span>
                            </small>
                        </label>

                        <div className="border p-3 rounded">
                            <div className="d-flex justify-content-between">
                                {left}
                                <div className="d-flex flex-column fs-6">
                                    <div>{props.profiles?.left && <div className="d-flex justify-content-center">
                                        <ProfileDisplayName profile={props.profiles.left.profileInfo}/>
                                    </div>
                                    }
                                    </div>
                                    <div className='mt-auto '>
                                        <button type='button' className='btn btn-primary btn-sm'
                                                onClick={() => setProfileLeftModalOpen(true)}>Profiel wijzigen
                                        </button>
                                    </div>
                                </div>
                                {right}
                            </div>
                        </div>
                    </div>
                </>}
        </div>
    </>
};
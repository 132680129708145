import { useQuery } from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import {
    ProductionSillRepresentation
} from "../../../../modules/api-client/generated";

export const useGetProductionSill = (catalogItemId: string | undefined) => {

    return useQuery<ProductionSillRepresentation, TypeError, ProductionSillRepresentation>(
        ['pmProductionSillCatalogItemIdGet', catalogItemId],
        () => {
            return ApiClient.Pm.ProductionSill.getByCatalogItemId(catalogItemId!).then((res) => res.data);
        }, {
        useErrorBoundary: true,
        enabled: !!catalogItemId,
    });
}
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {useParams} from "react-router-dom";
import {EkoCard, EkoCardBody, EkoCardHeader} from "../../../../../shared/components/card";
import {CustomerModalFormWrapper} from "../../components/CustomerModalFormWrapper";

export function CustomerEdit() {
    const {id} = useParams<{ id: string }>();
    return (
            <EkoCard>
                <EkoCardHeader title={'Gegevens'}>
                </EkoCardHeader>
                <EkoCardBody>
                    <CustomerModalFormWrapper customerId={id} handleClose={() => null}/>
                </EkoCardBody>
            </EkoCard>
    )
}

import {useEffect, useState} from "react";


const containsFile = (dataTransfer: DataTransfer | null) => {

    if (!dataTransfer) return false;

    for (let i = 0; i < (dataTransfer?.items.length ?? 0); i++) {
        const item = dataTransfer?.items[i]!;

        if (item.kind === 'file') {
            if (item.type === 'application/x-moz-nativeimage') {
                return false; //Drag image from img tag.
            }

            return true;
        }
    }

    return false;
}

interface UseDraggingOptions {
    filesOnly: boolean;
}

const defaultOptions = {filesOnly: true};

export function useDragging(options?: Partial<UseDraggingOptions>): boolean {

    const o = {...defaultOptions, ...options ?? {}} as Required<UseDraggingOptions>;

    const [dragging, setDragging] = useState<boolean>(false);

    useEffect(() => {

        let lastEnter: EventTarget | null = null;

        const onDragEnter = (e: DragEvent) => {
            e.preventDefault();

            lastEnter = e.target;

            if (!o.filesOnly || containsFile(e.dataTransfer)) {
                setDragging(true);
            }
        }

        const onDragLeave = (e: DragEvent) => {
            e.preventDefault();
            if (lastEnter === e.target) {
                setDragging(false);
            }
        }

        const onDrop = () => {
            setDragging(false);
        }

        window.addEventListener('dragenter', onDragEnter);
        window.addEventListener('dragleave', onDragLeave);
        window.addEventListener('drop', onDrop);

        return () => {
            window.addEventListener('dragenter', onDragEnter);
            window.removeEventListener('dragleave', onDragLeave);
            window.removeEventListener('drop', onDrop);
        }
    }, [o.filesOnly]);

    return dragging;
}
import {useModalForm, UseModalFormConfig} from "../../../../../shared/components/ModalForm";
import {
    CompartmentLayoutTypeCategory,
    CompartmentLayoutTypeCode,
    HefSchuifSchemaCode,
    RabbetPosition,
    SillNeutPositionCompartmentLayout,
} from "../../../../../modules/api-client/generated";
import React, {FC, useRef, useState} from "react";
import {Form, useFormikContext} from "formik";
import {Button, Modal} from "react-bootstrap";
import SpinnerButton from "../../../../../shared/components/SpinnerButton";
import {useProfilePickerModal} from "../../profile/ProfilePickerModal";
import {HefSchuifProfileFacets, QueryCallback, StandardProfileFacets} from "../../profile/ProfilePicker";
import ApiClient from "../../../../../modules/api-client/ApiClient";
import {Profile, ProfileDisplayName} from "../../profile/ProfileDisplayName";
import ProfilePreviewSvg from "../../../../../shared/components/ProfilePreviewSvg";


export type LayoutStandard = {
    $type: 'standard',
    defaultProfile: Profile;
}

export type LayoutADE = {
    $type: 'hsade'
    defaultProfileInside: Profile;
    defaultProfileOutside: Profile;
}
export type LayoutC = { $type: 'hsc', defaultProfileOutside: Profile; }

export type CurrentValue = LayoutStandard | LayoutADE | LayoutC;


export type FormValues = {
    $type: 'standard',
    defaultProfileId: string;
} | {
    $type: 'hsa' | 'hsd' | 'hse',
    defaultProfileIdInside: string;
    defaultProfileIdOutside: string;
} | {
    $type: 'hsc',
    defaultProfileIdOutside: string;
}

export type SaveHandler = (neutPositionId: string, compartmentLayoutTypeId: string, compartmentLayout: SillNeutPositionCompartmentLayout) => Promise<void>;

export type NeutPosition = {
    id: string;
    name: string;
    profileWidth: number;
}

export type CompartmentLayoutType = {
    id: string;
    code: CompartmentLayoutTypeCode;
    name: string;
    category: CompartmentLayoutTypeCategory;
    schemaCode?: HefSchuifSchemaCode;
    rabbetPosition?: RabbetPosition;
};

export type UseNeutPositionCompartmentLayoutModalConfig = {
    sillId: string;
    save: SaveHandler;
}

function getType(clt: CompartmentLayoutType): 'standard' | 'hsade' | 'hsc' {

    if (clt.category === CompartmentLayoutTypeCategory.Standard) return 'standard';

    if (clt.category === CompartmentLayoutTypeCategory.HefSchuif) {
        switch (clt.schemaCode) {
            case HefSchuifSchemaCode.A:
            case HefSchuifSchemaCode.D:
            case HefSchuifSchemaCode.E:
                return 'hsade';
            case HefSchuifSchemaCode.C:
                return 'hsc';
        }
    }

    throw new Error('Invalid compartment layout type');
}

export const useNeutPositionCompartmentLayoutModal = (config: UseNeutPositionCompartmentLayoutModalConfig) => {

    const state = useRef<{
        neutPosition: NeutPosition,
        compartmentLayoutType: CompartmentLayoutType,
        current: CurrentValue | null,
        defaultProfile: Profile | null,
        defaultProfileInside: Profile | null,
        defaultProfileOutside: Profile | null,
    } | null>(null);

    const modalFormConfig: UseModalFormConfig<FormValues> = {
        onSubmit: async (v: FormValues) => await config.save(state.current!.neutPosition.id,
            state.current!.compartmentLayoutType.id,
            v as SillNeutPositionCompartmentLayout
        )
    };

    const {open: openModal, render} = useModalForm<FormValues>(modalFormConfig);

    const open = (neutPosition: NeutPosition, compartmentLayoutType: CompartmentLayoutType, value: CurrentValue | null) => {

        state.current = {
            neutPosition: neutPosition, compartmentLayoutType: compartmentLayoutType, current: value,
            defaultProfile: null, defaultProfileInside: null, defaultProfileOutside: null
        };

        let initialValues: FormValues;

        if (compartmentLayoutType.category === CompartmentLayoutTypeCategory.Standard) {

            console.log(value);

            state.current.defaultProfile = (value as LayoutStandard)?.defaultProfile;

            initialValues = {
                $type: 'standard',
                defaultProfileId: state.current.defaultProfile?.id || '',
            };

        } else if (compartmentLayoutType.category === CompartmentLayoutTypeCategory.HefSchuif) {

            switch (compartmentLayoutType.schemaCode) {
                case HefSchuifSchemaCode.A: {

                    state.current.defaultProfileInside = (value as LayoutADE)?.defaultProfileInside;
                    state.current.defaultProfileOutside = (value as LayoutADE)?.defaultProfileOutside;

                    initialValues = {
                        $type: 'hsa',
                        defaultProfileIdInside: state.current.defaultProfileInside?.id || '',
                        defaultProfileIdOutside: state.current.defaultProfileOutside?.id || '',
                    };
                    break;
                }
                case HefSchuifSchemaCode.D: {

                    state.current.defaultProfileInside = (value as LayoutADE)?.defaultProfileInside;
                    state.current.defaultProfileOutside = (value as LayoutADE)?.defaultProfileOutside;

                    initialValues = {
                        $type: 'hsd',
                        defaultProfileIdInside: state.current.defaultProfileInside?.id || '',
                        defaultProfileIdOutside: state.current.defaultProfileOutside?.id || '',
                    };
                    break;
                }
                case HefSchuifSchemaCode.E: {

                    state.current.defaultProfileInside = (value as LayoutADE)?.defaultProfileInside;
                    state.current.defaultProfileOutside = (value as LayoutADE)?.defaultProfileOutside;

                    initialValues = {
                        $type: 'hse',
                        defaultProfileIdInside: state.current.defaultProfileInside?.id || '',
                        defaultProfileIdOutside: state.current.defaultProfileOutside?.id || '',
                    };
                    break;
                }
                case HefSchuifSchemaCode.C: {

                    state.current.defaultProfileOutside = (value as LayoutC)?.defaultProfileOutside;

                    initialValues = {
                        $type: 'hsc',
                        defaultProfileIdOutside: state.current.defaultProfileOutside?.id || '',
                    };
                    break;
                }
                default:
                    throw new Error("Not implemented");
            }

        } else
            throw new Error('Invalid compartment layout type');

        openModal(initialValues);
    };


    const element = render(({isSubmitting, close}) => {

        if (!state.current) return <></>;

        const compartmentLayoutType = state.current!.compartmentLayoutType;

        return <NeutPositionCompartmentLayoutForm close={close}
                                                  isSubmitting={isSubmitting}
                                                  sillId={config.sillId}
                                                  neutPositionId={state.current!.neutPosition.id}
                                                  compartmentLayoutType={compartmentLayoutType}
                                                  current={state.current!.current}
        />
    });

    return {
        open: open,
        element: element
    }
}

export const NeutPositionCompartmentLayoutForm: FC<{
    isSubmitting: boolean,
    close: () => void,
    sillId: string,
    neutPositionId: string,
    compartmentLayoutType: CompartmentLayoutType,
    current: CurrentValue | null,
}> = ({isSubmitting, close, sillId, neutPositionId, compartmentLayoutType, current}) => {


    const [defaultProfile, setDefaultProfile] = useState(current?.$type === 'standard' ? current.defaultProfile : null)
    const [defaultProfileInside, setDefaultProfileInside] = useState(current?.$type === 'hsade' ? current.defaultProfileInside : null);
    const [defaultProfileOutside, setDefaultProfileOutside] = useState(current?.$type === 'hsade' ? current.defaultProfileOutside : current?.$type === 'hsc' ? current.defaultProfileOutside : null);

    const {setValues} = useFormikContext<FormValues>();

    const queryCallback: QueryCallback = (query) =>
        ApiClient.Pim.SillProfiles.searchSillProfiles(sillId!, neutPositionId!, compartmentLayoutType.id, compartmentLayoutType.rabbetPosition!, false, query.filters).then((res) => res.data);

    const queryCallbackHsInside: QueryCallback = (query) =>
        ApiClient.Pim.SillProfiles.searchSillProfiles(sillId!, neutPositionId!, compartmentLayoutType.id, RabbetPosition.Inside, false, query.filters).then((res) => res.data);

    const queryCallbackHsOutside: QueryCallback = (query) =>
        ApiClient.Pim.SillProfiles.searchSillProfiles(sillId!, neutPositionId!, compartmentLayoutType.id, RabbetPosition.Outside, false, query.filters).then((res) => res.data);

    const standardProfilePickerModal = useProfilePickerModal({
        title: 'Selecteer een profiel',
        pickerProps: {
            multi: false,
            visibleFacets: StandardProfileFacets,
            query: queryCallback,
        },
        onSelected: async (selection) => {

            setDefaultProfile(selection[0]);

            await setValues((current: FormValues) => {
                return {
                    ...current,
                    defaultProfileId: selection[0].id
                }
            })
        }
    });

    const insideProfilePickerModal = useProfilePickerModal({
        title: 'Selecteer een profiel',
        pickerProps: {
            multi: false,
            visibleFacets: HefSchuifProfileFacets,
            query: queryCallbackHsInside,
        },
        onSelected: async (selection) => {

            setDefaultProfileInside(selection[0]);

            await setValues((current: FormValues) => {
                return {
                    ...current,
                    defaultProfileIdInside: selection[0].id,
                }
            })
        }
    });

    const outsideProfilePickerModal = useProfilePickerModal({
        title: 'Selecteer een profiel',
        pickerProps: {
            multi: false,
            visibleFacets: HefSchuifProfileFacets,
            query: queryCallbackHsOutside,
        },
        onSelected: async (selection) => {

            setDefaultProfileOutside(selection[0]);

            await setValues((current: FormValues) => {
                return {
                    ...current,
                    defaultProfileIdOutside: selection[0].id,
                }
            })
        }
    });


    const type = getType(compartmentLayoutType);

    return <>

        <Form>
            <Modal.Header closeButton>
                <Modal.Title>Vakindeling {current ? 'wijzigen' : 'toevoegen'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className='row mb-4 d-flex align-items-center'>
                    <div className="col-4">
                        <label className='form-label'>
                            Compartment layout type
                        </label>
                    </div>
                    <div className="col">
                        <div className="form-control">{compartmentLayoutType.name}</div>
                    </div>
                </div>

                {type === 'standard' &&
                    <ProfileRow label={'Standaard profiel'}
                                profile={defaultProfile}
                                onEdit={() => standardProfilePickerModal.open()}/>
                }
                {type === 'hsc' &&
                    <ProfileRow label={'Profiel buiten'}
                                profile={defaultProfileOutside}
                                onEdit={() => outsideProfilePickerModal.open()}/>
                }
                {type === 'hsade' && <>
                    <ProfileRow label={'Profiel binnen'}
                                profile={defaultProfileInside}
                                onEdit={() => insideProfilePickerModal.open()}/>
                    <ProfileRow label={'Profiel buiten'}
                                profile={defaultProfileOutside}
                                onEdit={() => outsideProfilePickerModal.open()}/>
                </>
                }
            </Modal.Body>

            <Modal.Footer>
                <Button variant="link" className="mx-4" onClick={close}>
                    Annuleren
                </Button>
                <SpinnerButton type="submit" className="btn btn-primary" spinning={isSubmitting}
                               disabled={isSubmitting}>
                    Opslaan
                </SpinnerButton>
            </Modal.Footer>
        </Form>
        {standardProfilePickerModal.element}
        {insideProfilePickerModal.element}
        {outsideProfilePickerModal.element}
    </>;
}

const ProfileRow: FC<{ label: string, profile: Profile | null, onEdit: () => void }> = ({label, profile, onEdit}) => {

    return <div className='row mb-4 d-flex align-items-center'>
        <div className='col-4'>
            <label className='form-label'>{label}</label>
        </div>
        <div className={'col'}>
            {profile
                && <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <div style={{height: '80px', width: '80px'}}>
                            <ProfilePreviewSvg profileSvg={profile.svgPreview}/>
                        </div>
                        <ProfileDisplayName profile={profile}/>
                    </div>
                    <button type="button" className="btn btn-sm btn-outline-primary" onClick={onEdit}>Wijzigen</button>
                </div>
                || <button type="button" className="btn btn-sm btn-outline-primary" onClick={onEdit}>Selecteer</button>}

        </div>
    </div>
}
import React, { useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import ApiClient from '../../../../modules/api-client/ApiClient';
import { Link, useParams } from 'react-router-dom';
import * as generated from '../../../../modules/api-client/generated';
import Loading from '../../../../shared/components/Loading';
import { formatDate } from '../../../../shared/components/date';
import { MDXEditor, MDXEditorMethods, headingsPlugin } from '@mdxeditor/editor'
import { Button } from 'react-bootstrap';
import { ContentTypeText } from './ContentTypeText';

const ContentDetails: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const queryClient = useQueryClient();
	const { isInitialLoading, data: details, isError } = useQuery<generated.ContentDetails>(['homepageContentDetailsGet'], () => ApiClient.Homepage.getContentDetails(id!).then((res) => res.data));
	const ref = React.useRef<MDXEditorMethods>(null);

	useEffect(() => {
		if (details) {
			ref.current?.setMarkdown(details.message ?? "");
		}
	}, [details]);

	if (isInitialLoading) {
		return <Loading />;
	}

	if (isError) {
		return <>ERROR!</>;
	}

	if (!details) return <></>;

	const handleDeactivate = async () => {
		await ApiClient.Homepage.deactivateNotification(details.id);
		queryClient.invalidateQueries(['homepageContentDetailsGet']).then();
	};

	return (
		<div className='card mb-5'>
			<div className='card-header'>
				<h3 className='card-title'>{details.contentType} {details.code}</h3>
				<div className='card-toolbar'>
					{details.contentType.toUpperCase() === generated.ContentType.ContentBlock.toUpperCase() && (
						<Link to='changeMessage' relative='path' className='btn btn-primary ms-2'>
							Bericht wijzigen
						</Link>)}
					{(details.contentType.toUpperCase() === generated.ContentType.NewsItem.toUpperCase() ||
						details.contentType.toUpperCase() === generated.ContentType.Image.toUpperCase()) && (
							<>
								<Link to='changeImage' relative='path' className='btn btn-primary ms-2'>
									{details.image !== null ? 'Afbeelding wijzigen' : 'Afbeelding toevoegen'}
								</Link>
							</>)}
					{details.contentType.toUpperCase() === generated.ContentType.NewsItem.toUpperCase() && (
						<>
							<Link to='changeMessageAndDate' relative='path' className='btn btn-primary ms-2'>
								Bericht en datum wijzigen
							</Link>
						</>)}
					{details.contentType.toUpperCase() === generated.ContentType.Notification.toUpperCase() && (
						<>
							<Link to='activateNotification' relative='path' className='btn btn-primary ms-2'>
								Activeren
							</Link>
							<Button className='btn btn-primary ms-2' onClick={handleDeactivate} >
								Deactiveren
							</Button>
						</>)}
				</div>
			</div>

			<div className='card-body position-relative'>
				<div className='row'>
					{(details.contentType.toUpperCase() === generated.ContentType.NewsItem.toUpperCase() ||
						details.contentType.toUpperCase() === generated.ContentType.Image.toUpperCase()) && (
							<div className='col-3'>
								<div style={{ maxHeight: '100%', maxWidth: '100%' }}>
									{details.image !== null && (
										<img
											src={details.image?.blobUri!}
											alt={details.image?.altText!}
											style={{ maxHeight: '100%', maxWidth: '100%' }}
										/>
									)}
								</div>
							</div>)}
					<div className='col'>
						<div className='row mb-4'>
							<div className='col-3'>
								<label className='form-label'>Code</label>
							</div>
							<div className='col'>{details.code}</div>
						</div>
						<div className='row mb-4'>
							<div className='col-3'>
								<label className='form-label'>Type</label>
							</div>
							<div className='col'><ContentTypeText type={details.contentType.toLowerCase()} /></div>
						</div>
						{details.contentType.toUpperCase() !== generated.ContentType.Image.toUpperCase() &&
							<div className='row mb-4'>
								<div className='col-3'>
									<label className='form-label'>Inhoud</label>
								</div>
								<div className='col p-0'>
									<MDXEditor markdown={details.message ?? ""} ref={ref} plugins={[headingsPlugin()]} readOnly />
								</div>
							</div>}
						{details.contentType.toUpperCase() === generated.ContentType.NewsItem.toUpperCase() && (<div className='row mb-4'>
							<div className='col-3'>
								<label className='form-label'>Geplaatst op</label>
							</div>
							<div className='col'>{formatDate(details.datePosted!)}</div>
						</div>)}
						{details.contentType.toUpperCase() === generated.ContentType.Notification.toUpperCase() && (<div className='row mb-4'>
							<div className='col-3'>
								<label className='form-label'>Geldig van</label>
							</div>
							<div className='col'>{formatDate(details.validFrom!)}</div>
						</div>)}
						{details.contentType.toUpperCase() === generated.ContentType.Notification.toUpperCase() && (<div className='row mb-4'>
							<div className='col-3'>
								<label className='form-label'>Geldig tot</label>
							</div>
							<div className='col'>{formatDate(details.validTo!)}</div>
						</div>)}
						{details.contentType.toUpperCase() === generated.ContentType.Notification.toUpperCase() && (<div className='row mb-4'>
							<div className='col-3'>
								<label className='form-label'>Status</label>
							</div>
							<div className='col'>{details.active ? "Actief" : "Inactief"}</div>
						</div>)}
					</div>
				</div>
			</div>
		</div>)
};

export default ContentDetails;
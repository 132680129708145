import React, {createContext, useContext, useId, useLayoutEffect, useRef, useState} from 'react';
import {Field, Form, Formik, useFormikContext} from 'formik';
import {
	NeutDrillHolePositioningStrategyName,
	SillConfigurationNeut,
	SillConfigurationNeutDrillHole, SillNeutDrillHoleMode,
	SillNeutOptions,
} from '../../../modules/api-client/generated';
import {NeutEditorDrillHoles} from './NeutEditorDrillHoles';

export interface NeutEditorOptions {
	value: SillConfigurationNeut | null;
	onChange: (result: SillConfigurationNeut | null) => void;
	neutOptions: SillNeutOptions;
	calculateDefaultDrillHoles: (neut: SillConfigurationNeut, positioningStrategyName: NeutDrillHolePositioningStrategyName) => Promise<null | Array<SillConfigurationNeutDrillHole>>;
	canOmit: boolean;
	children?: React.ReactNode | ((config: NeutEditorConfig) => React.ReactNode);
}

export interface NeutEditorProps extends NeutEditorOptions {
}

export interface NeutEditorConfig {
	submit: () => Promise<void>;
	content: React.ReactNode;
	isSubmitting: boolean;
	isInitializing: boolean;

	isOmitted: boolean;
	addNeut: () => void;
}

export interface NeutEditorContextValue {
	isInitializing: boolean;
	isOmitted: boolean;
	addNeut: () => void;
}

export const NeutEditorContext = createContext<NeutEditorContextValue>(undefined!);

export type NeutEditorFormValues = ({omitted?: undefined} & SillConfigurationNeut) | {omitted: true};

export const NeutEditor: React.FC<NeutEditorProps> = (props) => {

	const [isInitializing, setInitializing] = useState<boolean>(false);


	const onSubmit = (value: NeutEditorFormValues) => {
		if (value.omitted) {
			props.onChange(null);
		} else {
			props.onChange(value);
		}
	};

	const initializeNeut = async () => {
		const neut: SillConfigurationNeut = {
			width: 67,
			height: props.neutOptions.defaultNeutHeight,
			drillHoles: [],
		};
		neut.drillHoles = await props.calculateDefaultDrillHoles(neut, props.neutOptions.drillHoleOptions!.positioningStrategyName);

		return neut;
	};

	return (
		<Formik initialValues={props.value ?? {omitted: true}} enableReinitialize={false} onSubmit={onSubmit}>
			{(formik) => {

				const isOmitted = (formik.values as NeutEditorFormValues).omitted ?? false;

				const addNeut = async () => {
					try {
						setInitializing(true);
						const values = await initializeNeut();

						formik.resetForm({values: values});
					} finally {
						setInitializing(false);
					}
				};

				const contextValue: NeutEditorContextValue = {
					isInitializing: isInitializing,
					isOmitted: isOmitted,
					addNeut: addNeut,
				};

				return (
					<NeutEditorContext.Provider value={contextValue}>
						<Form>
							<NeutEditorChildren {...props} />
						</Form>
					</NeutEditorContext.Provider>
				);
			}}
		</Formik>
	);
};

const NeutEditorChildren: React.FC<NeutEditorProps> = (props) => {
	const {isInitializing, isOmitted, addNeut} = useContext(NeutEditorContext);

	const {submitForm, isSubmitting} = useFormikContext<SillConfigurationNeut>();

	const editorContent = <NeutEditorContent {...props} />;

	const childNodes =
		typeof props.children === 'undefined'
			? editorContent
			: typeof props.children === 'function'
				? props.children?.call(this, {
					content: editorContent,
					submit: submitForm,
					isSubmitting: isSubmitting,
					isInitializing: isInitializing,
					isOmitted: isOmitted,
					addNeut: addNeut,
				})
				: props.children;

	return <>{childNodes}</>;
};

const NeutEditorContent: React.FC<NeutEditorProps> = (props) => {

	const id = useId();

	const {isOmitted} = useContext(NeutEditorContext);

	const {setValues, values} = useFormikContext<SillConfigurationNeut>();

	const customWidthInput = useRef<HTMLInputElement>(null);
	const customHeightInput = useRef<HTMLInputElement>(null);

	// eslint-disable-next-line eqeqeq
	const isCustomWidth = props.neutOptions.standardWidths?.includes(values.width) !== true;

	// eslint-disable-next-line eqeqeq
	const isCustomHeight = !(props.neutOptions.defaultNeutHeight == values.height);

	const isCustomWidthEnabled = isCustomWidth || props.neutOptions.customWidth;
	const isCustomHeightEnabled = isCustomHeight || props.neutOptions.customHeight;

	useLayoutEffect(() => {
		if (isCustomWidth) {
			customWidthInput.current?.focus();
		}
	}, [customWidthInput, isCustomWidth]);

	useLayoutEffect(() => {
		if (isCustomHeight) {
			customHeightInput.current?.focus();
		}
	}, [customHeightInput, isCustomHeight]);

	const changeWidth = async (width: number | '') => {
		await setValues((prev) => {
			return {...prev, width: width} as SillConfigurationNeut;
		});
	};

	const changeHeight = async (height: number | '') => {
		await setValues((prev) => {

			return {...prev, height: height} as SillConfigurationNeut;
		});
	};

	return (
		<>
			{(isOmitted && <div>Deze neut is weglaten.</div>) || (
				<div>
					<div className='row'>
						<div className='col'>
							<div className='p-3 d-grid gap-3'>
								<div>
									<label htmlFor={`${id}_width`} className='form-label'>
										<small className='text-uppercase text-muted'>Breedte</small>
									</label>

									<div className='input-group'>
										<div className={`input-group-text dropdown-toggle cursor-pointer`}
											 data-bs-toggle='dropdown'>
											{!isCustomWidth &&
												<>
													{values.width}mm <small
													className='text-muted ms-1'>(standaard)</small>
												</>
											}

											{isCustomWidth && <span className='fw-bold'>Afwijkend</span>}
										</div>

										{isCustomWidth && (
											<>
												<Field type='number' className='form-control text-end' name='width'
													   min={props.neutOptions.minWidth}
													   innerRef={customWidthInput} placeholder='Afwijkende neut breedte'
													   required />

												<span className='input-group-text'>mm</span>
											</>
										)}

										<ul className='dropdown-menu dropdown-menu-end'>

											{props.neutOptions.standardWidths?.map((w, index) =>
												<li key={index} className='dropdown-item'
													onClick={() => changeWidth(w)}>
													{w}mm <small className='text-muted ms-1'>(standaard)</small>
												</li>,
											)}

											{isCustomWidthEnabled &&
												<li className='dropdown-item' onClick={() => changeWidth('')}>
													Afwijkend
												</li>
											}
										</ul>
									</div>
								</div>

								<div>
									<label htmlFor={`${id}_drillholes`} className='form-label'>
										<small className='text-uppercase text-muted'>Neut hoogte</small>
									</label>
									<div className='input-group'>
										<div className={`input-group-text dropdown-toggle cursor-pointer`}
											 data-bs-toggle='dropdown'>
											{!isCustomHeight &&
												<>
													<span>{values.height}mm</span> <small
													className='text-muted ms-1'>(standaard)</small>
												</>
											}

											{isCustomHeight && <span className='fw-bold'>Afwijkend</span>}

										</div>
										{isCustomHeight && (
											<>
												<Field type='text' className='form-control text-end'
													   innerRef={customHeightInput} name='height' required
													   placeholder='Afwijkende neut hoogte' />
												<span className='input-group-text'>mm</span>
											</>
										)}

										<ul className='dropdown-menu dropdown-menu-end'>
											<li className='dropdown-item'
												onClick={() => changeHeight(props.neutOptions.defaultNeutHeight)}>
												{props.neutOptions.defaultNeutHeight}mm <small
												className='text-muted ms-1'>(standaard)</small>
											</li>

											{isCustomHeightEnabled &&
												<li className='dropdown-item' onClick={() => changeHeight('')}>
													Afwijkend
												</li>
											}
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className='col'>
							<div className='p-3 d-grid gap-3'>
								{/*//TODO Render sill with viewbox for this neut*/}
								{/*<Preview svg={previewData?.previewSvg}*/}
								{/*		 onNeutClick={(index) => void(0)} />*/}
							</div>
						</div>
					</div>

					{props.neutOptions.drillHoleMode !== SillNeutDrillHoleMode.None &&
						<div className='row'>
							<div className='col'>
								<div className='p-3 d-grid gap-3'>
									<NeutEditorDrillHoles {...props} />
								</div>
							</div>
						</div>
					}

				</div>
			)}
		</>
	);
};

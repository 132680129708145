import {useQuery, useQueryClient} from '@tanstack/react-query';
import * as api from '../../../../modules/api-client/generated';
import ApiClient from '../../../../modules/api-client/ApiClient';
import {NotFound, ValidationFailed} from '../../../../modules/api-client/Responses';
import {useEkosietMutation} from '../../../../shared/hooks/useEkosietMutation';
import {
	CreateSluitpotCommand,
	Sluitpot,
	UpdateSluitpotCommand
} from '../../../../modules/api-client/generated';
import {FormValues} from './SluitpotUpsert';

export const useSluitpotUpsertMutation = (id?: string | undefined) => {
	const queryClient = useQueryClient();

	return useEkosietMutation<api.Sluitpot, NotFound | ValidationFailed, FormValues>(
		(values) => {
			const command: CreateSluitpotCommand | UpdateSluitpotCommand = {
				code: values.code,
				active: values.active,
				name: values.name,
				angle: values.angle,
				contour: values.contour,
				sluitpotBlockTypeId: values.sluitpotBlockTypeId,
				height: values.height,
				sluitpotBeslagId: values.sluitpotBeslagId,
				operationName: values.operationName,
			};

			if (id) {
				return ApiClient.Pim.Sluitpot.pimSluitpotsIdPut(id, undefined, command).then((response) => response.data);
			}
			return ApiClient.Pim.Sluitpot.pimSluitpotsPost(undefined, command).then((response) => response.data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['pimSluitpotsIdGet', id]).then();
				queryClient.invalidateQueries(['pimSluitpotsGet']).then();
			},
		},
	);
};

export const useSluitpotQuery = (id: string | undefined) => {
	return useQuery<Sluitpot,TypeError,Sluitpot>(
		['pimSluitpotsIdGet', id],
		() => {
			return ApiClient.Pim.Sluitpot.pimSluitpotsIdGet(id!).then((res) => res.data);
		},
		{
			useErrorBoundary: true,
			enabled: !!id,
		},
	);
};

export const useSluitpotDeleteMutation = (id: string) => {
	const queryClient = useQueryClient();
	return useEkosietMutation<void, NotFound | ValidationFailed, string>(
		(id) => {
			return ApiClient.Pim.Sluitpot.pimSluitpotsIdDelete(id, undefined).then((response) => response.data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['pimSluitpotsIdGet', id]).then();
				queryClient.invalidateQueries(['pimSluitpotsGet']).then();
			},
		},
	);
};

import React, {useContext, useId} from 'react';
import {CompartmentEditorOptions} from "./CompartmentEditor";
import {Field, useFormikContext} from "formik";
import {
    CompartmentLayoutTypeCategory,
    CompartmentLayoutTypeCode, ConfiguratorCompartmentProfiles, ConfiguratorCornerPiece,
    SillConfigurationCompartment,
    SillType
} from "../../../modules/api-client/generated";
import {CompartmentSluitpotten} from "./CompartmentSluitpotten";
import {CompartmentEditorContext} from "./CompartmentEditorContextProvider";
import {CompartmentProfileEditor, CompartmentProfileEditorMode} from "./CompartmentProfileEditor";

export const StandardCompartmentEditorFields: React.FC<CompartmentEditorOptions> = (props) => {
    const {
        profiles,
        setProfiles,
    } = useContext(CompartmentEditorContext);

    const id = useId();

    const {values, setValues} = useFormikContext<SillConfigurationCompartment>();

    const glazingBarOption = values.compartmentLayoutTypeCode === CompartmentLayoutTypeCode.VastglasBuiten || values.compartmentLayoutTypeCode === CompartmentLayoutTypeCode.VastglasBinnen;

    const glazingProfileOption = (props.sillType === SillType.Buitensponning || props.sillType === SillType.HefSchuif) && values.compartmentLayoutTypeCode === CompartmentLayoutTypeCode.VastglasBinnen;

    const glazingBar = values.glazingBar
        ? Object.values(props.glazingBars).filter(x => x.width === values.glazingBar?.width && x.height === values.glazingBar?.height).shift()
        : null;


    let cornerPiece: ConfiguratorCornerPiece | null = null;

    if (glazingBar && (values.glazingBar?.cornerPieceLeft || values.glazingBar?.cornerPieceRight)) {

        const {width, height} = (values.glazingBar!.cornerPieceLeft ?? values.glazingBar!.cornerPieceRight!);

        cornerPiece = Object.values(glazingBar.cornerPieces).filter(x => x.width === width && x.height === height).shift() ?? null;
    }

    const cornerPieceOptions = glazingBar?.cornerPieces ?? {};

    const glazingProfile = values.glazingProfile
        ? Object.values(props.glazingProfiles).filter(x => x.rabbetWidth === values.glazingProfile?.rabbetWidth).shift()
        : null;

    const stopProfile = values.stopProfile
        ? Object.values(props.stopProfiles).filter(x => x.rabbetWidth === values.stopProfile?.rabbetWidth).shift()
        : null;

    const stopProfileOption =
        (props.sillType === SillType.Buitensponning || props.sillType === SillType.HefSchuif)
        && (values.compartmentLayoutTypeCode === CompartmentLayoutTypeCode.DeurBinnendraaiend || values.compartmentLayoutTypeCode === CompartmentLayoutTypeCode.DraaiKiep);

    const stopProfileOptions = Object.values(props.stopProfiles).filter(x =>
        x.compartmentLayoutTypes.find(c => c.code === values.compartmentLayoutTypeCode),
    );

    const stopRabbetOption = values.compartmentLayoutTypeCode === CompartmentLayoutTypeCode.DraaiKiep;

    const compartmentLayoutTypeOption = props.compartmentLayoutOptions.find(o => o.code === values.compartmentLayoutTypeCode);

    const onProfileChange = async (profile: ConfiguratorCompartmentProfiles) => {
        setProfiles(profile);
        await setValues(current => {
            return {...current, profileId: profile.left.profileInfo.id}
        });
    };

    const changeGlazingBar = async (glazingBarId: string) => {
        await setValues((prev) => {
            const update = {...prev};

            if (glazingBarId === '') {
                update.glazingBar = undefined;
            } else {

                const gb = props.glazingBars[glazingBarId];

                update.glazingBar = {
                    width: gb.width,
                    height: gb.height,
                    cornerPieceLeft: undefined,
                    cornerPieceRight: undefined,
                };
            }

            return update;
        });
    };

    const changeCornerPiece = async (cornerPieceId: string) => {
        await setValues((prev) => {
            const update = {...prev};

            if (cornerPieceId === '') {
                update.glazingBar!.cornerPieceLeft = undefined;
                update.glazingBar!.cornerPieceRight = undefined;
            } else {

                const cornerPiece = glazingBar?.cornerPieces[cornerPieceId];

                if (cornerPiece) {
                    update.glazingBar!.cornerPieceLeft = {
                        width: cornerPiece.width,
                        height: cornerPiece.height,
                    };
                    update.glazingBar!.cornerPieceRight = {
                        width: cornerPiece.width,
                        height: cornerPiece.height,
                    };
                }
            }

            return update;
        });
    };

    const changeGlazingProfile = async (glazingProfileId: string) => {
        await setValues((prev) => {
            const update = {...prev};

            if (glazingProfileId === '') {
                update.glazingProfile = undefined;
            } else {

                const gp = props.glazingProfiles[glazingProfileId];

                update.glazingProfile = {
                    rabbetWidth: gp.rabbetWidth,
                };
            }

            return update;
        });
    };

    const changeStopProfile = async (stopProfileId: string) => {
        await setValues((prev) => {
            const update = {...prev};

            if (stopProfileId === '') {
                update.stopProfile = undefined;
            } else {

                const sp = props.stopProfiles[stopProfileId];

                update.stopProfile = {
                    rabbetWidth: sp.rabbetWidth,
                };
            }

            return update;
        });
    };

    return <div className='p-3 d-grid gap-3'>

        {props.neutPosition && profiles &&
            <CompartmentProfileEditor sillId={props.sillId}
                                      neutPositionId={props.neutPosition.id}
                                      compartmentLayoutTypeCodeCategory={CompartmentLayoutTypeCategory.Standard}
                                      compartmentLayoutTypeCode={values.compartmentLayoutTypeCode}
                                      profileWidth={props.neutPosition.profileWidth}
                                      mode={CompartmentProfileEditorMode.Symmetrical}
                                      profiles={profiles}
                                      onChange={onProfileChange}/>

        }

        {glazingProfileOption && (
            <div>
                <label htmlFor={`${id}_glazingProfileId`} className='form-label'>
                    <small className='text-uppercase text-muted'>Beglazingsprofiel</small>
                </label>

                <select id={`${id}_glazingProfileId`}
                        className='form-select'
                        value={glazingProfile?.id ?? ''}
                        onChange={(e) => changeGlazingProfile(e.target.value)}>
                    <option value=''>Zonder beglazingsprofiel</option>
                    {Object.values(props.glazingProfiles).map((gp) => (
                        <option key={gp.id} value={gp.id}>
                            {gp.name}
                        </option>
                    ))}
                </select>
            </div>
        )}

        {stopProfileOption && (
            <div>
                <label htmlFor={`${id}_stopProfileId`} className='form-label'>
                    <small className='text-uppercase text-muted'>Aanslagprofiel</small>
                </label>

                <select id={`${id}_stopProfileId`}
                        className='form-select'
                        value={stopProfile?.id ?? ''}
                        onChange={(e) => changeStopProfile(e.target.value)}>
                    <option value=''>Zonder aanslagprofiel</option>

                    {stopProfileOptions.map((l) => (
                        <option key={l.id} value={l.id}>
                            {l.name}
                        </option>
                    ))}
                </select>
            </div>
        )}

        {stopRabbetOption && (
            <div>
                <label htmlFor={`${id}_stopRabbetDepth`} className='form-label'>
                    <small className='text-uppercase text-muted'>Aanslagsponning</small>
                </label>

                <Field as='select' id={`${id}_stopRabbetDepth`} name='stopRabbetDepth'
                       className='form-select' meta={{initialValue: ''}}>
                    <option value=''>Geen aanslagsponning</option>
                    {props.stopRabbetDepths.map((l) => (
                        <option key={l} value={l}>
                            {l}mm
                        </option>
                    ))}
                </Field>
            </div>
        )}
        {glazingBarOption && (
            <div className='row'>
                <div className='col-7'>
                    <label htmlFor={`${id}_glazingBar`} className='form-label'>
                        <small className='text-uppercase text-muted'>Glaslat</small>
                    </label>

                    <select
                        id={`${id}_glazingBar`}
                        name='glazingBar.glazingBarId'
                        className='form-select'
                        value={glazingBar?.id ?? ''}
                        onChange={(e) => changeGlazingBar(e.target.value)}>
                        <option value=''>Geen glaslat meeleveren</option>
                        {Object.keys(props.glazingBars).map((id) => {

                            const l = props.glazingBars[id];

                            return (
                                <option key={l.id} value={l.id}>
                                    {l.name}
                                </option>
                            );
                        })}
                    </select>
                </div>

                <div className='col'>
                    <label htmlFor={`${id}_glazingBar`} className='form-label'>
                        <small className='text-uppercase text-muted'>Glaslat hoekstuk</small>
                    </label>

                    <select
                        id={`${id}_glazingBar`}
                        className='form-select'
                        disabled={!glazingBar}
                        value={cornerPiece?.id ?? ''}
                        onChange={(e) => changeCornerPiece(e.target.value)}
                    >
                        <option value=''>Geen hoekstuk</option>
                        {Object.values(cornerPieceOptions).map((cp) => (
                            <option key={cp.id} value={cp.id}>
                                {cp.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        )}
        <CompartmentSluitpotten {...props} />

        <div className='row mb-4'>

            {compartmentLayoutTypeOption?.canHaveDrainageHoles &&
                <div className='col-4'>
                    <label className='form-label'>
                        <small className='text-uppercase text-muted'>Afwateringsgaten</small>
                    </label>

                    <select className='form-select'
                            value={values.drainageHoles ? 'true' : 'false'}
                            onChange={(e) => setValues({
                                ...values,
                                drainageHoles: e.target.value === 'true',
                            })}>
                        <option value='false'>Nee</option>
                        <option value='true'>Ja</option>
                    </select>
                </div>
            }


            {compartmentLayoutTypeOption?.canHaveAerationHoles &&
                <div className='col-4'>
                    <label className='form-label'>
                        <small className='text-uppercase text-muted'>Beluchtingsgaten</small>
                    </label>


                    <select className='form-select'
                            value={values.aerationHoles ? 'true' : 'false'}
                            onChange={(e) => setValues({
                                ...values,
                                aerationHoles: e.target.value === 'true',
                            })}>
                        <option value='false'>Nee</option>
                        <option value='true'>Ja</option>
                    </select>
                </div>
            }

            <div className='col-4'>

                <label className='form-label'>
                    <small className='text-uppercase text-muted'>Beschermkap</small>
                </label>


                <select className='form-select'
                        value={values.protector ? 'true' : 'false'}
                        onChange={(e) => setValues({
                            ...values,
                            protector: e.target.value === 'true',
                        })}>
                    <option value='false'>Nee</option>
                    <option value='true'>Ja</option>
                </select>

            </div>
        </div>

    </div>

};

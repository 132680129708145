import React from "react";
import {useQuery} from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import {Link} from "react-router-dom";
import SillTypeText from "../sill/SillTypeText";
import {SillDetails} from "../../../../modules/api-client/generated";
import ActiveBadge from "../../../../shared/components/ActiveBadge";
import {EkoTable, TableHeader} from "../../../../shared/components/table";
import {EkoCard, EkoCardBody, EkoCardHeader, EkoCardToolbar} from "../../../../shared/components/card";
import BooleanBadge from "../../../../shared/components/BooleanBadge";
import SillContourSvg from "../../../../shared/components/SillContourSvg";
import Loading from "../../../../shared/components/Loading";

const SillList: React.FC = () => {
    const {
        isInitialLoading,
        data: sillList,
        isError
    } = useQuery<SillDetails[]>(['ApiClient.Pim.Sill.listSills'], () => ApiClient.Pim.Sill.listSills().then((res) => res.data));
    if (isInitialLoading) {
        return <Loading/>;
    }
    if (isError) {
        return <>ERROR!</>;
    }
    if (!sillList) return <></>;


    return <>

        <EkoCard>
            <EkoCardHeader title={'Dorpels'}>
                <EkoCardToolbar>
                    <Link to={'add'} className="btn btn-primary btn-sm">Toevoegen</Link>
                </EkoCardToolbar>
            </EkoCardHeader>
            <EkoCardBody>
                <EkoTable>
                    <TableHeader>
                        <th></th>
                        <th>Code</th>
                        <th>Naam</th>
                        <th className="fit-content text-center">Contour</th>
                        <th className="fit-content text-center">Contra profiel</th>
                        <th className="text-nowrap">Sponning type</th>
                        <th className="fit-content text-end">Sponning / Breedte</th>
                        <th className="fit-content text-end">Standaard neut hoogte</th>
                        <th></th>
                    </TableHeader>
                    <tbody>
                    {(!sillList || sillList.length === 0) &&
                        <tr>
                            <td colSpan={5} className="text-center">Geen gegevens</td>
                        </tr>}

                    {sillList && sillList.map((sill) => <tr key={sill.id}>
                        <td className="fit-content user-select-none">
                            <div style={{height: '80px', width: '80px'}}>
                                <SillContourSvg profileSvg={sill.contourSvg}/>
                            </div>
                        </td>
                        <td className="fit-content">
                            <Link to={sill.id}
                            >{sill.code}</Link>
                        </td>
                        <td>
                            <div>
                                {sill.name}
                            </div>
                            <div className="text-muted">
                                {sill.compartmentLayouts ? <>{sill.compartmentLayouts.map(l => l.name).join(', ')}</> :
                                    <span className="text-muted">-</span>}
                            </div>
                        </td>
                        <td className="fit-content text-center">
                            <BooleanBadge value={sill.contour !== null}/>
                        </td>
                        <td className="fit-content text-center">
                            <BooleanBadge value={sill.contraProfile !== null}/>
                        </td>
                        <td className="fit-content">
                            <SillTypeText code={sill.type}/>
                        </td>
                        <td className="fit-content text-end">
                            {sill.rabbetWidth}mm / {sill.width}mm
                        </td>
                        <td className="fit-content text-end">
                            {sill.neutOptions.defaultNeutHeight}mm
                        </td>

                        <td className="fit-content text-center">
                            <ActiveBadge value={sill}/>
                        </td>

                    </tr>)}
                    </tbody>
                </EkoTable>
            </EkoCardBody>
        </EkoCard>
    </>
}

export default SillList;
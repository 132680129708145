import React from 'react';
import {useQuery} from '@tanstack/react-query';
import {NumericFormat} from "react-number-format";
import {WeeklyLevertijdStatsRow} from "../../../../../modules/api-client/generated";
import ApiClient from "../../../../../modules/api-client/ApiClient";
import Loading from "../../../../../shared/components/Loading";
import {KTSVG} from "../../../../../shared/components/KTSVG";

type Props = {
    className: string
    description: string
    color?: string
    img?: string
}
export const WeeklyLevertijdStatsTile: React.FC<Props> = ({className, description, color, img}) => {

    const {isInitialLoading, data} = useQuery<{ [key: string]: { [key: string]: WeeklyLevertijdStatsRow; }; }>(
        ['weeklyLevertijdStats'],
        () => ApiClient.Em.Reporting.weeklyLevertijdStats().then((res) => res.data));
    if (!data) return <Loading/>

    if (isInitialLoading) return <Loading/>
    return (
        <>
            {Object.keys(data).reverse().slice(0,1).map((year) => {
                return (
                    <>

                        {Object.keys(data[year]).reverse().filter((v, i) => {
                            return i == 0
                        }).map((week) => {
                            const weekNumber:number = +week;
                            return (
                                <>
                                    <div key={week} className="card mb-5 mb-xl-8">
                                        <div className="card-body p-0">
                                            <div className="px-9 pt-7 card-rounded h-175px w-100 bg-warning">
                                                <div className="d-flex flex-stack text-white"><h3
                                                    className="m-0 fw-bold text-white fs-3">Levertijd week {week}</h3>
                                                    <span>
                                                        <span className="fw-bold fs-2x pt-1 ">{data[year][week]?.levertijdAvg} 	</span>
                                                        dagen</span>
                                                </div>
                                            </div>
                                            <div
                                                className="shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-body"
                                                style={{marginTop: '-100px'}}>
                                                <div className="d-flex align-items-center mb-6">
                                                    <div className="symbol symbol-45px w-40px me-5"><span
                                                        className="symbol-label bg-lighten">
                                                      <KTSVG path={'/media/icons/duotune/general/gen005.svg'} className='svg-icon-2' />
                                                </span>
                                                    </div>
                                                    <div className="d-flex align-items-center flex-wrap w-100">
                                                        <div className="mb-1 pe-3 flex-grow-1"><a href="#"
                                                                                                  className="fs-5 text-gray-800 text-hover-primary fw-bold">Week {weekNumber-1}</a>
                                                            <div className="text-gray-400 fw-semibold fs-7">
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <div
                                                                className="fw-bold fs-5 text-gray-800 pe-1">{data[year][weekNumber-1]?.levertijdAvg} dagen</div>
                                                            <i className="ki-duotone ki-arrow-up fs-5 text-success ms-1"><span
                                                                className="path1"></span><span className="path2"></span></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center mb-6">
                                                    <div className="symbol symbol-45px w-40px me-5"><span
                                                        className="symbol-label bg-lighten">
                                                      <KTSVG path={'/media/icons/duotune/finance/fin001.svg'} className='svg-icon-2' />
                                                </span>
                                                    </div>
                                                    <div className="d-flex align-items-center flex-wrap w-100">
                                                        <div className="mb-1 pe-3 flex-grow-1"><a href="#"
                                                                                                  className="fs-5 text-gray-800 text-hover-primary fw-bold">Week {weekNumber-2}</a>
                                                            <div className="text-gray-400 fw-semibold fs-7">
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <div
                                                                className="fw-bold fs-5 text-gray-800 pe-1">{data[year][weekNumber-2]?.levertijdAvg} dagen</div>
                                                            <i className="ki-duotone ki-arrow-down fs-5 text-danger ms-1"><span
                                                                className="path1"></span><span className="path2"></span></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center mb-6">
                                                    <div className="symbol symbol-45px w-40px me-5"><span
                                                        className="symbol-label bg-lighten">
                                                      <KTSVG path={'/media/icons/duotune/finance/fin001.svg'} className='svg-icon-2' />
                                                </span>
                                                    </div>
                                                    <div className="d-flex align-items-center flex-wrap w-100">
                                                        <div className="mb-1 pe-3 flex-grow-1"><a href="#"
                                                                                                  className="fs-5 text-gray-800 text-hover-primary fw-bold">Jaarlijks gemiddelde</a>
                                                            <div className="text-gray-400 fw-semibold fs-7">
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <div
                                                                className="fw-bold fs-5 text-gray-800 pe-1">{data[year][week]?.levertijdAvgYTD} dagen</div>
                                                            <i className="ki-duotone ki-arrow-down fs-5 text-danger ms-1"><span
                                                                className="path1"></span><span className="path2"></span></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <span className={'text-muted mb-0'}>* Gebaseerd op afgeleverde orders met levertijd "zo snel mogelijk".</span>
                                            </div>
                                        </div>
                                    </div>


                                </>


                            )
                        })
                        }

                    </>
                )

            })}
        </>
    );
};
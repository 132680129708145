import {Dispatch, FC, SetStateAction, useEffect, useState} from 'react'
import {Field, useFormikContext, useField} from 'formik'
import {
    CustomerDeliveryConditionsPicker
} from '../../../../../../../customer-portal/components/CustomerDeliveryConditionsPicker'
import {useGetCustomer} from '../../../../../crm/hooks/use-get-customer'
import {CustomerAddressPicker} from '../../../../../../../customer-portal/components/CustomerAddressPicker'
import {formatDate} from '../../../../../../../shared/components/date'
import {OrderAddress} from '../OrderAddress'
import {IAddOrderForm, addBusinessDays} from '../../types/IAddOrderForm'
import {DeliveryConditions} from '../../../../../../../modules/api-client/generated'
import {CalendarField, resetTimeOfDate} from '../../../../../../../shared/components/calendar'
import TextField from "../../../../../../../shared/components/TextField";

export type Props = {
    setFormValues: Dispatch<SetStateAction<IAddOrderForm>>
}

const ExpeditionDetails: FC<Props> = ({setFormValues}) => {
    const minExpeditionDate = resetTimeOfDate(addBusinessDays(new Date(), 10));

    const {
        values,
        setFieldValue,
        handleChange
    } = useFormikContext<IAddOrderForm>();


    const setPreferredExpeditionDate = (val: Date) => {
        setFieldValue('expedition.preferredExpeditionDate', val);
    }

    const [expeditionDate, setExpeditionDate] = useState<Date>(values.expedition.preferredExpeditionDate)
    const [field1, meta1, helpers1] = useField("expedition.deliveryCondition");
    const {value: value1} = meta1;
    const [field2, meta2, helpers2] = useField("expedition.deliveryDateChoice");
    const {value: value2} = meta2;
    const [
        metaCustomerDeliveryAddressId,
    ] = useField("orderDeliveryAddressPickerId");

    const {value: valueCustomerDeliveryAddressId} = metaCustomerDeliveryAddressId;
    const {data: customerData} = useGetCustomer(values.customerId);

    const deliveryAddressess = customerData?.addresses?.filter(address => address.isDeliveryAddress);

    useEffect(() => {
        setExpeditionDate(values.expedition.preferredExpeditionDate);
    }, [values.expedition.preferredExpeditionDate])

    useEffect(() => {

        if (valueCustomerDeliveryAddressId === undefined) return;

        const selectedAddress =
            deliveryAddressess?.find(address => address.id === valueCustomerDeliveryAddressId)

        if (selectedAddress) {
            setFormValues({
                ...values,
                expedition: {
                    ...values.expedition,
                    contactPerson: {
                        email: selectedAddress.contactPerson?.email || "",
                        firstName: selectedAddress.contactPerson?.firstName || "",
                        lastName: selectedAddress.contactPerson?.lastName || "",
                        telephone: selectedAddress.contactPerson?.telephone
                    },
                    deliveryAddress: {
                        attention: "",
                        city: selectedAddress.city || "",
                        country: selectedAddress.country || "",
                        postalCode: selectedAddress.postalCode || "",
                        houseNumber: selectedAddress.houseNumber || "",
                        houseNumberAddition: selectedAddress.houseNumberAddition || "",
                        street: selectedAddress.street || "",
                        extraAddressLine: "",
                        region: "",
                    }
                }
            });
        } else {
            setFormValues({
                ...values,
                expedition: {
                    ...values.expedition,
                    contactPerson: {
                        email: "",
                        firstName: "",
                        lastName: "",
                        telephone: ""
                    },
                    deliveryAddress: {
                        attention: "",
                        city: "",
                        country: "",
                        extraAddressLine: "",
                        houseNumber: "",
                        houseNumberAddition: "",
                        postalCode: "",
                        region: "",
                        street: "",
                    }
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueCustomerDeliveryAddressId]);

    return (
        <div className='w-100'>
            <div className='mb-5'>
                <h2 className='fw-bolder text-dark'>Levering</h2>
                <div className='text-gray-400 fw-bold fs-6'>
                    Uw referentie en levering.
                </div>
            </div>
            <div className='row mb-4'>
                <div className='col-4 col-lg-3'>
                    <label className='form-label required'>Referentie / Uw ordernummer</label>
                </div>
                <div className='col'>
                    <TextField
                        name='expedition.reference.reference'
                        autoFocus={true}
                        placeholder={'Uw referentie...'}
                        className='form-control form-control-lg'
                    />
                </div>
            </div>
            <div className='row mb-4'>
                <div className='col-4 col-lg-3'>
                    <label className='form-label'>Leverdatum</label>
                </div>
                <div className='col'>
                    <div className="form-check">
                        <Field type="radio"
                               name="expedition.deliveryDateChoice"
                               value="asap"
                               className="form-check-input mb-3 mb-lg-0"
                               id="rbDeliveryConditionAsap"
                               style={{marginTop: '0.7rem'}}
                        />

                        <label className="form-check-label form-control border-0 mb-3 mb-lg-0'"
                               htmlFor="rbDeliveryConditionAsap">
                            Zo spoedig mogelijk
                        </label>
                    </div>

                    <div className="form-check">
                        <Field type="radio"
                               name="expedition.deliveryDateChoice"
                               value="preferredExpeditionDate"
                               className="form-check-input mb-3 mb-lg-0"
                               id="rbDeliveryConditionDate"
                               style={{marginTop: '0.7rem'}}
                               onChange={(e: any) => {
                                   handleChange(e);
                                   setPreferredExpeditionDate(expeditionDate);
                               }}
                        />

                        <label className="form-check-label form-control border-0 mb-3 mb-lg-0'"
                               htmlFor="rbDeliveryConditionDate">
                            Gewenste leverdatum
                        </label>
                    </div>

                    {value2 === 'preferredExpeditionDate' &&
                        <div className='d-flex flex-column mb-7 fv-row form-check'>
                            <div className='form-control '>
                                <CalendarField value={expeditionDate} minDate={minExpeditionDate}
                                               onChange={setPreferredExpeditionDate}/>

                                <div className='text-white-400 fw-bold fs-6 m-5'>
                                    {/*eslint-disable-next-line react-hooks/rules-of-hooks*/}
                                    Gekozen datum: {formatDate(expeditionDate)}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>

            <div className='row mb-4'>
                <div className='col-4 col-lg-3'>
                    <label className='form-label'>Leveringscondities</label>
                </div>
                <div className='col'>
                    <CustomerDeliveryConditionsPicker fieldName='expedition.deliveryCondition'/>

                </div>
            </div>
            {value1 === DeliveryConditions.Bezorging &&
                <>
                    <div className='row mb-4'>
                        <div className='col-4 col-lg-3'>
                            <label className='form-label'>Afleveradres</label>
                        </div>
                        <div className='col'>
                            <CustomerAddressPicker fieldName='orderDeliveryAddressPickerId'
                                                   addresses={deliveryAddressess}/>
                        </div>
                    </div>


                    <div className={'row'}>
                        <div className={'col'}>
                            <OrderAddress addressfieldName='expedition.deliveryAddress'
                                          contactPersonFieldName='expedition.contactPerson'
                                          contactPersonTitle='Contactpersoon voor afleveradres'/>
                        </div>
                    </div>
                </>
            }


        </div>
    )
}

export {ExpeditionDetails}

import {useQuery, useQueryClient} from '@tanstack/react-query';
import {toast} from 'react-hot-toast';
import {useEkosietMutation} from "../../hooks/useEkosietMutation";
import {NotFound, ValidationFailed} from "../../../modules/api-client/Responses";
import ApiClient from "../../../modules/api-client/ApiClient";
import {AxiosError} from "axios";
import {isNotEmpty} from "../../../../_metronic/helpers";
import {
	CreateImportStopProfileMappingCommand,
	ImportStopProfileMappingRepresentation
} from "../../../modules/api-client/generated";

export const useGetImportStopProfileMappings = (enabled: boolean = true) => {
	const response = useQuery<Array<ImportStopProfileMappingRepresentation>, AxiosError, Array<ImportStopProfileMappingRepresentation>>(
		['aclImportMappingsStopProfilesGet'],
		() => {
			return ApiClient.Acl.ImportMapping.aclImportMappingStopprofilesGet().then((res) => res.data);
		},
		{
			useErrorBoundary: true,
			enabled: enabled,
		}
	);

	if (response.error) {
		var error = response.error;
		toast.error(error?.message);
	}

	return response;
};


export const useGetImportStopProfileMapping = (id: string | undefined, enabled: boolean = true) => {
	const response = useQuery<ImportStopProfileMappingRepresentation, AxiosError, ImportStopProfileMappingRepresentation>(
		['aclImportStopProfileIdGet', id],
		() => {
			return ApiClient.Acl.ImportMapping.aclImportMappingStopprofileImportStopProfileMappingIdGet(id!).then((res) => res.data);
		},
		{
			useErrorBoundary: true,
			enabled: enabled && isNotEmpty(id),
		}
	);

	if (response.error) {
		var error = response.error;
		toast.error(error?.message);
	}

	return response;
};


export const usePostStopProfileMapping = () => {
	const queryClient = useQueryClient();

	return useEkosietMutation<string, ValidationFailed, CreateImportStopProfileMappingCommand>(
		(command) => {
			return ApiClient.Acl.ImportMapping.aclImportMappingStopprofilePost(undefined, command).then((response) => response.data);
		},
		{
			onSuccess: (data, variables) => {
				queryClient.invalidateQueries(['aclImportMappingsStopProfilesGet']);
				queryClient.invalidateQueries(['aclImportStopProfileIdGet']);
				toast.success(`Koppeling succesvol toegevoegd of aangepast`);

			},
			onError: () => {
            	toast.error("Koppeling mislukt!");
			},
		}
	);
};

export const useDeleteStopProfileMapping = () => {
	const queryClient = useQueryClient();
	return useEkosietMutation<void, NotFound, string>(
		(importProfileMappingId) => {
			return ApiClient.Acl.ImportMapping.aclImportMappingStopprofileImportStopProfileMappingIdDelete(importProfileMappingId).then((response: any) => response.data);
		},
		{
			onSuccess: (data, variables) => {
				queryClient.invalidateQueries(['aclImportMappingsStopProfilesGet']);
				queryClient.invalidateQueries(['aclImportStopProfileIdGet']);
				toast.success(`Koppeling successvol verwijderd`);
			},
			onError: () => {
				toast.error('Koppeling verwijderen mislukt');
			},
		}
	);
};

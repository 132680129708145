import React from "react";
import {API_GATEWAY_BASE_URL} from "../../constants";

export interface RabbetSvgProps {
    profile?: string | null;
    profileSvg?: string | null;
    svgWidth?: string;
    svgHeight?: string;
    cacheBuster?: number | string | null;
}

const RabbetSvg: React.FC<RabbetSvgProps> = (props) => {

        if (props.profileSvg) {
            return <div className="preview-svg" dangerouslySetInnerHTML={{__html: props.profileSvg}}/>

        } else if (props.profile) {
            let imgSrc = props.profile ? `${API_GATEWAY_BASE_URL}/api/render/preview/rabbet?geometry=${encodeURIComponent(props.profile)}` : null;

            if (imgSrc && props.cacheBuster) {
                imgSrc += `&cacheBuster=${props.cacheBuster}`;
            }

            return <>{imgSrc && <img src={imgSrc} style={{
                width: "100%",
                height: "100%",
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain"
            }} alt=""/>}</>;
        }

        return <></>;

    }
;

export default RabbetSvg;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { EkoCard, EkoCardBody, KTSVG } from "../../../../../_metronic/helpers";
import { Link, useSearchParams } from "react-router-dom";
import {
    ProductionItemRepresentation,
    ProductionItemRepresentationFacetedSearchResultList,
    PutProductionItemStatusRepresentation,
} from '../../../../modules/api-client/generated';
import { useQuery } from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import Loading from "../../../../shared/components/Loading";
import { EkoTable } from "../../../../shared/components/table/EkoTable";
import { TableHeader } from "../../../../shared/components/table/TableHeader";
import { formatDate } from "../../../../shared/components/date";
import { PmRoutePath } from "../PmRoutes";
import BarcodeReader from 'react-barcode-reader'
import { toast } from 'react-hot-toast';
import { usePutProductionItemFinished } from "../hooks/use-put-production-item-finished";

type Props = {
    scope?: string[]
}

const ProductionItemAfmelden: React.FC<Props> = ({ scope }) => {
    const finishMutation = usePutProductionItemFinished()    
    const [searchParams, setSearchParams] = useSearchParams();
    const [filters, setFilters] = useState<string[]>(() => {
        return searchParams.getAll('f');
    });
    const [list, setList] = useState<ProductionItemRepresentationFacetedSearchResultList | null>(null);

    const { isInitialLoading, refetch, isError } = useQuery<ProductionItemRepresentationFacetedSearchResultList>(
        ['productionItemsList', filters],
        () => ApiClient.Pm.ProductionItem.pmProductionItemGet(scope, filters).then((res) => res.data),
        {
            refetchInterval: 5000,
            onSuccess: (data) => {
                setList(data)
            },
        },
    );

    const facets = useMemo(() => Object
        .values(list?.facets ?? {})
        .sort((a, b) => a.sortOrder - b.sortOrder),
        [list]);

    const handleSelection = (selected: boolean, profile: ProductionItemRepresentation) => {
        setSelection((selection) => {
            let newSelection = new Map(selection);
            if (selected) {
                newSelection.set(profile.id, profile);
            } else {
                newSelection.delete(profile.id);
            }

            return newSelection;
        });
    };
    const [selection, setSelection] = useState<Map<string, ProductionItemRepresentation>>(new Map<string, ProductionItemRepresentation>());

    const [selectPage, setSelectPage] = useState<boolean>(true);

    const selectAll = useCallback(() => {
        setSelection(() => {
            let newSelection = new Map();
            list?.hits.forEach((profile) => {
                if (!newSelection.has(profile.id)) {
                    newSelection.set(profile.id, profile);
                }
            });
            return newSelection;
        });
    }, [list, setSelection]);

    const allSelected = useMemo(() => list?.hits.filter((x) => !selection.has(x.id)).length === 0, [selection, list]);
    const selectAllRef = useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;
    const selectionSize: number = allSelected ? !selectPage ? list?.totalHits! : selection.size : selection.size;

    const selectNone = useCallback(() => {
        setSelection((selection) => {
            if (selection.size > 0) {
                return new Map<string, ProductionItemRepresentation>();
            }
            return selection;
        });
    }, [setSelection]);

    const toggleSelect = useCallback(() => (selectAllRef?.current?.checked ? selectAll() : selectNone()), [selectAllRef, selectAll, selectNone]);

    useEffect(() => {
        if (!selectAllRef?.current) return;

        const current = selectAllRef.current;

        current.addEventListener('change', toggleSelect);

        return () => {
            current.removeEventListener('change', toggleSelect);
        };
    }, [selectAllRef, toggleSelect]);

    useEffect(() => {
        if (!selectAllRef?.current) return;

        selectAllRef.current.indeterminate = !allSelected && selection.size > 0;
    }, [selection, allSelected, selectAllRef]);


    const onResetAllFilters = useCallback(async () => {
        setFilters([]);
        setSearchParams({});
        refetch().then();
        return false;
    }, [refetch, setSearchParams]);

    const onResetFilter = useCallback(
        async (tag: string) => {
            setFilters((filters) => {
                let newFilters = filters.filter((x) => !x.startsWith(`${tag}:`));
                const f: {} = newFilters.length === 0 ? {} : { f: newFilters };
                setSearchParams(f);
                return newFilters;
            });

            refetch().then();
        },
        [setFilters, refetch, setSearchParams],
    );

    const onSelectAllFilter = useCallback(
        async (tag: string) => {
            setFilters((newFilters) => {
                Object.values(list?.facets ?? [])
                    .filter((x) => x.value === tag)
                    .forEach((facet) => {
                        facet.labels.forEach((v) => newFilters.push(`${tag}:${v.value}`));
                    });
                const f: {} = newFilters.length === 0 ? {} : { f: newFilters };
                setSearchParams(f);
                return newFilters;
            });

            refetch().then();
        },
        [setFilters, refetch, setSearchParams],
    );

    const onFilterChange = useCallback(
        async (tag: string, bucketShortId: string, checked: boolean) => {
            setFilters((filters) => {
                const name = `${tag}:${bucketShortId}`;

                let list = filters;
                if (checked && filters.indexOf(name) === -1) {
                    list = [...filters, name];
                } else if (!checked && filters.indexOf(name) !== -1) {
                    list = filters.filter((x) => x !== name);
                }

                const f: {} = list.length === 0 ? {} : { f: list };
                setSearchParams(f);

                return list;
            });

            refetch().then();
        },
        [refetch, setSearchParams],
    );

    const toggleSelection = (value: boolean) => {
        return new Promise<void>(() => {
            const command: PutProductionItemStatusRepresentation = {
                productionItemIds: Array.from(selection, ([k, v]) => v.id),
            }
            finishMutation.mutate(command, {});
            refetch().then();
        }).finally();


    }

    const toggle = (id: string) => {
        return new Promise<void>(() => {
            const command: PutProductionItemStatusRepresentation = {
                productionItemIds: [id!],
            }
            finishMutation.mutate(command, {});
            refetch().then();

        }).finally();
    }

    const handleScan = async (code: string) => {

        var reg = /^\d+$/;

        if (!reg.test(code)) {

            toast.error(
                `Barcode bevat ongeldige karakters`,
                {
                    duration: 3000
                }
            );
            return;
        }        
    }

    const handleScanError = (err: string) => {
        toast.success(
            `Scan error '${err}'`,
            {
                duration: 3000
            }
        );
    }

    if (isInitialLoading && !list) {
        return <Loading />;
    }

    if (isError) {
        return <>ERROR!</>;
    }

    return (
        <>
            <BarcodeReader
                onError={handleScanError}
                onScan={handleScan}
            />

            <div className={'row'}>
                <div className={'col-12 col-lg-3'}>
                    <EkoCard>
                        <EkoCardBody>
                            <div className={'d-flex justify-content-between align-items-start mb-3'}>
                                <h3>Filters</h3>
                                {filters.length > 0 && (
                                    <button onClick={onResetAllFilters}
                                        className='btn btn-link btn-sm p-0 link-primary'>
                                        Reset alle filters
                                    </button>
                                )}
                            </div>
                            <div className='row'>
                                {facets.length > 0 && facets.map((t, index) => (
                                    <div key={`tag-${index}`} className='col-12 mb-10 d-flex flex-column '>
                                        <div className={'d-flex justify-content-between align-items-start'}>
                                            <label className='form-label text-nowrap'>{t.name}</label>
                                            <div>
                                                <button onClick={() => onSelectAllFilter(t.value)}
                                                    className='btn btn-link btn-sm p-0 link-primary'>
                                                    <KTSVG path={'/media/icons/duotune/general/gen043.svg'}
                                                        className='' />
                                                </button>
                                                <button onClick={() => onResetFilter(t.value)}
                                                    className='btn btn-link btn-sm p-0 link-primary'>
                                                    <KTSVG path={'/media/icons/duotune/general/gen040.svg'}
                                                        className='' />
                                                </button>
                                            </div>
                                        </div>
                                        <div className={'bg-light p-3 border-1 border-dark'}
                                            style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                            {t.labels.map((b) => (
                                                <div key={b.value}>
                                                    <label
                                                        className='w-100 h-100 cursor-pointer text-nowrap d-flex justify-content-between align-items-center'>
                                                        <span className='d-flex align-items-center'>
                                                            <input
                                                                className='form-check-input m-0 my-1'
                                                                type='checkbox'
                                                                checked={filters.indexOf(`${t.value}:${b.value}`) !== -1}
                                                                onChange={(e) => onFilterChange(t.value, b.value, e.target.checked)}
                                                                value={b.value}
                                                                disabled={b.count === 0}
                                                            />
                                                            <span
                                                                className={`ms-2 ${b.count === 0 ? 'text-muted' : ''}`}>{b.name}</span>
                                                        </span>
                                                        <small className='ms-2 text-muted'>({b.count})</small>
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </EkoCardBody>
                    </EkoCard>
                </div>
                <div className={'col-12 col-lg-9'}>
                    <EkoCard>
                        <EkoCardBody>
                            <div className={'d-flex align-items-center text-nowrap'}>
                                {list &&
                                    <div className='text-muted me-3'>{list.totalHits} dorpels</div>}
                                <div className={'d-flex align-items-center justify-content-between w-100'}>
                                    {selection && (
                                        <div className='d-flex align-items-center  '>
                                            {selection.size > 0 && (
                                                <span onClick={() => setSelection(new Map())}>
                                                    <KTSVG path={'/media/icons/duotune/general/gen040.svg'}
                                                        className='svg-icon-2' />
                                                </span>
                                            )}
                                            <span
                                                className={'ms-1 me-2 text-muted'}> {selectionSize} dorpels geselecteerd</span>
                                            <div>
                                                <button
                                                    type='button'
                                                    className='btn btn-light-primary btn-sm mx-1'
                                                    disabled={selectionSize === 0}
                                                    onClick={() => {
                                                        toggleSelection(true);
                                                        selectNone();
                                                    }
                                                    }
                                                >
                                                    Gereed melden
                                                </button>
                                            </div>                                        
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className={'d-flex justify-content-start align-items-center'}>
                                <label
                                    className='h-100 d-flex justify-content-start align-items-center cursor-pointer'>
                                    <input
                                        ref={selectAllRef}
                                        className='form-check-input m-0'
                                        type='checkbox'
                                        id={`profile-select-all`}
                                        checked={allSelected}
                                        onChange={(e) => (e.target.checked ? selectAll() : selectNone())}
                                    />
                                </label>
                                {allSelected &&
                                    <span className={'ms-4'}>
                                        <a href={'#'} className={` ${selectPage ? "fw-bold" : " text-dark"}`}
                                            onClick={() => setSelectPage(true)}>selecteer op pagina</a>
                                        &nbsp;|&nbsp;
                                        <a href={'#'} className={` ${!selectPage ? "fw-bold" : " text-dark"}`}
                                            onClick={() => setSelectPage(false)}>selecteer alle</a>
                                    </span>
                                }
                            </div>
                            <EkoTable>
                                <TableHeader>
                                    <th className='p-0 fit-content' style={{ height: '1px' }}>
                                    </th>
                                    <th>Dorpel</th>
                                    <th>Datum</th>
                                    <th>Order</th>
                                    <th>Status</th>
                                </TableHeader>
                                <tbody>
                                    {(!list || list.hits.length === 0) && (
                                        <tr>
                                            <td colSpan={5} className='text-center'>
                                                Geen gegevens
                                            </td>
                                        </tr>
                                    )}

                                    {list &&
                                        list.hits.map((pi) => (
                                            <tr key={pi.id}>
                                                <td className='p-0 fit-content' style={{ height: '1px' }}>
                                                    <label
                                                        className='w-100 h-100 d-flex justify-content-center align-items-center cursor-pointer'>
                                                        <input
                                                            className='form-check-input m-0'
                                                            type='checkbox'
                                                            id={`profile-${pi.id}`}
                                                            value={pi.id}
                                                            checked={selection.has(pi.id)}
                                                            onChange={(e) => handleSelection(e.target.checked, pi)}
                                                        />
                                                    </label>
                                                </td>
                                                <td>
                                                    <div>
                                                        <Link to={PmRoutePath.link(PmRoutePath.productionItemLink(pi.id!))}
                                                            className='text-hover-primary fs-6'>
                                                            {pi.title}
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <Link
                                                            to={PmRoutePath.link(PmRoutePath.productionItemGroupLink(pi.code))}
                                                            className='text-hover-primary fs-6'>
                                                            {pi.code}
                                                            {pi.sequence}
                                                        </Link>
                                                    </div>
                                                </td>
                                                <td>
                                                    {formatDate(pi.plannedProductionDate)}
                                                </td>
                                                <td>
                                                    <div>
                                                        <span className={''}>{pi.order?.code}</span>
                                                    </div>
                                                    <span>{pi.customer?.name}</span>
                                                </td>
                                                <td>
                                                    <div className={'d-flex flex-column text-nowrap'}>
                                                        <div>

                                                            <button
                                                                type='button'
                                                                className='btn btn-light-primary btn-sm mx-1'
                                                                onClick={() => {
                                                                    toggle(pi.id);
                                                                    selectNone();
                                                                }
                                                                }
                                                            >
                                                                Gereed melden
                                                            </button>

                                                        </div>
                                                    </div>

                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </EkoTable>
                        </EkoCardBody>
                    </EkoCard>
                </div>
            </div>
        </>
    )
}

export { ProductionItemAfmelden }

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react';
import {Link, Outlet, useLocation, useParams} from "react-router-dom";
import {useEkosietAuth0} from "../../../../modules/auth0/core/useEkosietAuth0";
import {ImportRoutePath} from "../../routes/ImportRoutes";
import {PageTitle, useLayout} from "../../../../../_metronic/layout/core";
import clsx from "clsx";
import {checkIsActive, KTSVG, WithChildren} from "../../../../../_metronic/helpers";


export const ImportMappingPage: React.FC = () => {

    const {isKlant} = useEkosietAuth0();

    return (
        <>
            <PageTitle>Import koppelingen beheren</PageTitle>
            <div className={'row'}>
                <div className={'col-12 col-lg-2'}>
                    <div className='app-sidebar-menu overflow-hidden flex-column-fluid'>
                        <div
                            className='app-sidebar-wrapper hover-scroll-overlay-y '
                        >
                            <div
                                className='menu menu-column menu-rounded menu-sub-indention'
                                id='#kt_app_sidebar_menu'
                                data-kt-menu='true'
                                data-kt-menu-expand='false'
                            >

                                {isKlant &&
                                    <>
                                        <div className='menu-item'>
                                            <div className='menu-content pt-8 pb-2'>
                                                <span
                                                    className='menu-section text-muted text-uppercase fs-8 ls-1'>Klant
                                                    specifieke koppelingen</span>
                                            </div>
                                        </div>
                                        <MenuItem to={`${ImportRoutePath.importProfileMapping}`}
                                            title={'Vakprofielen'}/>
                                        <MenuItem to={`${ImportRoutePath.importSluitpotMapping}`}
                                                  title={'Sluitpotten'}/>
                                    </>
                                }
                                <div className='menu-item'>
                                    <div className='menu-content pt-8 pb-2'>
                                        <span
                                            className='menu-section text-muted text-uppercase fs-8 ls-1'>Globale
                                            koppelingen</span>
                                    </div>
                                </div>
                                <MenuItem to={`${ImportRoutePath.importSillTypeMapping}`}
                                          title={'Dorpeltypes'}/>
                                <MenuItem to={`${ImportRoutePath.importCompartmentLayoutMapping}`}
                                          title={'Vakindelingen'}/>
                                <MenuItem to={`${ImportRoutePath.importStopProfileMapping}`}
                                          title={'Aanslagprofielen'}/>
                                <MenuItem to={`${ImportRoutePath.importGlazingprofileMapping}`}
                                          title={'Beglazingsprofielen'}/>
                                <MenuItem to={`${ImportRoutePath.importGlazingbarMapping}`}
                                          title={'Glaslatten'}/>
                                <MenuItem to={`${ImportRoutePath.importCornerpiecesMapping}`}
                                          title={'Hoekstukken'}/>

                            </div>
                        </div>
                    </div>
                </div>
                <div className={'col-12 col-lg-10'}>
                    <Outlet/>
                </div>
            </div>
        </>
    );
};

type Props = {
    to: string
    title: string
}

const MenuItem: FC<Props & WithChildren> = ({
                                                          children,
                                                          to,
                                                          title,
                                                      }) => {
    const {pathname} = useLocation()
    const isActive = checkIsActive(pathname, to)

    return (
        <div className='menu-item'>
            <Link className={clsx('menu-link without-sub py-1', {active: isActive})} to={to}>
                <span className='menu-title'>{title}</span>
            </Link>
            {children}
        </div>
    )
}
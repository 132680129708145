import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast"
import ApiClient from "../../../../../modules/api-client/ApiClient";
import {
    PostOrderRemarkRepresentation,
    OrderRemarkRepresentation,
} from "../../../../../modules/api-client/generated";
import { useEkosietMutation } from "../../../../../shared/hooks/useEkosietMutation";
import { NotFound, ValidationFailed } from "../../../../../modules/api-client/Responses";

export const usePostRecreateProductionItems = (orderId: string) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<void, NotFound | ValidationFailed, void>
        (() => {
            return ApiClient.Pm.ProductionItem.recreateProductionItems(orderId, undefined).then(response => response.data);
        },
            {
                onSuccess: (order) => {
                    queryClient.invalidateQueries(['smOrderIdGet', orderId]).then();
                    queryClient.invalidateQueries(['OrderActionLog', orderId]).then();

                    toast.success("Productie items succesvol ververst.");

                },
                onError: () => {
                    toast.error("Productie items vernieuwen mislukt!");
                }
            }
        );
}
    ;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactElement } from 'react'
import {OrderRemarkRepresentation, OrderRemarkType} from "../../modules/api-client/generated";
import {AddRemarkForm} from "../../backoffice/modules/sm/orders/edit-order/components/forms/AddRemarkForm";
import {EkoCard} from "../../shared/components/card/EkoCard";
import {EkoCardBody} from "../../shared/components/card/EkoCardBody";
import {Remark} from "../../backoffice/modules/sm/orders/edit-order/components/Remark";

type Props = {
    remarks: Array<OrderRemarkRepresentation> | null | undefined
    orderId?: string
    setModalComponent: React.Dispatch<React.SetStateAction<ReactElement | undefined | null>>,
    setModalTitle: React.Dispatch<React.SetStateAction<string | undefined | null>>,
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>,
}
const OrderRemarks: React.FC<Props> = ({ remarks, orderId, setShowEditModal, setModalTitle, setModalComponent }) => {
    if (!remarks || !orderId) return (<></>)

    const addRemark = () => {
        setModalComponent(<AddRemarkForm orderId={orderId} handleClose={() => setShowEditModal(false)} />);
        setModalTitle('Opmerking toevoegen aan deze order');
        setShowEditModal(true);
    }

    const remarkContent: any[] = [];
    remarks?.filter((remark)=>remark.type !== OrderRemarkType.Internal).forEach((remark, key) => {
        remarkContent.push(<Remark
            key={key}
            setModalTitle={setModalTitle}
            setModalComponent={setModalComponent}
            setShowEditModal={setShowEditModal}
            remark={remark}
        />)
    })
    return (
        <>
            <EkoCard className={''}  flush={true}>
                <EkoCardBody>
                    <div className={'fs-6 text-muted'}>Opmerkingen</div>
                    {remarkContent}
                    <button type="button" className="btn btn-sm btn-light-info" onClick={addRemark}>
                        <i className="fas fa-plus pe-0"></i> Toevoegen
                    </button>
                </EkoCardBody>
            </EkoCard>
        </>
    )
}

export {OrderRemarks}

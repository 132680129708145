import {FC} from 'react'

type Props = {
  blocked?: boolean
}

const UserBlockedStatusCell: FC<Props> = ({blocked}) => (
  <>
    {' '}
    {blocked === false || blocked === null || blocked === undefined ? (
      <div className='badge badge-light-success fw-bolder'>Actief</div>
    ) : (
      <div className='badge badge-light-danger fw-bolder'>Geblokkeerd</div>
    )}
  </>
)

export {UserBlockedStatusCell}

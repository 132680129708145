/* eslint-disable jsx-a11y/anchor-is-valid */
import {EkosietSignInMethod} from '../../modules/auth0/components/EkosietSignInMethod'

export function CustomerMyAccount() {  
  return (
    <>    
      <EkosietSignInMethod isEditModal={false} />
    </>
  )
}

import {useQuery, useQueryClient} from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import {NotFound, ValidationFailed} from "../../../../modules/api-client/Responses";
import {useEkosietMutation} from "../../../../shared/hooks/useEkosietMutation";
import {
    PriceList, CreatePriceListCommand, UpdatePriceListRequest
} from "../../../../modules/api-client/generated";
import {FormValues} from "./PriceListUpsert";


export const usePriceListUpsertMutation = (id?: string | undefined) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<PriceList, NotFound | ValidationFailed, FormValues>((values) => {
            const command: CreatePriceListCommand | UpdatePriceListRequest = {
                code: values.code,
                name: values.name,
                parentPriceListId: values.parentPriceListId
            };

            if (id) {
                return ApiClient.Sm.PriceList.smPriceListsIdPut(id, undefined, command).then(response => response.data);
            }
            return ApiClient.Sm.PriceList.smPriceListsPost(undefined, command).then(response => response.data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['pimPriceListIdGet', id]).then();
                queryClient.invalidateQueries(['pimPriceListGet']).then();
            }
        }
    );
};

export const usePriceListQuery = (id: string | undefined) => {
    return useQuery(['pimPriceListIdGet', id],
        () => {
            return ApiClient.Sm.PriceList.smPriceListsIdGet(id!).then(res => res.data);
        }, {
            useErrorBoundary: true,
            enabled: !!id,
        });
}

export const usePriceListDeleteMutation = (id: string) => {
    const queryClient = useQueryClient()
    return useEkosietMutation<void, NotFound | ValidationFailed, string>((id) => {
            return ApiClient.Sm.PriceList.smPriceListsIdDelete(id, undefined).then(response => response.data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['pimPriceListIdGet', id]).then();
                queryClient.invalidateQueries(['pimPriceListGet']).then();
            }
        }
    );
};




import clsx from 'clsx';
import {FC, useState} from 'react'
import {useFormikContext, getIn} from "formik";
import {build, FilterParameter} from "../../../../shared/context/FilterContext";
import AsyncSelect from "react-select/async";
import ApiClient from "../../../../modules/api-client/ApiClient";
import {SelectOption} from "../../../../shared/components/CustomSelect";

type Props = {
    fieldName: string;
    value?: string;
    onChange?: (value: any) => void;
}

export const OrderPicker: FC<Props> = (props: Props) => {
    const formik = useFormikContext();
    const [options, setOptions] = useState<SelectOption[]>([]);

    let orderListSelectedValue =
        options.find((option: any) => option.value === props.value) || null;

    const promiseOptions = (inputValue: string, callback: (options: SelectOption[]) => void) => {
        const filterProperty = 'Code'
        const expression = {[filterProperty]: {contains: inputValue}}
        const filter: FilterParameter = {
            filterExpression: expression,
            filterName: props.fieldName,
            values: [inputValue]
        }
        const filterQ = build([filter]);
        const getData = async () => {
            const arr: SelectOption[] = [];
            await ApiClient.Sm.Order.smOrderListOdataGet(10, undefined, 'Code', filterQ, false).then((res: any) => {
                let result = res.data.value;
                result.map((order: any) => {
                    return arr.push({value: order.id, label: order.code});
                });
                setOptions(arr)
                callback(arr);
            });
        };
        getData();
    }

    return (
        <>
            <AsyncSelect
                cacheOptions
                loadOptions={promiseOptions}
                defaultOptions
                className={clsx(
                    'w-250px',
                    {'is-invalid': getIn(formik.touched, props.fieldName) && getIn(formik.errors, props.fieldName)},
                    {'is-valid': getIn(formik.touched, props.fieldName) && !getIn(formik.errors, props.fieldName)}
                )}
                styles={{
                    // Fixes the overlapping problem of the component
                    menu: provided => ({...provided, zIndex: 9999})
                }}
                classNamePrefix="select"
                defaultValue={orderListSelectedValue}
                value={orderListSelectedValue}
                placeholder="Selecteer een order"
                isClearable={false}
                isSearchable={true}
                name={props.fieldName}
                onChange={(newValue) => {
                    if(newValue === null) {
                        formik.setFieldValue(props.fieldName, null)
                        return;
                    } else {
                        formik.setFieldValue(props.fieldName, newValue?.value)
                        formik.validateForm();
                        formik.submitForm();
                    }
                }}
            />
        </>
    )
}

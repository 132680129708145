import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {ErrorBoundary, FallbackProps} from "react-error-boundary";

import React from "react";
import axios from "axios";
import {BackofficeRoutePath} from "../../RoutePath";
import {MgtDashboard} from "./pages/MgtDashboard";
import {MgtSales} from "./pages/MgtSales";
import {MgtProduction} from "./pages/MgtProduction";
import {MgtExpedition} from "./pages/MgtExpedition";
import {MgtCrm} from "./pages/MgtCrm";
import {MgtCustomer} from "./pages/MgtCustomer";
import {MgtProductionBacklog} from "./pages/MgtProductionBacklog";
import {MgtSillStats} from "./pages/MgtSillStats";
import {MgtProductionDashboard} from "./pages/MgtProductionDashboard";
import {MgtProductionOperation} from "./pages/MgtProductionOperation";
import {EmployeeStatistics} from "./components/productionOperation/EmployeeStatistics";
import {OperationStatistics} from './components/productionOperation/OperationStatistics';
import {OperationEmployeeStatistics} from "./components/productionOperation/OperationEmployeeStatistics";


export const MgtRoutePath = {
    dashboard: 'dashboard',
    sales: 'sales',
    sillStats: 'sill-stats',

    production: 'productie',
    productionBacklog: 'backlog',

    poDashboard: 'operation-dashboard',
    poEmployee: 'e/:employeeId',
    poDate: 'd/:date',
    poOperation: 'o/:operationId',

    expedition: 'expeditie',

    crm: 'crm',
    customer: 'crm/customer/:id',

    link(path:string){
        return BackofficeRoutePath.mgt+path;
    },
    customerLink: (id: string) => {
        return MgtRoutePath.customer.replace(':id', id);
    },

    poDateLink(date: string) {
        return MgtRoutePath.poDate.replace(':date', date);
    },

    poEmployeeLink(employeeId: string) {
        return MgtRoutePath.poEmployee.replace(':employeeId', employeeId);
    },

    poOperationLink(operationId: string) {
        return MgtRoutePath.poOperation.replace(':operationId', operationId);
    }

};

const MgtRoutes = () => {
    return (
        <Routes>
            <Route element={<ErrorBoundary FallbackComponent={Fallback}><Outlet/></ErrorBoundary>}>
                <Route path={MgtRoutePath.dashboard} element={<MgtDashboard/>}/>
                <Route path={MgtRoutePath.sales} element={<MgtSales/>}/>
                <Route path={MgtRoutePath.production+'*'} element={<MgtProduction/>}>
                    <Route path={MgtRoutePath.productionBacklog} element={<MgtProductionBacklog/>}/>
                    <Route path={MgtRoutePath.poDashboard+'*'} element={<MgtProductionOperation/>}>
                        <Route path={MgtRoutePath.poDate} element={<OperationEmployeeStatistics/>}/>
                        <Route path={MgtRoutePath.poEmployee} element={<EmployeeStatistics/>}/>
                        <Route path={MgtRoutePath.poOperation} element={<OperationStatistics/>}/>
                        <Route index element={<OperationEmployeeStatistics/>}/>
                    </Route>
                    <Route index element={<MgtProductionDashboard/>}/>
                </Route>
                <Route path={MgtRoutePath.expedition} element={<MgtExpedition/>}/>
                <Route path={MgtRoutePath.customer} element={<MgtCustomer />} />
                <Route path={MgtRoutePath.crm} element={<MgtCrm/>}/>
                <Route path={MgtRoutePath.sillStats} element={<MgtSillStats/>}/>
            </Route>
            <Route index element={<Navigate to={MgtRoutePath.dashboard}/>}/>
        </Routes>
    )
}

const Fallback: React.FC<FallbackProps> = (props) => {

    if (axios.isAxiosError(props.error)) {
        if (props.error.response?.status === 404) {
            return <>Not found</>;
        }
        if (props.error.response?.status === 500) {
            return <>Server error</>;
        }
    }

    return <>Error</>;
};

export default MgtRoutes

import React from "react";
import {
    ProductionItemOperations,
    ProductionItemRepresentation,
    ProductionItemStatusCodes
} from "../../modules/api-client/generated";
import { useProductionItemGetId } from "../../backoffice/modules/pm/hooks/use-get-production-item";

export interface Props {
    status?: ProductionItemStatusCodes
}

export const ProductionItemStatus: React.FC<Props> = ({ status }) => {
    let label;
    let color;
    switch (status) {
        case ProductionItemStatusCodes.NotStarted: {
            label = 'Niet gestart';
            color = 'secondary';
            break;
        }
        case ProductionItemStatusCodes.Started: {
            label = 'Gestart';
            color = 'info';
            break;
        }
        case ProductionItemStatusCodes.Finished: {
            label = 'Gereed';
            color = 'success';
            break;
        }
        case ProductionItemStatusCodes.Cancelled: {
            label = 'Geannuleerd';
            color = 'light-danger';
            break;
        }
        case ProductionItemStatusCodes.Failed: {
            label = 'Gefaald';
            color = 'danger';
            break;
        }
        default: {
            label = 'trash'
        }
    }
    return (
        <span className={`p-1 bg-${color} text-inverse-${color}`}>{label}</span>
    )
}    

export interface PropsStationStatus {
    status?: boolean | null
    title: string
}

export const ProductionItemStationStatus: React.FC<PropsStationStatus> = ({ status, title }) => {
    let color;
    switch (status) {
        case true: {
            color = 'success';
            break;
        }
        case false: {
            color = 'grey';
            break;
        }
        default: {
            color = 'light-dark'
        }
    }
    return (
        <span
            className={`bullet bullet-dot h-10px w-10px  bg-${color}`} title={title}></span>
    )
}    

export interface PropsStationGroupStatus {
    pi?: ProductionItemRepresentation | undefined
    id?: string
}

export const ProductionItemStationStatusGroup: React.FC<PropsStationGroupStatus> = ({ pi, id }) => {
    var { data: pis } = useProductionItemGetId(id);

    if (!pis && id) {
        return <>Onbekend</>
    }

    if (!pis && pi) {
        pis = pi;
    }

    if (!pis) {
        return <>Onbekend</>

    }

    return (
        <div>
            <ProductionItemStatus status={pis.status} />
            <div>
                <ProductionItemStationStatus
                    title={ProductionItemOperations.NeutenGemaakt}
                    status={pis.stationStatus.neutenGemaakt} />
                <ProductionItemStationStatus
                    title={ProductionItemOperations.LattenGemaakt}
                    status={pis.stationStatus.lattenGemaakt} />
                <ProductionItemStationStatus
                    title={ProductionItemOperations.DorpelProfielGezaagd}
                    status={pis.stationStatus.dorpelProfielGezaagd} />
                <ProductionItemStationStatus
                    title={ProductionItemOperations.DorpelBewerkt}
                    status={pis.stationStatus.dorpelBewerkt} />

                <ProductionItemStationStatus
                    title={ProductionItemOperations.NeutenGemonteerd}
                    status={pis.stationStatus.neutenGemonteerd} />
                <ProductionItemStationStatus
                    title={ProductionItemOperations.SluitpottenGemonteerd}
                    status={pis.stationStatus.sluitpottenGemonteerd} />

                <ProductionItemStationStatus
                    title={ProductionItemOperations.KopisolatorenGemonteerd}
                    status={pis.stationStatus.kopisolatorenGemonteerd} />
                <ProductionItemStationStatus
                    title={ProductionItemOperations.ManchettesGemonteerd}
                    status={pis.stationStatus.manchettesGemonteerd} />

                <ProductionItemStationStatus
                    title={ProductionItemOperations.LattenGemonteerd}
                    status={pis.stationStatus.lattenGemonteerd} />

                <ProductionItemStationStatus
                    title={ProductionItemOperations.HefschuifVouwwand}
                    status={pis.stationStatus.hefschuifVouwwand} />

                <ProductionItemStationStatus
                    title={ProductionItemOperations.Custom}
                    status={pis.stationStatus.custom} />

                <ProductionItemStationStatus
                    title={ProductionItemOperations.Ingepakt}
                    status={pis.stationStatus.ingepakt} />


            </div>
        </div>
    )
}
    ;
import {FC} from 'react'
import clsx from 'clsx'
import {WithChildren} from "../../../../_metronic/helpers";

// Wrapper on html card:
// https://preview.keenthemes.com/metronic8/demo1/documentation/base/cards.html

type Props = {
    className?: string
}

const TableHeader: FC<Props & WithChildren> = (props) => {
    const {
        className,
        children,
    } = props
    return (
        <thead>
        <tr className={clsx(
            'fw-bold text-muted',
            className && className,
        )}>
            {children}
        </tr>
        </thead>
    )
}

export {TableHeader}

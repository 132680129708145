/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {ReactElement, useEffect, useRef, useState} from "react";
import {PageTitle} from "../../../../../_metronic/layout/core";
import {EkoCard, EkoCardBody, EkoCardHeader} from "../../../../shared/components/card";
import {EkoTable, TableHeader} from "../../../../shared/components/table";
import Euro from "../../../../shared/components/Euro";
import {DateTime} from "luxon";
import {useQuery} from "@tanstack/react-query";
import {
    DailyProductionAnalyticsRow,
    ProductionAnalysisMetrics,
    ProductionDashboardStatsRow
} from "../../../../modules/api-client/generated";
import ApiClient from "../../../../modules/api-client/ApiClient";
import Loading from "../../../../shared/components/Loading";
import {ProductionItemModal} from "../../pm/components/ProductionItemModal";
import {dateToString, formatDate, weekNumber} from "../../../../shared/components/date";
import CountUp from "react-countup";
import {getCSSVariableValue} from "../../../../../_metronic/assets/ts/_utils";

type Stations = {
    [key: string]: StationTotals
}
type StationTotals = {
    key: string
    title: string
    capacity: number
    workingHours: number
    totalCapacity: number
    totalWorkingHours: number
    totalPlanned: number
    backlog: number
    totalRealised: number
}

export function MgtProductionBacklog() {
    const [showEditModal, setShowEditModal] = useState<boolean>(false)
    const [modalTitle, setModalTitle] = useState<string | undefined | null>(null);
    const [modalComponent, setModalComponent] = useState<ReactElement | undefined | null>(null);

    const defaultFrom = DateTime.now().minus({days: 14}).toFormat("yyyy-MM-dd")
    const defaultTo = DateTime.now().plus({days: 1}).toFormat("yyyy-MM-dd")
    const [startDay, setFromDate] = useState<string>(defaultFrom)
    const [endDay, setToDate] = useState<string>(defaultTo)


    const onChangeFromDate = (date: string) => {
        const newDate = DateTime.fromFormat(date, "yyyy-MM-dd").toFormat("yyyy-MM-dd");
        setFromDate(newDate);
    }
    const onChangeToDate = (date: string) => {
        const newDate = DateTime.fromFormat(date, "yyyy-MM-dd").toFormat("yyyy-MM-dd");
        setToDate(newDate);
    }

    const setDateRange = (currentWeek: number, year: number = DateTime.now().year) => {
        const dt = DateTime.fromObject({
            weekYear: year,
            weekNumber: currentWeek
        });
        setFromDate(dt.startOf('week').toFormat("yyyy-MM-dd"));
        setToDate(dt.endOf('week').toFormat("yyyy-MM-dd"));
    }
    const setDay = (day: string) => {
        const dt = DateTime.fromFormat(day, "yyyy-MM-dd");
        setFromDate(dt.toFormat("yyyy-MM-dd"));
        setToDate(dt.plus({day: 1}).toFormat("yyyy-MM-dd"));
    }

    const resetRange = () => {
        setFromDate(defaultFrom);
        setToDate(defaultTo);
    }

    const initValues: StationTotals = {
        totalCapacity: 0,
        totalWorkingHours: 0,
        title: "",
        totalPlanned: 0,
        totalRealised: 0,
        backlog: 0,
        capacity: 0,
        workingHours: 8,
        key: ""
    }

    const stations: Stations = {
        dorpels: {...initValues, title: 'Dorpels', capacity: 23.125, workingHours: 8, key: "dorpels"},
        neutenMaken: {...initValues, title: 'Neuten maken', capacity: 45, workingHours: 7, key: "neutenMaken"},
        lattenMaken: {...initValues, title: 'Latten zagen', capacity: 40, workingHours: 4, key: "lattenMaken"},
        dorpelsZagen: {...initValues, title: 'Dorpel zagen', capacity: 60, workingHours: 6, key: "dorpelsZagen"},
        dorpelsBewerken: {
            ...initValues,
            title: 'Dorpel bewerken',
            capacity: 35,
            workingHours: 5,
            key: "dorpelsBewerken"
        },
        neutenMonteren: {...initValues, title: 'Neuten Montage', capacity: 40, workingHours: 8, key: "neutenMonteren"},
        sluitpottenPlaatsen: {
            ...initValues,
            title: 'Sluitpotten',
            capacity: 30,
            workingHours: 8,
            key: "sluitpottenPlaatsen"
        },
        lattenPlaatsen: {...initValues, title: 'Latten', capacity: 15, workingHours: 8, key: "lattenPlaatsen"},
        kopisolatorenPlaatsen: {
            ...initValues,
            title: 'Kopisolator',
            capacity: 40,
            workingHours: 8,
            key: "kopisolatorenPlaatsen"
        },
        manchettes: {...initValues, title: 'Manchettes', capacity: 30, workingHours: 8, key: "manchettes"},
        hefschuifVouwwand: {...initValues, title: 'Hefs/Vouw', capacity: 3, workingHours: 8, key: "hefschuifVouwwand"},
        inpakken: {...initValues, title: 'Inpakken', capacity: 20, workingHours: 8, key: "inpakken"},
        custom: {...initValues, title: 'Special', capacity: 1, workingHours: 8, key: "custom"},
    }

    const [stationData, setStationData] = useState<Stations>(stations);
    const {
        isInitialLoading,
        data,
    } = useQuery<DailyProductionAnalyticsRow[], TypeError, DailyProductionAnalyticsRow[]>(
        ['dailyProductionAnalytics', startDay, endDay],
        () => {
            return ApiClient.Pm.Reporting.dailyProductionAnalytics(startDay, endDay, undefined).then((res) => res.data);
        },
        {refetchInterval: 3000}
    );

    useEffect(() => {
        const newstationData = {...stationData};
        newstationData.dorpels.totalPlanned = 0;
        newstationData.dorpels.backlog = 0;
        newstationData.dorpels.totalRealised = 0;
        newstationData.dorpels.totalCapacity = 0;
        newstationData.dorpels.totalWorkingHours = 0;

        newstationData.neutenMaken.totalPlanned = 0;
        newstationData.neutenMaken.backlog = 0;
        newstationData.neutenMaken.totalRealised = 0;
        newstationData.neutenMaken.totalCapacity = 0;
        newstationData.neutenMaken.totalWorkingHours = 0;

        newstationData.lattenMaken.totalPlanned = 0;
        newstationData.lattenMaken.backlog = 0;
        newstationData.lattenMaken.totalRealised = 0;
        newstationData.lattenMaken.totalCapacity = 0;
        newstationData.lattenMaken.totalWorkingHours = 0;

        newstationData.dorpelsZagen.totalPlanned = 0;
        newstationData.dorpelsZagen.totalRealised = 0;
        newstationData.dorpelsZagen.backlog = 0;
        newstationData.dorpelsZagen.totalCapacity = 0;
        newstationData.dorpelsZagen.totalWorkingHours = 0;

        newstationData.dorpelsBewerken.totalPlanned = 0;
        newstationData.dorpelsBewerken.backlog = 0;
        newstationData.dorpelsBewerken.totalRealised = 0;
        newstationData.dorpelsBewerken.totalCapacity = 0;
        newstationData.dorpelsBewerken.totalWorkingHours = 0;

        newstationData.neutenMonteren.totalPlanned = 0;
        newstationData.neutenMonteren.backlog = 0;
        newstationData.neutenMonteren.totalRealised = 0;
        newstationData.neutenMonteren.totalCapacity = 0;
        newstationData.neutenMonteren.totalWorkingHours = 0;

        newstationData.sluitpottenPlaatsen.totalPlanned = 0;
        newstationData.sluitpottenPlaatsen.backlog = 0;
        newstationData.sluitpottenPlaatsen.totalRealised = 0;
        newstationData.sluitpottenPlaatsen.totalCapacity = 0;
        newstationData.sluitpottenPlaatsen.totalWorkingHours = 0;

        newstationData.lattenPlaatsen.totalPlanned = 0;
        newstationData.lattenPlaatsen.backlog = 0;
        newstationData.lattenPlaatsen.totalRealised = 0;
        newstationData.lattenPlaatsen.totalCapacity = 0;
        newstationData.lattenPlaatsen.totalWorkingHours = 0;

        newstationData.kopisolatorenPlaatsen.totalPlanned = 0;
        newstationData.kopisolatorenPlaatsen.backlog = 0;
        newstationData.kopisolatorenPlaatsen.totalRealised = 0;
        newstationData.kopisolatorenPlaatsen.totalCapacity = 0;
        newstationData.kopisolatorenPlaatsen.totalWorkingHours = 0;

        newstationData.manchettes.totalPlanned = 0;
        newstationData.manchettes.backlog = 0;
        newstationData.manchettes.totalRealised = 0;
        newstationData.manchettes.totalCapacity = 0;
        newstationData.manchettes.totalWorkingHours = 0;

        newstationData.hefschuifVouwwand.totalPlanned = 0;
        newstationData.hefschuifVouwwand.backlog = 0;
        newstationData.hefschuifVouwwand.totalRealised = 0;
        newstationData.hefschuifVouwwand.totalCapacity = 0;
        newstationData.hefschuifVouwwand.totalWorkingHours = 0;

        newstationData.inpakken.totalPlanned = 0;
        newstationData.inpakken.backlog = 0;
        newstationData.inpakken.totalRealised = 0;
        newstationData.inpakken.totalCapacity = 0;
        newstationData.inpakken.totalWorkingHours = 0;

        newstationData.custom.totalPlanned = 0;
        newstationData.custom.backlog = 0;
        newstationData.custom.totalRealised = 0;
        newstationData.custom.totalCapacity = 0;
        newstationData.custom.totalWorkingHours = 0;

        if (data) {
            data.forEach((d) => {

                newstationData.dorpels.totalPlanned += d.dorpels.planned;
                newstationData.dorpels.totalRealised += d.dorpels.realised;
                newstationData.dorpels.backlog = d.dorpels.backlog;
                newstationData.dorpels.totalCapacity += newstationData.dorpels.capacity * newstationData.dorpels.workingHours;
                newstationData.dorpels.totalWorkingHours += newstationData.dorpels.workingHours;

                newstationData.neutenMaken.totalPlanned += d.neutenMaken.planned;
                newstationData.neutenMaken.totalRealised += d.neutenMaken.realised;
                newstationData.neutenMaken.backlog = d.neutenMaken.backlog;
                newstationData.neutenMaken.totalCapacity += newstationData.neutenMaken.capacity * newstationData.neutenMaken.workingHours;
                newstationData.neutenMaken.totalWorkingHours += newstationData.neutenMaken.workingHours;

                newstationData.lattenMaken.totalPlanned += d.lattenMaken.planned;
                newstationData.lattenMaken.totalRealised += d.lattenMaken.realised;
                newstationData.lattenMaken.backlog = d.lattenMaken.backlog;
                newstationData.lattenMaken.totalCapacity += newstationData.lattenMaken.capacity * newstationData.lattenMaken.workingHours;
                newstationData.lattenMaken.totalWorkingHours += newstationData.lattenMaken.workingHours;

                newstationData.dorpelsZagen.totalPlanned += d.dorpelProfielZagen.planned;
                newstationData.dorpelsZagen.totalRealised += d.dorpelProfielZagen.realised;
                newstationData.dorpelsZagen.backlog = d.dorpelProfielZagen.backlog;
                newstationData.dorpelsZagen.totalCapacity += newstationData.dorpelsZagen.capacity * newstationData.dorpelsZagen.workingHours;
                newstationData.dorpelsZagen.totalWorkingHours += newstationData.dorpelsZagen.workingHours;

                newstationData.dorpelsBewerken.totalPlanned += d.dorpelBewerken.planned;
                newstationData.dorpelsBewerken.totalRealised += d.dorpelBewerken.realised;
                newstationData.dorpelsBewerken.backlog = d.dorpelBewerken.backlog;
                newstationData.dorpelsBewerken.totalCapacity += newstationData.dorpelsBewerken.capacity * newstationData.dorpelsBewerken.workingHours;
                newstationData.dorpelsBewerken.totalWorkingHours += newstationData.dorpelsBewerken.workingHours;

                newstationData.neutenMonteren.totalPlanned += d.neutenMonteren.planned;
                newstationData.neutenMonteren.totalRealised += d.neutenMonteren.realised;
                newstationData.neutenMonteren.backlog = d.neutenMonteren.backlog;
                newstationData.neutenMonteren.totalCapacity += newstationData.neutenMonteren.capacity * newstationData.neutenMonteren.workingHours;
                newstationData.neutenMonteren.totalWorkingHours += newstationData.neutenMonteren.workingHours;

                newstationData.sluitpottenPlaatsen.totalPlanned += d.sluitpottenMonteren.planned;
                newstationData.sluitpottenPlaatsen.totalRealised += d.sluitpottenMonteren.realised;
                newstationData.sluitpottenPlaatsen.backlog = d.sluitpottenMonteren.backlog;
                newstationData.sluitpottenPlaatsen.totalCapacity += newstationData.sluitpottenPlaatsen.capacity * newstationData.sluitpottenPlaatsen.workingHours;
                newstationData.sluitpottenPlaatsen.totalWorkingHours += newstationData.sluitpottenPlaatsen.workingHours;

                newstationData.lattenPlaatsen.totalPlanned += d.lattenMonteren.planned;
                newstationData.lattenPlaatsen.totalRealised += d.lattenMonteren.realised;
                newstationData.lattenPlaatsen.backlog = d.lattenMonteren.backlog;
                newstationData.lattenPlaatsen.totalCapacity += newstationData.lattenPlaatsen.capacity * newstationData.lattenPlaatsen.workingHours;
                newstationData.lattenPlaatsen.totalWorkingHours += newstationData.lattenPlaatsen.workingHours;

                newstationData.kopisolatorenPlaatsen.totalPlanned += d.kopisolatorenPlaatsen.planned;
                newstationData.kopisolatorenPlaatsen.totalRealised += d.kopisolatorenPlaatsen.realised;
                newstationData.kopisolatorenPlaatsen.backlog = d.kopisolatorenPlaatsen.backlog;
                newstationData.kopisolatorenPlaatsen.totalCapacity += newstationData.kopisolatorenPlaatsen.capacity * newstationData.kopisolatorenPlaatsen.workingHours;
                newstationData.kopisolatorenPlaatsen.totalWorkingHours += newstationData.kopisolatorenPlaatsen.workingHours;

                newstationData.manchettes.totalPlanned += d.manchettesPlaatsen.planned;
                newstationData.manchettes.totalRealised += d.manchettesPlaatsen.realised;
                newstationData.manchettes.backlog = d.manchettesPlaatsen.backlog;
                newstationData.manchettes.totalCapacity += newstationData.manchettes.capacity * newstationData.manchettes.workingHours;
                newstationData.manchettes.totalWorkingHours += newstationData.manchettes.workingHours;

                newstationData.hefschuifVouwwand.totalPlanned += d.hefschuifVouwwand.planned;
                newstationData.hefschuifVouwwand.totalRealised += d.hefschuifVouwwand.realised;
                newstationData.hefschuifVouwwand.backlog = d.hefschuifVouwwand.backlog;
                newstationData.hefschuifVouwwand.totalCapacity += newstationData.hefschuifVouwwand.capacity * newstationData.hefschuifVouwwand.workingHours;
                newstationData.hefschuifVouwwand.totalWorkingHours += newstationData.hefschuifVouwwand.workingHours;

                newstationData.inpakken.totalPlanned += d.inpakken.planned;
                newstationData.inpakken.totalRealised += d.inpakken.realised;
                newstationData.inpakken.backlog = d.inpakken.backlog;
                newstationData.inpakken.totalCapacity += newstationData.inpakken.capacity * newstationData.inpakken.workingHours;
                newstationData.inpakken.totalWorkingHours += newstationData.inpakken.workingHours;

                newstationData.custom.totalPlanned += d.specials.planned;
                newstationData.custom.totalRealised += d.specials.realised;
                newstationData.custom.backlog = d.specials.backlog;
                newstationData.custom.totalCapacity += newstationData.custom.capacity * newstationData.custom.workingHours;
                newstationData.custom.totalWorkingHours += newstationData.custom.workingHours;

            });
            setStationData({...stationData, ...newstationData});
        }
    }, [data, stationData]);

    if (!data) return <Loading/>

    let currentWeek = 0;
    if (isInitialLoading) return <Loading/>
    return (
        <>
            {/*<PageTitle toolbar={<>*/}
            {/*    <div className={'row'}>*/}
            {/*        <div className={'col'}>*/}
            {/*            <input type="date" id="start" className={'form-control'} name="startDay" value={startDay}*/}
            {/*                   onChange={(e) => onChangeFromDate(e.target.value)}/>*/}
            {/*        </div>*/}
            {/*        <div className={'col'}>*/}
            {/*            <input type="date" id="end" className={'form-control'} name="endDay" value={endDay}*/}
            {/*                   min={startDay}*/}
            {/*                   onChange={(e) => onChangeToDate(e.target.value)}/>*/}
            {/*        </div>*/}
            {/*        <div className={'col'}>*/}
            {/*            <button className={'btn btn-sm btn-light'} onClick={() => resetRange()}>Reset</button>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</>} breadcrumbs={[]}>*/}
            {/*    Backlog statistieken*/}
            {/*</PageTitle>*/}
            <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
                <div className='col mb-md-5 mb-xl-10'>


                    <ProductionItemModal show={showEditModal} handleClose={() => setShowEditModal(false)}
                                         title={modalTitle}
                                         size={'lg'}
                                         fullscreen={'false'}
                                         component={modalComponent}
                    />

                    <EkoTable classNameDivWrapper={'tableFixHead'} condensed={true}>
                        <colgroup>
                            <col width={'3%'}/>
                            <col width={'2%'}/>
                            <col width={'5%'}/>
                            <col width={'5%'}/>
                            <col width={'5%'}/>
                            <col width={'5%'}/>
                            <col width={'5%'}/>
                            <col width={'5%'}/>
                            <col width={'5%'}/>
                            <col width={'5%'}/>
                            <col width={'5%'}/>
                            <col width={'4%'}/>
                            <col width={'4%'}/>
                            <col width={'4%'}/>
                            <col width={'4%'}/>
                            <col width={'4%'}/>
                        </colgroup>
                        <TableHeader>
                            <th className={'bg-light'}>
                            </th>
                            <th className={'border-end border-primary border-bottom-0 border-1 bg-light'}>
                                <StationTotal
                                    station={stationData.dorpels}
                                              stations={stationData}
                                              showWorkingHours={false} showCapacity={true}
                                              setModalComponent={setModalComponent} setModalTitle={setModalTitle}
                                              setShowEditModal={setShowEditModal}
                                              setStationData={setStationData}/>

                            </th>
                            <th className={'bg-light'}>
                                <StationTotal station={stationData.lattenMaken}
                                              stations={stationData}
                                              setModalComponent={setModalComponent} setModalTitle={setModalTitle}
                                              setShowEditModal={setShowEditModal}
                                              setStationData={setStationData}/>

                            </th>
                            <th className={'bg-light'}>
                                <StationTotal station={stationData.dorpelsZagen}
                                              stations={stationData}
                                              setModalComponent={setModalComponent} setModalTitle={setModalTitle}
                                              setShowEditModal={setShowEditModal}
                                              setStationData={setStationData}/>

                            </th>

                            <th className={'bg-light'}>
                                <StationTotal station={stationData.neutenMaken}
                                              stations={stationData}
                                              setModalComponent={setModalComponent} setModalTitle={setModalTitle}
                                              setShowEditModal={setShowEditModal}
                                              setStationData={setStationData}/>
                            </th>
                            <th className={'bg-light'}>
                                <StationTotal station={stationData.dorpelsBewerken}
                                              stations={stationData}
                                              setModalComponent={setModalComponent} setModalTitle={setModalTitle}
                                              setShowEditModal={setShowEditModal}
                                              setStationData={setStationData}/>
                            </th>


                            <th className={'bg-light'}>
                                <StationTotal station={stationData.neutenMonteren}
                                              stations={stationData}
                                              setModalComponent={setModalComponent} setModalTitle={setModalTitle}
                                              setShowEditModal={setShowEditModal}
                                              setStationData={setStationData}/>

                            </th>

                            <th className={'bg-light'}>
                                <StationTotal station={stationData.sluitpottenPlaatsen}
                                              stations={stationData}
                                              setModalComponent={setModalComponent} setModalTitle={setModalTitle}
                                              setShowEditModal={setShowEditModal}
                                              setStationData={setStationData}/>

                            </th>

                            <th className={'bg-light'}>
                                <StationTotal station={stationData.kopisolatorenPlaatsen}
                                              stations={stationData}
                                              setModalComponent={setModalComponent} setModalTitle={setModalTitle}
                                              setShowEditModal={setShowEditModal}
                                              setStationData={setStationData}/>

                            </th>
                            <th className={'bg-light'}>
                                <StationTotal station={stationData.manchettes}
                                              stations={stationData}
                                              setModalComponent={setModalComponent} setModalTitle={setModalTitle}
                                              setShowEditModal={setShowEditModal}
                                              setStationData={setStationData}/>

                            </th>
                            <th className={'bg-light'}>
                                <StationTotal station={stationData.lattenPlaatsen}
                                              stations={stationData}
                                              setModalComponent={setModalComponent} setModalTitle={setModalTitle}
                                              setShowEditModal={setShowEditModal}
                                              setStationData={setStationData}/>

                            </th>
                            <th className={'bg-light'}>
                                <StationTotal station={stationData.inpakken}
                                              stations={stationData}
                                              setModalComponent={setModalComponent} setModalTitle={setModalTitle}
                                              setShowEditModal={setShowEditModal}
                                              setStationData={setStationData}/>
                            </th>
                            <th className={'bg-light'}>
                                <StationTotal station={stationData.hefschuifVouwwand}
                                              stations={stationData}
                                              setModalComponent={setModalComponent} setModalTitle={setModalTitle}
                                              setShowEditModal={setShowEditModal}
                                              setStationData={setStationData}/>

                            </th>
                            <th className={'bg-light'}>
                                <StationTotal station={stationData.custom}
                                              stations={stationData}
                                              setModalComponent={setModalComponent} setModalTitle={setModalTitle}
                                              setShowEditModal={setShowEditModal}
                                              setStationData={setStationData}/>
                            </th>
                        </TableHeader>
                        <tbody>
                        {data.map((row) => {
                            const newWeek = weekNumber(row.date) !== currentWeek;
                            currentWeek = weekNumber(row.date);
                            return (<>
                                    {newWeek &&
                                        <tr className={`fw-bold fs-6}`}>
                                            <th colSpan={13}>
                                                <span style={{cursor: 'pointer'}}
                                                      onClick={() => setDateRange(row.week)}>
                                                     <i className={'fas fa-calendar'}></i> Week {currentWeek}
                                                 </span>
                                            </th>
                                        </tr>
                                    }
                                    <tr key={formatDate(row.date)} className={`text-center}`}>
                                        <td className={'text-start text-nowrap'} style={{cursor: 'pointer'}}
                                            onClick={() => setDay(row.date)}>
                                            {dateToString(row.date, {
                                                weekday: 'short',
                                                year: 'numeric',
                                                month: 'numeric',
                                                day: 'numeric',
                                                timeZone: 'UTC'
                                            })}
                                        </td>
                                        <td className={'border-end border-primary border-bottom-0 border-1'}>
                                            <DailyStationProductionAnalytics metrics={row.dorpels}
                                                                             station={stationData.dorpels}
                                                                             stations={stationData}/>
                                        </td>
                                        <td>
                                            <DailyStationProductionAnalytics metrics={row.lattenMaken}
                                                                             station={stationData.lattenMaken}
                                                                             stations={stationData}/>
                                        </td>
                                        <td>
                                            <DailyStationProductionAnalytics metrics={row.dorpelProfielZagen}
                                                                             station={stationData.dorpelsZagen}
                                                                             stations={stationData}/>
                                        </td>
                                        <td>
                                            <DailyStationProductionAnalytics metrics={row.neutenMaken}
                                                                             station={stationData.neutenMaken}
                                                                             stations={stationData}/>
                                        </td>
                                        <td>
                                            <DailyStationProductionAnalytics metrics={row.dorpelBewerken}
                                                                             station={stationData.dorpelsBewerken}
                                                                             stations={stationData}/>
                                        </td>
                                        <td>
                                            <DailyStationProductionAnalytics metrics={row.neutenMonteren}
                                                                             station={stationData.neutenMonteren}
                                                                             stations={stationData}/>
                                        </td>
                                        <td>
                                            <DailyStationProductionAnalytics metrics={row.sluitpottenMonteren}
                                                                             station={stationData.sluitpottenPlaatsen}
                                                                             stations={stationData}/>
                                        </td>
                                        <td>
                                            <DailyStationProductionAnalytics metrics={row.kopisolatorenPlaatsen}
                                                                             station={stationData.kopisolatorenPlaatsen}
                                                                             stations={stationData}/>
                                        </td>
                                        <td>
                                            <DailyStationProductionAnalytics metrics={row.manchettesPlaatsen}
                                                                             station={stationData.manchettes}
                                                                             stations={stationData}/>
                                        </td>
                                        <td>
                                            <DailyStationProductionAnalytics metrics={row.lattenMonteren}
                                                                             station={stationData.lattenPlaatsen}
                                                                             stations={stationData}/>
                                        </td>
                                        <td>
                                            <DailyStationProductionAnalytics metrics={row.inpakken}
                                                                             station={stationData.inpakken}
                                                                             stations={stationData}/>
                                        </td>
                                        <td>
                                            <DailyStationProductionAnalytics metrics={row.hefschuifVouwwand}
                                                                             station={stationData.hefschuifVouwwand}
                                                                             stations={stationData}/>
                                        </td>
                                        <td>
                                            <DailyStationProductionAnalytics metrics={row.specials}
                                                                             station={stationData.custom}
                                                                             stations={stationData}/>
                                        </td>
                                    </tr>
                                </>

                            )
                        })
                        }
                        </tbody>
                    </EkoTable>
                </div>
                <div className='col col-lg-4 mb-md-5 mb-xl-10'>
                </div>
            </div>
        </>
    )
}

interface ProductionStationProgressProps {
    metrics: ProductionAnalysisMetrics;
    station: StationTotals;
    stations: Stations;
}

const DailyStationProductionAnalytics: React.FC<ProductionStationProgressProps> = ({
                                                                                       station,
                                                                                       metrics,
                                                                                       stations,
                                                                                   }) => {
    let stationWorkingHours = station.workingHours;
    let dagCapaciteit = station.capacity * stationWorkingHours;
    let bezetting = (((metrics.realised / station.capacity) / stationWorkingHours) * 100);
    let realised = metrics.realised;

    let backlog_previous_day = (metrics.backlog - (metrics.planned - metrics.realised));


    return (
        <>
            <div className={'d-flex justify-content-around align-items-center'}>
                {metrics.planned == 0 && metrics.realised == 0 ?
                    <>
                        -
                    </>

                    :
                    <div className={'text-nowrap'}>

                        {/*<div className="progress h-20px bg-secondary bg-opacity-50 w-75px">*/}

                        {/*{metrics.planned}+{backlog_previous_day}-*/}
                        <span
                            title={`${metrics.planned} gepland | ${metrics.backlog} backlog`}
                            //     className={`px-2 ${
                            //         (metrics.efficiency * 100).toFixed(0) == '0' ?
                            //             'bg-light ' :
                            //             metrics.efficiency * 100 > 75 ?
                            //                 metrics.efficiency * 100 >= 100 ?
                            //                     'bg-success '
                            //                     : 'bg-warning ' :
                            //                 'bg-danger '
                            //     }
                            // `}
                            className={`px-2 
                            //     (backlog_previous_day == metrics.backlog) ?
                            //         'bg-light ' :
                            //         backlog_previous_day > metrics.backlog ?
                            //             'bg-light ' : 'bg-danger '
                            // }
                            `}
                        >
{realised}
                            {/*{(metrics.efficiency * 100).toFixed(0)}%*/}
                        </span>
                        <i
                            title={`${metrics.backlog}`}
                            className={`px-2 fa fa-circle
                    ${
                                (metrics.backlog <= 0) ?
                                    'text-success ' :
                                    metrics.backlog > dagCapaciteit ?'text-danger ' : 'text-warning ' 
                            }
                            `}
                        >

                        </i>
                        {backlog_previous_day == metrics.backlog  ?
                            <i className={'fas fa-grip-lines text-dark ms-1'}></i>
                            :
                        backlog_previous_day > metrics.backlog  ?
                                                    <i className={'fas fa-arrow-down text-success ms-1'}></i>
                            :
                            <i className={'fas fa-arrow-up text-danger ms-1'}></i>
                        }

                        {/*<div title={`${realised}/${dagCapaciteit} (${bezetting.toFixed(2)}%)`}*/}
                        {/*     className={`progress-bar ${bezetting > 75 ? bezetting > 95 ? 'bg-success ' : 'bg-warning ' : 'bg-danger '}`}*/}
                        {/*     // className={`progress-bar`}*/}
                        {/*     role="progressbar" aria-label="Segment one"*/}
                        {/*     style={{width: 100}}*/}
                        {/*     // style={{width: bezetting > 100 ? 100 : bezetting + '%'}}*/}
                        {/*     aria-valuenow={bezetting} aria-valuemin={0} aria-valuemax={bezetting}>*/}
                        {/*            {realised} ({metrics.planned})*/}
                        {/*</div>*/}
                        {/*<div title={`${dagCapaciteit}`}*/}
                        {/*     className={`progress-bar bg-secondary'}`} role="progressbar" aria-label="Segment three"*/}
                        {/*     style={{width: 100+'%'}} aria-valuenow={100}*/}
                        {/*     aria-valuemin={0} aria-valuemax={100}>*/}

                        {/*</div>*/}

                        {/*</div>*/}
                        {/*<span className={'d-flex text-nowrap ms-2'}*/}
                        {/*      title={`${bezetting.toFixed(1)}% (${(metrics.realised / station.capacity).toFixed(2)} uur, ${dagCapaciteit}/dag)`}>*/}
                        {/*    <small>*/}
                        {/*        {metrics.backlog}*/}
                        {/*            </small>*/}
                        {/*</span>*/}
                    </div>
                }
            </div>

        </>
    )
};

interface ProductionStationProps {
    stations: Stations;
    station: StationTotals;
    showCapacity?: boolean;
    showWorkingHours?: boolean;
    setModalComponent: React.Dispatch<React.SetStateAction<ReactElement | undefined | null>>,
    setModalTitle: React.Dispatch<React.SetStateAction<string | undefined | null>>,
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>,
    setStationData: React.Dispatch<React.SetStateAction<Stations>>,
}


const StationTotal: React.FC<ProductionStationProps> = ({
                                                            station,
                                                            stations,
                                                            showCapacity = true,
                                                            showWorkingHours = true,
                                                            setModalTitle,
                                                            setModalComponent,
                                                            setShowEditModal,
                                                            setStationData
                                                        }) => {

    let uurBenodigd = (station.backlog / station.capacity)

    const chartRef = useRef<HTMLDivElement | null>(null)

    const editMetadata = (station: StationTotals) => {
        setModalComponent(
            <EditStationData station={station} setStationData={setStationData} stations={stations}
                             onClose={() => setShowEditModal(false)}/>
        )
        setModalTitle('Wijzig meta data')
        setShowEditModal(true)
    };


    return (
        <>
            <div className="fs-6 text-center fw-normal"
                 title={`
                ${(station.totalWorkingHours).toFixed(2)} uur 
                ${(uurBenodigd).toFixed(2)} uur 
                 `}
            >
                {/*<div*/}
                {/*    id={`kt_card_widget_17_chart`}*/}
                {/*    ref={chartRef}*/}
                {/*    style={{minWidth: 40 + 'px', minHeight: 40 + 'px'}}*/}
                {/*    data-kt-size={40}*/}
                {/*    data-kt-line={9}*/}
                {/*></div>*/}

                <h3>
                    <CountUp start={Math.max(0, station.backlog - 10)} end={station.backlog}
                             delay={0} duration={2.25}>
                        {({countUpRef}) => (
                            <div>
                                <span ref={countUpRef}/>
                            </div>
                        )}
                    </CountUp>
                </h3>
                <div className={``} style={{cursor: 'pointer'}}>
                    <div className={'text-nowrap text-primary'}>{station.title}</div>
                    <div className={`text-muted fs-8`}>{(uurBenodigd).toFixed(2)} uur</div>
                    <div  onClick={() => editMetadata(station)}
                        className={`text-muted fs-8 ${showCapacity ? '' : 'invisible'}`}>{station.capacity.toFixed(1)} per
                        uur
                    </div>
                    <div  onClick={() => editMetadata(station)}
                        className={`text-muted fs-8 ${showWorkingHours ? '' : 'invisible'}`}>{station.workingHours.toFixed(2)} uur
                    </div>
                </div>
            </div>
        </>
    )
};
const initChart = function (
    chartSize: number = 70,
    chartLine: number = 11,
    chartRotate: number = 145,
    bezetting: number = 100,
    el: HTMLDivElement | null
) {
    // const el = document.getElementById('kt_card_widget_17_chart')

    if (!el) {
        return
    }


    el.innerHTML = ''

    var options = {
        size: chartSize,
        lineWidth: chartLine,
        rotate: chartRotate,
        //percent:  el.getAttribute('data-kt-percent') ,
    }

    const canvas = document.createElement('canvas')
    const span = document.createElement('span')

    // @ts-ignore
    if (typeof G_vmlCanvasManager !== 'undefined') {
        // @ts-ignore
        G_vmlCanvasManager.initElement(canvas)
    }

    const ctx = canvas.getContext('2d')
    canvas.width = canvas.height = options.size

    el.appendChild(span)
    el.appendChild(canvas)

    // @ts-ignore
    ctx.translate(options.size / 2, options.size / 2) // change center
    // @ts-ignore
    // ctx.rotate((-1 / 2 + options.rotate / 180) * Math.PI) // rotate -90 deg

    //imd = ctx.getImageData(0, 0, 240, 240);
    const radius = (options.size - options.lineWidth) / 2

    const drawCircle = function (color: string, lineWidth: number, start: number, end: number) {
        // start = Math.min(Math.max(0, start || 1), 1)
        end = Math.min(Math.max(0, end || 1), 1)
        if (!ctx) {
            return
        }

        ctx.beginPath()
        ctx.arc(0, 0, radius, Math.PI * 2 * start, Math.PI * 2 * end, false)
        ctx.strokeStyle = color
        ctx.lineCap = 'round' // butt, round or square
        ctx.lineWidth = lineWidth
        ctx.stroke()
    }

    // Init 2
    drawCircle('#E4E6EF', options.lineWidth, 0, 100)
    drawCircle(getCSSVariableValue(bezetting > 75 ? bezetting > 95 ? '--kt-danger' : '--kt-warning' : '--kt-success'), options.lineWidth, 0, bezetting / 100)
    // drawCircle(getCSSVariableValue('--kt-warning'), options.lineWidth, total,capacity)
}

interface EditStationDataProps {
    station: StationTotals;
    stations: Stations;
    setStationData: React.Dispatch<React.SetStateAction<Stations>>,
    onClose: () => void
}

const EditStationData: React.FC<EditStationDataProps> = ({
                                                             station, setStationData,
                                                             stations, onClose
                                                         }) => {
    const [capacity, setCapacity] = useState<number>(station.capacity)
    const [workingHours, setWorkingHours] = useState<number>(station.workingHours)

    const updateMetadata = () => {
        let data = stations[station.key];
        data.capacity = capacity;
        data.workingHours = workingHours;
        stations[station.key] = data;
        setStationData(stations);
        onClose();
    }

    return (
        <>
            <div className={`row`}>
                <div className={`col-4`}>
                    <div className={`form-label`}>Capaciteit (per uur)</div>
                </div>
                <div className={`col`}>
                    <input className={`form-control mb-4`} type={'number'} value={capacity}
                           onChange={(e) => setCapacity(parseFloat(e.target.value))} name={'capacity'}/>
                </div>
            </div>
            <div className={`row`}>
                <div className={`col-4`}>
                    <div className={`form-label`}>Uren per dag</div>
                </div>
                <div className={`col`}>
                    <input className={`form-control mb-4`} type={'number'} value={workingHours}
                           onChange={(e) => setWorkingHours(parseFloat(e.target.value))} name={'workingHours'}/>
                </div>
            </div>
            <div className={`row`}>
                <div className={`col-4`}>
                </div>
                <div className={`col`}>
                    <button className={'btn btn-primary'} onClick={() => updateMetadata()}>Update</button>

                </div>
            </div>
        </>
    )
}

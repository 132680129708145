import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast"
import ApiClient from "../../../../../modules/api-client/ApiClient";
import {
    PostOrderRemarkRepresentation,
    OrderRemarkRepresentation,
} from "../../../../../modules/api-client/generated";
import { useEkosietMutation } from "../../../../../shared/hooks/useEkosietMutation";
import { NotFound, ValidationFailed } from "../../../../../modules/api-client/Responses";

export const usePostRemark = (orderId: string) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<OrderRemarkRepresentation, NotFound | ValidationFailed, PostOrderRemarkRepresentation>
        ((model: PostOrderRemarkRepresentation) => {
            return ApiClient.Sm.Remark.smOrderIdRemarkPost(orderId, undefined, model).then(response => response.data);
        },
            {
                onSuccess: (order) => {
                    queryClient.invalidateQueries(['smOrderIdGet', order.id]).then();
                    queryClient.invalidateQueries(['OrderActionLog', orderId]).then();

                    toast.success("Opmerking succesvol toegevoegd.");

                },
                onError: () => {
                    toast.error("Opmerking toevoegen mislukt!");
                }
            }
        );
};

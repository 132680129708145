import React, {useLayoutEffect} from 'react';
import {Formik, FormikHelpers, useFormikContext} from 'formik';
import SubmitButton from '../../../../shared/components/SubmitButton';
import {ValidationErrors} from '../../../../modules/api-client/Responses';
import {
	useProfileQuery,
	useProfileEditMutation,
} from './hooks';
import Loading from '../../../../shared/components/Loading';
import NotFound from '../../../../shared/components/NotFound';
import Error from '../../../../shared/components/Error';

export type FormData = {
	active: boolean,
	hasProgram: boolean,
};


type Props = {
	handleClose: () => void,
	id: string,
}

export const ProfileUpsert: React.FC<Props> = ({handleClose, id}) => {

	const {mutate, serverValidationErrors} = useProfileEditMutation(id);

	const onSubmit = (values: FormData, {setSubmitting}: FormikHelpers<FormData>) => {

		return new Promise<void>((resolve, reject) => {
			mutate(values, {
				onSuccess: () => {
					resolve();
					handleClose();
				},
				onError: () => {
					reject();
				},
			});

		}).finally(() => setSubmitting(false));
	};


	const {data: data, isInitialLoading, isError} = useProfileQuery(id);

	if (isInitialLoading) return <Loading />;
	if (isError) return <Error />;
	if (id && !data) return <NotFound />;

	const defaults = {
		active: false,
		hasProgram: false,
	};
	const initialValues: any = {...defaults, ...data};

	return (
		<>
			<Formik initialValues={initialValues} onSubmit={onSubmit}>
				<UpsertForm id={id} serverValidationErrors={serverValidationErrors} />
			</Formik>
		</>

	);
};


interface UpsertFormProps {
	id: string | undefined;
	serverValidationErrors: ValidationErrors | null;
}

export const UpsertForm: React.FC<UpsertFormProps> = (props) => {
	const {values, handleSubmit, handleChange, isSubmitting, errors, setErrors} = useFormikContext<FormData>();
	const mergeServerErrors = () => {

		if (props.serverValidationErrors) {
			setErrors({...errors, ...props.serverValidationErrors});
		}
	};
	useLayoutEffect(mergeServerErrors, [props.serverValidationErrors, errors, setErrors]);
	return (<form onSubmit={handleSubmit}>

		<div className='row mb-4'>
			<div className='col-4'>
				<label className='form-label' htmlFor='active'>Actief</label>
			</div>
			<div className='col-8'>
				<div className='form-check form-switch'>
					<input className='form-check-input' type='checkbox' role='switch'
						   name='active'
						   value='true'
						   onChange={handleChange}
						   checked={values.active}
						   id='active' />
					<label className='form-check-label' htmlFor='active'>Actief</label>
				</div>
			</div>
		</div>
		<div className='row mb-4'>
			<div className='col-4'>
				<label className='form-label' htmlFor='hasProgram'>Heeft programma</label>
			</div>
			<div className='col-8'>
				<div className='form-check form-switch'>
					<input className='form-check-input' type='checkbox' role='switch'
						   name='hasProgram'
						   value='true'
						   onChange={handleChange}
						   checked={values.hasProgram}
						   id='hasProgram' />
					<label className='form-check-label' htmlFor='hasProgram'>Programma</label>
				</div>
			</div>
		</div>

		<div className='row pt-5'>
			<div className='offset-4 col-8 d-flex justify-content-end'>
				<SubmitButton type='submit' className='btn btn-primary'
							  isSubmitting={isSubmitting}>Opslaan</SubmitButton>
			</div>
		</div>

	</form>);
};
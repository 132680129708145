import {useQueryClient} from "@tanstack/react-query";
import {toast} from "react-hot-toast"
import ApiClient from "../../../../../../modules/api-client/ApiClient";
import {
    OrderLineRepresentation, PutOrderLineRepresentation
} from "../../../../../../modules/api-client/generated";
import {useEkosietMutation} from "../../../../../../shared/hooks/useEkosietMutation";
import {NotFound, ValidationFailed} from "../../../../../../modules/api-client/Responses";

export const usePutOrderline = () => {

        const queryClient = useQueryClient()

        return useEkosietMutation<OrderLineRepresentation, NotFound | ValidationFailed, {
            orderLineId: string,
            line: PutOrderLineRepresentation
        }>
        (({orderLineId, line}) => {
                return ApiClient.Sm.Orderline.smOrderlineIdPut(orderLineId, undefined, line).then(response => response.data);
            },
            {
                onSuccess: (order) => {
                    queryClient.invalidateQueries(['smOrderIdGet', order.orderId]).then();
                    queryClient.invalidateQueries(['OrderActionLog', order.orderId]).then();

                    toast.success("Orderregel aangepast.");

                },
                onError: () => {
                    toast.error("Orderregel aanpassen mislukt!");
                }
            }
        );
    }
;

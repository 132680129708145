/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, ReactElement, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {CrmMasterData} from "../modules/crm/widgets/CrmMasterData";
import {PimMasterData} from "../modules/pim/widgets/PimMasterData";
import {Outlet} from "react-router-dom";
import {MasterdataModal} from "../modules/masterdata/components/MasterdataModal";
import {PmMasterData} from "../modules/pm/PmMasterData";

export type MasterdataModalContext = {
    setModalComponent: React.Dispatch<React.SetStateAction<ReactElement | undefined | null>>,
    setModalTitle: React.Dispatch<React.SetStateAction<string | undefined | null>>,
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
}
const MasterData: FC = () => {
    const [showModal, setShowModal] = useState<boolean>(false)
    const [modalTitle, setModalTitle] = useState<string | undefined | null>(null);
    const [modalComponent, setModalComponent] = useState<ReactElement | undefined | null>(null);

    const context: MasterdataModalContext = {
        setModalComponent: setModalComponent,
        setModalTitle: setModalTitle,
        setShowModal: setShowModal,
    }
    return (
        <>
            <PageTitle breadcrumbs={[]}>Stamgegevens</PageTitle>
            <MasterdataModal show={showModal} handleClose={() => setShowModal(false)}
                             title={modalTitle}
                             size={'lg'}
                             component={modalComponent}
            />
            <div className={'row'}>
                <div className={'col-12 col-lg-2'}>
                    <div className='app-sidebar-menu overflow-hidden flex-column-fluid'>
                        <div
                            className='app-sidebar-wrapper hover-scroll-overlay-y '
                        >
                            <div
                                className='menu menu-column menu-rounded menu-sub-indention'
                                id='#kt_app_sidebar_menu'
                                data-kt-menu='true'
                                data-kt-menu-expand='false'
                            >
                                <PimMasterData/>
                                <CrmMasterData/>
                                <PmMasterData/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'col-12 col-lg-10'}>
                    <Outlet context={context}/>
                </div>
            </div>
        </>
    )
}

export {MasterData}

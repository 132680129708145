import React, { useLayoutEffect } from "react";
import { Formik, FormikHelpers, useFormikContext } from "formik";
import { useEmailInsertMutation } from "../../hooks/useEmailInsertMutation";
import { ValidationErrors } from "../../../../../modules/api-client/Responses";
import TextField from "../../../../../shared/components/TextField";
import SubmitButton from "../../../../../shared/components/SubmitButton";

export interface FormValues {
    email: string;
}
type Props = {
    handleClose: () => void,
}

export const EmailInsert: React.FC<Props> = ({ handleClose }) => {

    const { mutate, serverValidationErrors } = useEmailInsertMutation()
    const onSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {

        return new Promise<void>((resolve, reject) => {
            mutate(values, {
                onSuccess: () => {
                    resolve();
                    handleClose();

                },
                onError: () => {
                    reject();
                }
            });

        }).finally(() => setSubmitting(false));
    };

    const initialValues: FormValues = {
        email: '',
    };

    return (
        <>
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                <InsertForm serverValidationErrors={serverValidationErrors} />
            </Formik>
        </>
    );
}

interface UpsertFormProps {
    serverValidationErrors: ValidationErrors | null;
}

export const InsertForm: React.FC<UpsertFormProps> = (props) => {
    const { handleSubmit, isSubmitting, errors, setErrors } = useFormikContext<FormValues>();
    const mergeServerErrors = () => {

        if (props.serverValidationErrors) {
            setErrors({ ...errors, ...props.serverValidationErrors });
        }
    }
    useLayoutEffect(mergeServerErrors, [props.serverValidationErrors, errors, setErrors]);
    return (<form onSubmit={handleSubmit}>
        <div className="text-danger mb-1">Let op! Voordat iemand zich kan laten inschrijven voor de nieuwsbrief, dient hij of zij eerst akkoord te gaan met de AVG-richtlijnen. Zonder deze toestemming mag de inschrijving niet worden voltooid.</div>
        <TextField row={true} name={'email'} label={'E-mailadres'} />

        <div className="row pt-5">
            <div className="offset-4 col-8 d-flex justify-content-end">
                <SubmitButton type="submit" className="btn btn-primary"
                    isSubmitting={isSubmitting}>Opslaan</SubmitButton>
            </div>
        </div>

    </form>);
}
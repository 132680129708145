import {useQuery, useQueryClient} from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import {NotFound, ValidationFailed} from "../../../../modules/api-client/Responses";
import {useEkosietMutation} from "../../../../shared/hooks/useEkosietMutation";
import {
    Catalog, CreateCatalogCommand, UpdateCatalogRequest
} from "../../../../modules/api-client/generated";
import {FormValues} from "./CatalogUpsert";


export const useCatalogUpsertMutation = (id?: string | undefined) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<Catalog, NotFound | ValidationFailed, FormValues>((values) => {
            const command: CreateCatalogCommand | UpdateCatalogRequest = {
                code: values.code,
                name: values.name,
            };

            if (id) {
                return ApiClient.Pim.Catalog.pimCatalogsIdPut(id, undefined, command).then(response => response.data);
            }
            return ApiClient.Pim.Catalog.pimCatalogsPost(undefined, command).then(response => response.data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['pimCatalogIdGet', id]).then();
                queryClient.invalidateQueries(['pimCatalogsGet']).then();
            }
        }
    );
};

export const useCatalogQuery = (id: string | undefined) => {
    return useQuery(['pimCatalogIdGet', id],
        () => {
            return ApiClient.Pim.Catalog.pimCatalogsIdGet(id!).then(res => res.data);
        }, {
            useErrorBoundary: true,
            enabled: !!id,
        });
}

export const useCatalogDeleteMutation = (id: string) => {
    const queryClient = useQueryClient()
    return useEkosietMutation<void, NotFound | ValidationFailed, string>((id) => {
            return ApiClient.Pim.Catalog.pimCatalogsIdDelete(id, undefined).then(response => response.data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['pimCatalogIdGet', id]).then();
                queryClient.invalidateQueries(['pimCatalogsGet']).then();
            }
        }
    );
};




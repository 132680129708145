import {FC} from 'react'
import * as Yup from 'yup'
import {Form, Formik, FormikHelpers} from 'formik'
import {
    OrderLineRepresentation,
    OrderLineType,
    PutConfigurationOrderLineRepresentation,
    PutProductOrderLineRepresentation,
    PutShippingCostOrderLineRepresentation,
    PutTextOrderLineRepresentation,
} from '../../../../../../../../modules/api-client/generated'
import TextField from "../../../../../../../../shared/components/TextField";
import {usePutOrderline} from "../../../../hooks/orderline/use-put-orderline";
import {useGetOrder} from '../../../../hooks/use-get-order';
import {useEkosietAuth0} from '../../../../../../../../modules/auth0/core/useEkosietAuth0';

type Props = {
    line: OrderLineRepresentation
    handleClose: () => void,
}

type OrderLineFormValues =
    PutConfigurationOrderLineRepresentation |
    PutProductOrderLineRepresentation |
    PutShippingCostOrderLineRepresentation |
    PutTextOrderLineRepresentation;

const baseFormSchema = Yup.object().shape({
        remark: Yup.string().min(3, 'Minimaal 3 karakters.').notRequired().nullable(),

        title: Yup.string()
            .min(3, 'Minimaal 3 karakters.')
            .required('Titel is verplicht'),

        // quantity: Yup.number().when('orderlineType', {
        //     is: (orderlineType: OrderLineType) => { return orderlineType === OrderLineType.ProductLine || orderlineType === OrderLineType.ConfigurationLine },
        //     then: Yup.number()
        //         .min(0, 'Negatieve aantal zijn niet toegestaan. Gebruik een negatieve prijs!')
        //         .required('Aantal is verplicht.')
        // }),

        quantity: Yup.number()            
            .when('orderlineType', {
                is: (orderlineType: OrderLineType) => {
                    return orderlineType === OrderLineType.ConfigurationLine
                },
                then: Yup.number()
                .min(0, 'Negatief aantal is niet toegestaan. Gebruik een negatieve prijs!')                        
                .max(999, 'Aantalen groter dan 999 zijn niet toegestaan. Gebruik meerdere order regels.')
                .required('Aantal is verplicht.'),
                otherwise: Yup.number()
                .min(0, 'Negatief aantal is niet toegestaan. Gebruik een negatieve prijs!')                                        
                .required('Aantal is verplicht.')
            }),

        price: Yup.number().when('orderlineType', {
            is: (orderlineType: OrderLineType) => {
                return orderlineType === OrderLineType.TextLine
            },
            then: Yup.number().required('Prijs is verplicht')
        }),

        merk: Yup.string()
            .notRequired()
            .nullable(),
    }
);

const PutOrderlineForm: FC<Props> = ({line, handleClose}) => {

    const mutation = usePutOrderline();
    const {refetch: refetchOrder} = useGetOrder(line.orderId, false);
    const {isKlant} = useEkosietAuth0();

    const formSchema = isKlant ? baseFormSchema.shape({
        price: Yup.number().notRequired().nullable(),
        title: Yup.string().notRequired().nullable()
    }) : baseFormSchema;

    const formValues: OrderLineFormValues = {
        ...line,
        price: line.price || 0,
        quantity: line.quantity || 1,
        merk: line.merk || undefined,
        orderlineType: line.type
    }

    const onSubmit = (values: OrderLineFormValues, {setSubmitting}: FormikHelpers<OrderLineFormValues>) => {

        return new Promise<void>((resolve, reject) => {
            mutation.mutate({orderLineId: line.id, line: values}, {
                onSuccess: () => {
                    resolve();
                    handleClose()
                },
                onError: () => {
                    reject();
                }
            });

        }).finally(() => {
            setSubmitting(false);
            refetchOrder();
        });
    };

    return (
        <>
            <Formik initialValues={formValues} onSubmit={onSubmit} validationSchema={formSchema}>
                {({
                      getFieldProps,
                      isSubmitting,
                  }) => (
                    <Form>
                        <TextField
                            row={true}
                            type={'number'} min={0}
                            label={'Aantal'}
                            {...getFieldProps('quantity')}
                        />
                                <TextField
                                    row={true}
                                    disabled={isKlant || !(line.type === OrderLineType.TextLine)}
                                    label={'Omschrijving'}
                                    {...getFieldProps('title')}
                                />

                            {(line.type === OrderLineType.ConfigurationLine) &&
                                    <TextField
                                        row={true}
                                        label={'Merk'}
                                        {...getFieldProps('merk')}
                                    />
                            }
                                <TextField
                                    row={true}
                                    label={'Opmerking'}
                                    {...getFieldProps('remark')}
                                />
                        <div className='text-center pt-5'>
                            <button
                                type='reset'
                                className='btn btn-light me-3'
                                data-kt-customer-modal-action='cancel'
                                data-dismiss="modal"
                                onClick={() => handleClose()}
                                disabled={isSubmitting}
                            >
                                Annuleren
                            </button>

                            <button
                                type='submit'
                                className='btn btn-primary'
                                data-kt-customer-modal-action='submit'
                                disabled={isSubmitting}
                            >
                                <span className='indicator-label'>Opslaan
                                </span>
                                {isSubmitting && (
                                    <span className='indicator-progress'>
                                        Even wachten a.u.b
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>

                    </Form>
                )}
            </Formik>
        </>
    )
}

export {PutOrderlineForm}

import { useMutation, useQueryClient  } from "@tanstack/react-query";
import {toast} from "react-hot-toast"
import ApiClient from "../../../../modules/api-client/ApiClient";
import { PostCustomerAddressRepresentation } from "../../../../modules/api-client/generated";
import { AxiosError } from "axios";


export const usePostCustomerAddress = () => {  

    const queryClient = useQueryClient();
    
    const mutation = useMutation(
        (address: PostCustomerAddressRepresentation) => {      
            
          return ApiClient.Crm.Customer
            .crmCustomerCustomerIdAddressPost(address.customerId || "", undefined, address, undefined)
          ;
        },        
        {
          onSuccess: (_data, { customerId }) => {            
            queryClient.invalidateQueries([`v1/crm/customer/${customerId}/address/list`])
            toast.success("adres successvol aangemaakt.");
          },
          onError: (error: AxiosError) => {                        
            toast.error(error?.message);
            // toast.error(error?.response?.data?.title || 'Onbekende fout!');
          }
        },
      );
    
      return mutation.mutateAsync;
};
import axios, {AxiosInstance, AxiosError, AxiosResponse} from "axios";
import {API_GATEWAY_BASE_URL} from "../../constants";
import {NotFound, ValidationErrors, ValidationFailed} from "./Responses";

axios.defaults.headers.get = {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
};

const instance: AxiosInstance = axios.create({
    baseURL: API_GATEWAY_BASE_URL,
    // validateStatus: status => status < 500
})


export function isValidationErrorAxiosResponse(response: AxiosResponse | undefined) {
    return response?.status === 400
        && 'status' in response.data
        && 'errors' in response.data;
}

export function isNotFoundAxiosResponse(response: AxiosResponse | undefined) {
    return response?.status === 404;
}

instance.interceptors.response.use(response => {
    return response;
}, (error: AxiosError | Error) => {

    if (axios.isAxiosError<ErrorResponse>(error)) {

        if (isValidationErrorAxiosResponse(error.response)) {
            return Promise.reject(new ValidationFailed(error.response!.data.errors, error.response!));
        }

        if (isNotFoundAxiosResponse(error.response)) {
            return Promise.reject(new NotFound(error.response!));
        }
    }

    return Promise.reject(error)
});

type ErrorResponse = {
    errors: ValidationErrors
}

export default instance


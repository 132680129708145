/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {EkoCard, EkoCardBody} from "../../../../../_metronic/helpers";
import {
    ProductionItemOperationRepresentation,
    ProductionItemOperationsRepresentation
} from '../../../../modules/api-client/generated';
import {useQuery} from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import Loading from "../../../../shared/components/Loading";

type Props = {
    id: string
}
export const ProductionItemOperation: React.FC<Props> = ({id}) => {

    const {isInitialLoading, data: io, isError} = useQuery<ProductionItemOperationRepresentation>(
        ['productionItemOperation', id],
        () => ApiClient.Pm.ProductionItem.getOperation(id).then((res) => res.data),
    );

    if (isInitialLoading ) {
        return <Loading/>;
    }

    if (isError || !io) {
        return <>ERROR!</>;
    }

    return (
            <EkoCard>
                <EkoCardBody>
                        <div className={'d-flex justify-content-between'}>
                            <div><strong>{io.operation.title}</strong></div>
                            <div>{io.status}</div>
                            <div>{io.instructions}</div>
                        </div>
                </EkoCardBody>
            </EkoCard>
    )
}


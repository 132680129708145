import {FC} from 'react'
import * as Yup from 'yup'
import {Form, Formik, FormikHelpers} from 'formik'
import {
    PaymentConditions, 
    PutPaymentConditionRepresentation
} from '../../../../../../../modules/api-client/generated'
import SelectField from "../../../../../../../shared/components/SelectField";
import {usePutPaymentCondition} from "../../../hooks/use-put-payment-condition";

type Props = {
    id: string
    paymentCondition?: PaymentConditions
    handleClose: () => void,
}

const PutPaymentConditionForm: FC<Props> = ({id, paymentCondition, handleClose}) => {

    const mutation = usePutPaymentCondition(id);

    const initialValues:PutPaymentConditionRepresentation= {
        paymentCondition: paymentCondition || 'opFactuur30Dagen'
    }

    const schema = {
        paymentCondition: Yup.string()
            .oneOf(
                Object.values(PaymentConditions).filter((item) => {
                    return isNaN(Number(item));
                }),
                'Kies betaalconditie'
            )
            .required('Required'),
    }
    const formSchema = () => Yup.lazy(() => {
        return Yup.object().shape(schema)
    });
    const onSubmit = (values: PutPaymentConditionRepresentation, {setSubmitting}: FormikHelpers<PutPaymentConditionRepresentation>) => {

        return new Promise<void>((resolve, reject) => {
            mutation.mutate(values, {
                onSuccess: () => {
                    resolve();
                    handleClose()
                },
                onError: () => {
                    reject();
                }
            });

        }).finally(() => setSubmitting(false));
    };


    return (
        <>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={formSchema}>
                {({
                      getFieldProps,
                      isSubmitting,
                  }) => (
                    <Form>
                        {/* begin form element */}
                        <SelectField
                            label={'Betaalconditie'}
                            {...getFieldProps('paymentCondition')}
                        >
                            <option value={PaymentConditions.Vooraf}>Vooraf betalen</option>
                            <option value={PaymentConditions.OpFactuur30Dagen}>Op factuur (30 dagen)</option>
                            <option value={PaymentConditions.OpFactuur8Dagen}>Op factuur (8 dagen)</option>
                            <option value={PaymentConditions.AutomatischeIncasso}>Automatische incasso</option>
                        </SelectField>

                        {/* begin::Actions */}
                        <div className='text-center pt-5'>
                            <button
                                type='reset'
                                className='btn btn-light me-3'
                                data-kt-customer-modal-action='cancel'
                                data-dismiss="modal"
                                onClick={() => handleClose()}
                                disabled={isSubmitting}
                            >
                                Annuleren
                            </button>

                            <button
                                type='submit'
                                className='btn btn-primary'
                                data-kt-customer-modal-action='submit'
                                disabled={isSubmitting}
                            >
                                <span className='indicator-label'>Opslaan
                                </span>
                                {isSubmitting && (
                                    <span className='indicator-progress'>
                                        Even wachten a.u.b
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                        {/* end::Actions */}

                    </Form>
                )}
            </Formik>
        </>
    )
}

export {PutPaymentConditionForm}

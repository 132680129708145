import { FC } from 'react'
import * as Yup from 'yup'
import { Form, Formik, FormikHelpers } from 'formik'
import { PutProjectDiscountRepresentation } from '../../../../../../../modules/api-client/generated'
import TextField from "../../../../../../../shared/components/TextField";
import { usePutProjectDiscount } from "../../../hooks/use-put-project-discount";

type Props = {
    id: string
    projectDiscount?: number
    handleClose: () => void,
}

const PutProjectDiscountForm: FC<Props> = ({ id, projectDiscount, handleClose }) => {
    const mutation = usePutProjectDiscount(id);

    const initialValues: PutProjectDiscountRepresentation = {
        projectDiscount: projectDiscount ? projectDiscount : 0.00
    }

    const schema = {
        projectDiscount: Yup.number().min(0, "Negatieve korting percentage is niet toegestaan").max(100, "Hogere korting dan 100% is niet toegestaan.").nullable().notRequired()
    }
    const formSchema = () => Yup.lazy((values) => {
        return Yup.object().shape(schema)
    });
    const onSubmit = (values: PutProjectDiscountRepresentation, { setSubmitting }: FormikHelpers<PutProjectDiscountRepresentation>) => {

        return new Promise<void>((resolve, reject) => {
            mutation.mutate(values, {
                onSuccess: (model) => {
                    resolve();
                    handleClose()
                },
                onError: () => {
                    reject();
                }
            });

        }).finally(() => setSubmitting(false));
    };


    return (
        <>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={formSchema}>
                {({
                    isSubmitting,
                }) => (
                    <Form>
                        <TextField row={true} name={'projectDiscount'} label={'Projectkorting'} type={'number'} inputGroupSuffixText={'%'}/>


                        {/* begin::Actions */}
                        <div className='text-center pt-5'>
                            <button
                                type='reset'
                                className='btn btn-light me-3'
                                data-kt-customer-modal-action='cancel'
                                data-dismiss="modal"
                                onClick={() => handleClose()}
                                disabled={isSubmitting}
                            >
                                Annuleren
                            </button>

                            <button
                                type='submit'
                                className='btn btn-danger'
                                data-kt-customer-modal-action='submit'
                                disabled={isSubmitting}
                            >
                                <span className='indicator-label'>Opslaan
                                </span>
                                {isSubmitting && (
                                    <span className='indicator-progress'>
                                        Even wachten a.u.b
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                        {/* end::Actions */}

                    </Form>
                )}
            </Formik>
        </>
    )
}

export { PutProjectDiscountForm }

import {UserAlert} from '../users-list/components/loading/UserAlert'
import {useEffect} from 'react'

import {PageTitle} from "../../../../../_metronic/layout/core";
import {QueryRequestProvider} from "../user-roles/core/QueryRequestProvider";
import {QueryResponseProvider} from "../user-roles/core/QueryResponseProvider";
import {ListViewProvider, useListView} from "../user-roles/core/ListViewProvider";
import {RolesTable} from "../user-roles/table/RolesTable";
import {RoleEditModal} from "../user-roles/role-edit-modal/RoleEditModal";
import {RoleWarningModal} from "../user-roles/role-warning-modal/RoleWarningModal";
import {Link} from "react-router-dom";
import AbsLink from "../../../../shared/components/RouteHelpers";
import {BackofficeRoutePath} from "../../../RoutePath";
import {UmRoutePath} from "../UmRoutes";

export const RoleList = () => {
  const {itemIdForUpdate, itemForWarning, Alert, setAlert,selected} = useListView()

  useEffect(() => {
    if (Alert.isVisible) {
      setTimeout(() => {
        setAlert({isVisible: !Alert.isVisible, message: '', title: '', status: ''})
      }, 5000)
    }
  }, [Alert, setAlert])

  return (
    <>
      <PageTitle>Gebruikersrollen</PageTitle>
        <Link to={AbsLink(BackofficeRoutePath.um,UmRoutePath.userList)} className={'btn btn-sm btn-primary mb-3'}>Gebruikers</Link>

        <RolesTable />

        {itemIdForUpdate !== undefined && <RoleEditModal />}
        {itemForWarning?.Id !== undefined && <RoleWarningModal />}
        {Alert.isVisible === true ? (
            <UserAlert title={Alert.title} message={Alert.message} status={Alert.status} />
        ) : (
            <></>
        )}

    </>
  )
}

const RolesListWrapper = () => (
    <QueryRequestProvider>
      <QueryResponseProvider>
        <ListViewProvider>
          <RoleList />
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
)

export {RolesListWrapper}

import React from "react";
import {ProductionBatchStatusCodes} from "../../modules/api-client/generated";
export interface Props {
    status?: ProductionBatchStatusCodes
}

const ProductionBatchStatus: React.FC<Props> = ({status}) => {
        let label;
        let color;
        switch (status) {
            case ProductionBatchStatusCodes.Drafted: {
                label = 'Concept';
                color = 'secondary';
                break;
            }
            case ProductionBatchStatusCodes.Accepted: {
                label = 'Geaccepteerd';
                color = 'primary';
                break;
            }
            case ProductionBatchStatusCodes.Started: {
                label = 'Gestart';
                color = 'info';
                break;
            }
            case ProductionBatchStatusCodes.Finished: {
                label = 'Gereed';
                color = 'success';
                break;
            }
            case ProductionBatchStatusCodes.Cancelled: {
                label = 'Geannuleerd';
                color = 'danger';
                break;
            }
            case ProductionBatchStatusCodes.Failed: {
                label = 'Gefaald';
                color = 'danger';
                break;
            }
            default: {
                label = 'trash'
            }
        }
        return (
            <span className={`p-1 bg-${color} text-inverse-${color} `}>{label}</span>
        )
    }
;

export default ProductionBatchStatus;

import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty} from '../../../../../../_metronic/helpers'
import {initialUser, User} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {UsersListLoading} from '../components/loading/UsersListLoading'
import {createUser, updateUser} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import { EkosietSignInMethod } from '../../../../../modules/auth0/components/EkosietSignInMethod'
import toast from 'react-hot-toast';
import { useListCustomers } from '../../../crm/hooks/use-list-customers'
import {CustomerListRepresentation} from '../../../../../modules/api-client/generated';
import Select from 'react-select';

type Props = {
  isUserLoading: boolean
  user: User
  isEditModal: boolean  
}

const UserEditModalForm: FC<Props> = ({user, isUserLoading, isEditModal}) => {    
  const {setItemIdForUpdate} = useListView()
  
  let defaultCustomerId = user?.userMetadata?.customerId ? user.userMetadata.customerId : ''
    
  const [customerId, setCustomerId] = useState<string>(defaultCustomerId.toLocaleLowerCase())
    
  const [userForEdit, setUserForEdit] = useState<User>({
    ...user,
    roles: user.roles || initialUser.roles,    
    email: user.email || initialUser.email,        
    userMetadata: { 
      customerId: customerId,      
    },
    roleIds: user?.roles?.map((a: any) => a.id) || initialUser?.roles?.map((a: any) => a.id),
    accountType: user.userMetadata?.customerId === null ? "employee" :  "customer",
    customerId: customerId
  })
  
  const [accountType, setAccountType] = useState<'customer' | 'employee' | undefined>(userForEdit.accountType)
  const {refetch, roles, isRolesLoading} = useQueryResponse()
  const { data: customerListData, isLoading: isLoadingListCustomers, isError: isErrorListCustomers } = useListCustomers(undefined, false);
  let customerListOptions: any[] = [{ value: '', label: ''}]
  if (customerListData) {
    let customerOptions = customerListData.map((item: CustomerListRepresentation) => {
      return { value: item.id, label: item.companyName}
    });

    customerListOptions = [...customerListOptions, ...customerOptions]
  }

  let customerListSelectedValue = customerListOptions.find((option: any) => option.value === customerId) || customerListOptions[0];

  if (customerListSelectedValue.value === '' && customerId) {
    setCustomerId('');
    setUserForEdit((value) => {
      value.customerId = '';
      value.userMetadata =  { 
        customerId: '',      
      }
      return value;
    })
  }
  
  let editSchemaObj = {            
    accountType: Yup.string()
    ,

    customerId: Yup.string().when("accountType", {
      is: 'customer',
      then: Yup.string().required("Klant is verplicht")
    })
  }

  let addschemaObj = {

    accountType: Yup.string(),

    customerId: Yup.string().when("accountType", {
      is: 'customer',
      then: Yup.string().required("Klant is verplicht")
    }),
              
    email: Yup.string()
    .email('Geen geldig e-mail.')
    .min(3, 'Minimaal 3 karakters.')
    .max(50, 'Maximaal 50 karakters.')
    .required('Email is verplicht.'),

    newPassword: Yup.string()
      .min(8, 'Minimaal 8 karakters.')
      .required('Wachtwoord is verplicht.')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        'Wachtwoord moet minimaal uit 8 karakters bestaan waarvan 1 hoofdletter en kleine letter.'
      ),
    passwordConfirmation: Yup.string()
      .min(8, 'Minimum 8 characters is required')
      .required('Wachtwoord is verplicht')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        'Wachtwoord moet minimaal uit 8 karakters bestaan waarvan 1 hoofdletter, 1 kleine letter.'
      )
      .oneOf([Yup.ref('newPassword'), null], 'Wachtwoorden moeten overeenkomen.'),
  }

  const userSchema = Yup.object().shape(isEditModal === true ? editSchemaObj : addschemaObj)

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }
  
  const createUserData = (values: any) => {
    
    let userMeta: any
    if (accountType === 'customer') {
      userMeta = { customerId: values.customerId, employeeId: null}
    } else {
      userMeta = { customerId: null }
    }
    
    //existing user
    if (isNotEmpty(values.userId)) {
      return {
        data: {
          userId: values.userId,                    
          userMetadata: userMeta,
        },
        roles: values.roleIds,
      }
    }

    //new user
    return {
      data: {
        email: values.email,
        fullName: values.email,
        password: values.newPassword,        
        userMetadata: userMeta,
      },
      roles: values.roleIds,
    }
  }

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: userSchema,
    onSubmit: (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        const userData = createUserData(values)
        if (isNotEmpty(values.userId)) {
          updateUser(userData)
            .then(() => {
              toast.success('Gebruiker succesvol bijgewerkt.')
              setTimeout(() => refetch(), 1000)
              cancel(false)
            })
            .catch((err) => {
              toast.error('Gebruiker wijzigen mislukt. Fout: ' + err?.detail)
            })
        } else {
          createUser(userData)
            .then(() => {
              toast.success('Gebruiker succesvol aangemaakt.')
              setTimeout(() => refetch(), 1000)
              cancel(false)
            })
            .catch((err) => {
              toast.error('Gebruiker aanmaken mislukt. Fout: ' + err?.detail)
            })
        }
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(false);
      }
    },
  })

  const GetEmailField = () => {
    return (
      !isEditModal && (
        <>
          {/* begin::Label */}
          <label className='required fw-bold fs-6 mb-2'>
            E-mail (is tevens login)
          </label>
          {/* end::Label */}

          {/* begin::Input */}
          <input            
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
            type='email'
            name='email'
            autoComplete='off'
            disabled={formik.isSubmitting || isUserLoading}
          />
          {/* end::Input */}
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          )}
        </>
      )
    )
  }

  const GetPasswordField = () => {
    return (
      !isEditModal && (
        <>
          <div className='row mb-5'>
            <div className={'col'}>
              <label htmlFor='newpassword' className='required fw-bold fs-6 mb-2'>
                Wachtwoord
              </label>
              <input
                  type='password'
                  className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      {'is-invalid': formik.touched.newPassword && formik.errors.newPassword},
                      {
                        'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
                      }
                  )}
                  id='newpassword'
                  {...formik.getFieldProps('newPassword')}
              />
              {formik.touched.newPassword && formik.errors.newPassword && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.newPassword}</span>
                    </div>
                  </div>
              )}
            </div>
            <div className={'col'}>
              <label htmlFor='confirmpassword' className='required fw-bold fs-6 mb-2'>
                Bevestig wachtwoord
              </label>
              <input
                  type='password'
                  className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      {'is-invalid': formik.touched.passwordConfirmation && formik.errors.passwordConfirmation},
                      {
                        'is-valid': formik.touched.passwordConfirmation && !formik.errors.passwordConfirmation,
                      }
                  )}
                  id='confirmpassword'
                  {...formik.getFieldProps('passwordConfirmation')}
              />
              {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.passwordConfirmation}</span>
                    </div>
                  </div>
              )}
            </div>
          </div>
        </>
      )
    )
  }

  const isCustomerAccount = accountType === 'customer';

  const GetCustomerField = () => {
    return (
      isCustomerAccount && (
        <>
            <div className='fv-row mb-7'>
              <label className='required fw-bold fs-6 mb-2'>
                Klant
              </label>            
            <div>
            
            <Select
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.customerId && formik.errors.customerId},
                  {
                    'is-valid': formik.touched.customerId && !formik.errors.customerId,
                  }
                )}
                classNamePrefix="select"
                defaultValue={customerListSelectedValue}                
                placeholder="Selecteer een klant"
                isClearable={true}
                isSearchable={true}
                name="customerId"
                options={customerListOptions}
                onChange={(newValue) => {
                  setCustomerId(newValue.value);
                  formik.values.customerId = newValue.value;
                  formik.validateForm();
                }}
              />
            </div>
            {formik.touched.customerId && formik.errors.customerId && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.customerId}</span>
                </div>
              </div>
            )}            
          </div>          
        </>
      )
    )
  }

  return (
    <>
      <form id='kt_modal_edit_user_form' className='form' onSubmit={formik.handleSubmit} noValidate autoComplete="off">
     
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>
              Type Account
            </label>
            {/* end::Label */}

            {/* begin::Input */}
            <div className="form-check" style={{marginTop: '1rem'}}>
              <input
                id="accountTypeKlant"
                name="accountType" 
                type="radio" 
                value="customer"
                className='form-check-input mb-3 mb-lg-0'                
                checked={accountType === "customer"}
                onChange={(e) => {                  
                  formik.values.accountType = "customer";                  
                  setAccountType("customer");
                  formik.validateForm();
                }}
              />
              <label className="form-check-label" htmlFor="accountTypeKlant">
                Klant
              </label>
            </div>
            
            <div className="form-check" style={{marginTop: '1rem'}}>
              <input
                id="accountTypeEmployee"
                name="accountType" 
                type="radio"       
                className='form-check-input mb-3 mb-lg-0'
                value="employee"                
                checked={accountType === "employee"}
                onChange={(e) => {                  
                  formik.values.accountType = "employee";
                  setAccountType("employee");
                  formik.validateForm();
                }}
              />
                <label className="form-check-label" htmlFor="accountTypeEmployee">
                Medewerker
                </label>
            </div>
          </div>  

          {GetCustomerField()}

          {/* {GetEmployeeField()} */}
               
          {/* begin::Input group */}
          <div className='fv-row mb-7'>{GetEmailField()}</div>
          {/* end::Input group */}

          {GetPasswordField()}
          {/* begin::Input group */}
          <div className='mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-5'>
              Rollen
            </label>
            {/* end::Label */}
            {/* begin::Roles */}
            {/* begin::Input row */}
            {!isRolesLoading &&
              roles.filter((item: any, index: any) => {
                if (accountType === 'customer') {
                  return (item.name === 'Klant');
                }

                if (accountType === 'employee') {
                  return (item.name !== 'Klant');
                }

                return false;
              }).map((item: any, index: any) => {
                return (
                  <div key={index}>
                    <div className='d-flex fv-row'>
                      {/* begin::Radio */}
                      <div className='form-check form-check-custom form-check-solid'>
                        {/* begin::Input */}
                        <input
                          className='form-check-input me-3'
                          {...formik.getFieldProps('roleIds')}
                          name='roleIds'
                          type='checkbox'
                          value={item.id}
                          id={`kt_modal_update_role_option_${index}`}
                          checked={formik.values.roleIds && formik.values.roleIds.includes(item.id)}
                          disabled={formik.isSubmitting || isUserLoading}
                        />

                        {/* end::Input */}
                        {/* begin::Label */}
                        <label className='form-check-label' htmlFor={`kt_modal_update_role_option_${index}`}>
                          <div className='fw-bolder text-gray-800'>{item.name}</div>
                          <div className='text-gray-600'>{item.description}</div>
                        </label>
                        {/* end::Label */}
                      </div>
                      {/* end::Radio */}
                    </div>
                    {index !== roles.length - 1 && (
                      <div className='separator separator-dashed my-5'></div>
                    )}
                  </div>
                )
              })}
            {/* end::Input row */}
            {/* end::Roles */}
          </div>
          {/* end::Input group */}
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            Annuleren
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.touched}
          >
            <span className='indicator-label'>
              Opslaan
            </span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Even wachten a.u.b
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      <br />
      {isEditModal === true && <EkosietSignInMethod isEditModal={true} userData={user} />}
      {(formik.isSubmitting || isUserLoading) && <UsersListLoading />}
    </>
  )
}

export {UserEditModalForm}

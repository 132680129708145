import React from "react";


export interface CompartmentCountPickerProps {
    selected: number | null;
    options: number[];
    onChange?: (option: number) => void;
}

const CompartmentCountPicker: React.FC<CompartmentCountPickerProps> = (props) => {

    const values = props.options;

    const selected = props.options.find(x => x === (props.selected ?? 1));

    return (
        <div className="dropdown">
            <div className="form-select form-control-lg py-1 px-3 d-flex align-items-center"
                 tabIndex={0}
                 data-bs-toggle="dropdown" aria-expanded="false">
                <div className="flex-fill d-flex align-items-center">
                    {selected && (
                        <span className="fs-5">
                            <span>{selected}</span>
                            <span className="text-muted">  <small>/ {selected + 1} neuten</small></span>
                        </span>
                    )}
                </div>
            </div>
            <ul className="dropdown-menu dropdown-menu-lg" style={{minWidth: '100%'}}>
                {values.map((option, index) => {
                    return (<li key={index} className="dropdown-item d-flex align-items-center"
                                onClick={() => props.onChange?.call(this, option)}>
                        <span className="fs-5">
                            <span>{option} {option === 1 ? 'vak' : 'vakken'}</span>
                            <span className="text-muted"> <small>/ {option + 1} neuten</small></span>
                        </span>
                    </li>);
                })}
            </ul>
        </div>
    )
};

export default CompartmentCountPicker;
import React from "react";
import { PageTitle } from "../../../../../_metronic/layout/core";
import { EkoCard, EkoCardBody } from "../../../../../_metronic/helpers";
import { FilterContextProvider } from "../../../../shared/context/FilterContext";
import { ExpeditionSearch } from "../expedition/components/ExpeditionSearch";
import { ExpeditionListTable } from "../expedition/components/ExpeditionListTable";

const ExpeditionList: React.FC = () => {
    
    return (
        <>            
            <PageTitle>
                Expeditie overzicht
            </PageTitle>
            <EkoCard>
                <EkoCardBody>
                    <FilterContextProvider>
                        <ExpeditionSearch />
                        <ExpeditionListTable />
                    </FilterContextProvider>
                </EkoCardBody>
            </EkoCard>
        </>
    )
}

export default ExpeditionList

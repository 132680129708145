import { UseQueryResult, useQuery } from "@tanstack/react-query";
import {toast} from "react-hot-toast"
import ApiClient from "../../../../modules/api-client/ApiClient";
import {CustomerListView, CustomerRepresentation} from "../../../../modules/api-client/generated";
import {isNotEmpty} from '../../../../../_metronic/helpers'
import { AxiosError } from "axios";

export const useGetCustomer = (id: string): UseQueryResult<CustomerRepresentation, unknown> => {  
    
    var key = [`v1/crm/customer/${id}`];
    
    const response = useQuery<CustomerRepresentation,TypeError, CustomerRepresentation>(
        key, 
        () => { 
            return ApiClient.Crm.Customer
            .crmCustomerIdGet(id)
            .then(d => d.data);
        }, 
        { enabled: isNotEmpty(id) }
    )

    if (response.error) {                
        var error = response.error as AxiosError;
        toast.error(error?.message);
        // toast.error(error?.response?.data?.title || 'Onbekende fout!');
    }

    return response;
};

export const useGetCustomerCard = (id: string): UseQueryResult<CustomerListView, unknown> => {

    var key = [`v1/crm/customer/card/${id}`];

    const response = useQuery<CustomerListView,TypeError, CustomerListView>(
        key,
        () => {
            return ApiClient.Crm.Customer
                .crmCustomerIdCardGet(id)
                .then(d => d.data);
        },
        { enabled: isNotEmpty(id) }
    )

    if (response.error) {
        var error = response.error as AxiosError;
        toast.error(error?.message);
    }

    return response;
};


export const BackofficeRoutePath = {
    base: '/b',

    dashboard: '/b/dashboard',
    masterdata: '/b/stamgegevens/',
    import: '/b/import/',
    crm: '/b/crm/',
    sm: '/b/sm/',
    fm: '/b/fm/',
    pm: '/b/pm/',
    pim: '/b/pim/',
    core: '/b/core/',
    mgt: '/b/mgt/',
    um: '/b/um/',
    em: '/b/em/',
    test: '/b/test/',
    admin: '/b/admin/',

    relative: (path: string): string => {
        return path.slice(BackofficeRoutePath.base.length);
    }
};
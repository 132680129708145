import React, {FC, ReactElement, useState} from 'react'
import {KTSVG} from '../../../../../../../../_metronic/helpers'
import {
    OrderLineRepresentation,
    OrderLineType,
    OrderRepresentation
} from "../../../../../../../modules/api-client/generated";
import {OrderLineTable} from '../../../edit-order/components/OrderLineTable';
import {EditOrderModal} from '../../../edit-order/components/EditOrderModal';
import {Link} from 'react-router-dom';
import {SmRoutePath} from '../../../../SmRoutes';
import {CustomerRoutePath} from "../../../../../../../customer-portal/routing/RoutePath";
import {useEkosietAuth0} from "../../../../../../../modules/auth0/core/useEkosietAuth0";

type Props = {
    order: OrderRepresentation
}
const ConfigureSills: FC<Props> = ({order}) => {

    const [showEditModal, setShowEditModal] = useState<boolean>(false)
    const [modalFullscreen, setModalFullscreen] = useState<true | string | 'sm-down' | 'md-down' | 'lg-down' | 'xl-down' | 'xxl-down'>('lg-down')
    const [modalTitle, setModalTitle] = useState<string | undefined | null>(null);
    const [modalComponent, setModalComponent] = useState<ReactElement | undefined | null>(null);

    const {isKlant} = useEkosietAuth0();

    const lines = order?.lines?.filter((l: OrderLineRepresentation) => l.type === OrderLineType.ConfigurationLine);

    return (<>

            <EditOrderModal
                show={showEditModal}
                handleClose={() => setShowEditModal(false)}
                title={modalTitle}
                size={'lg'}
                fullscreen={modalFullscreen}
                component={modalComponent}
            />

            <div className='w-100'>
                <div className='pb-10 pb-lg-15'>
                    <h2 className='fw-bolder text-dark'>Dorpels</h2>
                    <div className='text-gray-400 fw-bold fs-6'>
                        Configureer uw dorpel(s).

                    </div>
                    <div className='my-10 fv-row'>
                        {lines && lines.length > 0 ?
                            <>
                                <OrderLineTable
                                    orderId={order.id}
                                    customerId={order.customer.id}
                                    pricelistId={order.financial.priceList.id}
                                    catalogId={order.catalog.id}
                                    lines={lines}
                                    totalPriceNet={0}
                                    productionStatus={order.production.status}
                                    totalPriceGross={0}
                                    projectDiscount={0}
                                    status={order.status}
                                    setModalTitle={setModalTitle}
                                    setModalComponent={setModalComponent}
                                    setModalFullscreen={setModalFullscreen}
                                    setShowEditModal={setShowEditModal}
                                    isOrderEdit={false}
                                />
                            </>
                            :
                            <></>
                        }
                    </div>

                    {isKlant ?
                        <Link
                            to={CustomerRoutePath.orderAddWithIdConfiguratorLink({
                                orderId: order.id,
                                customerId: order.customer.id,
                                pricelistId: order.financial.priceList.id,
                            })}
                            className='btn btn-primary btn-small'>
                            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2'/>
                            Dorpel toevoegen
                        </Link>
                        :
                        <Link
                            to={SmRoutePath.orderAddWithIdConfiguratorLink({
                                orderId: order.id,
                                customerId: order.customer.id,
                                pricelistId: order.financial.priceList.id
                            })}
                            type={'button'}
                            className='btn btn-primary btn-small'>
                            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2'/>
                            Dorpel toevoegen
                        </Link>
                    }
                </div>
            </div>
        </>
    )
}

export {ConfigureSills}

import React from 'react';
import {useQuery} from '@tanstack/react-query';
import ApiClient from '../../../../modules/api-client/ApiClient';
import {
    EmployeeProductionStatRow, ProductionOperationRepresentation,
} from '../../../../modules/api-client/generated';
import Loading from '../../../../shared/components/Loading';
import {Doughnut} from "react-chartjs-2";
import {Duration} from "luxon";
import {useParams} from "react-router-dom";
import {useProductionOperations} from "../hooks/use-list-production-operations";

interface DailyOperationProps {
    fromDate: string;
    toDate: string;
    showCount: boolean;
}

export const DailyOperationDoughnut: React.FC<DailyOperationProps> = ({fromDate, toDate, showCount}) => {
    const { employeeId } = useParams<{ employeeId: string }>();
    const {
        isInitialLoading,
        data
    } = useQuery<EmployeeProductionStatRow[]>(
        ['employeeProductionStatRow', fromDate, toDate, employeeId],
        () => ApiClient.Pm.Reporting.employeeProductionStats(employeeId!, fromDate, toDate).then((res) => res.data)
    );

    const {
        isInitialLoading: isInitialLoadingProductionOperations,
        data: productionOperations
    } = useProductionOperations();
    const getOperationTotal = (operationCode: string) => {
        const total =
        data!.reduce((sum, stat) => {
            return sum + (stat.productionByOperation[startWithLowerCase(operationCode)] || 0);
        }, 0);
        if(showCount) {
            return total;
        }
        return (total * getDurationInSeconds(operationCode)) / 3600.0;
    }


    const startWithLowerCase = (str : string) => {
        return str.charAt(0).toLowerCase() + str.slice(1);
    }

    const getDurationInSeconds = (operactionCode : string) => {
        const isoDurationString = productionOperations!.find(po => po.code === operactionCode)!.averageDuration;
        return Duration.fromISOTime(isoDurationString).as('seconds');
    }

    const getTitle = () => {
        if(showCount) {
            return 'Aantal uitgevoerde handelingen';
        }
        return 'Totaal aantal uren';
    }

    if (!data || !productionOperations) return <Loading/>
    if (isInitialLoading || isInitialLoadingProductionOperations) return <Loading/>

    const options:any = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'right' as const,
            },
            title: {
                display: true,
                text: getTitle(),
            },
        },
    };

    const getColor = (operation : ProductionOperationRepresentation) => {
        if(operation.color) {
            return operation.color;
        }
        return '#70ab2a';
    }

    const graphData = {
        labels: productionOperations.map((po) => po.title),
        datasets: [{
            tension: 0.3,
            label: 'Operatie',
            borderColor: '#fff',
            borderWidth: 2,
            backgroundColor: productionOperations.map((po) => getColor(po)),
            pointRadius: 3,
            pointHoverRadius: 5,
            data: productionOperations.map((po) => getOperationTotal(po.code))
        }]
    };

    return (
        <div className="chart-container position-relative" style={{height: '40vh'}}>
            <Doughnut options={options} data={graphData}/>
        </div>

    );
};
import { FC } from 'react'
import * as Yup from 'yup'
import { Form, Formik, FormikHelpers, } from 'formik'
import {
    PutQuoteExpiryDateRepresentation,
} from '../../../../../../../modules/api-client/generated'
import { usePutQuoteExpiryDate } from "../../../hooks/use-put-quote-expiry-date";
import moment from 'moment';
import { DateField } from '../../../../../../../shared/components/date/DateField';

type Props = {
    id: string
    expiryDate?: Date | null,
    handleClose: () => void,
}

type FormValues = Omit<PutQuoteExpiryDateRepresentation, 'expiryDate'> & {
                        expiryDate: Date | undefined | null;
                    };

const PutQuoteExpiryDateForm: FC<Props> = ({ id, expiryDate, handleClose }) => {

    const mutation = usePutQuoteExpiryDate(id);

    const initialValues: FormValues = {
        expiryDate: expiryDate ? expiryDate : new Date()
    }

    const schema = {
        expiryDate: Yup.date().required("Geldig tot datum is verplicht."),
    }

    const formSchema = Yup.object().shape(schema);

    const onSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
        return new Promise<void>((resolve, reject) => {
            
            let payload : PutQuoteExpiryDateRepresentation = {
                ...values,
                expiryDate: values.expiryDate ? moment(values.expiryDate).format(moment.HTML5_FMT.DATE) : null
            }

            mutation.mutate(payload, {
                onSuccess: (model) => {
                    resolve();
                    handleClose()
                },
                onError: () => {
                    reject();
                }
            });

        }).finally(() => setSubmitting(false));
    };


    return (
        <>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={formSchema}>
                {({
                    values,
                    handleChange,
                    handleSubmit,
                    getFieldProps,
                    isSubmitting,
                }) => (
                    <Form>
                        <DateField fieldName='expiryDate' />

                        {/* begin::Actions */}
                        <div className='text-center pt-5'>
                            <button
                                type='reset'
                                className='btn btn-light me-3'
                                data-kt-customer-modal-action='cancel'
                                data-dismiss="modal"
                                onClick={() => handleClose()}
                                disabled={isSubmitting}
                            >
                                Annuleren
                            </button>

                            <button
                                type='submit'
                                className='btn btn-primary'
                                data-kt-customer-modal-action='submit'
                                disabled={isSubmitting}
                            >
                                <span className='indicator-label'>Opslaan
                                </span>
                                {isSubmitting && (
                                    <span className='indicator-progress'>
                                        Even wachten a.u.b
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                        {/* end::Actions */}

                    </Form>
                )}
            </Formik>
        </>
    )
}

export { PutQuoteExpiryDateForm }

import React, {useEffect, useState} from "react";
import {PageTitle} from "../../../../../_metronic/layout/core";
import BarcodeReader from "react-barcode-reader";
import {useProductionItemGetByBarCode} from "../hooks/use-get-production-item";
import {toast} from "react-hot-toast";
import {useNavigate, useParams} from "react-router-dom";
import {PmRoutePath} from "../PmRoutes";
import {printLabel, useDymoCheckService, useDymoFetchPrinters} from "../../../../shared/utils/react-dymo";
import {WS_SVC_HOST, WS_SVC_HOST_LEGACY} from "../../../../shared/utils/react-dymo/constants";
import {generateDymoLabel} from "../../../components/LabelPrinter";
import {ProductionItemAfmelden} from "../components/ProductionItemAfmelden";

const AfmeldenLanding: React.FC = () => {

    const statusDymoService = useDymoCheckService();

    const { statusFetchPrinters, printers } = useDymoFetchPrinters(statusDymoService);

    const [selectedPrinter, setSelectedPrinter] = useState<string | null>(null);
    const [printing, setPrinting] = useState<boolean|undefined>();

    useEffect(()=>{
        if(statusFetchPrinters === "success"){
            setSelectedPrinter(printers[0].name);
        }
    },[printers])

    async function handlePrintSingleLabel(labelXml: string) {
        setPrinting(true);
        try {
            const response = await printLabel(selectedPrinter || "", labelXml, "");
            console.info(response);
        } catch (error) {
            console.error(error);
        }
        setPrinting(false);
    }

    if(statusDymoService === "success"){
        console.log('Dymo Connect web service gevonden op host '+{WS_SVC_HOST}+' of '+{WS_SVC_HOST_LEGACY}+'.')
    }
    if(statusDymoService === "error"){
        console.log('Error: Dymo Connect webservice niet gevonden!')
    }
    const {code} = useParams<{ code: string |undefined}>()
    const [barCode, setBarCode] = useState<string | undefined>(code);
    const {data: pi, isError, isLoading} = useProductionItemGetByBarCode(barCode);
    const nagivate = useNavigate();

    const handleScan = async (code: string) => {
        var reg = /^\d+$/;
        if (!reg.test(code) || code.length !== 9) {
            handleScanError('Scan een EDS barcode!')
            return;
        }
        if (code.length === 9) {
            handleEdsBarCodeScan(code);
            return;
        }
        handleScanError('Scan een EDS barcode!')
        return;
    }

    const handleEdsBarCodeScan = async (code: string) => {
        setBarCode(code);
    }

    const handleScanError = (err: string) => {
        toast.error(
            `Scan error: '${err}'`,
            {
                duration: 3000
            }
        );
    }

    useEffect(() => {
        if(pi) {

        handlePrintSingleLabel(generateDymoLabel(pi));
        nagivate(PmRoutePath.link(PmRoutePath.afmeldenLink(pi.id)));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pi]);


    return (
        <>
            <PageTitle>
               Dorpel afmelden
            </PageTitle>

            <BarcodeReader
                onError={handleScanError}
                onScan={handleScan}
            />
            {!(pi) &&
                <>Scan een EDS barcode om een dorpel af te melden. <br/><br/></>
            }
            {(isError) &&
                <>
                    Fout bij het ophalen van gegevens voor barcode {barCode}
                </>
            }
            <ProductionItemAfmelden scope={[`Status:Started`]} />

        </>
    )
}

export default AfmeldenLanding

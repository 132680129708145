import {FieldProps} from 'formik';
import Select, {components, GroupBase, MultiValue, OptionProps, SingleValue} from 'react-select';

export interface SelectOption {
	label: string;
	value: string | number;
}

export interface SelectIconOption {
	label: string;
	value: string | number;
	imageUrl?: string | null;
}

export type OptionsType<OptionType> = OptionType[];
export type ValueType<OptionType> = OptionType | OptionsType<OptionType> | null | undefined;

const {Option} = components;

export function IconOption<T extends {value: string | number; label: string; imageUrl: string}>(props: OptionProps<T>) {
	const {label, imageUrl} = props.data;
	return (
		<Option {...props}>
			<div>
				{imageUrl && <img style={{height: '22px', width: '36px'}} src={imageUrl} alt="" />}
				<span className="ms-3">{label}</span>
			</div>
		</Option>
	);
}

interface CustomSelectProps<TOption> extends FieldProps {
	options: OptionsType<TOption>;
	isMulti?: boolean;
	className?: string;
	isClearable?: boolean;
	placeholder?: string;
	required?: boolean;
	disabled?: boolean;
	optionComponent?: React.ComponentType<OptionProps<TOption, boolean, GroupBase<TOption>>> | undefined;
}

export function CustomSelect<T extends {value: string | number; label: string}>(props: CustomSelectProps<T>) {
	const {className, placeholder, field, form, options, optionComponent, isMulti = false, isClearable = true, required = false, disabled = false} = props;

	const onSelectedChange = async <T extends {value: string | number; label: string}>(option: MultiValue<T> | SingleValue<T>) => {		
		await form.setFieldValue(field.name, isMulti ? (option as T[])?.map((item: T) => item.value) : (option as T)?.value);
	};

	const getValue = () => {
		if (options) {

			if  (isMulti) {
				return options?.filter((option: any) => field.value?.indexOf(option?.value) >= 0);
			} else {
				return options?.find((option) => option?.value === field.value) ?? '';				
			}			
		} else {
			return isMulti ? [] : ('' as any);
		}
	};

	return (
		<Select
			className={className}
			name={field.name}
			isDisabled={disabled}
			isClearable={isClearable}
			components={optionComponent ? {Option: optionComponent} : undefined}
			value={getValue()}
			onChange={onSelectedChange}
			placeholder={placeholder}
			options={options}
			isMulti={isMulti}
			required={required}
		/>
	);
}

export default CustomSelect;

import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { toast } from "react-hot-toast"
import ApiClient from "../../../../../modules/api-client/ApiClient";
import { InvoiceListViewArrayODataValue } from "../../../../../modules/api-client/generated";
import { AxiosError } from "axios";

export const useListInvoicesOdata = ($top?: number | undefined, $skip?: number | undefined, $orderBy?: string, $filter?: string, $count: boolean | undefined = false, enabled: boolean | undefined = true):
    UseQueryResult<InvoiceListViewArrayODataValue, unknown> => {

    if ($filter === "") {
        $filter = undefined
    }

    const key = 'pmInvoicesListOdataGet';

    const response = useQuery<InvoiceListViewArrayODataValue,TypeError,InvoiceListViewArrayODataValue>(
        [key, $filter, $top, $skip, $count, $orderBy], () => {
            return ApiClient.Fm.Invoice
                .pmInvoicesListOdataGet($top, $skip, $orderBy, $filter, $count, undefined, undefined)
                .then(d => d.data);
        },
        { enabled: enabled }
    )

    if (response.error) {
        var error = response.error as AxiosError;
        toast.error(error?.message);
        // toast.error(error?.response?.data?.title || 'Onbekende fout!');
    }

    return response;
};

import React from 'react';
import {Field} from 'formik';
import {useQuery} from '@tanstack/react-query';
import ApiClient from '../../../../modules/api-client/ApiClient';
import CustomSelect, {SelectOption, OptionsType} from '../../../../shared/components/CustomSelect';
import {FieldProps} from 'formik/dist/Field';
import {CompartmentLayoutType} from '../../../../modules/api-client/generated';
import {QueryKey} from "@tanstack/query-core/src/types";


export type CompartmentLayoutTypePickerOptions = {
    required?: boolean;
    disabled?: boolean;
    isMulti?: boolean;
    optionFilter?: (compartmentLayoutType: CompartmentLayoutType) => boolean;
    query?: CompartmentLayoutTypePickerQuery;
}

export type CompartmentLayoutTypePickerQuery = {
    fn: () => Promise<CompartmentLayoutType[]>;
    key: QueryKey;
}

export type CompartmentLayoutTypePickerFieldProps = {
    name: string;
    multi?: boolean;
    required?: boolean;
    sillId?: string;
}
export const HefSchuifSchemaPickerField: React.FC<CompartmentLayoutTypePickerFieldProps> = ({
                                                                                                      name,
                                                                                                      multi,
                                                                                                      sillId,
                                                                                                      required,
                                                                                                  }) => {

    let options: OptionsType<SelectOption> = [];

    const {data: list} = useQuery<CompartmentLayoutType[]>(['listHefSchuifSchema'],
        () => ApiClient.Pim.CompartmentLayoutType.listHefSchuifSchema(sillId).then((res) => res.data));

    if (list) {
        options = list.map((item) => {
            return {value: item.id!, label: item.name!};
        });
    }

    return (
        <>
            <Field
                className='custom-select'
                name={name}
                options={options}
                component={
                    CustomSelect
                }
                placeholder={multi ? 'Selecteer hefschuif schema\'s...' : 'Selecteer een schema'}
                isClearable={!required}
                isMulti={multi}
            />
        </>
    );
};
import React, {useEffect, useMemo, useState} from "react";
import {PageTitle} from "../../../../../_metronic/layout/core";


import {EkoCard, EkoCardBody} from "../../../../shared/components/card";

import {usePutBatchFinished} from "../hooks/use-put-batch-finished";
import {usePutBatchCancelled} from "../hooks/use-put-batch-cancelled";
import {FilterContextProvider, useEkosietFilter} from "../../../../shared/context/FilterContext";
import {useListProductionBatchesOdata} from "../hooks/use-list-production-batches-odata";
import {ProductionBatchStatusCodes, ProductionBatchTypes} from "../../../../modules/api-client/generated";
import ProductionBatchStatus from "../../../../shared/components/ProductionBatchStatus";
import {BmhDownloadButton} from "../components/BmhDownloadButton";
import ProductionBatchType from "../../../../shared/components/ProductionBatchType";
import {Link, useSearchParams} from "react-router-dom";
import {PmRoutePath} from "../PmRoutes";
import {usePutBatchStarted} from "../hooks/use-put-batch-start";
import {usePutBatchAccepted} from "../hooks/use-put-batch-accepted";
import UrgentBadge from "../../../../shared/components/UrgentBadge";
import {DateFromIso} from "../../../../shared/components/date";
import {EkoTable, TableHeader} from "../../../../shared/components/table";
import {OrderCodeFilter} from "../../sm/orders/components/OrderCodeFilter";
import {BatchCodeFilter} from "../components/BatchCodeFilter";
import {Pagination} from "react-bootstrap";

const ProductionBatchList: React.FC = () => {

    const [searchParams, setSearchParams] = useSearchParams()
    const [pageSize] = useState(150);
    const [currentPage, setPage] = useState(1);
    const [orderCount, setOrdersCount] = useState(0);
    const [pageCount, setPageCount] = useState(1);
    const [orderBy, setOrderBy] = useState('draftedDate desc');
    const skip = (currentPage - 1) * pageSize;
    const { filterQuery, filters,containsFilter,setFilters } = useEkosietFilter();

    const {
        data: list,
    } = useListProductionBatchesOdata(pageSize, skip, orderBy, filterQuery, true);


    useEffect(() => {
        const page = searchParams.get('page');
        const pageParamNumber = parseInt(page || '1', 10); // comes in as a string, convert to int

        if (pageParamNumber > 0 && pageParamNumber <= pageCount) {
            setPage(pageParamNumber)
        }
    }, [pageCount, searchParams]);

    useEffect(() => {
        if (list && list['@odata.count']) {
            setOrdersCount(list['@odata.count'] as number)
        }
    }, [list]);

    useEffect(() => {
        if (orderCount > 0) {
            setPageCount(Math.ceil(orderCount / pageSize))
        }
    }, [orderCount, pageSize]);

    const paginationItems = useMemo(() => {
        let items = []
        for (let number = 1; number <= pageCount; number++) {
            items.push(
                <Pagination.Item key={number} active={number === currentPage} onClick={() => { setSearchParams(`?${new URLSearchParams({ page: number.toString() })}`) }} >
                    {number}
                </Pagination.Item>,
            );
        }
        return items
    }, [pageCount, currentPage, setSearchParams]);



    const batchFinished = usePutBatchFinished()
    const batchStarted = usePutBatchStarted()
    const batchCancelled = usePutBatchCancelled()
    const batchAccepted = usePutBatchAccepted()


    const accept = (batchId: string) => {
        return new Promise<void>(() => {
            batchAccepted.mutate(batchId, {});
        }).finally();
    }

    const start = (batchId: string) => {
        return new Promise<void>(() => {
            batchStarted.mutate(batchId, {});
        }).finally();
    }
    const finish = (batchId: string) => {
        return new Promise<void>(() => {
            batchFinished.mutate(batchId, {});
        }).finally();
    }
    const cancel = (batchId: string) => {
        return new Promise<void>(() => {
            batchCancelled.mutate(batchId, {});
        }).finally();
    }

    return (
        <>
            <PageTitle>Productie batches</PageTitle>
            <FilterContextProvider>

            <EkoCard>
                <EkoCardBody>

                    <BatchCodeFilter fieldName='Code'/>
                    <EkoTable>
                        <TableHeader>
                            <th className='min-w-10px'>Batch</th>
                            <th className='min-w-10px'>Spoed</th>
                            <th className='min-w-50px'>Productietype</th>
                            <th className='min-w-50px text-center'>Status</th>
                            <th className='min-w-50px'>Aantal items</th>
                            <th className='min-w-50px'></th>
                            <th className='min-w-50px'></th>

                            <th className='min-w-50px '></th>
                        </TableHeader>
                        <tbody>
                        {list?.value.map((batch) => {
                            return (
                                <tr key={batch.id}>
                                    <td>
                                        <Link to={PmRoutePath.link(PmRoutePath.productionBatchLink(batch.id!))}
                                              className='fs-6'>
                                            <span className={'h5'}>{batch.title} {batch.code}</span>
                                        </Link>
                                        <br/><span className={'text-muted'}><DateFromIso
                                        isoDateString={batch.draftedDate}/></span>
                                    </td>
                                    <td className='text-center'>
                                        <UrgentBadge value={batch.urgent}/>
                                    </td>
                                    <td><ProductionBatchType type={batch.type}/></td>
                                    <td className='text-center'>
                                        <ProductionBatchStatus status={batch.status}/>
                                    </td>
                                    <td>
                                        {batch.type == ProductionBatchTypes.Latten &&
                                            <span className={'me-1'}>
                                                {batch.lattenCount} latten
                                            </span>
                                        }
                                        {batch.type == ProductionBatchTypes.Neuten &&
                                            <span className={'me-1'}>
                                                {batch.neutCount} neuten
                                            </span>
                                        }
                                        {/*{batch.type == ProductionBatchTypes.Dorpel &&*/}
                                        <span>{batch.itemsCount} dorpels</span>
                                        {/*}*/}
                                    </td>
                                    <td>
                                        {batch.remark &&
                                            <span>
                                                &nbsp;| {batch.remark}
                                            </span>
                                        }
                                    </td>
                                    <td className='text-end'>
                                        {batch.status === ProductionBatchStatusCodes.Drafted &&
                                            <button className={'btn btn-light btn-sm me-3'}
                                                    onClick={() => accept(batch.id)}>Accepteren</button>
                                        }
                                        {batch.status === ProductionBatchStatusCodes.Accepted &&
                                            <button className={'btn btn-light btn-sm me-3'}
                                                    onClick={() => window.confirm('Weet je het zeker?') && start(batch.id)}>Starten</button>
                                        }
                                        {batch.status !== ProductionBatchStatusCodes.Accepted && batch.status !== ProductionBatchStatusCodes.Drafted && batch.status !== ProductionBatchStatusCodes.Cancelled && batch.status !== ProductionBatchStatusCodes.Finished && batch.status !== ProductionBatchStatusCodes.Failed &&
                                            <button className={'btn btn-light btn-sm me-3'}
                                                    onClick={() => window.confirm('Weet je het zeker?') && finish(batch.id)}>Gereed
                                                melden</button>
                                        }
                                        {batch.status !== ProductionBatchStatusCodes.Cancelled && batch.status !== ProductionBatchStatusCodes.Finished && batch.status !== ProductionBatchStatusCodes.Failed &&
                                            <button className={'btn btn-light btn-sm me-3'}
                                                    onClick={() => window.confirm('Weet je het zeker?') && cancel(batch.id)}>Annuleren</button>
                                        }
                                        <BmhDownloadButton filename={`${batch.title}-${batch.code}-${batch.type}.xml`}
                                                           batch={batch.id}/>

                                    </td>
                                </tr>
                            )
                        })}

                        </tbody>
                    </EkoTable>

                    <div>
                        <span
                            className={`text-muted`}>{list?.value.length}/{list?.["@odata.count"]} orders</span>
                        <Pagination className='justify-content-start mt-3'>{paginationItems}</Pagination>
                    </div>
                </EkoCardBody>
            </EkoCard>
        </FilterContextProvider>

        </>
    )
}

export default ProductionBatchList

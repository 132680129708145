import {Dispatch, FC, SetStateAction} from 'react'
import { useFormikContext} from 'formik'
import {CustomerContactPersonPicker} from '../../../../../../../customer-portal/components/CustomerContactPersonPicker'
import {IAddOrderForm} from '../../types/IAddOrderForm';
import TextField from "../../../../../../../shared/components/TextField";
import {
    ContactPersonRepresentation,
} from "../../../../../../../modules/api-client/generated";

export type Props = {
    setFormValues: Dispatch<SetStateAction<IAddOrderForm>>
}

const SelectContactPerson: FC<Props> = () => {
    const {values,setValues} = useFormikContext<IAddOrderForm>();
    const contactPersonSelected = (contactPerson?: ContactPersonRepresentation) => {
        if (contactPerson) {
            setValues({
                ...values,
                contactPerson: {
                    email: contactPerson.email || '',
                    firstName: contactPerson.firstName || '',
                    lastName: contactPerson.lastName || '',
                    telephone: contactPerson.telephone || '',
                }
            });
        } else {
            setValues({
                ...values,
                contactPerson: {
                    email: '',
                    firstName: '',
                    lastName: '',
                    telephone: '',
                }
            });
        }
    }

    return (
        <div className='w-100'>
            <div className='mb-5 mb-lg-10'>
                <h2 className='fw-bolder text-dark'>Contactpersoon</h2>
                <div className='text-gray-400 fw-bold fs-6'>
                  Contactpersoon voor deze bestelling.
                </div>
            </div>

            <div className={'row'}>
                <div className={'col'}>
                    <div className='row mb-4'>
                        <div className='col-4'>
                            <label className='form-label required'>Bestaande contactpersoon</label>
                        </div>
                        <div className='col'>
                            <CustomerContactPersonPicker fieldName='orderContactPersonPickerId' onSelect={contactPersonSelected} selectedEmail={values.contactPerson?.email} customerId={values.customerId}/>
                        </div>
                    </div>
                    <div className='row mb-4'>
                        <div className='col-4'>
                            <label className='form-label required'>Naam</label>
                        </div>
                        <div className='col'>
                            <TextField
                                name='contactPerson.firstName'
                                className='form-control form-control-lg '
                            />
                        </div>
                        <div className='col'>
                            <TextField
                                name='contactPerson.lastName'
                                className='form-control form-control-lg '
                            />
                        </div>
                    </div>
                    <div className='row mb-4'>
                        <div className='col-4'>
                            <label className='form-label required'>E-mail</label>
                        </div>
                        <div className='col'>
                            <TextField
                                name='contactPerson.email'
                                className='form-control form-control-lg '

                            />
                        </div>
                    </div>
                    <div className='row mb-4'>
                        <div className='col-4'>
                            <label className='form-label required'>Telefoonnummer</label>
                        </div>
                        <div className='col'>
                            <TextField
                                name='contactPerson.telephone'
                                className='form-control form-control-lg '
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {SelectContactPerson}


import React from "react";
import { ErrorMessage, Field } from "formik";
import CustomSelect, { OptionsType, SelectOption } from "../../../../shared/components/CustomSelect";
import {
    useListSillProductsQuery,
    useListStandardProductsByCatalogIdAndCategoryQuery, useListStandardProductsByCatalogIdQuery,
} from "./hooks";
import {StandardProduct} from "../../../../modules/api-client/generated";


type Props = {
    name: string;
    catalogId?: string,
    category?: string,
    multi?: boolean;
    required?: boolean;
}
export interface GroupedOption {
    readonly code: string;
    readonly label: string;
    readonly options: SelectOption[]
}


export const SalesProductPicker: React.FC<Props> = (
    {
        name,
        catalogId,
        category,
        multi,
        required = false
    }
) => {

    let groupedOptions:  GroupedOption[] = [];
// TODO - this is a list of public categories, it should be moved to a configuration file
    const listPublicCategories = ['afwerking-en-dichting','bevestigingsmiddelen',
        'beschermkappen','pe-blokken','reparatie-en-service','presentatie-en-demomodellen','transport-artikelen']

    const {
        data: productList,
    } = useListStandardProductsByCatalogIdQuery(catalogId)

    if (productList) {
        groupedOptions = productList.filter((p)=>p.active).reduce<GroupedOption[]>((grouped, product) => {
            product.categories!.forEach(category => {
                let group = grouped.find(g => g.code === category.code);
                if (!group) {
                    group = { code: category.code, label: category.name, options: [] };
                    grouped.push(group);
                }
                group.options.push({ value: product.id!, label: product.title! });
            });
            return grouped.filter(option => listPublicCategories.includes(option.code))
                .sort((a, b) => listPublicCategories.indexOf(a.code) - listPublicCategories.indexOf(b.code));
        }, []);
        groupedOptions.forEach(group => {
            group.options.sort((a, b) => a.label.localeCompare(b.label));
        });
    }
    return (
        <>
            <Field
                className="custom-select"
                name={name}
                options={groupedOptions}
                component={CustomSelect}
                placeholder={multi ? "Kies een of meerdere producten..." : "Kies een product"}
                isMulti={multi ?? false}
                isClearable={!required}
            />

            <div className='text-danger mt-2'>
                <ErrorMessage name={name} />
            </div>
        </>
    )
}
export const StandardProductPicker: React.FC<Props> = (
    {
        name,
        catalogId,
        category,
        multi,
        required = false
    }
) => {

    let options: OptionsType<SelectOption> = [];

    const {
        data: standardProductList,
    } = useListStandardProductsByCatalogIdAndCategoryQuery(catalogId,category)

    if (standardProductList) {
        options = standardProductList.filter((p)=>p.active).map((item) => {
            return { value: item.id!, label: item.title! }
        })
    }

    return (
        <>
            <Field
                className="custom-select"
                name={name}
                options={options}
                component={CustomSelect}
                placeholder={multi ? "Kies een of meerdere producten..." : "Kies een product"}
                isMulti={multi ?? false}
                isClearable={!required}
            />

            <div className='text-danger mt-2'>
                <ErrorMessage name={name} />
            </div>
        </>
    )
}
export const SillProductsPicker: React.FC<Props> = (
    {
        name,
        multi,
        required = false
    }
) => {

    let options: OptionsType<SelectOption> = [];

    const {
        data: standardProductList,
    } = useListSillProductsQuery()

    if (standardProductList) {
        options = standardProductList.map((item) => {
            return { value: item.id!, label: item.title! }
        })
    }

    return (
        <>
            <Field
                className="custom-select"
                name={name}
                options={options}
                component={CustomSelect}
                placeholder={multi ? "Kies een of meerdere producten..." : "Kies een product"}
                isMulti={multi ?? false}
                isClearable={!required}
            />

            <div className='text-danger mt-2'>
                <ErrorMessage name={name} />
            </div>
        </>
    )
}
import React, {useContext, useEffect, useId, useState} from 'react';
import {Form, Formik, useFormikContext} from 'formik';
import {
    CompartmentLayoutTypeCode,
    ConfiguratorCompartmentLayout,
    ConfiguratorCompartmentLayoutHefSchuif,
    ConfiguratorCompartmentProfiles,
    ConfiguratorGlazingBar,
    ConfiguratorGlazingProfile,
    ConfiguratorNeutPosition,
    ConfiguratorSillCompartmentLayoutOption,
    ConfiguratorSluitpotBeslag,
    ConfiguratorStopProfile, HefSchuifSchemaCode, SetDefaultCompartmentProfileRequest,
    SillConfigurationCompartment,
    SillSluitpotArea,
    SillType,
} from '../../../modules/api-client/generated';
import {StandardCompartmentEditorFields} from "./StandardCompartmentEditorFields";
import {HefSchuifCompartmentEditorFields} from "./HefSchuifCompartmentEditorFields";
import {
    CompartmentEditorContext, CompartmentEditorContextValue
} from "./CompartmentEditorContextProvider";
import {useConfigurator} from "./hooks/use-configurator";
import ApiClient from "../../../modules/api-client/ApiClient";
import * as Yup from "yup";
import {ValidationError} from "yup";

export interface CompartmentEditorOptions {
    sillType: SillType;
    sillId: string;
    sillWidth: number;
    compartmentIndex: number;
    neutPosition: ConfiguratorNeutPosition;
    areas?: {
        [key: string]: SillSluitpotArea
    } | null;
    compartmentLayoutOptions: ConfiguratorSillCompartmentLayoutOption[];
    compartmentLayouts: ConfiguratorCompartmentLayout[];
    defaultCompartmentLayoutTypeCode: CompartmentLayoutTypeCode;
    glazingBars: {
        [key: string]: ConfiguratorGlazingBar;
    };
    glazingProfiles: {
        [key: string]: ConfiguratorGlazingProfile
    };
    stopProfiles: {
        [key: string]: ConfiguratorStopProfile
    };
    stopRabbetDepths: Array<number>;
    sluitpotHardware: {
        [key: string]: ConfiguratorSluitpotBeslag
    };
    value: SillConfigurationCompartment;
    onChange: (value: SillConfigurationCompartment) => void;
}

export interface CompartmentEditorProps extends CompartmentEditorOptions {
    children?: React.ReactNode | ((config: CompartmentEditorConfig) => React.ReactNode);
}

export interface CompartmentEditorConfig {

    submit: () => Promise<void>;
    content: React.ReactNode;
    isSubmitting: boolean;
    isInitializing: boolean;
    canSetDefaultCompartmentProfile: boolean;
    setDefaultProfile: () => Promise<void>;
    clearDefaultProfile: () => Promise<void>;
}

const createValidationSchema = () => {

    const dagmaat = Yup.ref<number>('$dagmaat');

    const fixedDoorBarLengthSchema = Yup.number()
        .max(dagmaat, 'Lengte mag niet groter zijn dan de dagmaat');

    const HefSchuifLayout = Yup.object({
        schemaCode: Yup.string().required(),

        slidingDirection: Yup.string()
            .when('schemaCode', {
                is: (s: HefSchuifSchemaCode) => s === HefSchuifSchemaCode.A || s === HefSchuifSchemaCode.E,
                then: Yup.string().required()
            }),

        innerDoorPosition: Yup.string()
            .when('schemaCode', {
                is: (schemaCode: string) => schemaCode === HefSchuifSchemaCode.D,
                then: Yup.string().required()
            }),

        fixedDoorBar: Yup
            .object({
                length: Yup.number()
            })
            .when('schemaCode', {
                is: (schemaCode: HefSchuifSchemaCode) => schemaCode === HefSchuifSchemaCode.A || schemaCode === HefSchuifSchemaCode.E,
                then: Yup.object({
                    length: fixedDoorBarLengthSchema
                })
            }),

        fixedDoorBarLeft: Yup
            .object({
                length: Yup.number()
            })
            .when('schemaCode', {
                is: (schemaCode: string) => schemaCode === HefSchuifSchemaCode.C,
                then: Yup.object({
                    length: fixedDoorBarLengthSchema
                })
            }),

        fixedDoorBarRight: Yup
            .object({
                length: Yup.number()
            })
            .when('schemaCode', {
                is: (schemaCode: string) => schemaCode === HefSchuifSchemaCode.C,
                then: Yup.object({
                    length: fixedDoorBarLengthSchema
                })
            }),

    }).test((value, context) => {

        if (value.fixedDoorBarLeft?.length && value.fixedDoorBarRight?.length) {
            if (value.fixedDoorBarLeft.length + value.fixedDoorBarRight.length > context.parent.dagmaat) {

                const msg = 'Vaste deur latten kunnen niet overlappen.';
                return new ValidationError([
                    context.createError({
                        message: msg,
                        path: 'hefSchuifLayout.fixedDoorBarLeft.length'
                    }),
                    context.createError({
                        message: msg,
                        path: 'hefSchuifLayout.fixedDoorBarRight.length'
                    })
                ]);
            }
        }

        return true;
    });

    return Yup.object({
        compartmentLayoutTypeCode: Yup.string().required(),
        dagmaat: Yup.number().required().min(0),
        hefSchuifLayout: Yup.mixed()
            .when('compartmentLayoutTypeCode', {
                is: CompartmentLayoutTypeCode.HefSchuifA,
                then: HefSchuifLayout
            })
    });
}

const ValidationSchema = createValidationSchema();

export const CompartmentEditor: React.FC<CompartmentEditorProps> = (props) => {

    const configurator = useConfigurator();

    const [profilesLoadError, setProfilesLoadError] = useState<string | undefined>(undefined);
    const [isProfilesInitializing, setIsProfilesInitializing] = useState<boolean>(false);
    const [profiles, setProfiles] = useState<ConfiguratorCompartmentProfiles | null>(null);

    const loadProfiles = async (leftId: string, rightId: string): Promise<ConfiguratorCompartmentProfiles> => {
        const response = await ApiClient.Pim.Configurator.getCompartmentProfiles(leftId, rightId, undefined);
        return response.data;
    };

    const onSubmit = (value: SillConfigurationCompartment): void => {
        props.onChange(value);
    }

    return <Formik initialValues={props.value}
                   enableReinitialize={true}
                   onSubmit={onSubmit}
                   validationSchema={ValidationSchema}>

        {({setValues, values}) => {

            const compartmentLayoutTypeId = props.compartmentLayoutOptions.find(x => x.code === values.compartmentLayoutTypeCode)!.id;

            const initializeProfiles = async (): Promise<void> => {

                const profileIds = configurator.getCompartmentProfileIds(values);

                if (profileIds) {

                    setIsProfilesInitializing(true);
                    try {
                        const {left, right} = profileIds;
                        const profiles = await loadProfiles(left, right);
                        setProfiles(profiles);
                    } finally {
                        setIsProfilesInitializing(false);
                    }
                }
            }

            const setProfileId = async (profileIds: { left: string, right: string }): Promise<void> => {
                const profiles = await loadProfiles(profileIds.left, profileIds.right);
                setProfiles(profiles);
            };

            const flipProfiles = () => {

                if (!profiles) return;
                setProfiles({left: profiles.right, right: profiles.left});
            }

            const changeCompartmentLayoutType = async (compartmentLayoutTypeCode: CompartmentLayoutTypeCode) => {

                const compartment = await configurator.changeCompartmentLayoutType(compartmentLayoutTypeCode, values.dagmaat);

                const profileIds = configurator.getCompartmentProfileIds(compartment);

                if (profileIds) {
                    await setProfileId(profileIds);
                }

                await setValues(() => compartment);
            };

            const makeFavoriteProfile = async (profileId: string, name?: string) => {
                await configurator.addFavoriteProfile(compartmentLayoutTypeId, profileId, name);
            };

            const removeFavoriteProfile = async (profileId: string) => {
                await configurator.removeFavoriteProfile(compartmentLayoutTypeId, profileId);
            };

            const renameFavoriteProfile = async (profileId: string, name: string) => {
                await configurator.renameFavoriteProfile(compartmentLayoutTypeId, profileId, name);
            };

            const setDefaultProfile = async () => {

                let defaultProfile: SetDefaultCompartmentProfileRequest;

                switch (values.compartmentLayoutTypeCode) {
                    case CompartmentLayoutTypeCode.HefSchuifA:
                    case CompartmentLayoutTypeCode.HefSchuifD:
                    case CompartmentLayoutTypeCode.HefSchuifC:
                    case CompartmentLayoutTypeCode.HefSchuifE: {

                        if ('profileIdInside' in values.hefSchuifLayout! && 'profileIdOutside' in values.hefSchuifLayout!) {
                            defaultProfile = {
                                profileIds: {
                                    inside: values.hefSchuifLayout.profileIdInside!,
                                    outside: values.hefSchuifLayout.profileIdOutside!
                                }
                            }
                        } else {
                            defaultProfile = {
                                outsideProfileId: values.hefSchuifLayout!.profileIdOutside!,
                            }
                        }
                        break;
                    }
                    default: {
                        defaultProfile = {
                            profileId: values.profileId!
                        }
                    }
                }

                await configurator.setDefaultCompartmentProfile(compartmentLayoutTypeId, defaultProfile);
            };

            const clearDefaultProfile = async () => {
                await configurator.clearDefaultCompartmentProfile(compartmentLayoutTypeId);
            };


            const contextValue: CompartmentEditorContextValue = {
                isProfilesInitializing: isProfilesInitializing,
                profilesLoadError: profilesLoadError,
                profiles: profiles,
                setProfiles: setProfiles,
                flipProfiles: flipProfiles,
                setProfileIds: setProfileId,
                initializeProfiles: initializeProfiles,
                clearProfilesLoadError: () => setProfilesLoadError(undefined),
                changeCompartmentLayoutType,
                searchProfiles: configurator.searchProfiles,
                makeFavoriteProfile,
                removeFavoriteProfile,
                renameFavoriteProfile,
                canSetDefaultCompartmentProfile: configurator.canSetDefaultCompartmentProfile,
                setDefaultProfile,
                clearDefaultProfile
            };

            return (
                <CompartmentEditorContext.Provider value={contextValue}>
                    <CompartmentEditorForm {...props} />
                </CompartmentEditorContext.Provider>
            );
        }}
    </Formik>
};

const CompartmentEditorForm: React.FC<CompartmentEditorProps> = (props) => {

    const {
        isProfilesInitializing,
        canSetDefaultCompartmentProfile,
        setDefaultProfile,
        clearDefaultProfile
    } = useContext(CompartmentEditorContext);

    const {submitForm, isSubmitting} = useFormikContext<SillConfigurationCompartment>();

    const editorContent = <CompartmentEditorContent {...props} />;

    const childNodes =
        typeof props.children === 'undefined'
            ? editorContent
            : typeof props.children === 'function'
                ? props.children?.call(this, {
                    content: editorContent,
                    submit: submitForm,
                    isSubmitting: isSubmitting,
                    isInitializing: isProfilesInitializing,
                    canSetDefaultCompartmentProfile: canSetDefaultCompartmentProfile,
                    setDefaultProfile: setDefaultProfile,
                    clearDefaultProfile: clearDefaultProfile
                })
                :
                props.children;

    return <Form>{childNodes}</Form>;
};

const CompartmentEditorContent: React.FC<CompartmentEditorProps> = (props) => {
    const {
        profilesLoadError,
        profiles,
        initializeProfiles,
        clearProfilesLoadError,
        changeCompartmentLayoutType,
    } = useContext(CompartmentEditorContext);

    const id = useId();

    const {values, handleChange, errors} = useFormikContext<SillConfigurationCompartment>();

    useEffect(() => {
        initializeProfiles().catch(console.error);
    }, []);


    const compartmentLayoutOptions = props.compartmentLayoutOptions.sort((a, b) => {

        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        return 0;
    })

    const compartmentLayout = props.compartmentLayouts.find(x => x.compartmentLayoutTypeCode === values.compartmentLayoutTypeCode)!;
    const compartmentLayoutOption = compartmentLayoutOptions.find(x => x.code === values.compartmentLayoutTypeCode)!;


    return (
        <div className='compartment-editor'>
            {profilesLoadError && (
                <div className='alert alert-danger alert-dismissible' role='alert'>
                    <strong>LET OP</strong> {profilesLoadError}
                    <button type='button' className='btn-close' aria-label='Close'
                            onClick={clearProfilesLoadError}></button>
                </div>
            )}

            <div style={{minHeight: '350px'}}>
                <div className='p-3 d-grid gap-3'>
                    <div className='row mb-3'>
                        <div className='col-8'>
                            <label htmlFor={`${id}_compartmentLayoutTypeCode`} className='form-label'>
                                <small className='text-uppercase text-muted'>Indeling</small>
                            </label>

                            <select
                                id={`${id}_compartmentLayoutTypeCode`}
                                name='compartmentLayoutTypeCode'
                                className='form-select'
                                value={values.compartmentLayoutTypeCode}
                                onChange={(e) => changeCompartmentLayoutType(e.target.value as CompartmentLayoutTypeCode)}>
                                {compartmentLayoutOptions.map((compartmentLayoutType) => {

                                    return <option key={compartmentLayoutType.id}
                                                   value={compartmentLayoutType.code}>
                                        {compartmentLayoutType.name}
                                    </option>;
                                })}
                            </select>
                        </div>
                        <div className='col'>
                            <label htmlFor={`${id}_rabbetWidth`} className='form-label'>
                                <small className='text-uppercase text-muted'>Dagmaat</small>
                            </label>
                            <div className='input-group'>
                                <input
                                    type={'number'}
                                    name={'dagmaat'}
                                    className='form-control text-end'
                                    value={values.dagmaat}
                                    onChange={handleChange}
                                    required
                                    min={0}
                                    step={0.1}
                                    placeholder='Dagmaat in mm'
                                />
                                <div className='input-group-text'>mm</div>
                            </div>
                        </div>
                    </div>

                    {(
                        compartmentLayoutOption.isHefSchuif &&
                        <HefSchuifCompartmentEditorFields
                            sillId={props.sillId}
                            neutPosition={props.neutPosition}
                            profileWidth={props.neutPosition.profileWidth}
                            compartmentLayout={compartmentLayout as ConfiguratorCompartmentLayoutHefSchuif}
                            profiles={profiles}
                        /> ||
                        <StandardCompartmentEditorFields {...props} />)}
                </div>
            </div>
        </div>
    );
};


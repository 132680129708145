import React, {FC} from 'react'
import * as Yup from 'yup'
import {Form, Formik, FormikHelpers} from 'formik'
import {usePutProductionItemRemark} from "../hooks/use-put-production-item-remark";
import {
    PutProductionItemKomoCertifiedRepresentation,
    PutProductionItemRemarkRepresentation, PutProductionItemUrgentRepresentation
} from "../../../../modules/api-client/generated";
import {usePutProductionItemKomoCertified} from "../hooks/use-put-production-item-komo-certified";
import {bool} from "yup";
import {usePutProductionItemUrgent} from "../hooks/use-put-production-item-urgent";

type Props = {
    ids: string[]
    urgent: boolean
    handleClose: () => void,
}

const PutUrgentForm: FC<Props> = ({ids, urgent, handleClose}) => {

    const mutation = usePutProductionItemUrgent();

    const initialValues: PutProductionItemUrgentRepresentation = {
        productionItemIds: ids,
        urgent: urgent ?? '',
    }

    const schema = {
        remark: Yup.string().notRequired().nullable()
    }
    const formSchema = () => Yup.lazy((values) => {
        return Yup.object().shape(schema)
    });

    //
    const onSubmit = (values: PutProductionItemUrgentRepresentation, {setSubmitting}: FormikHelpers<PutProductionItemUrgentRepresentation>) => {

        return new Promise<void>((resolve, reject) => {
            mutation.mutate(values, {
                onSuccess: (model) => {
                    resolve();
                    handleClose()
                },
                onError: () => {
                    reject();
                }
            });

        }).finally(() => setSubmitting(false));
    };


    return (
        <>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={formSchema}>
                {({
                      values,
                      handleChange,
                      handleSubmit,
                      getFieldProps,
                      isSubmitting,
                  }) => (
                    <Form>

                        <div className='row mb-4'>
                            <div className='col-4'>
                                <label className='form-label' htmlFor='type'>Spoed</label>
                            </div>
                            <div className='col-8'>
                                <div className='form-check form-switch'>
                                    <input className='form-check-input' type='checkbox' role='switch'
                                           name='urgent'
                                           value='true'
                                           onChange={handleChange}
                                           checked={values.urgent}
                                           id='active' />
                                    <label className='form-check-label' htmlFor='active'>Spoed?</label>
                                </div>
                            </div>
                        </div>

                        <div className='text-center pt-5'>
                            <button
                                type='reset'
                                className='btn btn-light me-3'
                                data-kt-customer-modal-action='cancel'
                                data-dismiss="modal"
                                onClick={() => handleClose()}
                                disabled={isSubmitting}
                            >
                                Annuleren
                            </button>

                            <button
                                type='submit'
                                className='btn btn-primary'
                                data-kt-customer-modal-action='submit'
                                disabled={isSubmitting}
                            >
                                <span className='indicator-label'>Opslaan
                                </span>
                                {isSubmitting && (
                                    <span className='indicator-progress'>
                                        Even wachten a.u.b
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                        {/* end::Actions */}

                    </Form>
                )}
            </Formik>
        </>
    )
}

export {PutUrgentForm}

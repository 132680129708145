import React from "react";
import {ProductionBatchTypes} from "../../modules/api-client/generated";
export interface Props {
    type?: ProductionBatchTypes
}

const ProductionBatchType: React.FC<Props> = ({type}) => {
        let label;
        let color;
        switch (type) {
            case ProductionBatchTypes.Latten: {
                label = 'Latten';
                color = 'light-danger';
                break;
            }
            case ProductionBatchTypes.Dorpel: {
                label = 'Dorpels';
                color = 'light-warning';
                break;
            }
            case ProductionBatchTypes.Neuten: {
                label = 'Neuten';
                color = 'light-info';
                break;
            }
            case ProductionBatchTypes.NeutenLos: {
                label = 'Losse neuten';
                color = 'light-success';
                break;
            }
            default: {
                label = 'Onbekend'
            }
        }
        return (
            <span className={`p-1 bg-${color} text-inverse-${color}`}>{label}</span>
        )
    }
;

export default ProductionBatchType;

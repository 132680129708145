import {Suspense} from 'react';
import {Outlet} from 'react-router-dom';
import {I18nProvider} from '../_metronic/i18n/i18nProvider';
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core';
import {MasterInit} from '../_metronic/layout/MasterInit';
import {AuthInit} from './auth';
import {Toaster} from 'react-hot-toast';
import './App.scss';
import {ConfirmDialogProvider} from './shared/components/ConfirmDialog';

const App = () => {
	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<I18nProvider>
				<LayoutProvider>
					<Toaster />
					<AuthInit>
						<ConfirmDialogProvider>
							<Outlet />
						</ConfirmDialogProvider>
						<MasterInit />
					</AuthInit>
				</LayoutProvider>
			</I18nProvider>
		</Suspense>
	);
};

export {App};

import React from "react";
import {PageTitle} from "../../../../../_metronic/layout/core";
import {ProductionItemOperations} from "../../../../modules/api-client/generated";
import {ProductionOperation} from "../components/ProductionOperation";
import {stationLookup} from "../models/stations";

const LattenMaken: React.FC = () => {
    var station = stationLookup.get(ProductionItemOperations.LattenGemaakt);
    if (!station) {
        throw new Error(`Station not found`);
    }
    return (
        <>
            <PageTitle
                color={station.color}
                breadcrumbs={[]}
            >
                {station.title}
            </PageTitle>
            <ProductionOperation scope={[`LattenGemaakt:lg-0`,`InLattenBatch:ilb-1`]} operation={ProductionItemOperations.LattenGemaakt} />
        </>
    )
}

export default LattenMaken

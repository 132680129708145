import {useEffect, useRef, useState} from 'react';
import {KTSVG} from '../../../../../../../_metronic/helpers';
import {SelectContactPerson} from './steps/SelectContactPerson';
import {ConfigureSills} from './steps/ConfigureSills';
import {AdditionalProducts} from './steps/AdditionalProducts';
import {ExpeditionDetails} from './steps/ExpeditionDetails';
import {Summary} from './steps/Summary';
import {StepperComponent} from '../../../../../../../_metronic/assets/ts/components';
import {Formik, Form, FormikValues, FormikProps} from 'formik';
import classNames from 'classnames';
import {useNavigate, useParams} from 'react-router-dom';
import {newOrderSchemas} from '../types/Schemas';
import {IAddOrderForm, OrderType, addBusinessDays, initialFormValues} from '../types/IAddOrderForm';
import {useGetOrder} from '../../hooks/use-get-order';
import {SmRoutePath} from '../../../SmRoutes';
import {OrderContactTypes, usePutOrderContact} from '../../hooks/use-put-contact';
import {useGetCustomer} from '../../../../crm/hooks/use-get-customer';
import {useListCustomerContactPersons} from '../../../../crm/hooks/use-list-customer-contact-persons';
import {EventHandlerUtil} from '../../../../../../../_metronic/assets/ts/_utils';
import {noopSubmit, stepSubmitHook} from '../types/StepSubmissionHook';
import {usePutFinancial} from '../../hooks/use-put-financial';
import {usePutExpedition} from '../../hooks/use-put-expedition';
import {PutWizardOrderRemarkRepresentation, PutOrderExpeditionRepresentation, PutOrderFinancialRepresentation, OrderRemarkType} from '../../../../../../modules/api-client/generated';
import moment from 'moment';
import {resetTimeOfDate} from '../../../../../../shared/components/calendar';
import {usePutQuoteIssued} from '../../hooks/use-put-quote-issued';
import {usePutOrderPlaced} from '../../hooks/use-put-order-placed';
import {CustomerRoutePath} from '../../../../../../customer-portal/routing/RoutePath';
import {useEkosietAuth0} from '../../../../../../modules/auth0/core/useEkosietAuth0';
import {ConfirmDialog} from '../../../../../../shared/components/ConfirmDialog';
import {useDeleteOrder} from '../../hooks/use-delete-order';
import {PageTitle} from '../../../../../../../_metronic/layout/core';

const AddOrderWizard = () => {
	const navigate = useNavigate();
	const {id: orderId} = useParams<{id: string}>();
	const {isKlant, user} = useEkosietAuth0();

	if (!orderId) {
		if (isKlant) {
			navigate(CustomerRoutePath.link(CustomerRoutePath.orderStart));
		} else {
			navigate(SmRoutePath.link(SmRoutePath.orderList));
		}
	}

	//todo check status of order, needs to be draft.

	const stepperRef = useRef<HTMLDivElement | null>(null);
	const formRef = useRef<FormikProps<IAddOrderForm> | null>(null);
	const stepper = useRef<StepperComponent | null>(null);
	const [currentSchema, setCurrentSchema] = useState(newOrderSchemas[0]);
	const [formValues, setFormValues] = useState<IAddOrderForm>(initialFormValues);

	const {data: order, isFetched: isOrderFetched} = useGetOrder(orderId, false);

	// if (order && order.status !== 'draft')  {
	//   if(isKlant) {
	//     navigate(CustomerRoutePath.link(CustomerRoutePath.orderLink(order.id)));
	//   } else {
	//     navigate(SmRoutePath.link(SmRoutePath.orderEditLink(order.id)));
	//   }
	// }

	const {data: contactPersons} = useListCustomerContactPersons(formValues.customerId);
	const {data: customer} = useGetCustomer(formValues.customerId);
	const invoiceAddressess = customer?.addresses!?.filter((address) => address.isInvoicingAddress);
	const deliveryAddressess = customer?.addresses!?.filter((address) => address.isDeliveryAddress);

	// data mutations
	const {mutateAsync: mutateOrderContact} = usePutOrderContact(formValues.id, OrderContactTypes.order);
	const {mutateAsync: mutateFinancial} = usePutFinancial(formValues.id);
	const {mutateAsync: mutateExpedition} = usePutExpedition(formValues.id);
	const {mutateAsync: mutateIssueQuote} = usePutQuoteIssued(formValues.id);
	const {mutateAsync: mutatePlaceOrder} = usePutOrderPlaced(formValues.id);
	const deleteOrderMutation = useDeleteOrder();
	const openOrderDeleteDialog = useRef<(show: boolean) => void>();

	// order contact
	const submitContactPerson: stepSubmitHook = async (values: IAddOrderForm, stepIndex: number) => {

		if (values.contactPerson) {
			await mutateOrderContact({
				...values.contactPerson,
				updateExpedition: false,
				updateFinancial: false,
			});
			return true;
		} else {
			return false;
		}
	};

	// financial
	const SubmitFinancial: stepSubmitHook = async (values: IAddOrderForm, stepIndex: number) => {
		if (values.financial && values.financial.contactPerson && values.financial.invoiceAddress) {
			let payload: PutOrderFinancialRepresentation = {
				contactPerson: {
					...values.financial.contactPerson,
				},
				invoiceAddress: {
					...values.financial.invoiceAddress,
					houseNumber: values.financial.invoiceAddress.houseNumber ? +values.financial.invoiceAddress.houseNumber : 0,
				},
			};
			let response = await mutateFinancial(payload);
			return true;
		} else {
			return false;
		}
	};

	// expedition
	const SubmitExpedition: stepSubmitHook = async (values: IAddOrderForm, stepIndex: number) => {
		if (values.expedition && values.expedition.deliveryAddress && values.expedition.contactPerson) {
			let payload: any = {
				...values.expedition,
				reference: values.expedition.reference,
				preferredExpeditionDate:
					values.expedition.deliveryDateChoice === 'preferredExpeditionDate' && values.expedition.preferredExpeditionDate
						? moment(values.expedition.preferredExpeditionDate).format(moment.HTML5_FMT.DATE)
						: null,
				contactPerson: values.expedition.deliveryCondition === 'bezorging' ? values.expedition.contactPerson : undefined,
				deliveryAddress:
					values.expedition.deliveryCondition === 'bezorging'
						? {
								...values.expedition.deliveryAddress,
								houseNumber: values.expedition.deliveryAddress.houseNumber ? +values.expedition.deliveryAddress.houseNumber : 0,
						  }
						: undefined,
			};

			let response = await mutateExpedition(payload);

			return true;
		} else {
			return false;
		}
	};

	// final step
	const SubmitConfirm: stepSubmitHook = async (values: IAddOrderForm, stepIndex: number) => {
		//todo do some validation on the whole form
        const savedSteps: number[] = stepper.current?.savedSteps || []
        const requiredSavedSteps = [2,3]
        const notSavedSteps = requiredSavedSteps.filter((o) => savedSteps.indexOf(o) === -1)

        for (var i = 0; i < notSavedSteps.length; i++) {     
                                
            let notSavedStepIndex = requiredSavedSteps[i];

            console.log("step " + (notSavedStepIndex + 1) + " will be saved")

            var notSavedStepSubmit = newOrderStepHooks[notSavedStepIndex]

            await notSavedStepSubmit(values, notSavedStepIndex + 1);
        }

		let payload: PutWizardOrderRemarkRepresentation | undefined = values.remark
			? {
					remark: values.remark,
			  }
			: undefined;

		if (values.type === 'order') {
			await mutatePlaceOrder(payload);
			if (isKlant) {
				navigate(CustomerRoutePath.link(CustomerRoutePath.orderLink(values.id)));
			} else {
				navigate(SmRoutePath.link(SmRoutePath.orderEditLink(values.id)));
			}
		} else {
			await mutateIssueQuote(payload);

			if (isKlant) {
				navigate(CustomerRoutePath.link(CustomerRoutePath.orderLink(values.id)));
			} else {
				navigate(SmRoutePath.link(SmRoutePath.orderEditLink(values.id)));
			}
		}
		return true;
	};

	const newOrderStepHooks = [ submitContactPerson,SubmitFinancial,submitContactPerson, SubmitExpedition, SubmitConfirm];

	const loadStepper = () => {
		stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement);
	};
    
	const archive = async () => {
		if (!orderId) return;
		await deleteOrderMutation.mutateAsync(orderId);
		if (isKlant) {
			navigate(CustomerRoutePath.link(CustomerRoutePath.dashboard));
		} else {
			navigate(SmRoutePath.link(SmRoutePath.orderList));
		}
	};

	const prevStep = async () => {
		if (!stepper.current || !formRef.current) {
			return;
		}

		const {submitForm} = formRef.current;

		// this doesn't submit (coz. noop submit method) but it triggers formik to validate and re-render,
		// a validateForm doesn't trigger the re-rendering of the component
		await submitForm();

		const {errors, setSubmitting} = formRef.current; //only after submit we read the errors

		setSubmitting(false);

		const isValid = !Object.keys(errors).length;

		if (isValid) {
			stepper.current.goPrev();
			setCurrentSchema(newOrderSchemas[stepper.current.currentStepIndex - 1]);
		}
	};

	const submitStep = async (stepIndex?: number, skipStepSubmission: boolean = false) => {

		if (!stepper.current || !formRef.current) {
			return;
		}
		const {submitForm} = formRef.current;

		// this doesn't submit (coz. noop submit method) but it triggers formik to validate and re-render,
		// a validateForm doesn't trigger the re-rendering of the component
		await submitForm();
		const {errors, values, setSubmitting} = formRef.current; //only after submit we read the errors
		const isValid = !Object.keys(errors).length;

		if (isValid) {
			if (!skipStepSubmission) {
				let stepSubmission = newOrderStepHooks[stepper.current.currentStepIndex - 1];
				var result = await stepSubmission(values, stepper.current.currentStepIndex - 1);
				if (!result) {
					setSubmitting(false);
					return; // we don't proceed to the next step if the submission failed.
				}
                stepper.current.addSavedStep(stepper.current.currentStepIndex - 1)
			}
			if (stepIndex !== undefined) {
				stepper.current.goto(stepIndex);
				setCurrentSchema(newOrderSchemas[stepper.current.currentStepIndex - 1]);
			} else if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
				stepper.current.goNext();
				setCurrentSchema(newOrderSchemas[stepper.current.currentStepIndex - 1]);
			} else {

			}
		} else {

		}
		setSubmitting(false);

	};

	// side effects

	// 1. load form values from order, customer addressess or custom contact persons or a combination of all.
	useEffect(() => {
		let minExpeditionDate: Date = resetTimeOfDate(addBusinessDays(new Date(), 10));
		let newValues = {...formValues};
		let changed = false;

		if (order) {
			const remark = order.remarks.find((r) => r.type === OrderRemarkType.Wizard);

			newValues = {
				...newValues,
				id: order.id || '',
				code: order.code || '',
				customerId: order.customer.id || '',
				contactPerson: order.orderContact,
				financial: order.financial,
				remark: remark ? remark.remark : undefined,
			};

			if (order.expedition) {
				newValues = {
					...newValues,
					expedition: {
						deliveryCondition: order.expedition.deliveryCondition || 'bezorging',
						deliveryDateChoice: order.expedition.preferredExpeditionDateAsap ? 'asap' : 'preferredExpeditionDate',
						contactPerson: order.expedition.contactPerson,
						deliveryAddress: order.expedition.deliveryAddress,
						preferredExpeditionDate: order.expedition.preferredExpeditionDate ? new Date(order.expedition.preferredExpeditionDate) : minExpeditionDate,
						reference: order.expedition.reference ? {reference: order.expedition.reference} : undefined,
					},
				};
			}
			changed = true;
		}

		if (!newValues.contactPerson && contactPersons && contactPersons.length > 0) {
			const defaultContactPerson = contactPersons.find((item) => item.email === user!.email);
			newValues = {
				...newValues,
				contactPerson: {
					email: defaultContactPerson?.email || contactPersons[0].email || '',
					firstName: defaultContactPerson?.firstName || contactPersons[0].firstName || '',
					lastName: defaultContactPerson?.lastName || contactPersons[0].lastName || '',
					telephone: defaultContactPerson?.telephone || contactPersons[0].telephone,
				},
			};
			changed = true;
		}

		if (!newValues.financial?.invoiceAddress && invoiceAddressess && invoiceAddressess.length > 0) {
			newValues = {
				...newValues,
				financial: {
					...newValues.financial,
					invoiceAddress: {
						attention: '',
						city: invoiceAddressess[0].city || '',
						country: invoiceAddressess[0].country || '',
						postalCode: invoiceAddressess[0].postalCode || '',
						houseNumber: invoiceAddressess[0].houseNumber || '',
						houseNumberAddition: invoiceAddressess[0].houseNumberAddition || '',
						street: invoiceAddressess[0].street || '',
						extraAddressLine: '',
						region: '',
					},
				},
			};
			changed = true;
		}

		if (!newValues.financial?.contactPerson) {
			if (invoiceAddressess && invoiceAddressess.length > 0) {
				newValues = {
					...newValues,
					financial: {
						...newValues.financial,
						invoiceAddress: newValues.financial?.invoiceAddress,
						contactPerson: {
							email: invoiceAddressess[0].contactPerson?.email || '',
							firstName: invoiceAddressess[0].contactPerson?.firstName || '',
							lastName: invoiceAddressess[0].contactPerson?.lastName || '',
							telephone: invoiceAddressess[0].contactPerson?.telephone,
						},
					},
				};
				changed = true;
			}
		}

		if (newValues.expedition && newValues.expedition.preferredExpeditionDate) {
			let expeditionDate: Date = new Date(minExpeditionDate);

			if (!(newValues.expedition.preferredExpeditionDate < minExpeditionDate)) {
				expeditionDate = newValues.expedition.preferredExpeditionDate;
			}

			newValues = {
				...newValues,
				expedition: {
					...newValues.expedition,
					preferredExpeditionDate: expeditionDate,
				},
			};
			changed = true;
		}

		if (newValues.expedition && !newValues.expedition.deliveryAddress && deliveryAddressess && deliveryAddressess.length > 0) {
			newValues = {
				...newValues,
				expedition: {
					...newValues.expedition,
					deliveryAddress: {
						attention: '',
						city: deliveryAddressess[0].city || '',
						country: deliveryAddressess[0].country || '',
						postalCode: deliveryAddressess[0].postalCode || '',
						houseNumber: deliveryAddressess[0].houseNumber || '',
						houseNumberAddition: deliveryAddressess[0].houseNumberAddition || '',
						street: deliveryAddressess[0].street || '',
						extraAddressLine: '',
						region: '',
					},
				},
			};
			changed = true;
		}

		if (newValues.expedition && !newValues.expedition.contactPerson && deliveryAddressess && deliveryAddressess.length > 0) {
			newValues = {
				...newValues,
				expedition: {
					...newValues.expedition,
					contactPerson: {
						email: deliveryAddressess[0].contactPerson?.email || '',
						firstName: deliveryAddressess[0].contactPerson?.firstName || '',
						lastName: deliveryAddressess[0].contactPerson?.lastName || '',
						telephone: deliveryAddressess[0].contactPerson?.telephone,
					},
				},
			};
			changed = true;
		}

		if (changed) {
			setFormValues(newValues);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [order, customer, contactPersons]);

	// 2. load wizard stepper
	useEffect(() => {
		if (!stepperRef.current) {
			return;
		}

		loadStepper();
	}, [stepperRef]);

	// 3. bind event handler `kt.stepper.goto.step` raised from stepper component which clicked on a step in the nav.
	useEffect(() => {
		if (!stepperRef.current) {
			return;
		}

		EventHandlerUtil.on(stepperRef.current, `kt.stepper.goto.step`, async function (e: CustomEvent<number>) {
			if (formRef != null && formRef.current != null && stepper.current != null) {
				await submitStep(e.detail, true);
			}
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stepperRef, formRef]);

	const isLastStep = stepper.current !== null && stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber!;
	return (
		<>
			<PageTitle>{'Nieuwe bestelling voor ' + customer?.companyName + ' (' + order?.code + ')'}</PageTitle>


			<Formik
				innerRef={formRef}
				initialValues={formValues}
				validationSchema={currentSchema}
				onSubmit={(values: IAddOrderForm, actions: FormikValues) => {}} //noop submit
				enableReinitialize={true}
			>
				{(formikProps) => {
					const {values, isSubmitting, setFieldValue} = formikProps;

					return (
						<Form noValidate id="kt_create_new_order_form">
							<div className="d-flex align-items-end p-1 w-100 ">
								<div className="d-flex align-items-end p-1 w-100 align-items-center">
									<div className="me-auto">
										<button onClick={prevStep} type="button" className="btn btn-lg btn-light-primary me-3" data-kt-stepper-action="previous">
											<KTSVG path="/media/icons/duotune/arrows/arr063.svg" className="svg-icon-4 me-1" />
											Vorige
										</button>
									</div>
									<div>
										<button type="button" onClick={() => openOrderDeleteDialog.current?.call(this, true)} className="btn btn-light-danger btn-sm me-3">
											Annuleren
										</button>
										<ConfirmDialog
											show={openOrderDeleteDialog}
											onConfirm={archive}
											onHide={() => openOrderDeleteDialog.current?.call(this, true)}
											dialogStyle="danger"
											title="Order Verwijderen"
											message="Wil je doorgaan?"
											confirmText="Definitief verwijderen"
										/>
										{isLastStep && (
											<button
												onClick={() => {
													setFieldValue('type', OrderType.Quote);
													setFormValues({
														...values,
														type: OrderType.Quote,
													});

													submitStep();
												}}
												disabled={!isOrderFetched || isSubmitting}
												className="btn btn-lg btn-primary me-3"
												type="submit"
											>
												<span className="indicator-label">
													Maak offerte
													<KTSVG path="/media/icons/duotune/arrows/arr064.svg" className="svg-icon-3 ms-2 me-0" />
												</span>
											</button>
										)}
										<button
											type="submit"
											onClick={() => {
												setFieldValue('type', OrderType.Order);
												setFormValues({
													...values,
													type: OrderType.Order,
												});

												submitStep();
											}}
											disabled={!isOrderFetched || isSubmitting}
											className={classNames('btn btn-lg', isLastStep ? 'btn-success' : 'btn-primary')}
										>
											<span className="indicator-label">
												{isLastStep ? 'Plaats order' : 'Volgende'}
												<KTSVG path="/media/icons/duotune/arrows/arr064.svg" className="svg-icon-3 ms-2 me-0" />
											</span>
										</button>
									</div>
								</div>
							</div>

							<div ref={stepperRef} className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid" id="kt_create_new_order_stepper">
								<div className="card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-4">
									<div className="card-body px-6 px-lg-10 px-xxl-15 ">
										<div className="stepper-nav">
											<div className="stepper-item current" data-kt-stepper-element="nav"
												 data-kt-stepper-action="step">
												<div className="stepper-wrapper">
													<div className="stepper-icon w-40px h-40px">
														<i className="stepper-check fas fa-check"></i>
														<span className="stepper-number">1</span>
													</div>
													<div className="stepper-label">
														<h3 className="stepper-title">Dorpels</h3>
														<div className="stepper-desc fw-semibold">Configureer dorpels
														</div>
													</div>
												</div>
												<div className="stepper-line h-40px"></div>
											</div>
											<div className="stepper-item" data-kt-stepper-element="nav"
												 data-kt-stepper-action="step">
												<div className="stepper-wrapper">
													<div className="stepper-icon w-40px h-40px">
														<i className="stepper-check fas fa-check"></i>
														<span className="stepper-number">2</span>
													</div>
													<div className="stepper-label">
														<h3 className="stepper-title">Aanvullende producten</h3>
														<div className="stepper-desc fw-semibold">Voeg extra producten
															toe
														</div>
													</div>
												</div>
												<div className="stepper-line h-40px"></div>
											</div>
											<div className="stepper-item" data-kt-stepper-element="nav"
												 data-kt-stepper-action="step">
												<div className="stepper-wrapper">
													<div className="stepper-icon w-40px h-40px">
														<i className="stepper-check fas fa-check"></i>
														<span className="stepper-number">3</span>
													</div>
													<div className="stepper-label">
														<h3 className="stepper-title">Contactgegevens</h3>
														<div className="stepper-desc fw-semibold">Kies een
															contactpersoon
														</div>
													</div>
												</div>
												<div className="stepper-line h-40px"></div>
											</div>
											<div className="stepper-item" data-kt-stepper-element="nav"
												 data-kt-stepper-action="step">
												<div className="stepper-wrapper">
													<div className="stepper-icon w-40px h-40px">
														<i className="stepper-check fas fa-check"></i>
														<span className="stepper-number">4</span>
													</div>
													<div className="stepper-label">
														<h3 className="stepper-title">Levering</h3>
														<div className="stepper-desc fw-semibold">Gewenste leverdatum en
															bezorging
														</div>
													</div>
												</div>
												<div className="stepper-line h-40px"></div>
											</div>


											<div className="stepper-item" data-kt-stepper-element="nav"
												 data-kt-stepper-action="step">
												<div className="stepper-wrapper">
													<div className="stepper-icon w-40px h-40px">
														<i className="stepper-check fas fa-check"></i>
														<span className="stepper-number">5</span>
													</div>
													<div className="stepper-label">
														<h3 className="stepper-title">Overzicht</h3>
														<div className="stepper-desc fw-semibold">Plaats bestelling of
															maak een offerte
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="bg-body rounded w-100">
									<div className=" d-flex align-items-start flex-column p-5 w-100 mb-10">
										<div data-kt-stepper-element="content" className="current w-100">
											{order && <ConfigureSills order={order!}/>}
										</div>
										<div data-kt-stepper-element="content" className="w-100">
											{order && <AdditionalProducts order={order!}/>}
										</div>
										<div data-kt-stepper-element="content" className="w-100">
											<SelectContactPerson setFormValues={setFormValues}/>
										</div>
										<div data-kt-stepper-element="content" className="w-100">
											<ExpeditionDetails setFormValues={setFormValues}/>
										</div>

										<div data-kt-stepper-element="content" className="w-100">
											<Summary order={order!}/>
										</div>
										<div className="mt-auto w-100">
											<div className="mr-2">
												<button onClick={prevStep} type="button"
														className="btn btn-lg btn-light-primary me-3"
														data-kt-stepper-action="previous">
													<KTSVG path="/media/icons/duotune/arrows/arr063.svg"
														   className="svg-icon-4 me-1"/>
													Vorige
												</button>
											</div>

											<div>
												<div className="d-flex align-items-center justify-content-end">
													<button
														onClick={() => openOrderDeleteDialog.current?.call(this, true)}
														className="btn btn-light-danger btn-sm me-3">
														Annuleren
													</button>
													<ConfirmDialog
														show={openOrderDeleteDialog}
														onConfirm={archive}
														onHide={() => openOrderDeleteDialog.current?.call(this, true)}
														dialogStyle="danger"
														title="Order Verwijderen"
														message="Wil je doorgaan?"
														confirmText="Definitief verwijderen"
													/>
													{isLastStep && (
														<button
															type="button"
															onClick={() => {
																setFieldValue('type', OrderType.Quote);
																setFormValues({
																	...values,
																	type: OrderType.Quote,
																});

																submitStep();
															}}
															disabled={!isOrderFetched || isSubmitting}
															className="btn btn-lg btn-primary me-3"
														>
															<span className="indicator-label">
																Maak offerte
																<KTSVG path="/media/icons/duotune/arrows/arr064.svg"
																	   className="svg-icon-3 ms-2 me-0"/>
															</span>
														</button>
													)}

													<button
														type="button"
														onClick={() => {
															setFieldValue('type', OrderType.Order);
															setFormValues({
																...values,
																type: OrderType.Order,
															});

															submitStep();
														}}
														disabled={!isOrderFetched || isSubmitting}
														className={classNames('btn btn-lg me-3', isLastStep ? 'btn-success' : 'btn-primary')}
													>
														<span className="indicator-label">
															{isLastStep ? 'Plaats order' : 'Volgende'}
															<KTSVG path="/media/icons/duotune/arrows/arr064.svg"
																   className="svg-icon-3 ms-2 me-0"/>
														</span>
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</Form>
					);
				}}
			</Formik>
		</>
	);
};

export {AddOrderWizard};

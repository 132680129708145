import React, {CSSProperties} from "react";
import {ColorOption} from "./model";
import {OptionPickerFC} from "./OptionPicker";
import {useField} from "formik";

const ColorOptionPicker: OptionPickerFC<ColorOption> = (props) => {

    const [field, , helpers] = useField(props.name);
    const {setValue} = helpers;

    const selectedOption = field.value ? props.options.options.find(x => x.id === field.value) : null;

    const colorboxCss: CSSProperties = {width: '30px', height: '30px'};

    return (
        <div className="dropdown">
            <div className="form-select form-control-lg py-1 px-3 d-flex align-items-center"
                 tabIndex={0}
                 data-bs-toggle="dropdown" aria-expanded="false">
                <div className="flex-fill d-flex  align-items-center">
                    <div className="rounded me-3" style={{...colorboxCss, background: selectedOption?.hex}}></div>
                    <span className="fs-5 text-truncate">{selectedOption?.name}</span>
                </div>
            </div>

            <ul className="dropdown-menu dropdown-menu-lg" style={{minWidth: '100%'}}>
                {props.options.options.map((option, index) => {
                    return (<li key={index} className="dropdown-item d-flex align-items-center"
                                onClick={() => setValue(option.id)}>
                        <div className="rounded me-3" style={{...colorboxCss, background: option.hex}}></div>
                        <span className="fs-5">{option.name}</span>
                    </li>);
                })}
            </ul>
        </div>
    )
};

export default ColorOptionPicker;
import React from "react";
import {ImportStatus} from "../../../modules/api-client/generated";
import classNames from "classnames";

export interface Props {
    status: ImportStatus
    className?: string
}

const ImportStatusProgress: React.FC<Props> = ({status, className}) => {
        let label;
        let color;
        switch (status) {
            case ImportStatus.TimeoutExpired: {
                label = 'Time out';
                color = 'danger';
                break;
            }
            case ImportStatus.ValidatingFile: {
                label = 'Uploaden';
                color = 'primary';
                break;
            }
            case ImportStatus.ValidatingEdsMapping: {
                label = 'Gegevens valideren';
                color = 'info';
                break;
            }
            case ImportStatus.ConfiguringSills: {
                label = 'Aanmaken dorpels';
                color = 'warning';
                break;
            }
            case ImportStatus.SillsConfigured: {
                label = 'Naar bestelling';
                color = 'success';
                break;
            }
            case ImportStatus.Completed: {
                label = 'Import compleet';
                color = 'success';
                break;
            }
            case ImportStatus.InvalidFile: {
                label = 'File ongeldig';
                color = 'danger';
                break;
            }
            case ImportStatus.EdsMappingFailed: {
                label = 'Eds mapping gefaald';
                color = 'danger';
                break;
            }
            case ImportStatus.SillConfigurationFailed: {
                label = 'Dorpels configuratie gefaald';
                color = 'danger';
                break;
            }
            case ImportStatus.Cancelled: {
                label = 'Geannuleerd';
                color = 'danger';
                break;
            }
            default: {
                color = 'light';
                label = 'Onbekend'
            }
        }
        const steps = [
            {step: 1, label: 'Uploaden', status: ImportStatus.ValidatingFile, icon: 'file-import'},
            {step: 2, label: 'Gegevens valideren', status: ImportStatus.ValidatingEdsMapping, icon: 'file-circle-check'},
            {step: 3, label: 'Aanmaken dorpels', status: ImportStatus.ConfiguringSills, icon: 'wand-magic-sparkles'},
            {step: 4, label: 'Naar bestelling', status: ImportStatus.SillsConfigured, icon: 'cart-shopping'},
        ];
        const getCurrentStep = (status: ImportStatus): number => {
            switch (status) {
                case ImportStatus.ValidatingFile:
                    return 1;
                case ImportStatus.ValidatingEdsMapping:
                    return 2;
                case ImportStatus.EdsMappingValidated:
                case ImportStatus.ConfiguringSills:
                    return 3;
                case ImportStatus.SillsConfigured:
                case ImportStatus.Completed:
                    return 4;
                default:
                    return 0; // Unknown or initial step
            }
        };
        const currentStep = getCurrentStep(status);
        return (
            <div className={` rounded p-5 bg-white ${className}`}>
                <div className={'row'}>
                    {steps.map(({step, label, icon, status}) => {
                            const isDone = step < currentStep;
                            const isActive = step === currentStep;
                            return (
                                <div key={step} className={'col'}>
                                    <div className={classNames('text-center  ', {
                                        'text-success': isDone,
                                        'fw-bold text-info': isActive,
                                        'text-gray-600': !isDone && !isActive
                                    })}>
                                        <h1  className={'mb-4'} style={{color: "inherit"}}>
                                            {isDone ? (
                                                    <i className={`fa fa-circle-check fa-2x fs-1`} style={{color: "inherit"}}/>
                                                ):(
                                                    <i className={`fa fa-${icon}  fa-2x fs-1`} style={{color: "inherit"}}/>
                                                )
                                            }
                                        </h1>
                                        {label}
                                    </div>
                                </div>
                            )
                        }
                    )
                    }
                </div>
            </div>
        )
    }
;

export default ImportStatusProgress;

import {useQuery, useQueryClient} from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import {NotFound, ValidationFailed} from "../../../../modules/api-client/Responses";
import {useEkosietMutation} from "../../../../shared/hooks/useEkosietMutation";
import {
    CreateSluitpotBlockTypeCommand, SluitpotBlockType,
    UpdateSluitpotBlockTypeCommand
} from "../../../../modules/api-client/generated";
import {FormValues} from "./SluitpotBlockTypeUpsert";


export const useSluitpotBlockTypeUpsertMutation = (id?: string | undefined) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<SluitpotBlockType, NotFound | ValidationFailed, FormValues>((values) => {
            const command: CreateSluitpotBlockTypeCommand | UpdateSluitpotBlockTypeCommand = {
                code: values.code,
                name: values.name,
            };

            if (id) {
                return ApiClient.Pim.SluitpotBlockType.pimSluitpotBlockTypesIdPut(id, undefined, command).then(response => response.data);
            }
            return ApiClient.Pim.SluitpotBlockType.pimSluitpotBlockTypesPost(undefined, command).then(response => response.data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['pimSluitpotBlockTypeIdGet', id]).then();
                queryClient.invalidateQueries(['pimSluitpotBlockTypeGet']).then();
            }
        }
    );
};

export const useSluitpotBlockTypeQuery = (id: string | undefined) => {
    return useQuery<SluitpotBlockType,TypeError,SluitpotBlockType>(['pimSluitpotBlockTypeIdGet', id],
        () => {
            return ApiClient.Pim.SluitpotBlockType.pimSluitpotBlockTypesIdGet(id!).then(res => res.data);
        }, {
            useErrorBoundary: true,
            enabled: !!id,
        });
}

export const useSluitpotBlockTypeDeleteMutation = (id: string) => {
    const queryClient = useQueryClient()
    return useEkosietMutation<void, NotFound | ValidationFailed, string>((id) => {
            return ApiClient.Pim.SluitpotBlockType.pimSluitpotBlockTypesIdDelete(id, undefined).then(response => response.data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['pimSluitpotBlockTypeIdGet', id]).then();
                queryClient.invalidateQueries(['pimSluitpotBlockTypeGet']).then();
            }
        }
    );
};




import React from 'react';
import * as api from '../../../../modules/api-client/generated';

export interface RabbetPositionTextProps {
	code: string | undefined;
}


export const RabbetPositionText: React.FC<RabbetPositionTextProps> = (props) => {

	switch (props.code) {
		case api.RabbetPosition.Inside: {
			return (<>Binnen</>);
		}
		case api.RabbetPosition.Outside: {
			return (<>Buiten</>);
		}
		default: {
			return (<>-</>);
		}
	}
};

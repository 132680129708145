import {useEffect} from 'react';
import {useFormikContext} from 'formik';

export function FormikOnChange<Values>({onChange}: {onChange: (values: Values) => void}) {
	const {values, dirty} = useFormikContext<Values>();

	useEffect(() => {
		if (dirty) onChange(values);
	}, [values]);

	return null;
}
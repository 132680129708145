import React from "react";
import BooleanBadge from "./BooleanBadge";

export interface ActiveBadgeProps {
    value: boolean | undefined | null | { active: boolean };
}

const ActiveBadge: React.FC<ActiveBadgeProps> = (props) => {

    let active: boolean | undefined;

    if (typeof props.value === 'object') {
        active = props.value?.active;
    } else {
        active = !!props.value;
    }

    return <BooleanBadge value={active} trueText="Actief" falseText="Inactief"/>
};

export default ActiveBadge;
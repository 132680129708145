import { FC, useEffect, useState } from 'react'
import { FilterParameter, useEkosietFilter } from '../../../../../shared/context/FilterContext';
import useDebounce from 'react-use/lib/useDebounce';

type Props = {
    fieldName: string;
    filterProperty?: string
}

export const OrderCodeFilter: FC<Props> = (props: Props) => {

    const { setFilters, containsFilter, resetCount } = useEkosietFilter()
    const [searchTerm, setSearchTerm] = useState("");    
    
    useDebounce(
        () => {        
            if (searchTerm) {
                
                const filterProperty = props.filterProperty || 'Code'
                const expression = { [filterProperty]: { contains: searchTerm } }
                const filter: FilterParameter = {
                    filterExpression:  expression,
                    filterName: props.fieldName,
                    values: [searchTerm]
                }
        
                setFilters(props.fieldName, [filter])
            } else {        
                if(containsFilter(props.fieldName)) {
                    setFilters(props.fieldName, [])
                }
            }
        },
        500,
        [searchTerm],
      );
    
    useEffect(() => {
        if (resetCount) {
            setSearchTerm("")
        }
    }, [resetCount])

    return (
        <>
                    <input type="text"
                    name={props.fieldName}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.currentTarget.value)}
                    className='form-control' 
                    placeholder="ORD123456" 
                    />
        </>
    )
}

import React from "react";
import {PageTitle} from "../../../../../_metronic/layout/core";
import {ProductionItemOperations} from "../../../../modules/api-client/generated";
import {ProductionOperation} from "../components/ProductionOperation";

const Afmontage: React.FC = () => {


    return (
        <>
            <PageTitle
                toolbar={
                    <>
                    </>
                }
                breadcrumbs={[]}
            >
               Afmontage
            </PageTitle>

            <ProductionOperation scope={[`Afgemonteerd:a-0`,`Status:Started`]} operation={ProductionItemOperations.Custom} />
        </>
    )
}

export default Afmontage

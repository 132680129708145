/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {PageTitle} from "../../../_metronic/layout/core";
import {EkosietKlantenserviceWidget} from "../widgets/EkosietKlantenserviceWidget";
import {OrderListDashboardWidget} from "../widgets/OrderListDashboardWidget";
import {EkoCard} from "../../shared/components/card/EkoCard";
import {EkoCardBody} from "../../shared/components/card/EkoCardBody";
import {toAbsoluteUrl} from "../../../_metronic/helpers";
import {EkosietUitlegWidget} from "../widgets/EkosietUitlegWidget";
import {MonsterDorpelWidget} from "../../shared/components/misc/MonsterDorpelWidget";

const CustomerDashboard: FC = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>Welkom bij Ekosiet</PageTitle>
            <div className='row g-5 g-xl-10'>
                <div className='col col-lg-8 mb-md-5 mb-xl-10'>
                    {/*<EkoCard className={'bg-warning mb-10'}>*/}
                    {/*    <EkoCardBody>*/}
                    {/*        <div>*/}
                    {/*            <h3>UPDATE HEFSCHUIF DORPELS</h3>*/}
                    {/*            <p className={'lead mb-0'}>*/}
                    {/*        Configureer nu ook Hefschuif dorpels in de dorpelconfigurator!*/}
                    {/*            </p>*/}
                    {/*        </div>*/}
                    {/*    </EkoCardBody>*/}
                    {/*</EkoCard>*/}
                {/*<EkoCard className={'bg-secondary mb-10'}>*/}
                {/*        <EkoCardBody>*/}

                            {/*<div>*/}
                                {/*<h3>Welkom op in het bestelportaal van Ekosiet</h3>*/}
                                {/*<p className={'lead'}>*/}
                                {/*    Afgelopen maanden hebben we, naast de nieuwe machine en het vernieuwde*/}
                                {/*    productieproces, hard gewerkt aan een nieuw bestelportaal en dorpelconfigurator.*/}
                                {/*</p>*/}
                                {/*<p className={'lead'}>*/}
                                {/*    Loopt u tegen een probleem aan, of heeft u suggesties of opmerkingen? Twijfel niet*/}
                                {/*    om ze door te geven aan onze werkvoorbereiders via info@ekosiet.nl of 0 488 72 59*/}
                                {/*    47. </p>*/}
                                {/*<p className={'lead mb-0'}>*/}
                                {/*    Met vriendelijke groet,<br/>*/}
                                {/*    Het team van Ekosiet*/}
                                {/*</p>*/}
                            {/*</div>*/}
                        {/*</EkoCardBody>*/}
                    {/*</EkoCard>*/}
                    <OrderListDashboardWidget className={'card-xxl-stretch mb-5 mb-xl-8'}/>

                </div>
                <div className='col col-lg-4'>

                    <MonsterDorpelWidget className='h-lg-50'/>

                    <EkosietUitlegWidget className='mb-10 '/>
                    <EkosietKlantenserviceWidget className=''/>


                </div>
            </div>

            <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
                <div className='col col-lg-8 mb-md-5 mb-xl-10'>
                </div>
                <div className='col col-lg-4'>
                </div>
            </div>
        </>
    )
}
export {CustomerDashboard}

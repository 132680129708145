import React from 'react';
import {KTSVG} from '../../../../shared/components/KTSVG';
import {IFacetResultLabel} from '../../../../modules/api-client/generated';


export interface ProfileFacetSelectorProps {
	selected: string[];
	title: string;
	labels: Array<IFacetResultLabel>;
	selectionChanged: (selection: string[]) => void;
	maxHeight?: string;
}


export const ProfileFacetSelector: React.FC<ProfileFacetSelectorProps> = (props) => {
	function onSelectAll() {
		props.selectionChanged(props.labels.map(l => l.value));
	}

	function onSelectNone() {
		props.selectionChanged([]);
	}

	function onSelectionChange(code: string, checked: boolean) {
		if (checked && props.selected.indexOf(code) === -1) {

			props.selectionChanged([...props.selected, code]);

		} else if (!checked) {

			const index = props.selected.indexOf(code);

			if (index > -1) {
				const copy = [...props.selected];
				copy.splice(index, 1);
				props.selectionChanged(copy);
			}
		}
	}

	const maxHeight = props.maxHeight ?? '200px';

	return <>
		<div className='col-12 mb-10 d-flex flex-column '>
			<div className={'d-flex justify-content-between align-items-start'}>
				<label className='form-label text-nowrap'>{props.title}</label>
				<div>
					<button onClick={() => onSelectAll()}
							className='btn btn-link btn-sm p-0 link-primary'>
						<KTSVG path={'/media/icons/duotune/general/gen043.svg'}
							   className='' />
					</button>
					<button onClick={() => onSelectNone()}
							className='btn btn-link btn-sm p-0 link-primary'>
						<KTSVG path={'/media/icons/duotune/general/gen040.svg'}
							   className='' />
					</button>
				</div>
			</div>
			<div className={'bg-light p-3 border-1 border-dark'} style={{maxHeight: maxHeight, overflowY: 'scroll'}}>
				{props.labels.map((facetLabel) => (
					<div key={facetLabel.value}>
						<label
							className='w-100 h-100 cursor-pointer text-nowrap d-flex justify-content-between align-items-center'>
							<span className='d-flex align-items-center'>
								<input
									className='form-check-input m-0 my-1'
									type='checkbox'
									checked={props.selected.indexOf(`${facetLabel.value}`) !== -1}
									onChange={(e) => onSelectionChange(facetLabel.value, e.target.checked)}
									value={facetLabel.value}
									disabled={facetLabel.count === 0}
								/>
								<span
									className={`ms-2 ${facetLabel.count === 0 ? 'text-muted' : ''} user-select-none`}>{facetLabel.name}</span>
							</span>
							<small className='ms-2 text-muted'>({facetLabel.count})</small>
						</label>
					</div>
				))}
			</div>
		</div>
	</>;
};

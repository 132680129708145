import { useMutation, useQueryClient  } from "@tanstack/react-query";
import {toast} from "react-hot-toast"
import ApiClient from "../../../../modules/api-client/ApiClient";
import { PostCustomerRepresentation } from "../../../../modules/api-client/generated";
import { AxiosError } from "axios";

export const usePostCustomer = () => {  

    const queryClient = useQueryClient();
    
    const mutation = useMutation(
        (customer: PostCustomerRepresentation) => {
          return ApiClient.Crm.Customer
            .crmCustomerPost(undefined, customer);
        },        
        {
          onSuccess: () => {            
            queryClient.invalidateQueries(['v1/crm/customer/list'])
            toast.success("Klant aangemaakt.");
          },
          onError: (error: AxiosError) => {                        
            toast.error(error?.message);
            // toast.error(error?.response?.data?.title || 'Onbekende fout!');
          },
        },
      );
    
      return mutation.mutateAsync;
};

import { FC } from "react";
import { createPortal } from "react-dom";
import { Button, Modal } from "react-bootstrap";
import { modalsRoot } from "../../../../global-helpers";
import { useQuery } from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import Loading from "../../../../shared/components/Loading";
import { useEkosietMutation } from "../../../../shared/hooks/useEkosietMutation";
import SpinnerButton from "../../../../shared/components/SpinnerButton";

export interface BulkActivateModalProps {
    show: boolean;
    onClose: () => void;
    onSuccess?: () => void;
    selectionFilters: string[];
}

const BulkActivateModal: FC<BulkActivateModalProps> = ({ show, onClose, selectionFilters, onSuccess }) => {

    const { data: preview, isLoading } = useQuery(
        ['pimProfilesBulkPreviewPost', selectionFilters],
        async () => {
            const response = await ApiClient.Pim.Profile.bulkPreviewProfiles(undefined, selectionFilters)
            return response?.data
        },
        {
            enabled: show
        });

    const mutation = useEkosietMutation(() => ApiClient.Pim.Profile.bulkActivateProfiles(undefined, selectionFilters), {
        onSuccess: () => {
            onClose();
            onSuccess?.call(undefined);
        }
    });

    if (!show) return null;

    return createPortal(
        <Modal
            tabIndex={-1}
            aria-hidden='true'
            show={show}
            size="lg"
            onHide={() => !isLoading ? onClose() : () => { }}
            animation={false}
            backdrop={mutation.isLoading ? 'static' : true}>
            <Modal.Header closeButton>
                <Modal.Title>Profielen activeren</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {(isLoading || !preview) ? <Loading /> : <>
                    Je staat op het punt om <strong>{preview.count} profielen</strong> te activeren. Wil je doorgaan?
                </>}

            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" className="mx-4" onClick={() => onClose()}>Annuleren</Button>
                <SpinnerButton type="button" className="btn btn-primary"
                    disabled={isLoading || !preview}
                    onClick={() => mutation.mutate()}
                    spinning={mutation.isLoading}>Profielen activeren</SpinnerButton>
            </Modal.Footer>
        </Modal>,
        modalsRoot
    )
}

export { BulkActivateModal }

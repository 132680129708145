/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {useParams} from "react-router-dom";
import {EkoCard, EkoCardBody, EkoCardHeader} from "../../../../../shared/components/card";
import {CustomerModalFormWrapper} from "../../components/CustomerModalFormWrapper";
import {CustomerAddressesModalBody} from "../../components/CustomerAddressesModalBody";
import {FilterContextProvider} from "../../../../../shared/context/FilterContext";
import {OrderSearch} from "../../../sm/orders/components/OrdersSearch";
import {OrderTable} from "../../../sm/orders/components/OrderTable";

export function CustomerOrders() {
    const {id} = useParams<{ id: string }>();
    return (
            <EkoCard>
                <EkoCardHeader title={'Orders'}>
                </EkoCardHeader>
                <EkoCardBody>
                    <FilterContextProvider>
                        <EkoCard className={'mb-5'}>
                            <EkoCardBody>
                                <OrderSearch/>
                            </EkoCardBody>
                        </EkoCard>
                        <EkoCard>
                            <EkoCardBody>
                                <OrderTable customerId={id}/>
                            </EkoCardBody>
                        </EkoCard>
                    </FilterContextProvider>
                </EkoCardBody>
            </EkoCard>
    )
}

import React from "react";
import {PageTitle} from "../../../../../_metronic/layout/core";

import {EkoCard, EkoCardHeader, EkoCardBody} from "../../../../shared/components/card";
import {EkoTable, TableHeader} from "../../../../shared/components/table";
import {usePutBatchFinished} from "../hooks/use-put-batch-finished";
import {usePutBatchCancelled} from "../hooks/use-put-batch-cancelled";
import {Link, useParams} from "react-router-dom";
import {useProductionBatchGetId} from "../hooks/use-get-production-batch";
import {BmhDownloadButton} from "../components/BmhDownloadButton";
import ProductionBatchStatus from "../../../../shared/components/ProductionBatchStatus";
import {formatDate} from "../../../../shared/components/date";
import {PmRoutePath} from "../PmRoutes";
import {ProductionBatchStatusCodes, ProductionBatchTypes} from "../../../../modules/api-client/generated";
import {usePutBatchStarted} from "../hooks/use-put-batch-start";
import {RegenerateBmhButton} from "../components/RegenerateBmhButton";
import {usePutBatchAccepted} from "../hooks/use-put-batch-accepted";
import {ProductionItemStatus} from "../../../../shared/components/ProductionItemStatus";
import {RegenerateGroupNumbersButton} from "../components/RegenerateGroupNumbersButton";
import BooleanBadge from "../../../../shared/components/BooleanBadge";
import {ManchettesDownloadButton} from "../components/ManchettesDownloadButton";

const ProductionBatchPage: React.FC = () => {

    const {id} = useParams<{ id: string }>()
    const {data: batch, isInitialLoading: itemLoading} = useProductionBatchGetId(id)

    const batchStarted = usePutBatchStarted()
    const batchAccepted = usePutBatchAccepted()
    const batchFinished = usePutBatchFinished()
    const batchCancelled = usePutBatchCancelled()

    const accept = () => {
        return new Promise<void>(() => {
            batchAccepted.mutate(id!, {});
        }).finally()
            ;
    }

    const start = () => {
        return new Promise<void>(() => {
            batchStarted.mutate(id!, {});
        }).finally()
            ;
    }

    const finish = () => {
        return new Promise<void>(() => {
            batchFinished.mutate(id!, {});
        }).finally();
    }

    const cancel = () => {
        return new Promise<void>(() => {
            batchCancelled.mutate(id!, {});
        }).finally();
    }

    if (!batch) return <></>

    return (
        <>

            <PageTitle toolbar={
                <div>
                    {batch.status === ProductionBatchStatusCodes.Drafted &&
                        <button className={'btn btn-primary me-3'} onClick={() => accept()}>Accepteren</button>
                    }

                    {batch.status === ProductionBatchStatusCodes.Accepted &&
                        <button className={'btn btn-light me-3'}
                                onClick={() => window.confirm('Weet je het zeker?') && start()}>Starten</button>
                    }
                    {batch.status !== ProductionBatchStatusCodes.Accepted && batch.status !== ProductionBatchStatusCodes.Drafted && batch.status !== ProductionBatchStatusCodes.Cancelled && batch.status !== ProductionBatchStatusCodes.Finished && batch.status !== ProductionBatchStatusCodes.Failed &&
                        <button className={'btn btn-light me-3'}
                                onClick={() => window.confirm('Weet je het zeker?') && finish()}>Gereed melden</button>
                    }
                    {batch.status !== ProductionBatchStatusCodes.Cancelled && batch.status !== ProductionBatchStatusCodes.Finished && batch.status !== ProductionBatchStatusCodes.Failed &&
                        <button className={'btn btn-light me-3'}
                                onClick={() => window.confirm('Weet je het zeker?') && cancel()}>Batch
                            annuleren</button>
                    }
                    {batch.type === "dorpel" && batch.status === ProductionBatchStatusCodes.Drafted &&
                        <RegenerateGroupNumbersButton batch={batch.id}/>
                    }

                    <ManchettesDownloadButton batch={batch.id}/>

                    <RegenerateBmhButton batch={batch.id} disabled={batch.type == ProductionBatchTypes.NeutenLos}/>

                    <BmhDownloadButton filename={`${batch.title}-${batch.code}-${batch.type}.xml`} batch={batch.id}/>

                </div>

            }>{'Productie batch ' + batch.title + ' ' + batch.code}</PageTitle>

            <div className={'row'}>
                <div className={'col col-lg-4'}>
                    <EkoCard className={'mb-5'}>
                        <EkoCardBody>
                            <div className={'row mb-4'}>
                                <div className={'col'}>
                                    <label className="form-label">BMH BatchCode</label>
                                </div>
                                <div className={'col'}>
                                    {batch.bmhBatchCode}
                                </div>
                            </div>
                            <div className={'row mb-4'}>
                                <div className={'col'}>
                                    <label className="form-label">Type</label>
                                </div>
                                <div className={'col'}>
                                    {batch.type}
                                </div>
                            </div>
                            <div className={'row mb-4'}>
                                <div className={'col'}>
                                    <label className="form-label">Spoed?</label>
                                </div>
                                <div className={'col'}>
                                    <BooleanBadge value={batch.urgent}/>
                                </div>
                            </div>
                            <div className={'row mb-4'}>
                                <div className={'col'}>
                                    <label className="form-label">Status</label>
                                </div>
                                <div className={'col'}>
                                    <ProductionBatchStatus status={batch.status}/>
                                </div>
                            </div>
                            <div className={'row mb-4'}>
                                <div className={'col'}>
                                    <label className="form-label">Opmerking</label>
                                </div>
                                <div className={'col'}>
                                    {batch.remark ?
                                        <>
                                            {batch.remark}
                                        </>
                                        :
                                        <>
                                            -
                                        </>
                                    }
                                </div>
                            </div>
                        </EkoCardBody>
                    </EkoCard>
                    <EkoCard>
                        <EkoCardHeader title={'Activiteit'}>
                        </EkoCardHeader>
                        <EkoCardBody>
                            <div className={'row mb-4'}>
                                <div className={'col'}>
                                    <label className="form-label">Concept</label>
                                </div>
                                <div className={'col'}>
                                    {formatDate(batch.drafted?.date, "dd-MM-yy HH:mm")}<br/>
                                    <span title={batch.drafted?.userId}>{batch.drafted?.userDisplayName}</span>
                                </div>
                            </div>

                            <div className={'row mb-4'}>
                                <div className={'col'}>
                                    <label className="form-label">Geaccepteerd</label>
                                </div>
                                <div className={'col'}>
                                    {formatDate(batch.accepted?.date, "dd-MM-yy HH:mm")}<br/>
                                    <span title={batch.accepted?.userId}>{batch.accepted?.userDisplayName}</span>
                                </div>
                            </div>

                            <div className={'row mb-4'}>
                                <div className={'col'}>
                                    <label className="form-label">Gestart</label>
                                </div>
                                <div className={'col'}>
                                    {formatDate(batch.started?.date, "dd-MM-yy HH:mm")}<br/>
                                    <span title={batch.started?.userId}>{batch.started?.userDisplayName}</span>
                                </div>
                            </div>
                            <div className={'row mb-4'}>
                                <div className={'col'}>
                                    <label className="form-label">Gereed</label>
                                </div>
                                <div className={'col'}>
                                    {formatDate(batch.finished?.date, "dd-MM-yy HH:mm")}<br/>
                                    <span title={batch.finished?.userId}>{batch.finished?.userDisplayName}</span>
                                </div>
                            </div>
                            <div className={'row mb-4'}>
                                <div className={'col'}>
                                    <label className="form-label">Mislukt</label>
                                </div>
                                <div className={'col'}>
                                    {formatDate(batch.failed?.date, "dd-MM-yy HH:mm")}<br/>
                                    <span title={batch.failed?.userId}>{batch.failed?.userDisplayName}</span>
                                </div>
                            </div>
                            <div className={'row mb-4'}>
                                <div className={'col'}>
                                    <label className="form-label">Geannuleerd</label>
                                </div>
                                <div className={'col'}>
                                    {formatDate(batch.cancelled?.date, "dd-MM-yy HH:mm")}<br/>
                                    <span title={batch.cancelled?.userId}>{batch.cancelled?.userDisplayName}</span>
                                </div>
                            </div>
                        </EkoCardBody>
                    </EkoCard>
                </div>
                <div className={'col col-lg-8'}>
                    <EkoCard>
                        <EkoCardHeader title={'Items'}>
                        </EkoCardHeader>
                        <EkoCardBody>
                            <EkoTable>
                                <TableHeader>
                                    <th>Item</th>
                                    <th>Code</th>
                                    <th>Order</th>
                                    <th>BMH\3DM Barcode</th>
                                    <th>Latten bak</th>
                                    <th>Neuten bak</th>
                                    <th>Status</th>
                                </TableHeader>
                                <tbody>
                                {batch.items.map((pi) => {
                                    return (
                                        <tr key={pi.id}>
                                            <td>
                                                <div>
                                                    <Link to={PmRoutePath.link(PmRoutePath.productionItemLink(pi.id!))}
                                                          className='text-hover-primary fs-6'>
                                                        {pi.title}
                                                    </Link>
                                                </div>
                                                <div>
                                                    <Link
                                                        to={PmRoutePath.link(PmRoutePath.productionItemGroupLink(pi.code!))}
                                                        className='text-hover-primary fs-6'>
                                                        {pi.code}
                                                        {pi.sequence}
                                                    </Link>
                                                </div>
                                            </td>
                                            <td>{pi.code}</td>
                                            <td>{pi.order?.code}</td>
                                            <td>{batch.bmhBatchCode}{pi.batchSequence}</td>
                                            <td>
                                                {pi.lattenGroupNumber}
                                            </td>
                                            <td>
                                                {pi.neutenGroupNumber}
                                            </td>
                                            <td>
                                                <ProductionItemStatus status={pi.status}/>
                                            </td>
                                        </tr>
                                    )
                                })}

                                </tbody>
                            </EkoTable>
                        </EkoCardBody>
                    </EkoCard>
                </div>
            </div>

        </>
    )
}

export default ProductionBatchPage

import React, { useCallback, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import SillTypeText from '../sill/SillTypeText';

import ActiveBadge from '../../../../shared/components/ActiveBadge';
import { EkoCard, EkoCardBody, EkoCardHeader, EkoCardToolbar } from '../../../../shared/components/card';
import { ConfirmDialog } from '../../../../shared/components/ConfirmDialog';
import { PimRoutePath } from '../PimRoutes';
import SillContraSvg from '../../../../shared/components/SillContraSvg';
import ProfileDropzone from '../components/ProfileDropzone';
import SillContourSvg from '../../../../shared/components/SillContourSvg';
import Loading from '../../../../shared/components/Loading';
import { EkoTable, TableHeader } from '../../../../shared/components/table';
import { KTSVG } from '../../../../shared/components/KTSVG';
import { FileCategory, SillType } from '../../../../modules/api-client/generated';
import { SillAssemblyOptionDetails } from '../sill/SillAssemblyOptionsDetails';
import { BooleanText, Mm } from '../../../../shared/components';
import { SillDetailsNeutPositionsCard } from "../sill/SillDetails/SillDetailsNeutPositionsCard";
import { useSillQuery } from "../sill/hooks/use-sill-query";
import { useSillUpdateContourMutation } from "../sill/hooks/use-sill-update-contour-mutation";
import { useSillUpdateContraProfileMutation } from "../sill/hooks/use-sill-update-contra-profile-mutation";
import { useSillDeleteMutation } from "../sill/hooks/use-sill-delete-mutation";
import { SillDetailsCompartmentLayoutsCard } from "../sill/SillDetails/SillDetailsCompartmentLayoutsCard";
import { FileUpload } from '../../../../shared/components/file-upload/FileUpload';
import { useSillUpsertImageMutation } from '../sill/hooks/use-sill-upsert-image-mutation';


const SillDetailsPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();

    const { data: sill, isInitialLoading } = useSillQuery(id);

    const navigate = useNavigate();

    const openDeleteDialog = useRef<(show: boolean) => void>();

    const deleteMutation = useSillDeleteMutation(id!);

    const updateContraProfileMutation = useSillUpdateContraProfileMutation(id!);
    const updateContourMutation = useSillUpdateContourMutation(id!);
    const updateImageMutation = useSillUpsertImageMutation(id!);

    const deleteCallback = useCallback(async () => {
        await deleteMutation.mutateAsync();
        navigate(PimRoutePath.link(PimRoutePath.sills));
    }, [deleteMutation, navigate]);

    const updateContraProfileCallback = useCallback(
        async (contraProfile: string) => {
            await updateContraProfileMutation.mutateAsync(contraProfile);
        },
        [updateContraProfileMutation],
    );

    const updateContourCallback = useCallback(
        async (contour: string) => {
            await updateContourMutation.mutateAsync(contour);
        },
        [updateContourMutation],
    );

    const updateImageCallback = useCallback(
        async (imageId: string) => {
            await updateImageMutation.mutateAsync(imageId);
        },
        [updateImageMutation],
    );

    if (!sill) return <></>;

    if (isInitialLoading) return <Loading />;

    // @ts-ignore
    return (
        <>
            <EkoCard className={'mb-3'}>
                <EkoCardHeader title={`Dorpel ${sill.code}`}>
                    <EkoCardToolbar>
                        <Link to='edit' relative='path' className='btn btn-primary ms-2'>
                            Dorpel wijzigen
                        </Link>
                    </EkoCardToolbar>
                </EkoCardHeader>
                <EkoCardBody>
                    <div className='row mb-4'>
                        <div className='col'>
                            <div className='row mb-4'>
                                <div className='col'>
                                    <label className='form-label'>Sponningtype</label>
                                </div>
                                <div className='col'>
                                    <SillTypeText code={sill.type} />
                                </div>
                            </div>
                            <div className='row mb-4'>
                                <div className='col'>
                                    <label className='form-label'>Actief</label>
                                </div>
                                <div className='col'>
                                    <ActiveBadge value={sill} />
                                </div>
                            </div>

                            <div className='row mb-4'>
                                <div className='col'>
                                    <label className='form-label'>Breedte (sponning + dam)</label>
                                </div>
                                <div className='col'><Mm value={sill.width} /> (<Mm value={sill.rabbetWidth} /> + <Mm
                                    value={sill.width - sill.rabbetWidth} />)
                                </div>
                            </div>

                            {sill.type === SillType.HefSchuif &&
                                <div className='row mb-4'>
                                    <div className='col'>
                                        <label className='form-label'>Rand</label>
                                    </div>
                                    <div className='col'><Mm value={sill.rand} /></div>
                                </div>
                            }

                            <div className='row mb-4'>
                                <div className='col'>
                                    <label className='form-label'>Prijscomponenten</label>
                                </div>
                            </div>

                            {sill.priceComponents && <>
                                <div className='row mb-4'>
                                    <div className='col'>
                                        Basis dorpel
                                    </div>
                                    <div className='col'>{sill.priceComponents?.baseSill}</div>
                                </div>
                                <div className='row mb-4'>
                                    <div className='col'>
                                        Verbreden (optioneel)
                                    </div>
                                    <div className='col'>{sill.priceComponents?.extender ||
                                        <span className='text-muted'>geen</span>}</div>
                                </div>
                            </>}

                            <div className='row mb-4'>
                                <div className='col'>
                                    <label className='form-label'>Dorpelafbeelding</label>
                                </div>
                                <div className='col'>
                                    <div className='position-relative'>
                                        <FileUpload
                                            onFileUpload={updateImageCallback}
                                            show='drag'
                                            fileCategory={FileCategory.Dorpelafbeelding}
                                            sasUri={sill.imageReference?.blobUri ?? ''}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='row mb-4 position-relative'>
                                <div className='col-3'>
                                    <label className='form-label'>Contour</label>
                                </div>
                                <div className='col'>
                                    <ProfileDropzone onSelect={(selected) => updateContourCallback(selected)}
                                        preview={(profile) => <SillContourSvg profile={profile} />}
                                        show={'drag'}>
                                        {({ openFileDialog }) => (
                                            <>
                                                <div
                                                    className={`form-control d-flex justify-content-center align-items-center ${sill.contour ? '' : 'cursor-pointer'}`}
                                                    onClick={() => {
                                                        if (!sill.contour) openFileDialog();
                                                    }}
                                                    onDoubleClick={openFileDialog}
                                                    style={{ height: '200px' }}
                                                >
                                                    {sill.contour && <SillContourSvg profileSvg={sill.contourSvg}
                                                        svgHeight='140px' />}

                                                    {!sill.contour && (
                                                        <div
                                                            className='d-flex flex-column justify-content-center align-items-center'>
                                                            <button type='button' className='btn btn-light-primary me-3'
                                                                onClick={openFileDialog}>
                                                                <i className='fa-regular fa-folder-open'></i> Blader
                                                            </button>
                                                            <div className='mt-2'>of sleep een .DXF bestand hier</div>
                                                        </div>
                                                    )}
                                                </div>
                                                {sill.contour && (
                                                    <div className='py-3 d-flex justify-content-start'>
                                                        <button type='button' className='btn btn-light-primary me-3'
                                                            onClick={openFileDialog}>
                                                            Wijzig contour
                                                        </button>
                                                        {/*<button type="button" className="btn btn-link"*/}
                                                        {/*        onClick={() => console.log('reset')*/}
                                                        {/*        }>Wissen*/}
                                                        {/*</button>*/}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </ProfileDropzone>
                                </div>
                            </div>

                            <div className='row mb-4'>
                                <div className='col-3'>
                                    <label className='form-label'>Contour profiel string</label>
                                </div>
                                <div className='col'>
                                    <div className='input-group'>
                                        <input className='form-control' value={sill.contour ?? ''}
                                            readOnly={true} />
                                        <button className='btn btn-outline-secondary'
                                            onClick={() => {
                                                if (sill.contour)
                                                    navigator.clipboard.writeText(sill.contour);
                                            }
                                            }
                                            disabled={!sill.contour} type='button'>
                                            <i className='fa-regular fa-copy'></i>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className='row mb-4 position-relative'>
                                <div className='col-3'>
                                    <label className='form-label'>Contra profiel</label>
                                </div>
                                <div className='col'>
                                    <ProfileDropzone
                                        onSelect={(selected) => updateContraProfileCallback(selected)}
                                        preview={(profile) => <SillContraSvg profile={profile}
                                            defaultNeutHeight={sill.neutOptions.defaultNeutHeight} />}
                                        show={'drag'}
                                    >
                                        {({ openFileDialog }) => (
                                            <>
                                                <div
                                                    className={`form-control d-flex justify-content-center align-items-center ${sill.contraProfile ? '' : 'cursor-pointer'}`}
                                                    onClick={() => {
                                                        if (!sill.contraProfile) openFileDialog();
                                                    }}
                                                    onDoubleClick={openFileDialog}
                                                    style={{ height: '200px' }}
                                                >
                                                    {sill.contraProfile &&
                                                        <SillContraSvg profileSvg={sill.contraProfileSvg}
                                                            defaultNeutHeight={sill.neutOptions.defaultNeutHeight}
                                                            svgHeight='140px' />}

                                                    {!sill.contraProfile && (
                                                        <div
                                                            className='d-flex flex-column justify-content-center align-items-center'>
                                                            <button type='button' className='btn btn-light-primary me-3'
                                                                onClick={openFileDialog}>
                                                                <i className='fa-regular fa-folder-open'></i> Blader
                                                            </button>
                                                            <div className='mt-2'>of sleep een .DXF bestand hier</div>
                                                        </div>
                                                    )}
                                                </div>
                                                {sill.contraProfile && (
                                                    <div className='py-3 d-flex justify-content-start'>
                                                        <button type='button' className='btn btn-light-primary me-3'
                                                            onClick={openFileDialog}>
                                                            Wijzig contra profiel
                                                        </button>
                                                        {/*<button type="button" className="btn btn-link"*/}
                                                        {/*        onClick={() => console.log('reset')*/}
                                                        {/*        }>Wissen*/}
                                                        {/*</button>*/}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </ProfileDropzone>
                                </div>
                            </div>

                            <div className='row mb-4'>
                                <div className='col-3'>
                                    <label className='form-label'>Contra profiel string</label>
                                </div>
                                <div className='col'>
                                    <div className='input-group'>
                                        <input className='form-control' value={sill.contraProfile ?? ''}
                                            readOnly={true} />
                                        <button className='btn btn-outline-secondary'
                                            onClick={() => {
                                                if (sill?.contraProfile)
                                                    navigator.clipboard.writeText(sill.contraProfile);
                                            }}
                                            disabled={!sill.contraProfile} type='button'>
                                            <i className='fa-regular fa-copy'></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </EkoCardBody>
            </EkoCard>

            <SillDetailsCompartmentLayoutsCard sill={sill} />

            <SillDetailsNeutPositionsCard sill={sill} />

            <EkoCard className={'mb-3'}>
                <EkoCardHeader title={'Neuten'}>
                    <EkoCardToolbar>
                        <button type='button' className='btn btn-primary' disabled>
                            Wijzigen (TODO)
                        </button>
                    </EkoCardToolbar>
                </EkoCardHeader>
                <EkoCardBody>

                    <div className='row mb-4'>
                        <div className='col-2'>
                            <label className='form-label'>Heeft neuten?</label>
                        </div>
                        <div className='col'>
                            <BooleanText value={sill.neutOptions.hasNeuten} />
                        </div>
                    </div>

                    {sill.neutOptions.hasNeuten && <>
                        <div className='row mb-4'>
                            <div className='col-2'>
                                <label className='form-label'>Standaard neut hoogte</label>
                            </div>
                            <div className='col'>
                                <Mm value={sill.neutOptions.defaultNeutHeight} />
                            </div>
                        </div>
                        <div className='row mb-4'>
                            <div className='col-2'>
                                <label className='form-label'>Afwijkende hoogte mogelijk</label>
                            </div>
                            <div className='col'>
                                <BooleanText value={sill.neutOptions.customHeight} />
                            </div>
                        </div>

                        <div className='row mb-4'>
                            <div className='col-2'>
                                <label className='form-label'>Standaard breedtes</label>
                            </div>
                            <div className='col'>
                                {sill.neutOptions.standardWidths?.map((w, index) => (<span key={index}>
                                    {index > 0 ? ', ' : ''}
                                    <Mm value={w} />
                                </span>))}
                            </div>
                        </div>

                        <div className='row mb-4'>
                            <div className='col-2'>
                                <label className='form-label'>Afwijkende breedte mogelijk</label>
                            </div>
                            <div className='col'>
                                <BooleanText value={sill.neutOptions.customWidth} />
                            </div>
                        </div>

                        <div className='row mb-4'>
                            <div className='col-2'>
                                <label className='form-label'>Minimale breedte</label>
                            </div>
                            <div className='col'>
                                <Mm value={sill.neutOptions.minWidth} />
                            </div>
                        </div>

                        <div className='row mb-4'>
                            <div className='col-2'>
                                <label className='form-label'>Positie bevestigingsgat gefixeerd</label>
                            </div>
                            <div
                                className='col'><BooleanText
                                    value={sill.neutOptions.drillHoleOptions?.fixedPositions} />
                            </div>
                        </div>

                        <div className='row mb-4'>
                            <div className='col-2'>
                                <label className='form-label'>Positioneringsstrategie bevestigingsgaten
                                    horizontaal</label>
                            </div>
                            <div
                                className='col'>{sill.neutOptions.drillHoleOptions?.positioningStrategyName}
                            </div>
                        </div>

                        <div className='row mb-4'>
                            <div className='col-2'>
                                <label className='form-label'>Positie bevestigingsgat #1</label>
                            </div>
                            <div
                                className='col'><Mm
                                    value={sill.neutOptions.drillHoleOptions?.defaultYPositions?.drillHole1} />
                            </div>
                        </div>
                        <div className='row mb-4'>
                            <div className='col-2'>
                                <label className='form-label'>Positie bevestigingsgat #2</label>
                            </div>
                            <div
                                className='col'><Mm
                                    value={sill.neutOptions.drillHoleOptions?.defaultYPositions?.drillHole2} />
                            </div>
                        </div>

                    </>}

                </EkoCardBody>
            </EkoCard>

            <div className='row'>
                <div className='col mb-3'>
                    <EkoCard>
                        <EkoCardHeader title={'Voormontage'}>
                            <EkoCardToolbar>
                                <button type='button' className='btn btn-primary' disabled>
                                    Wijzigen (TODO)
                                </button>
                            </EkoCardToolbar>
                        </EkoCardHeader>
                        <EkoCardBody>
                            {sill.assemblyOptions.preAssembly &&
                                <SillAssemblyOptionDetails assemblyOption={sill.assemblyOptions.preAssembly} />
                            }
                        </EkoCardBody>
                    </EkoCard>
                </div>
                <div className='col mb-3'>
                    <EkoCard>
                        <EkoCardHeader title={'Namontage'}>
                            <EkoCardToolbar>
                                <button type='button' className='btn btn-primary' disabled>
                                    Wijzigen (TODO)
                                </button>
                            </EkoCardToolbar>
                        </EkoCardHeader>
                        <EkoCardBody>
                            {sill.assemblyOptions.postAssembly &&
                                <SillAssemblyOptionDetails assemblyOption={sill.assemblyOptions.postAssembly} />
                            }
                        </EkoCardBody>
                    </EkoCard>
                </div>
            </div>

            {(sill.type === SillType.Binnensponning || sill.type === SillType.Vouwwand) &&
                <EkoCard className={'mb-3'}>
                    <EkoCardHeader title={'Afwaterings/ontluchtingsgaten'}>
                        <EkoCardToolbar>
                            <button type='button' className='btn btn-primary' disabled>
                                Wijzigen (TODO)
                            </button>
                        </EkoCardToolbar>
                    </EkoCardHeader>
                    <EkoCardBody>

                        <div className='row mb-4'>
                            <div className='col-3'>
                                Maximale tussenafstand
                            </div>
                            <div className='col'>
                                {(sill.aerationAndDrainageOptions?.maxSpacing && <>{sill.aerationAndDrainageOptions?.maxSpacing}mm</>) || <>-</>}
                            </div>
                        </div>

                        <strong>Gat bovenkant</strong>
                        <div className='row mb-4'>
                            <div className='col-3'>
                                <label className='form-label'>Y-positie</label>
                            </div>
                            <div
                                className='col'>{(sill.aerationAndDrainageOptions?.holeTop?.y && <>{sill.aerationAndDrainageOptions?.holeTop.y}mm</>) || <>-</>}</div>
                        </div>
                        <div className='row mb-4'>
                            <div className='col-3'>
                                <label className='form-label'>Diepte</label>
                            </div>
                            <div
                                className='col'>{(sill.aerationAndDrainageOptions?.holeTop?.depth && <>{sill.aerationAndDrainageOptions?.holeTop.depth}mm</>) || <>-</>}</div>
                        </div>
                        <div className='row mb-4'>
                            <div className='col-3'>
                                <label className='form-label'>Diameter</label>
                            </div>
                            <div
                                className='col'>{(sill.aerationAndDrainageOptions?.holeTop?.diameter && <>{sill.aerationAndDrainageOptions?.holeTop.diameter}mm</>) || <>-</>}</div>
                        </div>

                        <strong>Gat buitenkant</strong>
                        <div className='row mb-4'>
                            <div className='col-3'>
                                <label className='form-label'>Y-positie</label>
                            </div>
                            <div
                                className='col'>{(sill.aerationAndDrainageOptions?.holeFront?.y && <>{sill.aerationAndDrainageOptions?.holeFront.y}mm</>) || <>-</>}</div>
                        </div>
                        <div className='row mb-4'>
                            <div className='col-3'>
                                <label className='form-label'>Diepte</label>
                            </div>
                            <div
                                className='col'>{(sill.aerationAndDrainageOptions?.holeFront?.depth && <>{sill.aerationAndDrainageOptions?.holeFront.depth}mm</>) || <>-</>}</div>
                        </div>
                        <div className='row mb-4'>
                            <div className='col-3'>
                                <label className='form-label'>Diameter</label>
                            </div>
                            <div
                                className='col'>{(sill.aerationAndDrainageOptions?.holeFront?.diameter && <>{sill.aerationAndDrainageOptions?.holeFront.diameter}mm</>) || <>-</>}</div>
                        </div>
                    </EkoCardBody>
                </EkoCard>}

            <EkoCard className={'mb-3'}>
                <EkoCardHeader title={'Sluitpotgebied'}>
                    <EkoCardToolbar>
                        <EkoCardToolbar>
                            <Link to={'./sluitpot-areas/add'} className='btn btn-primary'>Toevoegen</Link>
                        </EkoCardToolbar>
                    </EkoCardToolbar>
                </EkoCardHeader>
                <EkoCardBody>
                    <EkoTable>
                        <TableHeader>
                            <th className='fit-content'>Code</th>
                            <th>Naam</th>
                            <th className='fit-content text-end'>Gebied</th>
                            <th className='fit-content text-end'>Basis-Y</th>
                            <th className='fit-content text-end'>Basis-Z</th>
                            <th className='fit-content text-end'>Hoek</th>
                        </TableHeader>
                        <tbody>
                            {sill.areas?.map((a, index) => (
                                <tr key={index}>
                                    <td className='fit-content'>{a.code}</td>
                                    <td>{a.name}</td>
                                    <td className='fit-content text-end '>
                                        {a.from}-{a.to}mm
                                    </td>
                                    <td className='fit-content text-end'>{a.originY}mm</td>
                                    <td className='fit-content text-end'>{a.originZ}mm</td>
                                    <td className='fit-content text-end'>{a.angle} graden</td>
                                    <td className='fit-content text-end'>
                                        <Link to={`./sluitpot-areas/${a.id}`}
                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                        </Link>
                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    </EkoTable>
                </EkoCardBody>
            </EkoCard>

            {/*<EkoCard className={'mb-3'}>*/}
            {/*	<EkoCardHeader title={'Configurator'}>*/}
            {/*		<EkoCardToolbar>*/}
            {/*			<Link to='configurator' relative='path' className='btn btn-primary ms-2'>*/}
            {/*				Beheer Configurator*/}
            {/*			</Link>*/}
            {/*		</EkoCardToolbar>*/}
            {/*	</EkoCardHeader>*/}
            {/*	<EkoCardBody>*/}
            {/*		{!isInitialLoadingOptions && !isErrorOptions && options && (*/}
            {/*			<>*/}
            {/*				<div className='mb-5'>*/}
            {/*					<div className='row'>*/}
            {/*						<div className='col-2'>*/}
            {/*							<label className='form-label'>Maximale lengte</label>*/}
            {/*						</div>*/}
            {/*						<div className='col'>{options?.maxLength}mm</div>*/}
            {/*					</div>*/}

            {/*					<div className='row'>*/}
            {/*						<div className='col-2'>*/}
            {/*							<label className='form-label'>Kleur</label>*/}
            {/*						</div>*/}
            {/*						<div className='col'>*/}
            {/*							<BadgeList options={options.colors.options} default={options.colors.default} />*/}
            {/*						</div>*/}
            {/*					</div>*/}
            {/*				</div>*/}
            {/*			</>*/}
            {/*		)}*/}
            {/*	</EkoCardBody>*/}
            {/*</EkoCard>*/}

            <EkoCard className={'mb-3'}>
                <EkoCardHeader title={'Gevarenzone'}></EkoCardHeader>
                <EkoCardBody>
                    <div className='row'>
                        <div className='col-10'>
                            <strong>Dorpel archiveren</strong>
                            <p>Een gearchiveerde dorpel kan niet meer worden besteld.</p>
                        </div>
                        <div className='col d-flex justify-content-end'>
                            <div>
                                <button type='button' className='btn btn-secondary' disabled>
                                    Dorpel archiveren (TODO)
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-10'>
                            <strong>Dorpel verwijderen</strong>
                            <p>Verwijder deze dorpel definitief.</p>
                        </div>
                        <div className='col d-flex justify-content-end'>
                            <div>
                                <button type='button' className='btn btn-danger' disabled={false}
                                    onClick={() => openDeleteDialog.current?.call(this, true)}>
                                    Dorpel verwijderen
                                </button>
                                <ConfirmDialog
                                    show={openDeleteDialog}
                                    onConfirm={deleteCallback}
                                    dialogStyle='danger'
                                    title='Verwijderen'
                                    message='Je staat op het punt deze dorpel te verwijderen. Wil je doorgaan?'
                                    confirmText='Dorpel verwijderen'
                                />
                            </div>
                        </div>
                    </div>
                </EkoCardBody>
            </EkoCard>
        </>
    );
};

export default SillDetailsPage;

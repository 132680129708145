import React from 'react';
import {useQuery} from '@tanstack/react-query';
import ApiClient from '../../modules/api-client/ApiClient';
import Loading from './Loading';
import Error from './Error';
import {ConfigurationPreview} from './configurator/ConfigurationForm';

export type ConfiguredSillSvgProps = {
	id?: string;
	catalogItemId?: string;
	svg?: string;

	svgWidth?: string;
	svgHeight?: string;

	onNeutClick: (index: number) => void;
};

const ConfiguredSillSvg: React.FC<ConfiguredSillSvgProps> = (props) => {
	const renderOnServer = !props.svg && !!(props.id || props.catalogItemId);

	const queryKey = props.id ? ['ApiClient.Pim.Render.renderConfiguredSillGet', props.id] : ['ApiClient.Pim.Render.renderConfiguredSillCatalogItemGet', props.catalogItemId];

	const {
		isInitialLoading,
		data: serverSvg,
		isError,
		refetch,
	} = useQuery<string, string, string>(
		queryKey,
		async () => {
			if (props.id) {
				const response = await ApiClient.Pim.Render.renderConfiguredSillGet(props.id, false);
				return response.data;
			} else {
				const response = await ApiClient.Pim.Render.renderConfiguredSillCatalogItemGet(props.catalogItemId, false);
				return response.data;
			}
		},
		{
			enabled: renderOnServer,
		}
	);

	if (renderOnServer && isInitialLoading) return <Loading message={'Dorpel tekening ophalen....'} />;

	if (renderOnServer && isError) return <Error />;

	const svg = renderOnServer ? serverSvg : props.svg;

	if (!svg) return <></>;

	return <ConfigurationPreview svg={svg} onRetry={() => refetch()} isPending={renderOnServer && isInitialLoading} isError={renderOnServer && isError} onNeutClick={props.onNeutClick} />;

	// <div className='styled-scrollbars' style={{overflowX: 'scroll'}}>
	// 	<div className='sill-configuration-preview-container' style={{height: props.svgHeight}}
	// 		 dangerouslySetInnerHTML={{__html: svg}} />
	// </div>;
};

export default ConfiguredSillSvg;

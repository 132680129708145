import React from "react";
import Loading from "./Loading";

export interface WallConnectionPreviewProps {
    svg?: string | null;
    isLoading?: boolean;
}

const WallConnectionPreview: React.FC<WallConnectionPreviewProps> = (props) => {

        if (!props.svg) return <></>;

        if (props.isLoading === true) {
            return <div className="preview-svg">
                <Loading showIcon={false} message="Voorbeeld wordt geladen..."/>
            </div>
        }

        return <div className="preview-svg"
                    style={{maxHeight: 'unset', height: 'unset'}}
                    dangerouslySetInnerHTML={{__html: props.svg}}/>
    }
;

export default WallConnectionPreview;

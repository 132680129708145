import { UseQueryResult, useQuery } from "@tanstack/react-query";
import {toast} from "react-hot-toast"
import ApiClient from "../../../../modules/api-client/ApiClient";
import { ContactPersonRepresentation } from "../../../../modules/api-client/generated";
import { isNotEmpty } from "../../../../../_metronic/helpers";
import { AxiosError } from "axios";

export const useListCustomerContactPersons = (customerId: string | null | undefined, enabled: boolean | undefined = true) : 
                UseQueryResult<Array<ContactPersonRepresentation>, unknown> => {  
    
    var key = [`v1/crm/customer/${customerId}/contactperson/list`]
    
    const response = useQuery(key, () => { 
        return ApiClient.Crm.Customer.crmCustomerCustomerIdContactpersonListGet(customerId || '')
        .then(d => d.data);        
    },
    { enabled: enabled && isNotEmpty(customerId) }
    );

    if (response.error) {                      
        var error = response.error as AxiosError;
        if (error?.message) toast.error(error?.message);
        // toast.error(error?.response?.data?.title || 'Onbekende fout!');
    }

    return response;
};

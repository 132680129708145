import React, {createContext, FC, useContext, useState} from 'react';
import {
    ConfiguratorSluitpotBeslag,
    SillConfigurationSluitpot,
    SillSluitpotArea,
} from '../../../modules/api-client/generated';
import {Field, Form, Formik, useFormikContext} from 'formik';
import {SexySelect, SexySelectItemProps} from "./SexySelect.jsx";
import {DateTime} from "luxon";

export interface SluitpotEditorProps {
    value?: SillConfigurationSluitpot | null;
    onChange: (value: SillConfigurationSluitpot) => void;
    dagmaat: number;
    sillWidth: number;
    areas: { [key: string]: SillSluitpotArea };
    sluitpotOptions: { [key: string]: Array<ConfiguratorSluitpotBeslag> };
    sluitpotHardware: { [key: string]: ConfiguratorSluitpotBeslag };
    children?: React.ReactNode | ((config: SluitpotEditorConfig) => React.ReactNode);
}

export interface SluitpotEditorConfig {
    submit: () => Promise<void>;
    content: React.ReactNode;
    isSubmitting: boolean;
    isInitializing: boolean;
}

export interface SluitpotEditorContextValue {
    isInitializing: boolean;
    areaId: string;
    setAreaId: (value: string) => void;
}

export const SluitpotEditorContext = createContext<SluitpotEditorContextValue>(undefined!);

export const SluitpotEditor: FC<SluitpotEditorProps> = (props) => {
    const sluitpotOptions = props.sluitpotOptions;

    const [areaId, setAreaId] = useState<string>(() => {
        let defaultAreaId = Object.keys(sluitpotOptions)[0];

        for (let areaId in props.areas) {
            const area = props.areas[areaId];
            if (area.code === 'sponning') {
                defaultAreaId = areaId;
                break;
            }
        }

        return defaultAreaId;
    });

    const onSubmit = async (value: SillConfigurationSluitpot) => {
        await props.onChange(value);
    };

    const area = props.areas[areaId];

    let y = area.originY;

    if (area.originY < area.from) {
        y = area.from;
    } else if (area.originY > area.to) {
        y = area.to;
    }

    const initialValues = props.value ?? {
        sluitpotBeslagId: sluitpotOptions[areaId][0].id,
        position: {
            x: props.dagmaat / 2,
            y: y,
        },
        includeHardware: false,
    };

    return (
        <Formik initialValues={initialValues} enableReinitialize={true} onSubmit={onSubmit}>
            {(formik) => {
                const contextValue: SluitpotEditorContextValue = {
                    isInitializing: false,
                    areaId: areaId,
                    setAreaId: setAreaId,
                };

                return (
                    <SluitpotEditorContext.Provider value={contextValue}>
                        <Form>
                            <SluitpotEditorChildren {...props} />
                        </Form>
                    </SluitpotEditorContext.Provider>
                );
            }}
        </Formik>
    );
};

const SluitpotEditorChildren: React.FC<SluitpotEditorProps> = (props) => {
    const {isInitializing} = useContext(SluitpotEditorContext);

    const {submitForm, isSubmitting} = useFormikContext<SillConfigurationSluitpot>();

    const editorContent = <SluitpotEditorContent {...props} />;

    const childNodes =
        typeof props.children === 'undefined'
            ? editorContent
            : typeof props.children === 'function'
                ? props.children?.call(this, {
                    content: editorContent,
                    submit: submitForm,
                    isSubmitting: isSubmitting,
                    isInitializing: isInitializing,
                })
                : props.children;

    return <>{childNodes}</>;
};

const SluitpotEditorContent: React.FC<SluitpotEditorProps> = (props) => {
    const {areaId, setAreaId} = useContext(SluitpotEditorContext);
    const {values, setValues} = useFormikContext<SillConfigurationSluitpot>();
    const hardwareOptions = props.sluitpotOptions[areaId] ?? [];
    const changeHardware = (hardwareId: string): void => {
        setValues((curr) => {
            return {...curr, sluitpotBeslagId: hardwareId};
        });
    };

    return (
        <div>
            {DateTime.now() <= DateTime.fromISO('2024-10-30') &&
                <div className="row">
                    <div className="col">
                        <div className="alert alert-danger" role="alert">
                            <strong>Let op:</strong> Voer de X-Positie in vanuit de dagkant. Voorheen was dat vanuit de
                            sponning.
                        </div>
                    </div>
                </div>
            }
            <div className="row mb-4">
                <div className="col-7">
                    <label className="form-label">
                        <small className="text-uppercase text-muted">Type sluitpot</small>
                    </label>
                    <SexySelect
                        options={hardwareOptions}
                        value={values.sluitpotBeslagId}
                        itemValue={(x) => x.id}
                        itemLabel={(x) => x.name}
                        onChange={(hardwareId) => changeHardware(hardwareId)}
                        labelComponent={(p) => SluitpotItemLabel({...p, sluitpotHardware: props.sluitpotHardware})}
                        required={true}
                    />
                </div>
                <div className="col">
                    <label className="form-label">
                        <small className="text-uppercase text-muted">Beslag meeleveren?</small>
                    </label>
                    <Field as="select" className="form-select" name={`includeHardware`}>
                        <option value={'true'}>Ja</option>
                        <option value={'false'}>Nee</option>
                    </Field>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-3">
                    <label className="form-label">
                        <small className="text-uppercase text-muted">Locatie</small>
                    </label>
                    <select className="form-select" value={areaId} onChange={(e) => setAreaId(e.target.value)} required>
                        {Object.keys(props.sluitpotOptions).map((areaId) => {
                            const area = props.areas![areaId];
                            return (
                                <option key={areaId} value={area.id}>
                                    {area.name}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <div className="col-6">
                    <label className="form-label">
                        <small className="text-uppercase text-muted">X-Positie (links / rechts)</small>
                    </label>
                    <div className="input-group">
                        <Field type="number" className="form-control text-end" placeholder="Links"
                               name={`position.x`} required/>
                        <div className="input-group-text">/</div>
                        <input type="number" className="form-control text-end" placeholder="Rechts"
                               value={values.position?.x ? props.dagmaat - values.position.x : ''}
                               onChange={async (e) => {
                                   const value = parseFloat(e.target.value);
                                   await setValues((curr) => {
                                       return {...curr, position: {...curr.position, x: props.dagmaat - value}};
                                   })
                               }}
                        />
                        <div className="input-group-text">mm</div>
                    </div>
                    <small className="text-muted"><i>Hart, vanuit de dagkant</i></small>
                </div>
                <div className="col-3">
                    <label className="form-label">
                        <small className="text-uppercase text-muted">Y-Positie</small>
                    </label>
                    <div className="input-group">
                        <Field type="number" className="form-control text-end" placeholder="Y-Positie"
                               name={`position.y`} required/>
                        <div className="input-group-text">mm</div>
                    </div>
                    <small className="text-muted"><i>Vanuit de binnenkant dorpel</i></small>
                </div>
            </div>
        </div>
    );
};

type SluitpotItemLabelProps = SexySelectItemProps<ConfiguratorSluitpotBeslag, string> & {
    sluitpotHardware: { [key: string]: ConfiguratorSluitpotBeslag };
};

const SluitpotItemLabel = (props: SluitpotItemLabelProps) => {
    const hardware = props.sluitpotHardware[props.value!] ?? null;

    return <>
        <div style={{height: '22px', width: '36px'}}>
            {hardware?.imageUrl &&
                <img style={{height: '100%', width: '100%', objectFit: 'contain'}} src={hardware.imageUrl} alt=""/>}
        </div>
        <span className="ms-3">{props.label}</span>
    </>;
};

import { toast } from "react-hot-toast"
import {useEkosietMutation} from "../../../../shared/hooks/useEkosietMutation";
import {NotFound, ValidationFailed} from "../../../../modules/api-client/Responses";
import ApiClient from "../../../../modules/api-client/ApiClient";
import {
    PutProductionItemOnPalletRepresentation,
    PutProductionItemStatusRepresentation
} from "../../../../modules/api-client/generated";
import {useQueryClient} from "@tanstack/react-query";

export const usePutProductionItemOnPallet = () => {
    const queryClient = useQueryClient()
    return useEkosietMutation<void, NotFound | ValidationFailed, PutProductionItemOnPalletRepresentation>((command) => {
            return ApiClient.Pm.ProductionItem.pmProductionItemOnPalletPut(undefined,command).then();
    },
        {
            onSuccess: () => {

                toast.success("Productie item op pallet");

                queryClient.invalidateQueries(['emExpeditionOrderExpeditionByWeekGet']);

            },
            onError: () => {
                toast.error("Productie item gereed melden mislukt");
            }
        }
    );
};

import {ProductionItemOperations} from "../../../../modules/api-client/generated";

export interface IStation {
    code: string
    title: string
    color: string
    icon: string | undefined
}

export const stationLookup: Map<string,IStation> = new Map()
    .set(ProductionItemOperations.NeutenGemaakt, {
        code: ProductionItemOperations.NeutenGemaakt
        , title: "Neuten frezen"
        , color: null
        , icon: undefined
    })
    .set(ProductionItemOperations.DorpelProfielGezaagd, {
        code: ProductionItemOperations.DorpelProfielGezaagd
        , title: "Dorpel zagen"
        , color: null
        , icon: undefined
    })
    .set(ProductionItemOperations.LattenGemaakt, {
        code: ProductionItemOperations.LattenGemaakt
        , title: "Latten zagen"
        , color: null
        , icon: undefined
    })
    .set(ProductionItemOperations.DorpelBewerkt, {
        code: ProductionItemOperations.DorpelBewerkt
        , title: "Dorpel bewerken"
        , color: null
        , icon: undefined
    })
    .set(ProductionItemOperations.NeutenGemonteerd, {
        code: ProductionItemOperations.NeutenGemonteerd
        , title: "Neuten montage"
        , color: "#0274f6"
        , icon: undefined
    })
    .set(ProductionItemOperations.SluitpottenGemonteerd, {
        code: ProductionItemOperations.SluitpottenGemonteerd
        , title: "Sluitpotten montage"
        , color: "#ac7bff"
        , icon: undefined
    })
    .set(ProductionItemOperations.LattenGemonteerd, {
        code: ProductionItemOperations.LattenGemonteerd
        , title: "Latten montage"
        , color: "#f90"
        , icon: undefined
    })
    .set(ProductionItemOperations.KopisolatorenGemonteerd, {
        code: ProductionItemOperations.KopisolatorenGemonteerd
        , title: "Kopisolatie plaatsen"
        , color: "#f149db"
        , icon: undefined
    })
    .set(ProductionItemOperations.ManchettesGemonteerd, {
        code: ProductionItemOperations.ManchettesGemonteerd
        , title: "Manchetten plaatsen"
        , color: "#1bbb05"
        , icon: undefined
    })
    .set(ProductionItemOperations.HefschuifVouwwand, {
        code: ProductionItemOperations.HefschuifVouwwand
        , title: "Hefschuif/vouwwand assemblage"
        , color: "#fff107"
        , icon: undefined
    })
    .set(ProductionItemOperations.
        Custom, {
        code: ProductionItemOperations.Custom
        , title: "Specials"
        , color: "#26e7d4"
        , icon: undefined
    })
    .set(ProductionItemOperations.Ingepakt, {
        code: ProductionItemOperations.Ingepakt
        , title: "Inpakken/sealen"
        , color: "#e03e42"
        , icon: undefined
    })
    // Afmelden is geen operatie op een dorpel. Om te laten zien wat de volgende actie van dorpel is maak ik een dummy station voor aan
    .set("afmelden", {
        code:"afmelden"
        , title: "Afmelden"
        , color: "#cccccc"
        , icon: undefined
    })
    .set("spoed", {
        code:"spoed"
        , title: "SPOED"
        , color: "#ef1117"
        , icon: undefined
    })
;

//     [{
//     [ProductionItemOperations.Custom]: {
//         code: ProductionItemOperations.Custom
//         , title: "Specials"
//         , color: "#f90"
//         , icon: undefined
//
//     },
//     [ProductionItemOperations.DorpelBewerkt]: {
//         code: ProductionItemOperations.DorpelBewerkt
//         , title: "Dorpel bewerkt"
//         , color: ""
//         , icon: undefined
//
//     },
// },
// ];

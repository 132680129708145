import React, { ReactElement, useState } from "react";
import { PageTitle } from "../../../../../_metronic/layout/core";
import { EkoCardBody } from "../../../../shared/components/card/EkoCardBody";
import { EkoCard } from "../../../../shared/components/card/EkoCard";
import { Link, useParams } from "react-router-dom";
import {
    ProductionItemOperations,
    ProductionItemRepresentation,
    PutProductionItemOperationRepresentation,
    PutProductionItemStatusRepresentation
} from "../../../../modules/api-client/generated";
import { EkoCardHeader } from "../../../../shared/components/card/EkoCardHeader";
import { EkoCardToolbar } from "../../../../shared/components/card/EkoCardToolbar";
import { SmRoutePath } from "../../sm/SmRoutes";
import { formatDate } from "../../../../shared/components/date";
import { usePutProductionItemOperation } from "../hooks/use-put-production-item-operation";
import { ProductionItemModal } from "../components/ProductionItemModal";
import { PutRemarkForm } from "../components/PutRemarkForm";
import { PutInstructionsForm } from "../components/PutInstructionsForm";
import { usePutProductionItemStarted } from "../hooks/use-put-production-item-started";
import { usePutProductionItemNotStarted } from "../hooks/use-put-production-item-not-started";
import { usePutProductionItemFinished } from "../hooks/use-put-production-item-finished";
import { usePutProductionItemFailed } from "../hooks/use-put-production-item-failed";
import { usePutProductionItemCancelled } from "../hooks/use-put-production-item-cancelled";
import { PutProductionDateForm } from "../components/PutProductionDateForm";
import { EkoTable } from "../../../../shared/components/table/EkoTable";
import { TableHeader } from "../../../../shared/components/table/TableHeader";
import { useProductionItemGroupGetByCode } from "../hooks/use-get-production-item-group";
import { PmRoutePath } from "../PmRoutes";
import { DownloadWorkOrders } from "../components/ProductionItemWorkorder";
import { StationOperationToggle } from "../components/StationOperationToggle";
import { ProductionItemGroupStatus } from "../../../../shared/components/ProductionItemGroupStatus";
import { ProductionItemStatus } from "../../../../shared/components/ProductionItemStatus";
import BooleanBadge from "../../../../shared/components/BooleanBadge";
import {PutKomoCertifiedForm} from "../components/PutKomoCertifiedForm";
import {PutUrgentForm} from "../components/PutUrgentForm";

const ProductionItemGroup: React.FC = () => {

    const [showEditModal, setShowEditModal] = useState<boolean>(false)
    const [modalTitle, setModalTitle] = useState<string | undefined | null>(null);
    const [modalComponent, setModalComponent] = useState<ReactElement | undefined | null>(null);

    const { code } = useParams<{ code: string }>()

    const operationMutation = usePutProductionItemOperation()
    const startMutation = usePutProductionItemStarted()
    const notStartedMutation = usePutProductionItemNotStarted()
    const finishedMutation = usePutProductionItemFinished()
    const failedMutation = usePutProductionItemFailed()
    const cancelledMutation = usePutProductionItemCancelled()
    const { data: group, isLoading, isError } = useProductionItemGroupGetByCode(code)

    if (!code || isLoading || !group) return <></>
    if (!group.items || group.items.length === 0 || isError) return <>Geen productie groep gevonden voor {code}</>

    let productionItemIds = group.items.map(x => x.id);
    let statuses = group.items.map(i => i.status);
    let remark = Array.from(new Set(group.items.map((item: ProductionItemRepresentation) => item.remark))).join('\n\n').trim();
    let plannedProductionDates = Array.from(new Set(group.items.map((item: ProductionItemRepresentation) => formatDate(item.plannedProductionDate)))).join(', ');
    let instructions = Array.from(new Set(group.items.map((item: ProductionItemRepresentation) => item.instructions))).join('\n\n').trim();
    let komoCertification = group.items[0].komoCertified;
    let urgent = group.items[0].urgent;

    let dorpelBewerktToggleValue =  !group.items.every(i => i.stationStatus.dorpelBewerkt=== true);
    let dorpelProfielGezaagdToggleValue =  !group.items.every(i => i.stationStatus.dorpelProfielGezaagd=== true);
    let ingepaktToggleValue =  !group.items.every(i => i.stationStatus.ingepakt=== true);
    let lattenGemaaktToggleValue =  !group.items.every(i => i.stationStatus.lattenGemaakt=== true);
    let neutenGemaaktToggleValue =  !group.items.every(i => i.stationStatus.neutenGemaakt === true);
    let neutenGemonteerdToggleValue =  !group.items.every(i => i.stationStatus.neutenGemonteerd === true);
    let sluitpottenGemonteerdToggleValue =  !group.items.every(i => i.stationStatus.sluitpottenGemonteerd === true);
    let lattenGemonteerdToggleValue =  !group.items.every(i => i.stationStatus.lattenGemonteerd === true);
    let kopisolatorenGemonteerdToggleValue =  !group.items.every(i => i.stationStatus.kopisolatorenGemonteerd === true);
    let manchettesGemonteerdToggleValue =  !group.items.every(i => i.stationStatus.manchettesGemonteerd === true);
    let hefschuifVouwwandToggleValue =  !group.items.every(i => i.stationStatus.hefschuifVouwwand === true);
    let customToggleValue =  !group.items.every(i => i.stationStatus.custom === true);

    const toggleGroup = (operation: ProductionItemOperations, value: boolean) => {
        return new Promise<void>(() => {
            const command: PutProductionItemOperationRepresentation = {
                productionItemIds: productionItemIds,
                value: value,
                operation: operation
            }
            operationMutation.mutate(command, {});
        }).finally();
    }

    const productionDate = (productionDate: string) => {
        setModalComponent(() =>
            <PutProductionDateForm
                handleClose={() => setShowEditModal(false)}
                productionDate={formatDate(productionDate)}
                ids={productionItemIds} />
        )
        setModalTitle('Wijzig productie datum voor alle items in de groep')
        setShowEditModal(true)
    }
    const failed = () => {
        return new Promise<void>(() => {
            const command: PutProductionItemStatusRepresentation = {
                productionItemIds: productionItemIds,
            }
            failedMutation.mutate(command, {});
        }).finally();
    }
    const cancel = () => {
        return new Promise<void>(() => {
            const command: PutProductionItemStatusRepresentation = {
                productionItemIds: productionItemIds,
            }
            cancelledMutation.mutate(command, {});
        }).finally();
    }
    const finish = () => {
        return new Promise<void>(() => {
            const command: PutProductionItemStatusRepresentation = {
                productionItemIds: productionItemIds,
            }
            finishedMutation.mutate(command, {});
        }).finally();
    }
    const started = () => {
        return new Promise<void>(() => {
            const command: PutProductionItemStatusRepresentation = {
                productionItemIds: productionItemIds,
            }
            startMutation.mutate(command, {});
        }).finally();
    }
    const notStarted = () => {
        return new Promise<void>(() => {
            const command: PutProductionItemStatusRepresentation = {
                productionItemIds: productionItemIds,
            }
            notStartedMutation.mutate(command, {});
        }).finally();
    }

    var editRemark = (remark: string) => {
        setModalComponent(() =>
            <PutRemarkForm handleClose={() => setShowEditModal(false)} remark={remark} ids={productionItemIds} />
        )
        setModalTitle('Wijzig opmerking voor alle items in de groep')
        setShowEditModal(true)
    }

    var editInstructions = () => {
        setModalComponent(() =>
            <PutInstructionsForm handleClose={() => setShowEditModal(false)} instructions={instructions}
                ids={productionItemIds} />
        )
        setModalTitle('Wijzig instructies voor alle items in de groep')
        setShowEditModal(true)
    }
    var editKomo = () => {
        setModalComponent(() =>
            <PutKomoCertifiedForm handleClose={() => setShowEditModal(false)} komoCertified={komoCertification} ids={productionItemIds} />,
        );
        setModalTitle('Wijzig komo');
        setShowEditModal(true);
    };
    var editUrgent = () => {
        setModalComponent(() =>
            <PutUrgentForm handleClose={() => setShowEditModal(false)} urgent={urgent} ids={productionItemIds} />,
        );
        setModalTitle('Wijzig spoed label');
        setShowEditModal(true);
    };
    var openDocument = () => {
        setModalComponent(() => <DownloadWorkOrders handleClose={() => setShowEditModal(false)} id={code}
            title={`werkorder-` + group.items[0].title} type={'itemCode'} />)
        setModalTitle('Document')
        setShowEditModal(true)
    }

    return (
        <>
            <PageTitle toolbar={<>
                
                <div>
                    <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle btn-sm  me-3" type="button"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            Update status
                        </button>
                        <ul className="dropdown-menu">
                            <li><a className="dropdown-item" href="#" onClick={() => notStarted()}>Reset</a>
                            </li>
                            <li><a className="dropdown-item" href="#" onClick={() => started()}>Start</a>
                            </li>
                            <li><a className="dropdown-item" href="#" onClick={() => finish()}>Gereed</a>
                            </li>
                            <li><a className="dropdown-item" href="#" onClick={() => cancel()}>Annuleren</a>
                            </li>
                            <li><a className="dropdown-item" href="#" onClick={() => failed()}>Mislukt</a>
                            </li>
                        </ul>
                        <button className={'btn btn-secondary btn-sm me-3'} onClick={() => openDocument()}>Print werkbon</button>
                        <button className={'btn btn-secondary btn-sm me-3 disabled'}>Download Dorpel XML</button>
                    </div>

                </div>
            </>
            }>
                Dorpel - Productie Groep
            </PageTitle>

            <ProductionItemModal show={showEditModal} handleClose={() => setShowEditModal(false)}
                title={modalTitle}
                size={'lg'}
                fullscreen={'false'}
                component={modalComponent}
            />

            <div className={'row'}>
                <div className={'col col-lg-12'}>
                    <EkoCard className={'mb-5 col col-lg-12'}>
                        <EkoCardBody>                        
                            <div className={'row mb-4'}>
                                <div className={'col-2'}>
                                    <label className="form-label">Code</label>
                                </div>
                                <div className={'col-10'}>
                                    {code}
                                </div>
                            </div>

                            <div className={'row mb-4'}>
                                <div className={'col-2'}>
                                    <label className="form-label">Aantal</label>
                                </div>
                                <div className={'col-10'}>
                                    {group.items.length}
                                </div>
                            </div>

                            <div className={'row mb-4'}>
                                <div className={'col-2'}>
                                    <label className="form-label">Status</label>
                                </div>
                                <div className={'col-10'}>
                                    <ProductionItemGroupStatus statuses={statuses} />
                                </div>
                            </div>

                            <div className={'row mb-4'}>
                                <div className={'col-2'}>
                                    <label className="form-label">Productiedatum (gepland)</label>
                                </div>
                                <div className={'col-10'}>
                                    <a href='#'
                                        onClick={() => productionDate(group.items[0].plannedProductionDate)}>
                                        {plannedProductionDates ?
                                            <>{plannedProductionDates}</>
                                            :
                                            <div className={''}>
                                                <i className={'fas fa-edit'}></i> Toevoegen
                                            </div>
                                        }
                                    </a>
                                </div>
                            </div>
                            <div className={'row mb-4'}>
                                <div className={'col-2'}>
                                    <label className="form-label">Klant</label>
                                </div>
                                <div className={'col-10'}>
                                    {group.items[0].customer?.name}
                                </div>
                            </div>
                            <div className={'row mb-4'}>
                                <div className={'col-2'}>
                                    <label className="form-label">Order</label>
                                </div>
                                <div className={'col-10'}>
                                    <Link to={SmRoutePath.link(SmRoutePath.orderEditLink(group.items[0].order?.id!))}
                                        className='text-hover-primary fs-6'
                                    >
                                        {group.items[0].order?.code}
                                    </Link>
                                </div>
                            </div>
                            <div className={'row mb-4'}>
                                <div className={'col-2'}>
                                    <label className="form-label">Merk</label>
                                </div>
                                <div className={'col-10'}>
                                    {group.items[0].merk}
                                </div>
                            </div>
                            <div className={'row mb-4'}>
                                <div className={'col-2'}>
                                    <label className='form-label'>Spoed?</label>
                                </div>
                                <div className={'col'}>
                                    <a href='#'
                                       onClick={() => editUrgent()}>
                                        <div className={''}>
                                            <i className={'fas fa-edit me-1'}></i>
                                            <BooleanBadge value={group.items[0].urgent}/>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className={'row mb-4'}>
                                <div className={'col-2'}>
                                    <label className='form-label'>Komo?</label>
                                </div>
                                <div className={'col'}>
                                    <a href='#'
                                       onClick={() => editKomo()}>
                                        <div className={''}>
                                            <i className={'fas fa-edit me-1'}></i>
                                            <BooleanBadge value={group.items[0].komoCertified}/>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className={'row mb-4'}>
                                <div className={'col-2'}>
                                    <label className="form-label">Opmerking</label>
                                </div>
                                <div className={'col-10'}>
                                    <a href='#'
                                        onClick={() => editRemark(remark)}>
                                        {remark ?
                                            <>{remark}</>
                                            :
                                            <div className={''}>
                                                <i className={'fas fa-edit'}></i> Toevoegen
                                            </div>
                                        }
                                    </a>

                                </div>
                            </div>
                            <div className={'row mb-4'}>
                                <div className={'col-2'}>
                                    <label className="form-label">Instructies</label>
                                </div>
                                <div className={'col-10'}>
                                    <a href='#'
                                        onClick={() => editInstructions()}>
                                        {instructions ?
                                            <div dangerouslySetInnerHTML={{ __html: instructions! }}></div>
                                            :
                                            <div className={''}>
                                                <i className={'fas fa-edit'}></i> Toevoegen
                                            </div>
                                        }
                                    </a>

                                </div>
                            </div>

                        </EkoCardBody>
                    </EkoCard>
                </div>
            </div>

            <div className={'row'}>
                <div className="col col-lg-12">
                    <EkoCard>
                        <EkoCardHeader title='Items in groep'>
                            <EkoCardToolbar>
                            </EkoCardToolbar>

                        </EkoCardHeader>
                        <EkoCardBody>
                            <EkoTable>
                                <TableHeader>
                                    <th>#</th>
                                    <th>
                                        <a href="javascript:void(0)"
                                           onClick={() => toggleGroup(ProductionItemOperations.NeutenGemaakt, neutenGemaaktToggleValue)}>
                                            Neuten gemaakt
                                        </a>
                                    </th>
                                    <th>
                                        <a href="javascript:void(0)"
                                           onClick={() => toggleGroup(ProductionItemOperations.LattenGemaakt, lattenGemaaktToggleValue)}>
                                            Latten gezaagd
                                        </a>
                                    </th>
                                    <th>
                                        <a href="javascript:void(0)"
                                           onClick={() => toggleGroup(ProductionItemOperations.DorpelProfielGezaagd, dorpelProfielGezaagdToggleValue)}>
                                            Dorpel gezaagd
                                        </a>
                                    </th>
                                    <th>
                                        <a href="javascript:void(0)"
                                           onClick={() => toggleGroup(ProductionItemOperations.DorpelBewerkt, dorpelBewerktToggleValue)}>
                                            Dorpel bewerkt
                                        </a>
                                    </th>
                                    <th>
                                        <a href="javascript:void(0)"
                                           onClick={() => toggleGroup(ProductionItemOperations.NeutenGemonteerd, neutenGemonteerdToggleValue)}>
                                            Neuten Gemonteerd
                                        </a>
                                    </th>
                                    <th>
                                        <a href="javascript:void(0)"
                                           onClick={() => toggleGroup(ProductionItemOperations.SluitpottenGemonteerd, sluitpottenGemonteerdToggleValue)}>
                                            Sluitpotten
                                        </a>
                                    </th>
                                    <th>
                                        <a href="javascript:void(0)"
                                           onClick={() => toggleGroup(ProductionItemOperations.LattenGemonteerd, lattenGemonteerdToggleValue)}>
                                            Latten plaatsen
                                        </a>
                                    </th>
                                    <th>
                                        <a href="javascript:void(0)"
                                           onClick={() => toggleGroup(ProductionItemOperations.KopisolatorenGemonteerd, kopisolatorenGemonteerdToggleValue)}>
                                            Kopisolatoren
                                        </a>
                                    </th>
                                    <th>
                                        <a href="javascript:void(0)"
                                           onClick={() => toggleGroup(ProductionItemOperations.ManchettesGemonteerd, manchettesGemonteerdToggleValue)}>
                                            Manchettes
                                        </a>
                                    </th>
                                    <th>
                                        <a href="javascript:void(0)"
                                           onClick={() => toggleGroup(ProductionItemOperations.HefschuifVouwwand, hefschuifVouwwandToggleValue)}>
                                            Hefschuif/Vouwwand
                                        </a>
                                    </th>
                                    <th>
                                        <a href="javascript:void(0)"
                                           onClick={() => toggleGroup(ProductionItemOperations.Ingepakt, ingepaktToggleValue)}>
                                            Inpakken
                                        </a>
                                    </th>
                                    <th>
                                        <a href="javascript:void(0)"
                                           onClick={() => toggleGroup(ProductionItemOperations.Custom, customToggleValue)}>
                                            Special
                                        </a>
                                    </th>
                                    <th>Productie Datum</th>
                                    <th>Status</th>
                                </TableHeader>
                                <tbody>
                                {group?.items.sort(function (a, b) {
                                    if (a.sequence < b.sequence) {
                                        return -1;
                                    }
                                    if (a.sequence > b.sequence) {
                                            return 1;
                                        }
                                        return 0;
                                        }).map((pi) => {
                                        return (
                                            <tr key={pi.id}>
                                                <td>
                                                    <Link to={PmRoutePath.link(PmRoutePath.productionItemLink(pi.id!))}
                                                          className='text-hover-primary fs-6'>
                                                        {pi.sequence}
                                                    </Link>
                                                </td>
                                                <td>
                                                    <StationOperationToggle id={pi.id}
                                                                            status={pi.stationStatus.neutenGemaakt}
                                                                            operation={ProductionItemOperations.NeutenGemaakt}
                                                                            title={'Neuten maken'} onlyToggle={true}/>
                                                </td>
                                                <td>
                                                    <StationOperationToggle id={pi.id}
                                                                            status={pi.stationStatus.lattenGemaakt}
                                                                            operation={ProductionItemOperations.LattenGemaakt}
                                                                            title={'Latten maken'} onlyToggle={true}/>
                                                </td>
                                                <td>
                                                    <StationOperationToggle id={pi.id}
                                                                            status={pi.stationStatus.dorpelProfielGezaagd}
                                                                            operation={ProductionItemOperations.DorpelProfielGezaagd}
                                                                            title={'Dorpel zagen'} onlyToggle={true}/>
                                                </td>
                                                <td>
                                                    <StationOperationToggle id={pi.id}
                                                                            status={pi.stationStatus.dorpelBewerkt}
                                                                            operation={ProductionItemOperations.DorpelBewerkt}
                                                                            title={'Dorpel bewerken'}
                                                                            onlyToggle={true}/>
                                                </td>
                                                <td>
                                                    <StationOperationToggle id={pi.id}
                                                                            status={pi.stationStatus.neutenGemonteerd}
                                                                            operation={ProductionItemOperations.NeutenGemonteerd}
                                                                            title={'Neuten monteren'}
                                                                            onlyToggle={true}/>
                                                </td>
                                                <td>
                                                    <StationOperationToggle id={pi.id}
                                                                            status={pi.stationStatus.sluitpottenGemonteerd}
                                                                            operation={ProductionItemOperations.SluitpottenGemonteerd}
                                                                            title={'Sluitpotten monteren'}
                                                                            onlyToggle={true}/>
                                                </td>
                                                <td>
                                                    <StationOperationToggle id={pi.id}
                                                                            status={pi.stationStatus.lattenGemonteerd}
                                                                            operation={ProductionItemOperations.LattenGemonteerd}
                                                                            title={'Latten plaatsen'}
                                                                            onlyToggle={true}/>
                                                </td>
                                                <td>
                                                    <StationOperationToggle id={pi.id}
                                                                            status={pi.stationStatus.kopisolatorenGemonteerd}
                                                                            operation={ProductionItemOperations.KopisolatorenGemonteerd}
                                                                            title={'Kopisolatoren plaatsen'}
                                                                            onlyToggle={true}/>
                                                </td>
                                                <td>
                                                    <StationOperationToggle id={pi.id}
                                                                            status={pi.stationStatus.manchettesGemonteerd}
                                                                            operation={ProductionItemOperations.ManchettesGemonteerd}
                                                                            title={'Manchettes monteren'}
                                                                            onlyToggle={true}/>
                                                </td>
                                                <td>
                                                    <StationOperationToggle id={pi.id}
                                                                            status={pi.stationStatus.hefschuifVouwwand}
                                                                            operation={ProductionItemOperations.HefschuifVouwwand}
                                                                            title={'Hefschuif/vouwwand'}
                                                                            onlyToggle={true}/>
                                                </td>
                                                <td>
                                                    <StationOperationToggle id={pi.id}
                                                                            status={pi.stationStatus.ingepakt}
                                                                            operation={ProductionItemOperations.Ingepakt}
                                                                            title={'Inpakken'} onlyToggle={true}/>
                                                </td>
                                                <td>
                                                    <StationOperationToggle id={pi.id}
                                                                            status={pi.stationStatus.custom}
                                                                            operation={ProductionItemOperations.Custom}
                                                                            title={'Special'} onlyToggle={true}/>
                                                </td>
                                                <td>
                                                    {formatDate(pi.plannedProductionDate)}
                                                </td>
                                                <td>
                                                    <ProductionItemStatus status={pi.status}/>
                                                </td>
                                            </tr>
                                        )
                                    }
                                )
                                }

                                </tbody>
                            </EkoTable>

                        </EkoCardBody>
                    </EkoCard>
                </div>
            </div>
        </>
    )
}

export default ProductionItemGroup

import React from 'react';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {useParams} from 'react-router-dom';
import { Button } from 'react-bootstrap';
import ApiClient from '../../../../../modules/api-client/ApiClient';
import Loading from '../../../../../shared/components/Loading';
import { useEkosietAuth0 } from '../../../../../modules/auth0/core/useEkosietAuth0';
import { formatDate } from '../../../../../shared/components/date';
import { ContactFormDetails } from '../../../../../modules/api-client/generated';

export const ContactFormDetailsPage : React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const queryClient = useQueryClient();
	const {isInitialLoading, data: details, isError} = useQuery<ContactFormDetails>(['contactFormDetailsGet'], () => ApiClient.Crm.ContactForm.getContactFormDetails(id!).then((res) => res.data));
	const {user} = useEkosietAuth0();

	if (isInitialLoading) {
		return <Loading />;
	}

	if (isError) {
		return <>ERROR!</>;
	}

	if (!details) return <></>;

	const handleFollowUp = async () => {
		await ApiClient.Crm.ContactForm.crmContactFormFollowUpPost(id, user?.nickname);
		queryClient.invalidateQueries(['contactFormDetailsGet']).then();
	};

	return (
		<div className='card mb-5'>
			<div className='card-header'>
				<h3 className='card-title'>Contactformulier</h3>
				<div className='card-toolbar'>
					<Button className="btn btn-primary" onClick={handleFollowUp} disabled={details.followedUpAt !== null}>
  						<i className="fas fa-check"></i> Afgehandeld
					</Button>
				</div>
			</div>

			<div className='card-body position-relative'>
				<div className='row mb-3'>
					<div className='col-2'>
						<label className='form-label'>Afgehandeld</label>
					</div>
					<div className='col'>
						{details.followedUpAt !== null 
							? <span className="badge badge-success">Ja</span>
							: <span className="badge text-bg-light">Nee</span>}
					</div>
				</div>
				<div className='row mb-3'>
					<div className='col-2'>
						<label className='form-label'>Naam</label>
					</div>
					<div className='col'>
						{details.name}
					</div>
				</div>
				<div className='row mb-3'>
					<div className='col-2'>
						<label className='form-label'>E-mailadres</label>
					</div>
					<div className='col'>
						{details.email}
					</div>
				</div>
				<div className='row mb-3'>
					<div className='col-2'>
						<label className='form-label'>Telefoonnummer</label>
					</div>
					<div className='col'>
						{details.phoneNumber}
					</div>
				</div>
				<div className='row mb-3'>
					<div className='col-2'>
						<label className='form-label'>Bedrijfsnaam</label>
					</div>
					<div className='col'>
						{details.companyName}
					</div>
				</div>
				<div className='row mb-3'>
					<div className='col-2'>
						<label className='form-label'>Bericht</label>
					</div>
					<div className='col'>
						{details.message}
					</div>
				</div>
				<div className='row mb-3'>
					<div className='col-2'>
						<label className='form-label'>Aangemaakt op</label>
					</div>
					<div className='col'>
						{formatDate(details.createdAt ?? "", "dd-MM-yyyy HH:mm")}
					</div>
				</div>
				<div className='row mb-3'>
					<div className='col-2'>
						<label className='form-label'>Opgevolgd door</label>
					</div>
					<div className='col'>
						{details.followedUpBy}
					</div>
				</div>
				<div className='row mb-3'>
					<div className='col-2'>
						<label className='form-label'>Opgevolgd op</label>
					</div>
					<div className='col'>
						{formatDate(details.followedUpAt ?? "", "dd-MM-yyyy HH:mm")}
					</div>
				</div>
			</div>
		</div>)
};
import {AxiosResponse} from "axios";


export class ValidationFailed {
    constructor(
        public readonly errors: ValidationErrors,
        public readonly response: AxiosResponse) {
    }
}

export class NotFound {
    constructor(
        public readonly response: AxiosResponse) {
    }
}

export type ValidationErrors = { [key: string]: string[] };
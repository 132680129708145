import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { toast } from "react-hot-toast"
import ApiClient from "../../../../modules/api-client/ApiClient";
import { isNotEmpty } from "../../../../../_metronic/helpers";
import { AxiosError } from "axios";
import { CustomerListRepresentation } from "../../../../modules/api-client/generated";

export const useListCustomers = (search?: string, archived?: boolean, enabled: boolean | undefined = true): UseQueryResult<Array<CustomerListRepresentation>, unknown> => {

    var key = [archived ? 'v1/crm/customer/list/archived' : 'v1/crm/customer/list'];
    //if we start supporting search we need to have the qs part of the key

    const response = useQuery<Array<CustomerListRepresentation>,TypeError, Array<CustomerListRepresentation>>(key, () => {
        return ApiClient.Crm.Customer
            .crmCustomerListGet(search, archived)
            .then(d => d.data);
    },
        { enabled: enabled && isNotEmpty(archived) }
    )

    if (response.error) {
        var error = response.error as AxiosError;
        toast.error(error?.message);
        // toast.error(error?.response?.data?.title || 'Onbekende fout!');
    }

    return response;
};

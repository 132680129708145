import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useEkosietAuth0 } from '../../modules/auth0/core/useEkosietAuth0'
import { usePostDraftOrder } from '../../backoffice/modules/sm/orders/hooks/use-post-draft-order'
import {CustomerRoutePath} from "../routing/RoutePath";

const StartOrder = () => {
  
  const navigate = useNavigate();
  let { customerId } = useEkosietAuth0(); // customer id can be empty when user is employee
  

  if (!customerId) {
      navigate(CustomerRoutePath.link(CustomerRoutePath.orders));
  }

  // data mutations
  const { mutateAsync: mutateDraft } = usePostDraftOrder();
    
  useEffect(() => {
    async function draftOrder() {
      const draftOrder = await mutateDraft(customerId);
      navigate(CustomerRoutePath.link(CustomerRoutePath.orderWizardLink(draftOrder.id)));
    }  
    draftOrder();    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <span>Nieuwe order wordt aangemaakt...</span>
    </>
  )
}

export { StartOrder }
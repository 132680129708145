import React, {FC} from "react";
import {ConfirmDialogStyle} from "../../../../../../shared/components/ConfirmDialog";

export interface OrderConfiguratorModalProps {
    show: boolean | (React.MutableRefObject<((show: boolean) => void) | undefined>);
    onHide?: () => void;
    onConfirming?: () => void;
    onConfirmed?: () => void;
    orderId?: string;
    catalogId?: string;
    title?: string;
    message?: string;
    confirmText?: string;
    cancelText?: string;
    dialogStyle?: ConfirmDialogStyle;
    container?: Element;
}
//
// export const DefaultProps: Partial<ConfirmDialogProps> = {
//     title: "Bevestigen",
//     message: "Weet je het zeker?",
//     confirmText: "Bevestigen",
//     cancelText: "Annuleren",
//     dialogStyle: 'primary'
// }
//
// interface OrderLineFormValues {
//     quantity: number,
//     remark?: string,
//     merk?: string
// }

const OrderConfiguratorModal: FC<OrderConfiguratorModalProps> = (props) => {

    // //todo rewrite this
    // props = Object.fromEntries(
    //     Object.entries(props).filter(([k, v]) => v !== undefined)
    // ) as ConfirmDialogProps;
    //
    // props = {...DefaultProps, ...props}
    //
    // if (!props.container) {
    //     props.container = modalsRoot;
    // }
    //
    // const [show, setShow] = useState<boolean>(() => typeof (props.show) === "boolean" ? props.show : false);
    // const [configuration, setConfiguration] = useState<SillConfiguration>();
    //
    // const mutatePostOrderLine = usePostOrderline();
    //
    // const putConfigurationMutation = useEkosietMutation((data: SillConfiguration) => {
    //     return ApiClient.Pim.Configurator.pimConfiguratorPut(undefined, data, {}).then(r => r.data);
    // });
    //
    // const showCallback = useCallback((show: boolean) => {
    //     setShow(show);
    // }, [setShow]);
    //
    // const hideCallback = useCallback(() => {
    //     setShow(false);
    // }, [setShow]);
    //
    // if (typeof (props.show) !== "boolean") {
    //     props.show.current = showCallback;
    // }
    //
    // const handleClose = () => {
    //     setShow(false)
    //     setConfiguration(undefined)
    // }
    //
    // const handleConfigurationConfirmed = async (values: SillConfiguration) => {
    //
    //     setConfiguration(values);
    //     console.log('handleConfigurationConfirmed');
    // };
    //
    // const handleAddOrderLine = async (values: OrderLineFormValues) => {
    //
    //     const result = await putConfigurationMutation.mutateAsync(configuration!);
    //     console.log(result);
    //
    //     const line: PostConfiguratorOrderLineRepresentation = {
    //         ...values,
    //         orderlineType: "configurationLine",
    //         catalogItemId: result.catalogItemId
    //     };
    //     await mutatePostOrderLine.mutateAsync({orderId: props.orderId!, line: line});
    //
    //     handleClose();
    // };
    //
    // return (
    //     <>
    //         {createPortal(
    //             <Modal
    //                 id='kt_configurator_modal'
    //                 tabIndex={-1}
    //                 aria-hidden='true'
    //                 dialogClassName={'modal-dialog modal-xl'}
    //                 show={show}
    //                 fullscreen={true}
    //                 onHide={handleClose}
    //                 animation={false}
    //                 backdrop={true}>
    //
    //                 <Modal.Header>
    //                     <Modal.Title className={'text-nowrap'}> TODO!
    //                     </Modal.Title>
    //                 </Modal.Header>
    //                 <Modal.Body>
    //                     Dit scherm vervangen met nieuwe routed modal
    //                 </Modal.Body>
    //
    //                 {/*{step === 'configure' && <>*/}
    //                 {/*    <Formik initialValues={{} as SillConfiguration} validate={validateSillConfiguration}*/}
    //                 {/*            onSubmit={handleConfigurationConfirmed}>*/}
    //
    //                 {/*        {({submitForm, isSubmitting, isValidating}) => {*/}
    //
    //                 {/*            return (*/}
    //                 {/*                <Form>*/}
    //                 {/*                    <Modal.Header>*/}
    //                 {/*                        <Modal.Title className={'text-nowrap'}>{title}</Modal.Title>*/}
    //
    //                 {/*                        <div className={'d-flex w-100 justify-content-end align-items-center'}>*/}
    //
    //                 {/*                            <button className={'btn btn-link mx-4'} onClick={handleClose}>Annuleren*/}
    //                 {/*                            </button>*/}
    //
    //                 {/*                            {sill &&*/}
    //                 {/*                                <SpinnerButton type="button" className={'btn btn-primary mx-4'}*/}
    //                 {/*                                               onClick={submitForm}*/}
    //                 {/*                                               spinning={isSubmitting || isValidating}>{isSubmitting ? 'Verwerken' : 'Volgende'}</SpinnerButton>}*/}
    //                 {/*                        </div>*/}
    //                 {/*                    </Modal.Header>*/}
    //                 {/*                    <Modal.Body>*/}
    //                 {/*                        <Configurator sillId={sill?.id} onSillSelected={setSill}/>*/}
    //                 {/*                    </Modal.Body>*/}
    //                 {/*                </Form>*/}
    //                 {/*            )*/}
    //                 {/*        }}*/}
    //                 {/*    </Formik>*/}
    //                 {/*</>}*/}
    //
    //                 {/*{step === 'finish' && configuration && <>*/}
    //
    //                 {/*    <Formik*/}
    //                 {/*        initialValues={{quantity: 1}}*/}
    //                 {/*        onSubmit={handleAddOrderLine}>*/}
    //
    //                 {/*        {({submitForm, isSubmitting, isValidating}) => {*/}
    //
    //                 {/*            return (*/}
    //                 {/*                <Form>*/}
    //                 {/*                    <Modal.Header>*/}
    //                 {/*                        <Modal.Title className={'text-nowrap'}>{title}</Modal.Title>*/}
    //                 {/*                    </Modal.Header>*/}
    //                 {/*                    <Modal.Body>*/}
    //                 {/*                        <div className='container'>*/}
    //                 {/*                            <div className="row">*/}
    //                 {/*                                <div className="col-6 offset-3">*/}
    //                 {/*                                    <OrderConfiguratorModalFinish configuration={configuration} configurationId={null}/>*/}
    //                 {/*                                </div>*/}
    //                 {/*                            </div>*/}
    //                 {/*                            <div className="row">*/}
    //                 {/*                                <div className="col-6 offset-3 d-flex justify-content-center">*/}
    //                 {/*                                    <button className={'btn btn-secondary mx-4'}*/}
    //                 {/*                                            onClick={() => setStep('configure')}>Terug*/}
    //                 {/*                                    </button>*/}
    //                 {/*                                    <SpinnerButton type="button" className={'btn btn-primary mx-4'}*/}
    //                 {/*                                                   onClick={submitForm}*/}
    //                 {/*                                                   spinning={isSubmitting}>{isSubmitting ? 'Opslaan...' : 'Toevoegen'}</SpinnerButton>*/}
    //                 {/*                                </div>*/}
    //                 {/*                            </div>*/}
    //                 {/*                        </div>*/}
    //                 {/*                        /!*<PostOrderlineForm orderId={props.orderId!} catalogId={props.catalogId!} orderLineData={data} handleClose={handleClose}/>*!/*/}
    //                 {/*                    </Modal.Body>*/}
    //                 {/*                </Form>*/}
    //                 {/*            )*/}
    //                 {/*        }}*/}
    //                 {/*    </Formik>*/}
    //                 {/*</>}*/}
    //             </Modal>,
    //
    //             modalsRoot)
    //         }
    //     </>
    // )

    return <></>
}

export {
    OrderConfiguratorModal
}

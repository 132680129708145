import {useQuery, useQueryClient} from '@tanstack/react-query';
import ApiClient from '../../../../modules/api-client/ApiClient';
import {NotFound, ValidationFailed} from '../../../../modules/api-client/Responses';
import {useEkosietMutation} from '../../../../shared/hooks/useEkosietMutation';
import {
	CreateProfileModel, ProfileInfo, UpdateProfileBulkActiveRequest, UpdateProfileRequest,
} from '../../../../modules/api-client/generated';


export const useProfileCreateMutation = () => {
	const queryClient = useQueryClient();
	return useEkosietMutation<ProfileInfo[], NotFound | ValidationFailed, CreateProfileModel[]>(async (profiles: CreateProfileModel[]) => {

			const response = await ApiClient.Pim.Profile.createProfile(undefined, profiles);
			return response.data;
		},
		{
			onSuccess: (profiles: ProfileInfo[]) => {

				profiles.forEach(async p => {
					await queryClient.invalidateQueries(['ApiClient.Pim.Profile.getProfile', p.id]);
				});

				queryClient.invalidateQueries(['ApiClient.Pim.Profile.listProfiles']).then();
			},
		},
	);
};

export const useProfileEditMutation = (id: string) => {
	const queryClient = useQueryClient();
	return useEkosietMutation<ProfileInfo, NotFound | ValidationFailed, UpdateProfileRequest>(async (data) => {

			const response = await ApiClient.Pim.Profile.updateProfile(id, undefined, data);
			return response.data;
		},
		{
			onSuccess: async (profile: ProfileInfo) => {

				await queryClient.invalidateQueries(['ApiClient.Pim.Profile.getProfile', profile.id]);
				await queryClient.invalidateQueries(['ApiClient.Pim.Profile.listProfiles']);
			},
		},
	);
};

export const useProfileBulkUpsertMutation = () => {
	const queryClient = useQueryClient();
	return useEkosietMutation<void, NotFound | ValidationFailed, UpdateProfileBulkActiveRequest>(async (command: UpdateProfileBulkActiveRequest) => {
			const response = await ApiClient.Pim.Profile.bulkUpdateProfiles(undefined, command);
			return response.data;
		},
		{
			onSuccess: (command) => {
				queryClient.invalidateQueries(['ApiClient.Pim.Profile.listProfiles']).then();
			},
		},
	);
};


export const useProfileQuery = (id: string | undefined) => {
	return useQuery(['ApiClient.Pim.Profile.getProfile', id],
		async () => {
			const res = await ApiClient.Pim.Profile.getProfile(id!);
			return res.data;
		}, {
			useErrorBoundary: true,
			enabled: !!id,
		});
};




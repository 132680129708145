import {useQuery, useQueryClient} from '@tanstack/react-query';
import {toast} from 'react-hot-toast';
import {useEkosietMutation} from "../../hooks/useEkosietMutation";
import {NotFound, ValidationFailed} from "../../../modules/api-client/Responses";
import ApiClient from "../../../modules/api-client/ApiClient";
import {AxiosError} from "axios";
import {isNotEmpty} from "../../../../_metronic/helpers";
import {
	CreateImportGlazingProfileMappingCommand,
	ImportGlazingProfileMappingRepresentation
} from "../../../modules/api-client/generated";

export const useGetImportGlazingProfileMappings = (enabled: boolean = true) => {
	const response = useQuery<Array<ImportGlazingProfileMappingRepresentation>, AxiosError, Array<ImportGlazingProfileMappingRepresentation>>(
		['aclImportMappingsGlazingProfilesGet'],
		() => {
			return ApiClient.Acl.ImportMapping.aclImportMappingGlazingprofilesGet().then((res) => res.data);
		},
		{
			useErrorBoundary: true,
			enabled: enabled,
		}
	);

	if (response.error) {
		var error = response.error;
		toast.error(error?.message);
	}

	return response;
};


export const useGetImportGlazingProfileMapping = (id: string | undefined, enabled: boolean = true) => {
	const response = useQuery<ImportGlazingProfileMappingRepresentation, AxiosError, ImportGlazingProfileMappingRepresentation>(
		['aclImportGlazingProfileIdGet', id],
		() => {
			return ApiClient.Acl.ImportMapping.aclImportMappingGlazingprofileImportGlazingProfileMappingIdGet(id!).then((res) => res.data);
		},
		{
			useErrorBoundary: true,
			enabled: enabled && isNotEmpty(id),
		}
	);

	if (response.error) {
		var error = response.error;
		toast.error(error?.message);
	}

	return response;
};


export const usePostGlazingProfileMapping = () => {
	const queryClient = useQueryClient();

	return useEkosietMutation<string, ValidationFailed, CreateImportGlazingProfileMappingCommand>(
		(command) => {
			return ApiClient.Acl.ImportMapping.aclImportMappingGlazingprofilePost(undefined, command).then((response) => response.data);
		},
		{
			onSuccess: (data, variables) => {
				queryClient.invalidateQueries(['aclImportMappingsGlazingProfilesGet']);
				queryClient.invalidateQueries(['aclImportGlazingProfileIdGet']);
				toast.success(`Koppeling succesvol toegevoegd of aangepast`);

			},
			onError: () => {
            	toast.error("Koppeling mislukt!");
			},
		}
	);
};

export const useDeleteGlazingProfileMapping = () => {
	const queryClient = useQueryClient();
	return useEkosietMutation<void, NotFound, string>(
		(importProfileMappingId) => {
			return ApiClient.Acl.ImportMapping.aclImportMappingGlazingprofileImportGlazingProfileMappingIdDelete(importProfileMappingId).then((response: any) => response.data);
		},
		{
			onSuccess: (data, variables) => {
				queryClient.invalidateQueries(['aclImportMappingsGlazingProfilesGet']);
				queryClient.invalidateQueries(['aclImportGlazingProfileIdGet']);
				toast.success(`Koppeling successvol verwijderd`);
			},
			onError: () => {
				toast.error('Koppeling verwijderen mislukt');
			},
		}
	);
};

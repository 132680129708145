/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {OrderStatusCodes} from "../../../../../../modules/api-client/generated";
import clsx from "clsx";

type Props = {
    status?: string | null
    className?: string
}
const OrderStatus: React.FC<Props> = ({status,className}) => {
    let color = 'light';
    let title = 'Onbekend';
        switch(status) {
            case OrderStatusCodes.Draft:
                color = 'dark'
                title = 'Concept'
                break;
            case OrderStatusCodes.QuoteIssued:
                color = 'warning'
                title = 'Offerte'
                break;
            case OrderStatusCodes.Placed:
                color = 'primary'
                title = 'Geplaatst'
                break;
            case OrderStatusCodes.Accepted:
                color = 'info'
                title = 'Geaccepteerd'
                break;
            case OrderStatusCodes.Completed:
                color = 'success'
                title = 'Compleet'
                break;
            case OrderStatusCodes.Cancelled:
                color = 'danger'
                title = 'Geannuleerd'
                break;
            default:
                color = 'dark'
        }

    return (
        <>
            <span className={clsx(
                'badge badge-light-'+color,
                className && className)}>
                {title}
            </span>
        </>
    )
}

const OrderStates = [
    {
        label: "Concept",
        value: OrderStatusCodes.Draft,
        key: "Draft",
        color: 'dark'

    },
    {
        label: "Offerte",
        value: OrderStatusCodes.QuoteIssued,
        key: "QuoteIssued",
        color: "warning",
    },
    {
        label: "Geplaatst",
        value: OrderStatusCodes.Placed,
        key: "Placed",
        color: "primary"
    },
    {
        label: "Geaccepteerd",
        value: OrderStatusCodes.Accepted,
        key: "Accepted",
        color: "info"
    },
    {
        label: "Compleet",
        value: OrderStatusCodes.Completed,
        key: "Completed",
        color: "success"
    },
    {
        label: "Geannuleerd",
        value: OrderStatusCodes.Cancelled,
        key: "Cancelled",
        color: "danger"
    }
]

export {OrderStatus, OrderStates}

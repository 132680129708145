import {FC} from 'react'
import * as Yup from 'yup'
import {Form, Formik, FormikHelpers} from 'formik'
import {
    OrderAddressRepresentation
} from '../../../../../../../modules/api-client/generated'
import TextField from "../../../../../../../shared/components/TextField";
import {OrderAddressTypes, usePutOrderAddress} from "../../../hooks/use-put-address";
import SelectField from "../../../../../../../shared/components/SelectField";

type Props = {
    id: string
    type: OrderAddressTypes
    address?: OrderAddressRepresentation
    handleClose: () => void,
}

const PutAddressForm: FC<Props> = ({id, type, address, handleClose}) => {

    const mutation = usePutOrderAddress(id, type);

    const initialValues: OrderAddressRepresentation = address ? address : {
        attention: null,
        city: "",
        country: "NL",
        extraAddressLine: null,
        houseNumber: 0,
        houseNumberAddition: null,
        postalCode: "",
        region: null,
        street: ""
    }

    const schema = {
        postalCode: Yup.string()
            .min(6, 'Minimaal 4 karakters.')
            .required('Postcode is verplicht.'),
        city: Yup.string()
            .min(3, 'Minimaal 3 karakters.')
            .required('Plaats is verplicht.'),
        street: Yup.string()
            .min(3, 'Minimaal 3 karakters.')
            .required('Straat is verplicht.'),
        houseNumber: Yup.number()
            .required('Huisnummer is verplicht'),
        extraAddressLine: Yup.string().notRequired().nullable(),
        attention: Yup.string().notRequired().nullable(),
        houseNumberAddition: Yup.string().notRequired().nullable(),
        region: Yup.string().notRequired().nullable(),
        country: Yup.string()
            .oneOf(
                ['NL', 'BE', 'DE', 'GB'],
                'Kies land'
            )

            .required('Required'),
    }
    const formSchema = () => Yup.lazy((values) => {
        return Yup.object().shape(schema)
    });

    const onSubmit = (values: OrderAddressRepresentation, {setSubmitting}: FormikHelpers<OrderAddressRepresentation>) => {

        return new Promise<void>((resolve, reject) => {
            var model = {...values, postalCode: values.postalCode.replace(/\s/g, '')}
            mutation.mutate(model, {
                onSuccess: (model) => {
                    resolve();
                    handleClose()
                },
                onError: () => {
                    reject();
                }
            });

        }).finally(() => setSubmitting(false));
    };


    return (
        <>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={formSchema}>
                {({
                      values,
                      handleChange,
                      handleSubmit,
                      getFieldProps,
                      isSubmitting,
                  }) => (
                    <Form>
                        {/* begin form element */}


                        <div className='row mb-7'>
                            <div className={'col'}>
                                <TextField
                                    label={'Ter attentie van'}
                                    {...getFieldProps('attention')}
                                />
                            </div>

                        </div>

                        <div className='row mb-7'>
                            <div className={'col-6'}>
                                <TextField
                                    label={'Straat'}
                                    {...getFieldProps('street')}
                                />
                            </div>
                            <div className={'col-3'}>
                                <TextField
                                    label={'Nummer'}
                                    {...getFieldProps('houseNumber')}
                                />
                            </div>
                            <div className={'col-3'}>
                                <TextField
                                    label={'Toevoeging'}
                                    {...getFieldProps('houseNumberAddition')}
                                />
                            </div>
                        </div>
                        <div className='row mb-7'>
                            <div className={'col'}>
                                <TextField
                                    label={'Extra regel'}
                                    {...getFieldProps('extraAddressLine')}
                                />
                            </div>

                        </div>
                        <div className='row mb-7'>
                            <div className={'col-6'}>
                                <TextField
                                    label={'Postcode'}
                                    {...getFieldProps('postalCode')}
                                />
                            </div>
                            <div className={'col'}>
                                <TextField
                                    label={'Plaats'}
                                    {...getFieldProps('city')}
                                />
                            </div>

                        </div>
                        <div className='row mb-7'>
                            <div className={'col-6'}>
                                <TextField
                                    label={'Regio'}
                                    {...getFieldProps('region')}
                                />
                            </div>
                            <div className={'col'}>
                                <SelectField
                                    label={'Land'}
                                    {...getFieldProps('country')}
                                >
                                    <option value='NL'>Nederland</option>
                                    <option value='BE'>België</option>
                                    <option value='DE'>Duitsland</option>
                                    <option value='FR'>Frankrijk</option>
                                    <option value='GB'>Verenigd Koninkrijk</option>
                                    <option value='PL'>Polen</option>
                                </SelectField>
                            </div>

                        </div>

                        {/* begin::Actions */}
                        <div className='text-center pt-5'>
                            <button
                                type='reset'
                                className='btn btn-light me-3'
                                data-kt-customer-modal-action='cancel'
                                data-dismiss="modal"
                                onClick={() => handleClose()}
                                disabled={isSubmitting}
                            >
                                Annuleren
                            </button>

                            <button
                                type='submit'
                                className='btn btn-primary'
                                data-kt-customer-modal-action='submit'
                                disabled={isSubmitting}
                            >
                                <span className='indicator-label'>Opslaan
                                </span>
                                {isSubmitting && (
                                    <span className='indicator-progress'>
                                        Even wachten a.u.b
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                        {/* end::Actions */}

                    </Form>
                )}
            </Formik>
        </>
    )
}

export {PutAddressForm}

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'

type Props = {
    show: boolean,
    fullscreen: true | string | 'sm-down' | 'md-down' | 'lg-down' | 'xl-down' | 'xxl-down';
    size?: 'sm' | 'lg' | 'xl';
    onHide: () => void;
    printers: any[];
}

const modalsRoot = document.getElementById('root-modals') || document.body

const PrintersModal = ({ show, onHide, size, fullscreen, printers }: Props) => {

    return createPortal(
        <Modal
            id='kt_printers_modal'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName={'modal-dialog modal-' + size}
            show={show}
            size={size}
            onHide={onHide}
            fullscreen={fullscreen}
            animation={true}
            backdrop={true}
        >
            <Modal.Header closeButton>
                <Modal.Title>Dymo Printers</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                Gevonden printers: {printers ? printers.length : 0}

                {
                    printers.map(printer => 
                    <>
                        {printer.name}
                    </>
                    )
                }    
            </Modal.Body>
        </Modal>,
        modalsRoot
    )
}

export { PrintersModal }

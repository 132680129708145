import React from 'react';
import {useQuery} from '@tanstack/react-query';
import ApiClient from '../../../../modules/api-client/ApiClient';
import {
    WeeklyExpeditionStatsRow, WeeklyLevertijdStatsRow,
    WeeklyProductionStatsRow,
    WeeklySalesStatsRow
} from '../../../../modules/api-client/generated';
import Loading from '../../../../shared/components/Loading';
import {EkoTable} from "../../../../shared/components/table/EkoTable";
import {TableHeader} from "../../../../shared/components/table/TableHeader";
import Euro from "../../../../shared/components/Euro";
import {NumericFormat} from "react-number-format";
import {bool} from "yup";

export const LevertijdStats: React.FC = () => {

    const {isInitialLoading, data} = useQuery<{ [key: string]: { [key: string]: WeeklyLevertijdStatsRow; }; }>(
        ['weeklyLevertijdStatsRow'],
        () => ApiClient.Em.Reporting.weeklyLevertijdStats().then((res) => res.data));
    if (!data) return <Loading/>

    if (isInitialLoading) return <Loading/>
	    return (
        <>
            {Object.keys(data).reverse().map((year) => {
                return (
                    <>
                        <h1>
                            {year}
                        </h1>
					<EkoTable className={'table-row-dashed table-row-gray-300 gy-7'}>
						<TableHeader>
							<th>Week</th>
							<th>Orders</th>
							<th>Levertijd</th>
						</TableHeader>
						<tbody>
                        {Object.keys(data[year]).reverse().map((week) => {
							return (
								<tr key={week} className=" fs-6 text-gray-800">
							<td>{week}</td>
									<td>
										{data[year][week]?.orders}
									</td>
									<td>
										{data[year][week]?.levertijdAvg} dagen
									</td>

						</tr>
						)
                        })
                        }
						</tbody>
					</EkoTable>
					</>
                )

            })}
        </>
    );
};
import {useQuery, useQueryClient} from "@tanstack/react-query";
import * as api from "../../../../modules/api-client/generated";
import ApiClient from "../../../../modules/api-client/ApiClient";
import {NotFound, ValidationFailed} from "../../../../modules/api-client/Responses";
import {useEkosietMutation} from "../../../../shared/hooks/useEkosietMutation";
import {
    CreateGlazingBarCommand, UpdateGlazingBarRequest
} from "../../../../modules/api-client/generated";
import {FormValues} from "./GlazingBarUpsert";


export const useGlazingBarUpsertMutation = (id?: string | undefined) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<api.GlazingBar, NotFound | ValidationFailed, FormValues>((values) => {
            const command: CreateGlazingBarCommand | UpdateGlazingBarRequest = {
                code: values.code,
                active: values.active,
                name: values.name,
                width: values.width,
                height: values.height,
            };

            if (id) {
                return ApiClient.Pim.GlazingBar.pimGlazingBarsIdPut(id, undefined, command).then(response => response.data);
            }
            return ApiClient.Pim.GlazingBar.pimGlazingBarsPost(undefined, command).then(response => response.data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['pimGlazingBarsIdGet', id]).then();
                queryClient.invalidateQueries(['pimGlazingBarsGet']).then();
            }
        }
    );
};

export const useGlazingBarQuery = (id: string | undefined) => {
    return useQuery(['pimGlazingBarsIdGet', id],
        () => {
            return ApiClient.Pim.GlazingBar.pimGlazingBarsIdGet(id!).then(res => res.data);
        }, {
            useErrorBoundary: true,
            enabled: !!id,
        });
}

export const useGlazingBarDeleteMutation = (id: string) => {
    const queryClient = useQueryClient()
    return useEkosietMutation<void, NotFound | ValidationFailed, string>((id) => {
            return ApiClient.Pim.GlazingBar.pimGlazingBarsIdDelete(id, undefined).then(response => response.data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['pimGlazingBarsIdGet', id]).then();
                queryClient.invalidateQueries(['pimGlazingBarsGet']).then();
            }
        }
    );
};




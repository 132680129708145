/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {PageTitle} from "../../../../../_metronic/layout/core";
import {EkoCard} from "../../../../shared/components/card";
import {EkoCardBody} from "../../../../shared/components/card";
import {EkoCardHeader} from "../../../../shared/components/card";
import {CrmIntake} from "../../crm/components/CrmIntake";

export function MgtCrm() {
    return (
        <>
            <PageTitle>Klanten</PageTitle>
            <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
                {/* begin::Col */}
                <div className='col'>
                    <EkoCard className={'mb-4'}>
                        <EkoCardHeader title={'Intake per klant'}>
                        </EkoCardHeader>
                        <EkoCardBody>
                            <CrmIntake/>
                        </EkoCardBody>
                    </EkoCard>
                    {/*<EkoCard>*/}
                    {/*    <EkoCardHeader title={'Alle klanten'}>*/}
                    {/*    </EkoCardHeader>*/}
                    {/*    <EkoCardBody>*/}
                    {/*        <CrmCustomerMap/>*/}
                    {/*    </EkoCardBody>*/}
                    {/*</EkoCard>*/}
                </div>
            </div>
        </>
    )
}

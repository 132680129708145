import React from "react";
import {ExpeditionWeekTable} from "../expedition/components/ExpeditionWeekTable";

const ExpeditionWeekPlanning: React.FC = () => {

    return (
        <>            
            <ExpeditionWeekTable/>
        </>
    )
}

export default ExpeditionWeekPlanning

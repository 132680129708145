import React, {useCallback} from "react";
import ApiClient from "../../../../modules/api-client/ApiClient";
import {DownloadButton} from "../../../../shared/components/DownloadButton";
import {mapDownload} from "../../../../shared/hooks/useDownloadFile";


export const BmhDownloadButton: React.FC<{ batch: string, filename: string, }> = ({batch, filename}) => {

    const downloadCallback = useCallback(() => {
        return ApiClient.Pm.ProductionBatch.pmProductionBatchIdXmlGet(batch).then(mapDownload);
    }, []);

    return <DownloadButton title="Download XML" filename={filename} downloadCallback={downloadCallback}/>
}
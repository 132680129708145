/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { InvoiceStatusCodes } from "../../../../../../modules/api-client/generated";
import {
    usePutFinancialStatusInvoiced,
    usePutFinancialStatusNotInvoiced, usePutFinancialStatusPaid
} from "../../hooks/use-put-financial-status";

type Props = {
    orderId: string
    status?: string | null
    className?: string
}
const OrderFinancialStatus: React.FC<Props> = ({ status, className, orderId }) => {

    const notInvoicedMutation = usePutFinancialStatusNotInvoiced(orderId)
    const invoicedMutation = usePutFinancialStatusInvoiced(orderId)
    const paidMutation = usePutFinancialStatusPaid(orderId)

    if (!status || !orderId) return (<></>)

    const setNotInvoiced = () => {
        return new Promise<void>(() => {
            notInvoicedMutation.mutate(undefined, {});
        }).finally();
    }
    const setInvoiced = () => {
        return new Promise<void>(() => {
            invoicedMutation.mutate(undefined, {});
        });
    }
    const setPaid = () => {
        return new Promise<void>(() => {
            paidMutation.mutate(undefined, {});
        }).finally();
    }

    return (
        <>
            <div className={"d-flex"}>
                <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                    <button type="button" className={`btn-badge btn btn-${status === InvoiceStatusCodes.NotInvoiced ? 'warning disabled' : ''}`} onClick={() => setNotInvoiced()}>Niet gefactureerd</button>
                    <button type="button" className={`btn-badge btn btn-${status === InvoiceStatusCodes.Invoiced ? 'info disabled' : ''}`} onClick={() => setInvoiced()}>Gefactureerd</button>
                    {/*<button type="button" className={`btn-badge btn btn-${status === InvoiceStatusCodes.Paid ? 'success disabled' : ''}`}  onClick={()=>setPaid()}>Betaald</button>*/}
                </div>
            </div>

        </>
    )
}

export { OrderFinancialStatus }

import {useQueryClient} from "@tanstack/react-query";
import {toast} from "react-hot-toast"
import ApiClient from "../../../../../modules/api-client/ApiClient";
import {
    OrderRepresentation,
    PutOrderReferenceRepresentation,
} from "../../../../../modules/api-client/generated";
import {useEkosietMutation} from "../../../../../shared/hooks/useEkosietMutation";
import {NotFound, ValidationFailed} from "../../../../../modules/api-client/Responses";

export const usePutReference = (id: string) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<OrderRepresentation, NotFound | ValidationFailed,PutOrderReferenceRepresentation
    >((model: PutOrderReferenceRepresentation) => {
            return ApiClient.Sm.Order.smOrderIdExpeditionReferencePut(id, undefined, model).then(response => response.data);                
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['smOrderIdGet', id]).then();
                toast.success("Referentie aangepast.");

            },
            onError: () => {
                toast.error("Referentie wijzigen mislukt!");
            }
        }
    );
};

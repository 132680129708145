import { FC } from 'react'
import * as Yup from 'yup'
import { Form, Formik, FormikHelpers } from 'formik'
import { usePutProductionDate } from "../../../hooks/use-put-production-date";
import { PutPlannedProductionDateRepresentation } from '../../../../../../../modules/api-client/generated';
import moment from 'moment';
import { DateField } from '../../../../../../../shared/components/date/DateField';


type Props = {
    id: string
    productionDate?: Date | null
    handleClose: () => void,
}

type FormValues = Omit<PutPlannedProductionDateRepresentation, 'productionDate'> & {
    productionDate?: Date | null;
};

const PutProductionDateForm: FC<Props> = ({ id, productionDate, handleClose }) => {

    const mutation = usePutProductionDate(id);

    const initialValues: FormValues = {
        productionDate: productionDate ? new Date(productionDate) : new Date(),
    }

    const schema = {
        productionDate: Yup.date().nullable().notRequired()
    }
    const formSchema = Yup.object().shape(schema);

    const onSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {

        return new Promise<void>((resolve, reject) => {
            
            let payload : PutPlannedProductionDateRepresentation = {
                ...values,
                plannedProductionDate: values.productionDate ? moment(values.productionDate).format(moment.HTML5_FMT.DATE) : null
            }

            mutation.mutate(payload, {
                onSuccess: (model) => {
                    resolve();
                    handleClose()
                },
                onError: () => {
                    reject();
                }
            });

        }).finally(() => setSubmitting(false));
    };

    return (
        <>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={formSchema}>
                {({
                    values,
                    handleChange,
                    setFieldValue,                    
                    isSubmitting,
                }) => (
                    <Form>

                        <div className='d-flex flex-column mb-7 fv-row'>
                            <label className='d-flex align-items-center form-label'>
                                <span>Productie</span>
                            </label>

                                <DateField fieldName='productionDate' />

                        </div>

                        {/* begin::Actions */}
                        <div className='text-center pt-5'>
                            <button
                                type='reset'
                                className='btn btn-light me-3'
                                data-kt-customer-modal-action='cancel'
                                data-dismiss="modal"
                                onClick={() => handleClose()}
                                disabled={isSubmitting}
                            >
                                Annuleren
                            </button>

                            <button
                                type='submit'
                                className='btn btn-primary'
                                data-kt-customer-modal-action='submit'
                                disabled={isSubmitting}
                            >
                                <span className='indicator-label'>Opslaan
                                </span>
                                {isSubmitting && (
                                    <span className='indicator-progress'>
                                        Even wachten a.u.b
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                        {/* end::Actions */}

                    </Form>
                )}
            </Formik>
        </>
    )
}

export { PutProductionDateForm }

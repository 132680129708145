/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {ReactElement} from 'react'
import {
    OrderLineRepresentation, OrderLineType,
    OrderStatusCodes, PostProductOrderLineRepresentation,
} from "../../modules/api-client/generated";
import Euro from "../../shared/components/Euro";
import {isNotEmpty} from "../../../_metronic/helpers";
import {OrderLineTableRow} from "./OrderLineTableRow";
import {createSearchParams, Link} from "react-router-dom";
import OrderlineTypeIcon from "../../shared/components/OrderlineTypeIcon";
import {CustomerRoutePath} from "../routing/RoutePath";
import {
    PostOrderlineForm
} from "../../backoffice/modules/sm/orders/edit-order/components/forms/orderline/PostOrderlineForm";


type Props = {
    orderId: string
    customerId: string
    pricelistId: string
    catalogId: string,
    lines: Array<OrderLineRepresentation> | undefined | null
    projectDiscount: number
    totalPriceGross: number
    totalPriceNet: number
    status: OrderStatusCodes
    setModalFullscreen: React.Dispatch<React.SetStateAction<true | string | 'sm-down' | 'md-down' | 'lg-down' | 'xl-down' | 'xxl-down'>>,
    setModalComponent: React.Dispatch<React.SetStateAction<ReactElement | undefined | null>>,
    setModalTitle: React.Dispatch<React.SetStateAction<string | undefined | null>>,
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>,
    isOrderEdit: boolean
}

const OrderLineTable: React.FC<Props> = ({
                                             orderId,
                                             customerId,
                                             lines,
                                             pricelistId,
                                             projectDiscount,
                                             totalPriceGross,
                                             totalPriceNet,
                                             catalogId,
                                             setModalTitle,
                                             setModalComponent,
                                             setShowEditModal,
                                             setModalFullscreen,
                                             status,
                                             isOrderEdit
                                         }) => {
    if (!orderId) return <></>;

    const addProductOrderLine = () => {
        const data: PostProductOrderLineRepresentation = {
            quantity: 1,
            catalogItemId: "",
            orderlineType: OrderLineType.ProductLine,
        }
        setModalComponent(<PostOrderlineForm handleClose={() => setShowEditModal(false)} catalogId={catalogId}
                                             orderId={orderId} orderLineData={data}/>);
        setModalTitle('Kies producten om toe te voegen aan de order');
        setShowEditModal(true);
    }


    const linesContent: any[] = [];

    lines?.forEach((line, index) => {
        linesContent.push(
            <OrderLineTableRow
                index={index}
                customerId={customerId}
                pricelistId={pricelistId}
                key={line.id}
                orderLine={line}
                status={status}
                setModalTitle={setModalTitle}
                setModalFullscreen={setModalFullscreen}
                setModalComponent={setModalComponent}
                setShowEditModal={setShowEditModal}/>)
    })
    return (
        <>
            {(status === OrderStatusCodes.Draft
                    || status === OrderStatusCodes.Placed) && isOrderEdit &&
                <div className={'d-flex w-100 justify-content-end'}>
                    {/*<Link*/}
                    {/*    to={CustomerRoutePath.link(CustomerRoutePath.orderConfiguratorLink(orderId)) + '?pl=' + pricelistId}*/}
                    {/*    className={`btn btn-primary btn-sm me-2 `}>*/}
                    {/*    <OrderlineTypeIcon type={OrderLineType.ConfigurationLine}/> Dorpel toevoegen*/}
                    {/*</Link>*/}

                    <Link
                        to={{
                            pathname: CustomerRoutePath.link(CustomerRoutePath.orderConfiguratorLink(orderId)),
                            search: createSearchParams({
                                ...(customerId ? {customerId: customerId} : {}),
                                pl: pricelistId,
                            }).toString()
                        }}
                        className={`btn btn-primary btn-sm me-2 `}>
                        <OrderlineTypeIcon type={OrderLineType.ConfigurationLine}/> Dorpel toevoegen
                    </Link>
                    <button type="button" className={`btn btn-primary btn-sm me-2 `}
                            onClick={() => addProductOrderLine()}>
                        <OrderlineTypeIcon type={OrderLineType.ProductLine}/> Product toevoegen
                    </button>
                </div>
            }
            <div className="table-responsive">
                <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0 ">
                    <thead>
                    <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                        <th className="min-w-20px">#</th>
                        <th className="min-w-20px text-center">Type</th>
                        <th className="min-w-70px text-center">Aantal</th>
                        <th className="min-w-175px">Product</th>
                        <th className="min-w-100px">Merk</th>
                        <th className="min-w-100px text-end">Prijs</th>
                        <th className="text-end">Totaal</th>
                        <th className="min-w-40px text-end"></th>
                    </tr>
                    </thead>
                    <tbody className="fw-semibold text-gray-600">
                    {linesContent}
                    {isOrderEdit && projectDiscount > 0 &&
                        <>
                            <tr>
                                <td colSpan={6} className="fs-3 text-dark text-end">
                                    Subtotaal
                                </td>
                                <td className="text-dark fs-3 fw-bolder text-end">
                                    <Euro value={totalPriceGross}/>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={6} className="fs-3 text-dark text-end">
                                    Projectkorting
                                </td>
                                <td className="text-dark fs-3 fw-bolder text-end">
                                    <Euro value={totalPriceGross - totalPriceNet}/>
                                </td>
                            </tr>
                        </>
                    }

                    {isOrderEdit && isNotEmpty(totalPriceNet) &&
                        <tr>
                            <td colSpan={6} className="fs-3 text-dark text-end">
                                Totaal
                            </td>
                            <td className="text-dark fs-3 fw-bolder text-end">
                                <Euro value={totalPriceNet}/>
                            </td>
                        </tr>
                    }
                    </tbody>
                </table>
            </div>
        </>
    )
}

export {OrderLineTable}

export const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN!;
export const ROLE_CLAIMS: any = process.env.REACT_APP_ROLE_CLAIMS_URL!;
export const CUSTOMERID_CLAIMS_URL: any = process.env.REACT_APP_CUSTOMERID_CLAIMS_URL!;
export const LICENSE_CLAIMS: any = process.env.REACT_APP_LICENSE_URL!;
export const ADMIN_ROLE = process.env.REACT_APP_ADMIN_ROLE!;
export const AUTH_CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID!;
export const AUTH_AUD_URL = process.env.REACT_APP_AUTH_AUD_URL!;
export const REACT_APP_API_AUTHZERO_PROXY_URL = process.env.REACT_APP_API_AUTHZERO_PROXY_URL!;
export const API_GATEWAY_BASE_URL = process.env.REACT_APP_API_GATEWAY_BASE_URL!;
export const KLANT_ROLE = 'Klant'
export const ADMINISTRATIE_ROLE = 'Adminstratie'
export const CONTROLLER_ROLE = 'Controller'
export const MANAGEMENT_ROLE = 'Management'
export const PRODUCTIEMEDEWERKER_ROLE = 'Productiemedewerker'
export const SYSTEEMBEHEERDER_ROLE = 'Systeembeheerder'
export const WERKVOORBEREIDER_ROLE = 'Werkvoorbereider'
export const EXPEDITIEMEDEWERKER_ROLE = 'Expeditiemedewerker'
export const TRANSPORTMEDEWERKER_ROLE = 'Transportmedewerker'
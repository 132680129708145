/* eslint-disable jsx-a11y/anchor-is-valid */
import { CustomerAddressRepresentation } from '../../modules/api-client/generated';
import {EkoCard, EkoCardBody} from "../../../_metronic/helpers";
import {EkoCardHeader} from "../../shared/components/card/EkoCardHeader";
import React from "react";

type Props = {  
  addresses: Array<CustomerAddressRepresentation> | undefined | null,    
}

export const CustomerAddressList = ({addresses}: Props) => {
  return (
    <>
      <EkoCard className={'mt-10'}>
        <EkoCardHeader title={'Adressen'}/>
          <EkoCardBody>
            {addresses && addresses.length > 0 ? addresses.map((address) => {
                  const id = `my-customer-address-${address.id}`
                  return (
                      <div key={id}>
                        <div className={'row'}>
                        <div className={'col-12 col-lg-6'}>
                          {address.attention &&
                              <>{address.attention}<br/></>
                          }
                          {address.street} {address.houseNumber} {address.houseNumberAddition}<br/>
                          {address.postalCode} {address.city}<br/>
                          {address.country}<br/>
                          {address.isInvoicingAddress ?
                              <><span className={'fw-bold'}><i className={'fas fa-check'}></i> Standaard factuuradres</span><br/></>
                              :
                              <><span className={'text-muted'}>Standaard factuuradres</span><br/></>
                          }
                          {address.isDeliveryAddress ?
                              <><span className={'fw-bold'}><i className={'fas fa-check'}></i> Standaard leveringsadres</span> <br/></>
                              :
                              <><span className={'text-muted'}>Standaard leveringsadres</span><br/></>
                          }

                        </div>
                        <div className={'col-12 col-lg-6'}>
                          {address.contactPerson?.firstName} {address.contactPerson?.lastName}<br/>
                          {address.contactPerson?.email} <br/>
                          {address.contactPerson?.telephone}
                        </div>
                      </div>
                        <div className='separator separator-dashed my-5'></div>
                      </div>
                  )
                })
                : <div className='p-10'>Geen adressen</div>
            }
          </EkoCardBody>
      </EkoCard>
    </>
  )
}

import React from "react";
import * as api from "../../../../modules/api-client/generated";

export interface SillTypeTextProps {
    code: string;
}

const SillTypeText: React.FC<SillTypeTextProps> = (props) => {

    switch (props.code) {
        case api.SillType.Binnensponning: {
            return (<>Binnensponning</>);
        }
         case api.SillType.Buitensponning: {
            return (<>Buitensponning</>);
        }
         case api.SillType.HefSchuif: {
            return (<>Hef/schuif</>);
        }
         case api.SillType.Vouwwand: {
            return (<>Vouwwand</>);
        }
         case api.SillType.Zijlicht: {
            return (<>Zijlicht</>);
        }
    }

    return (<>{props.code}</>);
}

export default SillTypeText;
import React, { useCallback, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import ApiClient from '../../../../../modules/api-client/ApiClient';
import { EkoCard, EkoCardBody } from '../../../../../../_metronic/helpers';
import { TableHeader, EkoTable } from '../../../../../shared/components/table';
import { EkoCardHeader, EkoCardToolbar } from '../../../../../shared/components/card';
import { NewsletterSubscriberListItem } from '../../../../../modules/api-client/generated';
import Loading from '../../../../../shared/components/Loading';
import { formatDate } from '../../../../../shared/components/date';
import Error from "../../../../../shared/components/Error";
import { ConfirmDialog } from '../../../../../shared/components/ConfirmDialog';
import { useNewsletterSubscriberDeleteMutation } from '../../hooks/useNewsletterSubscriberDeleteMutation';
import toast from 'react-hot-toast';
import { EmailInsert } from './EmailInsert';
import { useOutletContext } from 'react-router-dom';
import { MasterdataModalContext } from '../../../../pages/MasterData';

export const NewsletterSubscribersPage: React.FC = () => {
	const { isInitialLoading, data: list, isError } = useQuery<NewsletterSubscriberListItem[]>(['newsletterSubscribersListGet'], () => ApiClient.Crm.Newsletter.getListOfNewsletterSubscribers().then((res) => res.data));
	const { setShowModal, setModalTitle, setModalComponent } = useOutletContext<MasterdataModalContext>();

	if (isInitialLoading) return <Loading />;

	if (isError) return <Error />;

	const copyToClipboard = () => {
		const emails = list?.map(x => x.email).join(";");

		if (!emails) return toast.custom("Geen e-mailadressen om te kopiëren");
		navigator.clipboard.writeText(emails?.toString() ?? "");
		toast.success("E-mailadressen gekopieerd naar klembord");
	}

	const add = () => {
		setModalComponent(<EmailInsert handleClose={() => setShowModal(false)} />)
		setModalTitle('Toevoegen')
		setShowModal(true)
	}

	return (
		<>
			<EkoCard>
				<EkoCardHeader title={'Nieuwsbrief inschrijvingen'} >
					<EkoCardToolbar>
						<button onClick={() => add()} className="btn btn-primary btn-sm me-2">Toevoegen</button>
						<button onClick={copyToClipboard} className='btn btn-primary btn-sm'>Kopieer e-mailadressen</button>
					</EkoCardToolbar>
				</EkoCardHeader>
				<EkoCardBody>
					<EkoTable>
						<TableHeader>
							<th>E-mailadres</th>
							<th style={{ width: "12em" }}>Geregistreerd op</th>
							<th>GDPR ondertekend</th>
							<th></th>
						</TableHeader>
						<tbody>
							{(!list || list.length === 0) && (
								<tr>
									<td colSpan={5} className="text-center">
										Geen gegevens
									</td>
								</tr>
							)}
							{list &&
								list.map((data) => (
									<tr key={data.id}>
										<td>{data.email}</td>
										<td>{formatDate(data.registeredAt ?? "", "dd-MM-yyyy HH:mm")}</td>
										<td>{data.agreedPrivayPolicy === true ? "Ja" : "Nee"}</td>
										<td className="fit-content">
											<DeleteButton id={data.id!} />
										</td>
									</tr>
								))}
						</tbody>
					</EkoTable>
				</EkoCardBody>
			</EkoCard>
		</>
	);
};

const DeleteButton: React.FC<{ id: string }> = ({ id }) => {
	const openDeleteDialog = useRef<(show: boolean) => void>();

	const deleteMutation = useNewsletterSubscriberDeleteMutation(id);
	const deleteCallback = useCallback(async () => {
		await deleteMutation.mutateAsync(id!);
	}, [deleteMutation, id]);
	return (
		<>
			<button type="button" className="btn btn-light btn-sm" disabled={false}
				onClick={() => openDeleteDialog.current?.call(this, true)}><i className={'fas fa-trash'}></i>
			</button>
			<ConfirmDialog show={openDeleteDialog}
				onConfirm={deleteCallback}
				dialogStyle="danger"
				title="Verwijderen"
				message="Wil je doorgaan?"
				confirmText="Definitief verwijderen"

			/>
		</>
	)
}
import React from 'react';
import {useQuery} from '@tanstack/react-query';
import ApiClient from '../../../../modules/api-client/ApiClient';
import {
    DailyProductionGraphStatsRow,
} from '../../../../modules/api-client/generated';
import Loading from '../../../../shared/components/Loading';
import {Chart} from "react-chartjs-2";
import {DateTime} from "luxon";

export const DailyProduction: React.FC = () => {

    const {isInitialLoading, data} = useQuery<DailyProductionGraphStatsRow[]>(
        ['dailyProductionGraphStats'],
        () => ApiClient.Pm.Reporting.dailyProductionGraphStats().then((res) => res.data));
    if (!data) return <Loading/>

    if (isInitialLoading) return <Loading/>

    const options:any = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Dagelijks productie overzicht (afgelopen 30 dagen)',
            },
        },
    };

    const data2 = {
        labels: data.map((r)=>DateTime.fromFormat(r.date,'yyyy-MM-dd').toFormat('dd-MM-yyyy')),
        datasets: [
            {
                type: 'line' as const,
                label: 'Running total',
                borderColor: '#ff00ff',
                borderWidth: 2,
                tension:0.4,
                fill: false,
                data: data.map((r) => r.runningTotal),
            },
            {
                label: 'Geproduceerde dorpels',
                tension:0.4,
                data: data.map((r) => r.geproduceerdeDorpels),
                backgroundColor: '#70ab2a',
            },
            {
                label: 'Geplande dorpels',
                tension:0.4,
                data: data.map((r) => r.geplandeDorpels),
                backgroundColor: '#093869',
            },

        ],
    };

    return (
        <div className="chart-container position-relative" style={{height:'40vh'}}>
        <Chart type='bar' options={options} data={data2} />

            {/*{data.reverse().map((row) => {*/}
            {/*        return(*/}
            {/*        <>*/}
            {/*            {row.date} {row.geplandeDorpels} {row.geproduceerdeDorpels} {row.runningTotal} <br/>*/}
            {/*        </>*/}
            {/*        )*/}

            {/*    }*/}
            {/*)*/}
            {/*}*/}
        </div>
    );
};
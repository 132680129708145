import {useQuery} from "@tanstack/react-query";
import ApiClient from "../../../../../modules/api-client/ApiClient";
import {SillProfiles} from "../../../../../modules/api-client/generated";
import type {UseQueryOptions} from "@tanstack/react-query/src/types";

export const useGetSillProfilesQuery = (sillId: string | undefined, neutPositionId: string, compartmentLayoutTypeId: string,
                                        queryOptions?: Omit<UseQueryOptions<SillProfiles>, 'queryKey' | 'queryFn' | 'useErrorBoundary' | 'enabled'>
) => {
    return useQuery<SillProfiles>({

        queryKey: ['ApiClient.Pim.SillProfiles.getProfiles', sillId, neutPositionId, compartmentLayoutTypeId],
        queryFn: async () => {
            const res = await ApiClient.Pim.SillProfiles.getSillProfiles(sillId!, neutPositionId, compartmentLayoutTypeId);
            return res.data;
        },
        useErrorBoundary: true,
        enabled: !!sillId,
        ...queryOptions ?? {}
    });
};
import React, {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import {EmRoutePath} from "../EmRoutes";
const ExpeditionByProductionItem: React.FC = () => {
    const {barcode} = useParams<{ barcode: string }>();
    var navigate = useNavigate();
    const {
        data: orderid,
    } = useQuery<string, TypeError, string>(
        ['pmOrderIdBarcodeCodeGet', barcode],
        () => {
            return ApiClient.Pm.ProductionItem.pmProductionItemOrderIdByBarcodeCodeGet(barcode!).then((res) => res.data);
        }, {
            useErrorBoundary: true,
            refetchOnWindowFocus:!!barcode,
            enabled: !!barcode,
        });
    useEffect(() => {
        if(orderid)
        navigate(EmRoutePath.link(EmRoutePath.expeditionWithBarcodeLink(orderid!,barcode!)));
    }, [orderid]);
return <></>;
}
export default ExpeditionByProductionItem

import React, {useState} from 'react';
import {OrderTable} from '../orders/components/OrderTable';
import {PageTitle} from '../../../../../_metronic/layout/core';
import {EkoCard, EkoCardBody} from '../../../../../_metronic/helpers';
import {NewOrderCustomerModal} from '../orders/new-order/components/NewOrderCustomerModal';
import {OrderSearch} from '../orders/components/OrdersSearch';
import {FilterContextProvider} from '../../../../shared/context/FilterContext';

const OrderList: React.FC = () => {

    const [showEditModal, setShowEditModal] = useState<boolean>(false);

    return (
        <>
            <NewOrderCustomerModal show={showEditModal}
                                   handleClose={() => setShowEditModal(false)}></NewOrderCustomerModal>
            <PageTitle toolbar={<button className='btn btn-primary'
                                        onClick={() => setShowEditModal(true)}>
                <i className={'fas fa-plus'}/> Nieuwe order</button>}>
                Orderoverzicht
            </PageTitle>

            <FilterContextProvider>
                <EkoCard className={'mb-5'}>
                    <EkoCardBody>
                        <OrderSearch/>
                    </EkoCardBody>
                </EkoCard>
                <EkoCard>
                    <EkoCardBody>
                        <OrderTable/>
                    </EkoCardBody>
                </EkoCard>

            </FilterContextProvider>
        </>
    );
};

export default OrderList;

import clsx from 'clsx';
import { FC, useEffect, useRef } from 'react'
import Select from "react-select"
import { OrderStates } from '../edit-order/components/OrderStatus';
import { FilterParameter, useEkosietFilter } from '../../../../../shared/context/FilterContext';

type Props = {
    fieldName: string;
    filterProperty?: string
}

export const OrderStatusFilter: FC<Props> = (props: Props) => {

    const { setFilters, containsFilter, resetCount } = useEkosietFilter()
    const selectInputRef = useRef<any>();
    const stateOptions: any[] = OrderStates.map((item: any) => {
        return { value: item.key, label: item.label }
    });

    useEffect(() => {
        if (resetCount) {
            selectInputRef.current.clearValue();
        }
    }, [resetCount])



    return (
        <>
            <Select
                ref={selectInputRef}
                styles={{
                    // Fixes the overlapping problem of the component
                    menu: provided => ({ ...provided, zIndex: 9999 })
                  }}
                placeholder="Selecteer een order status"
                isMulti={true}
                classNames={{
                    control: (state) =>
                        clsx(
                            'form-control p-1 border-secondary',
                        ),
                    singleValue: (state) =>
                    {
                        return clsx(
                            'text-gray-700',
                        )
                    }
                }}
                isClearable={true}
                isSearchable={false}
                name={props.fieldName}
                options={stateOptions}
                onChange={(newValue) => {

                    if (newValue) {

                        const filterExpression = {
                            or: newValue.map(v => {
                                return {
                                    [props.filterProperty || 'Status']: v.value
                                }
                            })
                        };

                        const filter: FilterParameter = {
                            filterExpression: filterExpression,
                            filterName: props.fieldName,
                            values: newValue.map(v => v.value)
                        }

                        setFilters(props.fieldName, [filter])
                    } else {
                        if (containsFilter(props.fieldName)) {
                            setFilters(props.fieldName, [])
                        }
                    }
                }}
            />
        </>
    )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {CustomerFilter} from '../../../crm/components/CustomerFilter';
import {OrderStatusFilter} from './OrderStatusFilter';
import {useEkosietFilter} from '../../../../../shared/context/FilterContext';
import {OrderCodeFilter} from './OrderCodeFilter';
import {PlannedExpeditionDateFilter} from "../../../em/expedition/components/PlannedExpeditionDateFilter";
import {PlannedProductionDateFilter} from "../../../em/expedition/components/PlannedProductionDateFilter";

const OrderSearch: React.FC = () => {

    const {reset} = useEkosietFilter()

    return (
        <>
            <div className="row mb-lg-5">
                <div className="col-10">
                    <div className="row">
                        <div className="col-12 col-lg-4 mb-5 mb-lg-0">
                            <OrderCodeFilter fieldName='filterOrderCode'/>
                        </div>
                        <div className="col-12 col-lg-4 mb-5 mb-lg-0">
                            <CustomerFilter fieldName='filterCustomerId'/>
                        </div>
                        <div className="col-12 col-lg-4 mb-5 mb-lg-0">
                            <OrderStatusFilter fieldName='filterOrderStatus'/>
                        </div>
                    </div>
                </div>
                <div className="col col-lg-2 ms-auto d-flex justify-content-end align-items-start">
                    <button className="btn btn-secondary btn-secondary-icon btn-sm" type='reset' onClick={reset}>
                        <span>
                            <i className="la la-close"></i>
                            <span>Reset filters</span>
                        </span>
                    </button>
                </div>
            </div>
            {/*<div className="row mb-3">*/}
            <div className="d-flex flex-column flex-lg-row">
                <div className={'mb-5 mb-lg-0'}>
                    <label className="form-label">Geplande leverdatum</label>
                    <PlannedExpeditionDateFilter fieldName='filterPlannedExpeditionDate'/>
                </div>
                    <div className={'mb-5 mb-lg-0'}>
                    <label className="form-label">Geplande productie datum</label>
                    <PlannedProductionDateFilter fieldName='filterPlannedProductionDate'/>
                </div>
            </div>
            {/*</div>*/}
        </>
    )
}

export {OrderSearch}
import {useQuery, useQueryClient} from "@tanstack/react-query";
import * as api from "../../../../modules/api-client/generated";
import ApiClient from "../../../../modules/api-client/ApiClient";
import {NotFound, ValidationFailed} from "../../../../modules/api-client/Responses";
import {useEkosietMutation} from "../../../../shared/hooks/useEkosietMutation";
import {CreateColorCommand, UpdateColorRequest} from "../../../../modules/api-client/generated";
import {ColorFormValues} from "./ColorUpsert";

export const useColorUpsertMutation = (id?: string | undefined) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<api.Color, NotFound | ValidationFailed, ColorFormValues>((values) => {
            const command: CreateColorCommand | UpdateColorRequest = {
                code: values.code,
                active: values.active,
                name: values.name,
                hex: values.hex,
            };

            if (id) {
                return ApiClient.Pim.Color.pimColorsIdPut(id, undefined, command).then(response => response.data);
            }
            return ApiClient.Pim.Color.pimColorsPost(undefined, command).then(response => response.data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['pimColorsIdGet', id]).then();
                queryClient.invalidateQueries(['pimColorsGet']).then();
            }
        }
    );
};

export const useColorQuery = (id: string | undefined) => {
    return useQuery(['pimColorsIdGet', id],
        () => {
            return ApiClient.Pim.Color.pimColorsIdGet(id!).then(res => res.data);
        }, {
            useErrorBoundary: true,
            enabled: !!id,
        });
}

export const useColorDeleteMutation = (id: string) => {
    const queryClient = useQueryClient()
    return useEkosietMutation<void, NotFound | ValidationFailed, string>((id) => {
            return ApiClient.Pim.Color.pimColorsIdDelete(id, undefined).then(response => response.data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['pimColorsIdGet', id]).then();
                queryClient.invalidateQueries(['pimColorsGet']).then();
            }
        }
    );
};




/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { CustomerFilter } from '../../../crm/components/CustomerFilter';
import { useEkosietFilter } from '../../../../../shared/context/FilterContext';
import { OrderCodeFilter } from '../../../sm/orders/components/OrderCodeFilter';
import {OrderStatusFilter} from "../../../sm/orders/components/OrderStatusFilter";
import {PlannedExpeditionDateFilter} from "../../../em/expedition/components/PlannedExpeditionDateFilter";
import {ExpeditionStatusFilter} from "../../../sm/orders/components/ExpeditionStatusFilter";
import {FinancialStatusFilter} from "../../../sm/orders/components/FinancialStatusFilter";
import {ExpeditionDateFilter} from "../../../em/expedition/components/ExpeditionDateFilter";
import {RealisedExpeditionDateFilter} from "../../../em/expedition/components/RealisedExpeditionDateFilter";

export const InvoiceSearch: React.FC = () => {

    const { reset } = useEkosietFilter()

    return (
        <>
            <div className="row mb-3">
                <div className="col col-lg-2">
                    <OrderCodeFilter fieldName='filterOrderCode' filterProperty={'orderCode'}/>
                </div>
                <div className="col col-lg-2">
                    <CustomerFilter fieldName='filterCustomerId'/>
                </div>
                <div className="col col-lg-2">
                    <ExpeditionStatusFilter fieldName='filterExpeditionStatus'/>
                </div>
                <div className="col col-lg-2">
                    <OrderStatusFilter fieldName='filterOrderStatus' filterProperty={'OrderStatus'} />
                </div>
                <div className="col col-lg-2">
                    <FinancialStatusFilter fieldName='filterStatus'/>
                </div>
                <div className="col col-lg-2 ms-auto d-flex justify-content-end align-items-center">
                    <button className="btn btn-secondary btn-secondary-icon btn-sm" type='reset' onClick={reset}>
                        <span>
                            <i className="la la-close"></i>
                            <span>Reset filters</span>
                        </span>
                    </button>
                </div>

            </div>
            <div className="row mb-3">
                <div className="col  col-lg-4  mb-lg-0 mb-6">
                    <div className="row">
                        <div className="col">
                            <label className="form-label">Geplande leverdatum</label>
                            <PlannedExpeditionDateFilter fieldName='filterPlannedExpeditionDate'/>
                        </div>
                    </div>
                </div>
                <div className="col  col-lg-4  mb-lg-0 mb-6">
                    <div className="row">
                        <div className="col">
                            <label className="form-label">Gerealiseerde leverdatum</label>
                            <RealisedExpeditionDateFilter fieldName='filterRealisedExpeditionDate'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
import React, {useCallback, useRef} from 'react';
import {useDraggable} from "react-use-draggable-scroll";

export interface PreviewProps {
    svg?: string | null;
    onNeutClick: (index: number) => void;
}

export const Preview: React.FC<PreviewProps> = (props) => {

    const handler = useCallback((e: React.MouseEvent<HTMLDivElement>) => {

        const neut = (e.target as HTMLElement).closest('.neut');

        if (neut) {
            props.onNeutClick?.call(this, Number(neut.attributes.getNamedItem('data-index')?.value));
        }

    }, []);


    const onMouseMove = useCallback((e: React.MouseEvent) => {
        const svg = e.currentTarget.firstChild as SVGSVGElement;

        if (!svg) return;

        const bounds = svg.getBoundingClientRect();

        if (e.clientX >= bounds.left && e.clientX <= bounds.right
            && e.clientY >= bounds.top && e.clientY <= bounds.bottom) {
            // console.log(`${e.clientX - bounds.left}, ${e.clientY - bounds.top}`);
        }
    }, []);


    const ref =  useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
    const { events } = useDraggable(ref); // Now we pass the reference to the useDraggable hook:

    return (
    <div ref={ref} {...events} style={{overflowX: 'scroll'}}>
        <div className='sill-configuration-preview-container'
                onClick={handler}
                onMouseMove={onMouseMove}
                dangerouslySetInnerHTML={{__html: props.svg ?? ''}} style={{height: '300px', width: '2500px'}}></div>
    </div>
    )};



import React, {useRef, useState} from 'react';
import {PageTitle} from '../../../../../_metronic/layout/core';
import {EkoCard, EkoCardBody, EkoCardHeader, EkoCardToolbar} from '../../../../shared/components/card';
import TextField from '../../../../shared/components/TextField';
import {usePostLosseNeutenBatch} from '../../pim/neut/use-post-neut-production-list';
import {FieldArray, Form, Formik, FormikHelpers, FormikProps} from 'formik';
import {NeutProductionItemRepresentation} from '../../../../modules/api-client/generated';
import ConfiguredNeutSvg from '../../../../shared/components/ConfiguredNeutSvg';
import {useNeutPickerDialog} from '../components/NeutPickerDialog';
import SpinnerButton from '../../../../shared/components/SpinnerButton';

type ProductionListItem = NeutProductionItemRepresentation & {
	code: string;
	svg: string;
};

export interface ProductionListItemFormValues {
	title: string;
	remark?: string;
	items: ProductionListItem[];
}

const CreateNeutenBatch: React.FC = () => {
	const formRef = useRef<FormikProps<ProductionListItemFormValues>>(null);

	const pickerDialog = useNeutPickerDialog({
		onSelectionConfirmed: async (selection) => {
			await formRef.current?.setValues((current) => {
				const update = {...current};

				update.items.push(
					...selection.map((neut) => {
						return {
							id: neut.id,
							code: neut.code,
							svg: neut.svg,
							quantity: 1,
							remark: '',
						};
					})
				);

				return update;
			});
		},
	});

	const createBatchMutation = usePostLosseNeutenBatch();

	const createBatch = async (values: ProductionListItemFormValues) => {
		await createBatchMutation.mutateAsync(values);
	};

	const initialValues: ProductionListItemFormValues = {
		items: [],
		remark: '',
		title: '',
	};

	const addNeut = (id: string, code: string, svg: string) => {
		if (id && code) {
			const newValues = {...initialValues};
			newValues.items.push({
				id: id,
				code: code,
				svg: svg,
				quantity: 1,
				remark: '',
			});
		}
	};

	return (
		<>
			<PageTitle>Neuten batch aanmaken</PageTitle>
			{/*<div className="input-group mb-3">*/}
			{/*	<NeutPicker fieldName={'neutId'} onChange={addNeut} />*/}
			{/*</div>*/}
			<Formik
				innerRef={formRef}
				initialValues={initialValues}
				// enableReinitialize
				onSubmit={createBatch}
			>
				{({values}) => (
					<Form>
						<EkoCard className={'mb-5'}>
							<EkoCardHeader title={`Losse neuten batch`}>
								<EkoCardToolbar>
									<SpinnerButton type="submit" className={'btn btn-primary'} disabled={values.items.length === 0} spinning={createBatchMutation.isLoading}>
										Batch aanmaken
									</SpinnerButton>
								</EkoCardToolbar>
							</EkoCardHeader>
							<EkoCardBody>
								<div className="mb-4">
									<TextField row={false} placeholder={'Optionele opmerking...'} name={'remark'} />
								</div>

								<hr />

								<div className="mb-4">
									<button type="button" className="btn btn-secondary btn-lg" onClick={() => pickerDialog.open()}>
										Neuten toevoegen
									</button>
								</div>

								<FieldArray
									name="items"
									render={({remove}) => {
										return (
											<div>
												{values.items.length > 0 &&
													values.items.map((neut, index) => (
														<div className="d-flex mb-2 align-items-center" key={index}>
															<div className="w-25 me-3 d-flex">
																<ConfiguredNeutSvg svg={neut.svg} svgWidth={'65px'} svgHeight={'95px'} />
																<div className={'form-control-plaintext fw-bold'}>{neut.code}</div>
															</div>
															<div className="w-25 me-3">
																<TextField name={`items.${index}.quantity`} placeholder="Aantal" min={1} type="number" />
															</div>
															<div className="w-35 me-3">
																<TextField name={`items.${index}.remark`} placeholder="Optionele opmerking voor neut" type="text" />
															</div>
															<div className="w-10 d-flex align-items-center">
																<div className="badge badge-circle bg-secondary ms-auto" onClick={() => remove(index)}>
																	<i className={'fas fa-times'}></i>
																</div>
															</div>
														</div>
													))}
											</div>
										);
									}}
								/>
							</EkoCardBody>
						</EkoCard>
					</Form>
				)}
			</Formik>
			{pickerDialog.component}
		</>
	);
};

export default CreateNeutenBatch;

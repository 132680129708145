import ApiClient from "../../../modules/api-client/ApiClient";
import { ValidationFailed, NotFound } from "../../../modules/api-client/Responses";
import { useEkosietMutation } from "../../hooks/useEkosietMutation";
import { FormValues } from "./FileModal";

export const useFileUploadMutation = () => {
    return useEkosietMutation<string, NotFound | ValidationFailed, FormValues>(async (values) => {
        const response = await ApiClient.FileStorage.addFile(values.file, values.fileCategory, values.title, values.description, values.altText);
        return response.data;
    });
};
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {ReactNode, useEffect, useMemo, useRef, useState} from 'react'
import {EkoCardBody, KTSVG} from '../../../../../_metronic/helpers'
import BooleanBadge from "../../../../shared/components/BooleanBadge";
import {Link, useSearchParams} from "react-router-dom";
import {MgtRoutePath} from "../../mgt/MgtRoutes";
import {CrmRoutePath} from "../CrmRoutes";
import {useListCustomersOdata} from "../hooks/use-list-customer-odata";
import {useEkosietFilter} from "../../../../shared/context/FilterContext";
import Loading from "../../../../shared/components/Loading";
import {Pagination} from "react-bootstrap";
import {EkoTable, TableHeader} from "../../../../shared/components/table";
import Euro from "../../../../shared/components/Euro";
import {DateFromIso} from "../../../../shared/components/date";
import {NumberFormatBase, NumericFormat} from "react-number-format";
import {FormattedNumber} from "react-intl";

// interface Props {
//     isArchived: boolean,
//     setCustomerId: React.Dispatch<React.SetStateAction<string | undefined | null>>,
//     setShowCustomerModal: React.Dispatch<React.SetStateAction<boolean>>,
//     setShowCustomerAddressesModal: React.Dispatch<React.SetStateAction<boolean>> | null,
//     setCustomerCompanyName: React.Dispatch<React.SetStateAction<string | undefined | null>> | null
// }

const CustomerTable: React.FC =
    () => {
        const [searchParams, setSearchParams] = useSearchParams()
        const [pageSize] = useState(150);
        const [currentPage, setPage] = useState(1);
        const [customerCount, setCustomerCount] = useState(0);
        const [pageCount, setPageCount] = useState(1);
        const skip = (currentPage - 1) * pageSize;
        const {filterQuery} = useEkosietFilter();
        const [sortProp, setSortProp] = useState<string>("CompanyName");
        const [sortDirection, setSortDirection] = useState<"Desc" | "Asc">('Asc');
        const {
            isLoading,
            data: customers,
            isError,
        } = useListCustomersOdata(pageSize, skip, sortProp + ' ' + sortDirection, filterQuery, true);


        useEffect(() => {
            const page = searchParams.get('page');
            const pageParamNumber = parseInt(page || '1', 10); // comes in as a string, convert to int

            if (pageParamNumber > 0 && pageParamNumber <= pageCount) {
                setPage(pageParamNumber)
            }
        }, [pageCount, searchParams]);

        useEffect(() => {
            if (customers && customers['@odata.count']) {
                setCustomerCount(customers['@odata.count'] as number)
            }
        }, [customers]);

        useEffect(() => {
            if (customerCount > 0) {
                setPageCount(Math.ceil(customerCount / pageSize))
            }
        }, [customerCount, pageSize]);


        const paginationItems = useMemo(() => {
            let items = []
            for (let number = 1; number <= pageCount; number++) {
                items.push(
                    <Pagination.Item key={number} active={number === currentPage} onClick={() => {
                        setSearchParams(`?${new URLSearchParams({page: number.toString()})}`)
                    }}>
                        {number}
                    </Pagination.Item>,
                );
            }
            return items
        }, [pageCount, currentPage, setSearchParams]);



        if (isError) {
            return <>Fout bij het ophalen van de orders!</>;
        }

        // if (!customers || !customers.value) return <>Geen orders gevonden</>;


        return (
            <>
                <EkoCardBody className='py-4'>
                    <div className='table-responsive'>
                        <EkoTable>
                            <TableHeader>
                                <SortableColumnHeader
                                    columnName="CompanyName"
                                    currentSortProp={sortProp}
                                    currentSortDirection={sortDirection}
                                    onSortChange={(newSortProp, newSortDirection) => {
                                        setSortProp(newSortProp);
                                        setSortDirection(newSortDirection);
                                    }}
                                >Klant</SortableColumnHeader>
                                <SortableColumnHeader
                                    columnName="avgSalesLast4Months"
                                    currentSortProp={sortProp}
                                    currentSortDirection={sortDirection}
                                    onSortChange={(newSortProp, newSortDirection) => {
                                        setSortProp(newSortProp);
                                        setSortDirection(newSortDirection);
                                    }}
                                >Gem sales (~4 maanden)</SortableColumnHeader>
                                <SortableColumnHeader
                                    columnName="growthInSales"
                                    currentSortProp={sortProp}
                                    currentSortDirection={sortDirection}
                                    onSortChange={(newSortProp, newSortDirection) => {
                                        setSortProp(newSortProp);
                                        setSortDirection(newSortDirection);
                                    }}
                                >Groei</SortableColumnHeader>
                                <SortableColumnHeader
                                    columnName="latestOrderDate"
                                    currentSortProp={sortProp}
                                    currentSortDirection={sortDirection}
                                    onSortChange={(newSortProp, newSortDirection) => {
                                        setSortProp(newSortProp);
                                        setSortDirection(newSortDirection);
                                    }}
                                >Laatste Order</SortableColumnHeader>
                                <th>Prijslijst</th>
                                <th >Verzendkosten</th>
                                <th className=' text-end'>Acties</th>
                            </TableHeader>
                            <tbody>
                            {isLoading &&
                                <tr>
                                    <td>
                                        <Loading />
                                    </td>
                                </tr>
                            }
                            {customers && customers.value && customers.value.map((customer) =>
                                    <tr key={customer.id}>
                                        <td>
                                            <Link
                                                className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                                                to={CrmRoutePath.link(CrmRoutePath.customerLink(customer.id))}>
                                                {customer.companyName}
                                            </Link>
                                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                           <DateFromIso
                                               isoDateString={customer.created}/> | {customer.email} | {customer.telephone}
                            </span>
                                        </td>
                                        <td className={'text-end pe-5'}>
                                            <Euro value={customer.avgSalesLast4Months}/>
                                        </td>
                                        <td className={'text-center'}>
                                            <div className={'d-flex align-items-center justify-content-start'}>

                                                {customer.growthInSales ?
                                                    <>
                                                    <span
                                                        title={`${customer.avgSalesLast4Months} / ${customer.avgSalesLast12Months} = ${customer.growthInSales}`}
                                                        className={`bullet bullet-dot bg-${customer.growthInSales > 1.2 ? 'success' : customer.growthInSales < 0.8 ? 'danger' : 'light'} h-15px w-15px me-1`}></span>
                                                        <NumericFormat
                                                            className={'me-1 text-nowrap'} decimalScale={0}
                                                            value={(customer.growthInSales * 100) - 100}
                                                            displayType={'text'}
                                                            thousandSeparator={true} suffix={'%'}/>
                                                    </>
                                                    :
                                                    <>
                                                        nvt
                                                    </>
                                                }
                                            </div>

                                        </td>
                                        <td className={'text-center'}>
                                            {customer.latestOrderDate === null ? 'Geen orders' :
                                                <DateFromIso isoDateString={customer.latestOrderDate!}/>}
                                        </td>
                                        <td>
                                            {customer.catalogName}<br/>
                                            {customer.priceListName}
                                        </td>
                                        <td>
                                            <BooleanBadge value={!customer.waiveTransportCosts}/>
                                        </td>
                                        <td className='text-end'>
                                            <Link
                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                to={CrmRoutePath.link(CrmRoutePath.customerLink(customer.id))}>
                                                <i className={'fas fa-pencil'}/>
                                            </Link>
                                        </td>
                                    </tr>
                            )
                            }
                            </tbody>
                        </EkoTable>
                        {paginationItems.length > 1 &&
                            <div>
                                <Pagination className='justify-content-start mt-3'>{paginationItems}</Pagination>
                            </div>
                        }
                    </div>
                </EkoCardBody>
            </>
        )
    }

type SortableColumnHeaderProps = {
    columnName: string;
    currentSortProp: string;
    currentSortDirection: 'Asc' | 'Desc';
    onSortChange: (newSortProp: string, newSortDirection: 'Asc' | 'Desc') => void;
    children: ReactNode
};

const SortableColumnHeader: React.FC<SortableColumnHeaderProps> = ({
                                                                       columnName,
                                                                       currentSortProp,
                                                                       children,
                                                                       currentSortDirection,
                                                                       onSortChange
                                                                   }) => {
    const handleSortChange = () => {
        if (currentSortProp === columnName) {
            // Toggle the direction if the same column is clicked
            const newDirection = currentSortDirection === 'Asc' ? 'Desc' : 'Asc';
            onSortChange(columnName, newDirection);
        } else {
            // Set a default direction when a new column is clicked
            onSortChange(columnName, 'Asc');
        }
    };

    return (
        <th className='min-w-140px cursor-pointer' onClick={handleSortChange}>
            <div className={'d-flex justify-content-between align-items-center'}>
            {children}
            {currentSortProp === columnName ?
                <i className={`fas fa-sort-${currentSortDirection === 'Asc' ? 'down' : 'up'} ms-3 ${currentSortProp === columnName ? 'text-dark' : ''}`}/>
                :
                <i className={`fas fa-sort  ms-3 `}/>
            }
            </div>
        </th>
    );
};

export {CustomerTable}

import React, {FC} from 'react';
import {OverlayTrigger} from 'react-bootstrap';
import {PriceSpecification} from '../../../modules/api-client/generated';
import {PriceSpecificationPopover} from '../../../backoffice/modules/pricing/PriceSpecificationPopover';
import {TooltipProps} from 'react-bootstrap/Tooltip';
import Euro from '../Euro';

export interface ConfiguratorPriceProps {
    priceSpecification?: PriceSpecification;
}

export const ConfiguratorPrice: FC<ConfiguratorPriceProps> = ({priceSpecification}) => {

    const popover = (props: TooltipProps & React.RefAttributes<HTMLDivElement>) => PriceSpecificationPopover({
        spec: priceSpecification!,
        level: 0, ...props,
    });

    return <>
				<OverlayTrigger trigger='click' rootClose placement='bottom' overlay={popover}>
					<button type='button'
							className='dropdown-toggle form-control form-control-lg'><Euro
						value={priceSpecification?.unitPrice}/>
					</button>
				</OverlayTrigger>
    </>
};
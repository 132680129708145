/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {PageTitle} from "../../../../../_metronic/layout/core";
import {EkoCard} from "../../../../shared/components/card/EkoCard";
import {EkoCardBody} from "../../../../shared/components/card/EkoCardBody";
import {EkoCardHeader} from "../../../../shared/components/card/EkoCardHeader";
import {YTDStatsTile} from "../../sm/components/YTDStatsTile";
import {OrderIntake} from "../../sm/components/OrderIntake";
import {toAbsoluteUrl} from "../../../../../_metronic/helpers";

export function MgtSales() {
    return (
        <>
            <PageTitle>Intake</PageTitle>
            <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
                {/* begin::Col */}
                <div className='col col-lg-8 mb-md-5 mb-xl-10'>
                    <EkoCard>
                        <EkoCardHeader title={'Wekelijkse order intake'}>
                        </EkoCardHeader>
                        <EkoCardBody>
                            <OrderIntake/>
                        </EkoCardBody>
                    </EkoCard>
                </div>
                <div className='col col-lg-4 mb-md-5 mb-xl-10'>
                    <YTDStatsTile
                        className='mb-5 mb-xl-10'
                        description='Dit jaar'
                        color='#F1416C'
                        img={toAbsoluteUrl('/media/patterns/vector-1.png')}/>
                </div>
            </div>
        </>
    )
}

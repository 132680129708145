import React, {useCallback} from "react";
import ApiClient from "../../../../modules/api-client/ApiClient";
import {DownloadButton} from "../../../../shared/components/DownloadButton";
import {mapDownload} from "../../../../shared/hooks/useDownloadFile";


export const ManchettesDownloadButton: React.FC<{ batch: string, }> = ({batch}) => {

    const downloadCallback = useCallback(() => {
        return ApiClient.Pm.ProductionBatch.ekiExport(batch, undefined, {
            responseType: 'arraybuffer',
            headers: {
                'Accept': 'application/zip'
            }
        }).then(mapDownload);
    }, []);

    return <DownloadButton title="Download Manchetten DXF" downloadCallback={downloadCallback}/>
};



import React from "react";
import { PageTitle } from "../../../../../_metronic/layout/core";
import { EkoCard, EkoCardBody } from "../../../../../_metronic/helpers";
import { ExpeditionPlanningList } from "../expedition/components/ExpeditionPlanningList";

const ExpeditionPlanning: React.FC = () => {
    
    return (
        <>            
            <PageTitle>
                Expeditie planning
            </PageTitle>
            <EkoCard>
                <EkoCardBody>
                    <ExpeditionPlanningList />
                </EkoCardBody>
            </EkoCard>
        </>
    )
}

export default ExpeditionPlanning

import { useQueryClient } from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import { NotFound, ValidationFailed } from "../../../../modules/api-client/Responses";
import { useEkosietMutation } from "../../../../shared/hooks/useEkosietMutation";

export const useContactFormDeleteMutation = (id: string) => {
    const queryClient = useQueryClient()
    return useEkosietMutation<void, NotFound | ValidationFailed, string>(async (id) => {
        const response = await ApiClient.Crm.ContactForm.crmContactFormIdDelete(id, undefined);
        return response.data;
    },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['contactFormsListGet']).then();
            }
        }
    );
};
import React, {FC} from 'react';
import {Button, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {TooltipProps} from 'react-bootstrap/Tooltip';
import {SbomModel} from "../../../../../modules/api-client/generated";
import {EkoTable} from "../../../../../shared/components/table/EkoTable";
import {TableHeader} from "../../../../../shared/components/table/TableHeader";
import Euro from "../../../../../shared/components/Euro";

export interface SbomPriceProps {
	Sbom: SbomModel;
	price: number;
}

export const SbomPrice: FC<SbomPriceProps> = ({Sbom,price}) => {
	const tooltip = (
		<Tooltip  id="tooltip">
			<EkoTable className=' gy-1'>
				<TableHeader>
					<th colSpan={2}>Prijsopbouw</th>
				</TableHeader>
				<tbody>
				{Sbom.lines && Sbom.lines.map((s, index) => {
						return (
							<tr key={index}>
								<td className='fit-content text-end pe-0'>
									{s.quantity}x
								</td>
								<td className='text-start ps-1'>
									{s.description}
								</td>
								<td className='fit-content text-end'>
									<Euro value={s?.price}/>
								</td>
							</tr>
						);
					}
				)}
				</tbody>
			</EkoTable>
		</Tooltip>
	);

	return <>
		<OverlayTrigger placement="top" overlay={tooltip}>
			<span>
			<Euro value={price}/> <i className="fas fa-info-circle"></i>
			</span>
		</OverlayTrigger>
	</>;
};
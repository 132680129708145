import {FC} from 'react'
import {Form, Formik, FormikHelpers} from 'formik'
import {
    OrderLineType,
    PostConfiguratorOrderLineRepresentation,
    PostProductOrderLineRepresentation,
    PostQuantityOrderLineRepresentation,
    PostShippingCostOrderLineRepresentation,
    PostTextOrderLineRepresentation
} from '../../../../../../../../modules/api-client/generated'
import TextField from "../../../../../../../../shared/components/TextField";
import {usePostOrderline} from "../../../../hooks/orderline/use-post-orderline";
import {SalesProductPicker, StandardProductPicker} from "../../../../../../pim/catalogitem/CatalogItemPicker";
import {useGetOrder} from '../../../../hooks/use-get-order';
import * as Yup from 'yup'

type OrderLineFormValues =
    PostConfiguratorOrderLineRepresentation |
    PostProductOrderLineRepresentation |
    PostShippingCostOrderLineRepresentation |
    PostTextOrderLineRepresentation;

const formSchema = Yup.object().shape({
        remark: Yup.string().min(3, 'Minimaal 3 karakters.').notRequired().nullable(),

        // quantity: Yup.number().when('orderlineType', {
        //     is: (orderlineType: OrderLineType) => { return orderlineType === OrderLineType.ProductLine || orderlineType === OrderLineType.ConfigurationLine },
        //     then: Yup.number()
        //              .min(1, 'Minimaal 1.')
        //              .required('Aantal is verplicht.')
        // }),

        quantity: Yup.number()            
            .when('orderlineType', {
                is: (orderlineType: OrderLineType) => {
                    return orderlineType === OrderLineType.ConfigurationLine
                },
                then: Yup.number()
                .min(0, 'Negatief aantal is niet toegestaan. Gebruik een negatieve prijs!')                        
                .max(999, 'Aantalen groter dan 999 zijn niet toegestaan. Gebruik meerdere order regels.')
                .required('Aantal is verplicht.'),
                otherwise: Yup.number()
                .min(0, 'Negatief aantal is niet toegestaan. Gebruik een negatieve prijs!')                                        
                .required('Aantal is verplicht.')
            })
            ,

        catalogItemId: Yup.string().when('orderlineType', {
            is: (orderlineType: OrderLineType) => {
                return orderlineType === OrderLineType.ProductLine || orderlineType === OrderLineType.ConfigurationLine
            },
            then: Yup.string().required('Product is verplicht.')
        }),

        price: Yup.number().when('orderlineType', {
            is: (orderlineType: OrderLineType) => {
                return orderlineType === OrderLineType.TextLine
            },
            then: Yup.number().required('Prijs is verplicht')
        }),

        merk: Yup.string()
            .notRequired()
            .nullable(),
    }
);

type Props = {
    orderId: string,
    catalogId: string,
    orderLineData?: PostQuantityOrderLineRepresentation,
    handleClose: () => void,
}

const PostOrderlineForm: FC<Props> = ({orderId, catalogId, orderLineData, handleClose}) => {

    const mutatePostOrderLine = usePostOrderline();
    const {refetch: refetchOrder} = useGetOrder(orderId, false);

    if (!orderId || !orderLineData) return (<></>)

    const orderLineFormValues: OrderLineFormValues = {
        ...orderLineData,
    }

    const onSubmit = (values: OrderLineFormValues, {setSubmitting}: FormikHelpers<OrderLineFormValues>) => {
        return new Promise<void>((resolve, reject) => {
            mutatePostOrderLine.mutate({orderId: orderId, line: values}, {
                onSuccess: () => {
                    resolve();
                    handleClose()
                },
                onError: () => {
                    reject();
                }
            });

        }).finally(() => {
            setSubmitting(false);
            refetchOrder();
        });
    };

    return (
        <>
            <Formik initialValues={orderLineFormValues} onSubmit={onSubmit} validationSchema={formSchema}>
                {({
                      values,
                      getFieldProps,
                      isSubmitting
                  }) => (
                    <Form>
                        {/* begin form element */}
                        {values.orderlineType === OrderLineType.ProductLine &&
                            <div className="row mb-4">
                                <div className="col-4">
                                    <label className="form-label" htmlFor="type">Product</label>
                                </div>
                                <div className="col-8">
                                    <SalesProductPicker name='catalogItemId' catalogId={catalogId} required={true}/>
                                </div>
                            </div>
                        }
                        {values.orderlineType === OrderLineType.TextLine &&
                            <>
                                <TextField
                                    row={true}
                                    label={'Omschrijving'}
                                    {...getFieldProps('title')}
                                />
                                <TextField type={'number'}
                                           row={true}
                                           label={'Prijs'}
                                           {...getFieldProps('price')}
                                />
                            </>
                        }

                        <TextField type={'number'} min={0}
                                   label={'Aantal'}
                                   row={true}
                                   {...getFieldProps('quantity')}
                        />

                        <TextField
                            row={true}
                            label={'Opmerking'}
                            {...getFieldProps('remark')}
                        />
                        {values.orderlineType === OrderLineType.ConfigurationLine &&
                            <TextField
                                row={true}
                                label={'Merk'}
                                {...getFieldProps('merk')}
                            />
                        }

                        {/* begin::Actions */}
                        <div className='text-center pt-5'>
                            <button
                                type='reset'
                                className='btn btn-light me-3'
                                data-kt-customer-modal-action='cancel'
                                data-dismiss="modal"
                                onClick={() => handleClose()}
                                disabled={isSubmitting}
                            >
                                Annuleren
                            </button>

                            <button
                                type='submit'
                                className='btn btn-primary'
                                data-kt-customer-modal-action='submit'
                                disabled={isSubmitting}
                            >
                                <span className='indicator-label'>Opslaan
                                </span>
                                {isSubmitting && (
                                    <span className='indicator-progress'>
                                        Even wachten a.u.b
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                        {/* end::Actions */}

                    </Form>
                )}
            </Formik>
        </>
    )
}

export {PostOrderlineForm}

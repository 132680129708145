/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import clsx from "clsx";
import {ProductionStatusCodes} from "../../../../modules/api-client/generated";

type Props = {
    status?: string | null
    className?: string
}
const ProductionStatus: React.FC<Props> = ({ status, className }) => {
    let color = 'light';
    let title = 'Onbekend';
    switch (status) {
        case ProductionStatusCodes.NotStarted:
            color = 'light'
            title = 'Niet gestart'
            break;
        case ProductionStatusCodes.Started:
            color = 'info'
            title = 'Gestart'
            break;
        case ProductionStatusCodes.Cancelled:
            color = 'danger'
            title = 'Geannuleerd'
            break;
        case ProductionStatusCodes.Failed:
            color = 'dark'
            title = 'Gefaald'
            break;
        case ProductionStatusCodes.Finished:
            color = 'success'
            title = 'Gereed'
            break;
        default:
            color = 'dark'
    }

    return (
        <>
            <span className={clsx(
                'badge badge-' + color,
                className && className)}>
                {title}
            </span>
        </>
    )
}

const ProductionStates = [

    {
        label: "Niet gestart",
        value: ProductionStatusCodes.NotStarted,
        key: "NotStarted"
    },
    {
        label: "Gestart",
        value: ProductionStatusCodes.Started,
        key: "Started"
    },
    {
        label: "Gereed",
        value: ProductionStatusCodes.Finished,
        key: "Finished"
    },
    {
        label: "Gefaald",
        value: ProductionStatusCodes.Failed,
        key: "Failed"
    },
    {
        label: "Geannuleerd",
        value: ProductionStatusCodes.Cancelled,
        key: "Cancelled"
    }
]

export { ProductionStatus, ProductionStates }
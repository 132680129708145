import React, {useEffect, useState} from 'react';
import ApiClient from '../../../../modules/api-client/ApiClient';
import {useSearchParams} from 'react-router-dom';
// import {Diff, Hunk, parseDiff} from 'react-diff-view/esm';
// import {File} from 'gitdiff-parser';

// import 'react-diff-view/style/index.css';
import { ConfiguredSillDiffResult } from '../../../../modules/api-client/generated';
import { AxiosResponse } from 'axios';


export const ConfiguredSillDiffPage: React.FC = () => {

	const [searchParams, setSearchParams] = useSearchParams({
		left: '01000000-e1ec-8afa-22e5-08db89e606e3',
		right: '01000000-ec1b-8631-dba7-08db8c56334d',
	});

	const [diff, setDiff] = useState<string | null>();

	const left = searchParams.get('left');
	const right = searchParams.get('right');

	const refresh = async () => {

		setDiff(null);

		if (left && right) {
			const response = await ApiClient.Core.ConfiguredSill.diff(left!, right!).then((r: AxiosResponse<ConfiguredSillDiffResult>) => r.data);
			setDiff(response.diff);
		}
	};

	useEffect(() => {
		refresh().then();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onLeftChange = (value: string) => {
		searchParams.set('left', value);
		setSearchParams(searchParams);
	};

	const onRightChange = (value: string) => {

		searchParams.set('right', value);
		setSearchParams(searchParams);
	};

	// const files = parseDiff(diff ?? '');
	//
	// const renderFile = ({oldRevision, newRevision, type, hunks}: File): React.ReactNode => (
	// 	<Diff key={oldRevision + '-' + newRevision} viewType='split' diffType={type} hunks={hunks}>
	// 		{hunks => hunks.map(hunk => <Hunk key={hunk.content} hunk={hunk} />)}
	// 	</Diff>
	// );


	return <>
		<h1>
			Diff
		</h1>
		<div className='row'>
			<div className='col-3'>
				<input type='text' className='form-control' placeholder='Left Id' value={left ?? ''}
					   onChange={e => onLeftChange(e.target.value)} />
			</div>
			<div className='col-3'>
				<input type='text' className='form-control' placeholder='Right Id' value={right ?? ''}
					   onChange={e => onRightChange(e.target.value)} />
			</div>
			<div className='col-3'>
				<button type='button' className='btn btn-primary' onClick={e => refresh()} disabled={!left || !right}>Compare</button>
			</div>
		</div>
		<div className='row mt-5'>
			<div className='col'>
				<div className=' bg-white'>
					TODO
					{/*{files && files.map(renderFile)}*/}
				</div>
			</div>
		</div>
	</>;
};

import React, {useEffect, useMemo, useRef, useState} from "react";
import {Link, useSearchParams} from "react-router-dom";
import {EkoCard, EkoCardBody} from "../../../../../_metronic/helpers";
import {EkoTable, TableHeader} from '../../../../shared/components/table';
import Loading from "../../../../shared/components/Loading";
import {PageTitle} from "../../../../../_metronic/layout/core";
import {useListNeutenOdata} from "../neut/use-list-neuten-odata";
import {Pagination} from "react-bootstrap";
import {useDebounce} from "@uidotdev/usehooks";
import ConfiguredNeutSvg from "../../../../shared/components/ConfiguredNeutSvg";
import {NeutPicker} from "../components/NeutPicker";
import { NeutListItem } from "../../../../modules/api-client/generated";

const NeutList: React.FC = () => {

    const [searchParams, setSearchParams] = useSearchParams()
    const [pageSize, setPageSize] = useState(150);
    const [currentPage, setPage] = useState(1);
    const [neutCount, setOrdersCount] = useState(0);
    const [pageCount, setPageCount] = useState(1);
    const skip = (currentPage - 1) * pageSize;
    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, 2500);
    const {
        isInitialLoading,
        data: list,
        isError,
    } = useListNeutenOdata(pageSize, skip, "Sold desc", undefined, debouncedSearchTerm, true);

    useEffect(() => {
        const page = searchParams.get('page');
        const pageParamNumber = parseInt(page || '1', 10); // comes in as a string, convert to int

        if (pageParamNumber > 0 && pageParamNumber <= pageCount) {
            setPage(pageParamNumber)
        }
    }, [pageCount, searchParams]);

    useEffect(() => {
        if (list && list['@odata.count']) {
            setOrdersCount(list['@odata.count']!)
        }
    }, [list]);

    useEffect(() => {
        if (neutCount > 0) {
            setPageCount(Math.ceil(neutCount / pageSize))
        }
    }, [neutCount, pageSize]);

    const paginationItems = useMemo(() => {
        let items = []
        for (let number = 1; number <= pageCount; number++) {
            items.push(
                <Pagination.Item key={number} active={number === currentPage} onClick={() => {
                    setSearchParams(`?${new URLSearchParams({page: number.toString()})}`)
                }}>
                    {number}
                </Pagination.Item>,
            );
        }
        return items
    }, [pageCount, currentPage, setSearchParams]);


    const search = (searchTerm: string) => {
        // if(searchTerm.length ==4){
        setSearchTerm(searchTerm);
        // }
    }

    if (isInitialLoading) {
        return <Loading/>;
    }
    if (isError) {
        return <>ERROR!</>;
    }
    if (!list || !list.value) return <></>;


    return <>
        <PageTitle
            toolbar={<button className={'btn btn-primary btn-sm disabled'} onClick={() => 1}>Neut toevoegen</button>}
            breadcrumbs={[]}>Neuten</PageTitle>
        <p><strong>{neutCount}</strong> unieke neuten
        </p>

                    <div className={'row'}>
                        <div className={'col-8'}>

                            {/* <NeutSearch/> //odata filter */}

                            <div className="row mb-5">
                                <div className="col mb-lg-0 mb-6 d-flex align-items-center justify-content-between">
                                    <NeutPicker  fieldName={''} onChange={(id, code, svg) => search(code)}/>
                                    <button className="btn btn-secondary btn-secondary--icon btn-sm ms-3" type='reset'
                                            onClick={(e) => setSearchTerm("")}>
                                <span>
                                    <i className="la la-close"></i>
                                    <span>Reset</span>
                                </span>
                                    </button>
                                </div>

                            </div>

                            <EkoCard>
                                <EkoCardBody>
                                    <EkoTable>
                                        <TableHeader>
                                            <th>Code</th>
                                            <th></th>
                                            <th className=" ">Profilering</th>
                                            <th className="">Afmetingen</th>
                                            <th className="">Dorpelmodel</th>
                                            <th className="">Aantal besteld</th>
                                        </TableHeader>
                                        <tbody>
                                        {(!list || list.value.length === 0) &&
                                            <tr>
                                                <td colSpan={7} className="text-center">Geen neuten gevonden</td>
                                            </tr>}

                                        {list && list.value.map((neut: NeutListItem) => <tr key={neut.id}>

                                            <td className="fit-content">
                                                <Link to={neut.code}>{neut.code}</Link>
                                            </td>
                                            <td>
                                                <ConfiguredNeutSvg svg={neut.svg} svgWidth={'65px'} svgHeight={'95px'}/>
                                            </td>
                                            <td className="">
                                                {neut.profileLeft}<br/>
                                                {neut.profileRight}<br/>
                                                {neut.profileContra}
                                            </td>
                                            <td className="">
                                                {neut.height}mm*
                                                {neut.width}mm
                                            </td>
                                            <td className="">
                                                {neut.sillModel}
                                            </td>
                                            <td className="fit-content">
                                                <span className={'badge badge-primary me-1'}>{neut.sold}</span>
                                                <span
                                                    className={'badge badge-secondary me-1'}>{(neut.percentageOfTotal * 100.0).toPrecision(3)}%</span>
                                                <span
                                                    className={'badge badge-warning'}> {(neut.runningTotal * 100.0).toPrecision(3)}%</span>
                                            </td>
                                        </tr>)}
                                        </tbody>
                                    </EkoTable>
                                    <div>
                                        <span
                                            className={`text-muted`}>{list!.value.length}/{list!["@odata.count"]} neuten</span>
                                        <Pagination
                                            className='justify-content-start mt-3'>{paginationItems}</Pagination>
                                    </div>
                                </EkoCardBody>
                            </EkoCard>

                        </div>
                        <div className={'col-4'}>
                        </div>
                    </div>
    </>
}

export default NeutList;
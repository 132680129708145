import { Transition } from "@headlessui/react";
import { Popover } from "@headlessui/react";
import classNames from "classnames";
import { formatDate } from ".";
import { Calendar } from "../calendar";

interface Props {
  value?: Date;
  placeholder?: string;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  onChange: (val: Date) => void;
  buttonClassName?: string | undefined;
  dateFormat?: string | undefined;
}

export const DateInput = ({ value, placeholder, minDate, maxDate, disabled, onChange, buttonClassName, dateFormat }: Props) => {

  dateFormat = dateFormat || "d MMMM yyyy"
  
  return (
    <>
      <Popover className="relative z-10 me-3 ">
        {({ open, close }) => (
          <>
            <Popover.Button
              disabled={disabled}
              className={classNames(
                "form-control me-3 ",
                buttonClassName
              )}                            
            >
<div className={'d-flex justify-content-between align-items-center'}>
<span>
          <i className={classNames(
              "fa-solid fa-calendar me-3",
          )}></i>

  {value &&
      <span>{formatDate(value, dateFormat)}</span>
  }

  {!value &&
      <span>{placeholder}</span>
  }

</span>
  <i className={classNames(
      open
          ? "fa-chevron-up"
          : "fa-chevron-down",
      "fa-solid",
  )}></i>
</div>
            </Popover.Button>
            <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Popover.Panel
                static
                className="position-absolute p-5 mt-3 border bg-white w-100 min-w-300px z-index-3 rounded"
              >
                <Calendar
                  value={value}
                  onChange={(val) => {
                    onChange(val);
                    close();
                  }}
                  minDate={minDate}
                  maxDate={maxDate}
                  dontExcludeWeekendDays={true}
                />
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  );
};
import {PageTitle} from '../../../../../_metronic/layout/core';
import SpinnerButton from '../../../../shared/components/SpinnerButton';
import {useMutation, useQuery} from '@tanstack/react-query';
import ApiClient from '../../../../modules/api-client/ApiClient';
import {useConfirmDialog} from '../../../../shared/components/ConfirmDialog';
import {toast} from "react-hot-toast";
import React, {useState} from "react";
import {EkoCard} from "../../../../shared/components/card/EkoCard";
import {EkoCardHeader} from "../../../../shared/components/card/EkoCardHeader";
import {EkoCardBody} from "../../../../shared/components/card/EkoCardBody";
import {CustomerSillStats as x, ProductionItemsSaga} from "../../../../modules/api-client/generated";
import Loading from "../../../../shared/components/Loading";
import {TableHeader} from "../../../../shared/components/table/TableHeader";
import {EkoTable} from "../../../../shared/components/table/EkoTable";
import {formatDate} from "../../../../shared/components/date";
import {Link} from "react-router-dom";
import {SmRoutePath} from "../../sm/SmRoutes";

export function ProductionSagaPage() {
    const {isInitialLoading, data} = useQuery<ProductionItemsSaga[], TypeError, ProductionItemsSaga[]>(
        ['systemAdminProductionItemsSagas'],
        () => ApiClient.Pm.SystemAdmin.productionItemsSagas().then((res) => res.data));
    if (!data) return <Loading/>
    if (isInitialLoading) return <Loading/>

    return (
        <>
            <PageTitle>Volg het aanmaken van productionitems</PageTitle>
            <EkoTable className={'table-row-dashed table-row-gray-300 gy-7'}>
                <TableHeader>
                    <th>Order</th>
                    <th>Status</th>
                    <th>Orderregels - Productieitems</th>
                    <th>Aanmaken gestart</th>
                    <th>Aangemaakt</th>
                    <th>Verwijderen gestart</th>
                    <th>Verwijderd</th>
                    <th>Gefaald</th>
                </TableHeader>
                <tbody>
                {data.reverse().map((order) => {
                    return (
                        <tr key={order.orderId} className=" fs-6 text-gray-800">
                            <td><Link to={SmRoutePath.link(SmRoutePath.orderEditLink(order.orderId!))}
                                      className='text-hover-primary'>
                                {order.orderNo}
                            </Link></td>
                            <td>{order.currentState}</td>
                            <td>{order.numberOfSillsInOrder} - {order.numberOfProductionItems} = {order.diff}</td>
                            <td>{formatDate(order.creating!, "dd-MM-yy HH:mm:ss")}</td>
                            <td>{formatDate(order.creating!, "dd-MM-yy HH:mm:ss")}</td>
                            <td>{formatDate(order.created!, "dd-MM-yy HH:mm:ss")}</td>
                            <td>{formatDate(order.deleting!, "dd-MM-yy HH:mm:ss")}</td>
                            <td>{formatDate(order.deleted!, "dd-MM-yy HH:mm:ss")}</td>
                            <td>{formatDate(order.failed!, "dd-MM-yy HH:mm:ss")}</td>
                        </tr>
                    )
                })}
                </tbody>
            </EkoTable>
        </>
    );
}

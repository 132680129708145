import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast"
import ApiClient from "../../../../../modules/api-client/ApiClient";
import { useEkosietMutation } from "../../../../../shared/hooks/useEkosietMutation";
import { NotFound, ValidationFailed } from "../../../../../modules/api-client/Responses";
import {sleep} from "@tanstack/react-query/build/lib/__tests__/utils";

export const usePutFinancialStatusNotInvoiced = (id: string) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<void, NotFound | ValidationFailed, undefined>(() => {
            return ApiClient.Fm.Invoice.pmInvoicesIdNotInvoicedPut(id, undefined, undefined).then();
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['smOrderIdGet', id]).then();
                queryClient.invalidateQueries(['pmInvoicesListOdataGet']).then();
                toast.success("Financiële status gewijzigd");
            },
            onError: () => {
                toast.error("Financiële status wijziging mislukt!");
            }
        }
    );
};

export const usePutFinancialStatusInvoiced = (id: string) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<void, NotFound | ValidationFailed, undefined>(() => {
            return ApiClient.Fm.Invoice.pmInvoicesIdInvoicedPut(id, undefined, undefined).then();
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['smOrderIdGet', id]).then();
                queryClient.invalidateQueries(['pmInvoicesListOdataGet']).then();

                toast.success("Financiële status gewijzigd");
                // Since the order status can be updated in the background, we need to wait a bit before refreshing the data.
                // This is a temporary solution until we have a better way to handle this!!
                setTimeout(() => {
                    queryClient.invalidateQueries(['smOrderIdGet', id]).then();
                    queryClient.invalidateQueries(['pmInvoicesListOdataGet']).then();
                },3000)

            },
            onError: () => {
                toast.error("Financiële status wijziging mislukt!");
            }
        }
    );
};
export const usePutFinancialStatusPaid = (id: string) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<void, NotFound | ValidationFailed, undefined>(() => {
            return ApiClient.Fm.Invoice.pmInvoicesIdPaidPut(id, undefined, undefined).then();
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['smOrderIdGet', id]).then();
                queryClient.invalidateQueries(['pmInvoicesListOdataGet']).then();

                toast.success("Financiële status gewijzigd");

            },
            onError: () => {
                toast.error("Financiële status wijziging mislukt!");
            }
        }
    );
};

import {useQueryClient} from "@tanstack/react-query";
import {useEkosietMutation} from "../../../../../shared/hooks/useEkosietMutation";
import {NotFound, ValidationFailed} from "../../../../../modules/api-client/Responses";
import ApiClient from "../../../../../modules/api-client/ApiClient";
import {useGetSillProfilesQuery} from "./use-get-sill-profiles-query";
import {SillProfiles} from "../../../../../modules/api-client/generated";

export const useSillProfiles = (options: {
    sillId: string,
    neutPositionId: string,
    compartmentLayoutTypeId: string,
    onProfilesLoaded?: (profiles: SillProfiles) => void
}) => {

    const queryClient = useQueryClient();

    const remove = useEkosietMutation<void, NotFound | ValidationFailed, {
        sillId: string,
        neutPositionId: string,
        compartmentLayoutTypeId: string,
        profileIds: string[]
    }>(async ({sillId, neutPositionId, compartmentLayoutTypeId, profileIds}) => {

            await ApiClient.Pim.SillProfiles.deleteSillProfiles(
                sillId,
                neutPositionId,
                compartmentLayoutTypeId,
                undefined,
                profileIds);
        },
        {
            onSuccess: (_, {sillId, neutPositionId, compartmentLayoutTypeId}) => {
                queryClient.invalidateQueries(['ApiClient.Pim.SillProfiles.getProfiles', sillId, neutPositionId, compartmentLayoutTypeId]).then();
            },
        },
    );

    const add = useEkosietMutation<void, NotFound | ValidationFailed, {
        sillId: string,
        neutPositionId: string,
        compartmentLayoutTypeId: string,
        profileIds: string[]
    }>(async ({sillId, neutPositionId, compartmentLayoutTypeId, profileIds}) => {

            await ApiClient.Pim.SillProfiles.addSillProfiles(
                sillId,
                neutPositionId,
                compartmentLayoutTypeId,
                undefined,
                profileIds);
        },
        {
            onSuccess: (_, {sillId, neutPositionId, compartmentLayoutTypeId}) => {
                queryClient.invalidateQueries(['ApiClient.Pim.SillProfiles.getProfiles', sillId, neutPositionId, compartmentLayoutTypeId]).then();
            },
        },
    );


    const {
        isInitialLoading,
        isError,
        data: profiles,
    } = useGetSillProfilesQuery(options.sillId, options.neutPositionId, options.compartmentLayoutTypeId, {
        onSuccess: options.onProfilesLoaded
    });

    return {
        isInitialLoading,
        isError,
        profiles,
        addProfiles: async (profileIds: string[]) => {
            await add.mutateAsync({
                sillId: options.sillId,
                neutPositionId: options.neutPositionId,
                compartmentLayoutTypeId: options.compartmentLayoutTypeId,
                profileIds
            });
        },
        removeProfiles: async (profileIds: string[]) => {
            await remove.mutateAsync({
                sillId: options.sillId,
                neutPositionId: options.neutPositionId,
                compartmentLayoutTypeId: options.compartmentLayoutTypeId,
                profileIds
            });
        }
    }
};
import React, { useEffect, useId, useRef } from 'react';
import { NeutEditorProps } from './NeutEditor';
import { Field, useFormikContext } from 'formik';
import {
    NeutDrillHolePositioningStrategyName,
    SillConfigurationNeut,
    SillConfigurationNeutDrillHole,
    SillNeutDrillHoleMode,
} from '../../../modules/api-client/generated';
import { useConfirmDialog } from '../ConfirmDialog';
import { useEkosietAuth0 } from '../../../modules/auth0/core/useEkosietAuth0';

export const NeutEditorDrillHoles: React.FC<NeutEditorProps> = (props) => {

    const id = useId();
    const { values, setValues } = useFormikContext<SillConfigurationNeut>();
    const originalWidth = useRef(values.width)
    const { isMedewerker } = useEkosietAuth0();
    const confirm = useConfirmDialog();

    const recalculate = async () => {
        const drillHoles = await props.calculateDefaultDrillHoles(values, props.neutOptions.drillHoleOptions!.positioningStrategyName);
        await setValues((current) => {
            return { ...current, drillHoles: drillHoles };
        });
    };

    const clear = async () => {
        await setValues((current) => {
            return { ...current, drillHoles: [] };
        });
    };

    useEffect(() => {
        if (values.width !== originalWidth.current) {
            recalculate().catch(console.log);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.width]);

    const enabled = (values.drillHoles?.length ?? 0) > 0;


    const enableDrillHoles = async (enabled: boolean) => {
        if (enabled) {
            await recalculate();
        } else {
            await clear();
        }
    };

    const deleteDrillHole = async (index: number) => {
        await setValues((current) => {
            if (!current.drillHoles) return current;

            const update = { ...current };
            update.drillHoles!.splice(index, 1);
            return update;
        });
    };

    const addDrillHole = async () => {

        const newDrillHole: SillConfigurationNeutDrillHole = {
            position: {
                x: 0,
                y: 0,
            },
        };

        await setValues((current) => {

            const update = { ...current };

            update.drillHoles ??= [];
            update.drillHoles!.push(newDrillHole);

            return update;
        });
    };

    const onDelete = async (index: number) => {
        await confirm({
            title: 'Boorgat verwijderen',
            message: 'Je staat op het punt dit boorgat te verwijderen. Wil je doorgaan?',
            confirmText: 'Verwijderen',
            onConfirm: async () => await deleteDrillHole(index),
        });
    };


    if (!props.neutOptions.drillHoleOptions) return null;

    const editable = !props.neutOptions.drillHoleOptions!.fixedPositions;

    return (
        <>
            {props.neutOptions.drillHoleMode === SillNeutDrillHoleMode.Optional &&
                <div>
                    <label htmlFor={`${id}_drillholes`} className='form-label'>
                        <small className='text-uppercase text-muted'>Bevestigingsgaten</small>
                    </label>

                    <div className='row'>
                        <div className='col-3'>
                            <select className='form-select' value={enabled ? 'true' : 'false'}
                                onChange={(e) => enableDrillHoles(e.target.value === 'true')}>
                                <option value='false'>Nee</option>
                                <option value='true'>Ja</option>
                            </select>
                        </div>
                    </div>
                </div>
            }

            {(values.drillHoles ?? []).map((dh, index) => {
                return (<div key={`${id}_drillHole_${index}`} className='d-flex'>
                    <div className='row flex-fill'>
                        <div className='col-3 d-flex align-items-center'>
                            <span>Boorgat {index + 1}</span>
                        </div>

                        <div className='col'>
                            <div className='input-group'>
                                <div className='input-group-text'>X</div>
                                <Field id={`${id}_drillholes_${index}_x`} type='number' step={0.1}
                                    name={`drillHoles[${index}].position.x`}
                                    className='form-control text-end'
                                    disabled={!editable}
                                    placeholder='X-positie' required />
                                <div className='input-group-text'>mm</div>
                            </div>
                        </div>

                        <div className='col'>
                            <div className='input-group'>
                                <div className='input-group-text'>Y</div>
                                <Field id={`${id}_drillholes_${index}_y`} type='number' step={0.1}
                                    name={`drillHoles[${index}].position.y`}
                                    className='form-control text-end'
                                    disabled={!editable}
                                    placeholder='Y-positie' required />
                                <div className='input-group-text'>mm</div>
                            </div>
                        </div>
                    </div>

                    {editable &&
                        <div className='ms-2 px-2 d-flex justify-content-center'>
                            <button type='button' className='btn btn-link mx-1' onClick={() => onDelete(index)}
                                title='Boorgat verwijderen'>
                                <i className='fas fa-trash'></i>
                            </button>
                        </div>
                    }
                </div>
                )
            })}
            {editable &&
                <div className='d-flex justify-content-between'>
                    <button type='button' className='btn btn-link me-2' onClick={() => addDrillHole()}>
                        Boorgat toevoegen
                    </button>
                </div>
            }
        </>
    );
};

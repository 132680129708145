/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {CustomerSillStats, CustomerSillStats as x} from "../../../../modules/api-client/generated";
import ApiClient from "../../../../modules/api-client/ApiClient";
import Loading from "../../../../shared/components/Loading";
import {CustomerSalesStats} from "./CustomerSalesStats";
import {CustomerSillsStats} from "./CustomerSillsStats";
import {CustomerExpeditionStats} from "./CustomerExpeditionStats";
import {DateTime} from "luxon";
export function CustomerStats() {
    const {id} = useParams<{ id: string }>();
    const [year, setYear] = useState<number|undefined>(2024);


    const {isInitialLoading, data, isLoading} = useQuery<CustomerSillStats, TypeError, CustomerSillStats>(
        ['mgtCustomerSillStats',year],
        () => ApiClient.Crm.Reporting.customer(id, year).then((res) => res.data));
    if (!data) return <Loading/>
    if (isLoading) return <Loading/>

    const currentYear = new Date().getFullYear();
    const years = [];
    for(let i = 2023; i <= currentYear; i++) {
        years.push(i);
    }

    return (
        <>
            <div className={'mb-4'}>
                {years.map((year) => (
                    <a href="#" className={`  me-3 ${data.jaar === year ? 'fw-bolder fs-2' : 'fs-4'}`} onClick={(e) => {
                        e.preventDefault();
                        setYear(year);
                    }}>{year}</a>
                ))}
                <a href="#" className={`  me-3 ${year === undefined ? 'fw-bolder fs-2' : 'fs-4'}`} onClick={(e) => {
                    e.preventDefault();
                    setYear(undefined);
                }}>Totaal</a>

            </div>
            <div className='row'>
                <div className='col col-lg-4 mb-md-5'>
                    <CustomerSalesStats data={data} title={'Sales'}/>
                </div>
                <div className='col col-lg-4 mb-md-5'>
                    <CustomerSillsStats data={data}  title={'Dorpels'}/>
                </div>
                <div className='col col-lg-4 mb-md-5'>
                    <CustomerExpeditionStats data={data}  title={'Leveringen'}/>
                </div>
            </div>
        </>
    )
}


/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {useParams} from "react-router-dom";
import {EkoCard, EkoCardBody, EkoCardHeader} from "../../../../../shared/components/card";
import {CustomerModalFormWrapper} from "../../components/CustomerModalFormWrapper";
import {CustomerAddressesModalBody} from "../../components/CustomerAddressesModalBody";
import {CustomerStats} from "../../components/CustomerStats";

export function CustomerUsers() {
    return (
            <EkoCard>
                <EkoCardHeader title={'Gebruikers'}>
                </EkoCardHeader>
                <EkoCardBody>
                    TODO
                </EkoCardBody>
            </EkoCard>
    )
}

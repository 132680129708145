import { toast } from "react-hot-toast"
import { useEkosietMutation } from "../../../../shared/hooks/useEkosietMutation";
import { NotFound, ValidationFailed } from "../../../../modules/api-client/Responses";
import ApiClient from "../../../../modules/api-client/ApiClient";
import {
    ProductionItemNextOperationRepresentation,
    PutProductionItemOperationRepresentation
} from "../../../../modules/api-client/generated";
import { useQueryClient } from "@tanstack/react-query";

export const usePutProductionItemOperation = () => {
    const queryClient = useQueryClient()
    return useEkosietMutation<Array<ProductionItemNextOperationRepresentation>, NotFound | ValidationFailed, PutProductionItemOperationRepresentation>((command) => {
        return ApiClient.Pm.ProductionItem.pmProductionItemOperationPut(undefined, command).then(response => response.data);
    },
        {
            onSuccess: (data, variables) => {
                queryClient.invalidateQueries(['pmProductionItemIdGet']).then();
                queryClient.invalidateQueries(['productionItemsList']).then();
                queryClient.invalidateQueries(['pmProductionItemGroupCodeGet']).then();
                queryClient.invalidateQueries(['pmProductionItemBarcodeCodeGet']).then();

                if (variables.value) {
                    toast.success(
                        "Productiehandeling afgemeld",
                        {
                            duration: 4500
                        }
                    );
                } else {
                    toast.success(
                        "Productiehandeling reset uitgevoerd",
                        {
                            duration: 4500
                        }
                    );
                }
            },
            onError: (error, variables) => {

                let msg = "Productiehandeling afmelden mislukt";

                if (error.response.status === 404) {
                    msg = `Productiehandeling ${variables.productionItemIds.join(", ")} niet gevonden. Afmelden mislukt`

                }
                toast.error(msg, {
                    duration: 4500
                });
            }
        }
    );

};

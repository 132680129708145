/* eslint-disable jsx-a11y/anchor-is-valid */
import {PageTitle} from "../../_metronic/layout/core";
import {CustomerMyData} from "../customer-portal/components/CustomerMyData";
import {CustomerMyAccount} from "../customer-portal/components/CustomerMyAccount";
import React from "react";
import {useEkosietAuth0} from "../modules/auth0/core/useEkosietAuth0";

export function Account() {
    const {isKlant} = useEkosietAuth0()

    return (
        <>
            <PageTitle>Account</PageTitle>

            <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
                {isKlant &&
                    <div className='col-md-6 col-lg-6 col-xl-6 mb-md-5 mb-xl-10'>
                        <CustomerMyData/>
                    </div>
                }
                <div className='col-xxl-6'>
                    <CustomerMyAccount/>

                </div>
            </div>
        </>
    )
}

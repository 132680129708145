import React from "react";
import ApiClient from "../../../../modules/api-client/ApiClient";
import SpinnerButton from "../../../../shared/components/SpinnerButton";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {toast} from "react-hot-toast";

export const RegenerateGroupNumbersButton: React.FC<{
    batch:string,
}> = ({
          batch,
      }) => {


    const queryClient = useQueryClient()

    const regenerate = useMutation(
        async () => {
            await ApiClient.Pm.ProductionBatch.pmProductionBatchIdRegenerateGroupNumbersPut(batch);
        },
        {
            onSuccess: async () => {
                queryClient.invalidateQueries(['pmProductionBatchGetId', batch]).then();
                toast.success('Hergenereren gelukt.');                            
            },
            onError: () => {
                toast.error('Fout bij regeneren bij bak\\group nummers.');
            },
        },
    );

    return <>
        <SpinnerButton
            onClick={async () => window.confirm('Weet je het zeker?') && await regenerate.mutateAsync(undefined, undefined)}
            className='btn btn-primary me-3'
            spinning={regenerate.isLoading}
            disabled={regenerate.isLoading}
        >
            Baknummers hergenereren
        </SpinnerButton>

    </>;
};
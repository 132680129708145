/* eslint-disable jsx-a11y/anchor-is-valid */
import {CustomerAddressList} from './CustomerAddressList'
import {useEkosietAuth0} from '../../modules/auth0/core/useEkosietAuth0'
import {useGetCustomer} from '../../backoffice/modules/crm/hooks/use-get-customer'
import {EkoCard, EkoCardBody} from "../../../_metronic/helpers";
import {EkoCardHeader} from "../../shared/components/card/EkoCardHeader";

export function CustomerMyData() {
    const {customerId, isKlant} = useEkosietAuth0()
    const response = useGetCustomer(customerId)
    const customer = response.data;

    return (
        <>
            {isKlant &&
                <>
                    <EkoCard>
                        <EkoCardHeader title={'Mijn gegevens'}/>
                        <EkoCardBody>
                            <div className='row mb-7'>
                                <label className='col-lg-4 fw-bold text-muted'>Bedrijf</label>

                                <div className='col-lg-8'>
                                    <span className='fw-bolder fs-6 text-dark'
                                          data-customerid={customer?.id}>{customer?.companyName}</span>
                                </div>
                            </div>

                            <div className='row mb-7'>
                                <label className='col-lg-4 fw-bold text-muted'>Klant nummer</label>

                                <div className='col-lg-8'>
                                    <span className='fw-bolder fs-6 text-dark'>{customer?.code}</span>
                                </div>
                            </div>

                            <div className='row mb-7'>
                                <label className='col-lg-4 fw-bold text-muted'>KvK nummer</label>

                                <div className='col-lg-8 fv-row'>
                                    <span className='fw-bold fs-6'>{customer?.cocNumber}</span>
                                </div>
                            </div>

                            <div className='row mb-7'>
                                <label className='col-lg-4 fw-bold text-muted'>KvK vestigingsnummer</label>

                                <div className='col-lg-8 fv-row'>
                                    <span className='fw-bold fs-6'>{customer?.cocDepartment}</span>
                                </div>
                            </div>

                            <div className='row mb-7'>
                                <label className='col-lg-4 fw-bold text-muted'>BTW nummer</label>

                                <div className='col-lg-8'>
                                    <span className='fw-bolder fs-6 text-dark'>{customer?.vatNumber}</span>
                                </div>
                            </div>

                            <div className='row mb-7'>
                                <label className='col-lg-4 fw-bold text-muted'>E-mail</label>

                                <div className='col-lg-8'>
                                    <span className='fw-bolder fs-6 text-dark'>{customer?.email}</span>
                                </div>
                            </div>

                            <div className='row mb-7'>
                                <label className='col-lg-4 fw-bold text-muted'>Telefoonnummer</label>

                                <div className='col-lg-8'>
                                    <span className='fw-bolder fs-6 text-dark'>{customer?.telephone}</span>
                                </div>
                            </div>

                            <div className='row mb-7'>
                                <label className='col-lg-4 fw-bold text-muted'>Standaard betaalconditie</label>

                                <div className='col-lg-8'>
                                    <span className='fw-bolder fs-6 text-dark'>{customer?.paymentConditions}</span>
                                </div>
                            </div>

                            <div className='row mb-7'>
                                <label className='col-lg-4 fw-bold text-muted'>Standaard Leveringsconditie</label>

                                <div className='col-lg-8'>
                                    <span className='fw-bolder fs-6 text-dark'>{customer?.deliveryConditions}</span>
                                </div>
                            </div>
                        </EkoCardBody>
                    </EkoCard>

                    <CustomerAddressList addresses={customer?.addresses}></CustomerAddressList>

                </>
            }
        </>
    )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import CustomerArchivedList from "../components/CustomerArchivedList";


export function ArchivedCustomersPage() {
    return (
        <>            
            <CustomerArchivedList />
        </>
    )
}

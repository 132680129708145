import { useQuery } from "@tanstack/react-query";
import ApiClient from "../../../../../modules/api-client/ApiClient";
import { ShipmentDeliveryDetailsRepresentation } from "../../../../../modules/api-client/generated";

export const useGetShipmentDeliveryDetails = (orderId: string | undefined) => {

    return useQuery<ShipmentDeliveryDetailsRepresentation, TypeError, ShipmentDeliveryDetailsRepresentation>(
        ['emExpeditionShipmentDeliveryDetailsOrderIdGet', orderId],
        () => {
            return ApiClient.Em.Expedition.emExpeditionShipmentDeliveryDetailsOrderIdGet(orderId!).then((res) => res.data);
        }, {
        useErrorBoundary: true,
        enabled: !!orderId,
    });
}
import React from 'react';
import {useQuery} from '@tanstack/react-query';
import ApiClient from '../../../../modules/api-client/ApiClient';
import {MonthlySalesStatsRow, WeeklySalesStatsRow} from '../../../../modules/api-client/generated';
import Loading from '../../../../shared/components/Loading';
import {EkoTable} from "../../../../shared/components/table/EkoTable";
import {TableHeader} from "../../../../shared/components/table/TableHeader";
import Euro from "../../../../shared/components/Euro";
import {NumericFormat} from "react-number-format";
import {bool} from "yup";
import {Link} from "react-router-dom";
import {SmRoutePath} from "../../sm/SmRoutes";
import {MgtRoutePath} from "../../mgt/MgtRoutes";

export const CrmIntake: React.FC = () => {

    const {isInitialLoading, data} = useQuery<{ [key: string]: { [key: string]: MonthlySalesStatsRow; }; }>(
        ['monthlySalesStatsRow'],
        () => ApiClient.Crm.Reporting.monthlySalesStats().then((res) => res.data));
    if (!data) return <Loading/>

    if (isInitialLoading) return <Loading/>
    return (
        <>
            {Object.keys(data).reverse().map((year) => {
                return (
                    <>
                        <h1>
                            {year}
                        </h1>
                        <EkoTable className={'table-row-dashed table-row-gray-300 gy-7'}>
                            <TableHeader>
                                <th>#</th>
                                <th>Klant</th>
                                <th>Orders</th>
                                <th>Intake</th>
                                <th>Jan</th>
                                <th>Feb</th>
                                <th>Mar</th>
                                <th>Apr</th>
                                <th>Mei</th>
                                <th>Jun</th>
                                <th>Jul</th>
                                <th>Aug</th>
                                <th>Sep</th>
                                <th>Okt</th>
                                <th>Nov</th>
                                <th>Dec</th>
                            </TableHeader>
                            <tbody>
                            {Object.keys(data[year]).map((customer, i) => {
                                return (
                                    <tr key={customer} className=" fs-6 text-gray-800">
                                        <td>{i + 1}</td>
                                        <td className={'text-nowrap'}>
                                            <Link
                                                to={MgtRoutePath.link(MgtRoutePath.customerLink(data[year][customer].customerId))}
                                                className='text-hover-primary'>{data[year][customer].companyName}</Link>
                                        </td>
                                        <td>
                                            {data[year][customer].orders}
                                        </td>
                                        <td className={'text-end'}>
                                            <strong><Euro value={data[year][customer].omzet}/></strong>
                                        </td>
                                        <td className={'text-end'}>
                                            <Euro value={data[year][customer].omzet1}/>
                                        </td>
                                        <td className={'text-end'}>
                                            <Euro value={data[year][customer].omzet2}/>
                                        </td>
                                        <td className={'text-end'}>
                                            <Euro value={data[year][customer].omzet3}/>
                                        </td>
                                        <td className={'text-end'}>
                                            <Euro value={data[year][customer].omzet4}/>
                                        </td>
                                        <td className={'text-end'}>
                                            <Euro value={data[year][customer].omzet5}/>
                                        </td>
                                        <td className={'text-end'}>
                                            <Euro value={data[year][customer].omzet6}/>
                                        </td>
                                        <td className={'text-end'}>
                                            <Euro value={data[year][customer].omzet7}/>
                                        </td>
                                        <td className={'text-end'}>
                                            <Euro value={data[year][customer].omzet8}/>
                                        </td>
                                        <td className={'text-end'}>
                                            <Euro value={data[year][customer].omzet9}/>
                                        </td>
                                        <td className={'text-end'}>
                                            <Euro value={data[year][customer].omzet10}/>
                                        </td>
                                        <td className={'text-end'}>
                                            <Euro value={data[year][customer].omzet11}/>
                                        </td>
                                        <td className={'text-end'}>
                                            <Euro value={data[year][customer].omzet12}/>
                                        </td>

                                    </tr>
                                )
                            })
                            }
                            </tbody>
                        </EkoTable>
                    </>
                )

            })}
        </>
    );
};
import { FC, Suspense, useEffect } from 'react'
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import { useEkosietAuth0 } from '../modules/auth0/core/useEkosietAuth0'
import { CustomerRoutePath } from "../customer-portal/routing/RoutePath";
import { BackofficeRoutePath } from "../backoffice/RoutePath";
import CustomerPortalRoutes from "../customer-portal/routing/CustomerPortalRoutes";
import BackofficeRoutes from "../backoffice/BackofficeRoutes";
import { SharedRoutePath } from "./SharedRoutePath";
import SharedRoutes from "./SharedRoutes";
import { PmRoutePath } from '../backoffice/modules/pm/PmRoutes'
import { EmRoutePath } from "../backoffice/modules/em/EmRoutes";
import { PrinterContextProvider } from '../shared/context/PrinterContext'

const PrivateRoutes = () => {
    const { isMedewerker, isProductieMedewerker, isExpeditieMedewerker, isTransportMedewerker, isKlant } = useEkosietAuth0();
    const startUrl = isKlant ? CustomerRoutePath.dashboard :
        isProductieMedewerker ? PmRoutePath.link(PmRoutePath.stationsDashboard) :
            isExpeditieMedewerker ? EmRoutePath.link(EmRoutePath.planning) :
                isTransportMedewerker ? EmRoutePath.link(EmRoutePath.planningTransporteur) :
                    BackofficeRoutePath.dashboard;
    let navigate = useNavigate()


    useEffect(() => {
        const url = sessionStorage.getItem('redirect_url')
        if (url) {
            sessionStorage.removeItem('redirect_url')
            navigate(url)
        }
    }, [navigate])

    return (
        <Routes>
            <Route element={
                isMedewerker ?
                    (<>
                        <PrinterContextProvider>
                            <MasterLayout />
                        </PrinterContextProvider>
                    </>)
                    : (<MasterLayout />)
            }>

                {/* Redirect to Dashboard after success login/registartion */}
                <Route path='auth/*' element={<Navigate to={startUrl} />} />

                {isMedewerker && (
                    <>
                        <Route
                            path={`${BackofficeRoutePath.base}/*`}
                            element={
                                <SuspensedView>
                                    <BackofficeRoutes />
                                </SuspensedView>
                            }
                        />
                    </>

                )}

                {isKlant && (
                    <Route
                        path={`${CustomerRoutePath.base}/*`}
                        element={
                            <SuspensedView>
                                <CustomerPortalRoutes />
                            </SuspensedView>
                        }
                    />
                )}

                <Route
                    path={`${SharedRoutePath.base}/*`}
                    element={
                        <SuspensedView>
                            <SharedRoutes />
                        </SuspensedView>
                    }
                />

                {/* Page Not Found */}
                <Route path='*' element={<Navigate to='/error/404' />} />
            </Route>
        </Routes>
    )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
    const baseColor = getCSSVariableValue('--kt-primary')
    TopBarProgress.config({
        barColors: {
            '0': baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    })
    return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes, SuspensedView }

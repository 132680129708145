import {FC} from 'react'
import {ErrorMessage, Field, getIn, useField, useFormikContext} from 'formik'
import clsx from 'clsx'
import TextField from "../../../../../../shared/components/TextField";

type Props = {
    addressfieldName: string;
    contactPersonFieldName: string;
    contactPersonTitle?: string
}

export const OrderAddress: FC<Props> = ({addressfieldName, contactPersonFieldName, contactPersonTitle}) => {

    const formik = useFormikContext();
    const [field, metaCountry, helpers] = useField(`${addressfieldName}.country`);
    const {value: countryValue} = metaCountry;

    return (
        <>
            <div className={'row'}>
                <div className={'col'}>
                    <div className='row mb-4'>
                        <div className='col-4 col-lg-3'>
                            <label className='form-label required'>Adres wijzigen/toevoegen</label>
                        </div>
                        <div className='col'>
                            <div className='row mb-4'>
                                <div className={'col col-6'}>
                                    <label className='form-label required'>Straat</label>

                                    <TextField name={`${addressfieldName}.street`}/>
                                </div>
                                <div className={'col col-3'}>
                                    <label className='form-label required'>Huisnummer</label>

                                    <TextField name={`${addressfieldName}.houseNumber`}/>
                                </div>
                                <div className={'col col-3'}>
                                    <label className='form-label'>Toevoeging</label>

                                    <TextField name={`${addressfieldName}.houseNumberAddition`}/>
                                </div>
                            </div>
                            <div className='row mb-4'>
                                <div className={'col col-3'}>
                                    <label className='form-label required'>Postcode</label>

                                    <TextField name={`${addressfieldName}.postalCode`} />
                                </div>
                                <div className={'col col-6'}>
                                    <label className='form-label required'>Plaats</label>

                                    <TextField name={`${addressfieldName}.city`}/>
                                </div>
                                <div className={'col col-3'}>

                                    {/*<label className='d-flex align-items-center form-label required'>*/}
                                    {/*    <span>Land</span>*/}
                                    {/*  </label>*/}
                                    <label className='form-label required'>Land</label>

                                    <select
                                        {...formik.getFieldProps(`${addressfieldName}.country`)}
                                        id={`${addressfieldName}.country`}
                                        className={clsx(
                                            'form-control mb-3 mb-lg-0',
                                            {'is-invalid': getIn(formik.touched, `${addressfieldName}.country`) && getIn(formik.errors, `${addressfieldName}.country`)},
                                            {'is-valid': getIn(formik.touched, `${addressfieldName}.country`) && !getIn(formik.errors, `${addressfieldName}.country`)}
                                        )}
                                        data-kt-select2='true'
                                        value={countryValue ? countryValue : 'NL'}
                                        onChange={(e) => {
                                            formik.setFieldValue(`${addressfieldName}.country`, e.target.value);
                                            formik.validateField(`${addressfieldName}.country`);
                                        }}
                                    >
                                        <option value='NL'>Nederland</option>
                                        <option value='BE'>België</option>
                                        <option value='DE'>Duitsland</option>
                                        <option value='FR'>Frankrijk</option>
                                        <option value='GB'>Verenigd Koninkrijk</option>
                                        <option value='PL'>Polen</option>
                                    </select>

                                    {getIn(formik.touched, `${addressfieldName}.country`) && getIn(formik.errors, `${addressfieldName}.country`) && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span
                                                    role='alert'>{getIn(formik.errors, `${addressfieldName}.country`)}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'row'}>
                <div className={'col'}>
                    <div className='row mb-4'>
                        <div className='col-4 col-lg-3'>
                            <label
                                className='form-label required'>{(contactPersonTitle ? contactPersonTitle : 'Contactpersoon')}</label>
                        </div>
                        <div className='col'>

                            <div className='row mb-4'>
                                <div className='row'>
                                    <div className='col-6'>
                                        <label className='form-label required'>Voornaam</label>
                                        <Field
                                            name={`${contactPersonFieldName}.firstName`}
                                            className='form-control form-control-lg'
                                        />

                                        <div className='text-danger mt-2'>
                                            <ErrorMessage name={`${contactPersonFieldName}.firstName`}/>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <label className='form-label required'>Achternaam</label>
                                        <Field
                                            name={`${contactPersonFieldName}.lastName`}
                                            className='form-control form-control-lg'
                                        />

                                        <div className='text-danger mt-2'>
                                            <ErrorMessage name={`${contactPersonFieldName}.lastName`}/>
                                        </div>
                                    </div>
                                </div>


                                <div className='row'>
                                    <div className='col-6'>
                                        <label className='form-label required'>E-mail</label>
                                        <Field
                                            name={`${contactPersonFieldName}.email`}
                                            className='form-control form-control-lg'
                                        />

                                        <div className='text-danger mt-2'>
                                            <ErrorMessage name={`${contactPersonFieldName}.email`}/>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <label className='form-label'>Telefoon</label>
                                        <Field
                                            name={`${contactPersonFieldName}.telephone`}
                                            className='form-control form-control-lg'
                                        />

                                        <div className='text-danger mt-2'>
                                            <ErrorMessage name={`${contactPersonFieldName}.telephone`}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

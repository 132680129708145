import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty} from '../../../../../../_metronic/helpers'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {useAuth0RolesContext} from '../core/QueryResponseProvider'
import {Role} from '../core/_models'
import {createRole, updateRole} from '../core/_requests'
import {UsersListLoading} from '../../users-list/components/loading/UsersListLoading'
import toast from 'react-hot-toast';

type Props = {
  role: any
}

const editRoleSchema = Yup.object().shape({
  name: Yup.string().required('Naam is verplicht'),
  description: Yup.string(),
})

const RoleEditModalForm: FC<Props> = ({role}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useAuth0RolesContext()  
  const [roleForEdit] = useState<Role>({
    ...role,
    name: role.name || '',
    description: role.description || '',
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const createRoleData = (values: any) => {
    if (isNotEmpty(values.id)) {
      return {
        description: values.description,
        name: values.name,
        id: values.id,
      }
    }
    return {
      description: values.description,
      name: values.name,
    }
  }

  const formik = useFormik({
    initialValues: roleForEdit,
    validationSchema: editRoleSchema,
    onSubmit: (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        let roleData = createRoleData(values)
        if (isNotEmpty(values.id)) {
          updateRole(roleData)
            .then(() => {              
              toast.success('Rol succesvol gewijzigd')
              setTimeout(() => refetch(), 1000)
            })
            .catch((err) => {              
              toast.error('Rol wijzigen mislukt. Fout: ' + err?.response?.data?.detail)
            })
        } else {
          createRole(roleData)
            .then(() => {              
              toast.success('Rol succesvol aangemaakt');
              setTimeout(() => refetch(), 1000)
            })
            .catch((err) => {
              toast.error('Rol aanmaken mislukt. Fout: ' + err?.response?.data?.detail);
            })
        }
      } catch (ex) {        ;              
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_edit_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>
              Naam
            </label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Name'
              {...formik.getFieldProps('name')}
              type='text'
              name='name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.name && formik.errors.name},
                {
                  'is-valid': formik.touched.name && !formik.errors.name,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-2'>
              Omschrijving
            </label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Description'
              {...formik.getFieldProps('description')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.description && formik.errors.description},
                {
                  'is-valid': formik.touched.description && !formik.errors.description,
                }
              )}
              type='text'
              name='description'
              autoComplete='off'
              disabled={formik.isSubmitting}
            />
            {/* end::Input */}
            {formik.touched.description && formik.errors.description && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.description}</span>
              </div>
            )}
          </div>
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting}
          >
            Annuleren
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>
              Toevoegen
            </span>
            {formik.isSubmitting && (
              <span className='indicator-progress'>
                Even wachten a.u.b
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {formik.isSubmitting && <UsersListLoading />}
    </>
  )
}

export {RoleEditModalForm}

import React, {CSSProperties, ReactNode, useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import {useDragging} from "../../../../shared/hooks/useDragging";

export interface SvgDropzoneProps {
    preview: (profile: string) => ReactNode;
    onSelect?: (selected: string) => void;
    children?: ((config: SvgDropzoneConfig) => React.ReactNode) | React.ReactNode;
    show?: 'drag' | 'always'
    disabled?: boolean;
}

const DefaultSvgDropzoneProps = {
    show: 'drag',
    disabled: false,
}

export interface SvgDropzoneConfig {
    openFileDialog: () => void;
    disabled: boolean;
}


const SvgDropzone: React.FC<SvgDropzoneProps> = (props) => {

    const [file, setFile] = useState<File | null>(null);
    const [svg, setSvg] = useState<string | null>(null);

    const {getRootProps, getInputProps, open} = useDropzone({
        // maxFiles: 1
        multiple: false,
        accept: {
            'application/svg': ['.svg']
        },
        disabled: props.disabled,
        onDropAccepted: acceptedFiles => {
            if (acceptedFiles.length > 0) {
                setFile(acceptedFiles[0]);
            }
        },
        onDrop: () => {

        },
        onDropRejected: (fileRejections) => {

            const rejection = fileRejections[0];
            const message = rejection.errors[0].code === 'file-invalid-type'
                ? 'Alleen .svg bestanden zijn toegestaan.'
                : rejection.errors[0].message;

        },
        onError: e => {
            // setScanError(e.message);
        }
    });

    useEffect(() => {
        if(file){

        let fileReader: FileReader, isCancel = false;
        var reader = new FileReader();
        reader.onload = function (event) {
            if(props.onSelect && event.target && event.target.result?.toString()){
                props.onSelect(event.target.result.toString())
                setSvg(event.target.result.toString())
            }
        };
        reader.readAsText(file);
        return () => {
            isCancel = true;
            if (fileReader && fileReader.readyState === 1) {
                fileReader.abort();
            }
        }
        }

    }, [file]);

    const childNodes = typeof (props.children) === 'function' ? props.children.call(this, {
        openFileDialog: open,
        disabled: props.disabled ?? DefaultSvgDropzoneProps.disabled
    }) : props.children;

    const dragging = useDragging();

    const visible = props.show === "always" || (props.show === "drag" && dragging);

    const style: CSSProperties = {
        position: 'absolute',
        inset: visible ? '0' : '-10000px -10000px 20000px 20000px',

        visibility: visible ? 'visible' : 'collapse',
        display: visible ? 'flex' : 'none',
        opacity: visible ? '.8' : '0',
    };
    return (
        <>
            {!props.disabled &&
                <div {...getRootProps({
                    className: 'dropzone h-100 flex-column justify-content-center',
                    style: style
                })}>
                    <div className="m-2">
                        <input {...getInputProps()} />
                        <div className="h3">
                            Sleep een .svg bestand hier
                        </div>
                    </div>
                </div>
            }
            {childNodes}
        </>
    );
};

export default SvgDropzone;
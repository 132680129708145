import { FC } from 'react'
import * as Yup from 'yup'
import { Field, Formik, FormikHelpers } from 'formik'

import {
    OrderRemarkType,
    PostOrderRemarkRepresentation
} from '../../../../../../../modules/api-client/generated'

import { usePostRemark } from "../../../hooks/use-post-remark";
import FieldErrors from "../../../../../../../shared/components/FieldErrors";
import { useGetOrder } from '../../../hooks/use-get-order';
import {useEkosietAuth0} from "../../../../../../../modules/auth0/core/useEkosietAuth0";

type Props = {
    orderId?: string
    handleClose: () => void,
}

export type OrderRemarkForm = PostOrderRemarkRepresentation
    & {
        internal: boolean;
    };

const AddRemarkForm: FC<Props> = ({ orderId, handleClose }) => {

    const mutatePostRemark = usePostRemark(orderId || "");
    const {isKlant} = useEkosietAuth0();
    const {refetch: refetchOrder} = useGetOrder(orderId, false, false);

    if (!orderId) return (<></>)

    const initialValues: OrderRemarkForm = {
        remark: '',
        type: OrderRemarkType.External,
        internal: false
    }

    const schema = {
        remark: Yup.string()
            .min(3, 'Minimaal 3 karakters.')
            .required('Opmerking is verplicht.')
    }

    const formSchema = () => Yup.lazy((values) => {
        return Yup.object().shape(schema)
    });

    const onSubmit = (values: OrderRemarkForm, { setSubmitting }: FormikHelpers<OrderRemarkForm>) => {

        let payload: PostOrderRemarkRepresentation = {
            ...values,
            type: values.internal ? OrderRemarkType.Internal : OrderRemarkType.External
        };

        return new Promise<void>((resolve, reject) => {
            mutatePostRemark.mutate(payload, {
                onSuccess: () => {
                    refetchOrder();
                    resolve();
                    handleClose();
                },
                onError: () => {
                    reject();
                }
            });

        }).finally(() => setSubmitting(false));
    };


    return (
        <>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={formSchema}>
                {({                    
                    handleSubmit,
                    getFieldProps,
                    isSubmitting,
                }) => (
                    <form id='kt_modal_customer_form' className='form' onSubmit={handleSubmit}>
                        {/* begin form element */}
                        <div className='fv-row mb-7'>
                            <div>
                                <div className={'d-flex justify-content-between'}>
                                    <div>
                                        <label className="form-label" htmlFor="remark">Opmerking</label>
                                        <FieldErrors field='remark' />
                                    </div>
                                    {!isKlant &&
                                    <div className="form-check form-switch form-check-custom form-check-solid">
                                        <Field type="checkbox" id={'internal'} name={'internal'} className="form-check-input  h-20px w-30px"
                                        />
                                        <label className="form-check-label" htmlFor="internal">
                                            Interne Opmerking?
                                        </label>
                                    </div>
                                    }
                                </div>
                                <Field as={'textarea'} id="remark" className="form-control" required
                                    {...getFieldProps('remark')} />
                            </div>

                        </div>

                        {/* begin::Actions */}
                        <div className='text-center pt-5'>
                            <button
                                type='reset'
                                className='btn btn-light me-3'
                                data-kt-customer-modal-action='cancel'
                                data-dismiss="modal"
                                onClick={() => handleClose()}
                                disabled={isSubmitting}
                            >
                                Annuleren
                            </button>

                            <button
                                type='submit'
                                className='btn btn-primary'
                                data-kt-customer-modal-action='submit'
                                disabled={isSubmitting}
                            >
                                <span className='indicator-label'>
                                    Opslaan
                                </span>

                                {isSubmitting && (
                                    <span className='indicator-progress'>
                                        Even wachten a.u.b
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                        {/* end::Actions */}

                    </form>
                )}
            </Formik>
        </>
    )
}

export { AddRemarkForm }

import {MutationFunction, useMutation, UseMutationOptions, UseMutationResult} from "@tanstack/react-query";
import {useState} from "react";
import {ValidationErrors, ValidationFailed} from "../../modules/api-client/Responses";


export function useEkosietMutation<TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(mutationFn: MutationFunction<TData, TVariables>, options?: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn'>)
    : UseMutationResult<TData, TError, TVariables, TContext> & { serverValidationErrors: ValidationErrors | null } {

    const [serverValidationErrors, setServerValidationErrors] = useState<ValidationErrors | null>(null);

    const newOnError = (error: TError, variables: TVariables, context: TContext | undefined) => {
        if (error instanceof ValidationFailed) {
            setServerValidationErrors(error.errors);
        }
        if (typeof (options?.onError) !== 'undefined') {
            options.onError(error, variables, context);
        }
    }

    const newOptions = {...options, mutationFn: mutationFn, onError: newOnError};

    const mutation = useMutation(newOptions);

    return {...mutation, serverValidationErrors: serverValidationErrors};
}
import React, {useLayoutEffect, useState} from 'react';
import {Formik, FormikHelpers, useFormikContext} from 'formik';
import * as Yup from 'yup';
import Loading from '../../../components/Loading';
import FieldErrors from '../../../components/FieldErrors';
import TextField from '../../../components/TextField';
import {CreateImportGlazingBarMappingCommand} from "../../../../modules/api-client/generated";
import {useGetImportGlazingBarMapping, usePostGlazingBarMapping} from "../../hooks/use-glazing-bar-mapping";
import {GlazingBarPicker, GlazingBarPickerField} from "../../../../backoffice/modules/pim/glazingbar/GlazingBarPicker";


const schema = {
	externalCode: Yup.string().max(50, 'Maximaal 50 karakters.').required('Code is verplicht.'),
	toId: Yup.string().required('EDS object is verplicht.'),
};

const formSchema = () =>
	Yup.lazy(() => {
		return Yup.object().shape(schema);
	});

interface GlazingBarMappingUpsertProps {
	id: string | undefined;
	handleSucces?: () => void;
}

const GlazingBarMappingUpsert: React.FC<GlazingBarMappingUpsertProps> = ({id,  handleSucces}) => {
	const mutation = usePostGlazingBarMapping();
	const {data: importMapping, isInitialLoading, isError} = useGetImportGlazingBarMapping(id);

	if (isInitialLoading) return <Loading />;
	if (isError) return <>Error...</>;

	const initialValues: CreateImportGlazingBarMappingCommand = {
		id: importMapping?.id ?? undefined,
		externalCode: importMapping?.externalCode ?? '',
		toId: importMapping?.glazingBar?.id ?? '',
	};

	const onSubmit = async (values: CreateImportGlazingBarMappingCommand, {setSubmitting, resetForm, setFieldValue}: FormikHelpers<CreateImportGlazingBarMappingCommand>) => {
		await mutation.mutateAsync(values, {
			onSuccess: (data, variables) => {
				resetForm({
					values: {
						id: undefined,
						externalCode: '',
						toId: '',
					},
				});			
				if (handleSucces) {
					handleSucces();
				}
			},
			onSettled: () => {
				setSubmitting(false);
			},
		});
	};

	return (
		<Formik initialValues={initialValues} enableReinitialize={true} onSubmit={onSubmit} validationSchema={formSchema}>
			<ImportGlazingBarMapppingUpsertForm id={id} mutation={mutation} />
		</Formik>
	);
};

interface ImportGlazingBarMappingUpsertFormProps {
	id: string | undefined;
	mutation: ReturnType<typeof usePostGlazingBarMapping>;
}

export const ImportGlazingBarMapppingUpsertForm: React.FC<ImportGlazingBarMappingUpsertFormProps> = (props) => {
	const isAdd = !props.id;

	const { handleSubmit,  isSubmitting, errors, setErrors} = useFormikContext<CreateImportGlazingBarMappingCommand>();

	const mergeServerErrors = () => {
		if (props.mutation.serverValidationErrors) {
			setErrors({...errors, ...props.mutation.serverValidationErrors});
		}
	};
	useLayoutEffect(mergeServerErrors, [props.mutation.serverValidationErrors, errors, setErrors]);

	return (
		<form onSubmit={handleSubmit}>
			<div className="row mt-5">
				<div className={'col d-flex flex-column w-100'}>
					<div>
						<div className={'my-4'}>
							<label className="form-label" htmlFor={'externalCode'}>
								Code
							</label>
							<TextField row={false} name={'externalCode'} />
						</div>
						<div className={'my-4'}>
							<label className="form-label" htmlFor={'toId'}>
								EDS Glaslat
							</label>
							<GlazingBarPickerField name={'toId'} />
							<FieldErrors field="toId" />
						</div>
					</div>
					<div className=" align-self-end mt-auto">
						<button type="submit" className="btn btn-primary" data-kt-customer-modal-action="submit" disabled={isSubmitting}>
							{!isSubmitting && <span className="indicator-label">{isAdd ? 'Toevoegen' : 'Wijzigen'}</span>}
							{isSubmitting && (
								<span className="indicator-label">
									Even wachten a.u.b
									<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
								</span>
							)}
						</button>
					</div>
				</div>
			</div>
		</form>
	);
};

export default GlazingBarMappingUpsert;

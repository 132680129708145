import React, {useId} from "react";
import {useField} from "formik";
import {SillConfigurationHefSchuifSlidingRail} from "../../../modules/api-client/generated";
import FieldErrors from "../FieldErrors";

export interface HefSchuifRailTypeRowProps {
    fieldName: string;
    label: string;
    promptLength?: boolean;
    promptCenter?: boolean;
    defaultLength?: number;
    defaultCenter?: number;
}

export const HefSchuifSlidingRailRow: React.FC<HefSchuifRailTypeRowProps> = (props) => {

    const idCenter = useId();
    const idLength = useId();

    const [field, , fieldHelper] = useField<SillConfigurationHefSchuifSlidingRail | undefined>(props.fieldName);

    const promptLength = props.promptLength ?? false;
    const promptCenter = props.promptCenter ?? false;

    if (!promptLength && !promptCenter) return null;

    return <div className="row">

        {promptLength &&
            <div className="col-4">
                <label className='form-label'>
                    <small className='text-uppercase text-muted'>Lengte {props.label} </small>
                </label>
                <div className="input-group">

                    <input type="number"
                           id={idLength}
                           pattern={"[0-9]+([,\\.][0-9]+)?"}
                           name={`${props.fieldName}.length`}
                           value={(promptLength ? field.value?.length : undefined) ?? ''}
                           onChange={field.onChange}
                           className="form-control"
                           min="0" step="0.1"
                           placeholder={promptLength ? "Length" : 'n.v.t.'}
                           required={promptLength}
                           disabled={!promptLength}
                    />
                    <span className="input-group-text">mm</span>
                </div>
                <small className="text-muted"><i>Vanuit de dagkant</i></small>
                <FieldErrors field={`${props.fieldName}.length`}/>
            </div>

        }

        {promptCenter &&
            <div className="col-4">
                <label className='form-label'>
                    <small className='text-uppercase text-muted'>Hart {props.label}</small>
                </label>
                <div className="input-group">

                    <input type="number"
                           id={idCenter}
                           pattern={"[0-9]+([,\\.][0-9]+)?"}
                           name={`${props.fieldName}.center`}
                           value={(promptCenter ? field.value?.center : undefined) ?? ''}
                           onChange={field.onChange}
                           className="form-control"
                           min="0" step="0.1"
                           placeholder="Hart"
                           required={promptCenter}
                           disabled={!promptCenter}
                    />
                    <span className="input-group-text">mm</span>
                </div>
                <small className="text-muted"><i>Vanuit de binnenkant</i></small>
                <FieldErrors field={`${props.fieldName}.center`}/>
            </div>
        }
    </div>
}


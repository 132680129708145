import React from "react";
import {DownloadCallbackResult, useDownloadFile} from "../hooks/useDownloadFile";
import SpinnerButton from "./SpinnerButton";

export type DownloadButtonProps = {
    title: string,
    filename?: string,
    downloadCallback: () => Promise<DownloadCallbackResult>
}

export const DownloadButton: React.FC<DownloadButtonProps> = (props: DownloadButtonProps) => {

    const {ref, result, download, isDownloading} = useDownloadFile({
        downloadCallback: props.downloadCallback,
        filename: props.filename
    });

    return <>
        <a href={result?.url} download={result?.filename} ref={ref} className='d-none'/>
        <SpinnerButton type='button' className='btn btn-light-primary mx-3 button-sm' onClick={download}
                       spinning={isDownloading}>{props.title}</SpinnerButton>
    </>;
};
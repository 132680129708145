/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useMutation, useQueryClient} from "@tanstack/react-query"
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {ID, KTSVG, QUERIES} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {User} from '../../core/_models'
import {deleteUser, sendVerificationMail, updateUser} from '../../core/_requests'
import {toast} from 'react-hot-toast'

type Props = {
  id: ID
}

const UserActionsCell: FC<Props> = ({id}) => {
  const {setItemIdForUpdate, setitemForWarning} = useListView()
  const {query, response, refetch} = useQueryResponse()
  const queryClient = useQueryClient()
  
  const userItem =
    response && response.data ? response?.data?.find((item: User) => item.userId === id) : {}

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  const openWarningModal = (data: any) => {
    setitemForWarning({
      Id: id,
      message: data.message,
      onClick: data.onClick,
      submitButtonName: data.submitButtonName,
    })
  }

  const deleteItem = useMutation(
    () =>
      deleteUser(id)
        .then()
        .catch((err) => Promise.reject(err)),
    {
      // 💡 response of the mutation is passed to onSuccess
      onSuccess: () => {                
        toast.success('Verwijderen van gebruiker(s) gelukt');
        queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      },
      onError: (error: any) => {
        toast.error('Verwijderen van gebruiker(s) mislukt. Fout: ' + error?.response?.data?.detail);        
        console.log(error);
      },
    }
  )
  const blockUser = useMutation(
    () =>
      updateUser({
        data: {userId: id, blocked: !userItem?.blocked},
      })
        .then()
        .catch((err) => Promise.reject(err)),
    {
      // 💡 response of the mutation is passed to onSuccess
      onSuccess: () => {        
        toast.success(userItem?.blocked === true
          ? 'Gebruiker gedeblokkeerd'
          : 'Gebruiker geblokkeerd');
        setTimeout(() => refetch(), 1000)
      },
      onError: (error: any, variables, context) => {        
        toast.error('Gebruiker blokkeren / deblokkeren mislukt. Fout: ' + error?.response?.data?.detail);
      },
    }
  )

  const FireMail = useMutation(sendVerificationMail, {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {      
      toast.success("Verificatie mail successvol verstuurd naar de gebruiker");
    },
    onError: (error: any, variables, context) => {
      toast.error('Verificatie mail niet verstuurd aan de gebruiker. Fout: ' + error?.response?.data?.detail);      
    },
  })

  return (
    <>
      <a
        href='frontend/src/app/backoffice/modules/um/users-list/table/columns#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Acties
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            Wijzig
          </a>
        </div>
        {/* end::Menu item */}

        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            onClick={() => {
              let fun = async () => {
                setitemForWarning({Id: undefined, message: ''})
                await blockUser.mutateAsync()
              }
              let message =
                userItem?.blocked === true
                  ? 'Deblokkeer gebruiker?'
                  : 'Blokkeer gebruiker?'
              
              openWarningModal({
                message: message,
                onClick: fun,
                submitButtonName: 'Ja',
              })
            }}
          >
            {userItem?.blocked === true
              ? 'Deblokkeer'
              : 'Blokkeer'
              }
          </a>
        </div>
        {userItem?.emailVerified === false ? (
          <div className='menu-item px-3'>
            <a className='menu-link px-3' onClick={async () => await FireMail.mutateAsync(id)}>
              Verstuur verificatie mail
            </a>
          </div>
        ) : (
          <></>
        )}
      {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter='delete_row'
            onClick={() => {
              let fun = async () => {
                setitemForWarning({Id: undefined, message: ''})
                await deleteItem.mutateAsync()
              }
              let message = 'Weet u zeker dat u deze gebruiker wilt verwijderen?'
              let submitButtonName = 'Ja'
              openWarningModal({
                message: message,
                onClick: fun,
                submitButtonName: submitButtonName,
              })
            }}
          >
            Verwijderen
          </a>
        </div>
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export {UserActionsCell}

import React, {useEffect} from 'react';
import {ActionLogRepresentation} from "../../../modules/api-client/generated";
import {formatDate} from "../date";
import './Timeline.scss';
import {OrderStates} from "../../../backoffice/modules/sm/orders/edit-order/components/OrderStatus";

type TimelineProps = {
    logs: ActionLogRepresentation[];
};

const Timeline: React.FC<TimelineProps> = ({logs}) => {
    const statusToColor = (status: string) => {
        const statusObject = OrderStates.find((state) => state.value === status);
        return statusObject ? statusObject.color : 'light';
    };
    useEffect(() => {
        const style = document.createElement('style');
        style.id = 'timeline-item-after';
        document.head.appendChild(style);
        return () => {
            document.head.removeChild(style);
        };
    }, []);

    useEffect(() => {
        const style = document.getElementById('timeline-item-after') as HTMLStyleElement;
        style.innerHTML = logs.map((log, index) => `
            .timeline .timeline-item:nth-child(${index + 1})::after {
      border: 3px solid var(--bs-${statusToColor(log.endStatus)});
            }
        `).join('');
    }, [logs]);

    return (
        <div className="timeline">
            {logs.map((log, index) => (
                <div key={index}
                     className={`timeline-item d-flex flex-column align-items-stretch border-${statusToColor(log.endStatus)}`}>
                    <div className="timeline-header d-flex justify-content-between align-items-center">
                        <span className="timeline-date">{formatDate(log.date, 'dd-MM-yyyy HH:mm:ss')}</span>
                        <span className="timeline-username">{log.user}</span>
                    </div>
                    <div className="timeline-content bg-light p-4 text-bg-light">
                        <span>{log.message}</span>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Timeline;
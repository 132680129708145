/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {EkoTable} from "../../../../../../shared/components/table/EkoTable";
import {TableHeader} from "../../../../../../shared/components/table/TableHeader";
import {
    OrderLineType,
    OrderRepresentation,
    PostProductOrderLineRepresentation,
    StandardProduct
} from "../../../../../../modules/api-client/generated";
import {usePostOrderline} from "../../hooks/orderline/use-post-orderline";
import {useGetOrder} from '../../hooks/use-get-order';
import {
    useListStandardProductsByCatalogIdAndCategoryQuery,
    useListStandardProductsByCatalogIdQuery
} from '../../../../pim/catalogitem/hooks';
import Euro from '../../../../../../shared/components/Euro';
import {ProductList} from "./ProductList";
import {CategoryProductList} from "./CategoryProductList";

type Props = {
    order: OrderRepresentation
}

const OrderProductTablePicker: React.FC<Props> = ({order}) => {

    const mutation = usePostOrderline();
    const {refetch: refetchOrder} = useGetOrder(order.id);
    const addProductToOrder = (catalogItemId: string) => {
        return new Promise<void>((resolve, reject) => {
            const data: PostProductOrderLineRepresentation = {
                catalogItemId: catalogItemId, orderlineType: OrderLineType.ProductLine, quantity: 1
            }
            mutation.mutate({orderId: order.id, line: data}, {
                onSuccess: (model) => {
                    refetchOrder();
                    resolve();
                },
                onError: () => {
                    reject();
                }
            });

        }).finally(() => {
                refetchOrder();
            }
        );
    };

    return (
        <>
            <CategoryProductList catalogId={order.catalog.id} priceListId={order.financial.priceList.id}
                                 categoryCode={'afwerking-en-dichting'} onProductAdded={addProductToOrder}/>

            <CategoryProductList catalogId={order.catalog.id} priceListId={order.financial.priceList.id}
                                 categoryCode={'bevestigingsmiddelen'} onProductAdded={addProductToOrder}/>
            
            <CategoryProductList catalogId={order.catalog.id} priceListId={order.financial.priceList.id}
                                 categoryCode={'beschermkappen'} onProductAdded={addProductToOrder}/>
            
            <CategoryProductList catalogId={order.catalog.id} priceListId={order.financial.priceList.id}
                                 categoryCode={'pe-blokken'} onProductAdded={addProductToOrder}/>
            
            <CategoryProductList catalogId={order.catalog.id} priceListId={order.financial.priceList.id}
                                 categoryCode={'reparatie-en-service'} onProductAdded={addProductToOrder}/>
            
            <CategoryProductList catalogId={order.catalog.id} priceListId={order.financial.priceList.id}
                                 categoryCode={'presentatie-en-demomodellen'} onProductAdded={addProductToOrder}/>

            <CategoryProductList catalogId={order.catalog.id} priceListId={order.financial.priceList.id}
                                 categoryCode={'transport-artikelen'} onProductAdded={addProductToOrder}/>



        </>
    )
}

export {OrderProductTablePicker}

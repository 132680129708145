
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {useParams} from "react-router-dom";
import {EkoCard, EkoCardBody, EkoCardHeader} from "../../../../../shared/components/card";

export function CustomerInvoicing() {
    const {id} = useParams<{ id: string }>();
    return (
            <EkoCard>
                <EkoCardHeader title={'Facturatie'}>
                </EkoCardHeader>
                <EkoCardBody>
TODO
                </EkoCardBody>
            </EkoCard>
    )
}


import {useQueryClient} from "@tanstack/react-query";
import { toast } from "react-hot-toast"
import ApiClient from "../../../../../../modules/api-client/ApiClient";
import {
    OrderLineRepresentation,    
    PostConfiguratorOrderLineRepresentation, 
    PostProductOrderLineRepresentation, 
    PostShippingCostOrderLineRepresentation, 
    PostTextOrderLineRepresentation,
} from "../../../../../../modules/api-client/generated";
import { useEkosietMutation } from "../../../../../../shared/hooks/useEkosietMutation";
import { NotFound, ValidationFailed } from "../../../../../../modules/api-client/Responses";


export const usePostOrderline = () => {

    const queryClient = useQueryClient()

    return useEkosietMutation<OrderLineRepresentation, NotFound | ValidationFailed, {orderId: string, line: PostTextOrderLineRepresentation |
        PostProductOrderLineRepresentation |
        PostShippingCostOrderLineRepresentation |
        PostConfiguratorOrderLineRepresentation}>
        (({orderId, line}) => {

            return ApiClient.Sm.Orderline.smOrderlineIdPost(orderId, undefined, line).then(response => response.data);
        },
            {
                onSuccess: (order) => {
                    queryClient.invalidateQueries(['smOrderIdGet', order.orderId]).then();
                    queryClient.invalidateQueries(['OrderActionLog', order.orderId]).then();

                    toast.success("Orderregel toegevoegd.");

                },
                onError: () => {
                    toast.error("Orderregel toevoegen mislukt!");
                }
            }
        );
}
    ;

import {
    UpsertCompartmentLayoutRequest,
    SillDetails, SillCompartmentLayoutTypeOption, SillDetailsCompartmentLayout, CompartmentLayoutTypeCode
} from "../../../../../modules/api-client/generated";
import {useEkosietMutation} from "../../../../../shared/hooks/useEkosietMutation";
import {NotFound, ValidationFailed} from "../../../../../modules/api-client/Responses";
import ApiClient from "../../../../../modules/api-client/ApiClient";
import {useQueryClient} from "@tanstack/react-query";
import {useConfirmDialog} from "../../../../../shared/components/ConfirmDialog";
import {useSillCompartmentLayoutUpsertModal} from "./use-sill-compartment-layout-upsert-modal";

export const useSillCompartmentLayoutsManage = (sill: SillDetails) => {

    const queryClient = useQueryClient();

    const upsertMutation = useEkosietMutation<void, NotFound | ValidationFailed, {
        compartmentLayoutTypeId: string,
        compartmentLayout: UpsertCompartmentLayoutRequest
    }>(async ({compartmentLayoutTypeId, compartmentLayout}) => {
            await ApiClient.Pim.Sill.upsertCompartmentLayout(sill.id, compartmentLayoutTypeId, undefined, compartmentLayout);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['ApiClient.Pim.Sill.getSill', sill.id]).then();
            },
        },
    );


    const deleteMutation = useEkosietMutation<void, NotFound, {
        compartmentLayoutId: string,
    }>(async ({compartmentLayoutId}) => {
            await ApiClient.Pim.Sill.deleteCompartmentLayout(sill.id, compartmentLayoutId, undefined);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['ApiClient.Pim.Sill.getSill', sill.id]).then();
            },
        },
    );

    const confirmDialog = useConfirmDialog();

    const upsert = async (compartmentLayoutTypeId: string, compartmentLayout: UpsertCompartmentLayoutRequest): Promise<void> => {
        await upsertMutation.mutateAsync({
            compartmentLayoutTypeId,
            compartmentLayout
        });
    };

    const remove = async (compartmentLayoutId: string): Promise<void> => {
        await deleteMutation.mutateAsync({
            compartmentLayoutId: compartmentLayoutId
        });
    };

    const confirmRemove = async (compartmentLayoutId: string) => {
        await confirmDialog({
            onConfirm: async () => await remove(compartmentLayoutId),
            title: 'Vakindeling verwijderen',
            message: 'Weet je zeker dat je deze vakindeling wilt verwijderen?',
            dialogStyle: 'danger'
        });
    };

    const {open: openUpsertModal, element: upsertModalElement} = useSillCompartmentLayoutUpsertModal({
        save: upsert
    })


    const openAddModal = (compartmentLayoutTypeOption: SillCompartmentLayoutTypeOption) => {
        openUpsertModal(compartmentLayoutTypeOption, null);
    };

    const openEditModal = (compartmentLayout: SillDetailsCompartmentLayout) => {
        const compartmentLayoutTypeOption = sill.compartmentLayoutTypeOptions.find(x => x.id === compartmentLayout.id)!;
        openUpsertModal(compartmentLayoutTypeOption, compartmentLayout);
    };

    const add = async (compartmentLayout: SillCompartmentLayoutTypeOption) => {

        if (compartmentLayout.code === CompartmentLayoutTypeCode.HefSchuifA
            || compartmentLayout.code === CompartmentLayoutTypeCode.HefSchuifD
            || compartmentLayout.code === CompartmentLayoutTypeCode.HefSchuifE) {
            openAddModal(compartmentLayout);
        } else {
            await upsert(compartmentLayout.id, {$type: 'standard'});
        }
    }

    return {
        add,
        openEditModal,
        confirmRemove,
        upsertModalElement,
        isSaving: upsertMutation.isLoading,
        options: sill.compartmentLayoutTypeOptions.filter(x => !x.isInUse)
    }
};
import {useQuery, useQueryClient} from '@tanstack/react-query';
import * as api from '../../../../modules/api-client/generated';
import ApiClient from '../../../../modules/api-client/ApiClient';
import {NotFound, ValidationFailed} from '../../../../modules/api-client/Responses';
import {useEkosietMutation} from '../../../../shared/hooks/useEkosietMutation';
import {
	CreateGlazingProfileCommand, UpdateGlazingProfileRequest,
} from '../../../../modules/api-client/generated';
import {FormValues} from './GlazingProfileUpsert';


export const useGlazingProfileUpsertMutation = (id?: string | undefined) => {

	const queryClient = useQueryClient();

	return useEkosietMutation<api.GlazingProfile, NotFound | ValidationFailed, FormValues>((values) => {
			const command: CreateGlazingProfileCommand | UpdateGlazingProfileRequest = {
				code: values.code,
				active: values.active,
				name: values.name,
				height: values.height,
				defaultWidth: values.defaultWidth,
				minWidth: values.minWidth,
				mountingSlotPosition: values.mountingSlotPosition,
				stopPosition: values.stopPosition,
				rabbetWidth: values.rabbetWidth,
			};

			if (id) {
				return ApiClient.Pim.GlazingProfile.pimGlazingProfilesIdPut(id, undefined, command).then(response => response.data);
			}
			return ApiClient.Pim.GlazingProfile.pimGlazingProfilesPost(undefined, command).then(response => response.data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['pimGlazingProfilesIdGet', id]).then();
				queryClient.invalidateQueries(['pimGlazingProfilesGet']).then();
			},
		},
	);
};

export const useGlazingProfileQuery = (id: string | undefined) => {
	return useQuery(['pimGlazingProfilesIdGet', id],
		() => {
			return ApiClient.Pim.GlazingProfile.pimGlazingProfilesIdGet(id!).then(res => res.data);
		}, {
			useErrorBoundary: true,
			enabled: !!id,
		});
};

export const useGlazingProfileDeleteMutation = (id: string) => {
	const queryClient = useQueryClient();
	return useEkosietMutation<void, NotFound | ValidationFailed, string>((id) => {
			return ApiClient.Pim.GlazingProfile.pimGlazingProfilesIdDelete(id, undefined).then(response => response.data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['pimGlazingProfilesIdGet', id]).then();
				queryClient.invalidateQueries(['pimGlazingProfilesGet']).then();
			},
		},
	);
};




import React, {FC} from 'react'
import * as Yup from 'yup'
import {Field, Form, Formik, FormikHelpers} from 'formik'
import {useCopyOrderLine} from "../hooks/orderline/use-copy-orderline";
import {
    CopySillOrderLineModel,
    OrderLineRepresentation,
} from "../../../../../modules/api-client/generated";
import TextField from "../../../../../shared/components/TextField";

type Props = {
    line: OrderLineRepresentation,
    handleClose: () => void,
}

const CopySillForm: FC<Props> = ({
                                     line,
                                     handleClose
                                 }) => {

    const mutation = useCopyOrderLine(line.id);

    const initialValues: CopySillOrderLineModel = {
        quantity:line.quantity,
        merk:line.merk,
        remark:line.remark,
        mirror:false
    }

    const schema = {
        remark: Yup.string().notRequired().nullable()
    }
    const formSchema = () => Yup.lazy(() => {
        return Yup.object().shape(schema)
    });

    //
    const onSubmit = (values: CopySillOrderLineModel, {setSubmitting}: FormikHelpers<CopySillOrderLineModel>) => {

        return new Promise<void>((resolve, reject) => {
            mutation.mutate(values, {
                onSuccess: () => {
                    resolve();
                    handleClose()
                },
                onError: () => {
                    reject();
                }
            });

        }).finally(() => setSubmitting(false));
    };

    return (
        <>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={formSchema}>
                {({
                      values,
                    setFieldValue,
                      getFieldProps,
                      isSubmitting,
                  }) => (
                    <Form>
                        <div className='row mb-4'>
                            <div className='col-4'>
                                <div className='form-label' >
                                    Dorpel spiegelen
                                </div>
                            </div>
                            <div className={'col'}>
                                <div role="group" aria-labelledby="checkbox-group">
                                    <label className={'me-3'}>
                                        <Field type="radio" name="mirror" value="true"
                                               className={'me-1'}/>
                                        Ja
                                    </label>
                                    <label className={'me-3'}>
                                        <Field type="radio" name="mirror" value="false"
                                               className={'me-1'}/>
                                        Nee
                                    </label>

                                </div>
                            </div>
                        </div>
                        <TextField
                            row={true}
                            label={'Aantal'}
                            {...getFieldProps('quantity')}
                        />
                        <TextField
                            row={true}
                            label={'Opmerking'}
                            {...getFieldProps('remark')}
                        />
                        <TextField
                            row={true}
                            label={'Merk'}
                            {...getFieldProps('merk')}
                        />
                        <div className='text-center pt-5'>
                            <button
                                type='reset'
                                className='btn btn-light me-3'
                                data-kt-customer-modal-action='cancel'
                                data-dismiss="modal"
                                onClick={() => handleClose()}
                                disabled={isSubmitting}
                            >
                                Annuleren
                            </button>
                            <button
                                type='submit'
                                className='btn btn-primary'
                                data-kt-customer-modal-action='submit'
                                disabled={isSubmitting}
                            >
                                <span className='indicator-label'>Aanmaken
                                </span>
                                {isSubmitting && (
                                    <span className='indicator-progress'>
                                        Even wachten a.u.b
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                        {/* end::Actions */}
                    </Form>
                )}
            </Formik>
        </>
    )
}

export {CopySillForm}

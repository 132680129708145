/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import { useEkosietAuth0 } from '../../../../app/modules/auth0/core/useEkosietAuth0'
import {SharedRoutePath} from "../../../../app/routing/SharedRoutePath";

const {PUBLIC_URL} = process.env

const HeaderUserMenu: FC = () => {

  const {user, logout} = useEkosietAuth0();

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img
              alt='Avatar'
              src={user?.picture ? user.picture : ""}
            />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {user?.given_name} {user?.family_name}              
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {user?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={SharedRoutePath.account} className='menu-link px-5'>
          Mijn Gegevens
        </Link>
      </div>
      <div className='separator my-2'></div>



      <div className='menu-item px-5'>
        <a
          onClick={() =>
            logout({
              returnTo: window.location.origin + PUBLIC_URL,
            })
          }
          className='menu-link px-5'
        >
          Uitloggen
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}

import {useQuery, useQueryClient} from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import {NotFound, ValidationFailed} from "../../../../modules/api-client/Responses";
import {useEkosietMutation} from "../../../../shared/hooks/useEkosietMutation";
import {
    TurboholType,
     CreateTurboholTypeCommand,
     UpdateTurboholTypeRequest
} from "../../../../modules/api-client/generated";
import {FormValues} from "./TurboholTypeUpsert";


export const useTurboholTypeUpsertMutation = (id?: string | undefined) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<TurboholType, NotFound | ValidationFailed, FormValues>((values) => {
            const command: CreateTurboholTypeCommand | UpdateTurboholTypeRequest = {
                code: values.code,
                name: values.name,
            };

            if (id) {
                return ApiClient.Pim.TurboholType.pimTurboholTypesIdPut(id, undefined, command as UpdateTurboholTypeRequest).then(response => response.data);
            }
            return ApiClient.Pim.TurboholType.pimTurboholTypesPost(undefined, command).then(response => response.data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['pimTurboholTypeIdGet', id]).then();
                queryClient.invalidateQueries(['pimTurboholTypesGet']).then();
            }
        }
    );
};

export const useTurboholTypeQuery = (id: string | undefined) => {
    return useQuery(['pimTurboholTypeIdGet', id],
        () => {
            return ApiClient.Pim.TurboholType.pimTurboholTypesIdGet(id!).then(res => res.data);
        }, {
            useErrorBoundary: true,
            enabled: !!id,
        });
}


export const useTurboholTypeDeleteMutation = (id: string) => {
    const queryClient = useQueryClient()
    return useEkosietMutation<void, NotFound | ValidationFailed, string>((id) => {
            return ApiClient.Pim.TurboholType.pimTurboholTypesIdDelete(id, undefined).then(response => response.data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['pimTurboholTypeIdGet', id]).then();
                queryClient.invalidateQueries(['pimTurboholTypesGet']).then();
            }
        }
    );
};




/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import {OrderAddressRepresentation} from "../../../../../../modules/api-client/generated";

type Props = {
    address?: OrderAddressRepresentation
    withCountry?: boolean
}
const Address: React.FC<Props> = ({address,withCountry = false}) => {
    if (!address) return (<></>)
  return (
<>
    <div>
        {address.attention &&
            <>
                <span className={'fw-bold'}>{address.attention}</span><br/>
            </>
        }
        {address.street} {address.houseNumber} {address.houseNumberAddition}<br/>
        {address.extraAddressLine &&
            <>
                {address.extraAddressLine}<br/>
            </>
        }
        {address.postalCode} {address.city}
        {withCountry &&
            <>
                <br/>{address.country}
            </>
        }
    </div>

</>
  )
}

export {Address}

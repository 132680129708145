import {useAuth0} from '@auth0/auth0-react'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

const NoRoleError: FC = () => {
  const {logout} = useAuth0()  
  return (
    <>
      {/* begin::Title */}
      <h1 className='fw-bolder fs-2qx text-gray-900 mb-4'>Geen rollen toegewezen</h1>
      {/* end::Title */}

      {/* begin::Text */}
      <div className='fw-semibold fs-6 text-gray-700 mb-7'>
        U heeft geen toegang to het Ekosiet portaal omdat er geen rollen zijn toegewezen aan uw account.            
        <br /><br />
        Neem contact op met uw contactpersoon of stuur een mail naar info@ekosiet.nl
      </div>
      {/* end::Text */}

      {/* begin::Illustration */}
      <div className='mb-11'>
        <img
          src={toAbsoluteUrl('/media/auth/bankrupt.png')}
          className='mw-100 mh-300px theme-light-show'
          alt=''
        />
        <img
          src={toAbsoluteUrl('/media/auth/bankrupt.png')}
          className='mw-100 mh-300px theme-dark-show'
          alt=''
        />
      </div>
      {/* end::Illustration */}

      {/* begin::Link */}
      <div className='mb-0'>
        <button
          onClick={() => {
            logout({returnTo: window.location.origin + '/auth'})            
          }}
          className='btn btn-sm btn-primary'
        >
          Terug naar inloggen
        </button>
      </div>
      {/* end::Link */}
    </>
  )
}

export {NoRoleError}

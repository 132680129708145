import React, {useState} from 'react';
import {EkoCard, EkoCardBody, EkoCardHeader, EkoCardToolbar} from '../../../../../shared/components/card';
import {EkoTable, TableHeader} from '../../../../../shared/components/table';
import {DateTime, Duration} from "luxon";
import {PeriodInput} from "../../../../../shared/components/date/PeriodInput";
import {useQuery} from "@tanstack/react-query";
import {
    ProductionOperationRepresentation, TimePeriodProductionStatRow
} from "../../../../../modules/api-client/generated";
import ApiClient from "../../../../../modules/api-client/ApiClient";
import Loading from "../../../../../shared/components/Loading";
import {EmployeeOperationBarChart} from "../../../pm/components/EmployeeOperationBarChart";
import {useProductionOperations} from "../../../pm/hooks/use-list-production-operations";
import {TotalsHeader} from "../../../../../shared/components/TotalsHeader";

export function OperationEmployeeStatistics() {
    const defaultFrom = DateTime.now().startOf('week').minus({ day: DateTime.now().weekday > 3 ? 45 : 42 }).toFormat("yyyy-MM-dd");
    const defaultTo = DateTime.now().endOf('week').plus({ day: DateTime.now().weekday > 3 ? 3 : 0 }).toFormat("yyyy-MM-dd");
    const [fromDate, setFromDate] = useState<string>(defaultFrom);
    const [toDate, setToDate] = useState<string>(defaultTo);
    const [showCount, setShowCount] = useState<boolean>(false);

    const {
        isInitialLoading: isInitialLoadingPeriodProductionStats,
        data: periodProductionStats,
        isError: isErrorPeriodProductionStats,
        error: errorPeriodProductionStats
    } = useQuery<TimePeriodProductionStatRow[]>(
        ['timePeriodProductionStatRow', fromDate, toDate],
        () => ApiClient.Pm.Reporting.timePeriodProductionStats(fromDate, toDate)
            .then((res) => res.data)
    );

    const {
        isInitialLoading: isInitialLoadingProductionOperations,
        data: productionOperations,
        isError: isErrorProductionOperations,
        error: errorProductionOperations
    } = useProductionOperations();

    const resetRange = () => {
        setFromDate(defaultFrom);
        setToDate(defaultTo);
    };

    if (isInitialLoadingPeriodProductionStats || isInitialLoadingProductionOperations) {
        return <Loading/>;
    }

    if (isErrorPeriodProductionStats || isErrorProductionOperations) {
        return <>ERROR! De data kon niet worden geladen.</>;
        // return <>ERROR! {String(errorPeriodProductionStats)} -- {String(errorProductionOperations)}--{JSON.stringify(errorPeriodProductionStats)}</>;
    }

    if (!periodProductionStats || !productionOperations) {
        return <>Geen data gevonden!</>;
    }

    const employeeList = periodProductionStats.map(row => Object.keys(row.employee)).flat();
    const employees : string[] = [];
    for(const employee of employeeList) {
        if(!employees.includes(employee)) {
            employees.push(employee);
        }
    }
    employees.sort();

    function getDurationInSeconds(durationString: string) {
        return Duration.fromISOTime(durationString).as('seconds');
    }

    function getDisplayValue(stat: TimePeriodProductionStatRow, operation: ProductionOperationRepresentation) {
        const count = stat.productionByOperation[startWithLowerCase(operation.code)];
        if(!count) {
            return '-';
        }
        if(showCount) {
            return count;
        }
        const durationInSeconds = getDurationInSeconds(operation.averageDuration);
        return Duration.fromMillis(durationInSeconds * count * 1000).toFormat('hh:mm');
    }


    const getGrandTotalCount = () => {
        if(!periodProductionStats || !productionOperations) {
            return 0;
        }
        return periodProductionStats.reduce((sum, stat) => {
            return sum + calculateRowTotalCount(stat, productionOperations);
        }, 0);
    }

    const calculateRowTotalCount = (stat: TimePeriodProductionStatRow, productionOperations: ProductionOperationRepresentation[]) => {
        return productionOperations.reduce((sum, operation) => {
            const count = stat.productionByOperation[startWithLowerCase(operation.code)] || 0;
            return sum + count;
        }, 0);
    };

    const getGrandDuration = () => {
        if(!periodProductionStats || !productionOperations) {
            return '-';
        }
        const totalDurationInSeconds = periodProductionStats.reduce((sum, stat) => {
            return sum + calculateRowTotalDurationInSeconds(stat, productionOperations);
        }, 0);
        return toShortFormattedDuration(Duration.fromMillis(totalDurationInSeconds * 1000));
    }

    const toShortFormattedDuration = (duration : Duration) => {
        if(!duration) {
            return '-';
        }
        return duration.toFormat('hh:mm');
    }

    const calculateRowTotalDurationInSeconds = (stat: TimePeriodProductionStatRow, productionOperations: ProductionOperationRepresentation[]) => {
        return productionOperations.reduce((sum, operation) => {
            const count = stat.productionByOperation[startWithLowerCase(operation.code)] || 0;
            const durationInSeconds = getDurationInSeconds(operation.averageDuration);
            return sum + (durationInSeconds * count);
        }, 0);
    };

    const getDisplayRowTotalValue = (stat : TimePeriodProductionStatRow, productionOperations: ProductionOperationRepresentation[]) => {
        if(showCount) {
            return calculateRowTotalCount(stat, productionOperations);
        }
        return toShortFormattedDuration(Duration.fromMillis(calculateRowTotalDurationInSeconds(stat, productionOperations) * 1000));
    }

    const getOperationTotalCount = (operationCode: string) => {
        return periodProductionStats!.reduce((sum, stat) => {
            return sum + (stat.productionByOperation[startWithLowerCase(operationCode)] || 0);
        }, 0);
    }

    const getOperationDuration = (operactionCode : string) => {
        const isoDurationString = productionOperations!.find(po => po.code === operactionCode)!.averageDuration;
        return Duration.fromISOTime(isoDurationString);
    }

    const getTotalOperationDuration = (operationCode : string) => {
        const opDuration = getOperationDuration(operationCode);
        const count = getOperationTotalCount(operationCode);
        const totalDurationInSeconds = opDuration.as('seconds') * count;
        return Duration.fromMillis(totalDurationInSeconds * 1000);
    }

    const startWithLowerCase = (str : string) => {
        return str.charAt(0).toLowerCase() + str.slice(1);
    }

    return (
        <>
            <EkoCard>
                <EkoCardHeader title="Productiestatistieken per periode">
                    <EkoCardToolbar>
                        <div className='form-check form-switch'>
                            <input className='form-check-input' type='checkbox' role='switch'
                                   onChange={() => setShowCount(!showCount)}
                                   checked={showCount}
                                   id='displayCount'/>
                            <label className='form-check-label me-2' htmlFor='displayCount'>Toon aantallen</label>
                        </div>
                        <PeriodInput
                            startDate={fromDate}
                            endDate={toDate}
                            onStartDateChange={setFromDate}
                            onEndDateChange={setToDate}
                            resetRange={resetRange}
                        />
                    </EkoCardToolbar>
                </EkoCardHeader>
                <EkoCardBody>
                    <div className="container">
                        <div className="row">
                            <div className='col-12 col-lg-6'>
                                <EmployeeOperationBarChart fromDate={fromDate} toDate={toDate} showCount={showCount}/>
                            </div>
                            <div className='col-12 col-lg-6'>
                                <EmployeeOperationBarChart fromDate={fromDate} toDate={toDate} showCount={showCount} transposed={true}/>
                            </div>
                        </div>
                    </div>
                    <EkoTable>
                        <TableHeader>
                            <th/>
                            <TotalsHeader totalCount={getGrandTotalCount()}
                                          formattedTotalDuration={getGrandDuration()}
                                          showCount={showCount}
                                          reference={'Totaal'}/>
                            {productionOperations.map(po => (
                                <th>
                                    <TotalsHeader totalCount={getOperationTotalCount(po.code)}
                                                  formattedTotalDuration={toShortFormattedDuration(getTotalOperationDuration(po.code))}
                                                  showCount={showCount}
                                                  reference={po.title}/>
                                </th>
                            ))}
                        </TableHeader>
                        <tbody>
                        {periodProductionStats.map(stat => (
                            <tr key={stat.employee}>
                                <td>{stat.employee}</td>
                                <td>{getDisplayRowTotalValue(stat, productionOperations)}</td>
                                {productionOperations.map(operation => (
                                    <td className={'text-center'} key={operation.code}>{getDisplayValue(stat, operation)}</td>
                                ))}
                            </tr>
                        ))}
                        </tbody>
                    </EkoTable>
                </EkoCardBody>
            </EkoCard>
        </>
    );
}
import React, {FC} from 'react'
import * as Yup from 'yup'
import {Form, Formik, FormikHelpers} from 'formik'
import {PutOrderReferenceRepresentation} from '../../../../../../../modules/api-client/generated'
import TextField from "../../../../../../../shared/components/TextField";
import {usePutReference} from "../../../hooks/use-put-reference";

type Props = {
    id: string
    reference?: string|null
    handleClose: () => void,
}


const PutReferenceForm: FC<Props> = ({id, reference, handleClose}) => {

    const mutation = usePutReference(id);

    const initialValues:PutOrderReferenceRepresentation= {
        reference: reference ? reference : ''
    }

    const schema = {
        reference: Yup.string().nullable().notRequired().max(150, 'Maximaal 150 karakters.')
    }
    const formSchema = () => Yup.lazy((values) => {
        return Yup.object().shape(schema)
    });
    const onSubmit = (values: PutOrderReferenceRepresentation, {setSubmitting}: FormikHelpers<PutOrderReferenceRepresentation>) => {

        return new Promise<void>((resolve, reject) => {
            mutation.mutate(values, {
                onSuccess: (model) => {
                    resolve();
                    handleClose()
                },
                onError: () => {
                    reject();
                }
            });

        }).finally(() => setSubmitting(false));
    };


    return (
        <>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={formSchema}>
                {({
                      values,
                      handleChange,
                      handleSubmit,
                      getFieldProps,
                      isSubmitting,
                  }) => (
                    <Form>
                        {/* begin form element */}
                        <TextField
                            label={'Referentie'}
                            {...getFieldProps('reference')}
                        />

                        {/* begin::Actions */}
                        <div className='text-center pt-5'>
                            <button
                                type='reset'
                                className='btn btn-light me-3'
                                data-kt-customer-modal-action='cancel'
                                data-dismiss="modal"
                                onClick={() => handleClose()}
                                disabled={isSubmitting}
                            >
                                Annuleren
                            </button>

                            <button
                                type='submit'
                                className='btn btn-primary'
                                data-kt-customer-modal-action='submit'
                                disabled={isSubmitting}
                            >
                                <span className='indicator-label'>Opslaan
                                </span>
                                {isSubmitting && (
                                    <span className='indicator-progress'>
                                        Even wachten a.u.b
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                        {/* end::Actions */}

                    </Form>
                )}
            </Formik>
        </>
    )
}

export {PutReferenceForm}

import React, {useCallback, useRef} from 'react';
import {useQuery} from '@tanstack/react-query';
import ApiClient from '../../../../modules/api-client/ApiClient';
import {useOutletContext} from 'react-router-dom';
import ActiveBadge from '../../../../shared/components/ActiveBadge';
import {EkoCard, EkoCardBody} from '../../../../../_metronic/helpers';
import {TableHeader} from '../../../../shared/components/table/TableHeader';
import {EkoTable} from '../../../../shared/components/table/EkoTable';
import {EkoCardHeader} from '../../../../shared/components/card/EkoCardHeader';
import {EkoCardToolbar} from '../../../../shared/components/card/EkoCardToolbar';
import {MasterdataModalContext} from '../../../pages/MasterData';
import StopProfileUpsert from './StopProfileUpsert';
import {ConfirmDialog} from '../../../../shared/components/ConfirmDialog';
import {StopProfile} from '../../../../modules/api-client/generated';
import {useStopProfileDeleteMutation} from './hooks';
import Loading from '../../../../shared/components/Loading';


const StopProfileList: React.FC = () => {

	const {setShowModal, setModalTitle, setModalComponent} = useOutletContext<MasterdataModalContext>();

	const add = () => {
		setModalComponent(<StopProfileUpsert handleClose={() => setShowModal(false)} />);
		setModalTitle('Toevoegen');
		setShowModal(true);
	};
	const edit = (StopProfile: StopProfile) => {
		setModalComponent(<StopProfileUpsert id={StopProfile.id} handleClose={() => setShowModal(false)} />);
		setModalTitle(StopProfile.name + ' aanpassen');
		setShowModal(true);
	};

	const {
		isInitialLoading,
		data: list,
		isError,
	} = useQuery<StopProfile[]>(['pimStopProfilesGet'], () => ApiClient.Pim.StopProfile.pimStopProfilesGet().then((res) => res.data));

	if (isInitialLoading) {
		return <Loading />;
	}

	if (isError) {
		return <>ERROR!</>;
	}

	if (!list) return <></>;

	return <>

		<EkoCard>
			<EkoCardHeader title={'Aanslagprofielen'}>
				<EkoCardToolbar>
					<button onClick={() => add()} className='btn btn-primary btn-sm'>Toevoegen</button>
				</EkoCardToolbar>
			</EkoCardHeader>
			<EkoCardBody>
				<EkoTable>
					<TableHeader>
						<th>Code</th>
						<th>Naam</th>
						<th className='text-end'>Breedte sponning</th>
						<th className='text-end'>Positie aanslag</th>
						<th className='text-end'>Positie bevestigingssleuf</th>
						<th className='text-end'>Minimale breedte</th>
						<th className='text-end'>Hoogte</th>
						<th></th>
					</TableHeader>
					<tbody>
					{(!list || list.length === 0) &&
						<tr>
							<td colSpan={5} className='text-center'>Geen gegevens</td>
						</tr>}

					{list && list.map((data) => <tr key={data.id}>
						<td className='fit-content'>
							<a href='#' onClick={() => edit(data)}>{data.code}</a>
						</td>
						<td>
							{data?.name}
						</td>
						<td className='text-end'>
							{data?.rabbetWidth}mm
						</td>
						<td className='text-end'>
							{data?.stopPosition}mm
						</td>

						<td className='text-end'>
							{data?.mountingSlotPosition}mm
						</td>
						<td className='text-end'>
							{data?.minWidth}mm
						</td>
						<td className='text-end'>
							{data?.height}mm
						</td>

						<td className='fit-content'>
							<ActiveBadge value={data.active} />
						</td>
						<td className='fit-content'>
							<DeleteButton id={data.id!} />
						</td>
					</tr>)}
					</tbody>
				</EkoTable>
			</EkoCardBody>
		</EkoCard>
	</>;
};
const DeleteButton: React.FC<{id: string}> = ({id}) => {
	const openDeleteDialog = useRef<(show: boolean) => void>();

	const deleteMutation = useStopProfileDeleteMutation(id);
	const deleteCallback = useCallback(async () => {
		await deleteMutation.mutateAsync(id!);
	}, [deleteMutation, id]);
	return (
		<>
			<button type='button' className='btn btn-light btn-sm' disabled={false}
					onClick={() => openDeleteDialog.current?.call(this, true)}><i className={'fas fa-trash'}></i>
			</button>
			<ConfirmDialog show={openDeleteDialog}
						   onConfirm={deleteCallback}
						   dialogStyle='danger'
						   title='Verwijderen'
						   message='Wil je doorgaan?'
						   confirmText='Definitief verwijderen'

			/>
		</>

	);
};


export default StopProfileList;
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {ProfileMapperModal} from "../../components/ProfileMapperModal";
import {useNavigate, useParams} from "react-router-dom";
import {useGetImportProfileMapping} from "../../hooks/use-get-import-profile-mapping";
import Loading from "../../../components/Loading";

export function CustomerImportProfileMapperPage() {
const navigate = useNavigate();
    const {id,gaProfileId} = useParams<{ id: string , gaProfileId: string}>();

    const {isLoading, data: gaProfile, isError} = useGetImportProfileMapping(gaProfileId);
    if (isLoading) {
        return <Loading/>;
    }
    if (!gaProfile) {
        return <>Geen profiel geselecteerd</>;
    }

    if (isError) {
        return <>Fout bij het ophalen van het profiel!</>;
    }
    return (
        <ProfileMapperModal profile={gaProfile} handleClose={()=>{navigate(-1)}} show={true}/>
    )
}


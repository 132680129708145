import { FC } from 'react'
import * as Yup from 'yup'
import { Form, Formik, FormikHelpers } from 'formik'
import { PutPlannedExpeditionDateRepresentation } from '../../../../../../../modules/api-client/generated';
import moment from 'moment';
import { DateField } from '../../../../../../../shared/components/date/DateField';
import { usePutPlannedExpeditionDate } from '../../../hooks/use-put-planned-expedition-date';


type Props = {
    id: string
    plannedExpeditionDate?: Date | null
    updateStateToPlanned: boolean,
    handleClose: () => void,
}

type FormValues = Omit<PutPlannedExpeditionDateRepresentation, 'plannedExpeditionDate'> & {
    plannedExpeditionDate?: Date | null;
};

const PutPlannedExpeditionDateForm: FC<Props> = (
    {
        id,
        plannedExpeditionDate,
        updateStateToPlanned,
        handleClose
    }
) => {

    const mutation = usePutPlannedExpeditionDate(id);

    const initialValues: FormValues = {
        plannedExpeditionDate: plannedExpeditionDate ? plannedExpeditionDate : new Date(),
        updateStateToPlanned: updateStateToPlanned
    }

    const schema = {
        plannedExpeditionDate: Yup.date().nullable().notRequired()
    }
    const formSchema = Yup.object().shape(schema);

    const onSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {

        return new Promise<void>((resolve, reject) => {

            let payload: PutPlannedExpeditionDateRepresentation = {
                ...values,
                plannedExpeditionDate: values.plannedExpeditionDate ? moment(values.plannedExpeditionDate).format(moment.HTML5_FMT.DATE) : null
            }

            mutation.mutate(payload, {
                onSuccess: (model) => {
                    resolve();
                    handleClose()
                },
                onError: () => {
                    reject();
                }
            });

        }).finally(() => setSubmitting(false));
    };

    return (
        <>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={formSchema}>
                {({
                    values,
                    handleChange,
                    setFieldValue,
                    isSubmitting,
                }) => (
                    <Form>

                        <div className='d-flex flex-column mb-7 fv-row'>
                            <label className='d-flex align-items-center form-label'>
                                <span>Geplande leveringsdatum</span>
                            </label>

                            <DateField fieldName='plannedExpeditionDate' />

                        </div>

                        {/* begin::Actions */}
                        <div className='text-center pt-5'>
                            <button
                                type='reset'
                                className='btn btn-light me-3'
                                data-kt-customer-modal-action='cancel'
                                data-dismiss="modal"
                                onClick={() => handleClose()}
                                disabled={isSubmitting}
                            >
                                Annuleren
                            </button>

                            <button
                                type='submit'
                                className='btn btn-primary'
                                data-kt-customer-modal-action='submit'
                                disabled={isSubmitting}
                            >
                                <span className='indicator-label'>Opslaan
                                </span>
                                {isSubmitting && (
                                    <span className='indicator-progress'>
                                        Even wachten a.u.b
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                        {/* end::Actions */}

                    </Form>
                )}
            </Formik>
        </>
    )
}

export { PutPlannedExpeditionDateForm }

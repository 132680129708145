import React from "react";
import SpinnerButton from "./SpinnerButton";


export interface SubmitButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    isSubmitting?: boolean;
    dotdotdot?: boolean;
}

const SubmitButton: React.FC<SubmitButtonProps> = (props) => {

    const btnProps = {
        ...props,
        ...{
            spinning: props.isSubmitting ?? false,
            dotdotdot: props.dotdotdot
        }
    };

    delete btnProps.isSubmitting;

    return <SpinnerButton {...btnProps} />;
};

export default SubmitButton;
import React, { FC, useEffect, useState } from 'react'
import useDebounce from 'react-use/lib/useDebounce';
import {FilterParameter, useEkosietFilter} from "../../../../shared/context/FilterContext";

type Props = {
    fieldName: string;
    filterProperty?: string
}

export const CustomerArchivedFilter: FC<Props> = (props: Props) => {
    const { setFilters, containsFilter, resetCount,reset } = useEkosietFilter()
    const [archived, setArchived] = useState<boolean|undefined>(undefined);
    useEffect(() => {
        if (archived) {
            const filterProperty =  props.filterProperty || 'IsArchived'
            const expression = { [filterProperty]: { eq: archived } }
            const filter: FilterParameter = {
                filterExpression:  expression,
                filterName: props.fieldName,
                values: [archived]
            }
            setFilters(props.fieldName, [filter])
        } else {
            if(containsFilter(props.fieldName)) {
                setFilters(props.fieldName, [])
            }
        }
    }, [archived])
    useEffect(() => {
        if (resetCount) {
            setFilters(props.fieldName, [])
        }
    }, [resetCount])
    return (
        <>
            <div className='form-check form-switch'>
                <input className='form-check-input' type='checkbox' role='switch'
                       onChange={(e) => setArchived(archived === undefined ? true : !archived)}
                       checked={archived}
                       id={props.fieldName}/>
                <label className='form-check-label' htmlFor={props.fieldName}>Toon gearchiveerde klanten</label>
            </div>
        </>
    )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import {EkoCard, EkoCardBody, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useEkosietAuth0} from "../../../modules/auth0/core/useEkosietAuth0";

type Props = {
    className: string
}

const EngageWidget10 = ({className}: Props) => {
    const {isKlant} = useEkosietAuth0()
    return (
        <>
            <EkoCard>
                <EkoCardBody>
                    <div className='my-10'>
                        <div className='fs-2hx fw-bold text-center'>
                            <img
                                src={toAbsoluteUrl('/media/logos/ekosiet-logo-product.png')}
                                className='img-fluid'
                                alt=''
                            />
                        </div>
                    </div>

                    {isKlant &&
                        <>
                            <h3>Hulp nodig?</h3>
                            <p>
                                Contact: 0 488 72 59 47
                                E-mail: info@ekosiet.nl
                                Vragen over de je factuur?
                            </p>

                        </>
                    }
                </EkoCardBody>
            </EkoCard>
        </>
    )
}
export {EngageWidget10}

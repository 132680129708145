import React from "react";
import {Field} from "formik";
import {Category} from "../../../../modules/api-client/generated";
import {useQuery} from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import CustomSelect, {SelectOption, OptionsType} from "../../../../shared/components/CustomSelect";

type Props = {
    name: string;
    multi?: boolean;
    required?: boolean;
}
export const CategoryPicker: React.FC<Props> = ({name, multi, required}) => {

    let options: OptionsType<SelectOption> = [];

    const {
        isInitialLoading,
        data: list,
        isError
    } = useQuery<Category[]>(['pimCategoriesGet'], () => ApiClient.Pim.Category.pimCategoriesGet().then((res) => res.data));


    if (list) {
        options = list.map((item) => {
            return {value: item.id!, label: item.name!}
        })
    }


    return (
        <>
            <Field
                className="custom-select"
                name={name}
                options={options}
                component={
                    CustomSelect
                }
                placeholder={multi? "Kies een of meerdere categorien...":'Kies een categorie'}
                isClearable={!required}
                isMulti={multi}
            />
        </>
    )
}
import React, {useCallback} from 'react';
import {mapDownload, useDownloadFile} from '../../hooks/useDownloadFile';
import ApiClient from '../../../modules/api-client/ApiClient';
import toast from 'react-hot-toast';

export const ImportDownloadButton: React.FC<{importId: string}> = ({importId}) => {
	const downloadCallback = useCallback(() => {
		return ApiClient.Acl.Import.aclImportDownloadIdGet(importId)                                   
                                   .then(mapDownload)
                                   ;
	}, []);

	const {ref, result, download, isDownloading} = useDownloadFile({
		downloadCallback: downloadCallback,
        filename: undefined,
        onError: () => toast.error("Downloaden mislukt")
	});

	return (
		<>
			<a href={result?.url} download={result?.filename} ref={ref} className="d-none" />
			<a  href="#" className={'me-2'}
				title="Download import bestand"
				// disabled={isDownloading}
				onClick={download}
			>
				{isDownloading ? (
					<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
				) : (
					<i className="fas fa-download"></i>
				)}
			</a>
		</>
	);
};

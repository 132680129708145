import React, {ReactElement, useCallback, useRef, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import ActiveBadge from "../../../../shared/components/ActiveBadge";
import {EkoCard, EkoCardBody} from "../../../../../_metronic/helpers";
import {EkoTable, TableHeader} from "../../../../shared/components/table";
import CatalogItemUpsert from "../catalogitem/CatalogItemUpsert";
import {ConfirmDialog} from "../../../../shared/components/ConfirmDialog";
import {
    CatalogItem,
    CatalogItemProduct,
    CatalogItemType,
    CatalogItemCatalog,
    CornerPieceProduct,
    GlazingBarProduct,
    GlazingProfileProduct,
    KopIsolatorSetProduct,
    ConfiguredNeutProduct,
    SillConfiguratorProduct, SillProduct, StandardProduct, StopProfileProduct, TransportProduct
} from "../../../../modules/api-client/generated";
import {useCatalogItemDeleteMutation} from "../catalogitem/hooks";
import {PageTitle} from "../../../../../_metronic/layout/core";
import {CatalogItemModal} from "../catalogitem/components/CatalogItemModal";
import Euro from "../../../../shared/components/Euro";
import CatalogItemPricingUpsert from "../catalogitem/CatalogItemPricingUpsert";
import CatalogItemTypeLabel from "../catalogitem/components/CatalogItemTypeLabel";
import Loading from "../../../../shared/components/Loading";

type Product =
    CatalogItemProduct
    & CornerPieceProduct
    & GlazingBarProduct
    & GlazingProfileProduct
    & KopIsolatorSetProduct
    & ConfiguredNeutProduct
    & SillConfiguratorProduct
    & SillProduct
    & StandardProduct
    & StopProfileProduct
    & TransportProduct;


const CatalogItemPage: React.FC = () => {
    const [showModal, setShowModal] = useState<boolean>(false)
    const [modalTitle, setModalTitle] = useState<string | undefined | null>(null);
    const [modalComponent, setModalComponent] = useState<ReactElement | undefined | null>(null);
    const add = () => {
        setModalComponent(<CatalogItemUpsert handleClose={() => setShowModal(false)}/>)
        setModalTitle('Toevoegen')
        setShowModal(true)
    }
    const edit = (product: Product) => {
        setModalComponent(<CatalogItemUpsert id={product.id} handleClose={() => setShowModal(false)}/>)
        setModalTitle(product.title + ' aanpassen')
        setShowModal(true)
    }
    const editPrices = (product: CatalogItem) => {
        setModalComponent(<CatalogItemPricingUpsert id={product.id} handleClose={() => setShowModal(false)}/>)
        setModalTitle(product.title + ' prijzen')
        setShowModal(true)
    }
    const {
        isInitialLoading,
        data: list,
        isError
    } = useQuery<CatalogItem[]>(['pimCatalogItemsGet'], () => ApiClient.Pim.CatalogItem.pimCatalogItemsGet().then((res) => res.data));
    if (isInitialLoading) {
        return <Loading/>;
    }
    if (isError) {
        return <>ERROR!</>;
    }
    if (!list) return <></>;
    return <>
        <PageTitle toolbar={<button className={'btn btn-primary'} onClick={add}>Nieuw product</button>}
                   breadcrumbs={[]}>Producten</PageTitle>
        <CatalogItemModal show={showModal} component={modalComponent} title={modalTitle} size={'lg'}
                          handleClose={() => setShowModal(false)}/>
        <EkoCard>
            <EkoCardBody>
                <EkoTable>
                    <TableHeader>
                        <th className='min-w-150px'>Artikel</th>
                        <th className='min-w-150px'>Type</th>
                        <th className='min-w-50px'>Categorieen</th>
                        <th className='min-w-50px'>Kortingsgroep</th>
                        <th className='min-w-50px'>Catalogussen</th>
                        <th className='min-w-150px text-end'>Basisprijs</th>
                        <th className='min-w-150px text-center'></th>
                        <th className='min-w-50px'></th>
                    </TableHeader>
                    <tbody>
                    {(!list || list.length === 0) &&
                        <tr>
                            <td colSpan={5} className="text-center">Geen gegevens</td>
                        </tr>}

                    {list && list.map((data) => {
                        const defaults = {
                            active: false,
                            catalogItemType: CatalogItemType.Standard
                        };
                        const ci: Product = {...defaults, ...data} as Product;
                        return (
                            <tr key={ci.id}>
                                <td>
                                    <div>
                                        <div className={'fw-bold fs-6'}>{ci.title}</div>
                                        <div className={'text-muted'}>{ci.code}</div>
                                        <div className={'text-muted'}>{ci.description}</div>
                                        <div className={'text-muted'}>

                                            {(ci.catalogItemType === CatalogItemType.CornerPiece ||
                                                    ci.catalogItemType === CatalogItemType.GlazingBar ||
                                                    ci.catalogItemType === CatalogItemType.GlazingProfile ||
                                                    ci.catalogItemType === CatalogItemType.Sill ||
                                                    ci.catalogItemType === CatalogItemType.StopProfile
                                                ) &&
                                                <div>
                                                <span className="bullet h-10px w-10px"
                                                      style={{background: ci.color?.hex}}></span> {ci.color?.name}
                                                </div>
                                            }
                                            {(ci.catalogItemType === CatalogItemType.SillConfigurator ||
                                                    ci.catalogItemType === CatalogItemType.Sill ||
                                                    ci.catalogItemType === CatalogItemType.KopIsolatorSet
                                                ) &&
                                                <div>
                                                    Dorpel: {ci.sill?.name}
                                                </div>
                                            }
                                            {ci.catalogItemType === CatalogItemType.Sill &&
                                                <div>
                                                    Lengte: {ci.length}mm
                                                </div>
                                            }
                                            {ci.catalogItemType === CatalogItemType.GlazingBar &&
                                                <div>
                                                    Glaslat: {ci.glazingBar?.name}
                                                </div>
                                            }
                                            {ci.catalogItemType === CatalogItemType.GlazingProfile &&
                                                <div>
                                                    Beglazingsprofiel: {ci.glazingProfile?.name}
                                                </div>
                                            }
                                            {ci.catalogItemType === CatalogItemType.CornerPiece &&
                                                <div>
                                                    Hoekstukje: {ci.cornerPiece?.name}
                                                </div>
                                            }
                                            {ci.catalogItemType === CatalogItemType.StopProfile &&
                                                <div>
                                                    Aanslagprofiel: {ci.stopProfile?.name}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <CatalogItemTypeLabel type={ci.catalogItemType}/>
                                </td>
                                <td>
                                    {ci.categories?.map((ref: CatalogItemCatalog) =>
                                        <span className={'badge badge-secondary me-1'} key={ref.id}>{ref.name}</span>
                                    )}
                                </td>
                                <td>
                                     <span className={'badge badge-secondary'}>{ci.discountGroup?.name}</span>
                                </td>
                                <td>
                                    {ci.catalogs?.map((ref: CatalogItemCatalog) => {
                                            return (
                                                <span className={'badge badge-secondary me-1'} key={ref.id}>
                                                {ref.name}
                                            </span>
                                            )
                                        }
                                    )}
                                </td>
                                <td className={'text-end'}>
                                    {ci.catalogItemType !== CatalogItemType.SillConfigurator && ci.catalogItemType !== CatalogItemType.NeutConfigurator ?
                                        <a href="#" onClick={() => editPrices(data)}>
                                            <Euro value={ci.price}/>
                                        </a>
                                        :
                                        <>-</>
                                    }
                                </td>
                                <td className="fit-content text-center">
                                    <ActiveBadge value={ci.active}/>
                                </td>
                                <td className="fit-content text-end">
                                    <a className={'btn btn-light btn-sm me-1'} href='#' onClick={() => edit(ci)}>
                                        <i className={'fas fa-edit'}></i>
                                    </a>
                                    <DeleteButton id={ci.id!}/>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </EkoTable>
            </EkoCardBody>
        </EkoCard>
    </>
}
const DeleteButton: React.FC<{ id: string }> = ({id}) => {
    const openDeleteDialog = useRef<(show: boolean) => void>();

    const deleteMutation = useCatalogItemDeleteMutation(id);
    const deleteCallback = useCallback(async () => {
        await deleteMutation.mutateAsync(id!);
    }, [deleteMutation, id]);
    return (
        <>
            <button type="button" className="btn btn-light btn-sm" disabled={false}
                    onClick={() => openDeleteDialog.current?.call(this, true)}><i className={'fas fa-trash'}></i>
            </button>
            <ConfirmDialog show={openDeleteDialog}
                           onConfirm={deleteCallback}
                           dialogStyle="danger"
                           title="Verwijderen"
                           message="Wil je doorgaan?"
                           confirmText="Definitief verwijderen"
            />
        </>
    )
}


export default CatalogItemPage;
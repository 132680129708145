import React from "react";
import {API_GATEWAY_BASE_URL} from "../../constants";

export interface SluitpotBeslagSvgProps {
    svg?: string | null;
    contour?: string | null;
}

const SluitpotBeslagSvg: React.FC<SluitpotBeslagSvgProps> = (props) => {

        if (props.svg) {
            return <div className="sluitpotbeslag-preview-container"
                        dangerouslySetInnerHTML={{__html: props.svg}}/>
        }
    if (props.contour) {
        let imgSrc = props.contour ? `${API_GATEWAY_BASE_URL}/api/render/preview/sluitpot?geometry=${encodeURIComponent(props.contour)}&` : undefined;

        return <img src={imgSrc} style={{
            width: "100%",
            height: "100%",
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "contain"
        }} alt=""/>
    }
        return <></>;
    }
;

export default SluitpotBeslagSvg;

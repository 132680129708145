import React, {useId} from "react";
import {useField} from "formik";
import FieldErrors from "../FieldErrors";
import {SillConfigurationSlidingDoorBar} from "../../../modules/api-client/generated";
import {HefSchuifDoorBarCutoutField} from "./HefSchuifDoorBarCutoutField";


export interface HefSchuifSlidingDoorBarFieldRowProps {
    fieldName: string;
    label: string;
    promptLength?: boolean;
}

export const HefSchuifSlidingDoorBarFieldRow: React.FC<HefSchuifSlidingDoorBarFieldRowProps> = (props) => {

    const id = useId();

    const [field, meta, helper] = useField<SillConfigurationSlidingDoorBar | undefined>(props.fieldName);

    const promptLength = props.promptLength ?? false;

    return <>
        <div className="row">

            {promptLength && <div className={"col-4"}>
                <label htmlFor={`${id}_length`} className='form-label'>
                    <small className='text-uppercase text-muted'>Lengte {props.label}</small>
                </label>
                <div className="input-group">
                    <input type="number"
                           id={`${id}_length`}
                           pattern={"[0-9]+([,\\.][0-9]+)?"}
                           name={`${props.fieldName}.length`}
                           value={field.value?.length ?? ''}
                           onChange={field.onChange}
                           onBlur={field.onBlur}
                           className="form-control"
                           min="0" step="0.1"
                           placeholder="Lengte"
                           required={true}/>
                    <span className="input-group-text">mm</span>
                </div>
                <small className="text-muted"><i>Vanuit de dagkant</i></small>
                <FieldErrors field={`${props.fieldName}.length`}/>
            </div>
            }

            <div className={"col"}>
                <label className='form-label'>
                    <small className='text-uppercase text-muted'>Uitsparing rubberblok</small>
                </label>
                <HefSchuifDoorBarCutoutField fieldName={`${field.name}.cutout`}/>
            </div>
        </div>
    </>
}

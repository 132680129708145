import {useQuery, useQueryClient} from '@tanstack/react-query';
import {toast} from 'react-hot-toast';
import {useEkosietMutation} from "../../hooks/useEkosietMutation";
import {NotFound, ValidationFailed} from "../../../modules/api-client/Responses";
import ApiClient from "../../../modules/api-client/ApiClient";
import {AxiosError} from "axios";
import {isNotEmpty} from "../../../../_metronic/helpers";
import {CreateImportSillMappingCommand, ImportSillMappingRepresentation} from "../../../modules/api-client/generated";

export const useGetImportSillTypeMappings = (enabled: boolean = true) => {
	const response = useQuery<Array<ImportSillMappingRepresentation>, AxiosError, Array<ImportSillMappingRepresentation>>(
		['aclImportMappingsSillTypesGet'],
		() => {
			return ApiClient.Acl.ImportMapping.aclImportMappingSillsGet().then((res) => res.data);
		},
		{
			useErrorBoundary: true,
			enabled: enabled,
		}
	);

	if (response.error) {
		var error = response.error;
		toast.error(error?.message);
	}

	return response;
};


export const useGetImportSillTypeMapping = (id: string | undefined, enabled: boolean = true) => {
	const response = useQuery<ImportSillMappingRepresentation, AxiosError, ImportSillMappingRepresentation>(
		['aclImportSillTypeIdGet', id],
		() => {
			return ApiClient.Acl.ImportMapping.aclImportMappingSillImportSillMappingIdGet(id!).then((res) => res.data);
		},
		{
			useErrorBoundary: true,
			enabled: enabled && isNotEmpty(id),
		}
	);

	if (response.error) {
		var error = response.error;
		toast.error(error?.message);
	}

	return response;
};


export const usePostSillTypeMapping = () => {
	const queryClient = useQueryClient();

	return useEkosietMutation<string, ValidationFailed, CreateImportSillMappingCommand>(
		(command) => {
			return ApiClient.Acl.ImportMapping.aclImportMappingSillPost(undefined, command).then((response) => response.data);
		},
		{
			onSuccess: (data, variables) => {
				queryClient.invalidateQueries(['aclImportMappingsSillTypesGet']);
				queryClient.invalidateQueries(['aclImportSillTypeIdGet']);
				toast.success(`Koppeling succesvol toegevoegd of aangepast`);

			},
			onError: () => {
            	toast.error("Koppeling mislukt!");
			},
		}
	);
};

export const useDeleteSillTypeMapping = () => {
	const queryClient = useQueryClient();
	return useEkosietMutation<void, NotFound, string>(
		(importProfileMappingId) => {
			return ApiClient.Acl.ImportMapping.aclImportMappingSillImportSillMappingIdDelete(importProfileMappingId).then((response: any) => response.data);
		},
		{
			onSuccess: (data, variables) => {
				queryClient.invalidateQueries(['aclImportMappingsSillTypesGet']);
				queryClient.invalidateQueries(['aclImportSillTypeIdGet']);
				toast.success(`Koppeling successvol verwijderd`);
			},
			onError: () => {
				toast.error('Koppeling verwijderen mislukt');
			},
		}
	);
};

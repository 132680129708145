import React, {useCallback, useState} from 'react';
import {ProfilePicker, ProfilePickerProps, ProfilePickerHandle} from './ProfilePicker';
import {Button, Modal} from 'react-bootstrap';
import {modalsRoot} from '../../../../global-helpers';
import {ProfileInfo} from "../../../../modules/api-client/generated";
import SpinnerButton from "../../../../shared/components/SpinnerButton";


export type ProfilePickerModalOptions = {
    title?: string;
    pickerProps: Omit<ProfilePickerProps, 'onConfirm'>;
    onSelected: (profile: ProfileInfo[]) => Promise<void>;
    confirmText?: string;
    cancelText?: string;
}

export function useProfilePickerModal(options: ProfilePickerModalOptions) {

    const [open, setOpen] = useState(false);
    const [confirming, setConfirming] = useState(false);

    const pickerProps: ProfilePickerProps = {
        ...options.pickerProps,
        onConfirm: async (selection) => {
            setConfirming(true);
            try {
                await options.onSelected(selection);
                setOpen(false);
            } catch {
                setConfirming(false);
            }
        }
    };


    const element = <ProfilePickerModal
        title={options.title}
        show={open}
        onHide={() => setOpen(false)}
        confirmText={options.confirmText}
        cancelText={options.cancelText}
        pickerProps={pickerProps}
    />;

    return {
        open: () => setOpen(true),
        close: () => setOpen(false),
        element:  element,
        confirming
    };
}


export type ProfilePickerModalProps = {
    title?: string;
    pickerProps: ProfilePickerProps;
    show: boolean;
    onHide: () => void;
    confirmText?: string;
    cancelText?: string;
}

export const ProfilePickerModal: React.FC<ProfilePickerModalProps> = (props) => {

    const profilePicker = React.createRef<ProfilePickerHandle>();

    const [selectedCount, setSelectedCount] = useState(0);

    const onSelectionChanged = useCallback((selection: ProfileInfo[]) => {
        setSelectedCount(selection.length);
        props.pickerProps.onSelectionChanged?.(selection);
    }, []);

    return <Modal
        container={modalsRoot}
        tabIndex={-1}
        aria-hidden='true'
        autoFocus={true}
        className=''
        dialogClassName={'modal-dialog-scrollable'}
        contentClassName={'shadow-lg vh-100'}
        show={props.show}
        size='xl'
        onHide={props.onHide}
        animation={false}
        backdrop={'static'}>
        <Modal.Header closeButton>
            <Modal.Title>{props.title ?? 'Profiel'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>            
            <ProfilePicker ref={profilePicker} {...props.pickerProps} onSelectionChanged={onSelectionChanged}/>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
            <div>
                {selectedCount > 0 && <span>{selectedCount} geselecteerd</span>}
            </div>
            <div>
                <Button variant="link" className="mx-4" onClick={props.onHide}>
                    {props.cancelText ?? 'Annuleren'}
                </Button>
                {props.pickerProps.multi &&
                    <SpinnerButton className="btn btn-primary mx-4"
                                   spinning={false}
                                   disabled={selectedCount === 0}
                                   onClick={async () => await profilePicker.current?.confirm()}>
                        {props.confirmText ?? 'Bevestigen'}
                    </SpinnerButton>
                }
            </div>
        </Modal.Footer>
    </Modal>;
};

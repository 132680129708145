import {Route, Routes, Outlet} from 'react-router-dom'
import {ErrorBoundary, FallbackProps} from "react-error-boundary";

import React from "react";
import axios from "axios";
import {BackofficeRoutePath} from "../../RoutePath";
import {AdminDashboardPage} from './pages/AdminDashboardPage';
import {ProductionSagaPage} from "./pages/ProductionSagaPage";

export const AdminRoutePath = {
    dashboard: '',
    productionSaga: 'productionItems',
    link(path:string){
        return BackofficeRoutePath.admin+path;
    }
};

export const AdminRoutes = () => {
    return (
        <Routes>
            <Route element={<ErrorBoundary FallbackComponent={Fallback}><Outlet/></ErrorBoundary>}>
                <Route path={AdminRoutePath.dashboard} element={<AdminDashboardPage/>}/>
                <Route path={AdminRoutePath.productionSaga} element={<ProductionSagaPage/>}/>
            </Route>
        </Routes>
    )
}

const Fallback: React.FC<FallbackProps> = (props) => {

    if (axios.isAxiosError(props.error)) {
        if (props.error.response?.status === 404) {
            return <>Not found</>;
        }
        if (props.error.response?.status === 500) {
            return <>Server error</>;
        }
    }

    return <>Error</>;
};

import React, {FC} from "react";

export type CornerProfile = {
    name: string;
}

export type Rabbet = {
    name: string;
}

export type Profile = {
    id?: string;
    name?: string;
    cornerProfileInside?: CornerProfile | null;
    rabbet?: Rabbet | null;
    cornerProfileOutside?: CornerProfile | null;
    svgPreview?: string | null;
}

export interface ProfileDisplayNameProps {
    profile: Profile;
}

export const ProfileDisplayName: FC<ProfileDisplayNameProps> = ({profile}) => {

    return <div data-profile-id={profile.id}>

        {(profile.cornerProfileInside?.name &&
            <div>{profile.cornerProfileInside.name}</div>
        ) || <div className="text-muted">Geen hoekprofiel binnen</div>}

        {profile.rabbet && <>
            {profile.rabbet.name}
        </> || <div className="text-muted">Geen sponning</div>}

        {(profile.cornerProfileOutside?.name &&
            <div>{profile.cornerProfileOutside?.name}</div>
        ) || <div className="text-muted">Geen hoekprofiel buiten</div>}
    </div>
}
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {toast} from 'react-hot-toast';
import {useEkosietMutation} from "../../hooks/useEkosietMutation";
import {
	CreateImportCompartmentLayoutMappingCommand,
	ImportCompartmentLayoutMappingRepresentation,
	ImportProfileMappingRepresentation,
} from "../../../modules/api-client/generated";
import {NotFound, ValidationFailed} from "../../../modules/api-client/Responses";
import ApiClient from "../../../modules/api-client/ApiClient";
import {AxiosError} from "axios";
import {isNotEmpty} from "../../../../_metronic/helpers";

export const useGetImportCompartmentLayoutMappings = (enabled: boolean = true) => {
	const response = useQuery<Array<ImportCompartmentLayoutMappingRepresentation>, AxiosError, Array<ImportCompartmentLayoutMappingRepresentation>>(
		['aclImportMappingsCompartmentLayoutGet'],
		() => {
			return ApiClient.Acl.ImportMapping.aclImportMappingCompartmentLayoutsGet().then((res) => res.data);
		},
		{
			useErrorBoundary: true,
			enabled: enabled,
		}
	);

	if (response.error) {
		var error = response.error;
		toast.error(error?.message);
	}

	return response;
};


export const useGetImportCompartmentLayoutMapping = (id: string | undefined, enabled: boolean = true) => {
	const response = useQuery<ImportCompartmentLayoutMappingRepresentation, AxiosError, ImportCompartmentLayoutMappingRepresentation>(
		['aclImportCompartmentLayoutIdGet', id],
		() => {
			return ApiClient.Acl.ImportMapping.aclImportMappingCompartmentLayoutImportCompartmentLayoutMappingIdGet(id!).then((res) => res.data);
		},
		{
			useErrorBoundary: true,
			enabled: enabled && isNotEmpty(id),
		}
	);

	if (response.error) {
		var error = response.error;
		toast.error(error?.message);
	}

	return response;
};


export const usePostCompartmentLayoutMapping = () => {
	const queryClient = useQueryClient();

	return useEkosietMutation<string, ValidationFailed, CreateImportCompartmentLayoutMappingCommand>(
		(command) => {
			return ApiClient.Acl.ImportMapping.aclImportMappingCompartmentLayoutPost(undefined, command).then((response) => response.data);
		},
		{
			onSuccess: (data, variables) => {
				queryClient.invalidateQueries(['aclImportMappingsCompartmentLayoutGet']);
			},
			onError: () => {
            	toast.error("Koppeling mislukt!");
			},
		}
	);
};

export const useDeleteCompartmentLayoutMapping = () => {
	const queryClient = useQueryClient();
	return useEkosietMutation<void, NotFound, string>(
		(importProfileMappingId) => {
			return ApiClient.Acl.ImportMapping.aclImportMappingCompartmentLayoutImportCompartmentLayoutMappingIdDelete(importProfileMappingId).then((response: any) => response.data);
		},
		{
			onSuccess: (data, variables) => {
				queryClient.invalidateQueries(['aclImportMappingsCompartmentLayoutGet']);

				toast.success(`Koppeling successvol verwijderd`);
			},
			onError: () => {
				toast.error('Koppeling verwijderen mislukt');
			},
		}
	);
};

import React from "react";
import {PageTitle} from "../../../../../_metronic/layout/core";
import {ProductionItemOperations} from "../../../../modules/api-client/generated";
import {ProductionOperation} from "../components/ProductionOperation";
import {stationLookup} from "../models/stations";

const SawMaster: React.FC = () => {
    var station = stationLookup.get(ProductionItemOperations.DorpelProfielGezaagd);
    if (!station) {
        throw new Error(`Station not found`);
    }
    return (
        <>
            <PageTitle
                color={station.color}
                breadcrumbs={[]}
            >
                {station.title}
            </PageTitle>

            <ProductionOperation scope={[`DorpelProfielGezaagd:dpg-0`,`InDorpelBatch:idb-1`]} operation={ProductionItemOperations.DorpelProfielGezaagd} />
        </>
    )
}

export default SawMaster

import React from "react";
import {Navigate, Outlet, Route, Routes} from "react-router-dom";
import {ErrorBoundary, FallbackProps} from "react-error-boundary";
import axios from "axios";
import {BackofficeRoutePath} from "../../RoutePath";
import InvoiceList from "./pages/InvoiceList";

export const FmRoutePath = {

    invoiceList: 'invoices',    
        
    link(path:string){
        return BackofficeRoutePath.fm+path;
    }
};

const FmRoutes: React.FC = () => {

    return (
        <Routes>
            <Route element={<ErrorBoundary FallbackComponent={FmFallback}><Outlet/></ErrorBoundary>}>
                <Route path={FmRoutePath.invoiceList} element={<InvoiceList />}/>
            </Route>
            <Route index element={<Navigate to={FmRoutePath.invoiceList}/>}/>
        </Routes>
    );
}

const FmFallback: React.FC<FallbackProps> = (props) => {

    if (axios.isAxiosError(props.error)) {
        if (props.error.response?.status === 404) {
            return <>Not found</>;
        }
        if (props.error.response?.status === 500) {
            return <>Server error</>;
        }
    }

    return <>Error</>;
};

export default FmRoutes;
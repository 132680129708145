import * as Yup from 'yup'
import { DeliveryConditions } from '../../../../../../modules/api-client/generated'

const newOrderSchemas = [
    Yup.object(),
    Yup.object(),

    Yup.object({
        contactPerson: Yup.object().shape({
            firstName: Yup.string()
                .required('Voornaam is verplicht.')
                .max(200, 'Maximaal 200 karakters.')
            ,

            lastName: Yup.string()
                .required('Achternaam is verplicht.')
                .max(200, 'Maximaal 200 karakters.')
            ,

            email: Yup.string()
                .required('E-mail is verplicht.')
                .email('Geen geldig e-mail')
                .max(255, 'Maximaal 255 karakters.')
            ,

            telephone: Yup.string()
                .max(25, 'Maximaal 25 karakters.')
        })
    }),

    Yup.object({
        expedition: Yup.object({

            reference: Yup.object().shape({
                reference: Yup.string().required('Referentie is verplicht!').max(150, 'Maximaal 150 karakters.')
            }),

            contactPerson: Yup.object().when("deliveryCondition", {
                is: (type: DeliveryConditions) => {                    
                    return type === DeliveryConditions.Bezorging;
                },
                then: Yup.object().shape({
                firstName: Yup.string()
                    .required('Voornaam is verplicht.')
                    .max(200, 'Maximaal 200 karakters.')
                ,

                lastName: Yup.string()
                    .required('Achternaam is verplicht.')
                    .max(200, 'Maximaal 200 karakters.')
                ,

                email: Yup.string()
                    .required('E-mail is verplicht.')
                    .email('Geen geldig e-mail')
                    .max(255, 'Maximaal 255 karakters.')
                ,

                telephone: Yup.string()
                    .max(25, 'Maximaal 25 karakters.')
            })})
            ,
            deliveryAddress: Yup.object().when("deliveryCondition", {
                is: (type: DeliveryConditions) => {                    
                    return type === DeliveryConditions.Bezorging;
                },
                then: Yup.object().shape({  
                        street: Yup.string()
                            .required('Straat is verplicht.')
                            .max(200, 'Maximaal 200 karakters.')
                        ,

                        houseNumber: Yup.string()
                            .required('Huisnummer is verplicht.')
                            .matches(
                                /^[0-9]*$/,
                                'Alleen cijfers zijn toegestaan.'
                            ).max(5, 'Maximaal 5 karakters.')
                        ,

                        houseNumberAddition: Yup.string().nullable().max(4, 'Maximaal 4 karakters.')
                        ,

                        postalCode: Yup.string()
                            .required("Postcode is verplicht.")
                            .max(6, 'Maximaal 6 karakters.')
                            .matches(/^[1-9][0-9]{3}?(?!sa|sd|ss)[a-z]{2}$/i,
                                'Postcode moet bestaan uit 4 cijfers en twee letters zonder spatie (voorbeeld 1234AB)'
                            )
                        ,

                        city: Yup.string()
                            .required("Stad is verplicht.")
                            .max(100, 'Maximaal 100 karakters.')
                        ,

                        country: Yup.string()
                            .required('Land is verplicht.')
                            .max(60, 'Maximaal 60 karakters.')
                    })
            })
        })
    }),

    Yup.object({
        remark: Yup.string().max(1500, 'Er zijn meer dan 1500 karakters ingevuld.')
    }),
]

export { newOrderSchemas }

import React from 'react';
import {useQuery} from '@tanstack/react-query';
import ApiClient from '../../../../modules/api-client/ApiClient';
import {EkoTable, TableHeader} from '../../../../shared/components/table';
import ProfilePreviewSvg from '../../../../shared/components/ProfilePreviewSvg';
import BooleanBadge from '../../../../shared/components/BooleanBadge';
import ActiveBadge from '../../../../shared/components/ActiveBadge';
import Loading from '../../../../shared/components/Loading';
import Error from '../../../../shared/components/Error';
import { AxiosResponse } from 'axios';
import {ProfileSearchResult} from "../../../../modules/api-client/generated";

export const RabbetProfileList: React.FC<{rabbetId: string}> = ({rabbetId}) => {

	const {data: profileList, isInitialLoading, isError} = useQuery([], () => {
		return ApiClient.Pim.Profile.listProfiles([], [
			`RabbetId:${rabbetId}`,
		], undefined, undefined, undefined).then((x: AxiosResponse<ProfileSearchResult>) => x.data);
	});

	if (isInitialLoading && !profileList) return <Loading />;

	if (isError) return <Error />;

	return <>
		<EkoTable>
			<TableHeader>
				<th></th>
				<th>Naam</th>
				<th>Programma</th>
				<th></th>
			</TableHeader>
			
			<tbody>
			{(!profileList || profileList.profiles.length === 0) && (
				<tr>
					<td colSpan={5} className='text-center'>
						Geen gegevens
					</td>
				</tr>
			)}

			{profileList && profileList.profiles.map((profile) => (
				<tr key={profile.id}>

					<td className='fit-content user-select-none'>
						<div style={{height: '80px', width: '80px'}}>
							<ProfilePreviewSvg profileSvg={profile.svgPreview} />
						</div>
					</td>
					<td>
						<div>
							{profile.name}
						</div>
						<div>
							<small className='text-muted'>
								{profile.code} | {profile.id}
							</small>
						</div>
					</td>
					<td className='fit-content text-center'>
						<BooleanBadge value={profile.hasProgram}
									  falseClass={profile.active ? 'bg-danger' : 'bg-warning'} />
					</td>
					<td className='fit-content text-center'>
						<ActiveBadge value={profile} />
					</td>
				</tr>
			))}
			</tbody>
		</EkoTable>
	</>;

};
import React from 'react';
import {createSearchParams, Navigate, Outlet, Route, Routes, To} from 'react-router-dom';
import {ErrorBoundary, FallbackProps} from 'react-error-boundary';
import axios from 'axios';
import OrderList from './pages/OrderList';
import {EditOrder} from './pages/EditOrder';
import {AddOrderWizard} from './orders/new-order/components/AddOrderWizard';
import {StartOrder} from './pages/StartOrder';
import {BackofficeRoutePath} from '../../RoutePath';
import {OrderLineConfigurator} from './pages/OrderLineConfigurator';

export const SmRoutePath = {

    orderList: 'orders',
    orderEdit: 'orders/:id',
    orderEditConfiguratorLine: 'orders/:id/configurator',
    orderAddWithId: 'orders/add/:id',
    orderAddWithIdConfigurator: 'orders/add/:id/configurator',
    orderAddWithCustomerId: 'orders/customer/:customerId/add',

    orderAddWithIdLink: (id: string) => {
        return SmRoutePath.orderAddWithId.replace(':id', id);
    },

    // orderAddWithIdConfiguratorLink: (id: string) => {
    //     return SmRoutePath.orderAddWithIdConfigurator.replace(':id', id);
    // },

    orderAddWithIdConfiguratorLink: (params: {
        orderId: string;
        customerId: string;
        pricelistId: string;
        orderLineId?: string;
        catalogItemId?: string;
        quantity?: string;
        remark?: string;
        merk?: string;
    }): To => {

        return {
            pathname: SmRoutePath.link(SmRoutePath.orderAddWithIdConfigurator.replace(':id', params.orderId)),
            search: createSearchParams({
                pl: params.pricelistId,
                ...(params.customerId ? {customerId: params.customerId} : {}),
                ...(params.orderLineId ? {orderLineId: params.orderLineId} : {}),
                ...(params.catalogItemId ? {configurationId: params.catalogItemId} : {}),
                ...(params.quantity ? {q: params.quantity} : {}),
                ...(params.remark ? {remark: params.remark} : {}),
                ...(params.merk ? {merk: params.merk} : {})
            }).toString()
        }
    },

    orderAddWithCustomerIdLink: (id: string) => {
        return SmRoutePath.orderAddWithCustomerId.replace(':customerId', id);
    },

    orderEditLink: (id: string) => {
        return SmRoutePath.orderEdit.replace(':id', id);
    },

    // orderEditConfiguratorLineLink: (id: string) => {
    // 	return SmRoutePath.orderEditConfiguratorLine.replace(':id', id);
    // },

    orderEditConfiguratorLineLink: (params: {
        orderId: string;
        customerId: string;
        pricelistId: string;
        orderLineId?: string;
        catalogItemId?: string;
        quantity?: string;
        remark?: string;
        merk?: string;
    }): To => {

        return {
            pathname: SmRoutePath.link(SmRoutePath.orderEditConfiguratorLine.replace(':id', params.orderId)),
            search: createSearchParams({
                pl: params.pricelistId,
                ...(params.customerId ? {customerId: params.customerId} : {}),
                ...(params.orderLineId ? {orderLineId: params.orderLineId} : {}),
                ...(params.catalogItemId ? {configurationId: params.catalogItemId} : {}),
                ...(params.quantity ? {q: params.quantity} : {}),
                ...(params.remark ? {remark: params.remark} : {}),
                ...(params.merk ? {merk: params.merk} : {})
            }).toString()
        }
    },

    link(path: string) {
        return BackofficeRoutePath.sm + path;
    },
};
const SmRoutes: React.FC = () => {

    return (
        <Routes>
            <Route element={<ErrorBoundary FallbackComponent={SmFallback}><Outlet/></ErrorBoundary>}>
                <Route path={SmRoutePath.orderList} element={<OrderList/>}/>
                <Route path={SmRoutePath.orderAddWithId} element={<><AddOrderWizard/><Outlet/></>}>
                    <Route path='configurator/*' element={<OrderLineConfigurator isOrderEdit={false}/>}/>
                </Route>
                <Route path={SmRoutePath.orderAddWithCustomerId} element={<StartOrder/>}/>
                <Route path={SmRoutePath.orderEdit} element={<><EditOrder/><Outlet/></>}>
                    <Route path='configurator/*' element={<OrderLineConfigurator isOrderEdit={true}/>}/>
                </Route>
            </Route>
            <Route index element={<Navigate to={SmRoutePath.orderList}/>}/>
        </Routes>
    );
};

const SmFallback: React.FC<FallbackProps> = (props) => {

    if (axios.isAxiosError(props.error)) {
        if (props.error.response?.status === 404) {
            return <>Not found</>;
        }
        if (props.error.response?.status === 500) {
            return <>Server error</>;
        }
    }

    return <>Error</>;
};

export default SmRoutes;
import React from 'react';
import {useQuery} from '@tanstack/react-query';
import ApiClient from '../../../../modules/api-client/ApiClient';
import {
    EmployeeProductionStatRow, ProductionOperationRepresentation,
} from '../../../../modules/api-client/generated';
import Loading from '../../../../shared/components/Loading';
import {Chart} from "react-chartjs-2";
import {DateTime} from "luxon";
import {useParams} from "react-router-dom";
import {useProductionOperations} from "../hooks/use-list-production-operations";

interface DailyOperationProps {
    fromDate: string;
    toDate: string;
    showCount: boolean;
}

export const DailyOperationBarChart: React.FC<DailyOperationProps> = ({fromDate, toDate, showCount}) => {
    const { employeeId } = useParams<{ employeeId: string }>();
    const {
        isInitialLoading,
        data: operationsData
    } = useQuery<EmployeeProductionStatRow[]>(
        ['employeeProductionStatRow', fromDate, toDate, employeeId],
        () => ApiClient.Pm.Reporting.employeeProductionStats(employeeId!, fromDate, toDate).then((res) => res.data)
    );

    const {
        isInitialLoading: isInitialLoadingProductionOperations,
        data: productionOperations
    } = useProductionOperations();

    const startWithLowerCase = (str : string) => {
        return str.charAt(0).toLowerCase() + str.slice(1);
    }

    const getDurationInHours = (durationString : string) => {
        const duration = durationString.split(':').map(Number);
        return duration[0] + duration[1]*(1/60) + duration[2]*(1/3600);
    }

    const getTitle = () => {
        if(showCount) {
            return 'Aantal uitgevoerde handelingen';
        }
        return 'Totaal aantal uren';
    }

    if (!operationsData || !productionOperations) return <Loading/>
    if (isInitialLoading || isInitialLoadingProductionOperations) return <Loading/>

    const barChartOptions:any = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'right' as const,
            },
            title: {
                display: true,
                text: getTitle(),
            },
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
                type: 'linear',
                ticks: {
                    callback: function(value: number) {
                        const hours = Math.floor(value);
                        const minutes = Math.round((value - hours) * 60);
                        if(showCount) {
                            return value;
                        }
                        return `${hours}h ${minutes}m`;
                    }
                }
            },
        },
    };

    const getColor = (operation : ProductionOperationRepresentation) => {
        if(operation.color) {
            return operation.color;
        }
        return '#70ab2a';
    }

    const barChartData = {
        labels: operationsData.map((stat) => DateTime.fromFormat(stat.date, 'yyyy-MM-dd').toFormat('dd-MM-yyyy')),
        datasets: productionOperations.map((operation) => ({
            label: operation.title,
            data: operationsData.map((stat) => {
                const count = stat.productionByOperation[startWithLowerCase(operation.code)] || 0;
                return showCount ? count : getDurationInHours(operation.averageDuration) * count;
            }),
            backgroundColor: getColor(operation),
        })),
    };


    return (
        <>
            <div className="chart-container position-relative" style={{height:'40vh'}}>
                <Chart type='bar' options={barChartOptions} data={barChartData} />
            </div>
        </>
    );
};
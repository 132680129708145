import React, { useCallback, useRef } from 'react';
import {useQuery} from '@tanstack/react-query';
import ApiClient from '../../../../../modules/api-client/ApiClient';
import {EkoCard, EkoCardBody} from '../../../../../../_metronic/helpers';
import {TableHeader, EkoTable} from '../../../../../shared/components/table';
import {EkoCardHeader } from '../../../../../shared/components/card';
import {ContactFormListItem} from '../../../../../modules/api-client/generated';
import Loading from '../../../../../shared/components/Loading';
import { formatDate } from '../../../../../shared/components/date';
import Error from "../../../../../shared/components/Error";
import { ConfirmDialog } from '../../../../../shared/components/ConfirmDialog';
import { useContactFormDeleteMutation } from '../../hooks/useContactFormDeleteMutation';
import { Link } from 'react-router-dom';

export const ContactFormsPage: React.FC = () => {
	const {isInitialLoading, data: list, isError} = useQuery<ContactFormListItem[]>(['contactFormsListGet'], () => ApiClient.Crm.ContactForm.getListOfContactForms().then((res) => res.data));
	
	if (isInitialLoading) return <Loading />;

	if (isError) return <Error />;

	return (
		<>
			<EkoCard>
				<EkoCardHeader title={'Ingevulde contactformulieren'} >
				</EkoCardHeader>
				<EkoCardBody>
					<EkoTable>
						<TableHeader>
							<th>Naam</th>
							<th>Afgehandeld</th>
							<th>E-mailadres</th>
							<th>Telefoonnummer</th>
							<th>Bedrijfsnaam</th>
							<th style={{width: "12em"}}>Aangemaakt op</th>
							<th>Opgevolgd door</th>
							<th style={{width: "12em"}}>Opgevolgd op</th>
							<th></th>
						</TableHeader>
						<tbody>
							{(!list || list.length === 0) && (
								<tr>
									<td colSpan={5} className="text-center">
										Geen gegevens
									</td>
								</tr>
							)}
							{list &&
								list.map((data) => (
									<tr key={data.id}> 
										<td><Link to={data.id!} relative='path'>{data.name}</Link></td>
										<td>{data.followedUpAt !== null 
											? <span className="badge badge-success">Ja</span>
											: <span className="badge text-bg-light">Nee</span>}</td>
										<td>{data.email}</td>
										<td>{data.phoneNumber}</td>
										<td>{data.companyName}</td>
										<td>{formatDate(data.createdAt ?? "", "dd-MM-yyyy HH:mm")}</td>
										<td>{data.followedUpBy}</td>
										<td>{formatDate(data.followedUpAt ?? "", "dd-MM-yyyy HH:mm")}</td>
										<td className="fit-content">
                            				<DeleteButton id={data.id!}/>
                        				</td>
									</tr>
								))}
						</tbody>
					</EkoTable>
				</EkoCardBody>
			</EkoCard>
		</>
	);
};

const DeleteButton: React.FC<{id:string}>= ({id}) => {
    const openDeleteDialog = useRef<(show: boolean) => void>();

    const deleteMutation = useContactFormDeleteMutation(id);
    const deleteCallback = useCallback(async () => {
        await deleteMutation.mutateAsync(id!);
    }, [deleteMutation, id]);
    return (
        <>
            <button type="button" className="btn btn-light btn-sm" disabled={false}
                    onClick={() => openDeleteDialog.current?.call(this, true)}><i className={'fas fa-trash'}></i>
            </button>
            <ConfirmDialog show={openDeleteDialog}
                           onConfirm={deleteCallback}
                           dialogStyle="danger"
                           title="Verwijderen"
                           message="Wil je doorgaan?"
                           confirmText="Definitief verwijderen"

            />
        </>
    )
}
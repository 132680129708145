import React, {ReactElement, useEffect, useRef, useState} from "react";
import {PageTitle} from "../../../../../_metronic/layout/core";
import {useQuery} from "@tanstack/react-query";
import {
    ProductionDashboardStats,
    Station, Task
} from "../../../../modules/api-client/generated";
import ApiClient from "../../../../modules/api-client/ApiClient";
import Loading from "../../../../shared/components/Loading";
import {EkoTable, TableHeader} from "../../../../shared/components/table";
import {dateToString, formatDate, weekNumber} from "../../../../shared/components/date";
import CountUp from "react-countup";
import {getCSSVariableValue} from "../../../../../_metronic/assets/ts/_utils";
import {DateTime} from 'luxon';
import {ProductionItemModal} from "../components/ProductionItemModal";


const PlanningDashboard: React.FC = () => {

    const [showEditModal, setShowEditModal] = useState<boolean>(false)
    const [modalTitle, setModalTitle] = useState<string | undefined | null>(null);
    const [modalComponent, setModalComponent] = useState<ReactElement | undefined | null>(null);

    const defaultFrom = DateTime.now().minus({days: 5}).toFormat("yyyy-MM-dd")
    const defaultTo = DateTime.now().plus({days: 30}).toFormat("yyyy-MM-dd")
    const [startDay, setFromDate] = useState<string>(defaultFrom)
    const [endDay, setToDate] = useState<string>(defaultTo)


    const onChangeFromDate = (date: string) => {
        const newDate = DateTime.fromFormat(date, "yyyy-MM-dd").toFormat("yyyy-MM-dd");
        setFromDate(newDate);
    }
    const onChangeToDate = (date: string) => {
        const newDate = DateTime.fromFormat(date, "yyyy-MM-dd").toFormat("yyyy-MM-dd");
        setToDate(newDate);
    }

    const setDateRange = (currentWeek: number, year: number = DateTime.now().year) => {
        const dt = DateTime.fromObject({
            weekYear: year,
            weekNumber: currentWeek
        });
        setFromDate(dt.startOf('week').toFormat("yyyy-MM-dd"));
        setToDate(dt.endOf('week').toFormat("yyyy-MM-dd"));
    }
    const setDay = (day: string) => {
        const dt = DateTime.fromFormat(day, "yyyy-MM-dd");
        setFromDate(dt.toFormat("yyyy-MM-dd"));
        setToDate(dt.plus({day: 1}).toFormat("yyyy-MM-dd"));
    }

    const resetRange = () => {
        setFromDate(defaultFrom);
        setToDate(defaultTo);
    }

    const {
        isInitialLoading,
        data,
    } = useQuery<ProductionDashboardStats, TypeError, ProductionDashboardStats>(
        ['productionDashboardStats', startDay, endDay],
        () => {
            return ApiClient.Pm.Reporting.productionDashboardStats(startDay, endDay, undefined).then((res) => res.data);
        },
        {refetchInterval: 3000}
    );

    if (!data) return <Loading/>
    let currentWeek = 0;
    if (isInitialLoading) return <Loading/>
    return (
        <>
            <PageTitle toolbar={<>
                <div className={'d-flex align-items-center'}>
                    <div className={'me-2'}>
                        <input type="date" id="start" className={'form-control'} name="startDay" value={startDay}
                               onChange={(e) => onChangeFromDate(e.target.value)}/>
                    </div>
                    <div className={'me-2'}>
                        <input type="date" id="end" className={'form-control'} name="endDay" value={endDay}
                               min={startDay}
                               onChange={(e) => onChangeToDate(e.target.value)}/>
                    </div>
                    <div>
                        <button className={'btn btn-sm btn-light'} onClick={() => resetRange()}>Reset</button>
                    </div>
                </div>
            </>} breadcrumbs={[]}>Productie planning
            </PageTitle>

            <ProductionItemModal show={showEditModal} handleClose={() => setShowEditModal(false)}
                                 title={modalTitle}
                                 size={'lg'}
                                 fullscreen={'false'}
                                 component={modalComponent}
            />

            <EkoTable classNameDivWrapper={'tableFixHead'} condensed={true} className={'table-light'}>
                <colgroup>
                    <col width={'3%'}/>
                    <col width={'2%'}/>
                    <col width={'5%'}/>
                    <col width={'5%'}/>
                    <col width={'5%'}/>
                    <col width={'5%'}/>
                    <col width={'5%'}/>
                    <col width={'5%'}/>
                    <col width={'5%'}/>
                    <col width={'4%'}/>
                    <col width={'4%'}/>
                    <col width={'4%'}/>
                    <col width={'4%'}/>
                    <col width={'4%'}/>
                </colgroup>
                <TableHeader className={'bg-light'}>
                    <th className={'bg-light'}>
                    </th>
                    <th className={'border-end border-primary border-bottom-0 border-3 bg-light'}>
                        <StationHeader station={data.dorpels}
                                       setModalComponent={setModalComponent}
                                       setModalTitle={setModalTitle}
                                       setShowEditModal={setShowEditModal}/>
                    </th>
                    <th className={'bg-light'}>

                        <TaskHeader task={data.sawMaster.tasks.latMaken!}
                                    setModalComponent={setModalComponent}
                                    setModalTitle={setModalTitle}
                                    setShowEditModal={setShowEditModal}/>
                        <TaskHeader task={data.sawMaster.tasks.dorpelProfielZagen!}
                                    setModalComponent={setModalComponent}
                                    setModalTitle={setModalTitle}
                                    setShowEditModal={setShowEditModal}/>

                    </th>
                    {/*<th className={'border-end border-secondary border-bottom-0 border-3 border-dotted bg-light'}>*/}
                    {/*   */}
                    {/*</th>*/}
                    <th className={'border-end border-primary border-bottom-0 border-3 bg-light'}>
                        <StationHeader station={data.sawMaster!}
                                       setModalComponent={setModalComponent}
                                       setModalTitle={setModalTitle}
                                       setShowEditModal={setShowEditModal}/>
                    </th>
                    <th className={'bg-light'}>
                        <div>

                            <TaskHeader task={data.wm50.tasks.neutenMaken!}
                                        setModalComponent={setModalComponent}
                                        setModalTitle={setModalTitle}
                                        setShowEditModal={setShowEditModal}/>
                            <TaskHeader task={data.wm50.tasks.dorpelBewerken!}
                                        setModalComponent={setModalComponent}
                                        setModalTitle={setModalTitle}
                                        setShowEditModal={setShowEditModal}/>
                        </div>

                    </th>
                    {/*<th className={'border-end border-secondary border-bottom-0 border-3 border-dotted bg-light'}>*/}
                    {/**/}
                    {/*</th>*/}
                    <th className={'border-end border-primary border-bottom-0 border-3 bg-light'}>
                        <StationHeader station={data.wm50!}
                                       setModalComponent={setModalComponent}
                                       setModalTitle={setModalTitle}
                                       setShowEditModal={setShowEditModal}/>
                    </th>
                    <th className={'bg-light'}>
                        <StationHeader station={data.neutMontage!}
                                       setModalComponent={setModalComponent}
                                       setModalTitle={setModalTitle}
                                       setShowEditModal={setShowEditModal}/>
                    </th>
                    <th className={'bg-light'}>
                        <StationHeader station={data.sluitpot!}
                                       setModalComponent={setModalComponent}
                                       setModalTitle={setModalTitle}
                                       setShowEditModal={setShowEditModal}/>
                    </th>
                    <th className={'bg-light'}>
                        <StationHeader station={data.kopisolator!}
                                       setModalComponent={setModalComponent}
                                       setModalTitle={setModalTitle}
                                       setShowEditModal={setShowEditModal}/>
                    </th>
                    <th className={'bg-light'}>
                        <StationHeader station={data.manchette!}
                                       setModalComponent={setModalComponent}
                                       setModalTitle={setModalTitle}
                                       setShowEditModal={setShowEditModal}/>
                    </th>
                    <th className={'bg-light'}>
                        <StationHeader station={data.latten!}
                                       setModalComponent={setModalComponent}
                                       setModalTitle={setModalTitle}
                                       setShowEditModal={setShowEditModal}/>
                    </th>
                    <th className={'bg-light'}>
                        <StationHeader station={data.inpak!}
                                       setModalComponent={setModalComponent}
                                       setModalTitle={setModalTitle}
                                       setShowEditModal={setShowEditModal}/>
                    </th>
                    <th className={'bg-light'}>
                        <StationHeader station={data.hefschuif!}
                                       setModalComponent={setModalComponent}
                                       setModalTitle={setModalTitle}
                                       setShowEditModal={setShowEditModal}/>
                    </th>
                    <th className={'bg-light'}>
                        <StationHeader station={data.specials!}
                                       setModalComponent={setModalComponent}
                                       setModalTitle={setModalTitle}
                                       setShowEditModal={setShowEditModal}/>
                    </th>
                </TableHeader>
                <tbody>
                {data.rows?.map((row) => {

                    const newWeek = weekNumber(row.date) !== currentWeek;
                    currentWeek = weekNumber(row.date);

                    return (<>
                            {newWeek &&
                                <tr className={`fw-bold fs-6}`}>
                                    <th colSpan={13}>
                                        <span style={{cursor: 'pointer'}}
                                              onClick={() => setDateRange(DateTime.fromFormat(row.date, "yyyy-MM-dd").weekNumber)}><i
                                            className={'fas fa-calendar'}></i> Week {currentWeek}
                                    </span></th>
                                </tr>
                            }
                            <tr key={formatDate(row.date)} className={`text-center}`}>
                                <td className={'text-start text-nowrap'} style={{cursor: 'pointer'}}
                                    onClick={() => setDay(row.date)}>
                                    {dateToString(row.date, {
                                        weekday: 'short',
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric',
                                        timeZone: 'UTC'
                                    })}
                                </td>
                                <td className={'border-end border-primary border-bottom-0 border-3'}>
                                    <StationUtilization station={row.stations.dorpels!}
                                                        mode={'sills'}
                                                        setModalComponent={setModalComponent}
                                                        setModalTitle={setModalTitle}
                                                        setShowEditModal={setShowEditModal}/>
                                </td>
                                <td>
                                    <div className={'d-flex'}>

                                        <TaskUtilization task={row.stations.sawMaster!.tasks.latMaken!}
                                                         setModalComponent={setModalComponent}
                                                         setModalTitle={setModalTitle}
                                                         setShowEditModal={setShowEditModal}/>
                                        <TaskUtilization task={row.stations.sawMaster!.tasks.dorpelProfielZagen!}
                                                         setModalComponent={setModalComponent}
                                                         setModalTitle={setModalTitle}
                                                         setShowEditModal={setShowEditModal}/>
                                    </div>

                                </td>

                                <td className={'border-end border-primary border-bottom-0 border-3'}>

                                    <StationUtilization station={row.stations.sawMaster!}
                                                        mode={'hours'}
                                                        setModalComponent={setModalComponent}
                                                        setModalTitle={setModalTitle}
                                                        setShowEditModal={setShowEditModal}/>
                                </td>
                                <td>
                                    <div className={'d-flex'}>

                                        <TaskUtilization task={row.stations.wm50!.tasks.neutenMaken!}
                                                         setModalComponent={setModalComponent}
                                                         setModalTitle={setModalTitle}
                                                         setShowEditModal={setShowEditModal}/>
                                        <TaskUtilization task={row.stations.wm50!.tasks.dorpelBewerken!}
                                                         setModalComponent={setModalComponent}
                                                         setModalTitle={setModalTitle}
                                                         setShowEditModal={setShowEditModal}/>
                                    </div>
                                </td>

                                <td className={'border-end border-primary border-bottom-0 border-3'}>
                                    <StationUtilization station={row.stations.wm50!}
                                                        mode={'hours'}
                                                        setModalComponent={setModalComponent}
                                                        setModalTitle={setModalTitle}
                                                        setShowEditModal={setShowEditModal}/>
                                </td>
                                <td>
                                    <StationUtilization station={row.stations.neutMontage!}
                                                        setModalComponent={setModalComponent}
                                                        setModalTitle={setModalTitle}
                                                        setShowEditModal={setShowEditModal}/>

                                </td>
                                <td>
                                    <StationUtilization station={row.stations.sluitpot!}
                                                        setModalComponent={setModalComponent}
                                                        setModalTitle={setModalTitle}
                                                        setShowEditModal={setShowEditModal}/>
                                </td>

                                <td>
                                    <StationUtilization station={row.stations.kopisolator!}
                                                        setModalComponent={setModalComponent}
                                                        setModalTitle={setModalTitle}
                                                        setShowEditModal={setShowEditModal}/>
                                </td>
                                <td>
                                    <StationUtilization station={row.stations.manchette!}
                                                        setModalComponent={setModalComponent}
                                                        setModalTitle={setModalTitle}
                                                        setShowEditModal={setShowEditModal}/>
                                </td>
                                <td>
                                    <StationUtilization station={row.stations.latten!}
                                                        mode={'hours'}
                                                        setModalComponent={setModalComponent}
                                                        setModalTitle={setModalTitle}
                                                        setShowEditModal={setShowEditModal}/>
                                </td>

                                <td>
                                    <StationUtilization station={row.stations.inpak!}
                                                        setModalComponent={setModalComponent}
                                                        setModalTitle={setModalTitle}
                                                        setShowEditModal={setShowEditModal}/>
                                </td>
                                <td>
                                    <StationUtilization station={row.stations.hefschuif!}
                                                        setModalComponent={setModalComponent}
                                                        setModalTitle={setModalTitle}
                                                        setShowEditModal={setShowEditModal}/>
                                </td>
                                <td>
                                    <StationUtilization station={row.stations.specials!}
                                                        setModalComponent={setModalComponent}
                                                        setModalTitle={setModalTitle}
                                                        setShowEditModal={setShowEditModal}/>
                                </td>
                            </tr>
                        </>
                    )
                })
                }
                </tbody>
            </EkoTable>
        </>
    )
}
export default PlanningDashboard

interface StationUtilizationProps {
    station: Station;
    mode?: 'hours' | 'sills' | 'tasks';
    setModalComponent: React.Dispatch<React.SetStateAction<ReactElement | undefined | null>>,
    setModalTitle: React.Dispatch<React.SetStateAction<string | undefined | null>>,
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>,
}

const StationUtilization: React.FC<StationUtilizationProps> = ({
                                                                   station,
                                                                   mode = 'hours',
                                                                   setModalTitle,
                                                                   setModalComponent,
                                                                   setShowEditModal

                                                               }) => {
    let bezetting = station.utilization * 100;
    const showDetails = (station: Station) => {
        setModalComponent(
            <>
                {Object.entries(station.tasks).map(([key, task]) => (
                    <div key={key}>
                        <h2>{task.metrics.planned} {task.title} op {task.sillMetrics.planned} dorpels</h2>
                    </div>
                ))}
            </>
        )
        setModalTitle('Details')
        setShowEditModal(true)
    };
    return (
        <>
            <div className={'d-flex text-start align-items-center'} onClick={() => showDetails(station)}>
                <div className="progress h-20px bg-secondary bg-opacity-50 w-75px">
                    <div title={`${station.sillMetrics.planned} dorpels ${bezetting.toFixed(2)}%`}
                         className={`progress-bar ${bezetting > 75 ? bezetting > 95 ? 'bg-danger ' : 'bg-warning ' : 'bg-success '}`}
                         role="progressbar" aria-label="Segment one"
                         style={{width: bezetting > 100 ? 100 : bezetting + '%'}}
                         aria-valuenow={bezetting} aria-valuemin={0} aria-valuemax={bezetting}>

                        {mode === 'sills' ? station.sillMetrics.planned : mode === 'tasks' ? station.metrics.planned : station.plannedDuration.toFixed(1) + ' uur'}

                    </div>
                    <div title={`${station.sillMetrics.planned} dorpels`}
                         className={`progress-bar bg-secondary'}`} role="progressbar" aria-label="Segment three"
                         style={{width: bezetting > 100 ? 0 : 100 - bezetting + '%'}} aria-valuenow={100}
                         aria-valuemin={0} aria-valuemax={100}>
                        {mode === 'sills' ? undefined : mode === 'tasks' ? station.metrics.planned : (station.dailyOperatingHours - station.plannedDuration).toFixed(1) + ' uur'}

                    </div>
                </div>
                <span className={'d-flex text-nowrap ms-2'}
                      title={`${bezetting.toFixed(1)}% `}>
                    <small>
                        {bezetting.toFixed(0)}%
                    </small>
                </span>
            </div>
        </>
    )
};

interface TaskUtilizationProps {
    task: Task;
    setModalComponent: React.Dispatch<React.SetStateAction<ReactElement | undefined | null>>,
    setModalTitle: React.Dispatch<React.SetStateAction<string | undefined | null>>,
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>,
}

const TaskUtilization: React.FC<TaskUtilizationProps> = ({
                                                             task,
                                                             setModalTitle,
                                                             setModalComponent,
                                                             setShowEditModal

                                                         }) => {
    const showDetails = (task: Task) => {
        setModalComponent(
            <h2>{task.metrics.planned} {task.title} op {task.sillMetrics.planned} dorpels</h2>
        )
        setModalTitle('Details')
        setShowEditModal(true)
    };
    return (
        <>
            <div className={'d-flex text-start align-items-center'} onClick={() => showDetails(task)}>
                <div className="progress h-20px bg-secondary bg-opacity-50 w-75px">
                    <div title={`${task.sillMetrics.planned} dorpels /  ${task.plannedDuration.substring(0, 5)} uur`}
                         className={`progress-bar bg-secondary'}`} role="progressbar" aria-label="Segment three"
                         style={{width: 100 + '%'}} aria-valuenow={100}
                         aria-valuemin={0} aria-valuemax={100}>
                        {task.plannedDuration.substring(0, 5)}
                    </div>
                </div>
                {/*<span className={'d-flex text-nowrap ms-2'}*/}
                {/*      title={`${bezetting.toFixed(1)}% `}>*/}
                {/*    <small>*/}
                {/*        {bezetting.toFixed(0)}%*/}
                {/*    </small>*/}
                {/*</span>*/}
            </div>
        </>
    )
};

interface StationHeaderProps {
    station: Station;
    setModalComponent: React.Dispatch<React.SetStateAction<ReactElement | undefined | null>>,
    setModalTitle: React.Dispatch<React.SetStateAction<string | undefined | null>>,
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>,
}


const StationHeader: React.FC<StationHeaderProps> = ({
                                                         station,
                                                         setModalTitle,
                                                         setModalComponent,
                                                         setShowEditModal
                                                     }) => {

    let utilization = station.utilization * 100

    const chartRef = useRef<HTMLDivElement | null>(null)
    useEffect(() => {
        refreshChart()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [utilization])

    const refreshChart = () => {
        if (!chartRef.current) {
            return
        }

        setTimeout(() => {
            initChart(60, 9, 145, utilization, chartRef.current)
        }, 10)
    }

    const editMetadata = (station: Station) => {
        setModalComponent(
            <>
                <h2>{station.title}</h2>
                <div>Dagelijks aantal werkuren: {station.dailyOperatingHours} uur</div>
                <div>Totaal aantal werkuren: {station.totalOperatingHours} uur</div>
                <div>Totale tijd om alle taken te voltooien: {station.plannedDuration} uur</div>
                <h3>Handelingen op dit station:</h3>
                {station.tasks && Object.entries(station.tasks).map(([key, task]) => (
                    <div key={key}>
                        <h4>{task.title}</h4>
                        <div>Gemiddelde duur: {task.averageDuration}</div>
                        <div>Gepland: {task.metrics.planned} op {task.sillMetrics.planned} dorpels</div>
                        <div>Gerealiseerd: {task.metrics.realised} op {task.sillMetrics.realised} dorpels</div>
                        <div>Huidige wachtrij
                            (mogelijk): {task.metrics.currentQueue} op {task.sillMetrics.currentQueue} dorpels
                        </div>
                        <div>Totale wachtrij: {task.metrics.dailyQueue} op {task.sillMetrics.dailyQueue} dorpels</div>
                    </div>
                ))}
            </>
        )
        setModalTitle('Meta data')
        setShowEditModal(true)
    };


    return (
        <>
            <div className="fs-6 text-center fw-normal"
                 title={`
                ${(station.totalOperatingHours)} uur
                ${(station.plannedDuration).toFixed(2)} uur
                 `}
            >
                <div
                    id={`kt_card_widget_17_chart`}
                    ref={chartRef}
                    style={{minWidth: 40 + 'px', minHeight: 40 + 'px'}}
                    data-kt-size={40}
                    data-kt-line={9}
                ></div>

                <h3>
                    <CountUp start={Math.max(0, utilization - 10)} end={utilization} suffix={'%'}
                             delay={0} duration={2.25}>
                        {({countUpRef}) => (
                            <div>
                                <span ref={countUpRef}/>
                            </div>
                        )}
                    </CountUp>
                </h3>
                <div className={``} style={{cursor: 'pointer'}}
                     onClick={() => editMetadata(station)}
                >

                    <div className={'text-nowrap text-primary'}>{station.title}</div>
                    <div className={`text-muted fs-8`}
                         title={'Totaal aantal uur om geplande taken te voltooien / Totaal aantal beschikbare werkuren'}>{(station.plannedDuration).toFixed(2)}/{station.totalOperatingHours} uur
                    </div>
                </div>
            </div>
        </>
    )
};


interface TaskHeaderProps {
    task: Task;
    setModalComponent: React.Dispatch<React.SetStateAction<ReactElement | undefined | null>>,
    setModalTitle: React.Dispatch<React.SetStateAction<string | undefined | null>>,
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>,
}

const TaskHeader: React.FC<TaskHeaderProps> = ({
                                                   task,
                                                   setModalTitle,
                                                   setModalComponent,
                                                   setShowEditModal
                                               }) => {

    // const chartRef = useRef<HTMLDivElement | null>(null)
    // useEffect(() => {
    //     refreshChart()
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [task.metrics.planned])
    //
    // const refreshChart = () => {
    //     if (!chartRef.current) {
    //         return
    //     }
    //
    //     setTimeout(() => {
    //         initChart(60, 9, 145, task.metrics.planned, chartRef.current)
    //     }, 10)
    // }

    // const editMetadata = (station: Station) => {
    //     setModalComponent(
    //         <EditStationData station={station} setStationData={setStationData} stations={stations}
    //                          onClose={() => setShowEditModal(false)}/>
    //     )
    //     setModalTitle('Wijzig meta data')
    //     setShowEditModal(true)
    // };


    return (
        <div className="fs-6 text-center fw-normal mt-3" title={`${task.plannedDuration} uur`}>
            <h3>
                <CountUp start={Math.max(0, task.metrics.planned - 10)} end={task.metrics.planned} suffix={''}
                         delay={0} duration={2.25}>
                    {({countUpRef}) => (
                        <div>
                            <span ref={countUpRef}/>
                        </div>
                    )}
                </CountUp>
            </h3>
            <div className={``} style={{cursor: 'pointer'}}>
                <div className={'text-nowrap text-primary'}>{task.title}</div>
                <div className={`text-muted fs-8`}
                     title={'Uren om geplande taken te voltooien'}>{(task.plannedDuration)} uur
                </div>
                <div className={`text-muted fs-8`} title={'Totaal aantal dorpels'}>{task.sillMetrics.planned} dorpels
                </div>
            </div>
        </div>
    )
};

const initChart = function (
    chartSize: number = 70,
    chartLine: number = 11,
    chartRotate: number = 145,
    bezetting: number = 100,
    el: HTMLDivElement | null
) {
    // const el = document.getElementById('kt_card_widget_17_chart')

    if (!el) {
        return
    }


    el.innerHTML = ''

    var options = {
        size: chartSize,
        lineWidth: chartLine,
        rotate: chartRotate,
        //percent:  el.getAttribute('data-kt-percent') ,
    }

    const canvas = document.createElement('canvas')
    const span = document.createElement('span')

    // @ts-ignore
    if (typeof G_vmlCanvasManager !== 'undefined') {
        // @ts-ignore
        G_vmlCanvasManager.initElement(canvas)
    }

    const ctx = canvas.getContext('2d')
    canvas.width = canvas.height = options.size

    el.appendChild(span)
    el.appendChild(canvas)

    // @ts-ignore
    ctx.translate(options.size / 2, options.size / 2) // change center
    // @ts-ignore
    // ctx.rotate((-1 / 2 + options.rotate / 180) * Math.PI) // rotate -90 deg

    //imd = ctx.getImageData(0, 0, 240, 240);
    const radius = (options.size - options.lineWidth) / 2

    const drawCircle = function (color: string, lineWidth: number, start: number, end: number) {
        // start = Math.min(Math.max(0, start || 1), 1)
        end = Math.min(Math.max(0, end || 1), 1)
        if (!ctx) {
            return
        }

        ctx.beginPath()
        ctx.arc(0, 0, radius, Math.PI * 2 * start, Math.PI * 2 * end, false)
        ctx.strokeStyle = color
        ctx.lineCap = 'round' // butt, round or square
        ctx.lineWidth = lineWidth
        ctx.stroke()
    }

    // Init 2
    drawCircle('#E4E6EF', options.lineWidth, 0, 100)
    drawCircle(getCSSVariableValue(bezetting > 75 ? bezetting > 95 ? '--kt-danger' : '--kt-warning' : '--kt-success'), options.lineWidth, 0, bezetting / 100)
    // drawCircle(getCSSVariableValue('--kt-warning'), options.lineWidth, total,capacity)
}

// interface EditStationDataProps {
//     station: StationTotals;
//     stations: Stations;
//     setStationData: React.Dispatch<React.SetStateAction<Stations>>,
//     onClose: () => void
// }

// const EditStationData: React.FC<EditStationDataProps> = ({
//                                                              station, setStationData,
//                                                              stations, onClose
//                                                          }) => {
//     const [capacity, setCapacity] = useState<number>(station.capacity)
//     const [workingHours, setWorkingHours] = useState<number>(station.workingHours)
//
//     const updateMetadata = () => {
//         let data = stations[station.key];
//         data.capacity = capacity;
//         data.workingHours = workingHours;
//         stations[station.key] = data;
//         setStationData(stations);
//         onClose();
//     }
//
//     return (
//         <>
//             <div className={`row`}>
//                 <div className={`col-4`}>
//                     <div className={`form-label`}>Capaciteit (per uur)</div>
//                 </div>
//                 <div className={`col`}>
//                     <input className={`form-control mb-4`} type={'number'} value={capacity}
//                            onChange={(e) => setCapacity(parseFloat(e.target.value))} name={'capacity'}/>
//                 </div>
//             </div>
//             <div className={`row`}>
//                 <div className={`col-4`}>
//                     <div className={`form-label`}>Uren per dag</div>
//                 </div>
//                 <div className={`col`}>
//                     <input className={`form-control mb-4`} type={'number'} value={workingHours}
//                            onChange={(e) => setWorkingHours(parseFloat(e.target.value))} name={'workingHours'}/>
//                 </div>
//             </div>
//             <div className={`row`}>
//                 <div className={`col-4`}>
//                 </div>
//                 <div className={`col`}>
//                     <button className={'btn btn-primary'} onClick={() => updateMetadata()}>Update</button>
//
//                 </div>
//             </div>
//         </>
//     )
// };
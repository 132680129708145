import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {HeaderWrapper} from './components/header';
import {ScrollTop} from './components/scroll-top';
import {Sidebar} from './components/sidebar';
import {ThemeModeProvider} from '../partials';
import {PageDataProvider} from './core';
import {reInitMenu} from '../helpers';
import {MainWrapper} from './components/main/MainWrapper';

const MasterLayout = () => {
	const location = useLocation();

	useEffect(() => {
		reInitMenu();
	}, [location.key]);

	return (
		<PageDataProvider>
			<ThemeModeProvider>
				<div className="d-flex flex-column flex-root app-root" id="kt_app_root">
					<div className="app-page flex-column flex-column-fluid" id="kt_app_page">
						<HeaderWrapper />
						<div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
							<Sidebar />
							<MainWrapper />
						</div>
					</div>
				</div>
				<ScrollTop />
			</ThemeModeProvider>
		</PageDataProvider>
	);
};

export {MasterLayout};

import {useMutation, useQueryClient} from '@tanstack/react-query';
import {toast} from 'react-hot-toast';
import {StartImportFormValues} from "../components/StartImportForm";
import ApiClient from "../../../modules/api-client/ApiClient";

const upload = async (form: StartImportFormValues) => {
    return await ApiClient.Acl.Import.aclImportPost(undefined, form.customerId, form.title, form.detectWallConnections, form.file).then(x => x.data);
};

export const usePostImport = () => {

    const queryClient = useQueryClient()

    return useMutation<string, void, StartImportFormValues>(
        upload,
        {
            onSuccess: (id) => {
                queryClient.invalidateQueries(['aclImportListOdataGet'])
                toast.success("Import gestart");
                return id;
            },
            onError: () => {
                toast.error("Import mislukt");
            }
        }
    );
};

import React from 'react';
import {useQuery} from '@tanstack/react-query';
import {ConfiguredSill} from '../../modules/api-client/generated';
import ApiClient from '../../modules/api-client/ApiClient';
import Loading from './Loading';
import ConfiguredSillSvg from './ConfiguredSillSvg';
import {DestructCompartment} from '../../backoffice/modules/core';
import Error from './Error';
import {useProductionItemGetNeuten} from "../../backoffice/modules/pm/hooks/use-get-production-item-neuten";

export interface ConfiguredSillSpecificationProps {
	catalogItemId: string;
}

const ConfiguredSillSpecification: React.FC<ConfiguredSillSpecificationProps> = (props) => {
	const queryKey = ['ApiClient.Core.ConfiguredSill.getForCatalogItem', props.catalogItemId];

	const {
		isInitialLoading,
		isLoading,
		data,
		isError,
	} = useQuery(queryKey, async () => await ApiClient.Core.ConfiguredSill.getForCatalogItem(props.catalogItemId!).then((x) => x.data));



	if (isLoading || isInitialLoading) {
		return <Loading message={'Dorpelspecificatie ophalen....'} />;
	}

	if (isError) return <Error />;

	const {id, sill, svg} = data;

	if (!sill) return <></>;

	return <ConfiguredSillSpecificationDetails configuredSillId={id} sill={sill} svg={svg} />;
};

interface ConfiguredSillSpecificationDetails {
	configuredSillId: string
	sill: ConfiguredSill,
	svg: string,
	svgHeight?: string,
}

export const ConfiguredSillSpecificationDetails: React.FC<ConfiguredSillSpecificationDetails> = (props) => {
	const sill = props.sill;

	const protectors = sill.compartments.filter((c) => c.protector).length;

	return (
		<>
			<ConfiguredSillSvg svg={props.svg} svgHeight={props.svgHeight ?? '200px'}
							   onNeutClick={(index) => console.log(index)} />
			<div className={'my-4'}>
				<div className={'row mb-4'}>
					<div className={'col-3 px-1'}>
						<img className="img-fluid"
							 src={`https://bestellen.ekosiet.nl/media/dorpels/${sill.model.code}.png`}
							 title={sill.model.code} alt={sill.model.code} />
					</div>
					<div className={'col-3'}>
						<strong>{sill.model.code}</strong> <br />
						Breedte: {sill.model.width} mm <br />
						Lengte: {sill.totalLength} mm <br />
						{sill.compartments.length} vakken / {sill.neuten?.length ?? 0} neuten <br />
					</div>
					<div className={'col-3'}>
						Inpakken: {sill.wrap ? 'Ja' : 'Nee'} <br />
						Beschermkappen meeleveren: {protectors} <br />
					</div>
				</div>
				<hr />
				<div className={'row mb-4'}>
					{sill.neuten?.map((neut, i) => {
						return (
							<div key={i} className={'col-3'}>
								<div className="p-3">{/*{RenderNeut(neut)}*/}</div>
								<div className="d-flex flex-column">
									{neut ? (
										<>
											<div>
												<strong>
													Neut {i + 1}: {neut.width}mm
												</strong>
											</div>
											<div>Hoogte: {neut.height}mm</div>
										</>
									) : (
										<>
											<div>
												<strong>Neut {i + 1}: Weggelaten</strong>
											</div>
										</>
									)}
								</div>
								{/*{(rabbetLeft != null ? $"<div>Sponning links: {rabbetLeft.Width}*{rabbetLeft.Depth}mm</div>" : "")}*/}
								{/*{(rabbetRight != null ? $"<div>Sponning rechts: {rabbetRight.Width}*{rabbetRight.Depth}mm</div>" : "")}*/}
							</div>
						);
					})}
				</div>
				<div className={'row'}>
					{sill.compartments.map((compartment, i) => {
						const {
							glazingBar,
							glazingProfile,
							lProfile,
							stopProfile,
							aerationHoles,
							drainageHoles,
							sluitpotten,
						} = DestructCompartment(compartment);

						return (
							<div key={i} className={'col-3'}>
								<div className={'d-flex'}>
									<span>
										<strong>
											Vak {i + 1}: {compartment.compartmentLayoutTypeCode}
										</strong>
									</span>
								</div>
								<span>
									Dagmaat: <span>{compartment.dagmaat} mm</span>
								</span>
								<br />
								{compartment.protector && (
									<>
										<span>Beschermkap meeleveren!</span>
										<br />
									</>
								)}
								{aerationHoles?.length! > 0 && (
									<>
										<span>Beluchtingsgaten: ja</span>
										<br />
									</>
								)}
								{drainageHoles?.length! > 0 && (
									<>
										<span>Afwateringsgaten: ja</span>
										<br />
									</>
								)}
								{glazingBar && (
									<>
										<span>
											Glaslat {glazingBar.width}x{glazingBar.height}mm: <span>{glazingBar.length}mm </span>
										</span>
										<br />
										<span>Hoekstukjes? {glazingBar.cornerPieceLeft?.x ? 'ja' : 'nee'}</span>
										<br />
									</>
								)}
								{glazingProfile && (
									<>
										<span>
											Beglazingsprofiel {glazingProfile.width}x{glazingProfile.height}mm: <span>{glazingProfile.length}mm </span>
										</span>
										<br />
									</>
								)}
								{lProfile && (
									<>
										<span>
											L-profiel {lProfile.width}x{lProfile.height}mm: <span>{lProfile.length}mm </span>
										</span>
										<br />
									</>
								)}
								{stopProfile && (
									<>
										<span>
											Aanslagprofiel {stopProfile.width}x{stopProfile.height}mm: <span>{stopProfile.length}mm </span>
										</span>
										<br />
									</>
								)}
								{sluitpotten?.map((sluitpot, i) => {
									return (
										<React.Fragment key={i}>
											<span>
												Sluitpot: <span>{sluitpot.sluitpotHardware?.code}</span>
											</span>
											<br />
											<span>
												Beslag meeleveren? <span>{sluitpot.includeHardware ? 'ja' : 'nee'}</span>
											</span>
											<br />
										</React.Fragment>
									);
								})}
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
};

export default ConfiguredSillSpecification;

import {FC} from 'react'

import moment from 'moment';
import 'moment/locale/nl';

type Props = {
  last_login?: string
}

export const getTimeDiff = (loginTime: any) => {  
  if (loginTime) {
    return moment(loginTime).fromNow();
  }   
  return 'Nooit'
}


const UserLastLoginCell: FC<Props> = ({last_login}) => (
  <div className='badge badge-light fw-bolder'>{getTimeDiff(last_login)}</div>
)

export {UserLastLoginCell}

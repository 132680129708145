/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {MasterDataMenuItem} from "../masterdata/MasterDataMenuItem";
import {PmRoutePath} from "./PmRoutes";
import {MasterdataRoutePath} from "../masterdata/MasterdataRoutes";


export const PmMasterData: FC = () => {
    return (
        <>
            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Productie</span>
                </div>
            </div>
            <MasterDataMenuItem to={MasterdataRoutePath.link(MasterdataRoutePath.productionOperation)} title={'Handelingen'}/>
        </>
    )
}


import React, {useEffect, useRef, useState} from "react";
import {AxiosResponse} from "axios";


interface DownloadFileOptions {
    readonly downloadCallback: () => Promise<DownloadCallbackResult>;
    readonly onError?: () => void;
    readonly filename?: string;
}


interface DownloadedFileState {
    readonly download: () => Promise<void>;
    readonly ref: React.MutableRefObject<HTMLAnchorElement | null>;
    readonly result: { url: string, filename?: string, contentType?: string } | undefined;
    readonly isDownloading: boolean;
}

export type DownloadCallbackResult = {
    contentType?: string,
    filename?: string,
    file: File
}


export const mapDownload = (response: AxiosResponse<File>): Promise<DownloadCallbackResult> => {

    return new Promise((resolve, reject) => {

        try {
            const contentType: string | undefined = ('content-type' in response.headers ? response.headers['content-type'] as string : undefined);
            const contentDisposition = 'content-disposition' in response.headers ? response.headers['content-disposition'] as string : undefined;

            let filename: string | undefined = undefined;

            if (contentDisposition) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(contentDisposition);
                if (matches && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '');
                }

            }
            const mapped = {
                contentType: contentType,
                filename: filename,
                file: response.data
            };
            console.log(mapped);
            resolve(mapped);

        } catch (error) {
            reject(error);
        }

    });


}

export const useDownloadFile = (options: DownloadFileOptions): DownloadedFileState => {

    const ref = useRef<HTMLAnchorElement | null>(null);

    const [result, setResult] = useState<{ filename: string, url: string } | undefined>(undefined);

    const [isDownloading, setIsDownloading] = useState<boolean>(false);

    useEffect(() => {
        if (result) {
            ref.current?.click();
            setIsDownloading(false);
            setResult(undefined);
        }
    }, [result]);

    const download = async () => {

        try {
            setIsDownloading(true);

            const result = await options.downloadCallback();

            const blob = new Blob([result.file], {
                type: result.contentType ?? 'application/zip',
            });

            const url = URL.createObjectURL(blob);

            const filename = options.filename ?? result.filename ?? 'download';

            const r = {
                url: url,
                filename: filename,
                contentType: result.contentType
            }

            setResult(r);

        } catch (error) {
            setIsDownloading(false);
            options.onError?.call(this);
        }
    };

    return {download, ref, result: result, isDownloading};
};
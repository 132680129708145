import {useQuery, useQueryClient} from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import {NotFound, ValidationFailed} from "../../../../modules/api-client/Responses";
import {useEkosietMutation} from "../../../../shared/hooks/useEkosietMutation";
import {
    Category, CreateCategoryCommand, UpdateCategoryRequest
} from "../../../../modules/api-client/generated";
import {FormValues} from "./CategoryUpsert";
import {ImageFormValues} from "../sluitpotbeslag/SluitpotBeslagImageUpsert";
import {AxiosRequestConfig} from "axios";


export const useCategoryUpsertMutation = (id?: string | undefined) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<Category, NotFound | ValidationFailed, FormValues>((values) => {
            const command: CreateCategoryCommand | UpdateCategoryRequest = {
                code: values.code,
                name: values.name,
                description: values.description,
                weight: values.weight
            };

            if (id) {
                return ApiClient.Pim.Category.pimCategoriesIdPut(id, undefined, command).then(response => response.data);
            }
            return ApiClient.Pim.Category.pimCategoriesPost(undefined, command).then(response => response.data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['pimCategoryIdGet', id]).then();
                queryClient.invalidateQueries(['pimCategoriesGet']).then();
            }
        }
    );
};

export const useCategoryQuery = (id: string | undefined) => {
    return useQuery(['pimCategoryIdGet', id],
        () => {
            return ApiClient.Pim.Category.pimCategoriesIdGet(id!).then(res => res.data);
        }, {
            useErrorBoundary: true,
            enabled: !!id,
        });
}
export const useCategoryByCodeQuery = (code: string | undefined) => {
    return useQuery<Category>(['pimCategoryCodeGet', code],
        () => {
            return ApiClient.Pim.Category.pimCategoriesCodeCodeGet(code!).then(res => res.data);
        }, {
            useErrorBoundary: true,
            enabled: !!code,
        });
}
export const useCategoryDeleteMutation = (id: string) => {
    const queryClient = useQueryClient()
    return useEkosietMutation<void, NotFound | ValidationFailed, string>((id) => {
            return ApiClient.Pim.Category.pimCategoriesIdDelete(id, undefined).then(response => response.data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['pimCategoryIdGet', id]).then();
                queryClient.invalidateQueries(['pimCategoriesGet']).then();
            }
        }
    );
};

export const useCategoryImageUpsertMutation = (id: string) => {
    const queryClient = useQueryClient()
    return useEkosietMutation<void | undefined, NotFound | ValidationFailed, ImageFormValues>((values) => {
            let options:AxiosRequestConfig = {};
            if (values.image) {
                const formData = new FormData();
                formData.append('image', values.image, 'sdf.jpg');
                options = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    data: formData
                }
            }
            return ApiClient.Pim.Category.pimCategoriesIdImagePut(id, undefined, options).then(response => response.data);

        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['pimCategoryIdGet', id]).then();
                queryClient.invalidateQueries(['pimCategoriesGet']).then();
            }
        }
    );
};




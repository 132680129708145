/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { CustomerModalFormWrapper } from './CustomerModalFormWrapper';

type Props = {
  show: boolean,
  customerId: string | undefined | null,
  handleClose: () => void,
}

const modalsRoot = document.getElementById('root-modals') || document.body

const CustomerModal = ({ show, customerId, handleClose }: Props) => {

  return createPortal(
    <Modal
      id='kt_addedit_customer_modal'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-xl'
      show={show}
      onHide={handleClose}
      animation={true}
      backdrop={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>{(customerId) ? 'Klant beheren' : 'Nieuwe klant aanmaken'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CustomerModalFormWrapper customerId={customerId} handleClose={handleClose} />
      </Modal.Body>
    </Modal>,
    modalsRoot
  )
}

export { CustomerModal }

// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserLastLoginCell} from './UserLastLoginCell'
import {UserTwoStepsCell} from './UserTwoStepsCell'
import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {User} from '../../core/_models'
import {UserJoinedTimeCell} from './UserJoinedTimeCell'
import {AccountTypeCell} from './AccountTypeCell'
import {UserBlockedStatusCell} from './UserBlockedStatusCell'

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].userId} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Naam' className='min-w-125px' />,
    id: 'name',
    accessor: 'name',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Type' className='min-w-125px' />
    ),
    id: 'user_metadata',
    accessor: 'userMetadata',
    Cell: ({...props}) => (
      <AccountTypeCell user_metadata={props.data[props.row.index].userMetadata} />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Laatste login' className='min-w-125px' />
    ),
    id: 'last_login',
    accessor: 'lastLogin',
    Cell: ({...props}) => <UserLastLoginCell last_login={props.data[props.row.index].lastLogin} />,
  },
  
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Email Geverifieerd' className='min-w-125px' />
    ),
    id: 'email_verified',
    accessor: 'emailVerified',
    Cell: ({...props}) => (
      <UserTwoStepsCell two_steps={props.data[props.row.index].emailVerified} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    id: 'blocked',
    accessor: 'blocked',
    Cell: ({...props}) => <UserBlockedStatusCell blocked={props.data[props.row.index].blocked} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Aangemaakt' className='min-w-125px' />
    ),
    id: 'created_at',
    accessor: 'createdAt',
    Cell: ({...props}) => <UserJoinedTimeCell time={props.data[props.row.index].createdAt} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Acties' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].userId} />,
  },
]

export {usersColumns}

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, {PropsWithChildren} from 'react';
import {usePutValidationResolve} from '../../hooks/use-put-validation-resolve';
import {modalsRoot} from '../../../../global-helpers';
import {Button, Modal} from 'react-bootstrap';
import {
	GroeneveldHefSchuifSchemaLayoutImportValidation, HefschuifFixedDoorCutImportValidation,
	IgnoreImportValidationCommand, ResolveHefschuifFixedDoorCutImportValidationCommand,
	ResolveHefSchuifSchemaLayoutImportValidationCommand,
	ResolveImportValidationCommandType,
} from '../../../../modules/api-client/generated';
import {ValidationResolverProps} from '../../pages/ValidationResolverPage';
import {Form, Formik, FormikHelpers} from 'formik';
import SpinnerButton from '../../../components/SpinnerButton';
import {
	HefSchuifSchemaPickerField
} from "../../../../backoffice/modules/pim/compartment-layout-type/HefSchuifSchemaPicker";
import TextField from "../../../components/TextField";

type FormValues = {length: number, width: number};
export type UitsparingRubberBlokResolverProps = Omit<ValidationResolverProps, 'validation'> & {
	validation: HefschuifFixedDoorCutImportValidation
};
export const UitsparingRubberBlokResolver = ({show, validation, handleClose, children}: PropsWithChildren<UitsparingRubberBlokResolverProps>) => {
	var resolveMutation = usePutValidationResolve();

	const initialFormValues: FormValues = {length: 0, width:0};
	const resolve = (values: FormValues, {setSubmitting}: FormikHelpers<FormValues>) => {
		return new Promise<void>((resolve, reject) => {
			var command: ResolveHefschuifFixedDoorCutImportValidationCommand = {
				commandType: ResolveImportValidationCommandType.ResolveHefschuifFixedDoorCutImportValidationCommand,
				length: values.length,
				width: values.width,
				id: validation.id,
				importType: validation.type,
			};
			resolveMutation.mutate(command, {
				onSuccess: (model) => {
					resolve();
					handleClose();
				},
				onError: () => {
					reject();
				},
			});
		}).finally(() => setSubmitting(false));
	};

	const ignore = async () => {

		var command: IgnoreImportValidationCommand = {
			commandType: ResolveImportValidationCommandType.IgnoreImportValidationCommand,
			id: validation.id,
			importType: validation.type,
		};

		await resolveMutation.mutateAsync(command);

		handleClose();
	};

	return (
		<Formik initialValues={initialFormValues} onSubmit={resolve}>
			{({values, getFieldProps, isSubmitting}) => (
				<Modal
					container={modalsRoot}
					tabIndex={-1}
					aria-hidden="true"
					autoFocus={true}
					className=""
					dialogClassName={'modal-dialog-centered'}
					contentClassName={'shadow-lg'}
					show={show}
					size="lg"
					onHide={handleClose}
					animation={false}
					backdrop={'static'}
				>
					<Form>
						<Modal.Header closeButton>
							<Modal.Title>Configureer een uitsparing voor rubber blok</Modal.Title>
						</Modal.Header>

						<Modal.Body className="flex-fill">
							<p className={'lead'}>{validation.message} </p>


							<TextField row={true} type='number' name={'length'} label={'Lengte'}
									   help={'De lengte van de uitsparing'}/>

							<TextField row={true} type='number' name={'width'} label={'Breedte'}
									   help={'De breedte van de uitsparing'}/>


						</Modal.Body>

						<Modal.Footer>
							<Button variant="link" className="mx-4" onClick={handleClose}>
								Sluit
							</Button>
							<Button
								className="btn-light-danger mx-4"
								onClick={() => {
									ignore().then();
								}}
							>
								Negeer
							</Button>
							<SpinnerButton type="submit" className="btn btn-primary" spinning={isSubmitting} disabled={isSubmitting}>
								Toepassen
							</SpinnerButton>
						</Modal.Footer>
					</Form>
				</Modal>
			)}
		</Formik>
	);
};


import {PageTitle} from '../../../../../_metronic/layout/core';
import SpinnerButton from '../../../../shared/components/SpinnerButton';
import {useMutation} from '@tanstack/react-query';
import ApiClient from '../../../../modules/api-client/ApiClient';
import {useConfirmDialog} from '../../../../shared/components/ConfirmDialog';
import {toast} from "react-hot-toast";
import React, {useState} from "react";
import {EkoCard, EkoCardHeader, EkoCardBody} from "../../../../shared/components/card";
import {Link} from "react-router-dom";
import {AdminRoutePath} from "../AdminRoutes";

export function AdminDashboardPage() {


    const updateWallConnectionStatistics = useMutation(async () => {
        const response = await ApiClient.SystemAdmin.updateWallCollectionStatistics(undefined);
        return response.data;
    });

    const deduplicateWallConnections = useMutation(async () => {
        const response = await ApiClient.SystemAdmin.deduplicateWallConnections(undefined);
        return response.data;
    });

    const [deduplicateSillsSince, setDeduplicateSillsSince] = useState<string | undefined>(
        new Date().toISOString().slice(0, 16)
    );

    const [deduplicateSillsIds, setDeduplicateSillsIds] = useState<string | undefined>(undefined);

    const deduplicateSills = useMutation(async () => {
        const response = await ApiClient.SystemAdmin.deduplicateSillsAsync(undefined, {
            since: deduplicateSillsSince,
            ids: deduplicateSillsIds?.trim() === '' ? undefined : deduplicateSillsIds?.trim().split('\n')
        });
        return response.data;
    });
    const resaveConfiguredSills = useMutation(() => ApiClient.SystemAdmin.enqueueReSaveConfiguredSills());

    const deduplicateNeuten = useMutation(async () => {
        const response = await ApiClient.SystemAdmin.deduplicateNeuten();
        return response.data;
    });

    const deduplicateProfiles = useMutation(() => ApiClient.SystemAdmin.deduplicateProfiles());
    const rehashOrderAddresses = useMutation(() => ApiClient.SystemAdmin.rehashOrderAddresses());

    const deduplicateWallConnectionsConfirmDialog = useConfirmDialog();
    const deDuplicateSillsConfirmDialog = useConfirmDialog();
    const resaveConfiguredSillsConfirmDialog = useConfirmDialog();
    const deDuplicateNeutenConfirmDialog = useConfirmDialog();
    const rehashProfilesConfirmDialog = useConfirmDialog();
    const rehashOrderAddressesConfirmDialog = useConfirmDialog();

    const updateWallConnectionStatisticsClick = async () => {
        await deduplicateWallConnectionsConfirmDialog({
            title: 'Muuraansluitingen Statistieken bijwerken',
            onConfirm: async () => {
                const result = await updateWallConnectionStatistics.mutateAsync();
                console.log(result);
            },
        });
    };

    const deDuplicateWallConnectionsClick = async () => {
        await deduplicateWallConnectionsConfirmDialog({
            title: 'Muuraansluitingen rehashen en ontdubbelen',
            onConfirm: async () => {
                const result = await deduplicateWallConnections.mutateAsync();
                console.log(result);
            },
        });
    };

    const deDuplicateSillsClick = async () => {
        await deDuplicateSillsConfirmDialog({
            title: 'Dorpels ontdubbelen',
            onConfirm: async () => {
                const result = await deduplicateSills.mutateAsync();
                console.log(result);
                toast.success(`Dorpels worden ontdubbeld`)
            },
        });
    };

    const resaveConfiguredSillsClick = async () => {
        await resaveConfiguredSillsConfirmDialog({
            title: 'Dorpels opnieuw opslaan',
            onConfirm: async () => {
                await resaveConfiguredSills.mutateAsync();
            },
        });
    };


    const deDuplicateNeutenClick = async () => {
        await deDuplicateNeutenConfirmDialog({
            title: 'Neuten rehashen en ontdubbelen',
            onConfirm: async () => {
                const result = await deduplicateNeuten.mutateAsync();
                console.log(result);
                toast.success(`Neuten worden ontdubbeld`)
            },
        });
    };

    const rehashProfilesClick = async () => {
        await rehashProfilesConfirmDialog({
            title: 'Profielen rehashen',
            onConfirm: async () => {
                await deduplicateProfiles.mutateAsync();
            },
        });
    };


    const rehashOrderAddressesClick = async () => {
        await rehashOrderAddressesConfirmDialog({
            title: 'Order adressen re-hashen',
            onConfirm: async () => {
                await rehashOrderAddresses.mutateAsync();
            },
        });
    };


    return (
        <>
            <PageTitle>Admin</PageTitle>

            <div className="row">
                <div className="col d-flex flex-column gap-5">
                    <EkoCard>
                        <EkoCardHeader title={'Muuraansluitingen'}/>
                        <EkoCardBody>

                            <div className="d-flex justify-content-between border-bottom mb-3">
                                <div>
                                    <h6>Statistieken bijwerken</h6>
                                    <p>Werk de uitgerekende statistieken bij zoals aantal renovatie sponningen en
                                        groeven etc.</p>
                                </div>

                                <div>
                                    <SpinnerButton type='button' className='btn btn-primary'
                                                   spinning={updateWallConnectionStatistics.isLoading}
                                                   onClick={updateWallConnectionStatisticsClick}>Statistieken
                                        bijwerken</SpinnerButton>
                                </div>
                            </div>

                            <div className="d-flex justify-content-between border-bottom mb-3">
                                <div>
                                    <h6>Rehashen en ontdubbelen</h6>
                                    <p>Hash opnieuw uitrekenen en eventuele duplicaten verwijderen</p>
                                </div>

                                <div>
                                    <SpinnerButton type='button' className='btn btn-primary'
                                                   spinning={deduplicateWallConnections.isLoading}
                                                   onClick={deDuplicateWallConnectionsClick}>Rehashen en ontdubbelen</SpinnerButton>
                                </div>
                            </div>
                        </EkoCardBody>
                    </EkoCard>


                    <EkoCard>
                        <EkoCardHeader title={'Profielen'}/>
                        <EkoCardBody>
                            <div className="d-flex justify-content-between border-bottom mb-3">
                                <div>
                                    <h6>Rehashen en ontdubbelen</h6>
                                    <p>Hash opnieuw uitrekenen en eventuele duplucaten verwijderen</p>
                                </div>

                                <div>
                                    <SpinnerButton type='button' className='btn btn-primary'
                                                   spinning={deduplicateProfiles.isLoading}
                                                   onClick={rehashProfilesClick}>Rehashen en ontdubbelen</SpinnerButton>
                                </div>
                            </div>
                        </EkoCardBody>
                    </EkoCard>

                    <EkoCard>
                        <EkoCardHeader title={'Neuten'}/>
                        <EkoCardBody>

                            <div className="d-flex justify-content-between border-bottom mb-3">
                                <div>
                                    <h6>Rehashen en ontdubbelen</h6>
                                    <p>Hash opnieuw uitrekenen en eventuele duplucaten verwijderen</p>
                                </div>

                                <div>
                                    <SpinnerButton type='button' className='btn btn-primary'
                                                   spinning={deduplicateNeuten.isLoading}
                                                   onClick={deDuplicateNeutenClick}>Rehashen en ontdubbelen</SpinnerButton>
                                </div>
                            </div>
                        </EkoCardBody>
                    </EkoCard>

                    <EkoCard>
                        <EkoCardHeader title={'Overig'}/>
                        <EkoCardBody>

                            <div className="d-flex justify-content-between border-bottom mb-3">
                                <div>
                                    <h6>Aanmaken productie items</h6>
                                    <p>???</p>
                                </div>

                                <div>
                                    <Link to={AdminRoutePath.link(AdminRoutePath.productionSaga)}
                                          className='btn btn-link'>
                                        Aanmaken productie items</Link>
                                </div>
                            </div>

                            <div className="d-flex justify-content-between border-bottom mb-3">
                                <div>
                                    <h6>Rehash order addresses</h6>
                                    <p>Hash berekenen voor de addressen</p>
                                </div>

                                <div>
                                    <SpinnerButton type='button' className='btn btn-primary'
                                                   spinning={rehashOrderAddresses.isLoading}
                                                   onClick={rehashOrderAddressesClick}>Rehash order
                                        addresses</SpinnerButton>
                                </div>
                            </div>
                        </EkoCardBody>
                    </EkoCard>

                </div>
                <div className="col">

                    <EkoCard>
                        <EkoCardHeader title="Dorpels rehashen &amp; ontdubbelen"/>
                        <EkoCardBody className="d-grid gap-3">
                            <div className='row'>
                                <div className="col-4">
                                    Last hashed since
                                </div>
                                <div className="col">
                                    <input type="datetime-local" className="form-control"
                                           value={deduplicateSillsSince}
                                           onChange={(e) => {
                                               setDeduplicateSillsSince(e.target.value);
                                           }}/>
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-4">
                                    Configured Sill Ids (line separated)
                                </div>
                                <div className="col">
                                    <textarea className="form-control" rows={20}
                                              value={deduplicateSillsIds ?? ''}
                                              onChange={(e) => setDeduplicateSillsIds(e.target.value)}
                                              placeholder={"Paste sill id's to deduplicate only specific sills"}
                                    ></textarea>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end gap-3">

                                <SpinnerButton type='button' className='btn btn-primary'
                                               spinning={resaveConfiguredSills.isLoading}
                                               onClick={resaveConfiguredSillsClick}>Dorpels opnieuw
                                    opslaan</SpinnerButton>

                                <SpinnerButton type='button'
                                               className='btn btn-primary' spinning={deduplicateSills.isLoading}
                                               onClick={deDuplicateSillsClick}>Rehashen &amp; ontdubbelen!</SpinnerButton>
                            </div>
                        </EkoCardBody>
                    </EkoCard>
                </div>
            </div>

        </>
    );
}

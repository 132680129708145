import {createSearchParams, To} from "react-router-dom";

export const CustomerRoutePath = {
    base: '/c',
    import: '/c/import/',

    dashboard: '/dashboard',
    account: '/account',
    orders: '/orders',
    order: '/order/:id',
    orderConfigurator: '/order/:id/configurator',
    orderStart: '/order/add',
    orderPlaced: '/order/placed',
    quoteRequested: '/quote/requested',
    configurator: '/configurator',
    orderWizard: '/order/wizard/:id',
    orderAddWithIdConfigurator: '/order/wizard/:id/configurator',
    orderEditWithIdConfigurator: '/order/wizard/:id/configurator',
    orderAddWithId: '/order/sill/add/:id',

    relative: (path: string): string => {
        return path.slice(CustomerRoutePath.base.length);
    },
    orderLink: (id: string) => {
        return CustomerRoutePath.order.replace(':id', id)
    },
    // importLink: (id: string) => {
    //     return CustomerRoutePath.import.replace(':id', id)
    // },
    // importConfiguratorLink: (id: string, orderLineId: string) => {
    //     return CustomerRoutePath.importConfigurator.replace(':id', id).replace(':orderlineId', orderLineId);
    // },
    // importCompartmentConfiguratorLink: (id: string, orderLineId: string, compartmentId: number) => {
    //     return CustomerRoutePath.importCompartmentConfigurator.replace(':id', id).replace(':orderlineId', orderLineId).replace(':compartmentId', compartmentId.toString());
    // },
    orderConfiguratorLink: (id: string) => {
        return CustomerRoutePath.orderConfigurator.replace(':id', id)
    },
    orderWizardLink: (id: string) => {
        return CustomerRoutePath.orderWizard.replace(':id', id)
    },
    orderAddWithIdConfiguratorLink: (params: {
        orderId: string;
        customerId: string;
        pricelistId: string;
        orderLineId?: string;
        catalogItemId?: string;
        quantity?: string;
        remark?: string;
        merk?: string;
    }): To => {

        return {
            pathname: CustomerRoutePath.link(CustomerRoutePath.orderAddWithIdConfigurator.replace(':id', params.orderId)),
            search: createSearchParams({
                pl: params.pricelistId,
                ...(params.customerId ? {customerId: params.customerId} : {}),
                ...(params.orderLineId ? {orderLineId: params.orderLineId} : {}),
                ...(params.catalogItemId ? {configurationId: params.catalogItemId} : {}),
                ...(params.quantity ? {q: params.quantity} : {}),
                ...(params.remark ? {remark: params.remark} : {}),
                ...(params.merk ? {merk: params.merk} : {})
            }).toString()
        }
    },

    orderEditWithIdConfiguratorLink: (id: string) => {
        return CustomerRoutePath.orderAddWithIdConfigurator.replace(':id', id);
    },
    link(path: string) {
        return CustomerRoutePath.base + path;
    },
};
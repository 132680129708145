import React from 'react';
import {ProductionItemOperations, PutProductionItemOperationRepresentation} from '../../../../modules/api-client/generated';
import {usePutProductionItemOperation} from '../hooks/use-put-production-item-operation';

export const StationOperationToggle: React.FC<{
	id: string;
	status?: boolean | null;
	onlyToggle?: boolean | null;
	operation: ProductionItemOperations;
	title?: string;
}> = ({status, id, operation, title, onlyToggle}) => {
	const operationMutation = usePutProductionItemOperation();

	const toggle = async (operation: ProductionItemOperations, value: boolean | null) => {
		return new Promise<void>(() => {
			const command: PutProductionItemOperationRepresentation = {
				productionItemIds: [id],
				value: value,
				operation: operation,
			};
			operationMutation.mutate(command, {});
		});
	};
	return (
		<>
			<div className="d-flex align-items-center mb-8">
				{status !== null ? (
					<>
						<span className={`bullet bullet-vertical h-40px${status ? ' bg-success' : ''}`}></span>
						<div className="form-check form-check-custom form-check-solid mx-5">
							<input className="form-check-input" type="checkbox" checked={status} onChange={() => toggle(operation, !status)} value="" />
						</div>
					</>
				) : (
					<>
						{onlyToggle === true && (
							<button className="btn btn-sm btn-light" onClick={() => toggle(operation, false)}>
								<i className={'fa fa-plus'}></i>
							</button>
						)}
					</>
				)}
				{onlyToggle !== true && (
					<div className="flex-grow-1">
						<div className={'d-flex justify-content-between align-items-center'}>
							{status !== null ? (
								<>
									<a href="#" className="text-gray-800 text-hover-primary fw-bold fs-6" onClick={() => toggle(operation, !status)}>
										{title ? <>{title}</> : <>{operation}</>}
									</a>
									<button className="btn btn-sm btn-secondary" onClick={() => toggle(operation, null)}>
										<i className={'fa fa-times'}></i>
									</button>
								</>
							) : (
								<>
									<a href="#" className="text-muted text-hover-primary fw-bold fs-6" onClick={() => toggle(operation, false)}>
										<del>{title ? <>{title}</> : <>{operation}</>}</del>
									</a>
									<button className="btn btn-sm btn-secondary" onClick={() => toggle(operation, false)}>
										<i className={'fa fa-plus'}></i>
									</button>
								</>
							)}
						</div>
					</div>
				)}
			</div>
		</>
	);
};

import React, { FC } from 'react'
import * as Yup from 'yup'
import { Form, Formik, FormikHelpers } from 'formik'
import {
    PutProductionItemDateRepresentation
} from "../../../../modules/api-client/generated";
import {usePutProductionItemProductionDate} from "../hooks/use-put-production-item-production-date";
import moment from "moment/moment";
import {DateField} from "../../../../shared/components/date/DateField";

type Props = {
    ids: string[]
    productionDate?: string|null
    handleClose: () => void,
}
type FormValues = Omit<PutProductionItemDateRepresentation, 'date'> & {
    date?: Date | null;
};
const PutProductionDateForm: FC<Props> = ({ ids, productionDate,  handleClose }) => {

    const mutation = usePutProductionItemProductionDate();

    const initialValues: FormValues = {
        productionItemIds: ids,
        date: productionDate ? new Date(productionDate) : new Date(),
    }

    const schema = {
        date: Yup.string().notRequired().nullable()
    }
    const formSchema = () => Yup.lazy((values) => {
        return Yup.object().shape(schema)
    });

    //
    const onSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {

        return new Promise<void>((resolve, reject) => {
            let payload : PutProductionItemDateRepresentation = {
                ...values,
                date: moment(values.date).format(moment.HTML5_FMT.DATE)
            }
            mutation.mutate(payload, {
                onSuccess: (payload) => {
                    resolve();
                    handleClose()
                },
                onError: () => {
                    reject();
                }
            });

        }).finally(() => setSubmitting(false));
    };


    return (
        <>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={formSchema}>
                {({
                    values,
                    handleChange,
                    handleSubmit,
                    getFieldProps,
                    isSubmitting,
                }) => (
                    <Form >

                        <div className='d-flex flex-column mb-7 fv-row'>
                            <label className='d-flex align-items-center form-label' >
                                <span>Productie datum</span>
                            </label>

                            <DateField fieldName='date' />

                        </div>

                        <div className='text-center pt-5'>
                            <button
                                type='reset'
                                className='btn btn-light me-3'
                                data-kt-customer-modal-action='cancel'
                                data-dismiss="modal"
                                onClick={() => handleClose()}
                                disabled={isSubmitting}
                            >
                                Annuleren
                            </button>

                            <button
                                type='submit'
                                className='btn btn-primary'
                                data-kt-customer-modal-action='submit'
                                disabled={isSubmitting}
                            >
                                <span className='indicator-label'>Opslaan
                                </span>
                                {isSubmitting && (
                                    <span className='indicator-progress'>
                                        Even wachten a.u.b
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                        {/* end::Actions */}

                    </Form>
                )}
            </Formik>
        </>
    )
}

export { PutProductionDateForm }

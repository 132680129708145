import React from "react";
import {ExpeditionStatusCodes} from "../../modules/api-client/generated";
import clsx from "clsx";

type Props = {
    status?: string | null
    className?: string
    textOnly?: boolean
}
const ExpeditionStatus: React.FC<Props> = ({status, className, textOnly = false}) => {
    let color = 'light';
    let title = 'Onbekend';
    switch (status) {
        case ExpeditionStatusCodes.NotPlanned:
            color = 'warning'
            title = 'Niet gepland'
            break;
        case ExpeditionStatusCodes.Planned:
            color = 'primary'
            title = 'Gepland'
            break;
        case ExpeditionStatusCodes.ReadyForShipment:
            color = 'warning'
            title = 'Klaar voor verzending'
            break;
        case ExpeditionStatusCodes.InTransit:
            color = 'dark'
            title = 'Onderweg'
            break;
        case ExpeditionStatusCodes.Delivered:
            color = 'success'
            title = 'Afgeleverd'
            break;
        case ExpeditionStatusCodes.Cancelled:
            color = 'danger'
            title = 'Geannuleerd'
            break;
        default:
            color = 'dark'
    }

    return (
        <>
            {textOnly ?
                <>{title}</>
                :
            <span className={clsx(
                'badge badge-light-' + color,
                className && className)}>
                {title}
            </span>
            }
        </>

)
}

const ExpeditionStates = [
    {
        label: "Gepland",
        value: ExpeditionStatusCodes.Planned,
        key: "Planned"
    },
    {
        label: "Niet gepland",
        value: ExpeditionStatusCodes.NotPlanned,
        key: "NotPlanned"
    },
    {
        label: "Klaar voor verzending",
        value: ExpeditionStatusCodes.ReadyForShipment,
        key: "ReadyForShipment"
    },
    {
        label: "Onderweg",
        value: ExpeditionStatusCodes.InTransit,
        key: "InTransit"
    },
    {
        label: "Afgeleverd",
        value: ExpeditionStatusCodes.Delivered,
        key: "Delivered"
    },
    {
        label: "Geannuleerd",
        value: ExpeditionStatusCodes.Cancelled,
        key: "Cancelled"
    }
]

export {ExpeditionStatus, ExpeditionStates}
import {useQueryClient} from "@tanstack/react-query";
import {toast} from "react-hot-toast"
import ApiClient from "../../../../../modules/api-client/ApiClient";
import {
    OrderRepresentation, PutOrderDateRepresentation
} from "../../../../../modules/api-client/generated";
import {useEkosietMutation} from "../../../../../shared/hooks/useEkosietMutation";
import {NotFound, ValidationFailed} from "../../../../../modules/api-client/Responses";

export const usePutOrderDate = (id: string) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<OrderRepresentation, NotFound | ValidationFailed, PutOrderDateRepresentation>(
        (
            model: PutOrderDateRepresentation) => {
            return ApiClient.Sm.Order.smOrderIdOrderDatePut(id, undefined, model).then(response => response.data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['v1/sm/orders/list']).then();
                queryClient.invalidateQueries(['smOrderIdGet', id]).then();
                queryClient.invalidateQueries(['OrderActionLog', id]).then();
                toast.success("Order datum aangepast.");
            },
            onError: () => {
                toast.error("Order datum wijzigen mislukt!");
            }
        }
    );
};

import { toast } from "react-hot-toast"
import { useEkosietMutation } from "../../../../shared/hooks/useEkosietMutation";
import { NotFound, ValidationFailed } from "../../../../modules/api-client/Responses";
import ApiClient from "../../../../modules/api-client/ApiClient";
import {
    PutProductionItemRemarkRepresentation
} from "../../../../modules/api-client/generated";
import { useQueryClient } from "@tanstack/react-query";

export const usePutProductionItemRemark = () => {
    const queryClient = useQueryClient()
    return useEkosietMutation<void, NotFound | ValidationFailed, PutProductionItemRemarkRepresentation>((command) => {
        return ApiClient.Pm.ProductionItem.pmProductionItemRemarkPut(undefined, command).then();
    },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['pmProductionItemIdGet']).then();
                queryClient.invalidateQueries(['productionItemsList']).then();
                queryClient.invalidateQueries(['pmProductionItemGroupCodeGet']).then();
                queryClient.invalidateQueries(['pmProductionItemBarcodeCodeGet']).then();
                
                toast.success("Opmerking opgeslagen");
            },
            onError: () => {
                toast.error("Opmerking aanpassen mislukt");
            }
        }
    );
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {ReactElement} from 'react'
import {EkoCard, EkoCardBody, KTSVG} from "../../../../../../../_metronic/helpers";
import {Person} from "./Person";
import {
    OrderActionLogsRepresentation,
    OrderContactPersonRepresentation,
    OrderStatusCodes, WeeklySalesStatsRow
} from "../../../../../../modules/api-client/generated";
import {PutContactForm} from "./forms/PutContactForm";
import {PutReferenceForm} from "./forms/PutReferenceForm";
import {formatDate} from "../../../../../../shared/components/date";
import {OrderContactTypes} from '../../hooks/use-put-contact';
import {PutOrderDateForm} from "./forms/PutOrderDateForm";
import {EkoCardHeader} from "../../../../../../shared/components/card";
import {useQuery} from "@tanstack/react-query";
import ApiClient from "../../../../../../modules/api-client/ApiClient";
import Loading from "../../../../../../shared/components/Loading";
import {EkoTable} from "../../../../../../shared/components/table";
import Timeline from "../../../../../../shared/components/Timeline/Timeline";

type Props = {
    orderId: string
}
const OrderActionLog: React.FC<Props> = ({orderId}) => {
    const {isInitialLoading, data} = useQuery<OrderActionLogsRepresentation, TypeError, OrderActionLogsRepresentation>(
        ['OrderActionLog', orderId],
        () => ApiClient.Sm.Order.getOrderActionLog(orderId).then((res) => res.data));
    if (!data) return <Loading/>
    if (isInitialLoading) return <Loading/>
    return (
        <Timeline logs={data.actionLogs}/>
    )
}

export {OrderActionLog}

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {ReactElement} from "react";

type Props = {
    show: boolean,
    fullscreen: true | string | 'sm-down' | 'md-down' | 'lg-down' | 'xl-down' | 'xxl-down',
    size?: 'sm' | 'lg' | 'xl';
    component: ReactElement | null | undefined,
    title: string | null | undefined,
    handleClose: () => void,
}

const modalsRoot = document.getElementById('root-modals') || document.body

const EditOrderModal = ({show, title, handleClose, component,size,fullscreen}: Props) => {

    return createPortal(
        <Modal
            id='kt_edit_order_modal'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName={'modal-dialog modal-'+size}
            show={show}
            size={size}
            onHide={handleClose}
            fullscreen={fullscreen}
            animation={true}
            backdrop={true}
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {component &&
                    component
                }
            </Modal.Body>
        </Modal>,
        modalsRoot
    )
}

export {EditOrderModal}

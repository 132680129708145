import React, { useState } from "react";
import { CustomerTable } from "./CustomerTable";
import { EkoCard } from "../../../../../_metronic/helpers";
import { CustomerListHeader } from "./CustomerListHeader";
import { CustomerModal } from "./CustomerModal";
import { useListCustomers } from "../hooks/use-list-customers";

const CustomerArchivedList: React.FC = () => {

    const [showCustomerModal, setShowCustomerModal] = useState<boolean>(false)
    const [customerId, setCustomerId] = useState<string | undefined | null>(null);
    
    const { refetch } = useListCustomers(undefined, true, false);

    const handleCustomerModelClose = () => {
        setShowCustomerModal(false)
        refetch()
    };

    return (
        <>
            <EkoCard>

                <CustomerListHeader setCustomerId={setCustomerId} setShowCustomerModal={setShowCustomerModal} />

                {/*<CustomerTable*/}
                {/*    isArchived={true}*/}
                {/*    setCustomerId={setCustomerId}*/}
                {/*    setShowCustomerModal={setShowCustomerModal}*/}
                {/*    setShowCustomerAddressesModal={null}*/}
                {/*    setCustomerCompanyName={null}*/}
                {/*/>*/}
            </EkoCard>
            <CustomerModal
                show={showCustomerModal}
                customerId={customerId}
                handleClose={handleCustomerModelClose}
            />
        </>
    )
}

export default CustomerArchivedList

import React from "react";
import {useQuery} from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import {Link} from "react-router-dom";
import SillTypeText from "../sill/SillTypeText";
import {
   ConfiguredSillsListItem,
} from "../../../../modules/api-client/generated";
import {EkoCard, EkoCardBody} from "../../../../../_metronic/helpers";
import {TableHeader} from "../../../../shared/components/table/TableHeader";
import {EkoTable} from "../../../../shared/components/table/EkoTable";
import {EkoCardHeader} from "../../../../shared/components/card/EkoCardHeader";
import Loading from "../../../../shared/components/Loading";
import {PimRoutePath} from "../PimRoutes";
import {PageTitle} from "../../../../../_metronic/layout/core";

const ConfiguredSillList: React.FC = () => {
    const {
        isInitialLoading,
        data: sillList,
        isError
    } = useQuery<ConfiguredSillsListItem[]>(['pimConfiguredSillsGet'], () => ApiClient.Core.ConfiguredSill.configuredSillList().then((res) => res.data));
    if (isInitialLoading) {
        return <Loading />;
    }
    if (isError) {
        return <>ERROR!</>;
    }
    if (!sillList) return <></>;

    return <>
        <PageTitle toolbar={<button className={'btn btn-primary disabled'} onClick={()=>1}>Dorpel toevoegen</button>}
                   breadcrumbs={[]}>Dorpels</PageTitle>
        <EkoCard>
            <EkoCardBody>
                <EkoTable>
                    <TableHeader>
                        <th></th>
                        <th>Titel</th>
                        <th className="fit-content text-center">Lengte</th>
                        <th className="fit-content text-center">Dorpeltype</th>
                        <th className="fit-content text-center">Dorpelmodel</th>
                        <th className="fit-content text-center">Aantal besteld</th>
                        <th></th>
                    </TableHeader>
                    <tbody>
                    {(!sillList || sillList.length === 0) &&
                        <tr>
                            <td colSpan={5} className="text-center">Geen gegevens</td>
                        </tr>}

                    {sillList && sillList.map((sill) => <tr key={sill.id}>
                        <td className="fit-content">
                            <Link to={PimRoutePath.link(PimRoutePath.configuredSillDiff)}>{sill.code}</Link>
                        </td>
                        <td>
                                {sill.title}
                        </td>
                        <td>
                            {sill.totalLength}mm
                        </td>
                         <td className="fit-content text-center">
                             <SillTypeText code={sill.type}/>

                         </td>
                        <td className="fit-content text-center">
                            {sill.model}

                        </td>
                        <td className="fit-content">
                            {sill.totalSold}
                        </td>
                    </tr>)}
                    </tbody>
                </EkoTable>
            </EkoCardBody>
        </EkoCard>
    </>
}

export default ConfiguredSillList;
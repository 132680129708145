import {useQueryClient} from "@tanstack/react-query";
import {useEkosietMutation} from "../../../../../shared/hooks/useEkosietMutation";
import {NotFound, ValidationFailed} from "../../../../../modules/api-client/Responses";
import ApiClient from "../../../../../modules/api-client/ApiClient";
import {
    AddNeutPosition,
    SillDetails, SillDetailsCompartmentLayout,
    SillDetailsNeutPosition, SillNeutPositionCompartmentLayout,
    SillNeutPositionCompartmentLayoutHefSchuif,
    SillNeutPositionCompartmentLayoutStandard,
    SillNeutPositionUpdate
} from "../../../../../modules/api-client/generated";
import {useAddNeutPositionModal} from "../SillDetails/AddNeutPositionModal";
import {useConfirmDialog} from "../../../../../shared/components/ConfirmDialog";
import {useSillNeutPositionEditModal} from "./use-sill-neut-position-edit-modal";
import {useNeutPositionCompartmentLayoutModal} from "./use-neut-position-compartment-layout-modal";

export const useSillNeutPositionsManage = (sill: SillDetails) => {

    const queryClient = useQueryClient();

    const addMutation = useEkosietMutation<void, NotFound | ValidationFailed, {
        neutPosition: AddNeutPosition
    }>(async ({neutPosition}) => {
            await ApiClient.Pim.SillNeutPosition.addNeutPosition(sill.id, undefined, neutPosition);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['ApiClient.Pim.Sill.getSill', sill.id]).then();
            },
        },
    );

    const updateMutation = useEkosietMutation<void, NotFound | ValidationFailed, {
        neutPositionId: string,
        update: SillNeutPositionUpdate
    }>(async ({neutPositionId, update}) => {
            await ApiClient.Pim.SillNeutPosition.updateNeutPosition(sill.id, neutPositionId, undefined, update);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['ApiClient.Pim.Sill.getSill', sill.id]).then();
            },
        },
    );

    const deleteMutation = useEkosietMutation<void, NotFound, {
        neutPositionId: string,
    }>(async ({neutPositionId}) => {
            await ApiClient.Pim.SillNeutPosition.deleteNeutPosition(sill.id, neutPositionId);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['ApiClient.Pim.Sill.getSill', sill.id]).then();
            },
        },
    );

    const updateCompartmentLayoutMutation = useEkosietMutation<void, NotFound | ValidationFailed, {
        neutPositionId: string,
        compartmentLayoutTypeId: string,
        compartmentLayout: SillNeutPositionCompartmentLayoutStandard | SillNeutPositionCompartmentLayoutHefSchuif,
    }>(async ({neutPositionId, compartmentLayoutTypeId, compartmentLayout}) => {
            await ApiClient.Pim.SillNeutPosition.upsertNeutPositionCompartmentLayout(sill.id, neutPositionId, compartmentLayoutTypeId, undefined, compartmentLayout
            );
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['ApiClient.Pim.Sill.getSill', sill.id]).then();
            },
        },
    );

    const removeCompartmentLayoutMutation = useEkosietMutation<void, NotFound | ValidationFailed, {
        neutPositionId: string,
        compartmentLayoutTypeId: string,
    }>(async ({neutPositionId, compartmentLayoutTypeId}) => {
            await ApiClient.Pim.SillNeutPosition.deleteNeutPositionCompartmentLayout(sill.id, neutPositionId, compartmentLayoutTypeId, undefined);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['ApiClient.Pim.Sill.getSill', sill.id]).then();
            },
        },
    );

    const confirmDialog = useConfirmDialog();


    const add = async (neutPosition: AddNeutPosition): Promise<void> => {
        await addMutation.mutateAsync({
            neutPosition: neutPosition
        });
    };

    const update = async (neutPositionId: string, update: SillNeutPositionUpdate): Promise<void> => {
        await updateMutation.mutateAsync({
            neutPositionId: neutPositionId,
            update: update
        });
    }

    const remove = async (neutPositionId: string): Promise<void> => {
        await deleteMutation.mutateAsync({
            neutPositionId: neutPositionId
        });
    };

    const confirmRemove = async (neutPositionId: string) => {
        await confirmDialog({
            onConfirm: async () => await remove(neutPositionId),
            title: 'Neut positie verwijderen',
            message: 'Weet je zeker dat je deze neut positie wilt verwijderen?',
            dialogStyle: 'danger'
        });
    };


    const addCompartmentLayout = async (neutPosition: SillDetailsNeutPosition, compartmentLayoutId: string, compartmentLayout: SillNeutPositionCompartmentLayoutStandard | SillNeutPositionCompartmentLayoutHefSchuif): Promise<void> => {
        await updateCompartmentLayoutMutation.mutateAsync({
            neutPositionId: neutPosition.id,
            compartmentLayoutTypeId: compartmentLayoutId,
            compartmentLayout: compartmentLayout
        });
    };

    const removeCompartmentLayout = async (neutPosition: SillDetailsNeutPosition, compartmentLayoutId: string): Promise<void> => {
        await removeCompartmentLayoutMutation.mutateAsync({
            neutPositionId: neutPosition.id,
            compartmentLayoutTypeId: compartmentLayoutId
        });
    };

    const confirmRemoveCompartmentLayout = async (neutPosition: SillDetailsNeutPosition, compartmentLayoutId: string) => {
        await confirmDialog({
            onConfirm: async () => await removeCompartmentLayout(neutPosition, compartmentLayoutId),
            title: 'Vakindeling verwijderen',
            message: 'Weet je zeker dat je de vakindeling wilt verwijderen?',
            dialogStyle: 'danger'
        });
    };

    const {open: openAddModal, modal: addModalElement} = useAddNeutPositionModal({
        sill: sill,
        onSave: add
    });


    const {
        open: openEditModel,
        element: editModalElement
    } = useSillNeutPositionEditModal({save: update});

    const {
        open: openCompartmentLayoutModal,
        element: compartmentLayoutModalElement
    } = useNeutPositionCompartmentLayoutModal({
        sillId: sill.id,
        save: async (neutPositionId: string, compartmentLayoutTypeId: string, compartmentLayout: SillNeutPositionCompartmentLayout) => {
            await updateCompartmentLayoutMutation.mutateAsync({
                neutPositionId: neutPositionId,
                compartmentLayoutTypeId: compartmentLayoutTypeId,
                compartmentLayout: compartmentLayout
            });
        }
    })


    const getAvailableCompartmentLayouts = (neutPosition: SillDetailsNeutPosition): SillDetailsCompartmentLayout[] => {
        const existing = neutPosition.compartmentLayouts.map((cl) => cl.compartmentLayoutTypeCode);
        return sill.compartmentLayouts.filter((cl) => !existing.includes(cl.code));
    };

    return {
        openAddModal,
        addModalElement,

        openEditModel,
        editModalElement,

        confirmRemove,

        getAvailableCompartmentLayouts,
        addCompartmentLayout,
        confirmRemoveCompartmentLayout,

        openCompartmentLayoutModal,
        compartmentLayoutModalElement
    }
};
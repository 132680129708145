import { toast } from "react-hot-toast"
import ApiClient from "../../../../../modules/api-client/ApiClient";
import { useEkosietMutation } from "../../../../../shared/hooks/useEkosietMutation";
import { NotFound, ValidationFailed } from "../../../../../modules/api-client/Responses";
import { useNavigate } from "react-router-dom";
import { SmRoutePath } from "../../SmRoutes";
import {useEkosietAuth0} from "../../../../../modules/auth0/core/useEkosietAuth0";
import {CustomerRoutePath} from "../../../../../customer-portal/routing/RoutePath";

export const useFastCopyOrder = (id: string) => {
    const navigate = useNavigate();
    const {isKlant} = useEkosietAuth0();

    return useEkosietMutation<string, NotFound | ValidationFailed, boolean>
        ((copyOrderDate) => {
            return ApiClient.Sm.Order.smOrderIdFastCopyPost(id, undefined, copyOrderDate).then(response => response.data);
        },
            {
                onSuccess: (newOrderId) => {
                    if(isKlant){
                        navigate(CustomerRoutePath.link(CustomerRoutePath.orderLink(newOrderId)));
                    } else {
                        navigate(SmRoutePath.link(SmRoutePath.orderEditLink(newOrderId)));

                    }
                },
                onError: () => {
                    toast.error("Kopieren van order mislukt!");
                }
            }
        );
};
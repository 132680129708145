import { FC } from 'react'
import * as Yup from 'yup'
import { Form, Formik, FormikHelpers } from 'formik'
import {usePutProductionItemRemark} from "../hooks/use-put-production-item-remark";
import {PutProductionItemRemarkRepresentation} from "../../../../modules/api-client/generated";

type Props = {
    ids: string[]
    remark?: string|null
    handleClose: () => void,
}

const PutRemarkForm: FC<Props> = ({ ids, remark,  handleClose }) => {

    const mutation = usePutProductionItemRemark();

    const initialValues: PutProductionItemRemarkRepresentation = {
        productionItemIds: ids,
        remark: remark ?? '',
    }

    const schema = {
        remark: Yup.string().notRequired().nullable()
    }
    const formSchema = () => Yup.lazy((values) => {
        return Yup.object().shape(schema)
    });

    //
    const onSubmit = (values: PutProductionItemRemarkRepresentation, { setSubmitting }: FormikHelpers<PutProductionItemRemarkRepresentation>) => {

        return new Promise<void>((resolve, reject) => {
            mutation.mutate(values, {
                onSuccess: (model) => {
                    resolve();
                    handleClose()
                },
                onError: () => {
                    reject();
                }
            });

        }).finally(() => setSubmitting(false));
    };


    return (
        <>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={formSchema}>
                {({
                    values,
                    handleChange,
                    handleSubmit,
                    getFieldProps,
                    isSubmitting,
                }) => (
                    <Form >
                        <label className='form-label' htmlFor={'remark'}>
                            Opmerking
                        </label>



<textarea rows={5} className={'form-control'}   {...getFieldProps('remark')}>

</textarea>


                        <div className='text-center pt-5'>
                            <button
                                type='reset'
                                className='btn btn-light me-3'
                                data-kt-customer-modal-action='cancel'
                                data-dismiss="modal"
                                onClick={() => handleClose()}
                                disabled={isSubmitting}
                            >
                                Annuleren
                            </button>

                            <button
                                type='submit'
                                className='btn btn-primary'
                                data-kt-customer-modal-action='submit'
                                disabled={isSubmitting}
                            >
                                <span className='indicator-label'>Opslaan
                                </span>
                                {isSubmitting && (
                                    <span className='indicator-progress'>
                                        Even wachten a.u.b
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                        {/* end::Actions */}

                    </Form>
                )}
            </Formik>
        </>
    )
}

export { PutRemarkForm }

import {FC} from 'react'
import {FieldArray, Form, Formik, FormikHelpers} from 'formik'
import {
    OrderLineRepresentation,
    SbomModel,
} from '../../../../../../../../modules/api-client/generated'
import TextField from "../../../../../../../../shared/components/TextField";
import {useGetOrder} from '../../../../hooks/use-get-order';
import {usePutSbom} from "../../../../hooks/orderline/use-put-sbom";

type Props = {
    line: OrderLineRepresentation
    handleClose: () => void,
}

const PutSbomForm: FC<Props> = ({line, handleClose}) => {
    const mutation = usePutSbom();
    const {refetch: refetchOrder} = useGetOrder(line.orderId, true);    
    const formValues: SbomModel = line.sbom
    const onSubmit = (values: SbomModel, {setSubmitting}: FormikHelpers<SbomModel>) => {

        return new Promise<void>((resolve, reject) => {
            mutation.mutate({orderLineId: line.id, sbom: values}, {
                onSuccess: () => {
                    resolve();
                    handleClose()
                },
                onError: () => {
                    reject();
                }
            });

        }).finally(() => {
            setSubmitting(false);
            refetchOrder();
        });
    };

    return (
        <>
            <Formik initialValues={formValues} onSubmit={onSubmit} >
                {({
                    values,
                      getFieldProps,
                      isSubmitting,
                  }) => (
                    <Form>
                        <FieldArray name="lines">
                            {({ insert, remove, push }) => (
                                <div>
                                    {values.lines.length > 0 &&
                                        values.lines.map((line, index) => (
                                            <div className="row" key={index}>
                                                <div className="col">
                                                    <TextField
                                                        name={`lines.${index}.quantity`}
                                                        placeholder="quantity"
                                                        type="number"
                                                    />
                                                </div>
                                                <div className="col">
                                                    <TextField
                                                        name={`lines.${index}.description`}
                                                        placeholder="description"
                                                        disabled={!!line.catalogItem?.id}
                                                        type="text"
                                                    />
                                                </div>
                                                <div className="col">
                                                    <TextField
                                                        name={`lines.${index}.price`}
                                                        placeholder="price"
                                                        type="number"
                                                    />
                                                </div>
                                                <div className="col">
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-secondary"
                                                        onClick={() => remove(index)}
                                                    >
                                                        X
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-sm"
                                        onClick={() => push({
                                            description: "", price: 0, quantity: 1, total: 0,
                                            catalogItem:null

                                        })}
                                    >
                                        Prijscomponent toevoegen
                                    </button>
                                </div>
                            )}
                        </FieldArray>
                    
                        <div className='text-center pt-5'>
                            <button
                                type='reset'
                                className='btn btn-light me-3'
                                data-kt-customer-modal-action='cancel'
                                data-dismiss="modal"
                                onClick={() => handleClose()}
                                disabled={isSubmitting}
                            >
                                Annuleren
                            </button>

                            <button
                                type='submit'
                                className='btn btn-primary'
                                data-kt-customer-modal-action='submit'
                                disabled={isSubmitting}
                            >
                                <span className='indicator-label'>Opslaan
                                </span>
                                {isSubmitting && (
                                    <span className='indicator-progress'>
                                        Even wachten a.u.b
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                        {/* end::Actions */}

                    </Form>
                )}
            </Formik>
        </>
    )
}

export {PutSbomForm}

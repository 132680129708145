import React from "react";
import {PageTitle} from "../../../../../_metronic/layout/core";
import {EkoCard, EkoCardBody} from "../../../../../_metronic/helpers";
import {FilterContextProvider} from "../../../../shared/context/FilterContext";
import {InvoiceSearch} from "../invoices/components/InvoiceSearch";
import {InvoiceTable} from "../invoices/components/InvoiceTable";

const ExpeditionList: React.FC = () => {

    return (
        <>
            <PageTitle>
                Factuur overzicht
            </PageTitle>
            <FilterContextProvider>
                <EkoCard className={'mb-4'}>
                    <EkoCardBody>
                <InvoiceSearch/>
                    </EkoCardBody>
                </EkoCard>
                <EkoCard>
                    <EkoCardBody>
                        <InvoiceTable/>
                    </EkoCardBody>
                </EkoCard>
            </FilterContextProvider>

        </>
    )
}

export default ExpeditionList

import {toast} from "react-hot-toast"
import {useEkosietMutation} from "../../../../shared/hooks/useEkosietMutation";
import {NotFound, ValidationFailed} from "../../../../modules/api-client/Responses";
import ApiClient from "../../../../modules/api-client/ApiClient";
import {
    PostProductionBatchFromFilterRepresentation,
    PostProductionBatchRepresentation,
    ProductionBatchRepresentation
} from "../../../../modules/api-client/generated";
import {useNavigate} from "react-router-dom";
import {PmRoutePath} from "../PmRoutes";

export const usePostBatch = () => {

    const navigate = useNavigate();

    return useEkosietMutation<ProductionBatchRepresentation, NotFound | ValidationFailed, PostProductionBatchRepresentation>((data) => {
            return ApiClient.Pm.ProductionBatch.pmProductionBatchPost(undefined, data).then(response => response.data);
        },
        {
            onSuccess: (batch) => {
                navigate(PmRoutePath.link(PmRoutePath.productionBatchLink(batch.id)));
            },
            onError: () => {
                toast.error("Batch aanmaken mislukt");
            }
        }
    );
};

export const usePostBatchCreateFromFilter = () => {

    const navigate = useNavigate();

    return useEkosietMutation<ProductionBatchRepresentation, NotFound | ValidationFailed, PostProductionBatchFromFilterRepresentation>((data) => {
            return ApiClient.Pm.ProductionBatch.pmProductionBatchCreateFromFilterPost(undefined, data).then(response => response.data);
        },
        {
            onSuccess: (batch) => {
                navigate(PmRoutePath.link(PmRoutePath.productionBatchLink(batch.id)));
            },
            onError: () => {
                toast.error("Batch aanmaken mislukt");
            }
        }
    );
};
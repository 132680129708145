import React from 'react';
import {useQuery} from '@tanstack/react-query';
import ApiClient from '../../../../modules/api-client/ApiClient';
import {
    WeeklyExpeditionStatsRow,
} from '../../../../modules/api-client/generated';
import Loading from '../../../../shared/components/Loading';
import Euro from "../../../../shared/components/Euro";
import {NumericFormat} from "react-number-format";
import {KTSVG} from "../../../../shared/components/KTSVG";
import {Line} from "react-chartjs-2";
import {StatsLineChartOptions} from "../../../../shared/components/Chart";

type Props = {
    className: string
    description: string
    color: string
    img: string
}
export const YTDExpeditionStatsTile: React.FC<Props> = ({className, description, color, img}) => {

    const {isInitialLoading, data} = useQuery<{ [key: string]: { [key: string]: WeeklyExpeditionStatsRow; }; }>(
        ['weeklyExpeditionStats'],
        () => ApiClient.Em.Reporting.weeklyExpeditionStats().then((res) => res.data));
    if (!data) return <Loading/>

    if (isInitialLoading) return <Loading/>
    return (
        <>


            {Object.keys(data).reverse().map((year) => {
                return (
                    <>
                        {Object.keys(data[year]).reverse().filter((v, i) => {
                            return i == 0
                        }).map((week) => {

                            const yearData = {
                                labels: Object.keys(data[year]).map((r)=>r),
                                datasets: [
                                    {
                                        tension: 0.3,
                                        label: 'Orders',
                                        borderColor: '#ffffff',
                                        borderWidth: 3,
                                        fill: false,
                                        pointStyle: 'circle',
                                        backgroundColor:'#ffffff',
                                        pointHoverBackgroundColor:'#093869',
                                        pointRadius: 3,
                                        pointHoverRadius: 5,
                                        data: Object.values(data[year]).map((r,)=>r.orders),
                                    },


                                ],
                            };
                            return (
                                <div key={week} className="card mb-5 mb-xl-8">
                                    <div className="card-body p-0">
                                        <div className="px-9 pt-7 card-rounded h-475px w-100 bg-warning">
                                            <div className="d-flex flex-stack"><h3
                                                className="m-0 text-white fw-bold fs-3">Jaaroverzicht {year}</h3>
                                            </div>
                                            <Line options={StatsLineChartOptions} data={yearData}/>

                                            <div className="d-flex text-center flex-column text-white pt-8"><span
                                                className="fw-semibold fs-7">Orders afgeleverd</span><span
                                                className="fw-bold fs-2x pt-1">{data[year][week].ordersYTD}</span></div>
                                        </div>
                                        <div
                                            className="shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-body"
                                            style={{marginTop: '-100px'}}>
                                            <div className="d-flex align-items-center mb-6">
                                                <div className="symbol symbol-45px w-40px me-5"><span
                                                    className="symbol-label bg-lighten">
                                                      <KTSVG path={'/media/icons/duotune/general/gen005.svg'} className='svg-icon-2' />
                                                </span>
                                                </div>
                                                <div className="d-flex align-items-center flex-wrap w-100">
                                                    <div className="mb-1 pe-3 flex-grow-1"><a href="#"
                                                                                              className="fs-5 text-gray-800 text-hover-primary fw-bold">Omzet</a>
                                                        <div className="text-gray-400 fw-semibold fs-7">
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div
                                                            className="fw-bold fs-5 text-gray-800 pe-1">
                                                            <Euro
                                                                value={data[year][week].omzetYTD}/></div>
                                                        <i className="ki-duotone ki-arrow-up fs-5 text-success ms-1"><span
                                                            className="path1"></span><span className="path2"></span></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center mb-6">
                                                <div className="symbol symbol-45px w-40px me-5"><span
                                                    className="symbol-label bg-lighten">
                                                      <KTSVG path={'/media/icons/duotune/finance/fin001.svg'} className='svg-icon-2' />
                                                </span>
                                                </div>
                                                <div className="d-flex align-items-center flex-wrap w-100">
                                                    <div className="mb-1 pe-3 flex-grow-1"><a href="#"
                                                                                              className="fs-5 text-gray-800 text-hover-primary fw-bold">Dorpels</a>
                                                        <div className="text-gray-400 fw-semibold fs-7">
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div
                                                            className="fw-bold fs-5 text-gray-800 pe-1">{data[year][week].dorpelsYTD}</div>
                                                        <i className="ki-duotone ki-arrow-down fs-5 text-danger ms-1"><span
                                                            className="path1"></span><span className="path2"></span></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center mb-6">
                                                <div className="symbol symbol-45px w-40px me-5"><span
                                                    className="symbol-label bg-lighten">
                                                      <KTSVG path={'/media/icons/duotune/general/gen056.svg'} className='svg-icon-2' />
                                                </span>
                                                </div>
                                                <div className="d-flex align-items-center flex-wrap w-100">
                                                    <div className="mb-1 pe-3 flex-grow-1"><a href="#"
                                                                                              className="fs-5 text-gray-800 text-hover-primary fw-bold">Neuten</a>
                                                        <div
                                                            className="text-gray-400 fw-semibold fs-7"> Gem. <NumericFormat
                                                            className={'me-1 text-nowrap'} decimalScale={2}
                                                            value={data[year][week].avgNeutenYTD} displayType={'text'}
                                                            thousandSeparator={true} suffix={' stuks'}/>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="fw-bold fs-5 text-gray-800 pe-1"><NumericFormat
                                                            className={'me-1 text-nowrap'} decimalScale={0}
                                                            value={data[year][week].neutenYTD} displayType={'text'}
                                                            thousandSeparator={true} suffix={' stuks'}/></div>
                                                        <i className="ki-duotone ki-arrow-down fs-5 text-danger ms-1"><span
                                                            className="path1"></span><span className="path2"></span></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center mb-6">
                                                <div className="symbol symbol-45px w-40px me-5"><span
                                                    className="symbol-label bg-lighten">
                                                      <KTSVG path={'/media/icons/duotune/abstract/abs002.svg'} className='svg-icon-2' />
                                                </span>
                                                </div>
                                                <div className="d-flex align-items-center flex-wrap w-100">
                                                    <div className="mb-1 pe-3 flex-grow-1"><a href="#"
                                                                                              className="fs-5 text-gray-800 text-hover-primary fw-bold">Lengte</a>
                                                        <div
                                                            className="text-gray-400 fw-semibold fs-7"> Gem. <NumericFormat
                                                            className={'me-1 text-nowrap'} decimalScale={2}
                                                            value={data[year][week].avgLengteYTD} displayType={'text'}
                                                            thousandSeparator={true} suffix={' m'}/>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="fw-bold fs-5 text-gray-800 pe-1"><NumericFormat
                                                            className={'me-1 text-nowrap'} decimalScale={2}
                                                            value={data[year][week].meterYTD} displayType={'text'}
                                                            thousandSeparator={true} suffix={' m'}/></div>
                                                        <i className="ki-duotone ki-arrow-down fs-5 text-danger ms-1"><span
                                                            className="path1"></span><span className="path2"></span></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            )
                        })
                        }

                    </>
                )

            })}
        </>
    );
};
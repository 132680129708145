/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {EkoCard, EkoCardBody} from "../../../../../_metronic/helpers";
import {ProductionItemOperationsRepresentation} from '../../../../modules/api-client/generated';
import {useQuery} from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import Loading from "../../../../shared/components/Loading";
import {Link} from "react-router-dom";
import {PmRoutePath} from "../PmRoutes";


type Props = {
    barcode: string
}
export const ProductionItemsOperations: React.FC<Props> = ({barcode}) => {

    const {isInitialLoading, data: list, isError} = useQuery<ProductionItemOperationsRepresentation>(
        ['productionItemOperations', barcode],
        () => ApiClient.Pm.ProductionItem.getOperations(barcode).then((res) => res.data),
    );

    if (isInitialLoading && !list) {
        return <Loading/>;
    }

    if (isError) {
        return <>ERROR!</>;
    }

    return (
            <EkoCard>
                <EkoCardBody>
                    {list?.operations.length === 0 && <div>Geen operaties gevonden</div>}
                    {list?.operations.map((item, index) => (
                        <div key={index} className={'d-flex justify-content-between'}>
                            <div><strong>{item.operation.title}</strong></div>
                            <div>{item.status}</div>
                            <div><Link to={PmRoutePath.operationLink(item.id)}>{item.status}</Link></div>
                        </div>
                    ))}
                </EkoCardBody>
            </EkoCard>
    )
}


import {useQuery} from '@tanstack/react-query';
import ApiClient from '../../../modules/api-client/ApiClient';
import {AxiosError} from 'axios';
import {toast} from 'react-hot-toast';
import {isNotEmpty} from '../../../../_metronic/helpers';
import { ImportProfileMappingRepresentation } from '../../../modules/api-client/generated';

export const useGetImportProfileMapping = (id: string | undefined, enabled: boolean = true) => {
	const response = useQuery<ImportProfileMappingRepresentation, AxiosError, ImportProfileMappingRepresentation>(
		['aclImportMappingProfileIdGet', id],
		() => {
			return ApiClient.Acl.ImportMapping.aclImportMappingProfileIdGet(id!).then((res) => res.data);
		},
		{
			useErrorBoundary: true,
			enabled: enabled && isNotEmpty(id),
		}
	);

	if (response.error) {
		var error = response.error;
		toast.error(error?.message);
	}

	return response;
};

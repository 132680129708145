import {FC} from 'react'
import {WithChildren} from '../../../../_metronic/helpers'

type Props = {
  className?: string
  scroll?: boolean
  height?: number
  title?: string
}

const EkoCardToolbar: FC<Props & WithChildren> = ({className, scroll, height, children,title}) => {
  return (
      <div className="card-toolbar">
          {children}
      </div>
  )
}

export {EkoCardToolbar}

import React, {useLayoutEffect} from 'react';
import {Formik, FormikHelpers, useFormikContext} from 'formik';
import SubmitButton from '../../../../shared/components/SubmitButton';
import {ValidationErrors} from '../../../../modules/api-client/Responses';
import TextField from '../../../../shared/components/TextField';
import {useCornerPieceQuery, useCornerPieceUpsertMutation} from './hooks';
import Loading from '../../../../shared/components/Loading';
import EkoFormField from '../../../../shared/components/EkoFormField';
import {GlazingBarPicker} from '../glazingbar/GlazingBarPicker';

export interface FormValues {
	code: string;
	active: boolean;
	name: string;
	glazingBarId: string;
	width: number;
	contour: string | undefined;
}

type Props = {
	handleClose: () => void,
	id?: string,
}

const CornerPieceUpsert: React.FC<Props> = ({handleClose, id}) => {

	const {mutate, serverValidationErrors} = useCornerPieceUpsertMutation(id);

	const onSubmit = (values: FormValues, {setSubmitting}: FormikHelpers<FormValues>) => {

		return new Promise<void>((resolve, reject) => {
			mutate(values, {
				onSuccess: () => {
					resolve();
					handleClose();

				},
				onError: () => {
					reject();
				},
			});

		}).finally(() => setSubmitting(false));
	};


	const {data, isInitialLoading, isError} = useCornerPieceQuery(id);
	if (isInitialLoading) return <Loading />;
	if (isError) return (<>Error...</>);
	if (id && !data) return (<>'{id}' niet gevonden.</>);

	const initialValues: FormValues = {
		code: data?.code ?? '',
		active: data?.active ?? false,
		name: data?.name ?? '',
		glazingBarId: data?.glazingBar.id ?? '',
		width: data?.width ?? 0,
		contour: data?.contour ?? '',
	};

	return (
		<>
			<Formik initialValues={initialValues} onSubmit={onSubmit}>
				<UpsertForm id={id} serverValidationErrors={serverValidationErrors} />
			</Formik>
		</>
	);
};


interface UpsertFormProps {
	id: string | undefined;
	serverValidationErrors: ValidationErrors | null;
}

export const UpsertForm: React.FC<UpsertFormProps> = (props) => {
	const {values, handleSubmit, handleChange, isSubmitting, errors, setErrors} = useFormikContext<FormValues>();
	const mergeServerErrors = () => {

		if (props.serverValidationErrors) {
			setErrors({...errors, ...props.serverValidationErrors});
		}
	};
	useLayoutEffect(mergeServerErrors, [props.serverValidationErrors, errors, setErrors]);


	return (<form onSubmit={handleSubmit}>

		<TextField row={true} name={'code'} label={'Unieke code'} required={true} />
		<TextField row={true} name={'name'} label={'Naam'} required={true}/>

		<EkoFormField row={true} label={'Breedte'} field={{type: 'text', name: 'width', className: 'form-control', required: true}} />

		<EkoFormField row={true} label={'Glaslat'} field={{name: 'glazingBarId', component: GlazingBarPicker({required: true, isMulti: false})}} />

		{/*<TextField row={true} name={'width'} label={'Breedte'} />*/}
		{/*<TextField row={true} name={'contour'} label={'Profiel'} help={''} />*/}

		<div className='row mb-4'>
			<div className='col-4'>
				<label className='form-label' htmlFor='type'>Actief</label>
			</div>
			<div className='col-8'>
				<div className='form-check form-switch'>
					<input className='form-check-input' type='checkbox' role='switch'
						   name='active'
						   value='true'
						   onChange={handleChange}
						   checked={values.active}
						   id='active' />
					<label className='form-check-label' htmlFor='active'>Actief</label>
				</div>
			</div>
		</div>

		<div className='row pt-5'>
			<div className='offset-4 col-8 d-flex justify-content-end'>
				<SubmitButton type='submit' className='btn btn-primary'
							  isSubmitting={isSubmitting}>Opslaan</SubmitButton>
			</div>
		</div>

	</form>);
};

export default CornerPieceUpsert;
import React, {ReactElement, useState} from 'react'
import {EkoCard, EkoCardBody} from '../../../../../_metronic/helpers'
import {OrderDetails} from "../orders/edit-order/components/OrderDetails";
import {ShippingDetails} from "../orders/edit-order/components/ShippingDetails";
import {BillingDetails} from "../orders/edit-order/components/BillingDetails";
import {PageTitle} from "../../../../../_metronic/layout/core";
import {ProductionDetails} from "../orders/edit-order/components/ProductionDetails";
import {OrderActions} from "../orders/edit-order/components/OrderActions";
import {OrderRemarks} from "../orders/edit-order/components/OrderRemarks";
import {EditOrderModal} from "../orders/edit-order/components/EditOrderModal";
import {OrderLineTable} from "../orders/edit-order/components/OrderLineTable";
import {useParams} from "react-router-dom";
import {useGetOrder} from "../orders/hooks/use-get-order";
import {EditOrderToolbar} from "../orders/edit-order/components/EditOrderToolbar";
import {EkoCardHeader} from "../../../../shared/components/card/EkoCardHeader";
import {formatDate} from "../../../../shared/components/date";
import {OrderActionLog} from "../orders/edit-order/components/OrderActionLog";


const EditOrder = () => {
    const {id} = useParams<{ id: string }>();
    const {data: order} = useGetOrder(id);
    const [showEditModal, setShowEditModal] = useState<boolean>(false)
    const [modalFullscreen, setModalFullscreen] = useState<true | string | 'sm-down' | 'md-down' | 'lg-down' | 'xl-down' | 'xxl-down'>('xxl-down')
    const [modalTitle, setModalTitle] = useState<string | undefined | null>(null);
    const [modalComponent, setModalComponent] = useState<ReactElement | undefined | null>(null);
    return (<>
            {order?.id &&
                <>
                    <EditOrderModal show={showEditModal} handleClose={() => setShowEditModal(false)}
                                    title={modalTitle}
                                    size={'xl'}
                                    fullscreen={modalFullscreen}
                                    component={modalComponent}
                    />
                    <PageTitle toolbar={
                        <EditOrderToolbar
                            setModalTitle={setModalTitle}
                            setModalComponent={setModalComponent}
                            setShowEditModal={setShowEditModal}
                            orderId={order.id}
                            orderContact={order.orderContact?.email}
                            status={order.status}
                            orderNbr={order.code}
                        />
                    }>Order bewerken</PageTitle>

                    <div className="row mb-5">
                        <div className="col">
                            <OrderDetails
                                code={order.code}
                                companyName={order.customer?.name}
                                status={order.status}
                                orderDate={order.orderDate!}
                                placedDate={order.transitions.placed?.date}
                                person={order.orderContact}
                                reference={order.expedition?.reference}
                                orderId={order.id}
                                customerId={order.customer?.id}
                                setModalTitle={setModalTitle}
                                setModalComponent={setModalComponent}
                                setShowEditModal={setShowEditModal}
                            />
                        </div>
                        <div className="col">
                            <OrderRemarks remarks={order.remarks}
                                          orderId={order.id}
                                          setModalTitle={setModalTitle}
                                          setModalComponent={setModalComponent}
                                          setShowEditModal={setShowEditModal}
                            />
                        </div>
                        <div className="col">
                            {order.id &&
                                <OrderActions
                                    // orderId={order.id}
                                    // plannedProductionDate={order.production?.plannedProductionDate ? new Date(order.production?.plannedProductionDate) : null}
                                    // plannedExpeditionDate={order.expedition?.plannedExpeditionDate ? new Date(order.expedition?.plannedExpeditionDate) : null}
                                    // quoteExpiryDate={order.quoteExpiryDate ? new Date(order.quoteExpiryDate) : null}
                                    // status={order.status} 
                                    order={order}
                                    setModalTitle={setModalTitle}
                                    setModalComponent={setModalComponent}
                                    setShowEditModal={setShowEditModal}

                                />
                            }
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col">

                            <ProductionDetails
                                orderId={order.id}
                                orderCode={order.code}
                                status={order.status}
                                productionDate={order.production.plannedProductionDate ? new Date(order.production.plannedProductionDate) : null}
                                sillCount={order.sillCount}
                                setModalTitle={setModalTitle}
                                setModalComponent={setModalComponent}
                                setShowEditModal={setShowEditModal}
                                productionItemCount={order.production.productionItemCount}
                                productionStatus={order.production.status}/>
                        </div>
                        <div className="col">
                            <ShippingDetails status={order.status}
                                             person={order.expedition?.contactPerson}
                                             deliverycondition={order.expedition?.deliveryCondition}
                                             plannedExpeditionDate={order.expedition?.plannedExpeditionDate ? new Date(order.expedition?.plannedExpeditionDate) : null}
                                             deliveredDate={order.expedition?.delivered?.date ? new Date(order.expedition.delivered.date) : null}
                                             address={order.expedition?.deliveryAddress}
                                             preferredExpeditionDate={order.expedition?.preferredExpeditionDate ? new Date(order.expedition?.preferredExpeditionDate) : null}
                                             orderId={order.id}
                                             urgent={order.urgent}
                                             customerId={order.customer.id}
                                             expeditionStatus={order.expedition?.status}
                                             setModalTitle={setModalTitle}
                                             setModalComponent={setModalComponent}
                                             setShowEditModal={setShowEditModal}
                            />
                        </div>
                        <div className={'col'}>
                            <BillingDetails paymentStatus={order.financial.status}
                                            priceList={order.financial.priceList}
                                            customerId={order.customer.id}
                                            address={order.financial.invoiceAddress}
                                            person={order.financial.contactPerson}
                                            projectDiscount={order.financial.projectDiscount ? order.financial.projectDiscount : 0.00}
                                            paymentCondition={order.financial.paymentCondition}
                                            status={order.status}
                                            orderId={order.id}
                                            setModalTitle={setModalTitle}
                                            setModalComponent={setModalComponent}
                                            setShowEditModal={setShowEditModal}
                            />
                        </div>
                    </div>

                    <div className="tab-content">
                        <div
                            className="tab-pane fade show active"
                            id="kt_ecommerce_sales_order_summary"
                            role="tab-panel"
                        >
                            <div className="d-flex flex-column gap-5" style={{overflowAnchor: "auto"}}>
                                <EkoCard className={'py-4 flex-row-fluid overflow-hidden'} flush={true}>
                                    <EkoCardBody className={'pt-0'}>
                                        <OrderLineTable
                                            orderId={order.id}
                                            customerId={order.customer.id}
                                            pricelistId={order.financial.priceList.id}
                                            productionStatus={order.production.status}
                                            catalogId={order.catalog.id}
                                            lines={order.lines}
                                            projectDiscount={order.financial.projectDiscount}
                                            totalPriceGross={order.financial.totalPriceGross}
                                            totalPriceNet={order.financial.totalPriceNet}
                                            status={order.status}
                                            setModalTitle={setModalTitle}
                                            setModalComponent={setModalComponent}
                                            setModalFullscreen={setModalFullscreen}
                                            setShowEditModal={setShowEditModal}
                                            isOrderEdit={true}
                                        />
                                    </EkoCardBody>
                                </EkoCard>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col col-md-6">
                            <EkoCard>
                                <EkoCardHeader title={'Activiteit'}/>
                                <EkoCardBody>
                                    <OrderActionLog orderId={order.id}/>
                                </EkoCardBody>
                            </EkoCard>
                        </div>
                    </div>
                </>
            }
        </>
    )
}


export {
    EditOrder
}

import { FC } from 'react'
import * as Yup from 'yup'
import { Form, Formik, FormikHelpers } from 'formik'
import {
    PutPricelistRepresentation
} from '../../../../../../../modules/api-client/generated'
import { usePutPricelist } from "../../../hooks/use-put-pricelist";
import { PriceListPicker } from "../../../../../pim/pricelist/PriceListPicker";


type Props = {
    id: string
    pricelistId?: string
    handleClose: () => void,
}

const PutPricelistForm: FC<Props> = ({ id, pricelistId, handleClose }) => {

    const mutation = usePutPricelist(id);

    const initialValues: PutPricelistRepresentation = {
        priceListId: pricelistId ?? '00000000-0000-0000-0000-000000000000'
    }

    const schema = {
        priceListId: Yup.string().required('Prijslijst is verplicht'),
    }
    const formSchema = () => Yup.lazy(() => {
        return Yup.object().shape(schema)
    });
    const onSubmit = (values: PutPricelistRepresentation, { setSubmitting }: FormikHelpers<PutPricelistRepresentation>) => {

        return new Promise<void>((resolve, reject) => {
            mutation.mutate(values, {
                onSuccess: () => {
                    resolve();
                    handleClose()
                },
                onError: () => {
                    reject();
                }
            });

        }).finally(() => setSubmitting(false));
    };


    return (
        <>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={formSchema}>
                {({                    
                    isSubmitting,
                }) => (
                    <Form>

                        <div className='mb-5'>
                            Het wijzigen van de prijslijst heeft mogelijke gevolgen voor alle order regels die gekoppeld zijn aan een catalogus item.
                        </div>

                        {/* begin form element */}
                        <PriceListPicker name={'priceListId'} />

                        {/* begin::Actions */}
                        <div className='text-center pt-5'>
                            <button
                                type='reset'
                                className='btn btn-light me-3'
                                data-kt-customer-modal-action='cancel'
                                data-dismiss="modal"
                                onClick={() => handleClose()}
                                disabled={isSubmitting}
                            >
                                Annuleren
                            </button>

                            <button
                                type='submit'
                                className='btn btn-primary'
                                data-kt-customer-modal-action='submit'
                                disabled={isSubmitting}
                            >
                                <span className='indicator-label'>Opslaan
                                </span>
                                {isSubmitting && (
                                    <span className='indicator-progress'>
                                        Even wachten a.u.b
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                        {/* end::Actions */}

                    </Form>
                )}
            </Formik>
        </>
    )
}

export { PutPricelistForm }

import { ErrorMessage, Field, useFormikContext } from 'formik'
import { FC } from 'react'
import { IAddOrderForm, OrderAddressForm } from '../../types/IAddOrderForm';
import { formatDate } from '../../../../../../../shared/components/date';
import classNames from "classnames";
import {
    OrderContactPersonRepresentation,
    OrderRepresentation
} from '../../../../../../../modules/api-client/generated';
import OrderlineTypeIcon from "../../../../../../../shared/components/OrderlineTypeIcon";
import Euro from "../../../../../../../shared/components/Euro";


const renderAddress = (address: OrderAddressForm) => {
    return (
        <address>
            {address.street} {address.houseNumber}{address.houseNumberAddition}<br />
            {address.extraAddressLine && <>{address.extraAddressLine}<br /></>}
            {address.postalCode}  {address.city}<br />
            {/*{address.country}*/}
        </address>
    )
}

const renderContactPerson = (contactPerson: OrderContactPersonRepresentation) => {
    return (
        <address>
            {contactPerson?.firstName} {contactPerson?.lastName}<br />
            {contactPerson.email && <>{contactPerson?.email}<br /></>}
            {contactPerson.telephone && <>{contactPerson?.telephone}<br /></>}
        </address>
    )
}

type Props = {
    order?: OrderRepresentation
}

const Summary: FC<Props> = ({ order }) => {
    const {
        values,
        getFieldProps
    } = useFormikContext<IAddOrderForm>();
    if (!order) return <></>;


    return (
        <div className='w-100'>
            <div className='pb-8 pb-lg-10'>
                <h2 className='fw-bolder text-dark'>Overzicht</h2>
            </div>

            <div className="row">
                <div className="col-6">

                    <div className="row mb-7">
                        <label className="col-lg-4 fw-bold text-muted">Ordernummer</label>
                        <div className="col-lg-8">
                            <span className="fw-bolder fs-6 text-dark">{values.code}</span>
                        </div>
                    </div>

                    <div className="row mb-7">
                        <label className="col-lg-4 fw-bold text-muted">Referentie</label>
                        <div className="col-lg-8">
                            <span className="fw-bolder fs-6 text-dark">{values.expedition.reference?.reference}</span>
                        </div>
                    </div>

                    {values.contactPerson &&
                        <div className="row mb-7">
                            <label className="col-lg-4 fw-bold text-muted">Contact persoon bestelling</label>
                            <div className="col-lg-8">
                                <div
                                    className="fw-bolder fs-6 text-dark">{renderContactPerson(values.contactPerson)}</div>
                            </div>
                        </div>
                    }

                    <div className="row mb-7">
                        <label className="col-lg-4 fw-bold text-muted">Gewenste leverdatum</label>
                        <div className="col-lg-8">
                            <span
                                className="fw-bolder fs-6 text-dark">{values.expedition.deliveryDateChoice === 'asap' ? 'Z.s.m' : formatDate(values.expedition.preferredExpeditionDate)}</span>
                        </div>
                    </div>

                    <div className="row mb-7">
                        <label className="col-lg-4 fw-bold text-muted">Levering condities</label>
                        <div className="col-lg-8">
                            <span
                                className="fw-bolder fs-6 text-dark">{values.expedition.deliveryCondition === 'afFabriek' ? 'Af fabriek' : 'Bezorging'}</span>
                        </div>
                    </div>

                </div>

                {values.expedition?.deliveryCondition === 'bezorging' &&
                    <div className={classNames("col-3")}>
                        <div className="card">
                            {
                                (values.expedition?.deliveryAddress &&
                                    <>
                                        <h2 className='fw-bolder text-dark'>Afleveradres</h2>
                                        {renderAddress(values.expedition?.deliveryAddress)}
                                    </>
                                )
                            }
                            {
                                (values.expedition?.contactPerson &&
                                    <>
                                        <label className="fw-bold text-muted">Contact Persoon</label>
                                        {renderContactPerson(values.expedition?.contactPerson)}
                                    </>
                                )
                            }
                        </div>
                    </div>
                }

            </div>

            <div className='mb-0'>
                <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-striped table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                        {/* begin::Table head */}
                        <thead>
                            <tr className='fw-bold text-muted'>
                                <th className='w-20px text-begin py-5 ps-5'>#</th>
                                <th className="w-40px text-center py-5"> Aantal</th>
                                <th className="py-5"> Omschrijving</th>
                                <th className="py-5"> Merk</th>
                                <th className="text-end fit-content py-5"> Prijs</th>
                                <th className='text-end fit-content py-5 pe-5'>Totaal</th>
                            </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                            {order.lines && order.lines.map((orderLine) => {
                                return (
                                    <tr key={orderLine.id}>
                                        <td className={'w-20px text-center  ps-5'}><OrderlineTypeIcon
                                            type={orderLine.type} /></td>
                                        <td className={'w-40px text-center'}>{orderLine.quantity}</td>
                                        <td>{orderLine.title}
                                            {orderLine.sku &&
                                                <>
                                                    <div className={'text-muted fs-7'}>{orderLine.sku}</div>
                                                </>
                                            }
                                            {orderLine.remark &&
                                                <>
                                                    <div className={'text-muted fs-7'}>{orderLine.remark}</div>
                                                </>
                                            }
                                        </td>
                                        <td>{orderLine.merk}</td>
                                        <td className={'text-end text-nowrap'}><Euro value={orderLine.price} /></td>
                                        <td className={'text-end  text-nowrap pe-5'}><Euro value={orderLine.total} /></td>
                                    </tr>
                                )
                            })
                            }

                            {order.financial.projectDiscount !== undefined &&
                             order.financial.projectDiscount !== null &&
                             order.financial.projectDiscount > 0 &&
                                <>
                                    <tr>
                                        <td className='text-begin p-5'></td>
                                        <td className="hidden-xs"></td>
                                        <td className="hidden-xs"></td>
                                        <td className="hidden-xs"></td>
                                        <td className="text-end">Subtotaal</td>
                                        <td className='text-end fit-content py-5 pe-5  text-nowrap'><Euro value={order.financial.totalPriceGross} /></td>
                                    </tr>
                                    <tr>
                                        <td className='text-begin p-5'></td>
                                        <td className="hidden-xs"></td>
                                        <td className="hidden-xs"></td>
                                        <td className="hidden-xs"></td>
                                        <td className="text-end">Projectkorting</td>
                                        <td className='text-end fit-content py-5 pe-5  text-nowrap'><Euro value={order.financial.totalPriceGross-order.financial.totalPriceNet} /></td>
                                    </tr>
                                </>
                            }

                            <tr>
                                <td className='text-begin'></td>
                                <td className="hidden-xs"></td>
                                <td className="hidden-xs"></td>
                                <td className="hidden-xs"></td>
                                <td className="text-end">Totaal</td>
                                <td className='text-end fit-content py-5 pe-5 text-nowrap'><Euro value={order.financial.totalPriceNet} /></td>
                            </tr>

                        </tbody>
                        {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                </div>
            </div>

            <div className='fv-row mb-10'>
                <label className='d-flex align-items-center form-label'>
                    <span>Opmerkingen (maximaal 1500 karakters)
                        <span className='text-danger'>
                            &nbsp;<ErrorMessage name="remark" />
                        </span>
                    </span>
                </label>

                <Field as={'textarea'}
                    rows={5}
                    placeholder="Vul hier optioneel uw opmerkingen in"
                    className="form-control form-control-lg "
                    {...getFieldProps('remark')}
                />

            </div>
        </div>
    )
}

export { Summary }
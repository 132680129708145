import React from 'react';


export interface SpinnerButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
	spinning?: boolean;
	dotdotdot?: boolean;
}

const SpinnerButton: React.FC<SpinnerButtonProps> = (props) => {

	const btnProps = {
		...props,
		...{disabled: (props.disabled ?? false) ? true : props.spinning},
	};

	delete btnProps.spinning;
	delete btnProps.dotdotdot;

	return <button {...btnProps}>
		{(props.spinning ?? false) && (<div className='spinner-border spinner-border-sm me-3' role='status'><span
			className='sr-only'>Loading...</span></div>)}
		{props.children}
		{props.spinning && (props.dotdotdot ?? true) && <span>...</span>}
	</button>;
};

export default SpinnerButton;
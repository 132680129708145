/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactElement } from 'react'
import { OrderRemarkRepresentation, OrderRemarkType } from "../../../../../../modules/api-client/generated";
import { useDeleteRemark } from "../../hooks/use-delete-remark";
import { formatDate } from '../../../../../../shared/components/date';
import {useEkosietAuth0} from "../../../../../../modules/auth0/core/useEkosietAuth0";

type Props = {
    remark: OrderRemarkRepresentation,
    setModalComponent: React.Dispatch<React.SetStateAction<ReactElement | undefined | null>>,
    setModalTitle: React.Dispatch<React.SetStateAction<string | undefined | null>>,
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>
}
const Remark: React.FC<Props> = ({ remark, setShowEditModal, setModalTitle, setModalComponent }) => {
    const mutate = useDeleteRemark();

    const remove = (id?: string) => {
        if (!id) return;
        return new Promise<void>((resolve, reject) => {
            mutate.mutate(id);
        });
    }
    return (
        <>

            {remark.type === OrderRemarkType.Wizard &&
                <div className={'d-flex flex-column '}>

                    <span className={'fw-bold'}>                        
                        {formatDate(remark.createdDate, "dd-MM-yyyy HH:mm:ss")} {remark.userDisplayName}</span>
                    <div
                        className="d-flex align-items-center justify-content-start mb-2 text-dark">
                        <span className={'bg-light-warning text-dark p-2'}>{remark.remark}</span>
                    </div>
                </div>
            }

            {remark.type === OrderRemarkType.Internal &&
                <div className={'d-flex flex-column ms-auto justify-content-end'}>

                    <div className={'fs-8 text-muted mb-1 text-end'}>
                        <span className={'fw-bold'}>Intern - </span>
                        {formatDate(remark.createdDate, "dd-MM-yyyy HH:mm:ss")} {remark.userDisplayName}
                        <i className="far fa-circle-xmark ms-2"
                           onClick={() => window.confirm('Weet je het zeker?') && remove(remark.id)}></i>
                    </div>
                    <div
                        className="d-flex align-items-center justify-content-end  mb-2 text-dark">
                        <div className="d-flex flex-column">
                            <span className={'bg-light-info text-info p-2'}>{remark.remark}</span>
                        </div>
                    </div>
                </div>
            }

            {remark.type === OrderRemarkType.External &&
                <>
                    <div className={'d-flex flex-column '}>
                        <div className={'fs-8 text-muted mb-1'}>
                            <i className="far fa-circle-xmark me-2" onClick={() => window.confirm('Weet je het zeker?') && remove(remark.id)}></i>
                            {formatDate(remark.createdDate, "dd-MM-yyyy HH:mm:ss")} {remark.userDisplayName}
                        </div>
                        <div
                            className="d-flex align-items-center justify-content-start mb-2 text-dark">
                            <span className={'bg-light-warning text-dark p-2'}>{remark.remark}</span>

                        </div>
                    </div>
                </>
            }
        </>
    )
}

export { Remark }

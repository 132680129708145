/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {PageTitle} from "../../../../../_metronic/layout/core";
import {NavLink, Outlet} from "react-router-dom";
import {MgtRoutePath} from "../MgtRoutes";

export function MgtProduction() {
    return (
        <>
            <PageTitle toolbar={<>
                <div className={'d-flex align-items-center'}>
                        <NavLink to={MgtRoutePath.link(MgtRoutePath.production)} end
                                 className={({ isActive, isPending }) =>
                                     isPending ? "pending" : isActive ? "btn btn-primary me-2" : "btn btn-secondary me-2"
                                 }>
                            Dashboard
                        </NavLink >
                        <NavLink to={MgtRoutePath.link('productie/' + MgtRoutePath.productionBacklog)}
                                 className={({ isActive, isPending }) =>
                                     isPending ? "pending" : isActive ? "btn btn-primary me-2" : "btn btn-secondary me-2"
                                 }>
                            Backlog
                        </NavLink>
                        <NavLink to={MgtRoutePath.link('productie/' + MgtRoutePath.poDashboard)}
                                 className={({ isActive, isPending }) =>
                                     isPending ? "pending" : isActive ? "btn btn-primary" : "btn btn-secondary"
                                 }>
                            Medewerkers
                        </NavLink>
                </div>
                </>
            }>Productie</PageTitle>

                <Outlet/>
                </>
                )
            }

import React from "react";
import { PageTitle } from "../../../../../_metronic/layout/core";
import { EkoCard, EkoCardBody } from "../../../../../_metronic/helpers";
import { TransportPlanningByWeek } from "../expedition/components/TransportPlanningByWeek";

const TransportPlanning: React.FC = () => {

    return (
        <>
            <PageTitle>
                Ekosiet Transport planning
            </PageTitle>
            <EkoCard>
                <EkoCardBody>
                    <TransportPlanningByWeek />
                </EkoCardBody>
            </EkoCard>
        </>
    )
}

export default TransportPlanning

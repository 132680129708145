import React from "react";
import {PageTitle} from "../../../../../_metronic/layout/core";
import {ProductionItemOperations} from "../../../../modules/api-client/generated";
import {ProductionOperation} from "../components/ProductionOperation";
import {stationLookup} from "../models/stations";

const NeutenMaken: React.FC = () => {
    const scope = [`NeutenGemaakt:ng-0`,`Status:Started`];
    var station = stationLookup.get(ProductionItemOperations.NeutenGemaakt);
    if (!station) {
        throw new Error(`Station not found`);
    }
    return (
        <>
            <PageTitle
                color={station.color}
                breadcrumbs={[]}
            >
                {station.title}
            </PageTitle>
            <ProductionOperation scope={scope} operation={ProductionItemOperations.NeutenGemaakt} />

            {/*<div className={'alert alert-info'}> AFMELDEN OP BASIS VAN BATCH, HIER LIJST MET OPEN NEUTEN BATCHES</div>*/}
        </>
    )
}

export default NeutenMaken

import React, { useCallback, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import { useOutletContext } from "react-router-dom";
import ActiveBadge from "../../../../shared/components/ActiveBadge";
import { EkoCard, EkoCardBody } from "../../../../../_metronic/helpers";
import { TableHeader } from "../../../../shared/components/table/TableHeader";
import { EkoTable } from "../../../../shared/components/table/EkoTable";
import { EkoCardHeader } from "../../../../shared/components/card/EkoCardHeader";
import { EkoCardToolbar } from "../../../../shared/components/card/EkoCardToolbar";
import { MasterdataModalContext } from "../../../pages/MasterData";
import SluitpotBeslagUpsert from "./SluitpotBeslagUpsert";
import { ConfirmDialog } from "../../../../shared/components/ConfirmDialog";
import { SluitpotBeslag } from "../../../../modules/api-client/generated";
import { useSluitpotBeslagDeleteMutation } from "./hooks";
import Loading from "../../../../shared/components/Loading";
import SluitpotBeslagSvg from "../../../../shared/components/SluitpotBeslagSvg";
import SluitpotBeslagImageUpsert from "./SluitpotBeslagImageUpsert";


const SluitpotBeslagList: React.FC = () => {

    const { setShowModal, setModalTitle, setModalComponent } = useOutletContext<MasterdataModalContext>();

    const add = () => {
        setModalComponent(<SluitpotBeslagUpsert handleClose={() => setShowModal(false)} />)
        setModalTitle('Toevoegen')
        setShowModal(true)
    }
    const edit = (SluitpotBeslag: SluitpotBeslag) => {
        setModalComponent(<SluitpotBeslagUpsert id={SluitpotBeslag.id} handleClose={() => setShowModal(false)} />)
        setModalTitle(SluitpotBeslag.name + ' aanpassen')
        setShowModal(true)
    }
    const upsertImage = (SluitpotBeslag: SluitpotBeslag) => {
        setModalComponent(<SluitpotBeslagImageUpsert id={SluitpotBeslag.id} handleClose={() => setShowModal(false)} />)
        setModalTitle(SluitpotBeslag.name + ' afbeelding aanpassen')
        setShowModal(true)
    }

    const {
        isInitialLoading,
        data: list,
        isError
    } = useQuery<SluitpotBeslag[]>(['pimSluitpotBeslagGet'], () => ApiClient.Pim.SluitpotBeslag.pimSluitpotBeslagGet().then((res) => res.data));

    if (isInitialLoading) {
        return <Loading />;
    }

    if (isError) {
        return <>ERROR!</>;
    }

    if (!list) return <></>;

    return <>

        <EkoCard>
            <EkoCardHeader title={'Sluitpotten'}>
                <EkoCardToolbar>
                    <button onClick={() => add()} className="btn btn-primary btn-sm">Toevoegen</button>
                </EkoCardToolbar>
            </EkoCardHeader>
            <EkoCardBody>
                <EkoTable>
                    <colgroup>
                        <col width={'8%'}></col>
                        <col width={'8%'}></col>
                        <col width={'62%'}></col>
                        <col width={'10%'}></col>
                        <col width={'15%'}></col>
                    </colgroup>
                    <TableHeader>
                        <th></th>
                        <th></th>
                        <th>Code</th>
                        <th>Naam</th>
                        <th></th>
                    </TableHeader>
                    <tbody>
                        {(!list || list.length === 0) &&
                            <tr>
                                <td colSpan={5} className="text-center">Geen gegevens</td>
                            </tr>}

                        {list && list.map((data) => <tr key={data.id}>
                            <td>
                                <div>

                                    {data?.image ?

                                        <img src={`data:image/jpg;base64,` + data.image} alt={data.code}
                                            className={'img-fluid'} onClick={() => upsertImage(data)} />
                                        :
                                        <>
                                            <button onClick={() => upsertImage(data)}
                                                className="btn btn-light-primary btn-sm">upload afbeelding
                                            </button>
                                        </>
                                    }
                                </div>
                                <div className="text-center">
                                    {data.imageSize && <>{Math.round(data.imageSize / 1024)}kb</>}
                                </div>
                            </td>
                            <td>
                                <div>

                                    {data?.imageReference?.blobUri &&
                                        <img src={data.imageReference.blobUri} alt={data.imageReference.altText}
                                            className={'img-fluid'} />
                                    }
                                </div>
                            </td>
                            <td className="fit-content">
                                <a href='#' onClick={() => edit(data)}>{data.code}</a>
                            </td>
                            <td>
                                <div>{data?.name}</div>
                                <div className={'text-muted'}>
                                    {data?.description}
                                </div>
                            </td>
                            <td>
                                {data?.svg &&
                                    <SluitpotBeslagSvg svg={data.svg} />
                                }
                            </td>
                            <td className="fit-content">
                                <ActiveBadge value={data.active} />
                            </td>
                            <td className="fit-content">
                                <DeleteButton id={data.id!} />
                            </td>
                        </tr>)}
                    </tbody>
                </EkoTable>
            </EkoCardBody>
        </EkoCard>
    </>
}
const DeleteButton: React.FC<{ id: string }> = ({ id }) => {
    const openDeleteDialog = useRef<(show: boolean) => void>();

    const deleteMutation = useSluitpotBeslagDeleteMutation(id);
    const deleteCallback = useCallback(async () => {
        await deleteMutation.mutateAsync(id!);
    }, [deleteMutation, id]);
    return (
        <>
            <button type="button" className="btn btn-light btn-sm" disabled={false}
                onClick={() => openDeleteDialog.current?.call(this, true)}><i className={'fas fa-trash'}></i>
            </button>
            <ConfirmDialog show={openDeleteDialog}
                onConfirm={deleteCallback}
                dialogStyle="danger"
                title="Verwijderen"
                message="Wil je doorgaan?"
                confirmText="Definitief verwijderen"

            />
        </>

    )
}


export default SluitpotBeslagList;
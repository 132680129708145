import React from 'react';
import Euro from "../../../../shared/components/Euro";
import {CustomerSillStats} from "../../../../modules/api-client/generated";
import {NumericFormat} from "react-number-format";
import {toAbsoluteUrl} from "../../../../../_metronic/helpers";

type Props = {
    data: CustomerSillStats
    title: string
}
export const CustomerSillsStats: React.FC<Props> = ({data, title}) => {

        return (
            <>
                <div className="card card-flush mb-4 ">
                    <div
                        className="card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-top bgi-position-x-center align-items-start h-250px bg-info"
                        style={{backgroundImage: `url('${toAbsoluteUrl('/media/svg/shapes/wave-bg-purple.svg')}')`}}

                    >
                        <h3 className="card-title align-items-start flex-column text-white pt-15">
                            <span className="fw-bold fs-2x mb-3">{title}</span>
                            <div className="fs-1 text-white">
                                <span className=" fw-bold d-block fs-3qx lh-1 ls-n1 mb-1">
                                {data.dorpels}
                            </span>
                            </div>
                        </h3>
                    </div>
                    <div className="card-body mt-n20">
                        <div className="mt-n20 position-relative">
                            <div className="row g-3 g-lg-6">

                                <div className="col-6">
                                    <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                                        <div className="symbol symbol-30px me-5 mb-8">
                                <span className="symbol-label">
                                    <i className="ki-duotone ki-timer fs-1 text-primary"><span className="path1"></span><span
                                        className="path2"></span><span className="path3"></span></i>
                                </span>
                                        </div>
                                        <div className="m-0">
                                            <span
                                                className="text-gray-700 fw-bold d-block fs-2qx lh-1 ls-n1 mb-1">
                                                       {data.dorpelBI}
                                            </span>
                                            <span className="text-gray-500 fw-semibold fs-6">Dorpels BI</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                                        <div className="symbol symbol-30px me-5 mb-8">
                                <span className="symbol-label">
                                    <i className="ki-duotone ki-timer fs-1 text-primary"><span className="path1"></span><span
                                        className="path2"></span><span className="path3"></span></i>
                                </span>
                                        </div>
                                        <div className="m-0">
                                            <span
                                                className="text-gray-700 fw-bold d-block fs-2qx lh-1 ls-n1 mb-1">
                                                             <NumericFormat
                                                                 className={'me-1 text-nowrap'} decimalScale={2}
                                                                 value={data.lengteBI} displayType={'text'}
                                                                 thousandSeparator={true} suffix={''}/>
                                            </span>
                                            <span className="text-gray-500 fw-semibold fs-6">meter BI</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                                        <div className="symbol symbol-30px me-5 mb-8">
                                <span className="symbol-label">
                                    <i className="ki-duotone ki-timer fs-1 text-primary"><span className="path1"></span><span
                                        className="path2"></span><span className="path3"></span></i>
                                </span>
                                        </div>
                                        <div className="m-0">
                                            <span
                                                className="text-gray-700 fw-bold d-block fs-2qx lh-1 ls-n1 mb-1">
                                                       {data.dorpelBU}
                                            </span>
                                            <span className="text-gray-500 fw-semibold fs-6">Dorpels BU</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                                        <div className="symbol symbol-30px me-5 mb-8">
                                <span className="symbol-label">
                                    <i className="ki-duotone ki-timer fs-1 text-primary"><span className="path1"></span><span
                                        className="path2"></span><span className="path3"></span></i>
                                </span>
                                        </div>
                                        <div className="m-0">
                                            <span
                                                className="text-gray-700 fw-bold d-block fs-2qx lh-1 ls-n1 mb-1">
                                                             <NumericFormat
                                                                 className={'me-1 text-nowrap'} decimalScale={2}
                                                                 value={data.lengteBU} displayType={'text'}
                                                                 thousandSeparator={true} suffix={''}/>
                                            </span>
                                            <span className="text-gray-500 fw-semibold fs-6">meter BU</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                                        <div className="symbol symbol-30px me-5 mb-8">
                                <span className="symbol-label">
                                    <i className="ki-duotone ki-timer fs-1 text-primary"><span className="path1"></span><span
                                        className="path2"></span><span className="path3"></span></i>
                                </span>
                                        </div>
                                        <div className="m-0">
                                            <span
                                                className="text-gray-700 fw-bold d-block fs-2qx lh-1 ls-n1 mb-1">
                                                       {data.dorpelHefSchuif}
                                            </span>
                                            <span className="text-gray-500 fw-semibold fs-6">Dorpels Hefschuif</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                                        <div className="symbol symbol-30px me-5 mb-8">
                                <span className="symbol-label">
                                    <i className="ki-duotone ki-timer fs-1 text-primary"><span className="path1"></span><span
                                        className="path2"></span><span className="path3"></span></i>
                                </span>
                                        </div>
                                        <div className="m-0">
                                            <span
                                                className="text-gray-700 fw-bold d-block fs-2qx lh-1 ls-n1 mb-1">
                                                             <NumericFormat
                                                                 className={'me-1 text-nowrap'} decimalScale={2}
                                                                 value={data.lengteHefSchuif} displayType={'text'}
                                                                 thousandSeparator={true} suffix={''}/>
                                            </span>
                                            <span className="text-gray-500 fw-semibold fs-6">meter Hefschuif</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                                        <div className="symbol symbol-30px me-5 mb-8">
                                <span className="symbol-label">
                                    <i className="ki-duotone ki-timer fs-1 text-primary"><span className="path1"></span><span
                                        className="path2"></span><span className="path3"></span></i>
                                </span>
                                        </div>
                                        <div className="m-0">
                                            <span
                                                className="text-gray-700 fw-bold d-block fs-2qx lh-1 ls-n1 mb-1">
                                                       {data.dorpelZL}
                                            </span>
                                            <span className="text-gray-500 fw-semibold fs-6">Dorpels Zijlicht</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                                        <div className="symbol symbol-30px me-5 mb-8">
                                <span className="symbol-label">
                                    <i className="ki-duotone ki-timer fs-1 text-primary"><span className="path1"></span><span
                                        className="path2"></span><span className="path3"></span></i>
                                </span>
                                        </div>
                                        <div className="m-0">
                                            <span
                                                className="text-gray-700 fw-bold d-block fs-2qx lh-1 ls-n1 mb-1">
                                                             <NumericFormat
                                                                 className={'me-1 text-nowrap'} decimalScale={2}
                                                                 value={data.lengteZL} displayType={'text'}
                                                                 thousandSeparator={true} suffix={''}/>
                                            </span>
                                            <span className="text-gray-500 fw-semibold fs-6">meter Zijlicht</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                                        <div className="symbol symbol-30px me-5 mb-8">
                                <span className="symbol-label">
                                    <i className="ki-duotone ki-flask fs-1 text-primary"><span className="path1"></span><span
                                        className="path2"></span></i>
                                </span>
                                        </div>
                                        <div className="m-0">
                                            <span className="text-gray-700 fw-bold d-block fs-2qx lh-1 ls-n1 mb-1">
                                              <NumericFormat
                                                  className={'me-1 text-nowrap'} decimalScale={0}
                                                  value={data.lengte} displayType={'text'}
                                                  thousandSeparator={true} suffix={''}/>
                                            </span>
                                            <span className="text-gray-500 fw-semibold fs-6"> totale lengte (m)</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                                        <div className="symbol symbol-30px me-5 mb-8">
                                <span className="symbol-label">
                                    <i className="ki-duotone ki-timer fs-1 text-primary"><span className="path1"></span><span
                                        className="path2"></span><span className="path3"></span></i>
                                </span>
                                        </div>
                                        <div className="m-0">
                                            <span
                                                className="text-gray-700 fw-bold d-block fs-2qx lh-1 ls-n1 mb-1">
                                                       <Euro value={data.avgDorpelWaarde}/>
                                            </span>
                                            <span className="text-gray-500 fw-semibold fs-6">Gem prijs</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                                        <div className="symbol symbol-30px me-5 mb-8">
                                <span className="symbol-label">
                                    <i className="ki-duotone ki-bank fs-1 text-primary"><span
                                        className="path1"></span><span className="path2"></span></i>
                                </span>
                                        </div>
                                        <div className="m-0">
                                            <span
                                                className="text-gray-700 fw-bold d-block fs-2qx lh-1 ls-n1 mb-1">
                                    <NumericFormat
                                        className={'me-1 text-nowrap'} decimalScale={2}
                                        value={data.avgLengte} displayType={'text'}
                                        thousandSeparator={true} suffix={''}/>
                                            </span>
                                            <span className="text-gray-500 fw-semibold fs-6">Gem lengte (m)</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                                        <div className="symbol symbol-30px me-5 mb-8">
                                <span className="symbol-label">
                                    <i className="ki-duotone ki-award fs-1 text-primary"><span className="path1"></span><span
                                        className="path2"></span><span className="path3"></span></i>
                                </span>
                                        </div>
                                        <div className="m-0">
                                            <span
                                                className="text-gray-700 fw-bold d-block fs-2qx lh-1 ls-n1 mb-1">
                                                       <NumericFormat
                                                           className={'me-1 text-nowrap'} decimalScale={2}
                                                           value={data.maxLengte} displayType={'text'}
                                                           thousandSeparator={true} suffix={''}/>
                                            </span>

                                            <span className="text-gray-500 fw-semibold fs-6">Max lengte (m)</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                                        <div className="symbol symbol-30px me-5 mb-8">
                                <span className="symbol-label">
                                    <i className="ki-duotone ki-timer fs-1 text-primary"><span className="path1"></span><span
                                        className="path2"></span><span className="path3"></span></i>
                                </span>
                                        </div>
                                        <div className="m-0">
                                            <span
                                                className="text-gray-700 fw-bold d-block fs-2qx lh-1 ls-n1 mb-1">
                                                {data.neuten}
                                            </span>
                                            <span className="text-gray-500 fw-semibold fs-6">Neuten</span>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-6">
                                    <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                                        <div className="symbol symbol-30px me-5 mb-8">
                                <span className="symbol-label">
                                    <i className="ki-duotone ki-timer fs-1 text-primary"><span className="path1"></span><span
                                        className="path2"></span><span className="path3"></span></i>
                                </span>
                                        </div>
                                        <div className="m-0">
                                            <span
                                                className="text-gray-700 fw-bold d-block fs-2qx lh-1 ls-n1 mb-1">
                                                       <NumericFormat
                                                           className={'me-1 text-nowrap'} decimalScale={2}
                                                           value={data.avgNeuten} displayType={'text'}
                                                           thousandSeparator={true} suffix={''}/>
                                            </span>
                                            <span className="text-gray-500 fw-semibold fs-6">neuten per dorpel</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                                        <div className="symbol symbol-30px me-5 mb-8">
                                <span className="symbol-label">
                                    <i className="ki-duotone ki-timer fs-1 text-primary"><span className="path1"></span><span
                                        className="path2"></span><span className="path3"></span></i>
                                </span>
                                        </div>
                                        <div className="m-0">
                                            <span
                                                className="text-gray-700 fw-bold d-block fs-2qx lh-1 ls-n1 mb-1">
                                                       <NumericFormat
                                                           className={'me-1 text-nowrap'} decimalScale={2}
                                                           value={data.boorgaten} displayType={'text'}
                                                           thousandSeparator={true} suffix={''}/>
                                            </span>
                                            <span className="text-gray-500 fw-semibold fs-6">Boorgaten</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                                        <div className="symbol symbol-30px me-5 mb-8">
                                <span className="symbol-label">
                                    <i className="ki-duotone ki-timer fs-1 text-primary"><span className="path1"></span><span
                                        className="path2"></span><span className="path3"></span></i>
                                </span>
                                        </div>
                                        <div className="m-0">
                                            <span
                                                className="text-gray-700 fw-bold d-block fs-2qx lh-1 ls-n1 mb-1">
                                                       <NumericFormat
                                                           className={'me-1 text-nowrap'} decimalScale={2}
                                                           value={data.latten} displayType={'text'}
                                                           thousandSeparator={true} suffix={''}/>
                                            </span>
                                            <span className="text-gray-500 fw-semibold fs-6">Latten</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                                        <div className="symbol symbol-30px me-5 mb-8">
                                <span className="symbol-label">
                                    <i className="ki-duotone ki-timer fs-1 text-primary"><span className="path1"></span><span
                                        className="path2"></span><span className="path3"></span></i>
                                </span>
                                        </div>
                                        <div className="m-0">
                                            <span
                                                className="text-gray-700 fw-bold d-block fs-2qx lh-1 ls-n1 mb-1">
                                                       <NumericFormat
                                                           className={'me-1 text-nowrap'} decimalScale={2}
                                                           value={data.sluitpotten} displayType={'text'}
                                                           thousandSeparator={true} suffix={''}/>
                                            </span>
                                            <span className="text-gray-500 fw-semibold fs-6">Sluitpotten</span>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-6">
                                    <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                                        <div className="symbol symbol-30px me-5 mb-8">
                                <span className="symbol-label">
                                    <i className="ki-duotone ki-timer fs-1 text-primary"><span className="path1"></span><span
                                        className="path2"></span><span className="path3"></span></i>
                                </span>
                                        </div>
                                        <div className="m-0">
                                            <span
                                                className="text-gray-700 fw-bold d-block fs-2qx lh-1 ls-n1 mb-1">
                                                       <NumericFormat
                                                           className={'me-1 text-nowrap'} decimalScale={2}
                                                           value={data.glazingBars} displayType={'text'}
                                                           thousandSeparator={true} suffix={''}/>
                                            </span>
                                            <span className="text-gray-500 fw-semibold fs-6">Glaslatten</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                                        <div className="symbol symbol-30px me-5 mb-8">
                                <span className="symbol-label">
                                    <i className="ki-duotone ki-timer fs-1 text-primary"><span className="path1"></span><span
                                        className="path2"></span><span className="path3"></span></i>
                                </span>
                                        </div>
                                        <div className="m-0">
                                            <span
                                                className="text-gray-700 fw-bold d-block fs-2qx lh-1 ls-n1 mb-1">
                                                       <NumericFormat
                                                           className={'me-1 text-nowrap'} decimalScale={2}
                                                           value={data.glazingProfiles} displayType={'text'}
                                                           thousandSeparator={true} suffix={''}/>
                                            </span>
                                            <span className="text-gray-500 fw-semibold fs-6">Beglazingsprofiel</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                                        <div className="symbol symbol-30px me-5 mb-8">
                                <span className="symbol-label">
                                    <i className="ki-duotone ki-timer fs-1 text-primary"><span className="path1"></span><span
                                        className="path2"></span><span className="path3"></span></i>
                                </span>
                                        </div>
                                        <div className="m-0">
                                            <span
                                                className="text-gray-700 fw-bold d-block fs-2qx lh-1 ls-n1 mb-1">
                                                       <NumericFormat
                                                           className={'me-1 text-nowrap'} decimalScale={2}
                                                           value={data.lProfiles} displayType={'text'}
                                                           thousandSeparator={true} suffix={''}/>
                                            </span>
                                            <span className="text-gray-500 fw-semibold fs-6">L-profielen</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                                        <div className="symbol symbol-30px me-5 mb-8">
                                <span className="symbol-label">
                                    <i className="ki-duotone ki-timer fs-1 text-primary"><span className="path1"></span><span
                                        className="path2"></span><span className="path3"></span></i>
                                </span>
                                        </div>
                                        <div className="m-0">
                                            <span
                                                className="text-gray-700 fw-bold d-block fs-2qx lh-1 ls-n1 mb-1">
                                                       <NumericFormat
                                                           className={'me-1 text-nowrap'} decimalScale={2}
                                                           value={data.stopProfiles} displayType={'text'}
                                                           thousandSeparator={true} suffix={''}/>
                                            </span>
                                            <span className="text-gray-500 fw-semibold fs-6">Aanslagprofiel</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                                        <div className="symbol symbol-30px me-5 mb-8">
                                <span className="symbol-label">
                                    <i className="ki-duotone ki-timer fs-1 text-primary"><span className="path1"></span><span
                                        className="path2"></span><span className="path3"></span></i>
                                </span>
                                        </div>
                                        <div className="m-0">
                                            <span
                                                className="text-gray-700 fw-bold d-block fs-2qx lh-1 ls-n1 mb-1">
                                                       {data.ingepakt}
                                            </span>
                                            <span className="text-gray-500 fw-semibold fs-6">Ingepakt</span>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
;
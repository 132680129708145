import React from "react";
import {HefSchuifSlidingRailType} from "../../../modules/api-client/generated";

export interface HefSchuifSlidingRailTypeFieldProps {
    id?: string;
    value: HefSchuifSlidingRailType | undefined;
    onChange: (railType: HefSchuifSlidingRailType | undefined) => void;
}

export const HefSchuifSlidingRailTypeField: React.FC<HefSchuifSlidingRailTypeFieldProps> = (props) => {

    const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        props.onChange(e.target.value === ''
            ? undefined
            : e.target.value as HefSchuifSlidingRailType);
    };

    return <select className="form-select" id={props.id} value={props.value ?? ''} onChange={onChange}>
        <option value="">Geen looprail</option>
        <option value={HefSchuifSlidingRailType.Laag}>Lage looprail</option>
        <option value={HefSchuifSlidingRailType.Hoog}>Hoge looprail</option>
    </select>;
}

import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { toast } from "react-hot-toast"
import ApiClient from "../../../../../modules/api-client/ApiClient";
import { ExpeditionPlanningByOrderViewArrayODataValue } from "../../../../../modules/api-client/generated";
import { AxiosError } from "axios";

export const useListOrderExpeditionsByWeekOdata = ($filter?: string, enabled: boolean | undefined = true):
    UseQueryResult<ExpeditionPlanningByOrderViewArrayODataValue, unknown> => {

    if ($filter === "") {
        $filter = undefined
    }

    var key = 'emExpeditionOrderExpeditionByWeekGet';

    const response = useQuery([key, $filter], () => {
        return ApiClient.Em.Expedition
            .emExpeditionOrderExpeditionByWeekGet(undefined, undefined, undefined, $filter, undefined, undefined, undefined)
            .then((d) => d.data);
    },
        { enabled: enabled }
    )

    if (response.error) {
        var error = response.error as AxiosError;
        toast.error(error?.message);
        // toast.error(error?.response?.data?.title || 'Onbekende fout!');
    }

    return response;
};

import {useQueryClient} from "@tanstack/react-query";
import {useEkosietMutation} from "../../../../../shared/hooks/useEkosietMutation";
import {SillDetails, SillSluitpotArea} from "../../../../../modules/api-client/generated";
import {NotFound, ValidationFailed} from "../../../../../modules/api-client/Responses";
import ApiClient from "../../../../../modules/api-client/ApiClient";

export const useUpdateSillSluitpotAreasMutation = (id: string) => {

    const queryClient = useQueryClient();

    return useEkosietMutation<SillDetails, NotFound | ValidationFailed, SillSluitpotArea[]>((sluitpotAreas) => {

            return ApiClient.Pim.Sill.putSluitpotAreas(id, undefined, {sluitpotAreas: sluitpotAreas}).then(response => response.data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['ApiClient.Pim.Sill.getSill', id]).then();
                queryClient.invalidateQueries(['ApiClient.Pim.Sill.getSluitpotAreas', id]).then();
            },
        },
    );
};
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactElement, useRef, useState } from 'react'
import { EkoCard, EkoCardBody } from "../../../../../../../_metronic/helpers";
import {
    DeliveryConditions,
    OrderRepresentation,
    OrderStatusCodes, PutAcceptOrderRepresentation
} from "../../../../../../modules/api-client/generated";
import { OrderStatus } from "./OrderStatus";
import { usePutQuoteIssued } from "../../hooks/use-put-quote-issued";
import { PutQuoteExpiryDateForm } from "./forms/PutQuoteExpiryDateForm";
import { usePutQuoteRejected } from "../../hooks/use-put-quote-rejected";
import { usePutQuoteAccepted } from "../../hooks/use-put-quote-accepted";
import { usePutOrderPlaced } from "../../hooks/use-put-order-placed";
import { usePutOrderAccepted } from "../../hooks/use-put-order-accepted";
import { usePutOrderCompleted } from "../../hooks/use-put-order-completed";
import { usePutOrderCancelled } from "../../hooks/use-put-order-cancelled";
import { useDeleteOrder } from "../../hooks/use-delete-order";
import { formatDate } from '../../../../../../shared/components/date';
import { ConfirmDialog } from '../../../../../../shared/components/ConfirmDialog';
import { CustomerRoutePath } from "../../../../../../customer-portal/routing/RoutePath";
import { SmRoutePath } from "../../../SmRoutes";
import { useNavigate } from "react-router-dom";
import { useEkosietAuth0 } from "../../../../../../modules/auth0/core/useEkosietAuth0";
import moment from 'moment';

type Props = {    
    order: OrderRepresentation
    setModalComponent: React.Dispatch<React.SetStateAction<ReactElement | undefined | null>>,
    setModalTitle: React.Dispatch<React.SetStateAction<string | undefined | null>>,
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>,
}
const OrderActions: React.FC<Props> = ({
    order,
    setShowEditModal,
    setModalTitle,
    setModalComponent    
}) => {
    const navigate = useNavigate();
    const { isKlant } = useEkosietAuth0();
    const [isOrderMutating, setIsOrderMutating] = useState(false);
    const issueQuoteMutation = usePutQuoteIssued(order.id)
    const rejectQuoteMutation = usePutQuoteRejected(order.id)
    const acceptQuoteMutation = usePutQuoteAccepted(order.id)
    const placeOrderMutation = usePutOrderPlaced(order.id)
    const acceptOrderMutation = usePutOrderAccepted(order.id)
    const completeOrderMutation = usePutOrderCompleted(order.id)
    const cancelOrderMutation = usePutOrderCancelled(order.id)
    const deleteOrderMutation = useDeleteOrder();
    const openOrderDeleteDialog = useRef<(show: boolean) => void>();
    const openCancelDeleteDialog = useRef<(show: boolean) => void>();

    if (!order) return (<></>)

    const plannedProductionDate = order.production?.plannedProductionDate ? new Date(order.production?.plannedProductionDate) : null
    const plannedExpeditionDate = order.expedition?.plannedExpeditionDate ? new Date(order.expedition?.plannedExpeditionDate) : null
    const quoteExpiryDate = order.quoteExpiryDate ? new Date(order.quoteExpiryDate) : null
    const hasMissingOrderData = !order.orderContact || !order.financial || !order.financial.contactPerson || !order.financial.invoiceAddress || !order.expedition || !order.expedition.contactPerson || (order.expedition.deliveryCondition === DeliveryConditions.Bezorging && !order.expedition.deliveryAddress)

    const issueQuote = async () => {
        if (isOrderMutating) return;
        setIsOrderMutating(true)
        await issueQuoteMutation.mutateAsync(undefined, {}).finally(() => setIsOrderMutating(false));
    }
    const rejectQuote = async () => {
        if (isOrderMutating) return;
        setIsOrderMutating(true)        
        await rejectQuoteMutation.mutateAsync(undefined, {}).finally(() => setIsOrderMutating(false));        
    }
    const acceptQuote = async () => {
        if (isOrderMutating) return;
        setIsOrderMutating(true)        
        acceptQuoteMutation.mutateAsync(undefined, {}).finally(() => setIsOrderMutating(false));
        
    }
    const placeOrder = async () => {
        if (isOrderMutating) return;
        setIsOrderMutating(true)
        await placeOrderMutation.mutateAsync(undefined, {}).finally(() => setIsOrderMutating(false));;        
    }
    const acceptOrder = async (date: Date) => {
        if (isOrderMutating) return;
        setIsOrderMutating(true)
        var put: PutAcceptOrderRepresentation = {
            plannedProductionDate: moment(date).format(moment.HTML5_FMT.DATE)
        }
        await acceptOrderMutation.mutateAsync(put, {}).finally(() => setIsOrderMutating(false));
    }
    const completeOrder = () => {
        if (isOrderMutating) return;
        setIsOrderMutating(true)        
        completeOrderMutation.mutateAsync(undefined, {}).finally(() => setIsOrderMutating(false));        
    }
    const cancelOrder = async () => {
        if (isOrderMutating) return;
        setIsOrderMutating(true)
        await cancelOrderMutation.mutateAsync(undefined, {}).finally(() => setIsOrderMutating(false));        
    }
    const putQuoteExpiryDate = () => {
        setModalComponent(() =>
            <PutQuoteExpiryDateForm
                handleClose={() => setShowEditModal(false)}
                expiryDate={quoteExpiryDate}
                id={order.id}
            />)
        setModalTitle('Wijzig offerte vervaldatum')
        setShowEditModal(true)
    }

    const archive = async () => {
        if (!order || isOrderMutating) return;

        setIsOrderMutating(true)

        await deleteOrderMutation.mutateAsync(order.id).finally(() => setIsOrderMutating(false));        

        if (isKlant) {
            navigate(CustomerRoutePath.dashboard);
        } else {
            navigate(SmRoutePath.link(SmRoutePath.orderList));
        }
    }

    return (
        <>
            <EkoCard className={`py-4 h-100`} flush={true}>

                {/*begin::Card header*/}
                <div className="card-header">
                    <div className="card-title d-flex justify-content-between w-100">
                        <h4>Orderstatus</h4>
                        <h2><OrderStatus status={order.status} className={'fs-4'} /></h2>
                    </div>
                </div>
                {/*end::Card header*/}
                <EkoCardBody className={'pt-0 '}>
                    <div className={'d-flex w-100 justify-content-end'}>

                        {/* als status == DRAFT  */}
                        {order.status === OrderStatusCodes.Draft &&
                            <>
                                {!hasMissingOrderData ?
                                    <>
                                        <div className={'mb-3'}>
                                            <button disabled={isOrderMutating} onClick={() => issueQuote()} className="btn btn-warning me-2">
                                                Offerte aanmaken
                                            </button>

                                            <button disabled={isOrderMutating} onClick={() => placeOrder()}
                                                className="btn btn-primary mt-2">
                                                    Order plaatsen                                                
                                            </button>
                                        </div>
                                    </>

                                    :

                                    <>
                                        <div className={'mb-3'}>
                                            <button 
                                                disabled={isOrderMutating} 
                                                onClick={() => issueQuote()} 
                                                className="btn btn-warning me-2 disabled"
                                                >
                                                Offerte aanmaken<br />
                                                (Vul eerst ontbrekende gegevens in)
                                            </button>

                                            <button 
                                                disabled={isOrderMutating} 
                                                onClick={() => placeOrder()}
                                                className="btn btn-primary disabled mt-2"
                                                >
                                                    Order plaatsen<br />
                                                    (Vul eerst ontbrekende gegevens in)
                                            </button>
                                        </div>
                                    </>
                                }                                
                            </>
                        }
                        {order.status === OrderStatusCodes.QuoteIssued &&
                            <>
                                <div className={'mb-3'}>
                                    <button disabled={isOrderMutating} onClick={() => window.confirm('Weet je het zeker?') && rejectQuote()}
                                        className="btn btn-danger me-2">
                                            Offerte afwijzen
                                    </button>

                                    <button disabled={isOrderMutating} onClick={() => acceptQuote()}
                                        className="btn btn-primary">
                                            Offerte accepteren
                                    </button>
                                </div>
                            </>
                        }

                        {order.status === OrderStatusCodes.Placed &&
                            <>
                                {plannedProductionDate && plannedExpeditionDate ?
                                    <div className={'mb-3'}>
                                        <button disabled={isOrderMutating} onClick={() => acceptOrder(plannedProductionDate)}
                                            className="btn btn-info">Order accepteren
                                        </button>
                                    </div>
                                    :
                                    <div className={'mb-3'}>
                                        <button disabled={isOrderMutating}
                                            className="btn btn-info disabled">Order accepteren<br /> 
                                            {!plannedProductionDate && !plannedExpeditionDate ? "(kies eerst productie & leveringsdatum)" : ""}
                                            {plannedProductionDate && !plannedExpeditionDate ? "(kies eerst leveringsdatum)" : ""}
                                            {!plannedProductionDate && plannedExpeditionDate ? "(kies eerst productiedatum)" : ""}
                                        </button>
                                    </div>
                                }
                            </>
                        }
                        {order.status === OrderStatusCodes.Accepted &&
                            <>
                                <div className={'mb-3'}>
                                    <button disabled={isOrderMutating} onClick={() => completeOrder()}
                                        className="btn btn-success">Order afsluiten</button>
                                </div>
                            </>
                        }
                    </div>

                    <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5">
                        <tbody className="fw-semibold text-gray-600">
                            {order.status === OrderStatusCodes.QuoteIssued && quoteExpiryDate &&
                                <tr>
                                    <td className="text-muted">
                                        <div className="d-flex align-items-center">
                                            Offerte geldig tot:
                                        </div>
                                    </td>
                                    <td className="fw-bold text-end">
                                        <div className="d-flex align-items-center justify-content-end">
                                            <a href="#" onClick={() => putQuoteExpiryDate()}
                                                className="">{formatDate(quoteExpiryDate)}</a>
                                        </div>
                                    </td>
                                </tr>
                            }
                            <tr>
                                <td className="text-muted">
                                    <div className="d-flex align-items-start">
                                        Gevarenzone
                                    </div>
                                </td>
                                <td className="d-flex flex-column fw-bold text-end">

                                    <div className="d-flex align-items-center justify-content-end mb-5">
                                        {order.status !== OrderStatusCodes.Completed && order.status !== OrderStatusCodes.Cancelled ?
                                            <>
                                                <a href="#"
                                                    onClick={() => openCancelDeleteDialog.current?.call(this, true)}
                                                    className="btn btn-light-danger btn-sm">
                                                    Order annuleren
                                                </a>
                                                <ConfirmDialog
                                                    show={openCancelDeleteDialog}
                                                    onConfirm={cancelOrder}
                                                    onHide={() => openCancelDeleteDialog.current?.call(this, false)}
                                                    dialogStyle="danger"
                                                    title="Order Annuleren"
                                                    message="Wil je doorgaan?"
                                                    confirmText="Definitief annuleren"
                                                />
                                            </>
                                            :
                                            <>
                                                <a href="#" className="btn btn-light-danger btn-sm disabled">
                                                    Order annuleren
                                                </a>
                                            </>
                                        }

                                    </div>
                                    {(order.status === OrderStatusCodes.Draft || order.status === OrderStatusCodes.Cancelled) &&
                                        <div className="d-flex align-items-center justify-content-end">
                                            <a href="#"
                                                onClick={() => openOrderDeleteDialog.current?.call(this, true)}
                                                className="btn btn-light-danger btn-sm">Order verwijderen</a>
                                            <ConfirmDialog
                                                show={openOrderDeleteDialog}
                                                onConfirm={archive}
                                                onHide={() => openOrderDeleteDialog.current?.call(this, true)}
                                                dialogStyle="danger"
                                                title="Order Verwijderen"
                                                message="Wil je doorgaan?"
                                                confirmText="Definitief verwijderen"
                                            />
                                        </div>
                                    }

                                </td>
                            </tr>

                        </tbody>
                    </table>
                </EkoCardBody>
            </EkoCard>
        </>
    )
}

export { OrderActions }

import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {REACT_APP_API_AUTHZERO_PROXY_URL} from '../../../../../constants'
import instance from '../../../../../auth/core/AxiosInterceptor'
import {Role, User, UsersQueryResponse} from './_models'

const getUsers = (query: string): Promise<UsersQueryResponse> => {
  return instance
    .get(`${REACT_APP_API_AUTHZERO_PROXY_URL}User/users?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getUsersByCustomerId = (customerId: string, query: string): Promise<UsersQueryResponse> => {
  return instance
    .get(`${REACT_APP_API_AUTHZERO_PROXY_URL}User/users/customer/${customerId}?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getUserById = (id: ID): Promise<User | undefined> => {
  return instance
    .get(`${REACT_APP_API_AUTHZERO_PROXY_URL}User/user/${id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const getEmpById = (): Promise<User | undefined> => {
  return instance
    .post(`${REACT_APP_API_AUTHZERO_PROXY_URL}UserDetails/getdetails`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const getRoles = (): Promise<Role[] | undefined> => {
  return instance
    .get(`${REACT_APP_API_AUTHZERO_PROXY_URL}Roles/roles`)
    .then((response: AxiosResponse<Response<Role[]>>) => response.data)
    .then((response: Response<Role[]>) => response.data)
}

const createUser = (user: any): Promise<User | undefined> => {
  return instance
    .post(`${REACT_APP_API_AUTHZERO_PROXY_URL}User/users`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
    .catch((err) => Promise.reject(err))
}

const updateUser = (user: any): Promise<User | undefined> => {
  return instance
    .put(`${REACT_APP_API_AUTHZERO_PROXY_URL}User/user/${user.data.userId}`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => {

      
     return response as User
    }
      )
    .catch((err) => Promise.reject(err))
}

const updateEmp = (user: any): Promise<User | undefined> => {
  return instance
    .put(`${REACT_APP_API_AUTHZERO_PROXY_URL}UserDetails/update`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
    .catch((err) => Promise.reject(err))
}

const sendVerificationMail = (userId: ID): Promise<void> => {
  return instance
    .post(`${REACT_APP_API_AUTHZERO_PROXY_URL}User/user/${userId}/request-mail`)
    .then(() => {})
    .catch((err) => Promise.reject(err))
}

const deleteUser = (userId: ID): Promise<void> => {
  return instance
    .delete(`${REACT_APP_API_AUTHZERO_PROXY_URL}User/user/${userId}`)
    .then(() => {})
    .catch((err) => Promise.reject(err))
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => instance.delete(`${REACT_APP_API_AUTHZERO_PROXY_URL}User/user/${id}`))
  return axios
    .all(requests)
    .then(() => {})
    .catch((err) => Promise.reject(err))
}

export {
  getUsers,
  deleteUser,
  deleteSelectedUsers,
  getUserById,
  getEmpById,
  createUser,
  updateUser,
  updateEmp,
  getRoles,
  sendVerificationMail,
}

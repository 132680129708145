import {useQueryClient} from "@tanstack/react-query";
import {useEkosietMutation} from "../../../../../shared/hooks/useEkosietMutation";
import {NotFound, ValidationFailed} from "../../../../../modules/api-client/Responses";
import ApiClient from "../../../../../modules/api-client/ApiClient";

export const useSillDeleteMutation = (id: string) => {

    const queryClient = useQueryClient();

    return useEkosietMutation<void, NotFound | ValidationFailed>(async () => {
            let response = await ApiClient.Pim.Sill.deleteSill(id);
            return response.data;
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['ApiClient.Pim.Sill.getSill', id]).then();
            },
        },
    );
};
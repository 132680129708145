import {ToolbarWrapper} from "../toolbar";
import {Content} from "../content";
import {Outlet} from "react-router-dom";
import {usePageData} from "../../core";

const MainWrapper = () => {


  return (
      <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
        <div className='d-flex flex-column flex-column-fluid '>
          <ToolbarWrapper/>
          <Content>
            <Outlet/>
          </Content>
        </div>
        {/*<FooterWrapper />*/}
      </div>
  )
}

export {MainWrapper}

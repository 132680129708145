import {useAuth0} from '@auth0/auth0-react'
import {Navigate} from 'react-router-dom'
import {ROLE_CLAIMS} from '../constants'
const Protected = ({children}: any) => {
  const {isAuthenticated, error, user} = useAuth0()

  const {PUBLIC_URL} = process.env
  if (!isAuthenticated && error === undefined) {
    sessionStorage.setItem('redirect_url', window.location.pathname.replace(PUBLIC_URL, ''))
    return <Navigate to='/auth' replace />
  } else if (error) {
    return <Navigate to='/error/403' replace />
  }

  if (isAuthenticated) {
    const userData: any = user
    const roles = userData[ROLE_CLAIMS]
    if (roles.length <= 0) {
      return <Navigate to='/error/noRole' replace />
    }
  }
  return children
}
export default Protected

import React from "react";
import * as api from "../../../../modules/api-client/generated";


export interface RabbetApplicationTextProps {
    code: string | undefined;
}


const RabbetApplicationText: React.FC<RabbetApplicationTextProps> = (props) => {

    switch (props.code) {
        case api.RabbetApplication.Inside: {
            return (<>Binnen</>);
        }
        case api.RabbetApplication.Outside: {
            return (<>Buiten</>);
        }
        case api.RabbetApplication.InsideAndOutside: {
            return (<>Binnen en buiten</>);
        }
        default: {
            return (<>-</>);
        }
    }


}

export default RabbetApplicationText;
import {useAuth0} from '@auth0/auth0-react'
import {useEffect} from 'react'
import AxiosClient from "../../modules/api-client/AxiosClient";

const instance = AxiosClient;

const AxiosInterceptor = ({children}: any) => {
  const {getAccessTokenSilently} = useAuth0()
  useEffect(() => {
    const resInterceptor = (response: any) => {
      return response
    }

    const errInterceptor = (error: any) => {
      return Promise.reject(error)
    }

    const reqInterceptor = async (config: any) => {
      const token = await getAccessTokenSilently()
      config.headers['Authorization'] = `Bearer ${token}`
      return config
    }
    const responseinterceptor = instance.interceptors.response.use(resInterceptor, errInterceptor)
    const requestInterceptor = instance.interceptors.request.use(reqInterceptor, errInterceptor)
    return () => {
      instance.interceptors.response.eject(responseinterceptor)
      instance.interceptors.request.eject(requestInterceptor)
    }
    //eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [])
  return children
}

export default instance
export {AxiosInterceptor}

import {FC, useRef} from 'react'
import clsx from 'clsx'
import {WithChildren} from "../../../../_metronic/helpers";
import {useDraggable} from "react-use-draggable-scroll";

// Wrapper on html card:
// https://preview.keenthemes.com/metronic8/demo1/documentation/base/cards.html

type Props = {
    classNameDivWrapper?: string
    className?: string
    shadow?: boolean
    condensed?: boolean
}


const EkoTable: FC<Props & WithChildren> = (props) => {

    const {
        classNameDivWrapper,
        className,
        shadow,
        condensed,
        children,
    } = props

    const ref =  useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
    const { events } = useDraggable(ref); // Now we pass the reference to the useDraggable hook:


    return (

        <div
            className={clsx(
            'table-responsive',
                classNameDivWrapper && classNameDivWrapper,
            )}
            {...events}
            style={{overflowX: 'scroll'}}
            ref={ref} >
        <table
            className={clsx(
                'table table-row-bordered table-row-gray-100 align-middle gs-0 ',
                className && className,
                {
                    'gy-1': condensed,
                },
                {
                    'gy-3': !condensed,
                },
                {
                    'shadow-sm': shadow,
                },
            )}
        >
            {children}
        </table>
        </div>
    )
}

export {EkoTable}

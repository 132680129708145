import React from 'react';
import {useQuery} from '@tanstack/react-query';
import ApiClient from '../../../../modules/api-client/ApiClient';
import {
    WeeklyExpeditionStatsRow,
    WeeklyProductionStatsRow,
    WeeklySalesStatsRow
} from '../../../../modules/api-client/generated';
import Loading from '../../../../shared/components/Loading';
import {EkoTable} from "../../../../shared/components/table/EkoTable";
import {TableHeader} from "../../../../shared/components/table/TableHeader";
import Euro from "../../../../shared/components/Euro";
import {NumericFormat} from "react-number-format";
import {bool} from "yup";

export const ExpeditionStats: React.FC = () => {

    const {isInitialLoading, data} = useQuery<{ [key: string]: { [key: string]: WeeklyExpeditionStatsRow; }; }>(
        ['weeklyExpeditionStatsRow'],
        () => ApiClient.Em.Reporting.weeklyExpeditionStats().then((res) => res.data));
    if (!data) return <Loading/>

    if (isInitialLoading) return <Loading/>
	    return (
        <>
            {Object.keys(data).reverse().map((year) => {
                return (
                    <>
                        <h1>
                            {year}
                        </h1>
					<EkoTable className={'table-row-dashed table-row-gray-300 gy-7'}>
						<TableHeader>
							<th>Week</th>
							<th>Orders</th>
							<th>Omzet</th>
							<th>Dorpels</th>
							<th>Neuten</th>
							<th>Lengte</th>
						</TableHeader>
						<tbody>
                        {Object.keys(data[year]).reverse().map((week) => {
							return (
								<tr key={week} className=" fs-6 text-gray-800">
							<td>{week}</td>
									<td>
										{data[year][week].orders}
										{/*<br/>*/}
										{/*<span>{data[year][week].ordersYTD}</span>*/}
									</td>
									<td>
										<Euro value={data[year][week].omzet}/>
										{/*<br/>*/}
										{/*<Euro  value={data[year][week].omzetYTD}/>*/}
									</td>
									<td>
										{data[year][week].dorpels}
										{/*<br/>*/}
										{/*<span>{data[year][week].dorpelsYTD}</span>*/}
									</td>
									<td>
										<NumericFormat className={'me-1 text-nowrap'} decimalScale={0} value={data[year][week].neuten} displayType={'text'} thousandSeparator={true} suffix={' stuks'} /><br/>

										{/*<br/>*/}
										{/*<NumericFormat className={'me-1 text-nowrap'} decimalScale={0} value={data[year][week].neutenYTD} displayType={'text'} thousandSeparator={true} suffix={' stuks'} />*/}
										{/*Gem. <NumericFormat className={'me-1 text-nowrap'} decimalScale={2} value={data[year][week].avgNeuten} displayType={'text'} thousandSeparator={true} suffix={' stuks'} />*/}
										{/*<NumericFormat className={'me-1 text-nowrap'} decimalScale={2} value={data[year][week].avgNeutenYTD} displayType={'text'} thousandSeparator={true} suffix={' stuks'} />*/}
									</td>
									<td>
										<NumericFormat className={'me-1 text-nowrap'} decimalScale={2} value={data[year][week].meter} displayType={'text'} thousandSeparator={true} suffix={' m'} /><br/>
										{/*<br/>*/}
										{/*<NumericFormat className={'me-1 text-nowrap'} decimalScale={2} value={data[year][week].meterYTD} displayType={'text'} thousandSeparator={true} suffix={' m'} />*/}
										{/*Gem. <NumericFormat className={'me-1 text-nowrap'} decimalScale={2} value={data[year][week].avgLengte} displayType={'text'} thousandSeparator={true} suffix={' m'} />*/}
										{/*<NumericFormat className={'me-1 text-nowrap'} decimalScale={2} value={data[year][week].avgLengteYTD} displayType={'text'} thousandSeparator={true} suffix={' m'} />*/}
									</td>
						</tr>
						)
                        })
                        }
						</tbody>
					</EkoTable>
					</>
                )

            })}
        </>
    );
};
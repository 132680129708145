/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useMutation} from "@tanstack/react-query"
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {ID, KTSVG} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useAuth0RolesContext} from '../../core/QueryResponseProvider'
import {deleteRole} from '../../core/_requests'

type Props = {
  id: ID
}

const RolesActionsCell: FC<Props> = ({id}) => {
  const {setItemIdForUpdate, setitemForWarning, setAlert} = useListView()
  const {refetch} = useAuth0RolesContext()
  
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  const openWarningModal = (data: any) => {
    setitemForWarning({
      Id: id,
      message: data.message,
      onClick: data.onClick,
      submitButtonName: data.submitButtonName,
    })
  }

  const deleteItem = useMutation(
    () =>
      deleteRole(id)
        .then()
        .catch((err) => Promise.reject(err)),
    {
      // 💡 response of the mutation is passed to onSuccess
      onSuccess: () => {        
        setAlert({
          isVisible: true,
          title: 'Verwijderen is gelukt',
          message: '',
          status: 'success',
        })
        setTimeout(() => refetch(), 1000)
      },
      onError: (error: any) => {
        setAlert({
          isVisible: true,
          title: 'Verwijderen is mislukt',
          message: error.message,
          status: 'danger',
        })
      },
    }
  )

  return (
    <>
      <a
        href='frontend/src/app/backoffice/modules/um/user-roles/table/columns#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Acties
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            Wijzigen
          </a>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter='delete_row'
            onClick={() => {
              let fun = async () => {
                setitemForWarning({Id: undefined, message: ''})
                await deleteItem.mutateAsync()
              }
              let message = 'Weet je zeker dat je de rol wilt verwijderen?'
              let submitButtonName = 'Ja'
              openWarningModal({
                message: message,
                onClick: fun,
                submitButtonName: submitButtonName,
              })
            }}
          >
            Verwijderen
          </a>
        </div>
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export {RolesActionsCell}

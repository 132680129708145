import React from "react";
import {Field} from "formik";
import {GlazingProfile, StopProfile} from "../../../../modules/api-client/generated";
import {useQuery} from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import CustomSelect, {SelectOption, OptionsType} from "../../../../shared/components/CustomSelect";

export interface StopProfilePickerProps {
    name: string;
    multi?: boolean;
    required?: boolean;
}

export const StopProfilePicker: React.FC<StopProfilePickerProps> = ({name,multi,required}) => {

    let options: OptionsType<SelectOption> = [];

    const {
        isInitialLoading,
        data: list,
        isError
    } = useQuery<StopProfile[]>(['pimStopProfilesGet'], () => ApiClient.Pim.StopProfile.pimStopProfilesGet().then((res) => res.data));


    if (list) {
        options = list.map((item) => {
            return {value: item.id!, label: item.name!}
        })
    }

    return (
        <>
            <Field
                className="custom-select"
                name={name}
                options={options}
                component={
                    CustomSelect
                }
                placeholder={multi? "Kies een of meerdere aanslagprofielen...":'Kies een aanslagprofiel'}
                isMulti={multi}
                isClearable={!required}
            />
        </>
    )
}
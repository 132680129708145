import {useQuery} from "@tanstack/react-query";
import {SillSluitpotArea} from "../../../../../modules/api-client/generated";
import ApiClient from "../../../../../modules/api-client/ApiClient";

export const useSillSluitpotAreasQuery = (sillId: string) => {

    return useQuery<SillSluitpotArea[]>(['ApiClient.Pim.Sill.getSluitpotAreas', sillId],
        () => ApiClient.Pim.Sill.getSluitpotAreas(sillId, undefined).then((res) => res.data),
        {
            useErrorBoundary: true,
            enabled: !!sillId,
        });
};
import { useField } from "formik";
import { DateInput } from "./DateInput";
import FieldErrors from "../FieldErrors";

interface Props {
  fieldName: string,  
  placeholder?: string;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean ;  
}

export const DateField = ({ fieldName, placeholder, minDate, maxDate, disabled }: Props) => {
  
  const [field,,helpers] = useField(fieldName);

  const {setValue} = helpers;

  const setDateFieldValue = (val: Date) => {
    if (val) {
      setValue(val);    
    }
  }

  return (
    <>
      <DateInput 
        value={field.value} 
        disabled={disabled}
        placeholder={placeholder} 
        minDate={minDate} 
        maxDate={maxDate} 
        onChange={setDateFieldValue} 
      />

      <FieldErrors field={fieldName}/>
    </>
  );
};
import React from "react";
import {useQuery} from "@tanstack/react-query";
import ApiClient from "../../modules/api-client/ApiClient";
import Loading from "./Loading";
import Error from "./Error";
import {AxiosResponse} from "axios";
import {ConfiguredNeut, NeutDetails, NeutRepresentation} from "../../modules/api-client/generated";
import ConfiguredNeutSvg from "./ConfiguredNeutSvg";
import {EkoCard, EkoCardBody} from "./card";
import {Link} from "react-router-dom";
import {PmRoutePath} from "../../backoffice/modules/pm/PmRoutes";
import BooleanBadge from "./BooleanBadge";
import {Mm} from "./Mm";

export interface ConfiguredNeutSpecificationProps {
    // Ophalen op basis van volgnummer op dorpel
    sillId?: string
    number?: string

    // ophalen op basis van neutcode
    neutCode?: string

    // Of kant en klare neut
    neut?: NeutDetails

}

export const ProductionNeutSpecification: React.FC<ConfiguredNeutSpecificationProps> = (props) => {

    const {neut: propNeut, neutCode} = props;

    const {isInitialLoading, data: fetchedNeut, isError} = useQuery<NeutDetails, string, NeutDetails>(
        ['getNeutByNeutCode', neutCode],
        async () => ApiClient.Pim.Neuten.getNeutDetails(neutCode!).then((res: AxiosResponse<NeutDetails>) => res.data),
        {
            enabled: !propNeut && !!neutCode,
        }
    );

    const neut = propNeut || fetchedNeut;

    if (isInitialLoading) return <Loading message={'Neut tekening ophalen....'}/>;

    if (isError) return <Error/>;

    if (!neut) return <>Geen neut gevonden</>;

    return (
        <div className={'row'}>
            <div className={'col'}>
                <ConfiguredNeutSvg svg={neut.svg}/>
                <EkoCard className={'border'}>
                    <EkoCardBody>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item d-flex justify-content-between">Code <span
                                className={'fw-bold'}>{neut.code}</span></li>
                            <li className="list-group-item d-flex justify-content-between">Diepte <span
                                className={'fw-bold'}><Mm value={neut.depth}/></span></li>
                            <li className="list-group-item d-flex justify-content-between">Hoogte <span
                                className={'fw-bold'}><Mm value={neut.height}/></span></li>
                            <li className="list-group-item d-flex justify-content-between">Breedte <span
                                className={'fw-bold'}><Mm value={neut.width}/></span></li>
                            <li className="list-group-item d-flex justify-content-between">Profiel links <span
                                className={'fw-bold'}>{neut.profileLeft}</span></li>
                            <li className="list-group-item d-flex justify-content-between">Profiel rechts <span
                                className={'fw-bold'}>{neut.profileRight}</span></li>
                            <li className="list-group-item d-flex justify-content-between">Ingesprongen <span
                                className={'fw-bold'}>  {!neut.neut.isIndented && <BooleanBadge value={false}/>}
                                {neut.neut.isIndented && (
                                    <span>
											Binnen <Mm value={neut.neut.indentInside}/>, buiten <Mm
                                        value={neut.neut.indentOutside}/>
										</span>
                                )}</span></li>
                        </ul>
                    </EkoCardBody>
                </EkoCard>
            </div>
            <div className={'col'}>
                {neut.productionInstructions && (
                    <>
                        <h2>Instructies</h2>
                        <div className={'p-3 mx-1 my-3 bg-light border border-2 border-dashed rounded fs-2'}>

                        {neut.productionInstructions}
                        </div>
                    </>
                )}
                    <>
                        <h2>Onderdelen</h2>
                        <div className="row">
                            {neut.parts.map((part, index) => (
                                <div key={index} className="col-6 ">
                                    <EkoCard className={'border'}>
                                        <EkoCardBody>
                                            <h3>
                                                {neut.parts.length == 1 && ''}
                                                {neut.parts.length > 1 && (
                                                    <>
                                                        {index === 0 && 'Linkerdeel'}
                                                        {index === 1 && neut.parts.length === 2 && 'Rechterdeel'}
                                                        {index === 1 && neut.parts.length === 3 && 'Tussenstuk'}
                                                        {index === 2 && 'Rechterdeel'}
                                                    </>
                                                )}
                                            </h3>
                                            <ConfiguredNeutSvg svg={part.svg}/>
                                        </EkoCardBody>
                                    </EkoCard>
                                </div>
                            ))}
                        </div>
                    </>

            </div>
        </div>
    )
        ;
};

import {useEffect} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {useAuth0} from './core/Auth'

const {PUBLIC_URL} = process.env

export function Logout() {
  const {logout, isAuthenticated} = useAuth0()
  useEffect(() => {
    if (isAuthenticated) {
      logout({returnTo: window.location.origin + PUBLIC_URL})
      document.location.reload()
    }
  }, [logout, isAuthenticated])

  return (
    <Routes>
      <Route path='*' element={<Navigate to='/auth/login' />} />
    </Routes>
  )
}

import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {ErrorBoundary, FallbackProps} from "react-error-boundary";

import React from "react";
import axios from "axios";
import {Account} from "../pages/Account";
import {SharedRoutePath} from "./SharedRoutePath";

const SharedRoutes = () => {
  return (
    <Routes>
        <Route element={<ErrorBoundary FallbackComponent={Fallback}><Outlet/></ErrorBoundary>}>
            <Route path={SharedRoutePath.relative(SharedRoutePath.account)} element={<Account/>}/>
        </Route>
        <Route index element={<Navigate to={SharedRoutePath.account}/>}/>
    </Routes>
  )
}

const Fallback: React.FC<FallbackProps> = (props) => {

    if (axios.isAxiosError(props.error)) {
        if (props.error.response?.status === 404) {
            return <>Not found</>;
        }
        if (props.error.response?.status === 500) {
            return <>Server error</>;
        }
    }

    return <>Error</>;
};

export default SharedRoutes

import React, {FC, useId, useState} from 'react';
import {Button, Modal, Tabs, Tab} from 'react-bootstrap';
import SpinnerButton from '../../../../shared/components/SpinnerButton';
import {modalsRoot} from '../../../../global-helpers';
import {createSearchParams, Outlet, Route, Routes, useNavigate, useSearchParams} from 'react-router-dom';
import {ErrorMessage, Field, Form, Formik, useFormikContext} from 'formik';
import {EkoFormRow} from '../../../../shared/components/EkoFormRow';
import Select from 'react-select';
import ApiClient from '../../../../modules/api-client/ApiClient';
import {useQuery} from '@tanstack/react-query';
import Loading from '../../../../shared/components/Loading';
import Error from '../../../../shared/components/Error';
import {Mm} from '../../../../shared/components';
import {
	CompartmentProfileGenerationPreview,
	CompartmentProfileValue,
	CreateCompartmentProfileModel,
	RabbetApplication,
	RabbetPosition,
} from '../../../../modules/api-client/generated';
import {OnChangeValue} from 'react-select/dist/declarations/src/types';
import {RabbetPositionText} from '../rabbet';
import {EkoTable} from '../../../../shared/components/table';
import {FormikErrors} from 'formik/dist/types';
import {FormikOnChange} from '../../../../shared/components/FormikOnChange';
import {useProfileCreateMutation} from '../profile/hooks';

interface GenerateCompartmentProfileParams {
	rabbetId: string | undefined;
	sillWidths: string[];
	positions: RabbetPosition[];
	cornerProfilesInside: string[];
	cornerProfilesOutside: string[];
}

export const GenerateCompartmentProfilesDialog: FC = () => {

	const navigate = useNavigate();

	const cancel = () => {
		navigate(-1);
	};

	return <>
		<Modal
			container={modalsRoot}
			tabIndex={-1}
			aria-hidden='true'
			autoFocus={true}
			dialogClassName={'modal-dialog'}
			scrollable={true}
			show={true}
			size='lg'
			onHide={cancel}
			animation={false}
			backdrop={'static'}>

			<Routes>
				<Route path='' element={<GenerationParamsStep onCancel={cancel} />} />
				<Route path='preview' element={<GenerationPreviewStep />} />
			</Routes>

			<Outlet />

		</Modal>
	</>;
};


const GenerationParamsStep: FC<{onCancel: () => void}> = (props) => {

	const [searchParams] = useSearchParams();

	const navigate = useNavigate();

	const cancel = () => {
		props.onCancel();
	};

	const onNext = (params: GenerateCompartmentProfileParams) => {

		navigate({
			pathname: 'preview',
			search: createSearchParams({
				rabbetId: params.rabbetId!,
				sillWidths: params.sillWidths,
				positions: params.positions,
				cornerProfilesInside: params.cornerProfilesInside,
				cornerProfilesOutside: params.cornerProfilesOutside,
			}).toString(),
		});
	};

	const formProps: GenerationParamsFormProps = {
		rabbetId: searchParams.get('rabbetId') ?? undefined,
		positions: searchParams.getAll('positions').map(x => x as RabbetPosition) ?? [],
		sillWidths: searchParams.getAll('sillWidths') ?? [],
		cornerProfilesInside: searchParams.getAll('cornerProfilesInside') ?? [],
		cornerProfilesOutside: searchParams.getAll('cornerProfilesOutside') ?? [],
		onCancel: cancel,
		onConfirm: onNext,
	};

	return <GenerationParamsForm {...formProps} />;
};


const GenerationPreviewStep: FC = () => {

	const [searchParams] = useSearchParams();

	const navigate = useNavigate();

	const activateInputId = useId();

	const cancel = () => {
		navigate(-1);
	};

	const onSuccess = () => {
		navigate(-2);
	};

	const selection: GenerateCompartmentProfileParams = {
		rabbetId: searchParams.get('rabbetId')!,
		positions: searchParams.getAll('positions')!.map(x => x as RabbetPosition),
		sillWidths: searchParams.getAll('sillWidths'),
		cornerProfilesInside: searchParams.getAll('cornerProfilesInside')!,
		cornerProfilesOutside: searchParams.getAll('cornerProfilesOutside')!,
	};

	return <GenerationPreviewForm params={selection} onSuccess={onSuccess}>
		{({content, isSubmitting, selection, activate, setActivate}) => <>
			{/*<FormikOnChange onChange={onChange} />*/}
			<Modal.Header closeButton>
				<Modal.Title>Vakprofielen toevoegen</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{content}
			</Modal.Body>
			<Modal.Footer className='justify-content-between'>

				<Button variant='link' className='mx-4' onClick={() => cancel()}>Terug</Button>

				<div className='d-flex gap-5 align-items-center'>
					<div className='form-check form-switch'>
						<input className='form-check-input' type='checkbox' role='switch' id={activateInputId}
							   checked={activate}
							   onChange={(e) => setActivate(e.target.checked)}
						/>
						<label className='form-check-label' htmlFor={activateInputId}>Activeren</label>
					</div>

					<SpinnerButton type='submit' className='btn btn-primary' spinning={isSubmitting}
								   disabled={selection.length === 0 || isSubmitting}>Toevoegen</SpinnerButton>
				</div>
			</Modal.Footer>
		</>}
	</GenerationPreviewForm>;
};

type GenerationParamsFormProps = {
	onConfirm: (selection: GenerateCompartmentProfileParams) => void;
	onCancel: () => void;
} & GenerateCompartmentProfileParams;

const GenerationParamsForm: FC<GenerationParamsFormProps> = (props) => {

	const navigate = useNavigate();

	const submit = (values: GenerateCompartmentProfileParams) => {
		props.onConfirm({
			rabbetId: values.rabbetId,
			positions: values.positions,
			sillWidths: values.sillWidths,
			cornerProfilesInside: values.cornerProfilesInside,
			cornerProfilesOutside: values.cornerProfilesOutside,
		});
	};
	const cancel = () => {
		props.onCancel();
	};

	const initialValues: GenerateCompartmentProfileParams = {
		rabbetId: props.rabbetId ?? undefined,
		positions: props.positions ?? [],
		sillWidths: props.sillWidths ?? [],
		cornerProfilesInside: props.cornerProfilesInside ?? [],
		cornerProfilesOutside: props.cornerProfilesOutside ?? [],
	};

	const validate = (values: GenerateCompartmentProfileParams): FormikErrors<GenerateCompartmentProfileParams> => {

		const errors: FormikErrors<GenerateCompartmentProfileParams> = {};

		if (typeof (values.rabbetId) === 'undefined') {

			errors.rabbetId = 'Selecteer een sponning.';

		} else if (values.rabbetId !== '') {

			if (!values.positions || values.positions.length === 0) {
				errors.positions = 'Selecteer ten minste één spooning positie';
			}
		}

		if (!values.sillWidths || values.sillWidths.length === 0) {
			errors.sillWidths = 'Selecteer ten minste één dorpel breedte';
		}

		if (!values.cornerProfilesInside || values.cornerProfilesInside.length === 0) {
			errors.cornerProfilesInside = 'Selecteer ten minste één hoekprofiel binnen';
		}

		if (!values.cornerProfilesOutside || values.cornerProfilesOutside.length === 0) {
			errors.cornerProfilesOutside = 'Selecteer ten minste één hoekprofiel binnen';
		}

		return errors;
	};

	const onChange = (values: GenerateCompartmentProfileParams) => {
		navigate({
			search: createSearchParams({
				rabbetId: values.rabbetId!,
				sillWidths: values.sillWidths,
				positions: values.positions,
				cornerProfilesInside: values.cornerProfilesInside,
				cornerProfilesOutside: values.cornerProfilesOutside,
			}).toString(),
		}, {replace: true});
	};

	return <Formik initialValues={initialValues} onSubmit={submit} validate={validate}>
		{({isValid}) =>
			<Form>
				<FormikOnChange onChange={onChange} />
				<Modal.Header closeButton>
					<Modal.Title>Vakprofielen toevoegen</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<GenerationParams />
				</Modal.Body>
				<Modal.Footer className='justify-content-end'>
					<Button variant='link' className='mx-4' onClick={() => cancel()}>Annuleren</Button>
					<SpinnerButton type='submit' className='btn btn-primary' spinning={false}
								   disabled={!isValid}>Volgende</SpinnerButton>
				</Modal.Footer>
			</Form>
		}
	</Formik>;
};

interface GenerationParamsProps {
}

const GenerationParams: FC<GenerationParamsProps> = () => {

	const {values, setValues} = useFormikContext<GenerateCompartmentProfileParams>();

	const {data: options, isInitialLoading, isError} = useQuery(
		['getCompartmentProfileGenerationOptions'],
		() => ApiClient.Pim.ProfileGeneration.getCompartmentProfileGenerationOptions().then(x => x.data));


	if (isInitialLoading || !options) return <Loading />;

	if (isError) return <Error />;


	const selectRabbet = async (rabbetId: string | undefined) => {

		const newSelectedRabbet = rabbetId ?
			options?.rabbets.find(r => r.id === rabbetId)
			: null;

		const sillWidths: string[] = values.sillWidths;

		if (sillWidths.length === 0) {

			if (options.sillWidths.includes(114) && (!newSelectedRabbet || newSelectedRabbet.width <= 114)) {
				sillWidths.push('114');
			}

			if (options.sillWidths.includes(139) && (!newSelectedRabbet || newSelectedRabbet.width <= 139)) {
				sillWidths.push('139');
			}
		}

		const rabbetPositions: RabbetPosition[] = [];

		if (newSelectedRabbet) {
			if (newSelectedRabbet.application === RabbetApplication.Inside || newSelectedRabbet.application === RabbetApplication.InsideAndOutside) {
				rabbetPositions.push(RabbetPosition.Inside);
			}

			if (newSelectedRabbet.application === RabbetApplication.Outside || newSelectedRabbet.application === RabbetApplication.InsideAndOutside) {
				rabbetPositions.push(RabbetPosition.Outside);
			}
		}

		await setValues((current => {
			return {...current, rabbetId: rabbetId, positions: rabbetPositions, sillWidths: sillWidths};
		}));
	};

	const onRabbetChange = async (newValue: OnChangeValue<{
		value: string | undefined;
		label: string
	}, false>) => {

		await selectRabbet(newValue?.value);
	};

	const selectedRabbet = options?.rabbets.find(r => r.id === values.rabbetId);

	const cornerProfilesInside = options?.cornerProfiles.filter(x => x.application === RabbetApplication.Inside || x.application === RabbetApplication.InsideAndOutside);
	const cornerProfilesOutside = options?.cornerProfiles.filter(x => x.application === RabbetApplication.Outside || x.application === RabbetApplication.InsideAndOutside);

	const rabbetOptions: {value: string | undefined; label: string}[] = !isInitialLoading && !isError && options
		? [
			{value: '', label: 'Geen sponning'},
			...options.rabbets.map(x => ({value: x.id, label: x.name})),
		]
		: [];

	const selectedRabbetValue = rabbetOptions.find(x => x.value === values.rabbetId);

	return <>
		<EkoFormRow>
			<div className='col-4'>
				<label id='rabbet'>Sponning</label>
				<div className='text-danger'>
					<ErrorMessage name='rabbetId' />
				</div>
			</div>
			<div className={'col'}>
				<Select
					aria-labelledby='rabbet'
					placeholder='Selecteer een sponning'
					isClearable={true}
					isSearchable={true}
					isMulti={false}
					name='rabbetId'
					options={rabbetOptions}
					value={selectedRabbetValue}
					onChange={onRabbetChange}
				/>
			</div>
		</EkoFormRow>

		<EkoFormRow>
			<div className='col-4'>
				<label id='application-label'>Sponning positie</label>
				<div className='text-danger'>
					<ErrorMessage name='positions' />
				</div>
			</div>
			<div className={'col'} role='group' aria-labelledby='application-label'>

				<div className='form-check mb-2'>
					<Field name='positions' type='checkbox' className='form-check-input'
						   value={RabbetPosition.Inside}
						   id={`position-${RabbetPosition.Inside}`}
						   disabled={selectedRabbet?.application !== RabbetApplication.Inside
							   && selectedRabbet?.application !== RabbetApplication.InsideAndOutside}
					/>
					<label className='form-check-label'
						   htmlFor={`position-${RabbetPosition.Inside}`}>
						<RabbetPositionText code={RabbetPosition.Inside} />
					</label>
				</div>

				<div className='form-check form-check-inline'>
					<Field name='positions' type='checkbox' className='form-check-input'
						   value={RabbetPosition.Outside}
						   id={`position-${RabbetPosition.Outside}`}
						   disabled={selectedRabbet?.application !== RabbetApplication.Outside
							   && selectedRabbet?.application !== RabbetApplication.InsideAndOutside}
					/>
					<label className='form-check-label'
						   htmlFor={`position-${RabbetPosition.Outside}`}>
						<RabbetPositionText code={RabbetPosition.Outside} />
					</label>
				</div>
			</div>
		</EkoFormRow>

		<EkoFormRow>
			<div className='col-4'>
				<label id='application-label'>Breedte</label>
				<div className='text-danger'>
					<ErrorMessage name='sillWidths' />
				</div>
			</div>
			<div className={'col'} role='group' aria-labelledby='application-label'>

				{options.sillWidths.map(w => <div key={w} className='form-check mb-2'>
					<Field name='sillWidths' type='checkbox' className='form-check-input'
						   value={w.toString()}
						   id={`width-${w}`} />
					<label className='form-check-label' htmlFor={`width-${w}`}>
						<Mm value={w} />
					</label>
				</div>)}

			</div>
		</EkoFormRow>

		<EkoFormRow>
			<div className='col-4'>
				<label id='corner-profile-inside-label'>Hoekprofiel binnen</label>
				<div className='text-danger'>
					<ErrorMessage name='cornerProfilesInside' />
				</div>
			</div>
			<div className={'col'}>
				<div role='group' aria-labelledby='corner-profile-inside-label'>

					<div className='form-check mb-2'>
						<Field name='cornerProfilesInside' type='checkbox' className='form-check-input'
							   value='none'
							   id={`corner-profiles-inside-none`} />
						<label className='form-check-label' htmlFor={`corner-profiles-inside-none`}>
							Geen hoekprofiel binnen
						</label>
					</div>

					{cornerProfilesInside.map(cp =>
						<div key={cp.id} className='form-check mb-2'>
							<Field name='cornerProfilesInside' type='checkbox' className='form-check-input'
								   value={cp.id}
								   id={`corner-profiles-inside-${cp.id}`} />
							<label className='form-check-label' htmlFor={`corner-profiles-inside-${cp.id}`}>
								{cp.name}
							</label>
						</div>)}
				</div>
			</div>
		</EkoFormRow>

		<EkoFormRow>
			<div className='col-4'>
				<label id='corner-profile-outside-label'>Hoekprofiel buiten</label>
				<div className='text-danger'>
					<ErrorMessage name='cornerProfilesOutside' />
				</div>
			</div>
			<div className={'col'}>
				<div role='group' aria-labelledby='corner-profile-outside-label'>

					<div className='form-check mb-2'>
						<Field name='cornerProfilesOutside' type='checkbox' className='form-check-input'
							   value='none'
							   id={`corner-profiles-outside-none`} />
						<label className='form-check-label' htmlFor={`corner-profiles-outside-none`}>
							Geen hoekprofiel buiten
						</label>
					</div>

					{cornerProfilesOutside.map(cp =>
						<div key={cp.id} className='form-check mb-2'>
							<Field name='cornerProfilesOutside' type='checkbox' className='form-check-input'
								   value={cp.id}
								   id={`corner-profiles-outside-${cp.id}`} />
							<label className='form-check-label' htmlFor={`corner-profiles-outside-${cp.id}`}>
								{cp.name}
							</label>
						</div>)}
				</div>
			</div>
		</EkoFormRow>
	</>;
};


interface GenerationPreviewFormConfig {
	content: React.ReactNode;
	isSubmitting: boolean;
	selection: CompartmentProfileValue[];
	activate: boolean;
	setActivate: (activate: boolean) => Promise<void>;
}

type GenerationPreviewFormProps = {
	params: GenerateCompartmentProfileParams;
	children?: React.ReactNode | ((config: GenerationPreviewFormConfig) => React.ReactNode);
	onSuccess: () => void;
};


interface GenerationPreviewFormValues {
	selection: CompartmentProfileGenerationPreview[];
	activate: boolean;
}

const GenerationPreviewForm: FC<GenerationPreviewFormProps> = (props) => {

	const [selection] = useState<CompartmentProfileGenerationPreview[]>([]);

	const {data, isInitialLoading, isError} = useQuery(
		['ApiClient.Pim.ProfileGeneration.previewCompartmentProfileGeneration', props.params],

		() => ApiClient.Pim.ProfileGeneration.previewCompartmentProfileGeneration(undefined, {
			rabbetId: props.params.rabbetId === '' ? null : props.params.rabbetId,
			positions: props.params.positions,
			sillWidths: props.params.sillWidths.map(x => Number(x)),
			cornerProfilesInside: props.params.cornerProfilesInside.map(x => x === 'none' ? null! : x),
			cornerProfilesOutside: props.params.cornerProfilesOutside.map(x => x === 'none' ? null! : x),

		}).then(x => x.data),
		{
			refetchOnWindowFocus: false,
			onSuccess: (data) => {

				const previews = data.filter(x => !x.profileId && x.profile);

				previews.forEach(x => selection.push(x));
			},
		});


	const createMutation = useProfileCreateMutation();

	if (isInitialLoading || !data) return <Loading />;

	if (isError) return <Error />;


	const submit = async (values: GenerationPreviewFormValues) => {

		const models: CreateCompartmentProfileModel[] = values.selection.map(preview => ({
			profileType: 'standardCompartmentProfile',
			code: preview.code,
			name: preview.title,
			sillWidth: preview.width,
			rabbet: preview.profile!.rabbet,
			cornerProfileInsideId: preview.profile!.cornerProfileInside?.id,
			cornerProfileOutsideId: preview.profile!.cornerProfileOutside?.id,
			activate: values.activate,
		}));

		await createMutation.mutateAsync(models);

		props.onSuccess();
	};

	const initialValues: GenerationPreviewFormValues = {
		selection: selection,
		activate: true,
	};

	return <Formik initialValues={initialValues} onSubmit={submit}>

		{({values, setValues, submitForm, isSubmitting}) => {

			const newProfiles = data.filter(x => x.isValid && !x.profileId);
			const existingProfiles = data.filter(x => x.isValid && x.profileId);
			const invalidProfiles = data.filter(x => !x.isValid);

			const previewContent = <GenerationPreviewFormContent params={props.params}
																 newProfiles={newProfiles}
																 existingProfiles={existingProfiles}
																 invalidProfiles={invalidProfiles} />;

			const childNodes =
				typeof props.children === 'undefined'
					? previewContent
					: typeof props.children === 'function'
						? props.children?.call(this, {
							content: previewContent,
							isSubmitting: isSubmitting,
							selection: values.selection.map(x => x.profile!),
							activate: values.activate,
							setActivate: async (activate: boolean) => {
								await setValues((current) => {
									return {...current, activate};
								});
							},
						})
						: props.children;
			return <Form>
				{childNodes}
			</Form>;
		}}

	</Formik>;
};


interface GenerationPreviewProps {
	params: GenerateCompartmentProfileParams;
	newProfiles: CompartmentProfileGenerationPreview[];
	existingProfiles: CompartmentProfileGenerationPreview[];
	invalidProfiles: CompartmentProfileGenerationPreview[];
	// onSelectionChange: (selection: CompartmentProfileGenerationPreviewProfile[]) => void;
}

const GenerationPreviewFormContent: FC<GenerationPreviewProps> = (props) => {


	const {values, setValues} = useFormikContext<GenerationPreviewFormValues>();


	const toggleSelection = async (profile: CompartmentProfileGenerationPreview) => {

		await setValues(current => {

			const update = {...current};

			if (!update.selection.includes(profile)) {
				update.selection.push(profile);
			} else {
				const index = update.selection.indexOf(profile);
				update.selection.splice(index, 1);
			}

			return update;
		});

		// setSelection((selection) => {
		// 	let newSelection = [...selection];
		//
		// 	if (!newSelection.includes(profile)) {
		// 		newSelection.push(profile);
		// 	} else {
		// 		const index = newSelection.indexOf(profile);
		// 		newSelection.splice(index, 1);
		// 	}
		//
		// 	return newSelection;
		// });
	};


	const table = (rows: CompartmentProfileGenerationPreview[], checkable: boolean = false) => <>
		{(rows.length === 0 &&
				<p>
					Geen profielen
				</p>)
			|| <EkoTable>
				<tbody>
				{rows.map((preview, index) =>
					<tr key={index}>
						<td className='fit-content'>
							<label
								className='w-100 h-100 d-flex justify-content-center align-items-center cursor-pointer'>
								<input
									className='form-check-input m-0'
									type='checkbox'
									id={`profile-${index}}`}
									value={index}
									// checked={checkable}
									disabled={!checkable}
									checked={(checkable && values.selection.includes(preview!)) || false}
									onChange={(e) => toggleSelection(preview!)}
								/>
							</label>
						</td>

						<td style={{width: '60px'}}
							onClick={() => preview.profile && toggleSelection(preview)}>
							{preview.svg &&
								<div dangerouslySetInnerHTML={{__html: preview.svg}}
									 style={{width: '100%'}}
									 className='d-flex justify-content-center cursor-pointer'>
								</div>
							}
						</td>

						<td onClick={() => preview.profile && toggleSelection(preview)} className='cursor-pointer'>
							{preview.title}</td>
					</tr>,
				)}
				</tbody>
			</EkoTable>
		}
	</>;

	return <>
		<Tabs
			defaultActiveKey='new'
			className='mb-3'>
			<Tab eventKey='new' title={<>Nieuw <span className='badge bg-success'>{props.newProfiles.length}</span></>}>
				{table(props.newProfiles, true)}
			</Tab>
			<Tab eventKey='existing'
				 disabled={props.existingProfiles.length === 0}
				 title={<>Bestaand <span className='badge bg-primary'>{props.existingProfiles.length}</span></>}>
				{table(props.existingProfiles)}
			</Tab>
			<Tab eventKey='invalid'
				 disabled={props.invalidProfiles.length === 0}
				 title={<>Ongeldig <span className='badge bg-warning'>{props.invalidProfiles.length}</span></>}>
				{table(props.invalidProfiles)}
			</Tab>
		</Tabs>
	</>;
};



/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {EkoTable} from "../../../../../../shared/components/table/EkoTable";
import {TableHeader} from "../../../../../../shared/components/table/TableHeader";
import {
    OrderLineType,
    OrderRepresentation,
    PostProductOrderLineRepresentation,
    StandardProduct
} from "../../../../../../modules/api-client/generated";
import {usePostOrderline} from "../../hooks/orderline/use-post-orderline";
import {useGetOrder} from '../../hooks/use-get-order';
import {
    useListStandardProductsByCatalogIdAndCategoryQuery,
    useListStandardProductsByCatalogIdQuery
} from '../../../../pim/catalogitem/hooks';
import Euro from '../../../../../../shared/components/Euro';
import Loading from "../../../../../../shared/components/Loading";

type Props = {
    catalogId?: string
    category?: string
    priceListId?: string
    onProductAdded?: ((productId: string) => void) | undefined
}

const ProductList: React.FC<Props> = ({category, catalogId, priceListId, onProductAdded}) => {

    const {
        isInitialLoading,
        data: standardProductList,
        isError
    } = useListStandardProductsByCatalogIdAndCategoryQuery(catalogId, category, priceListId)

    if (isInitialLoading) {
        return <Loading/>;
    }
    if (isError) {
        return <>Fout bij het laden van de producten!</>;
    }
    if (!standardProductList) return <></>;
    function addProductToOrder(productId: string) {
        if (onProductAdded) {
            onProductAdded(productId)
        }
    }
    return (
        <EkoTable>
            <TableHeader>
                <th className='min-w-150px'>Product</th>
                <th className='fit-content text-end'>Prijs</th>
                <th className='w-50px text-end'></th>
            </TableHeader>
            <tbody>

            {(!standardProductList || standardProductList.length === 0) &&
                <tr>
                    <td colSpan={3} className="text-center">Geen producten gevonden. U kunt verder met uw bestelling.
                    </td>
                </tr>}

            {standardProductList && standardProductList.filter((product: StandardProduct) => product.active).map((product: StandardProduct) => {

                    return (
                        <tr key={product.id}>

                            <td>
                                <div className='fw-bold fs-6'>
                                    {product.title}
                                </div>
                                {product.description &&
                                    <div className='text-muted '>
                                        {product.description}
                                    </div>
                                }
                            </td>

                            <td className='text-nowrap'>
                                <Euro value={product.price}/>
                            </td>

                            <td className='text-nowrap text-end'>
                                {onProductAdded &&
                                <button className="btn btn-primary btn-sm" type="button"
                                        onClick={() => addProductToOrder(product.id)}>
                                    <i className={'fas fa-plus pe-0'}></i>
                                </button>
                                }
                            </td>

                        </tr>
                    )
                }
            )
            }

            </tbody>
        </EkoTable>
    )
}

export {ProductList}

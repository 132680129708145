
import React from 'react';
interface Props {
  startDate: string;
  endDate: string;
  placeholderStart?: string;
  placeholderEnd?: string;
  disabled?: boolean;
  onStartDateChange: (date: string) => void;
  onEndDateChange: (date: string) => void;
  resetRange: () => void;
  buttonClassName?: string | undefined;
}

export const PeriodInput = ({ startDate, endDate, placeholderStart, placeholderEnd, disabled
                              , onStartDateChange, onEndDateChange, buttonClassName, resetRange }: Props) => {
  return (
      <>
        <div className={'me-2'}>
          <input type="date" id="start" className={'form-control'} name="startDay" value={startDate}
                 onChange={e => onStartDateChange(e.target.value)} placeholder={placeholderStart} disabled={disabled}
          />
        </div>
        <div className={'me-2'}>
            <input type="date" id="end" className={'form-control'} name="endDay" value={endDate}
                 onChange={e => onEndDateChange(e.target.value)} placeholder={placeholderEnd} disabled={disabled}/>
        </div>
        <div>
          <button className={'btn btn-sm btn-light' + buttonClassName} onClick={() => resetRange()} disabled={disabled}>
              Reset
          </button>
        </div>
      </>
  );
}
import React, {FC} from 'react';
import * as Yup from 'yup';
import {Form, Formik, FormikHelpers} from 'formik';
import {CustomerPicker} from '../../../backoffice/modules/crm/components/CustomerPicker';
import FieldErrors from '../../components/FieldErrors';
import ImportFileDropzone from './ImportFileDropzone';
import clsx from 'clsx';
import {useNavigate} from "react-router-dom";
import {ImportRoutePath} from "../routes/ImportRoutes";
import {usePostImport} from "../hooks/use-post-import";
import TextField from "../../components/TextField";
import SpinnerButton from "../../components/SpinnerButton";

export type StartImportFormValues = {
	customerId?: string;
	title?: string;
	detectWallConnections: boolean;
	file: File | undefined;
};

const schema = (requireCustomer: boolean) => ({
	customerId: requireCustomer ? Yup.string().required('Klant is verplicht.') : Yup.string(),
	detectWallConnections: Yup.boolean().required('Opties zijn verplicht'),
	file: Yup.mixed().required('Bestand is verplicht'),
	title: Yup.string(),
});

const formSchema = (requireCustomer: boolean) =>
	Yup.lazy((values) => {
		return Yup.object().shape(schema(requireCustomer));
	});


export type StartImportFormProps = {
	requireCustomer?: boolean;
};

const StartImportForm: FC<StartImportFormProps> = ({requireCustomer = true}) => {
	const navigate = useNavigate();
	const mutation = usePostImport();

	const initialValues: StartImportFormValues = {
		customerId: undefined,
		title: undefined,
		detectWallConnections: true,
		file: undefined,
	};

	const onSubmit = (values: StartImportFormValues, {setSubmitting}: FormikHelpers<StartImportFormValues>) => {
		return new Promise<void>((resolve, reject) => {
			if (!values.file) {
				reject();
				return;
			}
			mutation.mutate(values, {
				onSuccess: (id) => {
					resolve();
					navigate('../'+ImportRoutePath.importLink(id));
				},
				onError: () => {
					reject();
				},
			});
		}).finally(() => setSubmitting(false));
	};

	return (
		<>
			<Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={formSchema(requireCustomer)}>
				{({values, handleChange, handleSubmit, getFieldProps, isSubmitting, setFieldValue}) => (
					<Form>
						<div className="row ">
							<div className="col">
								<ImportFileDropzone onSelect={(file) => setFieldValue('file', file)} />
								<FieldErrors field="file"/>
							</div>
							<div className={'col d-flex flex-column w-100'}>
								<div>
									<label className="form-label fw-bold mb-4">
										Opties
									</label>

									<div className="form-check form-switch">
										<input
											{...getFieldProps('detectWallConnections')}
											className={clsx('form-check-input')}
											type="checkbox"
											name="detectWallConnections"
											id="detectWallConnections"
											value={values.detectWallConnections ? 'true' : 'false'}
											checked={values.detectWallConnections}
											disabled={isSubmitting}
										/>
										<label className="fw-bold fs-6 mb-2 form-check-label"
											   htmlFor="detectWallConnections">
											Muuraansluitingen overnemen
										</label>
									</div>


									{requireCustomer && (
										<div className={'my-4'}>
											<label className="form-label" htmlFor={'customerId'}>
												Klant
											</label>
											<CustomerPicker fieldName="customerId" value={values.customerId}
															className="w-100"
															onChange={(customerId) => setFieldValue('customerId', customerId)}/>
											<FieldErrors field="customerId"/>
										</div>
									)}

									<div className={'my-4'}>
										<TextField row={false} name={'title'} label={'Titel'}
												   placeholder={'Optionele titel'}/>
									</div>


								</div>
								<div className=" align-self-end mt-auto">
									<SpinnerButton className={'btn btn-primary'} disabled={isSubmitting}
												   spinning={isSubmitting}>
									{!isSubmitting && <span className="indicator-label">
											Start import
										</span>}
										{isSubmitting && (
											<span className="indicator-label">
												Even wachten a.u.b
											</span>
										)}
									</SpinnerButton>
								</div>

							</div>
						</div>
						<div className="row">
							<div className="col">
							</div>
						</div>
					</Form>
				)}
			</Formik>

		</>
	);
};

export {StartImportForm};

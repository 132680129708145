import React from 'react';

export type SexySelectProps<TItem, TValue> = {
	options: TItem[];
	value: TValue;
	itemValue: (option: TItem) => TValue;
	itemLabel: (option: TItem) => string;
	onChange: (value: TValue) => void;
	required?: boolean;
	disabled?: boolean;
	emptyText?: string;
	itemComponent?: React.FC<SexySelectItemProps<TItem, TValue>>;
	labelComponent?: React.FC<SexySelectItemProps<TItem, TValue>>;
	elementProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
};

export type SexySelectItemProps<TItem, TValue> = {
	option: TItem;
	value: TValue | undefined;
	label: string;
	active: boolean;
	index: number;
	onChange: (value: TValue) => void;
	labelComponent?: React.FC<SexySelectItemProps<TItem, TValue>>;
};



export const SexySelect = <TItem extends unknown, TValue extends unknown>(props: SexySelectProps<TItem, TValue>) => {
	const s = props.options.filter((x) => props.itemValue(x) === props.value)[0] ?? null;

	const isEmpty = typeof s === 'undefined' || s === null;

	const emptyText = typeof props.emptyText !== 'undefined' ? props.emptyText : 'Geen';

	const required = props.required ?? false;

	const text = !isEmpty ? props.itemLabel(s) : required ? 'Kies een optie' : emptyText;

	const itemComponent = props.itemComponent ?? SexySelectDefaultItem;
	const labelComponent = props.labelComponent ?? SexySelectDefaultLabel;

	return (
		<>
			<div className="form-select position-relative" tabIndex={props.disabled ? -1 : 0} data-bs-toggle="dropdown" aria-expanded="false" {...props.elementProps}>
				<div className="d-flex align-items-center">
					{labelComponent({
						option: s!,
						value: s ? props.itemValue(s) : undefined,
						label: text,
						active: isEmpty,
						index: -1,
						onChange: props.onChange,
						labelComponent: labelComponent,
					})}
				</div>

				<ul className="dropdown-menu" style={{minWidth: '100%', maxHeight: '300px', overflowY: 'scroll'}}>
					{!required &&
						itemComponent({
							option: undefined!,
							value: undefined!,
							label: emptyText,
							active: isEmpty,
							index: -1,
							onChange: props.onChange,
							labelComponent: labelComponent,
						})}
					{props.options.map((option, index) => {
						const active = s === option;

						return itemComponent({
							option: option,
							value: props.itemValue(option),
							label: props.itemLabel(option),
							active: active,
							index: index,
							onChange: props.onChange,
							labelComponent: labelComponent,
						});
					})}
				</ul>
			</div>
		</>
	);
};

export const SexySelectDefaultItem = <TItem extends unknown, TValue extends unknown>(props: SexySelectItemProps<TItem, TValue>) => {
	const labelComponent = props.labelComponent ?? SexySelectDefaultLabel;

	return (
		<li
			key={props.index}
			className={`dropdown-item d-flex align-items-center ${props.active ? 'active' : ''}`}
			onClick={() => {
				props.onChange(props.value!);
			}}
		>
			{labelComponent(props)}
		</li>
	);
};
export const SexySelectDefaultLabel = <TItem extends unknown, TValue extends unknown>(props: SexySelectItemProps<TItem, TValue>) => <span className="user-select-none">{props.label}</span>;

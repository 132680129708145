import axios, {AxiosResponse} from 'axios'
import {ID} from '../../../../../../_metronic/helpers'
import {REACT_APP_API_AUTHZERO_PROXY_URL} from '../../../../../constants'
import instance from '../../../../../auth/core/AxiosInterceptor'
import {Role, RolesQueryResponse} from './_models'

const getRoles = (query: any): Promise<RolesQueryResponse> => {
  return instance
    .get(`${REACT_APP_API_AUTHZERO_PROXY_URL}Roles/roles?${query}`)
    .then((d: AxiosResponse<RolesQueryResponse>) => d.data)
}

const createRole = (data: any): Promise<Role> => {
  return instance
    .post(`${REACT_APP_API_AUTHZERO_PROXY_URL}Roles/roles`, data)
    .then((d: AxiosResponse<Role>) => d.data)
    .catch((err) => Promise.reject(err))
}

const deleteRole = (roleId: ID): Promise<void> => {
  return instance
    .delete(`${REACT_APP_API_AUTHZERO_PROXY_URL}Roles/roles/${roleId}`)
    .then(() => {})
    .catch((err) => Promise.reject(err))
}

const updateRole = (data: any): Promise<Role> => {
  return instance
    .put(`${REACT_APP_API_AUTHZERO_PROXY_URL}Roles/roles`, data)
    .then((d: AxiosResponse<Role>) => d.data)
    .catch((err) => Promise.reject(err))
}

const deleteSelectedRoles = (roleIds: Array<ID>): Promise<void> => {
  const requests = roleIds.map((id) => instance.delete(`${REACT_APP_API_AUTHZERO_PROXY_URL}Roles/roles/${id}`))
  return axios
    .all(requests)
    .then(() => {})
    .catch((err) => Promise.reject(err))
}
export {getRoles, createRole, deleteRole, updateRole, deleteSelectedRoles}

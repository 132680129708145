import React from "react";
import ApiClient from "../../../../modules/api-client/ApiClient";
import SpinnerButton from "../../../../shared/components/SpinnerButton";
import {useMutation} from "@tanstack/react-query";
import {toast} from "react-hot-toast";

export const RegenerateBmhButton: React.FC<{
    batch:string,
    disabled:boolean,
}> = ({
          batch,disabled
      }) => {


    const regenerate = useMutation(
        async () => {
            await ApiClient.Pm.ProductionBatch.pmProductionBatchIdRegenerateBmhXmlPut(batch);
        },
        {
            onSuccess: async () => {
                toast.success('Hergenereren gelukt.');

            },
            onError: () => {
                toast.error('Fout bij regeneren bij BMH 3DMaster XML.');
            },
        },
    );

    return <>
        <SpinnerButton
            onClick={async () => await regenerate.mutateAsync(undefined, undefined)}
            className='btn btn-primary'
            spinning={regenerate.isLoading}
            disabled={disabled || regenerate.isLoading}
        >
            XML hergenereren
        </SpinnerButton>

    </>;
};
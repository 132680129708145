import React from "react";
import {stationList} from "./models/data";
import {StationTodoWidget} from "./StationTodoWidget";

const StationProductionTodo: React.FC = () => {


    const station = stationList[0];

    return (
        <>
            <StationTodoWidget code={station.code} title={station.title} type={station.type}
                               description={station.description} slots={station.slots} status={station.status} handelingen={station.handelingen} medewerkers={station.medewerkers}/>

        </>
    )
}

export default StationProductionTodo

import { useQuery } from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import { ProductionBatchRepresentation } from "../../../../modules/api-client/generated";


export const useProductionBatchGetId = (id: string | undefined) => {
    return useQuery<ProductionBatchRepresentation, TypeError, ProductionBatchRepresentation>(['pmProductionBatchGetId', id],
        () => {
            return ApiClient.Pm.ProductionBatch.pmProductionBatchIdGet(id!).then((res) => res.data);
        }, {
        useErrorBoundary: true,
        enabled: !!id,
    });
}
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {EkoCard, EkoCardBody, toAbsoluteUrl} from "../../../_metronic/helpers";

type Props = {
    className: string
}
const EkosietKlantenserviceWidget = ({className}: Props) => {
    return (
        <>
            <EkoCard>
                <EkoCardBody>
                    <div className='my-10'>
                        <div className='fs-2hx fw-bold text-center'>
                            <img
                                src={toAbsoluteUrl('/media/logos/ekosiet-logo-product.png')}
                                className='img-fluid'
                                alt=''
                            />
                        </div>
                    </div>
                        <div className={'text-center mt-3'}>
                            <h3>Hulp nodig?</h3>
                            <p>
                                Contact: 0 488 72 59 47
                                E-mail: info@ekosiet.nl
                                Vragen over de je factuur?
                            </p>

                        </div>
                </EkoCardBody>
            </EkoCard>
        </>
    )
}
export {EkosietKlantenserviceWidget}

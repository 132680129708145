import React from "react";
import {Field, useField} from "formik";
import {PriceList} from "../../../../modules/api-client/generated";
import {useQuery} from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import CustomSelect, {OptionsType, SelectOption} from "../../../../shared/components/CustomSelect";


type Props = {
    name: string;
    multi?: boolean;
    required?: boolean;
}

export const PriceListPicker: React.FC<Props> = ({name,multi,required=false, ...props}) => {
    const [field, meta] = useField(name);

    let options: OptionsType<SelectOption> = [];

    const {
        data: list,
    } = useQuery<PriceList[]>(['pimPriceListsGet'], () => ApiClient.Sm.PriceList.smPriceListsGet().then((res) => res.data));


    if (list) {
        options = list.map((item) => {
            return {value: item.id!, label: item.name!}
        })
    }

    return (
        <>
            <Field
                className="custom-select"
                name={name}
                options={options}
                component={
                    CustomSelect
                }
                placeholder="Kies een prijslijst..."
                isMulti={multi}
                isClearable={!required}
            />
        </>
    )
}
import React from "react";
import {useField} from "formik";
import FieldErrors from "./FieldErrors";

const SelectField: React.FC<any> = ({label, ...props}) => {
    const [field, meta] = useField(props);
    return (
        <>
            <label className="form-label"  htmlFor={props.id || props.name}>{label}</label>
            <select className="form-control" {...field} {...props} />
            <FieldErrors field={props.name} />
        </>
    );
};

export default SelectField;
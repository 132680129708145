import React, {useLayoutEffect} from 'react';
import {Field, Formik, FormikHelpers, useFormikContext} from 'formik';
import SubmitButton from '../../../../shared/components/SubmitButton';
import {ValidationErrors} from '../../../../modules/api-client/Responses';
import TextField from '../../../../shared/components/TextField';
import {useCatalogItemQuery, useCatalogItemUpsertMutation} from './hooks';
import {
	CatalogItemType,
	CatalogItemCatalog,
	CatalogItemCategory,
	CornerPieceProduct,
	GlazingBarProduct,
	GlazingProfileProduct, KopIsolatorSetProduct,
	SillConfiguratorProduct,
	SillProduct,
	StopProfileProduct,
} from '../../../../modules/api-client/generated';
import ColorOptionPicker from '../../../../shared/components/configurator/ColorOptionPicker';
import {useQuery} from '@tanstack/react-query';
import * as api from '../../../../modules/api-client/generated';
import ApiClient from '../../../../modules/api-client/ApiClient';
import {ColorOption, Options} from '../../../../shared/components/configurator/model';
import {CategoryPicker} from '../category/CategoryPicker';
import {DiscountGroupPicker} from '../discountgroup/DiscountGroupPicker';
import {GlazingBarPickerField} from '../glazingbar/GlazingBarPicker';
import {CatalogPicker} from '../catalog/CatalogPicker';
import {SillPicker} from '../sill/SillPicker';
import {GlazingProfilePicker} from '../glazingprofile/GlazingProfilePicker';
import {CornerPiecePicker} from '../cornerpiece/CornerPiecePicker';
import {LProfilePicker} from '../l-profile/LProfilePicker';
import Loading from '../../../../shared/components/Loading';


type Props = {
	handleClose: () => void,
	id?: string,
}

type FormData = {catalogItemType: string, active: boolean};

const CatalogItemUpsert: React.FC<Props> = ({handleClose, id}) => {

	const {mutate, serverValidationErrors} = useCatalogItemUpsertMutation(id);

	const onSubmit = (values: FormData, {setSubmitting}: FormikHelpers<FormData>) => {

		return new Promise<void>((resolve, reject) => {
			mutate(values, {
				onSuccess: () => {
					resolve();
					handleClose();

				},
				onError: () => {
					reject();
				},
			});

		}).finally(() => setSubmitting(false));
	};

	const {data, isInitialLoading, isError} = useCatalogItemQuery(id);

	if (isInitialLoading) return (<Loading />);
	if (isError) return (<>Error...</>);
	if (id && !data) return (<>'{id}' niet gevonden.</>);

	const defaults = {
		active: false,
		catalogItemType: CatalogItemType.Standard,
	};
	const initialValues: any = {...defaults, ...data};

	initialValues.categoryIds = data?.categories?.map((c: CatalogItemCategory) => c.id!);
	initialValues.catalogIds = data?.catalogs?.map((c: CatalogItemCatalog) => c.id!);
	initialValues.discountGroupId = data?.discountGroup?.id!;
	if (data?.catalogItemType === CatalogItemType.SillConfigurator) {
		const x = data as SillConfiguratorProduct;
		initialValues.sillId = x?.sill?.id!;
	}
	if (data?.catalogItemType === CatalogItemType.Sill) {
		const x = data as SillProduct;
		initialValues.sillId = x?.sill?.id!;
		initialValues.colorId = x?.color?.id!;
	}
	if (data?.catalogItemType === CatalogItemType.GlazingBar) {
		const x = data as GlazingBarProduct;
		initialValues.glazingBarId = x?.glazingBar?.id!;
		initialValues.colorId = x?.color?.id!;
	}
	if (data?.catalogItemType === CatalogItemType.CornerPiece) {
		const x = data as CornerPieceProduct;
		initialValues.cornerPieceId = x?.cornerPiece?.id!;
		initialValues.colorId = x?.color?.id!;
	}
	if (data?.catalogItemType === CatalogItemType.GlazingProfile) {
		const x = data as GlazingProfileProduct;
		initialValues.glazingProfileId = x?.glazingProfile?.id!;
		initialValues.colorId = x?.color?.id!;
	}
	if (data?.catalogItemType === CatalogItemType.StopProfile) {
		const x = data as StopProfileProduct;
		initialValues.stopProfileId = x?.stopProfile?.id!;
		initialValues.colorId = x?.color?.id!;
	}
	if (data?.catalogItemType === CatalogItemType.KopIsolatorSet) {
		const x = data as KopIsolatorSetProduct;
		initialValues.sillId = x?.sill?.id!;
	}
	return (
		<>
			<Formik initialValues={initialValues} onSubmit={onSubmit}>
				{({values, setFieldValue}) =>
					<>

						<div className='row mb-4'>
							<div className='col-4'>
								<label className='form-label' htmlFor='catalogItemType'>Type</label>
							</div>
							<div className='col-8'>
								<Field as='select' name={`catalogItemType`}
									   className='form-select'
									   disabled={id}
									   onChange={(e: any) => setFieldValue('catalogItemType', e.target.value)}>
									<option value={CatalogItemType.Standard}>Standaard</option>
									<option value={CatalogItemType.Transport}>Transport</option>
									<option value={CatalogItemType.SillConfigurator}>Dorpel Configurator</option>
									<option value={CatalogItemType.Sill}>Dorpelproduct (Profiel)</option>
									<option value={CatalogItemType.GlazingBar}>Glaslat</option>
									<option value={CatalogItemType.CornerPiece}>Glaslat Hoekstukje</option>
									<option value={CatalogItemType.GlazingProfile}>Beglazingsprofiel</option>
									<option value={CatalogItemType.StopProfile}>Aanslagprofiel</option>
									<option value={CatalogItemType.LProfile}>L-Profiel</option>
									<option value={CatalogItemType.KopIsolatorSet}>Kopisolatorset</option>
								</Field>
							</div>
						</div>

						<DataUpsertForm type={values.catalogItemType} id={id}
										serverValidationErrors={serverValidationErrors} />

					</>
				}

			</Formik>
		</>

	);
};


interface DataUpsertFormProps {
	id: string | undefined;
	type: string | undefined;
	serverValidationErrors: ValidationErrors | null;
}

export const DataUpsertForm: React.FC<DataUpsertFormProps> = ({id, type, serverValidationErrors}) => {

	const {data: colors} = useQuery<api.Color[]>(['pimColorsGet'], () => ApiClient.Pim.Color.pimColorsGet().then((res) => res.data));

	let colorOptions: Options<ColorOption> = {options: []};

	if (colors) {
		colorOptions = {
			options: colors.map((color) => {
				return {id: color.id!, name: color.name!, hex: color.hex!};
			}),
		};
	}

	const {
		values,
		handleSubmit,
		handleChange,
		isSubmitting,
		errors,
		setErrors,
	} = useFormikContext<FormData>();
	const mergeServerErrors = () => {

		if (serverValidationErrors) {
			setErrors({...errors, ...serverValidationErrors});
		}
	};
	useLayoutEffect(mergeServerErrors, [serverValidationErrors, errors, setErrors]);
	return (<form onSubmit={handleSubmit}>


		<TextField row={true} name={'code'} label={'Unieke code'} />
		<TextField row={true} name={'title'} label={'Titel'} />
		<TextField row={true} name={'description'} label={'Omschrijving'} />


		{!id && (type !== CatalogItemType.SillConfigurator &&
				type !== CatalogItemType.NeutConfigurator) &&
			<TextField row={true} name={'price'} label={'Prijs'} />
		}

		<div className='row mb-4'>
			<div className='col-4'>
				<label className='form-label' htmlFor='type'>Catalogus</label>
			</div>
			<div className='col-8'>
				<CatalogPicker multi={true} name={'catalogIds'} />
			</div>
		</div>
		<div className='row mb-4'>
			<div className='col-4'>
				<label className='form-label' htmlFor='type'>Kortingsgroep</label>
			</div>
			<div className='col-8'>
				<DiscountGroupPicker name={'discountGroupId'} />
			</div>
		</div>
		<div className='row mb-4'>
			<div className='col-4'>
				<label className='form-label' htmlFor='type'>Categorieen</label>
			</div>
			<div className='col-8'>
				<CategoryPicker multi={true} name={'categoryIds'} />
			</div>
		</div>


		{(type === CatalogItemType.CornerPiece ||
				type === CatalogItemType.GlazingBar ||
				type === CatalogItemType.GlazingProfile ||
				type === CatalogItemType.Sill ||
				type === CatalogItemType.StopProfile
			) &&
			<>
				<div className='row mb-4'>
					<div className='col-4'>
						<label className='form-label' htmlFor='type'>Kleur</label>
					</div>
					<div className='col-8'>
						<ColorOptionPicker name={'colorId'} options={colorOptions!} />
					</div>
				</div>

			</>
		}

		{(type === CatalogItemType.SillConfigurator ||
				type === CatalogItemType.Sill ||
				type === CatalogItemType.KopIsolatorSet
			) &&
			<>
				<div className='row mb-4'>
					<div className='col-4'>
						<label className='form-label' htmlFor='type'>Dorpelmodel</label>
					</div>
					<div className='col-8'>
						<SillPicker name={'sillId'} />
					</div>
				</div>
			</>
		}
		{type === CatalogItemType.Sill &&
			<>
				<TextField row={true} label={'Lengte (mm)'} name={'length'} />
			</>
		}

		{type === CatalogItemType.GlazingBar &&
			<>
				<div className='row mb-4'>
					<div className='col-4'>
						<label className='form-label' htmlFor='type'>Glaslat</label>
					</div>
					<div className='col-8'>
						<GlazingBarPickerField name={'glazingBarId'} />
					</div>
				</div>
			</>
		}
		{type === CatalogItemType.GlazingProfile &&
			<>
				<div className='row mb-4'>
					<div className='col-4'>
						<label className='form-label' htmlFor='type'>Beglazingsprofiel</label>
					</div>
					<div className='col-8'>
						<GlazingProfilePicker name={'glazingProfileId'} />
					</div>
				</div>
			</>
		}
		{type === CatalogItemType.CornerPiece &&
			<>
				<div className='row mb-4'>
					<div className='col-4'>
						<label className='form-label' htmlFor='type'>Hoekstukje</label>
					</div>
					<div className='col-8'>
						<CornerPiecePicker name={'cornerPieceId'} />
					</div>
				</div>
			</>
		}

		{type === CatalogItemType.LProfile &&
			<>
				<div className='row mb-4'>
					<div className='col-4'>
						<label className='form-label' htmlFor='type'>L-Profiel</label>
					</div>
					<div className='col-8'>
						<LProfilePicker name={'lProfileId'} />
					</div>
				</div>
			</>
		}

		<div className='row mb-4'>
			<div className='col-4'>
				<label className='form-label' htmlFor='type'>Actief</label>
			</div>
			<div className='col-8'>
				<div className='form-check form-switch'>
					<input className='form-check-input' type='checkbox' role='switch'
						   name='active'
						   value='true'
						   onChange={handleChange}
						   checked={values.active}
						   id='active' />
					<label className='form-check-label' htmlFor='active'>Actief</label>
				</div>
			</div>
		</div>


		<div className='row pt-5'>
			<div className='offset-4 col-8 d-flex justify-content-end'>
				<SubmitButton type='submit' className='btn btn-primary'
							  isSubmitting={isSubmitting}>Opslaan</SubmitButton>
			</div>
		</div>

	</form>);
};

export default CatalogItemUpsert;
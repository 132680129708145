import React from "react";
import {CopyToClipboard} from "react-copy-to-clipboard";
import './EkosietCopyToClipboard.scss';
import clsx from "clsx";

export type EkosietCopyToClipboardProps =
    {
        text?: string;
    }

export const EkosietCopyToClipboard: React.FC<EkosietCopyToClipboardProps> = (props) => {

    const enabled = typeof (props.text) !== "undefined";

    const className = clsx('ekosiet-copy-to-clipboard',
        enabled ? 'ekosiet-copy-to-clipboard--enabled' :
            'ekosiet-copy-to-clipboard--disabled');

    const content = <span className={className}><i className='fa-regular fa-copy'></i></span>;

    return enabled
        ? <CopyToClipboard text={props.text!}>{content}</CopyToClipboard>
        : content;
};

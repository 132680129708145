import {useModalForm, UseModalFormConfig} from "../../../../../shared/components/ModalForm";
import {
    SillDetailsNeutPosition, SillNeutPositionUpdate,
} from "../../../../../modules/api-client/generated";
import React, {useRef} from "react";
import {Form} from "formik";
import {Button, Modal} from "react-bootstrap";
import TextField from "../../../../../shared/components/TextField";
import SpinnerButton from "../../../../../shared/components/SpinnerButton";


export type SillNeutPositionEditFormValues = {
    name: string;
}

export const useSillNeutPositionEditModal = (config: {
    save: (neutPositionId: string, update: SillNeutPositionUpdate) => Promise<void>;
}) => {

    const neutPosition = useRef<SillDetailsNeutPosition | null>(null);

    const modalFormConfig: UseModalFormConfig<SillNeutPositionEditFormValues> = {
        onSubmit: async (v: SillNeutPositionEditFormValues) => await config.save(neutPosition.current!.id, {
            name: v.name,
        })
    };

    const {open: openModal, render} = useModalForm<SillNeutPositionEditFormValues>(modalFormConfig);

    const open = (p: SillDetailsNeutPosition) => {
        neutPosition.current = p;
        openModal({
            name: p.name,
        });
    };


    const element = render(({isSubmitting, close}) => {
        return <Form>
            <Modal.Header closeButton>
                <Modal.Title>Neut positie wijzigen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TextField row={true} label={"Naam"} name={'name'} required autoFocus/>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="link" className="mx-4" onClick={close}>
                    Annuleren
                </Button>
                <SpinnerButton type="submit" className="btn btn-primary" spinning={isSubmitting}
                               disabled={isSubmitting}>
                    Opslaan
                </SpinnerButton>
            </Modal.Footer>
        </Form>;
    });

    return {
        open: open,
        element: element
    }
}
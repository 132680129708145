import { useParams } from 'react-router-dom';
import {DateTime, Duration} from 'luxon';
import React, { useState } from 'react';
import { EkoCard, EkoCardBody, EkoCardHeader, EkoCardToolbar } from "../../../../../shared/components/card";
import { PeriodInput } from "../../../../../shared/components/date/PeriodInput";
import { EkoTable, TableHeader } from "../../../../../shared/components/table";
import {useQuery} from "@tanstack/react-query";
import {
  ProductionOperationStatRow
} from "../../../../../modules/api-client/generated";
import ApiClient from "../../../../../modules/api-client/ApiClient";
import Loading from "../../../../../shared/components/Loading";
import {dateToString, weekNumber} from "../../../../../shared/components/date";
import {useProductionOperations} from "../../../pm/hooks/use-list-production-operations";
import {TotalsHeader} from "../../../../../shared/components/TotalsHeader";

export function OperationStatistics() {
  const { operationId } = useParams();
  const defaultFrom = DateTime.now().startOf('week').minus({ day: DateTime.now().weekday > 3 ? 3 : 0 }).toFormat("yyyy-MM-dd");
  const defaultTo = DateTime.now().endOf('week').plus({ day: DateTime.now().weekday > 3 ? 3 : 0 }).toFormat("yyyy-MM-dd");
  const [fromDate, setFromDate] = useState<string>(defaultFrom);
  const [toDate, setToDate] = useState<string>(defaultTo);
  const [showCount, setShowCount] = useState<boolean>(false);

  const {
    isInitialLoading: isInitialLoadingProductionStats,
    data: employeeProductionStats,
    isError: isErrorProductionStats
  } = useQuery<ProductionOperationStatRow[]>(
      ['ProductionOperationStatRow', fromDate, toDate, operationId],
      () => ApiClient.Pm.Reporting.productionOperationStats(operationId!, fromDate, toDate).then((res) => res.data)
  );

  const {
    isInitialLoading: isInitialLoadingProductionOperations,
    data: productionOperations,
    isError: isErrorProductionOperations
  } = useProductionOperations();

  const setDateRange = (currentWeek: number, year: number = DateTime.now().year) => {
    const dt = DateTime.fromObject({
      weekYear: year,
      weekNumber: currentWeek
    });
    setFromDate(dt.startOf('week').toFormat("yyyy-MM-dd"));
    setToDate(dt.endOf('week').toFormat("yyyy-MM-dd"));
  }

  const resetRange = () => {
    setFromDate(defaultFrom);
    setToDate(defaultTo);
  };

  if(isInitialLoadingProductionOperations || isInitialLoadingProductionStats) {

    return <div><Loading/>test</div>;
  }

  if(!productionOperations || !employeeProductionStats) {
    return <div>Error loading data</div>;
  }

  if(isErrorProductionStats || isErrorProductionOperations) {
    return <div>Error loading data</div>;
  }

  const getOperationDurationInSeconds = () => {
    const operation = productionOperations.find(op => op.code === operationId);
    return operation ? Duration.fromISOTime(operation.averageDuration).as('seconds') : 0;
  };

  const employeeList = employeeProductionStats.map(row => Object.keys(row.productionByEmployee)).flat();
  const employees : string[] = [];
  for(const employee of employeeList) {
    if(!employees.includes(employee)) {
      employees.push(employee);
    }
  }
  employees.sort();

  const getDisplayValue = (stat : ProductionOperationStatRow, employee : string) => {
    const count = stat.productionByEmployee[employee];
    if(!count) {
      return '-';
    }
    if(showCount) {
      return count;
    }
    return Duration.fromMillis(count * getOperationDurationInSeconds() * 1000).toFormat('hh:mm');
  }

  const getDisplayRowTotalValue = (stat : ProductionOperationStatRow) => {
    const totalCount = calculateRowTotalCount(stat);
    if(showCount) {
      return totalCount;
    }
    return toShortFormattedDuration(Duration.fromMillis(totalCount * getOperationDurationInSeconds() * 1000));
  }

  const calculateRowTotalCount = (stat: ProductionOperationStatRow) => {
    return employees.reduce((sum, employee) => {
      const count = stat.productionByEmployee[(employee)] || 0;
      return sum + count;
    }, 0);
  };

  const toShortFormattedDuration = (duration : Duration) => {
    if(!duration) {
      return '-';
    }
    return duration.toFormat('hh:mm');
  }

  const getOperationName = () => {
    const operation = productionOperations.find(op => op.code === operationId);
    return operation ? operation.title : 'Onbekende handeling';
  };

  const getGrandTotalCount = () => {
    if(!employeeProductionStats || !productionOperations) {
      return 0;
    }
    return employeeProductionStats.reduce((sum, stat) => {
      return sum + calculateRowTotalCount(stat);
    }, 0);
  }
  const getGrandDuration = () => {
    if(!employeeProductionStats || !productionOperations) {
      return '-';
    }
    const totalDurationInSeconds = employeeProductionStats.reduce((sum, stat) => {
      return sum + calculateRowTotalDurationInSeconds(stat);
    }, 0);
    return toShortFormattedDuration(Duration.fromMillis(totalDurationInSeconds * 1000));
  }

  const calculateRowTotalDurationInSeconds = (stat: ProductionOperationStatRow) => {
    return employees.reduce((sum, employee) => {
      const count = stat.productionByEmployee[employee] || 0;
      const durationInSeconds = getOperationDurationInSeconds();
      return sum + (durationInSeconds * count);
    }, 0);
  };

  const getEmployeeTotalCount = (employee: string) => {
    return employeeProductionStats!.reduce((sum, stat) => {
      return sum + (stat.productionByEmployee[employee] || 0);
    }, 0);
  }


  const getTotalEmployeeDuration = (employee : string) => {
    const operationDuration = getOperationDurationInSeconds();
    const count = getEmployeeTotalCount(employee);
    return Duration.fromMillis(count * operationDuration * 1000);
  }

  let currentWeek = 0;

  return (
    <>
      <EkoCard>
        <EkoCardHeader title={getOperationName()}>
          <EkoCardToolbar>
            <div className='form-check form-switch'>
              <input className='form-check-input' type='checkbox' role='switch'
                     onChange={() => setShowCount(!showCount)}
                     checked={showCount}
                     id='displayCount'/>
              <label className='form-check-label me-2' htmlFor='displayCount'>Toon aantallen</label>
            </div>
            <PeriodInput
                startDate={fromDate}
                endDate={toDate}
                onStartDateChange={setFromDate}
                onEndDateChange={setToDate}
                resetRange={resetRange}
            />
          </EkoCardToolbar>
        </EkoCardHeader>
        <EkoCardBody>
          <EkoTable>
            <TableHeader className={'align-text-top'}>
              <th></th>
              <th>
                <TotalsHeader totalCount={getGrandTotalCount()}
                              formattedTotalDuration={getGrandDuration()}
                              showCount={showCount}
                              reference={'Totaal'}/>
              </th>
              {employees.map(emp => (
                  <th>
                    <TotalsHeader totalCount={getEmployeeTotalCount(emp)}
                                  formattedTotalDuration={toShortFormattedDuration(getTotalEmployeeDuration(emp))}
                                  showCount={showCount}
                                  reference={emp}/>
                  </th>
              ))}
            </TableHeader>
            <tbody>
            {employeeProductionStats.map((entry, index) => {
              const newWeek = weekNumber(entry.date) !== currentWeek;
              currentWeek = weekNumber(entry.date);

              return (
                  <>
                    {newWeek &&
                        <tr className={`fw-bold fs-6}`}>
                          <th><span style={{cursor: 'pointer'}}
                                                 onClick={() => setDateRange(DateTime.fromFormat(entry.date, "yyyy-MM-dd").weekNumber,
                                                     DateTime.fromFormat(entry.date, "yyyy-MM-dd").year)}>
												Week {currentWeek}
                                    </span></th>
                        </tr>
                    }
                    <tr key={index}>
                      <td className={'text-start text-nowrap'}>
                        {dateToString(entry.date, {
                          weekday: 'short',
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                          timeZone: 'UTC'
                        })}
                      </td>
                      <td className={'text-center'}>{getDisplayRowTotalValue(entry)}</td>
                      {employees.map(employee => (
                          <td key={employee} className={'text-center'}>
                            {getDisplayValue(entry, employee)}
                          </td>
                      ))}
                    </tr>
                  </>
              );
            })}
            </tbody>
          </EkoTable>
        </EkoCardBody>
      </EkoCard>
    </>
  );
}
import React from 'react';
import {KTSVG} from "../../../../shared/components/KTSVG";
import Euro from "../../../../shared/components/Euro";
import {CustomerSillStats} from "../../../../modules/api-client/generated";
import {toAbsoluteUrl} from "../../../../../_metronic/helpers";

type Props = {
    data: CustomerSillStats
    title: string
}
export const CustomerSalesStats: React.FC<Props> = ({data, title}) => {

        return (
            <>
                <div className="card card-flush mb-4 ">
                    <div
                        className="card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-top bgi-position-x-center align-items-start h-250px bg-success"
                        style={{backgroundImage: `url('${toAbsoluteUrl('/media/svg/shapes/wave-bg-purple.svg')}')`}}
                    >
                        <h3 className="card-title align-items-start flex-column text-white pt-15">
                            <span className="fw-bold fs-2x mb-3">{title}</span>
                            <div className="fs-1 text-white">
                                <span className=" fw-bold d-block fs-3qx lh-1 ls-n1 mb-1">
                                <Euro value={data.omzet}/>
                            </span>
                            </div>
                        </h3>
                    </div>
                    <div className="card-body mt-n20">
                        <div className="mt-n20 position-relative">
                            <div className="row g-3 g-lg-6">
                                <div className="col-6">
                                    <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                                        <div className="symbol symbol-30px me-5 mb-8">
                                <span className="symbol-label">
                                    <i className="ki-duotone ki-flask fs-1 text-primary"><span className="path1"></span><span
                                        className="path2"></span></i>
                                </span>
                                        </div>
                                        <div className="m-0">
                                            <span className="text-gray-700 fw-bold d-block fs-2qx lh-1 ls-n1 mb-1">
                                                <Euro value={data.omzetDorpels}/>
                                            </span>
                                            <span className="text-gray-500 fw-semibold fs-6">Dorpels</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                                        <div className="symbol symbol-30px me-5 mb-8">
                                <span className="symbol-label">
                                    <i className="ki-duotone ki-bank fs-1 text-primary"><span
                                        className="path1"></span><span className="path2"></span></i>
                                </span>
                                        </div>
                                        <div className="m-0">
                                            <span
                                                className="text-gray-700 fw-bold d-block fs-2qx lh-1 ls-n1 mb-1">
                                                <Euro value={data.omzetProducten}/>
                                            </span>
                                            <span className="text-gray-500 fw-semibold fs-6">Producten</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                                        <div className="symbol symbol-30px me-5 mb-8">
                                <span className="symbol-label">
                                    <i className="ki-duotone ki-award fs-1 text-primary"><span className="path1"></span><span
                                        className="path2"></span><span className="path3"></span></i>
                                </span>
                                        </div>
                                        <div className="m-0">
                                            <span
                                                className="text-gray-700 fw-bold d-block fs-2qx lh-1 ls-n1 mb-1">
                                                <Euro value={data.omzetTransport}/>
                                            </span>

                                            <span className="text-gray-500 fw-semibold fs-6">Transport</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                                        <div className="symbol symbol-30px me-5 mb-8">
                                <span className="symbol-label">
                                    <i className="ki-duotone ki-timer fs-1 text-primary"><span className="path1"></span><span
                                        className="path2"></span><span className="path3"></span></i>
                                </span>
                                        </div>
                                        <div className="m-0">
                                            <span
                                                className="text-gray-700 fw-bold d-block fs-2qx lh-1 ls-n1 mb-1">
                                                <Euro value={data.omzetOverig}/>
                                            </span>
                                            <span className="text-gray-500 fw-semibold fs-6">Overig</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
;
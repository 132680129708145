import React from "react";
import {
    ProductionItemStatusCodes,
    ProductionItemViewRepresentation
} from "../../modules/api-client/generated";

export interface Props {
    statuses: ProductionItemStatusCodes[]
}

const getStatusBadge = (status: ProductionItemStatusCodes, count: number | undefined = undefined, totalNumberOfItems: number | undefined = undefined, showLabel: boolean | undefined = true) => {
    let label;
    let color;

    switch (status) {
        case ProductionItemStatusCodes.NotStarted: {
            label = 'Niet gestart';
            color = 'secondary';
            break;
        }
        case ProductionItemStatusCodes.Started: {
            label = 'Gestart';
            color = 'info';
            break;
        }
        case ProductionItemStatusCodes.Finished: {
            label = 'Gereed';
            color = 'success';
            break;
        }
        case ProductionItemStatusCodes.Cancelled: {
            label = 'Geannuleerd';
            color = 'light-danger';
            break;
        }
        case ProductionItemStatusCodes.Failed: {
            label = 'Gefaald';
            color = 'danger';
            break;
        }
        default: {
            label = 'trash'
        }
    }
    return (
        <span className={`badge badge-${color} me-1 `}>
            {count!} {showLabel && label}
        </span>
    )
}

export const ProductionItemGroupStatus: React.FC<Props> = ({statuses}) => {
    var distinctStatuses = Array.from(new Set(statuses.map((item: ProductionItemStatusCodes) => item)));
    return <>{distinctStatuses.map(status => getStatusBadge(status))}</>
}

export interface OrderProductionStatusProps {
    productionItems: ProductionItemViewRepresentation[]
}

export const OrderProductionStatus: React.FC<OrderProductionStatusProps> = ({productionItems}) => {


    var counts = productionItems.map((x) => x.status).reduce((p: { [status: string]: number }, status) => {
        if (!p.hasOwnProperty(status)) {
            p[status] = 0;
        }
        p[status]++;
        return p;
    }, {});
    var countsExtended = Object.keys(counts).map(k => {
        return {title: k, count: counts[k]};
    });

    return <>
        {countsExtended.reverse().map((status, index) => {
            return <React.Fragment key={index}>
                {getStatusBadge(status.title as ProductionItemStatusCodes, status.count, productionItems.length, false)}
            </React.Fragment>
        })}

    </>
}
// @ts-nocheck
import {Column} from 'react-table'
import {RoleInfoCell} from './RoleInfoCell'
import {RolesActionsCell} from './RolesActionsCell'
import {RoleSelectionCell} from './RoleSelectionCell'
import {RolesCustomHeader} from './RolesCustomHeader'
import {RoleSelectionHeader} from './RoleSelectionHeader'
import {User} from '../../core/_models'
import {RoleDescriptionCell} from './RoleDescriptionCell'

const rolesColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <RoleSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <RoleSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => (
      <RolesCustomHeader tableProps={props} title='Naam' className='min-w-125px' />
    ),
    id: 'name',
    Cell: ({...props}) => <RoleInfoCell name={props.data[props.row.index].name} />,
  },
  {
    Header: (props) => (
      <RolesCustomHeader tableProps={props} title='Omschrijving' className='min-w-125px' />
    ),
    accessor: 'description',
    Cell: ({...props}) => (
      <RoleDescriptionCell description={props.data[props.row.index].description} />
    ),
  },
  {
    Header: (props) => (
      <RolesCustomHeader tableProps={props} title='Acties' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <RolesActionsCell id={props.data[props.row.index].id} />,
  },
]

export {rolesColumns}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {useParams} from "react-router-dom";
import {EkoCard, EkoCardBody, EkoCardHeader} from "../../../../../shared/components/card";
import {CustomerModalFormWrapper} from "../../components/CustomerModalFormWrapper";
import {CustomerAddressesModalBody} from "../../components/CustomerAddressesModalBody";

export function CustomerAddresses() {
    const {id} = useParams<{ id: string }>();
    return (
            <EkoCard>
                <EkoCardHeader title={'Adressen'}>
                </EkoCardHeader>
                <EkoCardBody>
                    <CustomerAddressesModalBody
                        customerId={id}
                        handleClose={() => null}
                    />
                </EkoCardBody>
            </EkoCard>
    )
}

import React, {useEffect, useLayoutEffect} from 'react';
import {Formik, FormikHelpers, useFormikContext} from 'formik';
import * as api from '../../../../modules/api-client/generated';
import {Link, useNavigate, useParams} from 'react-router-dom';
import SubmitButton from '../../../../shared/components/SubmitButton';
import {useRabbetQuery, useRabbetUpsertMutation} from './hooks';
import RabbetSvg from '../../../../shared/components/RabbetSvg';
import ProfileDropzone from '../components/ProfileDropzone';
import * as Yup from 'yup';
import FieldErrors from '../../../../shared/components/FieldErrors';
import RabbetTypeText from './RabbetTypeText';
import RabbetApplicationText from './RabbetApplicationText';
import {MasterdataRoutePath} from '../../masterdata/MasterdataRoutes';
import AbsLink from '../../../../shared/components/RouteHelpers';
import {BackofficeRoutePath} from '../../../RoutePath';
import {RabbetType, TurboholRabbet} from '../../../../modules/api-client/generated';
import {TurboholTypePicker} from '../turbohol-type/TurboholTypePicker';
import {CompartmentLayoutTypePickerField} from '../compartment-layout-type/CompartmentLayoutTypePicker';
import Loading from '../../../../shared/components/Loading';
import TextField from "../../../../shared/components/TextField";

export interface RabbetFormValues {
    code: string;
    name: string;
    type: api.RabbetType | '';
    application: string;
    geometry: string;
    width: number | '';
    depth: number | '';
    compartmentLayoutTypeIds: string[];
    turboholRabbet?: TurboholRabbet;
    groove?: {
        width: number | '',
        depth: number | '',
        center: number | '',
    }
    active: boolean;
}


const RabbetUpsert: React.FC = () => {

    const {id} = useParams<{ id: string }>();

    const mutation = useRabbetUpsertMutation(id);

    const navigate = useNavigate();

    const onSubmit = async (values: RabbetFormValues, {setSubmitting}: FormikHelpers<RabbetFormValues>) => {

        await mutation.mutateAsync(values, {
            onSuccess: (rabbet) => {
                if (id) {
                    navigate(`../`, {relative: 'path'});
                } else {
                    navigate(`../${rabbet.id}`, {relative: 'path'});
                }
            },
            onError: () => {
            },
            onSettled: () => {
                setSubmitting(false)
            }
        });
    };

    const {data: rabbet, isInitialLoading, isError} = useRabbetQuery(id);

    if (isInitialLoading) return <Loading/>;
    if (isError) return (<>Error...</>);

    const initialValues: RabbetFormValues = {
        code: rabbet?.code ?? '',
        name: rabbet?.name ?? '',
        type: rabbet?.type ?? '',
        application: rabbet?.application ?? '',
        geometry: rabbet?.geometry ?? '',
        width: rabbet?.width ?? '',
        depth: rabbet?.depth ?? '',
        compartmentLayoutTypeIds: rabbet?.compartmentLayoutTypes?.map(x => x.id) ?? [],

        turboholRabbet: rabbet?.turboholRabbet ? {
            width: rabbet.turboholRabbet.width ?? 0,
            depth: rabbet.turboholRabbet.depth ?? 0,
            turboholTypeId: rabbet.turboholRabbet.turboholType?.id ?? '',
            beslagSponningDepth: rabbet.turboholRabbet.beslagSponningDepth ?? 0,
            aanslagNok: rabbet.turboholRabbet.aanslagNok ? {
                width: rabbet.turboholRabbet.aanslagNok?.width ?? 0,
                depth: rabbet.turboholRabbet.aanslagNok?.depth ?? 0,
            } : undefined,
        } : undefined,

        groove: rabbet?.groove ? {
            width: rabbet.groove.width ?? '',
            depth: rabbet.groove.depth ?? '',
            center: rabbet.groove.center ?? '',
        } : undefined,

        active: rabbet?.active ?? false,
    };

    return <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={UpsertSchema}>
        <RabbetUpsertForm id={id} mutation={mutation}/>
    </Formik>;
};

const UpsertSchema = Yup.object().shape({

    code: Yup.string()
        .min(1)
        .max(50)
        .required('Geen code ingevoerd'),

    name: Yup.string()
        .min(1)
        .max(200)
        .required('Geen naam ingevoerd'),

    type: Yup.string()
        .oneOf(Object.values(api.RabbetType))
        .required('Geen sponning type gekozen'),

    application: Yup
        .string()
        .oneOf(Object.values(api.RabbetApplication))
        .when('type', {
            is: (type: api.RabbetType) => {
                return type === api.RabbetType.Stomp;
            },
            then: Yup.string().required(),
        }),

    geometry: Yup.string()
        .required('Geen profiel ingevoerd'),


    groove: Yup.object().shape({
        width: Yup.number()
            .min(0),
        depth: Yup.number(),
        center: Yup.number()
            .min(0)
    }),
});

interface RabbetUpsertFormProps {
    id: string | undefined;
    mutation: ReturnType<typeof useRabbetUpsertMutation>;
}

export const RabbetUpsertForm: React.FC<RabbetUpsertFormProps> = (props) => {

    const {
        values,
        handleSubmit,
        handleChange,
        isSubmitting,
        errors,
        setErrors,
        handleBlur,
        setFieldValue,
    } = useFormikContext<RabbetFormValues>();

    const initialApplication = values.application;

    const mergeServerErrors = () => {

        if (props.mutation.serverValidationErrors) {
            setErrors({...errors, ...props.mutation.serverValidationErrors});
        }
    };
    useLayoutEffect(mergeServerErrors, [props.mutation.serverValidationErrors, errors, setErrors]);


    const applicationDisabled = !(
        values.type === api.RabbetType.Stomp ||
        values.type === api.RabbetType.HoekProfile ||
        values.type === api.RabbetType.DubbeleSponning ||
        values.type === api.RabbetType.HefSchuif);

    useEffect(() => {
        switch (values.type) {
            case api.RabbetType.DraaiKiep:
            case api.RabbetType.BinnendraaiendStomp: {
                if (!initialApplication || applicationDisabled) {
                    setFieldValue('application', api.RabbetApplication.Inside);
                }
                break;
            }
            case api.RabbetType.Stomp:
            case api.RabbetType.HoekProfile:
            case api.RabbetType.DubbeleSponning:
            case api.RabbetType.HefSchuif: {
                if (!initialApplication || applicationDisabled) {
                    setFieldValue('application', api.RabbetApplication.InsideAndOutside);
                }
                break;
            }
            case api.RabbetType.VlakkedeurSponning: {
                if (!initialApplication || applicationDisabled) {
                    setFieldValue('application', api.RabbetApplication.Outside);
                }
                break;
            }
            default: {
                setFieldValue('application', '');
            }
        }

    }, [values.type]);


    const isAdd = !props.id;

    // const grooveWidth = Number(values.groove?.width);
    // const grooveCenter = Number(values.groove?.center);
    // const randWidth = !errors.groove? && !errors.groove.width
    //     ? groove.center - groove.width / 2
    //     : '';


    const allowGeometryUpdate = isAdd || values.type === api.RabbetType.HefSchuif;


    return (<form onSubmit={handleSubmit}>

        <div className={'row'}>
            <div className='col-12 col-md-10'>
                <div className='card'>
                    <div className='card-header'>
                        <h3 className='card-title'>
                            {props.id && 'Sponning wijzigen'}
                            {!props.id && 'Sponning toevoegen'}
                        </h3>
                        <div className='card-toolbar'>
                        </div>
                    </div>

                    <div className='card-body'>
                        <div className='row mb-4'>
                            <div className='col-4'>
                                <label className='form-label'>Code</label>
                                <FieldErrors field='code'/>
                            </div>
                            <div className='col-8'>
                                <input type='text' className={`form-control`}
                                       name='code' required
                                       autoComplete='off'
                                       placeholder='Unieke code voor de sponning'
                                       onChange={handleChange}
                                       value={values.code}
                                       autoFocus/>

                            </div>
                        </div>

                        <div className='row mb-4'>
                            <div className='col-4'>
                                <label className='form-label'>Naam</label>
                                <FieldErrors field='name'/>
                            </div>
                            <div className='col-8'>
                                <input type='text' className='form-control' name='name' required
                                       placeholder=''
                                       onChange={handleChange}
                                       value={values.name}
                                />
                            </div>
                        </div>

                        <div className='row mb-4'>
                            <div className='col-4'>
                                <label className='form-label' htmlFor='type'>Sponning type</label>
                                <FieldErrors field='type'/>
                            </div>
                            <div className='col-8'>
                                <select id='type' className='form-select' name='type' required
                                        onChange={handleChange}
                                        value={values.type}>
                                    <option value=''>Selecteer sponning type</option>

                                    {Object.values(api.RabbetType).map(value =>
                                        <option key={value} value={value}><RabbetTypeText code={value}/></option>,
                                    )}
                                </select>
                            </div>
                        </div>

                        <div className='row mb-4'>
                            <div className='col-4'>
                                <label className='form-label' htmlFor='type'>Toepassing</label>
                                <FieldErrors field='application'/>
                            </div>
                            <div className='col-8'>
                                <div className='form-check mb-3'>
                                    <input className='form-check-input' type='radio'
                                           value={api.RabbetApplication.Inside}
                                           id='application_inside'
                                           name='application'
                                           onBlur={handleBlur}
                                           onChange={handleChange}
                                           checked={values.application === api.RabbetApplication.Inside}
                                           disabled={applicationDisabled}
                                    />
                                    <label className='form-check-label' htmlFor='application_inside'>
                                        <RabbetApplicationText code={api.RabbetApplication.Inside}/>
                                    </label>
                                </div>
                                <div className='form-check mb-3'>
                                    <input className='form-check-input' type='radio'
                                           value={api.RabbetApplication.Outside}
                                           id='application_outside'
                                           name='application'
                                           onBlur={handleBlur}
                                           onChange={handleChange}
                                           checked={values.application === api.RabbetApplication.Outside}
                                           disabled={applicationDisabled}
                                    />
                                    <label className='form-check-label' htmlFor='application_outside'>
                                        <RabbetApplicationText code={api.RabbetApplication.Outside}/>
                                    </label>
                                </div>
                                <div className='form-check mb-3'>
                                    <input className='form-check-input' type='radio'
                                           value={api.RabbetApplication.InsideAndOutside}
                                           id='application_inside_outside'
                                           name='application'
                                           onBlur={handleBlur}
                                           onChange={handleChange}
                                           checked={values.application === api.RabbetApplication.InsideAndOutside}
                                           disabled={applicationDisabled}
                                    />
                                    <label className='form-check-label' htmlFor='application_inside_outside'>
                                        <RabbetApplicationText code={api.RabbetApplication.InsideAndOutside}/>
                                    </label>
                                </div>
                            </div>
                        </div>

                        {allowGeometryUpdate &&

                            <div className='row mb-4'>
                                <div className='col-4'>
                                    <label className='form-label' htmlFor='type'>Profiel</label>
                                    <FieldErrors field='geometry'/>
                                </div>
                                <div className='col-8'>
                                    <div className='position-relative'>
                                        <ProfileDropzone onSelect={(selected) => setFieldValue('geometry', selected)}
                                                         preview={(profile) => <RabbetSvg profile={profile}/>}
                                                         show={values.geometry ? 'drag' : 'always'}>
                                            {({openFileDialog}) => <>
                                                <div
                                                    className='form-control d-flex justify-content-center cursor-pointer'
                                                    onClick={openFileDialog}

                                                    style={{height: '200px'}}>

                                                    {values.geometry &&
                                                        <RabbetSvg profile={values.geometry} svgHeight='140px'/>}

                                                </div>
                                                {values.geometry &&
                                                    <div className='py-3 d-flex justify-content-between'>
                                                        <button type='button' className='btn btn-light-primary me-3'
                                                                onClick={openFileDialog}>Wijzig
                                                        </button>
                                                        <button type='button' className='btn btn-link'
                                                                onClick={() => setFieldValue('geometry', '')
                                                                }>Wissen
                                                        </button>
                                                    </div>}
                                            </>
                                            }
                                        </ProfileDropzone>
                                    </div>
                                </div>
                            </div>
                        }

                        <TextField row={true} required type="number" name={'width'} inputGroupSuffixText="mm"
                                   label={'Breedte'}/>
                        <TextField row={true} required type="number" name={'depth'} inputGroupSuffixText="mm"
                                   label={'Diepte'}/>

                        {values.type === RabbetType.HefSchuif && <>

                            <TextField row={true} required name={'groove.width'}
                                       type="number"
                                       inputGroupSuffixText="mm"
                                       label={'Breedte groef'}
                                       help="De breedte van de groef voor de vastedeur of de schuifdeur."/>

                            <TextField row={true} required name={'groove.depth'}
                                       type="number"
                                       inputGroupSuffixText="mm"
                                       label={'Diepte groef'}
                                       help="De diepte van de groef voor de vastedeur of de schuifdeur. Kan negatief zijn."/>

                            <TextField row={true} required name={'groove.center'}
                                       type="number"
                                       inputGroupSuffixText="mm"
                                       label={'Hart groef'}
                                       help="Het hart van de groef (= hart van de looprail)."/>

                            {/*<div className='row mb-4'>*/}
                            {/*    <div className='col'>*/}
                            {/*        <label className='form-label'>Rand</label>*/}
                            {/*    </div>*/}
                            {/*    <div className='col-8'>*/}
                            {/*        <div className='input-group'>*/}
                            {/*            <input type='text' className='form-control'*/}
                            {/*                   placeholder=''*/}
                            {/*                   disabled*/}
                            {/*                   value={randWidth}/>*/}
                            {/*            <span className='input-group-text'>mm</span>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </>}

                        <div className='row mb-4'>
                            <div className='col-4'>
                                <label className='form-label' htmlFor='type'>Vakindelingen</label>
                            </div>
                            <div className='col-8'>
                                <CompartmentLayoutTypePickerField multi={true} name={'compartmentLayoutTypeIds'}/>
                            </div>
                        </div>

                        {(values.type === RabbetType.DraaiKiep || values.type === RabbetType.BinnendraaiendStomp) && <>
                            <div className='row mb-4'>
                                <div className='col'>
                                    <label className='form-label'>Breedte turboholsponning</label>
                                    <FieldErrors field='turboholRabbet.width'/>

                                </div>
                                <div className='col-8'>
                                    <div className='input-group'>
                                        <input type='text' className='form-control' name='turboholRabbet.width' required
                                               placeholder=''
                                               onChange={handleChange}
                                               value={values.turboholRabbet?.width ?? ''}/>
                                        <span className='input-group-text'>mm</span>
                                    </div>
                                </div>
                            </div>

                            <div className='row mb-4'>
                                <div className='col'>
                                    <label className='form-label'>Diepte turboholsponning</label>
                                    <FieldErrors field='turboholRabbet.depth'/>

                                </div>
                                <div className='col-8'>
                                    <div className='input-group'>
                                        <input type='text' className='form-control' name='turboholRabbet.depth' required
                                               placeholder=''
                                               onChange={handleChange}
                                               value={values.turboholRabbet?.depth?? ''}/>
                                        <span className='input-group-text'>mm</span>
                                    </div>
                                </div>
                            </div>

                            <div className='row mb-4'>
                                <div className='col'>
                                    <label className='form-label'>Turbohol type</label>
                                    <FieldErrors field='turboholRabbet.turboholTypeId'/>

                                </div>
                                <div className='col-8'>
                                    <TurboholTypePicker name='turboholRabbet.turboholTypeId' required={true}/>
                                </div>
                            </div>

                            <div className='row mb-4'>
                                <div className='col'>
                                    <label className='form-label'>Diepte beslagsponning</label>
                                    <FieldErrors field='turboholRabbet.beslagSponningDepth'/>

                                </div>
                                <div className='col-8'>
                                    <div className='input-group'>
                                        <input type='text' className='form-control'
                                               name='turboholRabbet.beslagSponningDepth' required
                                               placeholder=''
                                               onChange={handleChange}
                                               value={values.turboholRabbet?.beslagSponningDepth ?? ''}/>
                                        <span className='input-group-text'>mm</span>
                                    </div>
                                </div>
                            </div>


                            <div className='row mb-4'>
                                <div className='col'>
                                    <label className='form-label'>Breedte aanslagnok</label>
                                    <FieldErrors field='turboholRabbet.aanslagNok.width'/>

                                </div>
                                <div className='col-8'>
                                    <div className='input-group'>
                                        <input type='text' className='form-control'
                                               name='turboholRabbet.aanslagNok.width' required
                                               placeholder=''
                                               onChange={handleChange}
                                               value={values.turboholRabbet?.aanslagNok?.width ?? ''}/>
                                        <span className='input-group-text'>mm</span>
                                    </div>
                                </div>
                            </div>

                            <div className='row mb-4'>
                                <div className='col'>
                                    <label className='form-label'>Diepte aanslagnok</label>
                                    <FieldErrors field='turboholRabbet.aanslagNok.depth'/>

                                </div>
                                <div className='col-8'>
                                    <div className='input-group'>
                                        <input type='text' className='form-control'
                                               name='turboholRabbet.aanslagNok.depth' required
                                               placeholder=''
                                               onChange={handleChange}
                                               value={values.turboholRabbet?.aanslagNok?.depth ?? ''}/>
                                        <span className='input-group-text'>mm</span>
                                    </div>
                                </div>
                            </div>
                        </>
                        }

                        <div className='row mb-4'>
                            <div className='col-4'>
                                <label className='form-label'>Actief</label>
                                <FieldErrors field='active'/>
                            </div>
                            <div className='col-8'>

                                <div className='form-check form-switch'>
                                    <input className='form-check-input' type='checkbox' role='switch'
                                           name='active'
                                           value='true'
                                           onChange={handleChange}
                                           checked={values.active}
                                           id='active'/>
                                    <label className='form-check-label' htmlFor='active'>Actief</label>
                                </div>

                            </div>
                        </div>


                        <div className='row pt-5'>
                            <div className='offset-4 col-8 d-flex justify-content-end'>
                                <Link className='btn btn-link me-5'
                                      to={AbsLink(BackofficeRoutePath.masterdata, MasterdataRoutePath.rabbetList)}>Annuleren</Link>
                                <SubmitButton type='submit' className='btn btn-primary'
                                              isSubmitting={isSubmitting}>Opslaan</SubmitButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>);
};

export default RabbetUpsert;
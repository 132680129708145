import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { toast } from "react-hot-toast"
import { AxiosError } from "axios";
import {
    ProductionBatchViewArrayODataValue,
} from "../../../../modules/api-client/generated";
import ApiClient from "../../../../modules/api-client/ApiClient";

// export const useListProductionBatchesOdata = ($filter?: string, enabled: boolean | undefined = true):
export const useListProductionBatchesOdata = ($top?:number|undefined, $skip?:number|undefined,$orderBy?: string,$filter?: string,$count:boolean|undefined = false, enabled: boolean | undefined = true):
    UseQueryResult<ProductionBatchViewArrayODataValue, unknown> => {

    if ($filter === "") {
        $filter = undefined
    }
    
    var key = 'pmProductionBatchesListOdataGet';

    const response = useQuery([key, $filter, $top, $skip, $count, $orderBy], () => {
        return ApiClient.Pm.ProductionBatch.pmProductionBatchListOdataGet(
            $top,$skip,$orderBy||'draftedDate desc', $filter, $count, undefined, undefined)
            .then(d => d.data);
    },
        { enabled: enabled }
    )

    if (response.error) {
        var error = response.error as AxiosError;
        toast.error(error?.message);
        // toast.error(error?.response?.data?.title || 'Onbekende fout!');
    }

    return response;
};

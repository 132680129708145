import {FC} from 'react'

type Props = {
  time?: string
}

const UserJoinedTimeCell: FC<Props> = ({time}) => {
  return (
    <>
      {time && (
        <div className='text-gray-800'>
          {new Date(time).toLocaleString('nl-NL', {
            dateStyle: 'medium',
            timeStyle: 'short',
            hour12: false,
          })}
        </div>
      )}
    </>
  )
}

export {UserJoinedTimeCell}

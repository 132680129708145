import {useQueryClient} from "@tanstack/react-query";
import {toast} from "react-hot-toast"
import ApiClient from "../../../../../modules/api-client/ApiClient";
import {useEkosietMutation} from "../../../../../shared/hooks/useEkosietMutation";
import {NotFound, ValidationFailed} from "../../../../../modules/api-client/Responses";

export const usePutExpeditionNotPlanned = (id: string) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<void, NotFound | ValidationFailed, undefined>(() => {
            return ApiClient.Em.Expedition.emExpeditionOrderIdNotPlannedPut(id, undefined, undefined).then();
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['smOrderIdGet', id]);
                queryClient.invalidateQueries(['emExpeditionOrderIdGet', id]);
                queryClient.invalidateQueries(['emExpeditionListOdataGet']);
                queryClient.invalidateQueries(['emExpeditionOrderExpeditionByWeekGet']);
                queryClient.invalidateQueries(['emExpeditionByWeekGet']);
                queryClient.invalidateQueries(['emExpeditionTransportPlanningByWeekGet']);
                queryClient.invalidateQueries(['emExpeditionTransportPlanningByOrderGet']);

                toast.success("Expeditie status gewijzigd");
                // Since the order status can be updated in the background, we need to wait a bit before refreshing the data.
                // This is a temporary solution until we have a better way to handle this!!
                setTimeout(() => {
                    queryClient.invalidateQueries(['smOrderIdGet', id]);
                    queryClient.invalidateQueries(['pmInvoicesListOdataGet']).then();
                },3000)

            },
            onError: () => {
                toast.error("Wijzigen mislukt!");
            }
        }
    );
};
export const usePutExpeditionPlanned = (id: string) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<void, NotFound | ValidationFailed, undefined>(() => {
        return ApiClient.Em.Expedition.emExpeditionOrderIdPlannedPut(id, undefined, undefined).then();
    },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['smOrderIdGet', id]);
                queryClient.invalidateQueries(['emExpeditionOrderIdGet', id]);
                queryClient.invalidateQueries(['emExpeditionListOdataGet']);
                queryClient.invalidateQueries(['emExpeditionOrderExpeditionByWeekGet']);
                queryClient.invalidateQueries(['emExpeditionByWeekGet']);
                queryClient.invalidateQueries(['emExpeditionTransportPlanningByWeekGet']);
                queryClient.invalidateQueries(['emExpeditionTransportPlanningByOrderGet']);
                toast.success("Expeditie status gewijzigd");

            },
            onError: () => {
                toast.error("Wijzigen mislukt!");
            }
        }
    );
};
export const usePutExpeditionDelivered = () => {

    const queryClient = useQueryClient()

    return useEkosietMutation<void, NotFound | ValidationFailed, string>((orderId) => {
        return ApiClient.Em.Expedition.emExpeditionOrderIdDeliveredPut(orderId, undefined, undefined).then();
    },
        {
            onSuccess: (data,orderId) => {
                queryClient.invalidateQueries(['smOrderIdGet', orderId]);
                queryClient.invalidateQueries(['emExpeditionOrderIdGet', orderId]);
                queryClient.invalidateQueries(['emExpeditionListOdataGet']);
                queryClient.invalidateQueries(['emExpeditionOrderExpeditionByWeekGet']);
                queryClient.invalidateQueries(['emExpeditionByWeekGet']);
                queryClient.invalidateQueries(['emExpeditionTransportPlanningByWeekGet']);
                queryClient.invalidateQueries(['emExpeditionTransportPlanningByOrderGet']);

                toast.success("Expeditie status gewijzigd");
            },
            onError: () => {
                toast.error("Wijzigen mislukt!");
            }
        }
    );
};

export const usePutExpeditionInTransit = () => {

    const queryClient = useQueryClient()

    return useEkosietMutation<void, NotFound | ValidationFailed, string>((orderId) => {
        return ApiClient.Em.Expedition.emExpeditionOrderIdInTransitPut(orderId, undefined, undefined).then();
    },
        {
            onSuccess: (data,orderId) => {
                queryClient.invalidateQueries(['smOrderIdGet', orderId]);
                queryClient.invalidateQueries(['emExpeditionOrderIdGet', orderId]);
                queryClient.invalidateQueries(['emExpeditionListOdataGet']);
                queryClient.invalidateQueries(['emExpeditionOrderExpeditionByWeekGet']);
                queryClient.invalidateQueries(['emExpeditionByWeekGet']);
                queryClient.invalidateQueries(['emExpeditionTransportPlanningByWeekGet']);
                queryClient.invalidateQueries(['emExpeditionTransportPlanningByOrderGet']);

                toast.success("Expeditie status gewijzigd");
            },
            onError: () => {
                toast.error("Wijzigen mislukt!");
            }
        }
    );
};

export const usePutExpeditionReadyForShipment = () => {

    const queryClient = useQueryClient()

    return useEkosietMutation<void, NotFound | ValidationFailed, string>((orderId) => {
        return ApiClient.Em.Expedition.emExpeditionOrderIdReadyForShipmentPut(orderId, undefined, undefined).then();
    },
        {
            onSuccess: (data, orderId) => {                
                queryClient.invalidateQueries(['smOrderIdGet', orderId]);
                queryClient.invalidateQueries(['emExpeditionOrderIdGet', orderId]);
                queryClient.invalidateQueries(['emExpeditionListOdataGet']);
                queryClient.invalidateQueries(['emExpeditionOrderExpeditionByWeekGet']);
                queryClient.invalidateQueries(['emExpeditionByWeekGet']);
                queryClient.invalidateQueries(['emExpeditionTransportPlanningByWeekGet']);
                queryClient.invalidateQueries(['emExpeditionTransportPlanningByOrderGet']);
                queryClient.invalidateQueries(['emExpeditionExpeditionsForProductionItemProductionItemCodeGet']);

                toast.success("Expeditie status gewijzigd");
            },
            onError: () => {
                toast.error("Wijzigen mislukt!");
            }
        }
    );
};

export const usePutExpeditionCancelled = (id: string) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<void, NotFound | ValidationFailed, undefined>(() => {
        return ApiClient.Em.Expedition.emExpeditionOrderIdCancelledPut(id, undefined, undefined).then();
    },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['smOrderIdGet', id]);
                queryClient.invalidateQueries(['emExpeditionOrderIdGet', id]);
                queryClient.invalidateQueries(['emExpeditionListOdataGet']);
                queryClient.invalidateQueries(['emExpeditionOrderExpeditionByWeekGet']);
                queryClient.invalidateQueries(['emExpeditionByWeekGet']);
                queryClient.invalidateQueries(['emExpeditionTransportPlanningByWeekGet']);
                queryClient.invalidateQueries(['emExpeditionTransportPlanningByOrderGet']);
                
                toast.success("Expeditie status gewijzigd");
            },
            onError: () => {
                toast.error("Wijzigen mislukt!");
            }
        }
    );
};
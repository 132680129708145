import {ITask, IStation, IProductionItemType} from "./model"

export const stationList: Array<IStation> = [
    {
        code: "",
        title: "Sawmaster",
        type: "",
        description: "Zagen van dorpelprofielen, beglazingsprofielen, glaslatten, aanslagprofielen",
        handelingen: [
            {
                code: "",
                title: "Dorpelprofiel zagen",
                level: 0,
                description: "",
                   instructies: [
                        {
                            title: "Product",
                            type: "Product van type dorpelprofiel"
                        },
                        {
                            title: "lengte",
                            type: "Lengte in mm"
                        }
                    ]
            },
            {
                code: "",
                title: "Glaslat zagen",
                description: "",
                level: 0,
                   instructies: [
                        {
                            title: "Product",
                            type: "Product van type dorpelprofiel"
                        },
                        {
                            title: "lengte",
                            type: "Lengte in mm"
                        }
                    ]
            },
            {
                code: "",
                title: "Beglazingsprofiel zagen",
                level: 0,
                description: "",
                   instructies: [
                        {
                            title: "Product",
                            type: "Product van type dorpelprofiel"
                        },
                        {
                            title: "lengte",
                            type: "Lengte in mm"
                        }
                    ]
            }
            ,
            {
                code: "",
                title: "Aanslagprofiel zagen",
                description: "",
                   instructies: [
                        {
                            title: "Product",
                            type: "Product van type dorpelprofiel"
                        },
                        {
                            title: "lengte",
                            type: "Lengte in mm"
                        }
                    ]
            }
        ],
        medewerkers: [],
        slots: [
            {
                code: "",
                title: "Vandaag",
                capaciteit: 100,
                description: "Zagen van dorpelprofielen, beglazingsprofielen, glaslatten, aanslagprofielen",
                opdrachten: [
                    {
                        "code": "",
                        "title": "Glaslat BU-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'012542'",
                        "dorpel": "'056879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Glaslat BU-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'112542'",
                        "dorpel": "'156879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Glaslat BU-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'212542'",
                        "dorpel": "'256879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-D57-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'312542'",
                        "dorpel": "'356879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "VG-17 m¹BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'412542'",
                        "dorpel": "'456879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "ZL-A-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'512542'",
                        "dorpel": "'556879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-C57-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'612542'",
                        "dorpel": "'656879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Glaslat BU-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'712542'",
                        "dorpel": "'756879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-C67-GREY",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'812542'",
                        "dorpel": "'856879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-C57-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'912542'",
                        "dorpel": "'956879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "\tDK-30 m¹BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1012542'",
                        "dorpel": "'1056879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "ZL-A-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1112542'",
                        "dorpel": "'1156879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-C67-GREY",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1212542'",
                        "dorpel": "'1256879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-D57-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1312542'",
                        "dorpel": "'1356879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-D57-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1412542'",
                        "dorpel": "'1456879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Glaslat BU-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1512542'",
                        "dorpel": "'1556879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "VG-17 m¹BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1612542'",
                        "dorpel": "'1656879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-C57-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1712542'",
                        "dorpel": "'1756879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "VW-H-BH-BU-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1812542'",
                        "dorpel": "'1856879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "ZL-A-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1912542'",
                        "dorpel": "'1956879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "\tDK-30 m¹BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2012542'",
                        "dorpel": "'2056879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "VW-H-BH-BU-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2112542'",
                        "dorpel": "'2156879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-D57-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2212542'",
                        "dorpel": "'2256879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Glaslat BU-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2312542'",
                        "dorpel": "'2356879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "VW-H-BH-BU-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2412542'",
                        "dorpel": "'2456879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "VW-H-BH-BU-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2512542'",
                        "dorpel": "'2556879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "\tDK-30 m¹BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2612542'",
                        "dorpel": "'2656879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "\tDK-30 m¹BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2712542'",
                        "dorpel": "'2756879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-C57-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2812542'",
                        "dorpel": "'2856879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "VW-H-BH-BU-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2912542'",
                        "dorpel": "'2956879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-D57-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3012542'",
                        "dorpel": "'3056879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-D57-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3112542'",
                        "dorpel": "'3156879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "ZL-A-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3212542'",
                        "dorpel": "'3256879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Glaslat BU-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3312542'",
                        "dorpel": "'3356879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "VW-H-BH-BU-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3412542'",
                        "dorpel": "'3456879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-C57-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3512542'",
                        "dorpel": "'3556879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Glaslat BU-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3612542'",
                        "dorpel": "'3656879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-C57-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3712542'",
                        "dorpel": "'3756879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-C57-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3812542'",
                        "dorpel": "'3856879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "\tDK-30 m¹BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3912542'",
                        "dorpel": "'3956879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "\tDK-30 m¹BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'4012542'",
                        "dorpel": "'4056879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "VW-H-BH-BU-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'4112542'",
                        "dorpel": "'4156879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "ZL-A-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'4212542'",
                        "dorpel": "'4256879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Glaslat BU-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'4312542'",
                        "dorpel": "'4356879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "ZL-A-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'4412542'",
                        "dorpel": "'4456879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-C57-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'4512542'",
                        "dorpel": "'4556879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "\tDK-30 m¹BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'4612542'",
                        "dorpel": "'4656879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-C57-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'4712542'",
                        "dorpel": "'4756879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "VW-H-BH-BU-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'4812542'",
                        "dorpel": "'4856879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "VG-17 m¹BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'4912542'",
                        "dorpel": "'4956879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "VW-H-BH-BU-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'5012542'",
                        "dorpel": "'5056879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-C57-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'5112542'",
                        "dorpel": "'5156879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-C57-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'5212542'",
                        "dorpel": "'5256879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "VW-H-BH-BU-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'5312542'",
                        "dorpel": "'5356879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "\tDK-30 m¹BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'5412542'",
                        "dorpel": "'5456879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-C57-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'5512542'",
                        "dorpel": "'5556879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "\tDK-30 m¹BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'5612542'",
                        "dorpel": "'5656879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-C67-GREY",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'5712542'",
                        "dorpel": "'5756879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "VG-17 m¹BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'5812542'",
                        "dorpel": "'5856879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Glaslat BU-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'5912542'",
                        "dorpel": "'5956879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "\tDK-30 m¹BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'6012542'",
                        "dorpel": "'6056879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "VW-H-BH-BU-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'6112542'",
                        "dorpel": "'6156879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "\tDK-30 m¹BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'6212542'",
                        "dorpel": "'6256879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "\tDK-30 m¹BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'6312542'",
                        "dorpel": "'6356879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "\tDK-30 m¹BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'6412542'",
                        "dorpel": "'6456879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "ZL-A-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'6512542'",
                        "dorpel": "'6556879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Glaslat BU-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'6612542'",
                        "dorpel": "'6656879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-C57-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'6712542'",
                        "dorpel": "'6756879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "VW-H-BH-BU-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'6812542'",
                        "dorpel": "'6856879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-C67-GREY",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'6912542'",
                        "dorpel": "'6956879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-C57-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'7012542'",
                        "dorpel": "'7056879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-D57-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'7112542'",
                        "dorpel": "'7156879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-C67-GREY",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'7212542'",
                        "dorpel": "'7256879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Glaslat BU-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'7312542'",
                        "dorpel": "'7356879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-D57-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'7412542'",
                        "dorpel": "'7456879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "\tDK-30 m¹BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'7512542'",
                        "dorpel": "'7556879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "\tDK-30 m¹BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'7612542'",
                        "dorpel": "'7656879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-C67-GREY",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'7712542'",
                        "dorpel": "'7756879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-C57-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'7812542'",
                        "dorpel": "'7856879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-C67-GREY",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'7912542'",
                        "dorpel": "'7956879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "VG-17 m¹BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'8012542'",
                        "dorpel": "'8056879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "VW-H-BH-BU-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'8112542'",
                        "dorpel": "'8156879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-C57-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'8212542'",
                        "dorpel": "'8256879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "VW-H-BH-BU-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'8312542'",
                        "dorpel": "'8356879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-C57-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'8412542'",
                        "dorpel": "'8456879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-D57-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'8512542'",
                        "dorpel": "'8556879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Glaslat BU-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'8612542'",
                        "dorpel": "'8656879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Glaslat BU-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'8712542'",
                        "dorpel": "'8756879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "\tDK-30 m¹BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'8812542'",
                        "dorpel": "'8856879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-D57-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'8912542'",
                        "dorpel": "'8956879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "\tDK-30 m¹BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'9012542'",
                        "dorpel": "'9056879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "VG-17 m¹BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'9112542'",
                        "dorpel": "'9156879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "VG-17 m¹BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'9212542'",
                        "dorpel": "'9256879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-C57-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'9312542'",
                        "dorpel": "'9356879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Glaslat BU-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'9412542'",
                        "dorpel": "'9456879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-D57-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'9512542'",
                        "dorpel": "'9556879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-C67-GREY",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'9612542'",
                        "dorpel": "'9656879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-C57-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'9712542'",
                        "dorpel": "'9756879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "VG-17 m¹BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'9812542'",
                        "dorpel": "'9856879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-C57-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'9912542'",
                        "dorpel": "'9956879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    }
                ]
            },
            {
                code: "",
                title: "Morgen 5 december",
                capaciteit: 120,
                description: "Zagen van dorpelprofielen, beglazingsprofielen, glaslatten, aanslagprofielen",
                opdrachten:
                    [
                        {
                            "code": "",
                            "title": "BI-C57-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'012542'",
                            "dorpel": "'056879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "VW-H-BH-BU-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'112542'",
                            "dorpel": "'156879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "\tDK-30 m¹BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'212542'",
                            "dorpel": "'256879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "VW-H-BH-BU-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'312542'",
                            "dorpel": "'356879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-C57-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'412542'",
                            "dorpel": "'456879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "VG-17 m¹BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'512542'",
                            "dorpel": "'556879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "VG-17 m¹BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'612542'",
                            "dorpel": "'656879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "Glaslat BU-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'712542'",
                            "dorpel": "'756879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-C67-GREY",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'812542'",
                            "dorpel": "'856879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "VW-H-BH-BU-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'912542'",
                            "dorpel": "'956879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "VG-17 m¹BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'1012542'",
                            "dorpel": "'1056879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "ZL-A-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'1112542'",
                            "dorpel": "'1156879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-C57-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'1212542'",
                            "dorpel": "'1256879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "VG-17 m¹BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'1312542'",
                            "dorpel": "'1356879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "\tDK-30 m¹BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'1412542'",
                            "dorpel": "'1456879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-C57-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'1512542'",
                            "dorpel": "'1556879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-C67-GREY",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'1612542'",
                            "dorpel": "'1656879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "VW-H-BH-BU-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'1712542'",
                            "dorpel": "'1756879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-D57-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'1812542'",
                            "dorpel": "'1856879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "Glaslat BU-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'1912542'",
                            "dorpel": "'1956879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "\tDK-30 m¹BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'2012542'",
                            "dorpel": "'2056879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-C67-GREY",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'2112542'",
                            "dorpel": "'2156879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-C57-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'2212542'",
                            "dorpel": "'2256879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "\tDK-30 m¹BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'2312542'",
                            "dorpel": "'2356879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "Glaslat BU-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'2412542'",
                            "dorpel": "'2456879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "VG-17 m¹BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'2512542'",
                            "dorpel": "'2556879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-C57-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'2612542'",
                            "dorpel": "'2656879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "ZL-A-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'2712542'",
                            "dorpel": "'2756879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "ZL-A-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'2812542'",
                            "dorpel": "'2856879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "Glaslat BU-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'2912542'",
                            "dorpel": "'2956879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "\tDK-30 m¹BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'3012542'",
                            "dorpel": "'3056879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-D57-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'3112542'",
                            "dorpel": "'3156879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "VG-17 m¹BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'3212542'",
                            "dorpel": "'3256879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-C57-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'3312542'",
                            "dorpel": "'3356879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "ZL-A-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'3412542'",
                            "dorpel": "'3456879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "Glaslat BU-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'3512542'",
                            "dorpel": "'3556879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-C57-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'3612542'",
                            "dorpel": "'3656879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "VG-17 m¹BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'3712542'",
                            "dorpel": "'3756879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-D57-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'3812542'",
                            "dorpel": "'3856879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "\tDK-30 m¹BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'3912542'",
                            "dorpel": "'3956879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "VW-H-BH-BU-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'4012542'",
                            "dorpel": "'4056879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "Glaslat BU-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'4112542'",
                            "dorpel": "'4156879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-C67-GREY",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'4212542'",
                            "dorpel": "'4256879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "\tDK-30 m¹BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'4312542'",
                            "dorpel": "'4356879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-C57-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'4412542'",
                            "dorpel": "'4456879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "Glaslat BU-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'4512542'",
                            "dorpel": "'4556879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-D57-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'4612542'",
                            "dorpel": "'4656879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "VG-17 m¹BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'4712542'",
                            "dorpel": "'4756879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "\tDK-30 m¹BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'4812542'",
                            "dorpel": "'4856879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-D57-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'4912542'",
                            "dorpel": "'4956879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "VW-H-BH-BU-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'5012542'",
                            "dorpel": "'5056879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "\tDK-30 m¹BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'5112542'",
                            "dorpel": "'5156879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-C57-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'5212542'",
                            "dorpel": "'5256879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "Glaslat BU-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'5312542'",
                            "dorpel": "'5356879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "VW-H-BH-BU-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'5412542'",
                            "dorpel": "'5456879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "Glaslat BU-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'5512542'",
                            "dorpel": "'5556879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "VG-17 m¹BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'5612542'",
                            "dorpel": "'5656879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-C67-GREY",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'5712542'",
                            "dorpel": "'5756879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "ZL-A-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'5812542'",
                            "dorpel": "'5856879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-D57-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'5912542'",
                            "dorpel": "'5956879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "VG-17 m¹BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'6012542'",
                            "dorpel": "'6056879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-C57-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'6112542'",
                            "dorpel": "'6156879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-D57-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'6212542'",
                            "dorpel": "'6256879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "ZL-A-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'6312542'",
                            "dorpel": "'6356879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-C57-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'6412542'",
                            "dorpel": "'6456879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "ZL-A-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'6512542'",
                            "dorpel": "'6556879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-C67-GREY",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'6612542'",
                            "dorpel": "'6656879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-C57-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'6712542'",
                            "dorpel": "'6756879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "VW-H-BH-BU-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'6812542'",
                            "dorpel": "'6856879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "Glaslat BU-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'6912542'",
                            "dorpel": "'6956879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-D57-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'7012542'",
                            "dorpel": "'7056879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "ZL-A-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'7112542'",
                            "dorpel": "'7156879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "VG-17 m¹BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'7212542'",
                            "dorpel": "'7256879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-C67-GREY",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'7312542'",
                            "dorpel": "'7356879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-D57-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'7412542'",
                            "dorpel": "'7456879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-C57-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'7512542'",
                            "dorpel": "'7556879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-C57-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'7612542'",
                            "dorpel": "'7656879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-C67-GREY",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'7712542'",
                            "dorpel": "'7756879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "Glaslat BU-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'7812542'",
                            "dorpel": "'7856879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "VW-H-BH-BU-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'7912542'",
                            "dorpel": "'7956879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "VW-H-BH-BU-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'8012542'",
                            "dorpel": "'8056879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "ZL-A-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'8112542'",
                            "dorpel": "'8156879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "VW-H-BH-BU-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'8212542'",
                            "dorpel": "'8256879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-D57-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'8312542'",
                            "dorpel": "'8356879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "VG-17 m¹BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'8412542'",
                            "dorpel": "'8456879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "Glaslat BU-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'8512542'",
                            "dorpel": "'8556879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "ZL-A-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'8612542'",
                            "dorpel": "'8656879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "ZL-A-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'8712542'",
                            "dorpel": "'8756879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "\tDK-30 m¹BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'8812542'",
                            "dorpel": "'8856879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-C57-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'8912542'",
                            "dorpel": "'8956879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-C67-GREY",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'9012542'",
                            "dorpel": "'9056879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "\tDK-30 m¹BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'9112542'",
                            "dorpel": "'9156879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "VW-H-BH-BU-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'9212542'",
                            "dorpel": "'9256879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "Glaslat BU-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'9312542'",
                            "dorpel": "'9356879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-C57-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'9412542'",
                            "dorpel": "'9456879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-C67-GREY",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'9512542'",
                            "dorpel": "'9556879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-D57-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'9612542'",
                            "dorpel": "'9656879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-D57-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'9712542'",
                            "dorpel": "'9756879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-D57-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'9812542'",
                            "dorpel": "'9856879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "\tDK-30 m¹BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'9912542'",
                            "dorpel": "'9956879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-C67-GREY",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'10012542'",
                            "dorpel": "'10056879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-C67-GREY",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'10112542'",
                            "dorpel": "'10156879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "VW-H-BH-BU-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'10212542'",
                            "dorpel": "'10256879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "VW-H-BH-BU-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'10312542'",
                            "dorpel": "'10356879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "\tDK-30 m¹BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'10412542'",
                            "dorpel": "'10456879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "\tDK-30 m¹BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'10512542'",
                            "dorpel": "'10556879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "ZL-A-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'10612542'",
                            "dorpel": "'10656879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "\tDK-30 m¹BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'10712542'",
                            "dorpel": "'10756879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "Glaslat BU-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'10812542'",
                            "dorpel": "'10856879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "Glaslat BU-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'10912542'",
                            "dorpel": "'10956879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-D57-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'11012542'",
                            "dorpel": "'11056879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-C57-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'11112542'",
                            "dorpel": "'11156879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "VW-H-BH-BU-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'11212542'",
                            "dorpel": "'11256879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-C67-GREY",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'11312542'",
                            "dorpel": "'11356879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "\tDK-30 m¹BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'11412542'",
                            "dorpel": "'11456879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-C67-GREY",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'11512542'",
                            "dorpel": "'11556879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-C67-GREY",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'11612542'",
                            "dorpel": "'11656879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "\tDK-30 m¹BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'11712542'",
                            "dorpel": "'11756879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-C57-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'11812542'",
                            "dorpel": "'11856879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        },
                        {
                            "code": "",
                            "title": "BI-C57-BLACK",
                            "type": "Dorpel zagen",
                            "enabled": true,
                            "status": "TODO",
                            "description": "",
                            "order": "'11912542'",
                            "dorpel": "'11956879'",
                            "instructies": [
                                {
                                    "lengte": "2412mm"
                                }
                            ]
                        }
                    ],
            },
            {
                code: "",
                title: "Woensdag 7 december",
                capaciteit: 20,
                description: "Zagen van dorpelprofielen, beglazingsprofielen, glaslatten, aanslagprofielen",
                opdrachten: [
                    {
                        "code": "",
                        "title": "Glaslat BU-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'012542'",
                        "dorpel": "'056879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "VW-H-BH-BU-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'112542'",
                        "dorpel": "'156879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "VG-17 m¹BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'212542'",
                        "dorpel": "'256879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-C57-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'312542'",
                        "dorpel": "'356879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "ZL-A-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'412542'",
                        "dorpel": "'456879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "\tDK-30 m¹BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'512542'",
                        "dorpel": "'556879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-C57-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'612542'",
                        "dorpel": "'656879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Glaslat BU-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'712542'",
                        "dorpel": "'756879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "VG-17 m¹BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'812542'",
                        "dorpel": "'856879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "BI-C67-GREY",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'912542'",
                        "dorpel": "'956879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "ZL-A-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1012542'",
                        "dorpel": "'1056879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Glaslat BU-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1112542'",
                        "dorpel": "'1156879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "VG-17 m¹BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1212542'",
                        "dorpel": "'1256879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "ZL-A-BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1312542'",
                        "dorpel": "'1356879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "VG-17 m¹BLACK",
                        "type": "Dorpel zagen",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1412542'",
                        "dorpel": "'1456879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    }
                ],
            }
        ],
        status: 'ACTIEF'
    },
    {
        code: "",
        title: "BMH WM50 Neuten",
        type: "",
        description: "Bewerken van dorpelprofielen en neuten",
        handelingen: [

            {
                code: "",
                title: "Beglazingsprofiel zagen",
                description: "",
                   instructies: [
                        {
                            title: "Product",
                            type: "Product van type dorpelprofiel"
                        },
                        {
                            title: "lengte",
                            type: "Lengte in mm"
                        }
                    ]

            }
            ,
            {
                code: "",
                title: "Aanslagprofiel zagen",
                description: "",
                   instructies: [
                        {
                            title: "Product",
                            type: "Product van type dorpelprofiel"
                        },
                        {
                            title: "lengte",
                            type: "Lengte in mm"
                        }
                    ]

            }
        ],
        medewerkers: [],
        slots: [
            {
                code: "",
                title: "Morgen 17 december",
                capaciteit: 9999,
                description: "Zagen van dorpelprofielen, beglazingsprofielen, glaslatten, aanslagprofielen",
                opdrachten: [
                    {
                        "code": "",
                        "title": "Neut 012542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'012542'",
                        "dorpel": "'056879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 112542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'112542'",
                        "dorpel": "'156879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 212542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'212542'",
                        "dorpel": "'256879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 312542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'312542'",
                        "dorpel": "'356879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 412542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'412542'",
                        "dorpel": "'456879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 512542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'512542'",
                        "dorpel": "'556879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 612542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'612542'",
                        "dorpel": "'656879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 712542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'712542'",
                        "dorpel": "'756879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 812542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'812542'",
                        "dorpel": "'856879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 912542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'912542'",
                        "dorpel": "'956879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 1012542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1012542'",
                        "dorpel": "'1056879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 1112542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1112542'",
                        "dorpel": "'1156879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 1212542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1212542'",
                        "dorpel": "'1256879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 1312542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1312542'",
                        "dorpel": "'1356879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 1412542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1412542'",
                        "dorpel": "'1456879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 1512542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1512542'",
                        "dorpel": "'1556879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 1612542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1612542'",
                        "dorpel": "'1656879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 1712542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1712542'",
                        "dorpel": "'1756879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 1812542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1812542'",
                        "dorpel": "'1856879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 1912542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1912542'",
                        "dorpel": "'1956879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 2012542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2012542'",
                        "dorpel": "'2056879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 2112542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2112542'",
                        "dorpel": "'2156879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 2212542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2212542'",
                        "dorpel": "'2256879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 2312542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2312542'",
                        "dorpel": "'2356879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 2412542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2412542'",
                        "dorpel": "'2456879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 2512542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2512542'",
                        "dorpel": "'2556879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 2612542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2612542'",
                        "dorpel": "'2656879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 2712542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2712542'",
                        "dorpel": "'2756879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 2812542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2812542'",
                        "dorpel": "'2856879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 2912542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2912542'",
                        "dorpel": "'2956879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 3012542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3012542'",
                        "dorpel": "'3056879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 3112542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3112542'",
                        "dorpel": "'3156879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 3212542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3212542'",
                        "dorpel": "'3256879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 3312542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3312542'",
                        "dorpel": "'3356879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 3412542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3412542'",
                        "dorpel": "'3456879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 3512542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3512542'",
                        "dorpel": "'3556879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 3612542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3612542'",
                        "dorpel": "'3656879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 3712542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3712542'",
                        "dorpel": "'3756879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 3812542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3812542'",
                        "dorpel": "'3856879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 3912542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3912542'",
                        "dorpel": "'3956879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 4012542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'4012542'",
                        "dorpel": "'4056879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 4112542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'4112542'",
                        "dorpel": "'4156879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 4212542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'4212542'",
                        "dorpel": "'4256879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 4312542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'4312542'",
                        "dorpel": "'4356879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 4412542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'4412542'",
                        "dorpel": "'4456879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 4512542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'4512542'",
                        "dorpel": "'4556879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 4612542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'4612542'",
                        "dorpel": "'4656879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 4712542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'4712542'",
                        "dorpel": "'4756879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 4812542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'4812542'",
                        "dorpel": "'4856879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 4912542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'4912542'",
                        "dorpel": "'4956879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    }
                ]
            },
            {
                code: "",
                title: "Volgende week vrijdag 26 december",
                capaciteit: 9999,
                description: "Zagen van dorpelprofielen, beglazingsprofielen, glaslatten, aanslagprofielen",
                opdrachten: [
                    {
                        "code": "",
                        "title": "Neut 012542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'012542'",
                        "dorpel": "'056879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 112542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'112542'",
                        "dorpel": "'156879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 212542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'212542'",
                        "dorpel": "'256879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 312542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'312542'",
                        "dorpel": "'356879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 412542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'412542'",
                        "dorpel": "'456879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 512542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'512542'",
                        "dorpel": "'556879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 612542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'612542'",
                        "dorpel": "'656879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 712542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'712542'",
                        "dorpel": "'756879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 812542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'812542'",
                        "dorpel": "'856879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 912542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'912542'",
                        "dorpel": "'956879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 1012542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1012542'",
                        "dorpel": "'1056879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 1112542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1112542'",
                        "dorpel": "'1156879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 1212542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1212542'",
                        "dorpel": "'1256879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 1312542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1312542'",
                        "dorpel": "'1356879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 1412542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1412542'",
                        "dorpel": "'1456879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 1512542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1512542'",
                        "dorpel": "'1556879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 1612542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1612542'",
                        "dorpel": "'1656879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 1712542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1712542'",
                        "dorpel": "'1756879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 1812542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1812542'",
                        "dorpel": "'1856879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 1912542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1912542'",
                        "dorpel": "'1956879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 2012542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2012542'",
                        "dorpel": "'2056879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 2112542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2112542'",
                        "dorpel": "'2156879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 2212542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2212542'",
                        "dorpel": "'2256879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 2312542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2312542'",
                        "dorpel": "'2356879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 2412542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2412542'",
                        "dorpel": "'2456879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 2512542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2512542'",
                        "dorpel": "'2556879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 2612542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2612542'",
                        "dorpel": "'2656879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 2712542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2712542'",
                        "dorpel": "'2756879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 2812542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2812542'",
                        "dorpel": "'2856879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 2912542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2912542'",
                        "dorpel": "'2956879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 3012542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3012542'",
                        "dorpel": "'3056879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 3112542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3112542'",
                        "dorpel": "'3156879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 3212542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3212542'",
                        "dorpel": "'3256879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 3312542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3312542'",
                        "dorpel": "'3356879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Neut 3412542",
                        "type": "Neut maken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3412542'",
                        "dorpel": "'3456879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    }
                ]
            }
            ],
        status: 'ACTIEF'
    },
    {
        code: "",
        title: "BMH WM50 Dorpels",
        type: "",
        description: "Bewerken van dorpelprofielen en neuten",
        handelingen: [
            {
                code: "",
                title: "Dorpelprofiel zagen",
                description: "",
                instructies: [
                    {
                        title: "Product",
                        type: "Product van type dorpelprofiel"
                    },
                    {
                        title: "lengte",
                        type: "Lengte in mm"
                    }
                ]

            },
            {
                code: "",
                title: "Glaslat zagen",
                description: "",

                instructies: [
                    {
                        title: "Product",
                        type: "Product van type dorpelprofiel"
                    },
                    {
                        title: "lengte",
                        type: "Lengte in mm"
                    }
                ]

            },
            {
                code: "",
                title: "Beglazingsprofiel zagen",
                description: "",
                instructies: [
                    {
                        title: "Product",
                        type: "Product van type dorpelprofiel"
                    },
                    {
                        title: "lengte",
                        type: "Lengte in mm"
                    }
                ]

            }
            ,
            {
                code: "",
                title: "Aanslagprofiel zagen",
                description: "",
                instructies: [
                    {
                        title: "Product",
                        type: "Product van type dorpelprofiel"
                    },
                    {
                        title: "lengte",
                        type: "Lengte in mm"
                    }
                ]

            }
        ],
        medewerkers: [],
        slots: [{
            code: "",
            title: "Vandaag",
            capaciteit: 75,
            description: "Zagen van dorpelprofielen, beglazingsprofielen, glaslatten, aanslagprofielen",
            opdrachten: [

                    {
                        "code": "",
                        "title": "Dorpel 012542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'012542'",
                        "dorpel": "'056879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 112542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'112542'",
                        "dorpel": "'156879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 212542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'212542'",
                        "dorpel": "'256879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 312542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'312542'",
                        "dorpel": "'356879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 412542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'412542'",
                        "dorpel": "'456879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 512542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'512542'",
                        "dorpel": "'556879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 612542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'612542'",
                        "dorpel": "'656879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 712542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'712542'",
                        "dorpel": "'756879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 812542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'812542'",
                        "dorpel": "'856879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 912542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'912542'",
                        "dorpel": "'956879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 1012542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1012542'",
                        "dorpel": "'1056879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 1112542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1112542'",
                        "dorpel": "'1156879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 1212542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1212542'",
                        "dorpel": "'1256879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 1312542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1312542'",
                        "dorpel": "'1356879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 1412542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1412542'",
                        "dorpel": "'1456879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 1512542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1512542'",
                        "dorpel": "'1556879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 1612542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1612542'",
                        "dorpel": "'1656879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 1712542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1712542'",
                        "dorpel": "'1756879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 1812542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1812542'",
                        "dorpel": "'1856879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 1912542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1912542'",
                        "dorpel": "'1956879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 2012542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2012542'",
                        "dorpel": "'2056879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 2112542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2112542'",
                        "dorpel": "'2156879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 2212542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2212542'",
                        "dorpel": "'2256879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 2312542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2312542'",
                        "dorpel": "'2356879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 2412542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2412542'",
                        "dorpel": "'2456879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 2512542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2512542'",
                        "dorpel": "'2556879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 2612542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2612542'",
                        "dorpel": "'2656879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 2712542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2712542'",
                        "dorpel": "'2756879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 2812542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2812542'",
                        "dorpel": "'2856879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 2912542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2912542'",
                        "dorpel": "'2956879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 3012542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3012542'",
                        "dorpel": "'3056879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 3112542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3112542'",
                        "dorpel": "'3156879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 3212542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3212542'",
                        "dorpel": "'3256879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 3312542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3312542'",
                        "dorpel": "'3356879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 3412542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3412542'",
                        "dorpel": "'3456879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 3512542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3512542'",
                        "dorpel": "'3556879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 3612542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3612542'",
                        "dorpel": "'3656879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 3712542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3712542'",
                        "dorpel": "'3756879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 3812542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3812542'",
                        "dorpel": "'3856879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 3912542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3912542'",
                        "dorpel": "'3956879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 4012542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'4012542'",
                        "dorpel": "'4056879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 4112542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'4112542'",
                        "dorpel": "'4156879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 4212542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'4212542'",
                        "dorpel": "'4256879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 4312542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'4312542'",
                        "dorpel": "'4356879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 4412542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'4412542'",
                        "dorpel": "'4456879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 4512542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'4512542'",
                        "dorpel": "'4556879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 4612542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'4612542'",
                        "dorpel": "'4656879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 4712542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'4712542'",
                        "dorpel": "'4756879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 4812542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'4812542'",
                        "dorpel": "'4856879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 4912542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'4912542'",
                        "dorpel": "'4956879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 5012542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'5012542'",
                        "dorpel": "'5056879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 5112542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'5112542'",
                        "dorpel": "'5156879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 5212542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'5212542'",
                        "dorpel": "'5256879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 5312542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'5312542'",
                        "dorpel": "'5356879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 5412542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'5412542'",
                        "dorpel": "'5456879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 5512542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'5512542'",
                        "dorpel": "'5556879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 5612542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'5612542'",
                        "dorpel": "'5656879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 5712542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'5712542'",
                        "dorpel": "'5756879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 5812542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'5812542'",
                        "dorpel": "'5856879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 5912542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'5912542'",
                        "dorpel": "'5956879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 6012542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'6012542'",
                        "dorpel": "'6056879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 6112542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'6112542'",
                        "dorpel": "'6156879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 6212542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'6212542'",
                        "dorpel": "'6256879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 6312542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'6312542'",
                        "dorpel": "'6356879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 6412542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'6412542'",
                        "dorpel": "'6456879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 6512542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'6512542'",
                        "dorpel": "'6556879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 6612542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'6612542'",
                        "dorpel": "'6656879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 6712542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'6712542'",
                        "dorpel": "'6756879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 6812542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'6812542'",
                        "dorpel": "'6856879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 6912542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'6912542'",
                        "dorpel": "'6956879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 7012542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'7012542'",
                        "dorpel": "'7056879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 7112542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'7112542'",
                        "dorpel": "'7156879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 7212542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'7212542'",
                        "dorpel": "'7256879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 7312542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'7312542'",
                        "dorpel": "'7356879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 7412542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'7412542'",
                        "dorpel": "'7456879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    }

            ],
        },
            {
                code: "",
                title: "Morgen 17 december",
                capaciteit: 75,
                description: "Zagen van dorpelprofielen, beglazingsprofielen, glaslatten, aanslagprofielen",
                opdrachten: [
                    {
                        "code": "",
                        "title": "Dorpel 012542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'012542'",
                        "dorpel": "'056879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 112542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'112542'",
                        "dorpel": "'156879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 212542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'212542'",
                        "dorpel": "'256879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 312542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'312542'",
                        "dorpel": "'356879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 412542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'412542'",
                        "dorpel": "'456879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 512542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'512542'",
                        "dorpel": "'556879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 612542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'612542'",
                        "dorpel": "'656879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 712542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'712542'",
                        "dorpel": "'756879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 812542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'812542'",
                        "dorpel": "'856879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 912542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'912542'",
                        "dorpel": "'956879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 1012542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1012542'",
                        "dorpel": "'1056879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 1112542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1112542'",
                        "dorpel": "'1156879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 1212542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1212542'",
                        "dorpel": "'1256879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 1312542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1312542'",
                        "dorpel": "'1356879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 1412542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1412542'",
                        "dorpel": "'1456879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 1512542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1512542'",
                        "dorpel": "'1556879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 1612542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1612542'",
                        "dorpel": "'1656879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 1712542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1712542'",
                        "dorpel": "'1756879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 1812542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1812542'",
                        "dorpel": "'1856879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 1912542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'1912542'",
                        "dorpel": "'1956879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 2012542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2012542'",
                        "dorpel": "'2056879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 2112542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2112542'",
                        "dorpel": "'2156879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 2212542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2212542'",
                        "dorpel": "'2256879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 2312542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2312542'",
                        "dorpel": "'2356879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 2412542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2412542'",
                        "dorpel": "'2456879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 2512542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2512542'",
                        "dorpel": "'2556879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 2612542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2612542'",
                        "dorpel": "'2656879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 2712542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2712542'",
                        "dorpel": "'2756879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 2812542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2812542'",
                        "dorpel": "'2856879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 2912542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'2912542'",
                        "dorpel": "'2956879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 3012542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3012542'",
                        "dorpel": "'3056879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 3112542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3112542'",
                        "dorpel": "'3156879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 3212542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3212542'",
                        "dorpel": "'3256879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 3312542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3312542'",
                        "dorpel": "'3356879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 3412542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3412542'",
                        "dorpel": "'3456879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 3512542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3512542'",
                        "dorpel": "'3556879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 3612542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3612542'",
                        "dorpel": "'3656879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 3712542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3712542'",
                        "dorpel": "'3756879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 3812542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3812542'",
                        "dorpel": "'3856879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 3912542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'3912542'",
                        "dorpel": "'3956879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 4012542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'4012542'",
                        "dorpel": "'4056879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 4112542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'4112542'",
                        "dorpel": "'4156879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 4212542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'4212542'",
                        "dorpel": "'4256879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 4312542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'4312542'",
                        "dorpel": "'4356879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 4412542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'4412542'",
                        "dorpel": "'4456879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 4512542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'4512542'",
                        "dorpel": "'4556879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 4612542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'4612542'",
                        "dorpel": "'4656879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 4712542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'4712542'",
                        "dorpel": "'4756879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 4812542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'4812542'",
                        "dorpel": "'4856879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 4912542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'4912542'",
                        "dorpel": "'4956879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 5012542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'5012542'",
                        "dorpel": "'5056879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 5112542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'5112542'",
                        "dorpel": "'5156879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 5212542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'5212542'",
                        "dorpel": "'5256879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 5312542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'5312542'",
                        "dorpel": "'5356879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 5412542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'5412542'",
                        "dorpel": "'5456879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 5512542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'5512542'",
                        "dorpel": "'5556879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 5612542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'5612542'",
                        "dorpel": "'5656879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 5712542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'5712542'",
                        "dorpel": "'5756879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 5812542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'5812542'",
                        "dorpel": "'5856879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 5912542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'5912542'",
                        "dorpel": "'5956879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 6012542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'6012542'",
                        "dorpel": "'6056879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 6112542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'6112542'",
                        "dorpel": "'6156879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 6212542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'6212542'",
                        "dorpel": "'6256879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 6312542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'6312542'",
                        "dorpel": "'6356879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 6412542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'6412542'",
                        "dorpel": "'6456879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 6512542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'6512542'",
                        "dorpel": "'6556879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    },
                    {
                        "code": "",
                        "title": "Dorpel 6612542",
                        "type": "Dorpel bewerken",
                        "enabled": true,
                        "status": "TODO",
                        "description": "",
                        "order": "'6612542'",
                        "dorpel": "'6656879'",
                        "instructies": [
                            {
                                "lengte": "2412mm"
                            }
                        ]
                    }
                ],
            }],
        status: 'ACTIEF'
    },

    {
        code: "",
        title: "Neutmontage",
        type: "",
        description: "Handmatig monteren van neuten op dorpelprofielen",
        handelingen: [
            {
                code: "",
                title: "Dorpelprofiel zagen",
                description: "",
                   instructies: [
                        {
                            title: "Product",
                            type: "Product van type dorpelprofiel"
                        },
                        {
                            title: "lengte",
                            type: "Lengte in mm"
                        }
                    ]

            },
            {
                code: "",
                title: "Glaslat zagen",
                description: "",
                   instructies: [
                        {
                            title: "Product",
                            type: "Product van type dorpelprofiel"
                        },
                        {
                            title: "lengte",
                            type: "Lengte in mm"
                        }
                    ]

            },
            {
                code: "",
                title: "Beglazingsprofiel zagen",
                description: "",
                   instructies: [
                        {
                            title: "Product",
                            type: "Product van type dorpelprofiel"
                        },
                        {
                            title: "lengte",
                            type: "Lengte in mm"
                        }
                    ]

            }
            ,
            {
                code: "",
                title: "Aanslagprofiel zagen",
                description: "",
                   instructies: [
                        {
                            title: "Product",
                            type: "Product van type dorpelprofiel"
                        },
                        {
                            title: "lengte",
                            type: "Lengte in mm"
                        }
                    ]

            }
        ],
        medewerkers: [],
        slots: [{
            code: "",
            title: "Vandaag",
            description: "Zagen van dorpelprofielen, beglazingsprofielen, glaslatten, aanslagprofielen",
            opdrachten: [{
                code: "",
                title: "Dorpelprofiel BI_C57",
                type: "",
                status: "TODO",
                enabled: true,
                description: "",
                order: "45626",
                dorpel: "34563",
                instructies: [{
                    lengte: "2341",
                }]
            }],
        }],
        status: 'ACTIEF'
    }
    ,
    {
        code: "",
        title: "Afmontage",
        type: "",
        description: "Versimpelde afmontage stap",
        handelingen: [
            {
                code: "",
                title: "Dorpelprofiel zagen",
                description: "",
                   instructies: [
                        {
                            title: "Product",
                            type: "Product van type dorpelprofiel"
                        },
                        {
                            title: "lengte",
                            type: "Lengte in mm"
                        }
                    ]

            },
            {
                code: "",
                title: "Glaslat zagen",
                description: "",
                   instructies: [
                        {
                            title: "Product",
                            type: "Product van type dorpelprofiel"
                        },
                        {
                            title: "lengte",
                            type: "Lengte in mm"
                        }
                    ]

            },
            {
                code: "",
                title: "Beglazingsprofiel zagen",
                description: "",
                   instructies: [
                        {
                            title: "Product",
                            type: "Product van type dorpelprofiel"
                        },
                        {
                            title: "lengte",
                            type: "Lengte in mm"
                        }
                    ]
            }
            ,
            {
                code: "",
                title: "Aanslagprofiel zagen",
                description: "",
                   instructies: [
                        {
                            title: "Product",
                            type: "Product van type dorpelprofiel"
                        },
                        {
                            title: "lengte",
                            type: "Lengte in mm"
                        }
                    ]
            }
        ],
        medewerkers: [],
        slots: [{
            code: "",
            title: "Vandaag",
            description: "Zagen van dorpelprofielen, beglazingsprofielen, glaslatten, aanslagprofielen",
            opdrachten: [{
                code: "",
                title: "Dorpelprofiel BI_C57",
                type: "",
                status: "TODO",
                enabled: true,
                description: "",
                order: "45626",
                dorpel: "34563",
                instructies: [{
                    lengte: "2341",
                }]
            }],
        }],
        status: 'ACTIEF'
    }
    ,
    {
        code: "",
        title: "Verpakken",
        type: "",
        description: "Losse onderdelen bij dorpel voegen.",
        handelingen: [
            {
                code: "",
                title: "Dorpelprofiel zagen",
                description: "",
                   instructies: [
                        {
                            title: "Product",
                            type: "Product van type dorpelprofiel"
                        },
                        {
                            title: "lengte",
                            type: "Lengte in mm"
                        }
                    ]
            },
            {
                code: "",
                title: "Glaslat zagen",
                description: "",
                   instructies: [
                        {
                            title: "Product",
                            type: "Product van type dorpelprofiel"
                        },
                        {
                            title: "lengte",
                            type: "Lengte in mm"
                        }
                    ]
            },
            {
                code: "",
                title: "Beglazingsprofiel zagen",
                description: "",
                   instructies: [
                        {
                            title: "Product",
                            type: "Product van type dorpelprofiel"
                        },
                        {
                            title: "lengte",
                            type: "Lengte in mm"
                        }
                    ]
            }
            ,
            {
                code: "",
                title: "Aanslagprofiel zagen",
                description: "",
                   instructies: [
                        {
                            title: "Product",
                            type: "Product van type dorpelprofiel"
                        },
                        {
                            title: "lengte",
                            type: "Lengte in mm"
                        }
                    ]
            }
        ],
        medewerkers: [],
        slots: [{
            code: "",
            title: "Vandaag",
            description: "Zagen van dorpelprofielen, beglazingsprofielen, glaslatten, aanslagprofielen",
            opdrachten: [{
                code: "",
                title: "Dorpelprofiel BI_C57",
                type: "",
                status: "TODO",
                enabled: true,
                description: "",
                order: "45626",
                dorpel: "34563",
                instructies: [{
                    lengte: "2341",
                }]
            }],
        }],
        status: 'ACTIEF'
    }
]
export const handelingenList: Array<ITask> = [
    {
        code: '',
        title: 'Dorpelprofiel zagen',
        description: '',
                   instructies: [
                        {
                            title: "Product",
                            type: "Product van type dorpelprofiel"
                        },
                        {
                            title: "lengte",
                            type: "Lengte in mm"
                        }
                    ]
    },
    {
        code: '',
        title: 'Glaslat zagen',
        description: '',
                   instructies: [
                        {
                            title: "Product",
                            type: "Product van type dorpelprofiel"
                        },
                        {
                            title: "lengte",
                            type: "Lengte in mm"
                        }
                    ]
    },
    {
        code: '',
        title: 'Beglazingsprofiel zagen',
        description: '',
                   instructies: [
                        {
                            title: "Product",
                            type: "Product van type dorpelprofiel"
                        },
                        {
                            title: "lengte",
                            type: "Lengte in mm"
                        }
                    ]
    },
    {
        code: '',
        title: 'Aanslagprofiel zagen',
        description: '',
                   instructies: [
                        {
                            title: "Product",
                            type: "Product van type dorpelprofiel"
                        },
                        {
                            title: "lengte",
                            type: "Lengte in mm"
                        }
                    ]
    },
    {
        code: '',
        title: 'Dorpelprofiel bewerken',
        description: 'Borengaten, koppenfrezen etc',
                   instructies: [
                        {
                            title: "Product",
                            type: "Product van type dorpelprofiel"
                        },
                        {
                            title: "lengte",
                            type: "Lengte in mm"
                        }
                    ]
    },
    {
        code: '',
        title: 'Neuten maken',
        description: '',
                   instructies: [
                        {
                            title: "Product",
                            type: "Product van type dorpelprofiel"
                        },
                        {
                            title: "lengte",
                            type: "Lengte in mm"
                        }
                    ]
    },
    {
        code: '',
        title: 'Neut maken',
        description: '',
                   instructies: [
                        {
                            title: "Product",
                            type: "Product van type dorpelprofiel"
                        },
                        {
                            title: "lengte",
                            type: "Lengte in mm"
                        }
                    ]
    },
    {
        code: '',
        title: 'Neuten montage',
        level: 3,
        description: 'Monteren van neuten op dorpel',
                   instructies: [
                        {
                            title: "Product",
                            type: "Product van type dorpelprofiel"
                        },
                        {
                            title: "lengte",
                            type: "Lengte in mm"
                        }
                    ]
    },
    {
        code: '',
        title: 'Beglazingsprofielen plaatsen',
        description: 'Monteren van beglazingsprofielen op dorpel',
                   instructies: [
                        {
                            title: "Product",
                            type: "Product van type dorpelprofiel"
                        },
                        {
                            title: "lengte",
                            type: "Lengte in mm"
                        }
                    ]
    },
]


export const productionItemTypeList: Array<IProductionItemType> = [
    {
        code: 'standaard',
        title: 'Standaard dorpel',
        description: '',
        tasks: new Map()
            .set(1,
                {
                    title: 'Dorpelprofiel zagen',
                    level: 0,
                    description: 'Dorpelprofiel op lengte zagen',
                    dependencies: [],
                    instructies: [
                        {
                            title: "Product",
                            type: "Product van type dorpelprofiel"
                        },
                        {
                            title: "lengte",
                            type: "Lengte in mm"
                        }
                    ]
                })
            .set(2,
                {
                    title: 'Neut maken',
                    level: 0,
                    dependencies: [],
                    instructies: new Map()
                        .set('product', "Product van type dorpelprofiel")
                        .set('lengte', "Lengte in mm")
                }
            )
            .set(3,
                {
                    title: 'Neuten monteren',
                    level: 1,
                    dependencies: [1, 2],
                    instructies: new Map()
                        .set('product', "Product van type dorpelprofiel")
                        .set('lengte', "Lengte in mm")
                }
            )
            .set(4,
                {
                    title: 'Afmontage',
                    level: 2,
                    dependencies: [3],
                    instructies: new Map()
                        .set('product', "Product van type dorpelprofiel")
                        .set('lengte', "Lengte in mm")
                }
            )
            .set(5,
                {
                    title: 'Verpakken',
                    level: 3,
                    dependencies: [4],
                    instructies: new Map()
                        .set('product', "Product van type dorpelprofiel")
                        .set('lengte', "Lengte in mm")
                }
            )
    },
    {
        code: '',
        title: 'Hefschuifdorpel',
        description: '',
        tasks: new Map()
            .set(1,
                {
                    code: '',
                    title: 'Dorpelprofiel zagen',
                    description: 'Dorpelprofiel op lengte zagen',
                    instructies: new Map()
                        .set('product', "Product van type dorpelprofiel")
                        .set('lengte', "Lengte in mm")
                })
            .set(2,
                {
                    code: 2,
                    title: 'Neut maken',
                    dependencies: [],
                    instructies: new Map()
                        .set('product', "Product van type dorpelprofiel")
                        .set('lengte', "Lengte in mm")
                }
            )
            .set(3,
                {
                    code: 3,
                    title: 'Neuten monteren',
                    dependencies: ['Alle opdrachten van type Dorpelprofiel zagen gereed', 'Alle opdrachten van type Neut maken gereed'],
                    instructies: new Map()
                        .set('product', "Product van type dorpelprofiel")
                        .set('lengte', "Lengte in mm")
                }
            )
    },
    {
        code: '',
        title: 'Losse neut',
        description: '',
        tasks: new Map()
            .set(2,
                {
                    code: 2,
                    title: 'Neut maken',
                    dependencies: [],
                    instructies: new Map()
                        .set('product', "Product van type dorpelprofiel")
                        .set('lengte', "Lengte in mm")
                }
            )
    },
    {
        code: '',
        title: 'Voorraad product',
        description: '',
        tasks: new Map()
            .set(1,
                {
                    code: '',
                    title: 'Voorraad picken',
                    description: 'Items uit magazijn halen',
                })
    }
]


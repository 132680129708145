import {useQueryClient} from '@tanstack/react-query';
import {toast} from 'react-hot-toast';
import ApiClient from '../../../modules/api-client/ApiClient';
import {useEkosietMutation} from '../../hooks/useEkosietMutation';
import {NotFound} from '../../../modules/api-client/Responses';

export const useDeleteImportSluitpotMapping = () => {
	const queryClient = useQueryClient();

	return useEkosietMutation<void, NotFound, string>(
		(importSluitpotMappingId) => {
			return ApiClient.Acl.ImportMapping.aclImportMappingSluitpotIdDelete(importSluitpotMappingId).then((response: any) => response.data);
		},
		{
			onSuccess: (data, variables) => {
				queryClient.invalidateQueries(['aclImportMappingSluitpotCustomerCustomerIdGet']);
				queryClient.invalidateQueries(['aclImportMappingProfileIdGet', variables]);

				toast.success(`Sluitpot mapping successvol verwijderd`);
			},
			onError: () => {
				toast.error('Sluitpot mapping verwijderen mislukt');
			},
		}
	);
};

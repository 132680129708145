import {Button, Modal} from 'react-bootstrap';
import {CompartmentEditor, CompartmentEditorOptions} from './CompartmentEditor';
import React, {FC} from 'react';
import SpinnerButton from '../SpinnerButton';
import {modalsRoot} from '../../../global-helpers';
import clsx from "clsx";

type CompartmentModalProps = {
    show: boolean;
    title: string | null | undefined;
    handleClose: () => void;
    editorOptions: CompartmentEditorOptions;
};

export const CompartmentModal: FC<CompartmentModalProps> = (props) => {
    return (
        <Modal
            container={modalsRoot}
            tabIndex={-1}
            aria-hidden="true"
            autoFocus={true}
            className="configurator-compartment-modal"
            dialogClassName={'modal-dialog-centered'}
            contentClassName={'shadow-lg'}
            show={props.show}
            size="lg"
            onHide={() => props.handleClose()}
            animation={false}
            backdrop={'static'}
        >
            <CompartmentModalContent {...props} />
        </Modal>
    );
};

const CompartmentModalContent: FC<CompartmentModalProps> = (props) => {
    return (
        <CompartmentEditor {...props.editorOptions} >
            {({
                  content,
                  isSubmitting,
                  isInitializing,
                  canSetDefaultCompartmentProfile,
                  setDefaultProfile,
                  clearDefaultProfile
              }) => {
                return (
                    <>
                        <Modal.Header closeButton>
                            <Modal.Title>{props.title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="flex-fill">{content}</Modal.Body>
                        <Modal.Footer className="d-flex justify-content-between">
                            <div>
                                <div className="dropup">
                                    <button type="button" className="btn btn-link py-0"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false">Standaard profiel
                                    </button>
                                    <ul className="dropdown-menu" style={{minWidth: '100%'}}>
                                        <li className={clsx('dropdown-item d-flex align-items-center', {disabled: !canSetDefaultCompartmentProfile})}
                                            onClick={() => setDefaultProfile()}>
                                            <span className="fs-5">Profiel als standaard instellen</span>
                                        </li>
                                        <li className={clsx('dropdown-item d-flex align-items-center', {disabled: !canSetDefaultCompartmentProfile})}
                                            onClick={() => clearDefaultProfile()}>
                                            <span className="fs-5">Reset standaard profiel</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div>
                                <Button variant="link" className="mx-4" onClick={props.handleClose}
                                        disabled={isInitializing}>
                                    Annuleren
                                </Button>
                                <SpinnerButton type="submit" className="btn btn-primary" spinning={isSubmitting}
                                               disabled={isInitializing}>
                                    Toepassen
                                </SpinnerButton>
                            </div>
                        </Modal.Footer>
                    </>
                );
            }}
        </CompartmentEditor>
    );
};

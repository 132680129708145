import React from 'react';
import {useQuery} from '@tanstack/react-query';
import ApiClient from '../../../../modules/api-client/ApiClient';
import {
    ProductionOperationRepresentation, TimePeriodProductionStatRow,
} from '../../../../modules/api-client/generated';
import Loading from '../../../../shared/components/Loading';
import {Chart} from "react-chartjs-2";
import {useProductionOperations} from "../hooks/use-list-production-operations";

interface EmployeeOperationProps {
    fromDate: string;
    toDate: string;
    showCount: boolean;
    transposed? :boolean;
}

export const EmployeeOperationBarChart: React.FC<EmployeeOperationProps> = ({fromDate, toDate, showCount, transposed}) => {
    const {
        isInitialLoading: isInitialLoadingPeriodProductionStats,
        data: periodProductionStats,
        isError: isErrorPeriodProductionStats
    } = useQuery<TimePeriodProductionStatRow[]>(
        ['timePeriodProductionStatRow', fromDate, toDate],
        () => ApiClient.Pm.Reporting.timePeriodProductionStats(fromDate, toDate).then((res) => res.data)
    );

    const {
        isInitialLoading: isInitialLoadingProductionOperations,
        data: productionOperations,
        isError: isErrorProductionOperations
    } = useProductionOperations();

    const startWithLowerCase = (str : string) => {
        return str.charAt(0).toLowerCase() + str.slice(1);
    }

    const getDurationInHours = (durationString : string) => {
        const duration = durationString.split(':').map(Number);
        return duration[0] + duration[1]*(1/60) + duration[2]*(1/3600);
    }

    const getTitle = () => {
        if(showCount) {
            return 'Aantal uitgevoerde handelingen';
        }
        return 'Totaal aantal uren';
    }

    if (!periodProductionStats || !productionOperations) return <Loading/>
    if (isInitialLoadingPeriodProductionStats || isInitialLoadingProductionOperations) return <Loading/>
    if (isErrorPeriodProductionStats || isErrorProductionOperations) {
        return <>Error</>
    }

    const employees = periodProductionStats.map(row => row.employee);

    const transposedDate = productionOperations.map(po => {
        const transposedRow = {
            title: po.title,
            code: po.code,
            averageDuration: po.averageDuration,
            color: po.color,

            productionByOperation: {} as {[key: string]: number}
        };
        periodProductionStats.forEach(row => {
            transposedRow.productionByOperation[row.employee] = row.productionByOperation[startWithLowerCase(po.code)] || 0;
        });
        return transposedRow;
    });

    const barChartOptions:any = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'right' as const,
            },
            title: {
                display: true,
                text: getTitle(),
            },
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
                type: 'linear',
                ticks: {
                    callback: function(value: number) {
                        const hours = Math.floor(value);
                        const minutes = Math.round((value - hours) * 60);
                        if(showCount) {
                            return value;
                        }
                        return `${hours}h ${minutes}m`;
                    }
                }
            },
        },
    };

    const getColor = (operation : ProductionOperationRepresentation) => {
        if(operation.color) {
            return operation.color;
        }
        return '#70ab2a';
    }

    const barChartData = {
        labels: employees,
        datasets: productionOperations.map((operation) => ({
            label: operation.title,
            data: periodProductionStats.map((stat) => {
                const count = stat.productionByOperation[startWithLowerCase(operation.code)] || 0;
                return showCount ? count : getDurationInHours(operation.averageDuration) * count;
            }),
            backgroundColor: getColor(operation),
        })),
    };

    const transposedBarChartData = {
        labels: productionOperations.map((operation) => operation.title),
        datasets: employees.map((employee) => ({
            label: employee,
            data: transposedDate.map((stat) => {
                const count = stat.productionByOperation[employee] || 0;
                return showCount ? count : getDurationInHours(stat.averageDuration) * count;
            }),
            // backgroundColor: '#70ab2a',
        })),
    };

    const barChartDataToUse = transposed ? transposedBarChartData : barChartData;

    return (
        <>
            <div className="chart-container position-relative" style={{height:'40vh'}}>
                <Chart type='bar' options={barChartOptions} data={barChartDataToUse} />
            </div>
        </>
    );
};
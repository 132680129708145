import {useQuery} from "@tanstack/react-query";
import {SillDetails} from "../../../../../modules/api-client/generated";
import ApiClient from "../../../../../modules/api-client/ApiClient";

export const useSillQuery = (sillId: string | undefined) => {
    return useQuery<SillDetails, unknown, SillDetails>({
        queryKey: ['ApiClient.Pim.Sill.getSill', sillId],
        queryFn: async () => {
            const res = await ApiClient.Pim.Sill.getSill(sillId!);
            return res.data;
        },
        useErrorBoundary: true,
        enabled: !!sillId,
    });
};
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {EkoCard, EkoCardBody, toAbsoluteUrl} from "../../../_metronic/helpers";

type Props = {
    className: string
}
const EkosietUitlegWidget = ({className}: Props) => {
    return (
        <>
            <EkoCard className={className}>
                <EkoCardBody>
                    <div className={'mt-3'}>
                        <h3>Hoe bestel ik dorpels?</h3>
                        <p><em>Voor het aanvragen van een offerte volg je dezelfde stappen.</em></p>
                        <ol>
                            <li>Klik op "Dorpel(s) bestellen" linksboven in het scherm.</li>
                            <li>Volg de eerste 3 stappen om de benodigde ordergegevens in te voeren.<br/>
                                <em>Kloppen de vooraf ingevulde gegevens niet? Laat ons dit weten via info@ekosiet.nl,
                                    dan passen wij deze voor u aan zodat het in de toekomst automatisch correct wordt
                                    ingevuld.</em></li>
                            <li>In stap 4 klikt u op "Dorpel toevoegen"; de dorpel configurator wordt geopend.</li>
                            <li>Kies eerst het dorpel model</li>
                            <li>In de configurator selecteert u eerst de montage optie: Voormontage of Namontage.</li>
                            <li>Vervolgens verschijnt een tekening van de dorpel en neuten.</li>
                            <li>Kies het aantal vakken/neuten.</li>
                            <li>Gebruik de knoppen onderin het scherm om de muuraansluiting, neuten en vakken te
                                configureren.
                            </li>
                            <li>De prijs van de dorpel wordt rechtsboven bij iedere wijziging opnieuw berekend.</li>
                            <li>Wanneer u klaar bent met het configureren van de dorpel klikt u op "Volgende"</li>
                            <li>In het laatste scherm kunt u het gewenste aantal, merk en opmerking invullen.</li>
                            <li>Klik op "Opslaan"; u keert terug naar het order scherm, de dorpel is toegevoegd aan de
                                order.
                            </li>
                            <li>
                                Voeg alle gewenste dorpels toe.
                            </li>
                            <li>
                                In de volgende stap kunt u losse producten selecteren om toe te voegen aan uw order.
                            </li>
                            <li>
                                In de laatste stap vindt u een samenvatting van de order en kunt u de order plaatsen of
                                als offerte indienen.
                            </li>
                            <li>
                                U ontvangt de offerte automatisch per mail, de orderbevestiging wordt voorlopig nog
                                handmatig verstuurd door onze werkvoorbereiders.
                            </li>
                            <li>
                                Zolang de order is geplaatst kunt u nog wijzigen aanbrengen in de order. Zodra onze
                                werkvoorbereiders de order hebben geaccepteerd is dit niet meer mogelijk en dient u
                                contact op te nemen.
                            </li>
                        </ol>

                    </div>
                </EkoCardBody>
            </EkoCard>
        </>
    )
}
export {EkosietUitlegWidget}

import { toast } from "react-hot-toast"
import { useEkosietMutation } from "../../../../shared/hooks/useEkosietMutation";
import { NotFound, ValidationFailed } from "../../../../modules/api-client/Responses";
import ApiClient from "../../../../modules/api-client/ApiClient";
import { useQueryClient } from "@tanstack/react-query";
import {
    NeutProductionItemRepresentation,
    PostProductionBatchForNeutenRepresentation,
    ProductionBatchRepresentation
} from "../../../../modules/api-client/generated";
import {PmRoutePath} from "../../pm/PmRoutes";
import {useNavigate} from "react-router-dom";
import {ProductionListItemFormValues} from "../../pm/pages/CreateNeutenBatch";

export const usePostNeutProductionList = () => {
    const queryClient = useQueryClient()

    return useEkosietMutation<void, NotFound | ValidationFailed, string>((id) => {
        return ApiClient.Pm.ProductionBatch.pmProductionBatchIdFinishedPut(id, undefined).then();
    },
        {
            onSuccess: (data, variables) => {
                toast.success("Batch gereed");
            },
            onError: () => {
                toast.error("Batch gereedmelden mislukt");
            }
        }
    );
};

export const usePostLosseNeutenBatch = () => {
    const navigate = useNavigate();

    const queryClient = useQueryClient()

    return useEkosietMutation<ProductionBatchRepresentation, NotFound | ValidationFailed, ProductionListItemFormValues>((values) => {
        var items: NeutProductionItemRepresentation[] =
            Object.entries(values.items).map(([k,v]) => v);
        var codes: string =
            Object.entries(values.items).map(([a,b]) =>b.quantity +' x '+ b.code).join(', ');

        const command: PostProductionBatchForNeutenRepresentation  = {

            items: items,
            remark: values.remark +' '+ codes,
            title: values.title,
            urgent: false,
        };

        return ApiClient.Pm.ProductionBatch.pmProductionBatchCreateLosseNeutenBatchPost(undefined, command).then(response => response.data);
    }, {
        onSuccess: (batch) => {
            queryClient.invalidateQueries(['pmProductionBatchGetId']).then();
            queryClient.invalidateQueries(['pmProductionBatchesListOdataGet']).then();
            navigate(PmRoutePath.link(PmRoutePath.productionBatchLink(batch.id)));
        }
    });
};

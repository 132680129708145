import React, {useId} from "react";
import {useField} from "formik";
import {ConfiguredSillHefSchuifBarCutout} from "../../../modules/api-client/generated";


interface HefSchuifDoorBarCutoutFieldProps {
    fieldName: string;
}

export const HefSchuifDoorBarCutoutField: React.FC<HefSchuifDoorBarCutoutFieldProps> = (props) => {

    const id = useId();

    const [field, , helper] = useField<ConfiguredSillHefSchuifBarCutout | undefined>(props.fieldName);

    const onToggle = async (e: React.ChangeEvent<HTMLInputElement>) => {
        await helper.setValue(e.target.checked ? {
            length: undefined!,
            width: undefined!
        } : undefined)
    }


    return <div className="d-flex align-items-center">

        <div className="form-check form-switch">
            <input type="checkbox"
                   checked={!!field.value}
                   onChange={onToggle}
                   className="form-check-input"/>
        </div>

        <div className="input-group">
            <input type="number"
                   id={`${id}_length`}
                   pattern={"[0-9]+([,\\.][0-9]+)?"}
                   className="form-control"
                   placeholder="Lengte"
                   name={`${props.fieldName}.length`}
                   value={field.value?.length ?? ''}
                   onChange={field.onChange}
                   onBlur={field.onBlur}
                   disabled={!field.value}
                   min="0" step="0.1"
                   required/>

            {/*<span className="input-group-text">x</span>*/}

            <input type="number"
                   id={`${id}_width`}
                   pattern={"[0-9]+([,\\.][0-9]+)?"}
                   className="form-control"
                   placeholder="Breedte"
                   name={`${props.fieldName}.width`}
                   value={field.value?.width ?? ''}
                   onChange={field.onChange}
                   onBlur={field.onBlur}
                   disabled={!field.value}
                   min="0" step="0.1"
                   required/>
            <span className="input-group-text">mm</span>
        </div>
    </div>;
}

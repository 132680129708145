import React, {
    FormEvent,
    forwardRef,
    useCallback,
    useEffect,
    useId,
    useImperativeHandle,
    useMemo,
    useRef,
    useState
} from 'react';
import {
    CompartmentProfileInfo,
    IFacetResult,
    ProfileInfo,
    ProfileSearchResult
} from '../../../../modules/api-client/generated';
import {useQuery} from '@tanstack/react-query';
import ProfilePreviewSvg from '../../../../shared/components/ProfilePreviewSvg';
import {EkoTable} from '../../../../shared/components/table';
import {ProfileFacetSelector} from './ProfileFacetSelector';
import Loading from '../../../../shared/components/Loading';
import Error from '../../../../shared/components/Error';
import {ProfileDisplayName} from './ProfileDisplayName';
import {Dropdown} from 'react-bootstrap';

export type ProfilePickerFacetName =
    | 'RabbetPosition'
    | 'RabbetSize'
    | 'Turbohol'
    | 'AanslagNokSize'
    | 'RabbetRandWidth'
    | 'RabbetGrooveWidth'
    | 'CornerProfileInsideId'
    | 'CornerProfileOutsideId'
    | 'CompartmentLayoutTypeCode';

export const AllProfileFacets: ProfilePickerFacetName[] = [
    'CompartmentLayoutTypeCode',
    'RabbetSize',
    'Turbohol',
    'AanslagNokSize',
    'RabbetRandWidth',
    'RabbetGrooveWidth',
    'CornerProfileInsideId',
    'CornerProfileOutsideId',
];

export const StandardProfileFacets: ProfilePickerFacetName[] = ['RabbetSize', 'Turbohol', 'AanslagNokSize', 'CornerProfileInsideId', 'CornerProfileOutsideId'];

export const HefSchuifProfileFacets: ProfilePickerFacetName[] = ['RabbetPosition', 'RabbetRandWidth', 'RabbetGrooveWidth', 'CornerProfileInsideId', 'CornerProfileOutsideId'];

export interface QueryOptions {
    scope?: string[];
    filters?: string[];
    active?: boolean;
    hasProgram?: boolean;
    bestMatchFor?: string;
}

export type QueryCallback = (query: QueryOptions) => Promise<ProfileSearchResult>;

export type Profile = CompartmentProfileInfo & { isFavorite?: boolean; favoriteName?: string; favoriteWeight?: number };

export interface ProfilePickerProps {
    visibleFacets?: ProfilePickerFacetName[];
    scope?: string[];
    value?: string;
    onConfirm: (selection: ProfileInfo[]) => Promise<void>;
    onSelectionChanged?: (selection: ProfileInfo[]) => void;
    mirror?: boolean;
    multi?: boolean;
    query: QueryCallback;
    onMakeFavorite?: (profileId: string, name?: string) => Promise<void>;
    onRemoveFavorite?: (profileId: string) => Promise<void>;
    onRenameFavorite?: (profileId: string, name: string) => Promise<void>;
    initialProfileId?: string | null;
    bestMatchFor?: string;
}

export type ProfilePickerContext = {
    isInitialLoading: boolean;
    isError: boolean;
    refreshProfiles: () => Promise<void>;
    profiles: Profile[];
    facets: IFacetResult[];
    facetSelection: Facets;
    setFacetSelection: (value: Facets) => void;
    onFacetSelectionChanged: (facetCode: string, selection: string[]) => void;
    selectProfile: (profile: ProfileInfo) => void;
    deselectProfile: (profile: ProfileInfo) => void;
    toggle: (profile: ProfileInfo) => Promise<void>;
    isSelected: (profile: ProfileInfo) => boolean;
    selection: Map<string, ProfileInfo>;
    confirm: () => void;
    makeFavorite: (profileId: string, name?: string) => Promise<void>;
    removeFavorite: (profileId: string) => Promise<void>;
    renameFavorite: (profileId: string, name: string) => Promise<void>;
    initialProfileId?: string;
};

type Facets = { [key: string]: string[] };

function useProfilePickerContext(props: ProfilePickerProps): ProfilePickerContext {
    const isMulti = props.multi ?? false;

    const [profileList, setProfileList] = useState<ProfileSearchResult | null>(null);
    const [facetSelection, setFacetSelection] = useState<Facets>({});
    const [selection, setSelection] = useState<ProfileInfo[]>([]);

    const selectionMap = useMemo(() => {
        return new Map<string, ProfileInfo>(selection.map((x) => [x.id, x]));
    }, [selection]);

    const filters = useMemo(() => {
        return Object.keys(facetSelection).map((code) => {
            return `${code}:${facetSelection[code].filter((x) => x).join(',')}`;
        });
    }, [facetSelection]);

    const {
        isInitialLoading,
        isError,
        refetch
    } = useQuery<ProfileSearchResult>(['ProfilePicker', props.scope, filters], async () => {
        const list = await props.query({
            scope: props.scope,
            filters: filters,
            active: true,
            hasProgram: undefined,
            bestMatchFor: props.bestMatchFor
        });

        setProfileList(list);

        return list;
    });

    const profiles = useMemo(() => (profileList?.profiles ?? []) as CompartmentProfileInfo[], [profileList]);

    useEffect(() => {

        if (props.initialProfileId && selection.length === 0) {

            var infos = profiles as ProfileInfo[];
            if (profiles.length) {
                var initProfile = infos.find((p) => p.id === props.initialProfileId);
                if (initProfile !== undefined) {
                    setSelection((current) => {
                        return [...current, initProfile!];
                    });

                    // Toegevoegd door Niels om
                    props.onConfirm([initProfile]);
                }
            }
        }
    }, [props.initialProfileId, profiles]);

    const facets = useMemo((): IFacetResult[] => {
        if (!props.visibleFacets) return [];

        const visibleFacets: string[] | undefined = props.visibleFacets;

        return Object.values(profileList?.facets ?? {})
            .filter((f) => typeof visibleFacets !== 'undefined' && visibleFacets.indexOf(f.value) > -1)
            .sort((a, b) => visibleFacets.indexOf(a.value) - visibleFacets.indexOf(b.value));
    }, [profileList, props.visibleFacets]);

    useEffect(() => {
        props.onSelectionChanged?.(selection);
    }, [selection]);

    const onFacetSelectionChanged = (facetCode: string, selection: string[]) => {
        setFacetSelection((prev: Facets): Facets => {
            if (selection.length === 0 && Object.keys(prev).indexOf(facetCode) !== -1) {
                delete prev[facetCode];
                return {...prev};
            } else {
                return {...prev, [facetCode]: selection};
            }
        });
    };

    const confirm = useCallback(async (): Promise<void> => {
        await props.onConfirm(selection);
    }, [props.onConfirm, selection]);

    const isSelected = (profile: ProfileInfo) => selectionMap.has(profile.id);

    const selectProfile = async (profile: ProfileInfo) => {
        if (!isMulti) {
            const selection = [profile];
            setSelection(selection);
            await props.onConfirm(selection);
            return;
        }

        setSelection((current) => {
            return [...current, profile];
        });
    };

    const deselectProfile = (profile: ProfileInfo) => {
        setSelection((current) => {
            const index = current.findIndex((x) => x.id === profile.id);
            if (index === -1) return current;
            return [...current.slice(0, index), ...current.slice(index + 1)];
        });
    };

    const toggle = async (profile: ProfileInfo) => {
        if (isSelected(profile)) {
            deselectProfile(profile);
        } else {
            await selectProfile(profile);
        }
    };

    const refreshProfiles = async () => {
        await refetch();
    };

    const makeFavorite = async (profileId: string, name?: string) => {
        await props.onMakeFavorite!(profileId, name);
        await refreshProfiles();
    };

    const removeFavorite = async (profileId: string) => {
        await props.onRemoveFavorite!(profileId);
        await refreshProfiles();
    };

    const renameFavorite = async (profileId: string, name: string) => {
        await props.onRenameFavorite!(profileId, name);
        await refreshProfiles();
    };

    return {
        isInitialLoading,
        isError,
        refreshProfiles,
        profiles,
        facets,
        facetSelection,
        setFacetSelection: (facets: Facets) => setFacetSelection(facets),
        onFacetSelectionChanged,
        selectProfile,
        deselectProfile,
        toggle,
        isSelected,
        selection: selectionMap,
        confirm,
        makeFavorite,
        removeFavorite,
        renameFavorite,
    };
}

export type ProfilePickerHandle = {
    confirm: () => void;
};

export const ProfilePicker = forwardRef<ProfilePickerHandle, ProfilePickerProps>((props, ref) => {
    const {
        isInitialLoading,
        isError,
        profiles,
        facetSelection,
        setFacetSelection,
        facets,
        onFacetSelectionChanged,
        isSelected,
        toggle,
        confirm,
        makeFavorite,
        removeFavorite,
        renameFavorite
    } =
        useProfilePickerContext(props);

    useImperativeHandle(
        ref,
        () => {
            return {confirm};
        },
        [confirm]
    );

    if (isInitialLoading && !profiles) return <Loading/>;

    if (isError) return <Error/>;

    const favorites = profiles.filter((x) => 'isFavorite' in x && x.isFavorite);

    const nonFavorites = profiles.filter((x) => !('isFavorite' in x) || !x.isFavorite);

    return (
        <>
            <div className="row">
                {profiles.length > 0 && (
                    <div className="col-4">
                        <div className="sticky-top">
                            <div className={'d-flex justify-content-between align-items-start mb-3'}>
                                <h3>Filters</h3>
                                {Object.keys(facetSelection).length > 0 && (
                                    <button onClick={() => setFacetSelection({})}
                                            className="btn btn-link btn-sm p-0 link-primary">
                                        Reset alle filters
                                    </button>
                                )}
                            </div>

                            {facets.length > 0 &&
                                facets.map((facet, index) => (
                                    <ProfileFacetSelector
                                        key={index}
                                        title={facet.name}
                                        labels={facet.labels}
                                        selected={facetSelection[facet.value] ?? []}
                                        selectionChanged={(selection) => onFacetSelectionChanged(facet.value, selection)}
                                        maxHeight="140px"
                                    />
                                ))}
                        </div>
                    </div>
                )}
                <div className="col">
                    <EkoTable>
                        <tbody>
                        {profiles.length === 0 && (
                            <tr>
                                <td colSpan={2} className="text-center">
                                    Geen gegevens
                                </td>
                            </tr>
                        )}

                        {favorites.length > 0 && (
                            <>
                                <tr>
                                    <td colSpan={3}>
                                        <h5 className="mb-0">Favorieten</h5>
                                    </td>
                                </tr>
                                {favorites.map((profile) => (
                                    <ProfilePickerTableRow
                                        key={profile.id}
                                        profile={profile}
                                        isSelected={isSelected}
                                        toggle={toggle}
                                        mirror={props.mirror ?? false}
                                        onMakeFavorite={makeFavorite}
                                        onRemoveFavorite={removeFavorite}
                                        onRenameFavorite={renameFavorite}
                                    />
                                ))}
                                <tr>
                                    <td colSpan={3}>
                                        <h5 className="mt-3 mb-0">Overige</h5>
                                    </td>
                                </tr>
                            </>
                        )}

                        {nonFavorites.map((profile) => (
                            <ProfilePickerTableRow
                                key={profile.id}
                                profile={profile}
                                isSelected={isSelected}
                                toggle={toggle}
                                mirror={props.mirror ?? false}
                                onMakeFavorite={makeFavorite}
                                onRemoveFavorite={removeFavorite}
                                onRenameFavorite={renameFavorite}
                                showMatchPercentage={props.bestMatchFor !== undefined}
                            />
                        ))}
                        </tbody>
                    </EkoTable>
                </div>
            </div>
        </>
    );
});

export const ProfilePickerTableRow: React.FC<{
    profile: Profile;
    isSelected: (profile: Profile) => boolean;
    toggle: (profile: Profile) => Promise<void>;
    mirror: boolean;
    onMakeFavorite?: (profileId: string, name?: string) => Promise<void>;
    onRemoveFavorite?: (profileId: string) => Promise<void>;
    onRenameFavorite?: (profileId: string, name: string) => Promise<void>;
    showMatchPercentage?: boolean;
}> = ({
          profile,
          isSelected,
          toggle,
          mirror,
          onMakeFavorite,
          onRemoveFavorite,
          onRenameFavorite,
          showMatchPercentage
      }) => {
    const toggleFavorites = !!onMakeFavorite && !!onRemoveFavorite;


    const matchPercentText = profile.match ? Number(profile.match.percentage).toLocaleString(undefined, {
        style: 'percent',
        minimumFractionDigits: 2
    }) : undefined;

    return (
        <tr key={profile.id} onDoubleClick={async () => await toggle(profile)}>
            <td className="fit-content user-select-none">
                <div style={{height: '80px', width: '80px'}}>
                    <ProfilePreviewSvg profileSvg={profile.svgPreview} mirror={mirror ?? false}/>
                </div>
            </td>
            <td>
                <div>
                    <ProfileDisplayName profile={profile}/>
                </div>
            </td>
            {showMatchPercentage && <td>
                {matchPercentText && <span className="badge bg-light text-dark"
                                           title={`Dit profiel komt voor ${matchPercentText} overeen.`}>{matchPercentText}</span>}
            </td>}
            {toggleFavorites && (
                <td className="fit-content text-center">
                    <ToggleFavoriteDropDown profile={profile} onMakeFavorite={onMakeFavorite}
                                            onRemoveFavorite={onRemoveFavorite} onRename={onRenameFavorite}/>
                </td>
            )}
            <td className="fit-content text-center">
                {(isSelected(profile) && (
                    <button type="button" className="btn btn-success w-100" onClick={async () => await toggle(profile)}>
                        <i className="fas fa-check"></i>
                    </button>
                )) || (
                    <button type="button" className="btn btn-light-primary  w-100"
                            onClick={async () => await toggle(profile)}>
                        Selecteer
                    </button>
                )}
            </td>
        </tr>
    );
};

const ToggleFavoriteDropDown: React.FC<{
    profile: Profile;
    onMakeFavorite?: (profileId: string, name?: string) => Promise<void>;
    onRemoveFavorite?: (profileId: string) => Promise<void>;
    onRename?: (profileId: string, name: string) => Promise<void>;
}> = ({profile, onMakeFavorite, onRemoveFavorite, onRename}) => {
    const id = useId();

    const dropDownToggleRef = useRef<HTMLDivElement>(null);
    const renameDropdownToggleRef = useRef<HTMLInputElement>(null);
    const renameInputRef = useRef<HTMLInputElement>(null);

    const [showRenameDropdown, setShowRenameDropdown] = useState(false);

    useEffect(() => {
        const autoFocus = () => {
            dropDownToggleRef.current?.parentElement?.querySelector('input')?.focus();
        };

        if (dropDownToggleRef.current) {
            dropDownToggleRef.current.addEventListener('shown.bs.dropdown', autoFocus);

            return function cleanup() {
                dropDownToggleRef.current?.removeEventListener('shown.bs.dropdown', autoFocus);
            };
        }
    }, []);

    const onMakeFavoriteSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const data = new FormData(e.currentTarget);
        const name: string | undefined = data.has('name') ? (data.get('name') as string) : undefined;
        await onMakeFavorite!(profile.id, name);
    };

    const onRenameFavoriteSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const data = new FormData(e.currentTarget);
        const name: string = data.get('name') as string;
        await onRename!(profile.id, name);
        setShowRenameDropdown(false);
    };

    useEffect(() => {
        if (showRenameDropdown) {
            setTimeout(() => {
                renameInputRef.current?.focus();
                renameInputRef.current?.select();
            });
        }
    }, [showRenameDropdown]);

    return (
        <>
            {onRename && (
                <Dropdown
                    show={showRenameDropdown}
                    drop="down-centered"
                    onToggle={(nextShow) => {
                        setShowRenameDropdown(nextShow);
                    }}
                >
                    <Dropdown.Toggle ref={renameDropdownToggleRef} as="div"
                                     style={{visibility: 'collapse'}}></Dropdown.Toggle>
                    <Dropdown.Menu as="form" className="p-4" style={{minWidth: '260px'}}
                                   onSubmit={onRenameFavoriteSubmit}>
                        <Dropdown.Header as="h6" className="px-0">
                            Favoriet naam wijzigen
                        </Dropdown.Header>
                        <div className="mb-3">
                            <input ref={renameInputRef} type="text" name="name" className="form-control form-control-sm"
                                   defaultValue={profile.favoriteName} placeholder="Naam"/>
                        </div>
                        <div className="d-flex justify-content-end">
                            <button type="submit" className="btn btn-primary btn-sm">
                                Wijzigen
                            </button>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
            )}

            <div className="dropdown d-flex justify-content-end gap-3">
                <div ref={dropDownToggleRef} className="cursor-pointer" data-bs-toggle="dropdown"
                     data-bs-target={`#${id}-context-dropdown-menu`} aria-expanded="false">
                    <small>{profile.favoriteName}</small> <i className="fas fa-star"
                                                             style={{color: profile.isFavorite ? '#EEBD01' : ''}}></i>
                </div>

                {!profile.isFavorite && (
                    <form className="dropdown-menu p-4" id={`${id}-context-dropdown-menu`} style={{minWidth: '260px'}}
                          onSubmit={onMakeFavoriteSubmit}>
                        <h6 className="dropdown-header px-0">Favoriet</h6>
                        <div className="mb-3">
                            <input type="text" name="name" className="form-control form-control-sm"
                                   placeholder="Naam (optioneel)"/>
                        </div>
                        <div className="d-flex justify-content-end">
                            <button type="submit" className="btn btn-primary btn-sm">
                                Toevoegen
                            </button>
                        </div>
                    </form>
                )}

                {profile.isFavorite && (
                    <div className="dropdown">
                        <div className="dropdown-menu dropdown-menu-end" id={`${id}-context-dropdown-menu`}
                             style={{minWidth: '100%'}}>
                            <h6 className="dropdown-header">Favoriet</h6>
                            {onRename && (
                                <>
                                    <div className="dropdown-item" onClick={() => setShowRenameDropdown(true)}>
                                        Naam wijzigen
                                    </div>
                                    <span className="dropdown-divider"></span>
                                </>
                            )}
                            <div className="dropdown-item" onClick={async () => await onRemoveFavorite!(profile.id)}>
                                Verwijderen
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

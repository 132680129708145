
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {useParams} from "react-router-dom";
import {EkoCard, EkoCardBody, EkoCardHeader} from "../../../../../shared/components/card";
import {RenderCustomerPriceList} from "../../components/CustomerPriceList";

export function CustomerPricelist() {
    const {id} = useParams<{ id: string }>();
    return (
            <EkoCard>
                <EkoCardHeader title={'Prijslijst'}>
                </EkoCardHeader>
                <EkoCardBody>
                    <div className={'row'}>
                        <div className={'col col-lg-7'}>
                            <RenderCustomerPriceList customerId={id!}/>
                        </div>
                    </div>
                </EkoCardBody>
            </EkoCard>
    )
}

import React, {useId} from "react";
import {useField} from "formik";
import {HefSchuifSlidingRailType} from "../../../modules/api-client/generated";
import {HefSchuifSlidingRailTypeField} from "./HefSchuifRailTypeField";

export interface HefSchuifSlidingRailTypeRowProps {
    fieldName: string;
}

export const HefSchuifSlidingRailTypeRow: React.FC<HefSchuifSlidingRailTypeRowProps> = (props) => {

    const id = useId();

    const [field, meta, helpers] = useField<HefSchuifSlidingRailType | undefined>(props.fieldName);

    return <div className="row">
        <div className="col-4">
            <label htmlFor={id} className='form-label'>
                <small className='text-uppercase text-muted'>Looprail type</small>
            </label>
            <HefSchuifSlidingRailTypeField id={id} value={field.value} onChange={(value) => helpers.setValue(value)} />
        </div>
    </div>
}


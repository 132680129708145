import {
    RenovationRabbetStep,
    SillConfigurationGroove,
    SillConfigurationRenovationRabbetStep,
    SillConfigurationStandardWallConnection
} from "../../../modules/api-client/generated";
import React from "react";
import {useFormikContext} from "formik";
import {useRenovationRabbetDialog} from "./hooks/use-renovation-rabbet-dialog";
import {useGrooveDialog} from "./hooks/use-groove-dialog";

export interface StandardWallConnectionEditorProps {
    width: number;
    onChange: (value: SillConfigurationStandardWallConnection) => Promise<void>;
}

export const StandardWallConnectionEditor: React.FC<StandardWallConnectionEditorProps> = (props) => {

    const {values} = useFormikContext<SillConfigurationStandardWallConnection>();

    const {
        open: openRenovationRabbetDialog,
        component: renovationRabbetDialogComponent
    } = useRenovationRabbetDialog({wallConnectionWidth: props.width});

    const {
        open: openGrooveDialog,
        component: grooveDialogComponent
    } = useGrooveDialog<SillConfigurationGroove>({wallConnectionWidth: props.width});

    const addOrChangeRenovationRabbetStepInside = async (original?: SillConfigurationRenovationRabbetStep) => {
        openRenovationRabbetDialog({
            title: 'Renovatiesponning binnen',
            value: original,
            onChange: async (step) => {
                let steps = [...values.renovationRabbetInside?.steps ?? []];
                if (original) steps = steps.filter(s => s !== original);
                steps.push(step);
                return props.onChange({...values, renovationRabbetInside: {steps: steps}});
            }
        });
    };

    const addOrChangeRenovationRabbetStepOutside = async (original?: SillConfigurationRenovationRabbetStep) => {
        openRenovationRabbetDialog({
            title: 'Renovatiesponning buiten',
            value: original,
            onChange: async (step) => {
                let steps = [...values.renovationRabbetOutside?.steps ?? []];
                if (original) steps = steps.filter(s => s !== original);
                steps.push(step);
                return props.onChange({...values, renovationRabbetOutside: {steps: steps}});
            }
        });
    };

    const addOrChangeGroove = async (original?: SillConfigurationGroove) => {
        openGrooveDialog({
            title: 'Kalksponning / Groef',
            value: original,
            onChange: async (groove) => {
                let grooves = [...values.grooves ?? []];
                if (original) grooves = grooves.filter(g => g !== original);
                grooves.push(groove);
                return props.onChange({...values, grooves: grooves});
            }
        });
    };

    const removeRenovationRabbetStepInside = async (step: RenovationRabbetStep) => {
        const steps = values.renovationRabbetInside!.steps.filter(s => s !== step);
        const wc = {...values, renovationRabbetInside: {steps: steps}};
        console.log(wc);
        await props.onChange(wc);
    }

    const removeRenovationRabbetStepOutside = async (step: RenovationRabbetStep) => {
        const steps = values.renovationRabbetOutside!.steps.filter(s => s !== step);
        const wc = {...values, renovationRabbetOutside: {steps: steps}};
        console.log(wc);
        await props.onChange(wc);
    }

    const removeGroove = async (groove: SillConfigurationGroove) => {
        const grooves = values.grooves!.filter(g => g !== groove);
        const wc = {...values, grooves: grooves};
        console.log(wc);
        await props.onChange(wc);
    }

    return <>
        <div className="d-grid gap-3">

            <div>
                <label className='form-label'><small className='text-uppercase text-muted'>Renovatiesponning
                    binnen</small></label>

                <div className="input-group">
                    <div className="form-control d-flex gap-2">
                        {values.renovationRabbetInside?.steps.map((step, index) => {
                            return <div key={index} className="input-group w-auto flex-nowrap">
                                <button type="button" className="btn btn-primary badge py-2 px-2 border-0"
                                        title="Renovatiesponning binnen wijzigen"
                                        onClick={() => addOrChangeRenovationRabbetStepInside(step)}>{step.width}x{step.depth}mm
                                </button>
                                <button type="button" className="btn btn-primary badge py-2 px-2 border-0"
                                        title="Renovatiesponning binnen verwijderen"
                                        onClick={() => removeRenovationRabbetStepInside(step)}><i
                                    className="text-white fa fa-close"/></button>
                            </div>
                        })}

                        {(!values.renovationRabbetInside || values.renovationRabbetInside.steps.length < 3) &&
                            <button type="button" className="btn btn-outline-secondary btn-sm py-1 px-2"
                                    title="Renovatiesponning binnen toevoegen"
                                    onClick={() => addOrChangeRenovationRabbetStepInside()}>Toevoegen
                            </button>
                        }
                    </div>
                </div>
            </div>
            <div>
                <label className='form-label'><small className='text-uppercase text-muted'>Kalksponning / Groef</small></label>
                <div className="input-group">
                    <div className="form-control d-flex gap-2 flex-wrap">
                        {values.grooves?.map((groove, index) => {
                            return <div key={index} className="input-group w-auto flex-nowrap">
                                <button type="button" className="btn btn-primary badge py-2 px-2 border-0"
                                        title="Kalksponning / groef wijzigen"
                                        onClick={() => addOrChangeGroove(groove)}>{groove.width}x{groove.depth}mm
                                    @{groove.position}mm

                                </button>
                                <button type="button" className="btn btn-primary badge py-2 px-2 border-0"
                                        title="Kalksponning / groef verwijderen"
                                        onClick={() => removeGroove(groove)}><i
                                    className="text-white fa fa-close"/></button>
                            </div>
                        })}
                        {(!values.grooves || values.grooves.length < 4) &&
                            <button type="button" className="btn btn-outline-secondary btn-sm py-1 px-2"
                                    title="Kalksponning / groef toevoegen"
                                    onClick={() => addOrChangeGroove()}>Toevoegen
                            </button>
                        }
                    </div>
                </div>
            </div>
            <div>
                <label className='form-label'><small className='text-uppercase text-muted'>Renovatiesponning
                    buiten</small></label>
                <div className="input-group">
                    <div className="form-control d-flex gap-2">
                        {values.renovationRabbetOutside?.steps.map((step, index) => {
                            return <div key={index} className="input-group w-auto flex-nowrap">
                                <button type="button" className="btn btn-primary badge py-2 px-2 border-0"
                                        title="Renovatiesponning buiten wijzigen"
                                        onClick={() => addOrChangeRenovationRabbetStepOutside(step)}>{step.width}x{step.depth}mm
                                </button>
                                <button type="button" className="btn btn-primary badge py-2 px-2 border-0"
                                        title="Renovatiesponning buiten verwijderen"
                                        onClick={() => removeRenovationRabbetStepOutside(step)}><i
                                    className="text-white fa fa-close"/></button>
                            </div>
                        })}

                        {(!values.renovationRabbetOutside || values.renovationRabbetOutside.steps.length < 3) &&
                            <button type="button" className="btn btn-outline-secondary btn-sm py-1 px-2"
                                    title="Renovatiesponning buiten toevoegen"
                                    onClick={() => addOrChangeRenovationRabbetStepOutside()}>Toevoegen
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div>
        {renovationRabbetDialogComponent}
        {grooveDialogComponent}
    </>
};
import {useQuery} from "@tanstack/react-query";
import ApiClient from "../../../modules/api-client/ApiClient";
import {
    ImportRepresentation,    
} from "../../../modules/api-client/generated";
import {AxiosError} from "axios";
import {toast} from "react-hot-toast";
import { isNotEmpty } from "../../../../_metronic/helpers";

export const useGetImportSummary = (id: string | undefined, enabled: boolean = true) => {

    const response =  useQuery<ImportRepresentation, AxiosError, ImportRepresentation>(
        ['aclImportIdGet', id],
        () => {
            return ApiClient.Acl.Import.aclImportIdGet(id!).then((res) => res.data);
        }, {
            useErrorBoundary: true,
            enabled: enabled && isNotEmpty(id),
            refetchOnWindowFocus: true,
            refetchInterval: 3000,
        });

    if (response.error) {
        var error = response.error;
        toast.error(error?.message);
    }
    return response;
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {PageTitle} from "../../../../../_metronic/layout/core";
import {EkoCard, EkoCardToolbar} from "../../../../shared/components/card";
import {EkoCardBody} from "../../../../shared/components/card";
import {EkoCardHeader} from "../../../../shared/components/card";
import {toAbsoluteUrl} from "../../../../../_metronic/helpers";
import {ProductionStats} from "../../pm/components/ProductionStats";
import {YTDProductionStatsTile} from "../../sm/components/YTDProductionStatsTile";
import {DailyProduction} from "../../pm/components/DailyProduction";
import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import {MgtRoutePath} from "../MgtRoutes";

export function MgtProductionDashboard() {
    return (
        <>
            <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
                <div className='col col-lg-8 mb-md-5 mb-xl-10'>
                    <EkoCard className={'mb-4'}>
                        <EkoCardHeader title={'Dagelijkse productie'}>
                            <EkoCardToolbar>
                                <Link to={MgtRoutePath.productionBacklog}
                                      className='text-hover-primary'> Gedetailleerde backlog
                                </Link>
                            </EkoCardToolbar>
                        </EkoCardHeader>
                        <EkoCardBody>
                            <DailyProduction/>
                        </EkoCardBody>
                    </EkoCard>
                    <EkoCard className={'mb-4'}>
                        <EkoCardHeader title={'Wekelijkse productie'}>
                        </EkoCardHeader>
                        <EkoCardBody>
                            <ProductionStats/>
                        </EkoCardBody>
                    </EkoCard>
                </div>
                <div className='col col-lg-4 mb-md-5 mb-xl-10'>
                    <YTDProductionStatsTile
                        className='mb-5 mb-xl-10'
                        description='Dit jaar'
                        color='#F1416C'
                        img={toAbsoluteUrl('/media/patterns/vector-1.png')}/>
                </div>
            </div>
        </>
    )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {EkoTable} from "../../../../../../shared/components/table/EkoTable";
import {TableHeader} from "../../../../../../shared/components/table/TableHeader";
import {
    OrderLineType,
    OrderRepresentation,
    PostProductOrderLineRepresentation,
    StandardProduct
} from "../../../../../../modules/api-client/generated";
import {usePostOrderline} from "../../hooks/orderline/use-post-orderline";
import {useGetOrder} from '../../hooks/use-get-order';
import {
    useListStandardProductsByCatalogIdAndCategoryQuery,
    useListStandardProductsByCatalogIdQuery
} from '../../../../pim/catalogitem/hooks';
import Euro from '../../../../../../shared/components/Euro';
import {ProductList} from "./ProductList";
import {useCategoryByCodeQuery, useCategoryQuery} from "../../../../pim/category/hooks";
import Loading from "../../../../../../shared/components/Loading";

type Props = {
    catalogId?: string
    categoryCode?: string
    priceListId?: string
    onProductAdded?: ((productId: string) => void) | undefined
}

const CategoryProductList: React.FC<Props> = ({categoryCode, catalogId, priceListId, onProductAdded}) => {

    const {
        isInitialLoading,
        data: category,
        isError
    } = useCategoryByCodeQuery(categoryCode)

    if (isInitialLoading) {
        return <Loading/>;
    }
    if (isError) {
        return <>Fout bij het laden van de producten!</>;
    }
    if (!category) return <></>;

    return (
        <div className={'mb-5'}>
            <div className='d-flex justify-content-between'>
                <div>

                    <h2>{category.name}</h2>
                    <p>
                        {category.description}
                    </p>
                </div>
                <div>
                    {category.image &&
                        <div className={'image-container'} >
                            <img src={`data:image/jpg;base64,` + category.image} alt={category.code}
                                 className={'img-fluid'}/>
                        </div>

                    }
                </div>

            </div>

            <ProductList catalogId={catalogId} priceListId={priceListId} category={categoryCode}
                         onProductAdded={onProductAdded}/>
        </div>

    )
}

export {CategoryProductList}

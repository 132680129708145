/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {ReactElement} from 'react'
import {
    OrderLineRepresentation,
    OrderLineType,
    OrderStatusCodes,
    PostProductOrderLineRepresentation,
    PostShippingCostOrderLineRepresentation,
    PostTextOrderLineRepresentation, ProductionStatusCodes,
} from "../../../../../../modules/api-client/generated";
import {OrderLineTableRow} from "./OrderLineTableRow";
import Euro from "../../../../../../shared/components/Euro";
import OrderlineTypeIcon from "../../../../../../shared/components/OrderlineTypeIcon";
import {PostOrderlineForm} from "./forms/orderline/PostOrderlineForm";
import {isNotEmpty} from '../../../../../../../_metronic/helpers';
import {Link} from "react-router-dom";
import {SmRoutePath} from "../../../SmRoutes";

type Props = {
    orderId: string;
    customerId: string;
    pricelistId: string,
    catalogId: string,
    lines: Array<OrderLineRepresentation> | undefined | null
    projectDiscount: number
    totalPriceGross: number
    totalPriceNet: number
    productionStatus?: ProductionStatusCodes | undefined,
    status: OrderStatusCodes
    showConfiguratorModal?: React.MutableRefObject<((show: boolean) => void) | undefined>
    setModalFullscreen: React.Dispatch<React.SetStateAction<true | string | 'sm-down' | 'md-down' | 'lg-down' | 'xl-down' | 'xxl-down'>>,
    setModalComponent: React.Dispatch<React.SetStateAction<ReactElement | undefined | null>>,
    setModalTitle: React.Dispatch<React.SetStateAction<string | undefined | null>>,
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>,
    isOrderEdit: boolean
}

const OrderLineTable: React.FC<Props> = ({
                                             orderId,
                                             customerId,
                                             lines,
                                             projectDiscount,
                                             productionStatus,
                                             totalPriceGross,
                                             totalPriceNet,

                                             catalogId,
                                             pricelistId,
                                             setModalTitle,
                                             setModalComponent,
                                             setShowEditModal,
                                             setModalFullscreen,
                                             status,
                                             isOrderEdit
                                         }) => {

    if (!orderId) return (<></>)

    const addProductOrderLine = () => {
        const data: PostProductOrderLineRepresentation = {
            quantity: 1,
            catalogItemId: "",
            orderlineType: OrderLineType.ProductLine,
        }
        setModalComponent(<PostOrderlineForm handleClose={() => setShowEditModal(false)} catalogId={catalogId}
                                             orderId={orderId} orderLineData={data}/>);
        setModalTitle('Kies producten om toe te voegen aan de order');
        setShowEditModal(true);
    }

    const addTextline = () => {
        const data: PostTextOrderLineRepresentation = {
            title: "Tekst",
            price: 0,
            quantity: 1,
            orderlineType: OrderLineType.TextLine
        }
        setModalComponent(<PostOrderlineForm handleClose={() => setShowEditModal(false)} catalogId={catalogId}
                                             orderId={orderId} orderLineData={data}/>);
        setModalTitle('Tekstregel toevoegen aan order');
        setShowEditModal(true);
    }

    const addTransportline = () => {
        const data: PostShippingCostOrderLineRepresentation = {
            quantity: 1,
            orderlineType: OrderLineType.ShippingCostLine
        }
        setModalComponent(<PostOrderlineForm handleClose={() => setShowEditModal(false)} catalogId={catalogId}
                                             orderId={orderId} orderLineData={data}/>);
        setModalTitle('Transportkosten toevoegen aan order');
        setShowEditModal(true);
    }


    return (
        <>
            {(status === OrderStatusCodes.Draft
                    || status === OrderStatusCodes.Placed
                    || status === OrderStatusCodes.QuoteIssued
                    || (status === OrderStatusCodes.Accepted && productionStatus === ProductionStatusCodes.NotStarted)

                ) && isOrderEdit &&
                <>
                    {status === OrderStatusCodes.Accepted && productionStatus === ProductionStatusCodes.NotStarted &&
                        <div className={'alert alert-danger mt-3'}>Let op: Dit is een geaccepteerde order:
                            Hergenereer de productie items handmatig als je dorpels wijzigt of toevoegt!
                        </div>

                    }
                    {status === OrderStatusCodes.QuoteIssued &&
                        <div className={'alert alert-danger mt-3'}>Let op: Verstuur de offerte opnieuw naar de klant als
                            je projectkorting of regels wijzigt!
                        </div>
                    }


                    <div className={'d-flex w-100 justify-content-end'}>

                        <Link to={SmRoutePath.orderEditConfiguratorLineLink({
                            orderId: orderId,
                            customerId: customerId,
                            pricelistId: pricelistId,
                        })} className={`btn btn-primary btn-sm me-2 `}><OrderlineTypeIcon
                            type={OrderLineType.ConfigurationLine}/> Dorpel toevoegen</Link>

                        <button type="button" className={`btn btn-primary btn-sm me-2 `}
                                onClick={() => addProductOrderLine()}>
                            <OrderlineTypeIcon type={OrderLineType.ProductLine}/> Product toevoegen
                        </button>
                        <button type="button" className={`btn btn-primary btn-sm me-2 `} onClick={() => addTextline()}>
                            <OrderlineTypeIcon type={OrderLineType.TextLine}/> Tekstregel toevoegen
                        </button>
                        <button type="button" className="btn btn-primary btn-sm "
                                onClick={() => addTransportline()}>
                            <OrderlineTypeIcon type={OrderLineType.ShippingCostLine}/> Transportkosten toevoegen
                        </button>
                    </div>
                </>
            }

            <div className="table-responsive">
                {/*begin::Table*/}
                <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0 ">
                    {/*begin::Table head*/}
                    <thead>
                    <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                        {isOrderEdit &&
                            <>
                                <th className="fit-content">#</th>
                                <th className="min-w-20px text-center">Type</th>
                            </>
                        }
                        <th className="min-w-70px text-center">Aantal</th>
                        <th className="min-w-175px">Product</th>
                        <th className="min-w-100px">Merk</th>
                        <th className="min-w-100px text-end">Prijs</th>
                        <th className="text-end">Totaal</th>
                        <th className="min-w-40px text-end"></th>
                    </tr>
                    </thead>
                    {/*end::Table head*/}

                    {/*begin::Table body*/}
                    <tbody className="fw-semibold text-gray-600">
                    {/*begin::Products*/}
                    {lines?.map((line, index) => {
                        return (
                            <OrderLineTableRow
                                index={index}
                                key={line.id}
                                customerId={customerId}
                                pricelistId={pricelistId}
                                productionStatus={productionStatus}
                                orderLine={line}
                                status={status}
                                setModalTitle={setModalTitle}
                                setModalComponent={setModalComponent}
                                setModalFullscreen={setModalFullscreen}
                                setShowEditModal={setShowEditModal} isOrderEdit={isOrderEdit}/>
                        )
                    })}

                    {isOrderEdit && projectDiscount > 0 &&
                        <>
                            {/*begin::Subtotal*/}

                            <tr>
                                <td colSpan={7} className="fs-3 text-dark text-end">
                                    Subtotaal
                                </td>
                                <td className="text-dark fs-3 fw-bolder text-end">
                                    <Euro value={totalPriceGross}/>
                                </td>
                            </tr>

                            {/*end::Subtotal*/}
                            {/*begin::Project Discount*/}
                            <tr>
                                <td colSpan={7} className="fs-3 text-dark text-end">
                                    Projectkorting
                                </td>
                                <td className="text-dark fs-3 fw-bolder text-end">
                                    <Euro value={totalPriceGross - totalPriceNet}/>
                                </td>
                            </tr>

                            {/*end::Project Discount*/}
                        </>
                    }

                    {/*begin::Grand total*/}
                    {isOrderEdit && isNotEmpty(totalPriceNet) &&
                        <tr>
                            <td colSpan={7} className="fs-3 text-dark text-end">
                                Totaal
                            </td>
                            <td className="text-dark fs-3 fw-bolder text-end">
                                <Euro value={totalPriceNet}/>
                            </td>
                        </tr>
                    }
                    {/*end::Grand total*/}
                    </tbody>
                    {/*end::Table head*/}
                </table>
                {/*end::Table*/}
            </div>
        </>
    )
}

export {OrderLineTable}

import React, {FC} from 'react';
import {useQuery} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import ApiClient from '../../../modules/api-client/ApiClient';
import SillTypeText from '../../../backoffice/modules/pim/sill/SillTypeText';
import SillCard from '../../../backoffice/modules/pim/sill/SillCard';
import {EkoCard} from '../card/EkoCard';
import {EkoCardBody} from '../card/EkoCardBody';

import Loading from '../Loading';
import {SillDetails, SillType} from '../../../modules/api-client/generated';
import {useEkosietAuth0} from '../../../modules/auth0/core/useEkosietAuth0';
import {CategoryProductList} from "../../../backoffice/modules/sm/orders/new-order/components/CategoryProductList";

type SelectedSill = {
	id: string
	title: string
}
type Props = {
	onSillSelected: (sill: SelectedSill) => void
}
const ConfiguratorSillPicker: FC<Props> = ({onSillSelected}) => {
	const {data: sills, isLoading} = useQuery<SillDetails[], TypeError, SillDetails[]>({
		queryKey: ['ApiClient.Pim.Sill.listSills'],
		queryFn: () => ApiClient.Pim.Sill.listSills().then((response: AxiosResponse<SillDetails[]>) => response.data),
	});

	const {isMedewerker} = useEkosietAuth0();
	if (isLoading) return <Loading />;

	if ((!sills || sills.length === 0)) return <div>Geen dorpels gevonden</div>;

	return (
		<>
			{/*<CategoryProductList catalogId={order.catalog.id} priceListId={order.financial.priceList.id}*/}
			{/*					 categoryCode={'dorpel-buitensponning'}/>*/}
			<TypeRow sills={sills} type={SillType.Buitensponning} onSillSelected={onSillSelected} />
			<TypeRow sills={sills} type={SillType.Binnensponning} onSillSelected={onSillSelected} />
			<TypeRow sills={sills} type={SillType.HefSchuif} onSillSelected={onSillSelected} />
			<TypeRow sills={sills} type={SillType.Zijlicht} onSillSelected={onSillSelected} />
			{isMedewerker && <TypeRow sills={sills} type={SillType.Vouwwand} onSillSelected={onSillSelected} />}

		</>
	);
};


interface TypeRowProps {
	type: SillType,
	sills: SillDetails[],
	onSillSelected: (sill: SelectedSill) => void;
}

const TypeRow: FC<TypeRowProps> = ({type, sills, onSillSelected}) => {

	if (type === SillType.Vouwwand) {

		const order = [
			'NEO-BU-A75HEN-114(BH)',
			'NEO-BU-A72HEN-114(OL)',
			'NEO-BI-A75HEN-114(BH)',
			'NEO-BI-A72HEN-114(OL)',
		];

		sills = sills.sort((a, b) => order.indexOf(a.code) - order.indexOf(b.code));
	}


	return <EkoCard className={'bg-light mb-3'} key={type}>
		<EkoCardBody>
			<h3 className={'mb-5'}><SillTypeText code={type} /></h3>
			<div className={'row'}>
				{sills && sills.filter((sill) => sill.type === type).map((sill, index) =>
					<div key={sill.id} className={'col-12 col-md-6 col-xl-3'}>
						<SillCard id={sill.id} code={sill.code} title={sill.name}
								  onClick={() => onSillSelected({id: sill.id, title: sill.name})}
								  description={sill.description ?? undefined} />
					</div>,
				)}
			</div>
		</EkoCardBody>
	</EkoCard>;
};

export {ConfiguratorSillPicker};

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {EkoCard, EkoCardBody, toAbsoluteUrl} from "../../../_metronic/helpers";

type Props = {
    className: string
}
const EkosietUitlegGroeneveldWidget = ({className}: Props) => {
    return (
        <>
            <EkoCard className={className}>
                <EkoCardBody>
                    <div className={'mt-3'}>
                        <h3>Orders importeren</h3>
                        <p>
                            Om het bestellen sneller en minder foutgevoelig te maken hebben wij een data koppeling voor werkvoorbereidingsprogramma's ontwikkeld.
                            Momenteel alleen voor Groeneveld, op termijn volgen koppelingen met andere leveranciers.
                        </p>
                        <p>De werkvoorbereidingspakketten zijn in staat om dorpels te exporteren, wij lezen deze
                            bestanden in en proberen met de informatie uit deze bestanden zo goed mogelijk een order
                            en bij behorende dorpels te genereren.</p>
                        <p>Dat zal niet in alle gevallen lukken, het kan bv zijn dat we de geometrie van een vakindeling niet kunnen matchen van een Ekosiet profiel.
                            In dat geval tonen we een melding en dient u handmatig een profiel te koppelen.
                        Deze koppeling kan worden opgeslagen zodat in toekomstige imports het juiste profiel automatisch wordt gekoppeld.</p>
                        <h4>Hoe werkt het importeren?</h4>
                        <ol>
                            <li>Sleep een Groeneveld file in het upload veld.</li>
                            <li>Kies opties voor het importproces:
                                   Muuraansluiting overnemen: Deze optie bepaald of de muuraansluitingen uit de
                                      file moeten worden overgenomen.
                                        Wil je dit niet, dan hebben alle geimporteerde dorpels rechte muuraansluitignen.
                                                                 </li>
                            <li>Klik op <em>Start import</em>: Het import proces wordt gestart</li>

                            <li>
                                De geimporteerde file wordt verwerkt, voor elke dorpel wordt gekeken of de informatie gematched kan worden door onze software (EDS).
                                Is dit niet geval dan wordt een waarschuwing aangemaakt. Aan het eind van dit proces krijgt u een lijst met waarschuwingen die u
                                dient op te lossen of negeren.
                            </li>
                            <li>
                                Er zijn verschillende type waarschuwing, de meest voorkomende zal de melding zijn dat een profiel niet is herkent.
                                U dient handmatig een EDS profiel te selecteren voor de dorpel en vakindeling in kwestie.
                                Let op: Als u ervoor kiest om de melding te negeren, dan wordt een standaard profiel gekozen.
                            </li>
                            <li>
                                Als je alle waarschuwingen hebt afgehandeld, kunnen in de volgende stap Ekosiet dorpels worden aangemaakt.
                            </li>

                            <li>Voor elke orderregel wordt automatisch een Ekosiet dorpel geconfigureerd op basis van de gegevens uit de importfile. Dit kan een aantal minuten duren.

                            </li>
                            <li>
                                Zodra alle dorpels zijn geconfigureerd, kunt u deze controleren en de order afronden.
                            </li>
                            <li>
                                U wordt vervolgens doorgeleid naar het bestelformulier. De dorpels uit de import zijn toegevoegd. Deze kun je eventueel wijzigen of extra dorpels toevoegen..
                            </li>
                        </ol>
                    </div>
                </EkoCardBody>
            </EkoCard>
        </>
    )
}
export {EkosietUitlegGroeneveldWidget}

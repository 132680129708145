import React from 'react'
import CountUp from "react-countup";

export interface TotalsHeaderProps {
    totalCount: number;
    formattedTotalDuration: string;
    reference: string;
    showCount: boolean;
}

const TotalsHeader: React.FC<TotalsHeaderProps> = ({totalCount,formattedTotalDuration,showCount,reference}) => {
    return (
        <>
            <div className="fs-7 text-center vertical-align-text-top"
            >
                <h3>{showCount ?
                    <CountUp start={Math.max(0, totalCount - 10)} end={totalCount} delay={0}
                             duration={2.25}>
                        {({countUpRef}) => (
                            <div>
                                <span ref={countUpRef}/>
                            </div>
                        )}
                    </CountUp> :
                    formattedTotalDuration
                }
                </h3>
                <div>{reference}</div>
            </div>
        </>
    )
};

export {TotalsHeader}
import React, {FC} from 'react';
import {modalsRoot} from '../../../../global-helpers';
import {Modal} from 'react-bootstrap';
import {Form, Formik} from 'formik';
import EkoFormField from '../../../../shared/components/EkoFormField';
import {useNavigate, useParams} from 'react-router-dom';
import {SillSluitpotArea} from '../../../../modules/api-client/generated';
import Loading from '../../../../shared/components/Loading';
import NotFound from '../../../../shared/components/NotFound';
import SpinnerButton from '../../../../shared/components/SpinnerButton';
import {SluitpotBlockTypePicker} from "../sluitpot-block-type/SluitpotBlockTypePicker";
import {useSillSluitpotAreasQuery} from "./hooks/use-sill-sluitpot-areas-query";
import {useUpdateSillSluitpotAreasMutation} from "./hooks/use-update-sill-sluitpot-areas-mutation";

export interface SillSluitpotAreaEditorModalProps {
	show: boolean;
	onHide: () => void;
	sillId: string;
	sluitpotAreaId?: string;
}

export const SillSluitpotAreaEditorModal: FC<SillSluitpotAreaEditorModalProps> = (props: SillSluitpotAreaEditorModalProps) => {

	const isAdd = !props.sluitpotAreaId;

	const {
		data: sluitpotAreas,
		isInitialLoading,
	} = useSillSluitpotAreasQuery(props.sillId);

	const mutation = useUpdateSillSluitpotAreasMutation(props.sillId);

	if (isInitialLoading) return <Loading />;
	if (!sluitpotAreas) return <NotFound />;

	const sluitpotArea = isAdd ? undefined : sluitpotAreas.find(x => x.id === props.sluitpotAreaId);

	if (!isAdd && !sluitpotArea)
		return <NotFound />;

	const initialValues: SillSluitpotArea = {

		id: props.sluitpotAreaId ?? crypto.randomUUID()!,
		code: sluitpotArea?.code ?? '',
		name: sluitpotArea?.name ?? '',
		angle: sluitpotArea?.angle ?? 0,
		from: sluitpotArea?.from ??0,
		to: sluitpotArea?.to ??0,
		originY: sluitpotArea?.originY ??0,
		originZ: sluitpotArea?.originZ ??0,
		sluitpotBlockTypeId: sluitpotArea?.sluitpotBlockTypeId ??"",
	};

	const onSubmit = async (values: SillSluitpotArea) => {

		if (isAdd) {
			sluitpotAreas.push(values);
		} else {
			Object.assign(sluitpotArea, values);
		}
		await mutation.mutateAsync(sluitpotAreas);

		props.onHide();
	};

	return <Formik initialValues={initialValues} onSubmit={async (values) => onSubmit(values)}>
		{({values, errors}) => (
			<Modal container={modalsRoot}
				   tabIndex={-1}
				   aria-hidden='true'
				   dialogClassName=''
				   size='lg'
				   onHide={props.onHide}
				   show={props.show}
				   animation={false}
				   backdrop='static'>
				<Form>
					<Modal.Header>
						<Modal.Title
							className={'text-nowrap'}>Sluitpotgebied {isAdd ? 'toevoegen' : 'wijzigen'}</Modal.Title>
					</Modal.Header>
					<Modal.Body>

						{/*<EkoFormField row={true} label={'Vakindeling'} field={{*/}
						{/*	name: 'sluitpotAreaTypeId',*/}
						{/*	disabled: !isAdd,*/}
						{/*	component: SluitpotAreaTypePicker({*/}
						{/*		required: true,*/}
						{/*		isMulti: false,*/}
						{/*		optionFilter: sluitpotAreaFilter,*/}
						{/*	}),*/}
						{/*}} />*/}
						<EkoFormField row={true} label={'Code'} field={{
							type: 'text',
							name: 'code',
							className: 'form-control',
							required: true,
						}} />

						<EkoFormField row={true} label={'Naam'} field={{
							type: 'text',
							name: 'name',
							className: 'form-control',
							required: true,
						}} />

						<EkoFormField row={true} label={'Vanaf'} field={{
							type: 'text',
							name: 'from',
							className: 'form-control',
							required: true,
						}} />
						<EkoFormField row={true} label={'Tot'} field={{
							type: 'text',
							name: 'to',
							className: 'form-control',
							required: true,
						}} />
						<EkoFormField row={true} label={'Basis-Y'} field={{
							type: 'text',
							name: 'originY',
							className: 'form-control',
							required: true,
						}} />
						<EkoFormField row={true} label={'Basis-Z'} field={{
							type: 'text',
							name: 'originZ',
							className: 'form-control',
							required: true,
						}} />
						<EkoFormField row={true} label={'Hoek'} field={{
							type: 'text',
							name: 'angle',
							className: 'form-control',
							required: true,
						}} />
						<div className="row mb-4">
							<div className="col-4">
								<label className="form-label" htmlFor="type">
									Type
								</label>
							</div>
							<div className="col-8">
								<SluitpotBlockTypePicker name={'sluitpotBlockTypeId'} required/>
							</div>
						</div>

					</Modal.Body>
					<Modal.Footer>
						<button type='button' className='btn btn-link me-5' onClick={() => props.onHide()}>Annuleren
						</button>
						<SpinnerButton type='submit' className='btn btn-primary'
									   spinning={mutation.isLoading}>{isAdd ? 'Toevoegen' : 'Wijzigen'}</SpinnerButton>
					</Modal.Footer>
				</Form>
			</Modal>
		)}
	</Formik>;
};

export const RoutableSillSluitpotAreaEditorModal: FC = (props) => {

	const {id, sluitpotAreaId} = useParams();

	// const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const onHide = () => {
		// if (searchParams.has('returnUrl')) {
		// 	// navigate({
		// 	// 	pathnam
		// 	// });
		// } else {
		navigate(-1);
		// }
	};

	return <SillSluitpotAreaEditorModal sillId={id!}
											 sluitpotAreaId={sluitpotAreaId} show={true}
											 onHide={onHide} />;
};
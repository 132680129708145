import React from "react";
import {Navigate, Outlet, Route, Routes} from "react-router-dom";
import {ErrorBoundary, FallbackProps} from "react-error-boundary";
import axios from "axios";
import {BackofficeRoutePath} from "../../RoutePath";
import ExpeditionList from "./pages/ExpeditionList";
import ExpeditionPlanning from "./pages/ExpeditionPlanning";
import ExpeditionWeekPlanning from "./pages/ExpeditionWeekPlanning";
import ExpeditionPlanningTransporteur from "./pages/TransportPlanning";
import TransportWeekPlanning from "./pages/TransportWeekPlanning";
import Expedition from "./pages/Expedition";
import ExpeditionByProductionItem from "./pages/ExpeditionByProductionItem";

export const EmRoutePath = {

    expeditionList: 'expeditions',    
    planningTransporteur: 'transport',
    planningTransporteurWeek: 'transport/:year/:week',
    planning: 'planning',
    planningWeek: 'planning/:year/:week',
    expedition: 'expedition/:id',
    expeditionWithBarcode: 'expedition/:id/:barcode',
    expeditionByProductionItem: 'expedition/production-item/:barcode',
    link(path:string){
        return BackofficeRoutePath.em+path;
    },
    expeditionLink(id:string){
        return EmRoutePath.expedition.replace(':id', id);
    },
    expeditionWithBarcodeLink(id:string,barcode:string){
        return EmRoutePath.expeditionWithBarcode.replace(':id', id).replace(':barcode',barcode);
    },
    expeditionByProductionItemLink(barcode:string){
        return EmRoutePath.expeditionByProductionItem.replace(':barcode', barcode);
    },
    planningWeekLink: (year: number, week: number) => {
		return  EmRoutePath.planningWeek.replace(':year', year.toString()).replace(':week', week.toString());
	},
    planningTransportWeekLink: (year: number, week: number) => {
        return  EmRoutePath.planningTransporteurWeek.replace(':year', year.toString()).replace(':week', week.toString());
    }
};

const EmRoutes: React.FC = () => {

    return (
        <Routes>
            <Route element={<ErrorBoundary FallbackComponent={EmFallback}><Outlet/></ErrorBoundary>}>
                <Route path={EmRoutePath.expeditionList} element={<ExpeditionList />}/>
                {productionRoutes}
                {transporterRoutes}
            </Route>
            <Route index element={<Navigate to={EmRoutePath.expeditionList}/>}/>
        </Routes>
    );
}

export const EmProductionRoutes = () => {
    return (
        <Routes>
            {productionRoutes}
        </Routes>
    )
}
export const EmTransporterRoutes = () => {
    return (
        <Routes>
            {transporterRoutes}
        </Routes>
    )
}
const EmFallback: React.FC<FallbackProps> = (props) => {

    if (axios.isAxiosError(props.error)) {
        if (props.error.response?.status === 404) {
            return <>Not found</>;
        }
        if (props.error.response?.status === 500) {
            return <>Server error</>;
        }
    }

    return <>Error</>;
};

const productionRoutes =
    <>
        <Route element={<ErrorBoundary FallbackComponent={EmFallback}><Outlet /></ErrorBoundary>}>
            <Route path={EmRoutePath.planningWeek} element={<ExpeditionWeekPlanning />} />
            <Route path={EmRoutePath.planning} element={<ExpeditionPlanning />}/>
            <Route path={EmRoutePath.expedition} element={<Expedition />}/>
            <Route path={EmRoutePath.expeditionWithBarcode} element={<Expedition />}/>
            <Route path={EmRoutePath.expeditionByProductionItem} element={<ExpeditionByProductionItem />}/>
        </Route>
        <Route index element={<Navigate to={EmRoutePath.link(EmRoutePath.planningWeek)} />} />
        <Route path='*' element={<Navigate to='/error/404' />} />
    </>

const transporterRoutes =
    <>
        <Route element={<ErrorBoundary FallbackComponent={EmFallback}><Outlet /></ErrorBoundary>}>
            <Route path={EmRoutePath.planningTransporteurWeek} element={<TransportWeekPlanning />} />
            <Route path={EmRoutePath.planningTransporteur} element={<ExpeditionPlanningTransporteur />} />
        </Route>
        <Route index element={<Navigate to={EmRoutePath.link(EmRoutePath.planningTransporteur)} />} />
        <Route path='*' element={<Navigate to='/error/404' />} />
    </>

export default EmRoutes;
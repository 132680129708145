import {Button, Modal} from 'react-bootstrap';
import React, {FC} from 'react';
import SpinnerButton from '../SpinnerButton';
import {modalsRoot} from '../../../global-helpers';
import {SluitpotEditor, SluitpotEditorProps} from './SluitpotEditor';

type SluitpotModalProps = {
	show: boolean;
	title?: string;
	confirmBtnText?: string;
	handleClose: () => void;
	editorOptions: SluitpotEditorProps;
};

export const SluitpotModal: FC<SluitpotModalProps> = (props) => {
	return (
		<Modal
			container={modalsRoot}
			tabIndex={-1}
			aria-hidden="true"
			autoFocus={true}
			className=""
			dialogClassName={'modal-dialog-centered'}
			contentClassName={'shadow-lg'}
			show={props.show}
			size="lg"
			onHide={() => props.handleClose()}
			animation={false}
			backdrop={'static'}
		>
			<SluitpotModalContent {...props}></SluitpotModalContent>
		</Modal>
	);
};

const SluitpotModalContent: FC<SluitpotModalProps> = (props) => {
	return (
		<SluitpotEditor {...props.editorOptions}>
			{({submit, isSubmitting, isInitializing, content}) => (
				<>
					<Modal.Header closeButton>
						<Modal.Title>{props.title ?? 'Sluitpot'}</Modal.Title>
					</Modal.Header>
					<Modal.Body className="flex-fill">{content}</Modal.Body>
					<Modal.Footer>
						<Button variant="link" className="mx-4" onClick={props.handleClose} disabled={isInitializing}>
							Annuleren
						</Button>
						<SpinnerButton type="submit" className="btn btn-primary" spinning={isSubmitting} disabled={isInitializing}>
							{props.confirmBtnText ?? 'Toepassen'}
						</SpinnerButton>
					</Modal.Footer>
				</>
			)}
		</SluitpotEditor>
	);
};

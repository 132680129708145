import React, { FC, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import buildQuery from 'odata-query'
import { useSearchParams } from "react-router-dom";

export interface FilterParameter {
    filterName: string,
    filterExpression: any
    values: any[]
}

export interface FilterContextStore {
    filters: FilterParameter[],
    setFilters: (filterName: string, filters: FilterParameter[]) => void;
    containsFilter: (filterName: string) => boolean;
    clear: () => void;
    filterQuery: string;
    resetCount: Number;
    reset: () => void;
}

export const FilterContext = React.createContext({} as FilterContextStore);

export const useEkosietFilter = () => useContext(FilterContext);

export type FilterContextProviderProps = {
    children?: ReactNode
};

export const build = (filters: FilterParameter[]) => {
    const filter = {
        and: filters.map(v => { return v.filterExpression })
    };
    const filterQueryString = buildQuery({ filter })

    const filterQuery = filterQueryString.substring(9, filterQueryString.length);

    return filterQuery;
};

export const FilterContextProvider: FC<FilterContextProviderProps> = ({ children }) => {

    const [filters, setFilterState] = useState<FilterParameter[]>([]);
    const [resetCount, setResetCount] = useState<number>(0);
    const [filterQuery, setFilterQuery] = useState<string>("");
    const [searchParams, setSearchParams] = useSearchParams()

    const clear = useCallback(() => {
        setFilterState([]);
    }, [setFilterState]);

    const setFilters = useCallback((filterName: string, filters: FilterParameter[]) => {
        setFilterState(values => {
            let newFilters = values.filter(item => item.filterName !== filterName);
            return [...newFilters, ...filters]
        });
    }, [setFilterState]);

    const containsFilter = useCallback((filterName: string) => {
        return filters.some(filter => filter.filterName === filterName);
    }, [filters]);

    const reset = useCallback(() => {
        clear();
        setResetCount(values => values + 1);
    }, [clear, setResetCount]);

    const store: FilterContextStore = {
        filters,
        setFilters,
        containsFilter,
        clear,
        filterQuery,
        resetCount,
        reset
    };

    useEffect(() => {
        var newFilterQuery = build(filters)
        setFilterQuery(newFilterQuery)
    }, [filters])

    //todo pagination needs to be cleaner integrated withing the filters.
    useEffect(() => {
        if (searchParams.has('page')) {
            searchParams.delete('page');
            setSearchParams(searchParams);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])

    return (
        <FilterContext.Provider value={store}>
            {children}
        </FilterContext.Provider>
    );
}
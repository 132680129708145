import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast"
import ApiClient from "../../../../../modules/api-client/ApiClient";
import {
    OrderRemarkRepresentation,
} from "../../../../../modules/api-client/generated";
import { useEkosietMutation } from "../../../../../shared/hooks/useEkosietMutation";
import { NotFound, ValidationFailed } from "../../../../../modules/api-client/Responses";

export const useDeleteRemark = () => {

    const queryClient = useQueryClient()

    return useEkosietMutation<OrderRemarkRepresentation, NotFound | ValidationFailed, string>
        ((id: string) => {
            return ApiClient.Sm.Remark.smOrderRemarkIdDelete(id, undefined, undefined).then(response => response.data);
        },
            {
                onSuccess: (order) => {
                    queryClient.invalidateQueries(['smOrderIdGet', order.orderId]).then();
                    queryClient.invalidateQueries(['OrderActionLog', order.orderId]).then();

                    toast.success("Opmerking succesvol verwijderd.");

                },
                onError: () => {
                    toast.error("Opmerking verwijderen mislukt!");
                }
            }
        );
}
    ;

import clsx from 'clsx';
import { FC } from 'react'
import {useFormikContext, getIn} from "formik";
import Select from "react-select"
import {DeliveryConditions} from "../../modules/api-client/generated";

type Props= {    
  fieldName: string;
}

export const CustomerDeliveryConditionsPicker : FC<Props> = (props: Props) => {

  const formik = useFormikContext();

  let options: any[] = [
    { value: DeliveryConditions.AfFabriek, label: 'Af fabriek'},
    { value: DeliveryConditions.Bezorging, label: 'Bezorging'}
  ]

  let selectedValue = options.find((option: any) => option.value === getIn(formik.values, props.fieldName)) || options[0];

  return (
    <>
        <div className='fv-row mb-7'>
          <div>
            <Select
                className={clsx(
                  'mb-3 mb-lg-0',
                  {'is-invalid': getIn(formik.touched, props.fieldName) && getIn(formik.errors, props.fieldName)},
                  {'is-valid': getIn(formik.touched, props.fieldName) && !getIn(formik.errors, props.fieldName)}
                )}
                classNamePrefix="select"
                value={selectedValue}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 7,
                    padding: 5,
                })}
                classNames={{
                    control: (state) =>
                        clsx(
                            'form-control form-control p-2 border-secondary',
                        ),
                    singleValue: (state) =>
                    {
                        return clsx(
                            'text-gray-700',
                        )
                    }
                }}
                isClearable={false}
                isSearchable={false}
                name={props.fieldName}
                options={options}
                onChange={(newValue) => {
                  formik.setFieldValue(props.fieldName, newValue?.value)
                  formik.validateForm();
                }}
              />
          </div>

          {getIn(formik.touched, props.fieldName) && getIn(formik.errors, props.fieldName) && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{getIn(formik.errors, props.fieldName)}</span>
              </div>
            </div>
          )}

        </div>
    </>
  )
}

import {Query, useQuery} from "@tanstack/react-query";
import ApiClient from "../../../../../modules/api-client/ApiClient";
import { OrderRepresentation } from "../../../../../modules/api-client/generated";
import {AxiosError} from "axios";
import {toast} from "react-hot-toast";
import { isNotEmpty } from "../../../../../../_metronic/helpers";

export const useGetOrder = (id: string | undefined, refetchOnMount: boolean = false, enabled: boolean = true, refetchInterval: number | false | ((data: OrderRepresentation | undefined, query: Query<OrderRepresentation, AxiosError, OrderRepresentation>) => number | false) = false) => {




    const response =  useQuery<OrderRepresentation,AxiosError,OrderRepresentation>(['smOrderIdGet', id],
        () => {
            return ApiClient.Sm.Order.smOrderIdGet(id!).then((res) => res.data);
        }, {
            useErrorBoundary: true,
            enabled: enabled && isNotEmpty(id),
            refetchOnWindowFocus: false,
            refetchOnMount: refetchOnMount,
            refetchInterval: refetchInterval
        });

    if (response.error) {
        var error = response.error;
        toast.error(error?.message);
        // toast.error(error?.response?.data?.title || 'Onbekende fout!');
    } 
    
    return response;       
};

import {RoleEditModalForm} from './RoleEditModalForm'
import {useListView} from '../core/ListViewProvider'
import {useAuth0RolesData} from '../core/QueryResponseProvider'
import {Role} from '../core/_models'

const RolesEditModalFormWrapper = () => {
  const {itemIdForUpdate} = useListView()
  const roles = useAuth0RolesData()

  if (!itemIdForUpdate) {
    return <RoleEditModalForm role={{id: undefined}} />
  }

  const role = roles.find((item: Role) => item.id === itemIdForUpdate)
  if (role) {
    return <RoleEditModalForm role={role} />
  }

  return null
}

export {RolesEditModalFormWrapper}

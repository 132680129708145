import {useListView} from '../users-list/core/ListViewProvider'
import {UsersTable} from '../users-list/table/UsersTable'
import {UserEditModal} from '../users-list/user-edit-modal/UserEditModal'
import {UserWarningModal} from '../users-list/user-warning-modal/UserWarningModal'
import {UserAlert} from '../users-list/components/loading/UserAlert'
import {useEffect} from 'react'
import {QueryRequestProvider} from "../users-list/core/QueryRequestProvider";
import {QueryResponseProvider} from "../users-list/core/QueryResponseProvider";
import {ListViewProvider} from "../users-list/core/ListViewProvider";
import {PageTitle} from "../../../../../_metronic/layout/core";
import {BackofficeRoutePath} from "../../../RoutePath";
import AbsLink from "../../../../shared/components/RouteHelpers";
import {Link} from "react-router-dom";
import {UmRoutePath} from "../UmRoutes";

export const UserList = () => {
  const {itemIdForUpdate, itemForWarning, Alert, setAlert,selected} = useListView()

  useEffect(() => {
    if (Alert.isVisible) {
      setTimeout(() => {
        setAlert({isVisible: !Alert.isVisible, message: '', title: '', status: ''})
      }, 5000)
    }
  }, [Alert, setAlert])

  return (
    <>
      <PageTitle>Gebruikers</PageTitle>

        <Link to={AbsLink(BackofficeRoutePath.um,UmRoutePath.rolesList)} className={'btn btn-sm btn-primary mb-3'}>Rollen</Link>

        <UsersTable />

      {itemIdForUpdate !== undefined && <UserEditModal />}
      {itemForWarning?.Id !== undefined && <UserWarningModal />}
      {Alert.isVisible === true ? (
        <UserAlert title={Alert.title} message={Alert.message} status={Alert.status} />
      ) : (
        <></>
      )}

    </>
  )
}

const UserListWrapper = () => (
    <QueryRequestProvider>
      <QueryResponseProvider>
        <ListViewProvider>
          <UserList />
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
)

export {UserListWrapper}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {PageTitle} from "../../../../../_metronic/layout/core";
import {EkoCard} from "../../../../shared/components/card/EkoCard";
import {EkoCardBody} from "../../../../shared/components/card/EkoCardBody";
import {EkoCardHeader} from "../../../../shared/components/card/EkoCardHeader";
import {toAbsoluteUrl} from "../../../../../_metronic/helpers";
import {ExpeditionStats} from "../../pm/components/ExpeditionStats";
import {YTDExpeditionStatsTile} from "../../sm/components/YTDExpeditionStatsTile";
import {LevertijdStats} from "../../em/components/LevertijdStats";

export function MgtExpedition() {
    return (
        <>
            <PageTitle>Expedition</PageTitle>
            <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
                {/* begin::Col */}
                <div className='col col-lg-8 mb-md-5 mb-xl-10'>
                    <EkoCard>
                        <EkoCardHeader title={'Wekelijkse expeditie'}>
                        </EkoCardHeader>
                        <EkoCardBody>
                            <ExpeditionStats/>
                        </EkoCardBody>
                    </EkoCard>
                </div>
                <div className='col col-lg-4 mb-md-5 mb-xl-10'>
                    <YTDExpeditionStatsTile
                        className='mb-5 mb-xl-10'
                        description='Dit jaar'
                        color='#F1416C'
                        img={toAbsoluteUrl('/media/patterns/vector-1.png')}/>
                    <EkoCard className={'mt-4'}>
                        <EkoCardHeader title={'Levertijd'}>
                        </EkoCardHeader>
                        <EkoCardBody>
                            <LevertijdStats/>
                        </EkoCardBody>
                    </EkoCard>
                </div>
            </div>
        </>
    )
}

import React from 'react';
import ApiClient from '../../../../modules/api-client/ApiClient';
import Loading from '../../../../shared/components/Loading';
import {AxiosResponse} from "axios";

export const RenderCustomerPriceList: React.FC<{ customerId: string }> = ({customerId}) => {
    const [post, setPost] = React.useState<string>();
    React.useEffect(() => {
        if (!post) {

            ApiClient.Crm.Customer.crmCustomerCustomerIdPricelistPdfGet(customerId, undefined, {responseType: "blob"})
                .then((d: AxiosResponse<File>) => {
                    const file = new Blob(
                        [d.data],
                        {type: 'application/pdf'});
                    setPost(URL.createObjectURL(file));
                });
        }
    })
    if (!post) return <Loading/>;
    return (
            <object width="100%" height="1000" data={post} type="application/pdf">Document</object>
    )

};
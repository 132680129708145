/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from "../../../../shared/components/KTSVG";
import {useQuery} from "@tanstack/react-query";
import {NumberOfSubscribers, WeeklyProductionStatsRow} from "../../../../modules/api-client/generated";
import ApiClient from "../../../../modules/api-client/ApiClient";
import Loading from "../../../../shared/components/Loading";
import {DateTime} from "luxon";
import {NumericFormat} from "react-number-format";
import { MasterdataRoutePath } from '../../masterdata/MasterdataRoutes';

type Props = {
    className: string
    color: string
}

const DashboardWidget: React.FC<Props> = ({className, color}) => {

    const {data} = useQuery<{ [key: string]: { [key: string]: WeeklyProductionStatsRow; }; }>(
        ['weeklyProductionStats'],
        () => ApiClient.Pm.Reporting.weeklyProductionStats().then((res) => res.data));
    const {data: numberOfSubscribersLast7Days} = useQuery<NumberOfSubscribers>(
        ['numberOfSubscribersLast7DaysGet'],
        () => ApiClient.Crm.Newsletter.getNewsletterSubscribersLast7Days().then((res) => res.data));
    const {data: numberOfUnhandledContactForms} = useQuery<NumberOfSubscribers>(
        ['numberOfUnhandledContactForms'],
        () => ApiClient.Crm.ContactForm.getNumberOfUnhandledContactForms().then((res) => res.data));
    if (!data || !numberOfSubscribersLast7Days || !numberOfUnhandledContactForms) return <Loading/>

    const dt = DateTime.utc();
    const currentYear = dt.year.toString()
    const currentWeek = dt.weekNumber.toString()


    return (
        <div className={`card ${className}`}>
            <div className='card-body p-0'>
                <div className={`px-9 pt-7 card-rounded h-100px w-100 bg-${color}`}>
                    <div className='d-flex flex-stack'>
                        <h3 className='m-0 text-white fw-bold fs-3'>Voortgang huidige week</h3>
                        <div className='ms-1'>
                        </div>
                    </div>
                </div>
                <div
                    className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-body'
                    style={{marginTop: '-40px'}}
                >
                    <div className='d-flex align-items-center mb-6'>
                        <div className='symbol symbol-45px w-40px me-5'>
                            <span className='symbol-label bg-lighten'>
                                <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-1'/>
                            </span>
                        </div>
                        <div className='d-flex align-items-center flex-wrap w-100'>
                            <div className='mb-1 pe-3 flex-grow-1'>
                                <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                                    Order intake
                                </a>
                                <div className='text-gray-400 fw-semibold fs-7'>Alle regio's</div>
                            </div>
                            <div className='d-flex align-items-center'>
                                <div className='fw-bold fs-5 text-gray-800 pe-1'>Op schema</div>
                                <div className={'bullet bullet-dot h-15px w-15px bg-success'}></div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex align-items-center mb-6'>
                        <div className='symbol symbol-45px w-40px me-5'>
                            <span className='symbol-label bg-lighten'>
                                <KTSVG path='/media/icons/duotune/art/art002.svg' className='svg-icon-1'/>
                            </span>
                        </div>
                        <div className='d-flex align-items-center flex-wrap w-100'>
                            <div className='mb-1 pe-3 flex-grow-1'>
                                <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                                    Productie
                                </a>
                                <div className='text-gray-400 fw-semibold fs-7'>Inclusief spoed items</div>
                            </div>
                            <div className='d-flex align-items-center'>
                                <div className='fw-bold fs-5 text-gray-800 pe-1'>
                                  {data[currentYear][currentWeek]?.geproduceerdeDorpels} dorpels
                                </div>
                                <KTSVG
                                    path='/media/icons/duotune/arrows/arr066.svg'
                                    className='svg-icon-5 svg-icon-success ms-1'
                                />
                            </div>
                        </div>
                    </div>

                    <div className='d-flex align-items-center mb-6'>
                        <div className='symbol symbol-45px w-40px me-5'>
                            <span className='symbol-label bg-lighten'>
                                <KTSVG path='/media/icons/duotune/general/gen014.svg' className='svg-icon-1'/>
                            </span>
                        </div>
                        <div className='d-flex align-items-center flex-wrap w-100'>
                            <div className='mb-1 pe-3 flex-grow-1'>
                                <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                                    Doorlooptijd
                                </a>
                                <div className='text-gray-400 fw-semibold fs-7'>Voor reguliere orders</div>
                            </div>
                            <div className='d-flex align-items-center'>
                                <div className='fw-bold fs-5 text-gray-800 pe-1'><NumericFormat
                                    className={'me-1 text-nowrap'} decimalScale={2}
                                    value={data[currentYear][currentWeek]?.doorlooptijd} displayType={'text'}
                                    thousandSeparator={true} suffix={' dagen'}/></div>
                                <KTSVG
                                    path='/media/icons/duotune/arrows/arr065.svg'
                                    className='svg-icon-5 svg-icon-success ms-1'
                                />
                            </div>
                        </div>
                    </div>

                    <div className='d-flex align-items-center mb-6'>
                        <div className='symbol symbol-45px w-40px me-5'>
                            <span className='symbol-label bg-lighten'>
                                <KTSVG path='/media/icons/duotune/communication/com005.svg' className='svg-icon-1'/>
                            </span>
                        </div>
                        <div className='d-flex align-items-center flex-wrap w-100'>
                            <div className='mb-1 pe-3 flex-grow-1'>
                                <a href={MasterdataRoutePath.link(MasterdataRoutePath.newsletterSubscribers)}
                                   className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                                    Nieuwsbrief inschrijvingen
                                </a>
                                <div className='text-gray-400 fw-semibold fs-7'>In de laatste zeven dagen</div>
                            </div>
                        </div>
                        <div className='d-flex align-items-center'>
                            <div className='fw-bold fs-5 text-gray-800 pe-1'><NumericFormat
                                className={'me-1 text-nowrap'} decimalScale={0}
                                value= {numberOfSubscribersLast7Days.number} displayType={'text'}
                                thousandSeparator={true} suffix={''}/></div>
                        </div>
                    </div>

                    <div className='d-flex align-items-center'>
                        <div className='symbol symbol-45px w-40px me-5'>
                            <span className='symbol-label bg-lighten'>
                                <KTSVG path='/media/icons/duotune/communication/com005.svg' className='svg-icon-1'/>
                            </span>
                        </div>
                        <div className='d-flex align-items-center flex-wrap w-100'>
                            <div className='mb-1 pe-3 flex-grow-1'>
                                <a href={MasterdataRoutePath.link(MasterdataRoutePath.contactForms)}
                                   className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                                    {numberOfUnhandledContactForms.number === 1
                                        ? `Af te handelen contactformulier`
                                        : `Af te handelen contactformulieren`}
                                </a>
                            </div>
                        </div>
                        <div className='d-flex align-items-center'>
                            <div className='fw-bold fs-5 text-gray-800 pe-1'><NumericFormat
                                className={'me-1 text-nowrap'} decimalScale={0}
                                value={numberOfUnhandledContactForms.number} displayType={'text'}
                                thousandSeparator={true} suffix={''}/></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {DashboardWidget}

import React from 'react';
import {	
	ConfiguratorAssemblyOptions, 
	SillAssemblyOption,
	SillAssemblyWrapOption,
} from '../../../../modules/api-client/generated';

export interface SillAssemblyOptionsDetailsProps {
	assemblyOptions: ConfiguratorAssemblyOptions;
}

export interface SillAssemblyOptionDetailsProps {
	assemblyOption: SillAssemblyOption;
}

export const SillAssemblyOptionDetails: React.FC<SillAssemblyOptionDetailsProps> = (props: SillAssemblyOptionDetailsProps) => {
	return <>
		<div className='row mb-4'>
			<div className='col-2'>
				<label className='form-label'>Inpakken</label>
			</div>
			<div className='col'>
				{props.assemblyOption.wrap === SillAssemblyWrapOption.Yes
					? 'Ja'
					: props.assemblyOption.wrap === SillAssemblyWrapOption.No
						? 'Nee'
						: 'Optioneel'}
			</div>
		</div>
		<div className='row mb-4'>
			<div className='col-2'>
				<label className='form-label'>Bevestigingsopties</label>
			</div>
			<div className='col'>
				<ul className='list-unstyled'>
					{props.assemblyOption.mountingOptions.map((x, index) => <li key={index}>{x.mountingOptionId}</li>)}
				</ul>
			</div>
		</div>
	</>;
};
import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { toast } from "react-hot-toast"
import ApiClient from "../../../../../modules/api-client/ApiClient";
import { ExpeditionListViewArrayODataValue } from "../../../../../modules/api-client/generated";
import { AxiosError } from "axios";

export const useListExpeditionsOdata = ($top?: number | undefined, $skip?: number | undefined, $orderBy?: string, $filter?: string, $count: boolean | undefined = false, enabled: boolean | undefined = true): 
    UseQueryResult<ExpeditionListViewArrayODataValue, unknown> => {

    if ($filter === "") {
        $filter = undefined
    }
    
    const key = 'emExpeditionListOdataGet';

    const response = useQuery(
        [key, $filter, $top, $skip, $count, $orderBy], () => {
        return ApiClient.Em.Expedition
            .emExpeditionListOdataGet($top, $skip, $orderBy, $filter, $count, undefined, undefined)
            .then(d => d.data);
    },
        { enabled: enabled }
    )

    if (response.error) {
        var error = response.error as AxiosError;
        toast.error(error?.message);
        // toast.error(error?.response?.data?.title || 'Onbekende fout!');
    }
    return response;
};

import React, {useMemo, useState} from "react";
import {SillConfigurationRenovationRabbetStep} from "../../../../modules/api-client/generated";
import {Modal} from "react-bootstrap";
import {modalsRoot} from "../../../../global-helpers";
import {Form, Formik, useFormikContext} from "formik";


type RenovationRabbetDialogOptions = {
    onChange: (value: SillConfigurationRenovationRabbetStep) => Promise<void>,
    title?: string,
    value?: SillConfigurationRenovationRabbetStep,
}

export function useRenovationRabbetDialog(props: { wallConnectionWidth: number }) {

    const [options, setOptions] = useState<RenovationRabbetDialogOptions | null>(null);
    const [error, setError] = useState<Error | null>(null);

    const open = (options: RenovationRabbetDialogOptions): void => {
        setOptions(options);
    };

    const onConfirm = async (value: SillConfigurationRenovationRabbetStep) => {

        try {
            await options!.onChange(value);
            close();
        } catch (error) {
            console.log('catching error');
            setError(error as Error);
        }
    };

    const onCancel = () => {
        close();
    };

    const close = () => {
        setOptions(null);
        setError(null);
    };

    const initialValues: SillConfigurationRenovationRabbetStep = options?.value ?? {
        width: undefined!,
        depth: undefined!
    };


    const component = useMemo(() => !options
            ? null
            : <Modal
                container={modalsRoot}
                show
                onHide={() => onCancel()}
                // tabIndex={-1}
                aria-hidden='true'
                autoFocus={true}
                contentClassName={'shadow-lg'}
                animation={false}
                backdrop={'static'}
            >
                <Formik initialValues={initialValues} onSubmit={onConfirm}>
                    <Form>
                        <Modal.Header>
                            <Modal.Title>{options?.title ?? 'Renovatiesponning'}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {error && <div className="alert alert-danger">{error.message}</div>}
                            <RenovationRabbetStepEditor wallConnectionWidth={props.wallConnectionWidth}/>
                        </Modal.Body>
                        <Modal.Footer className="d-flex justify-content-start flex-row-reverse gap-3">
                            <button type="submit" className="btn btn-primary">
                                {options.value ? 'Wijzigen' : 'Toevoegen'}
                            </button>
                            <button type="button" className="btn btn-link" onClick={() => onCancel()}>
                                Annuleren
                            </button>
                        </Modal.Footer>
                    </Form>
                </Formik>
            </Modal>
        , [options, error, props.wallConnectionWidth])

    return {
        open,
        component
    }
}

const RenovationRabbetStepEditor: React.FC<{ wallConnectionWidth: number }> = (props) => {

    const {values, handleChange} = useFormikContext<SillConfigurationRenovationRabbetStep>();

    return <>
        <div className="d-grid gap-3">
            <div>
                <label className='form-label'><small className='text-uppercase text-muted'>Afmeting</small> <small
                    className='text-muted'>(BxD)</small></label>

                <div className="row">
                    <div className="col">
                        <div className="input-group">
                            <input type="number" className={'form-control'} placeholder={'Breedte'}
                                   step="0.1"
                                   min={"0.1"}
                                   max={props.wallConnectionWidth - 0.1}
                                   required
                                   autoFocus
                                   name="width"
                                   value={values.width ?? ''} onChange={handleChange}/>
                        </div>
                    </div>
                    <div className="col">
                        <div className="input-group">
                            <input type={'number'} className={'form-control'} placeholder={'Diepte'}
                                   step="0.1"
                                   min={"0.1"}
                                   required
                                   name="depth"
                                   value={values.depth ?? ''} onChange={handleChange}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
};

import { useQuery } from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import {
    ProductionOperationRepresentation
} from "../../../../modules/api-client/generated";

export const useProductionOperations = () => {
    return useQuery<ProductionOperationRepresentation[]>(
        ['productionOperationGetList'],
        () => ApiClient.Pm.ProductionOperation.getProductionOperationsList().then((res) => res.data)
    );
}

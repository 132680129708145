import {useQueryClient} from "@tanstack/react-query";
import {toast} from "react-hot-toast"
import ApiClient from "../../../../../modules/api-client/ApiClient";
import {    
    OrderRepresentation, 
    PutExpeditionDateRepresentation
} from "../../../../../modules/api-client/generated";
import {useEkosietMutation} from "../../../../../shared/hooks/useEkosietMutation";
import {NotFound, ValidationFailed} from "../../../../../modules/api-client/Responses";

export const usePutExpeditionDate = (id: string) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<OrderRepresentation, NotFound | ValidationFailed, PutExpeditionDateRepresentation>(
        (
            model: PutExpeditionDateRepresentation) => {
            return ApiClient.Sm.Order.smOrderIdExpeditionPreferredDatePut(id, undefined, model).then(response => response.data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['smOrderIdGet', id]).then();
                queryClient.invalidateQueries(['OrderActionLog', id]).then();
                toast.success("Expeditiedatum aangepast.");

            },
            onError: () => {
                toast.error("Expeditiedatum wijzigen mislukt!");
            }
        }
    );
};

import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast"
import ApiClient from "../../../../../modules/api-client/ApiClient";
import {
    PutWizardOrderRemarkRepresentation,
} from "../../../../../modules/api-client/generated";
import { useEkosietMutation } from "../../../../../shared/hooks/useEkosietMutation";
import { NotFound, ValidationFailed } from "../../../../../modules/api-client/Responses";

export const usePutOrderPlaced = (id: string) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<void, NotFound | ValidationFailed, PutWizardOrderRemarkRepresentation | undefined>((remark) => {        
        if (remark) {
            return ApiClient.Sm.Order.smOrderIdPlaceWithRemarkPut(id, undefined, remark).then();
        } else {
            return ApiClient.Sm.Order.smOrderIdPlacePut(id, undefined).then();
        }
    },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['smOrderIdGet', id]).then();
                queryClient.invalidateQueries(['OrderActionLog', id]).then();

                toast.success("Order geplaatst");
            },
            onError: () => {
                toast.error("Order plaatsen mislukt!");
            }
        }
    );
};

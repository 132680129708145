import React from 'react';
import { Outlet, Route, Routes} from 'react-router-dom';
import {ErrorBoundary, FallbackProps} from 'react-error-boundary';
import axios from 'axios';
import {BackofficeRoutePath} from '../../RoutePath';
import {ConfiguredSillPage} from './page/ConfiguredSillPage';

export const CoreRoutePath = {

	sill: 'sill/:id',

	link(path: string) {
		return BackofficeRoutePath.core + path;
	},
};

export const CoreRoutes: React.FC = () => {

	return (
		<Routes>
			<Route element={<ErrorBoundary FallbackComponent={Fallback}><Outlet /></ErrorBoundary>}>
				<Route path={CoreRoutePath.sill} element={<ConfiguredSillPage />} />
			</Route>
		</Routes>
	);
};

const Fallback: React.FC<FallbackProps> = (props) => {

	if (axios.isAxiosError(props.error)) {
		if (props.error.response?.status === 404) {
			return <>Not found</>;
		}
		if (props.error.response?.status === 500) {
			return <>Server error</>;
		}
	}

	return <>Error</>;
};


/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import { NewOrderCustomerForm } from './NewOrderCustomerForm'


type Props = {
  show: boolean,
  handleClose: () => void,
}

const modalsRoot = document.getElementById('root-modals') || document.body

const NewOrderCustomerModal = ({show, handleClose}: Props) => {
    
  return createPortal(
    <Modal
      id='kt_add_new_order_for_customer_modal'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-lg'
      show={show}
      onHide={handleClose}
      animation={true}      
      backdrop={true}
    >
        <Modal.Header closeButton>
          <Modal.Title>Nieuwe order aanmaken voor klant</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NewOrderCustomerForm handleClose={handleClose} />
        </Modal.Body>      
    </Modal>,
    modalsRoot
  )
}

export {NewOrderCustomerModal}

import React from 'react';
import {Field, useField} from 'formik';
import {SluitpotBlockType} from '../../../../modules/api-client/generated';
import {useQuery} from '@tanstack/react-query';
import ApiClient from '../../../../modules/api-client/ApiClient';
import CustomSelect, {OptionsType, SelectOption} from '../../../../shared/components/CustomSelect';

type Props = {
	name: string;
	multi?: boolean;
	required?: boolean;
};

export const SluitpotBlockTypePicker: React.FC<Props> = ({name, multi, required = false, ...props}) => {
	const [field, meta] = useField(name);

	let options: OptionsType<SelectOption> = [];

	const {data: list} = useQuery<SluitpotBlockType[]>(['pimSluitpotBlockTypeGet'], () => ApiClient.Pim.SluitpotBlockType.pimSluitpotBlockTypesGet().then((res) => res.data));

	if (list) {
		options = list.map((item) => {
			return {value: item.id!, label: item.name!};
		});
	}

	return (
		<>
			<Field className="custom-select" name={name} options={options} component={CustomSelect} placeholder={'-Selecteer-'} isMulti={multi} isClearable={!required} />
		</>
	);
};

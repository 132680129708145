import {FC} from 'react'
import {LayoutSplashScreen} from '../../../_metronic/layout/core'
import {WithChildren} from '../../../_metronic/helpers'
import {useAuth0} from '@auth0/auth0-react'

const AuthInit: FC<WithChildren> = ({children}) => {
  const {isLoading} = useAuth0()
  return isLoading ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthInit, useAuth0}

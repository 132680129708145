import React from "react";
import {useField} from "formik";


export interface FieldErrorsProps {
    field: string;
}

const FieldErrors: React.FC<FieldErrorsProps> = (props) => {

    const [field, meta] = useField(props.field);

    return <>
        {meta.error && meta.touched &&
            <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                    <span role='alert'>{meta.error}</span>
                </div>
            </div>
        }
    </>
};

export default FieldErrors;
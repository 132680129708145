/* tslint:disable */
/* eslint-disable */
/**
 * Ekosiet Web Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ConfigurationParameters {
apiKey?: string | Promise<string> | ((name: string) => string) | ((name: string) => Promise<string>);
    username?: string;
    password?: string;
    accessToken?: string | Promise<string> | ((name?: string, scopes?: string[]) => string) | ((name?: string, scopes?: string[]) => Promise<string>);
        basePath?: string;
        serverIndex?: number;
        baseOptions?: any;
        formDataCtor?: new () => any;
        }

        export class ClientConfig {
        /**
        * parameter for apiKey security
        * @param name security name
        * @memberof ClientConfig
        */
        apiKey?: string | Promise<string> | ((name: string) => string) | ((name: string) => Promise<string>);
            /**
            * parameter for basic security
            *
            * @type {string}
            * @memberof Configuration
            */
            username?: string;
            /**
            * parameter for basic security
            *
            * @type {string}
            * @memberof Configuration
            */
            password?: string;
            /**
            * parameter for oauth2 security
            * @param name security name
            * @param scopes oauth2 scope
            * @memberof Configuration
            */
            accessToken?: string | Promise<string> | ((name?: string, scopes?: string[]) => string) | ((name?: string, scopes?: string[]) => Promise<string>);
                /**
                * override base path
                *
                * @type {string}
                * @memberof Configuration
                */
                basePath?: string;
                /**
                * override server index
                *
                * @type {number}
                * @memberof Configuration
                */
                serverIndex?: number;
                /**
                * base options for axios calls
                *
                * @type {any}
                * @memberof Configuration
                */
                baseOptions?: any;
                /**
                * The FormData constructor that will be used to create multipart form data
                * requests. You can inject this here so that execution environments that
                * do not support the FormData class can still run the generated client.
                *
                * @type {new () => FormData}
                */
                formDataCtor?: new () => any;

                constructor(param: ConfigurationParameters = {}) {
                this.apiKey = param.apiKey;
                this.username = param.username;
                this.password = param.password;
                this.accessToken = param.accessToken;
                this.basePath = param.basePath;
                this.serverIndex = param.serverIndex;
                this.baseOptions = param.baseOptions;
                this.formDataCtor = param.formDataCtor;
                }

                /**
                * Check if the given MIME is a JSON MIME.
                * JSON MIME examples:
                *   application/json
                *   application/json; charset=UTF8
                *   APPLICATION/JSON
                *   application/vnd.company+json
                * @param mime - MIME (Multipurpose Internet Mail Extensions)
                * @return True if the given MIME is JSON, false otherwise.
                */
                public isJsonMime(mime: string): boolean {
                const jsonMime: RegExp = new RegExp('^(application\/json|[^;/ \t]+\/[^;/ \t]+[+]json)[ \t]*(;.*)?$', 'i');
                return mime !== null && (jsonMime.test(mime) || mime.toLowerCase() === 'application/json-patch+json');
                }
                }

import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useListView} from '../core/ListViewProvider'

const RoleWarningModal = () => {
  const {setitemForWarning, itemForWarning} = useListView()
  const {messages} = useIntl()
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const cancel = () => {
    setitemForWarning({Id: undefined, message: ''})
  }
  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_popup_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <label className='text-center fw-bolder fs-4 text-gray-600'>
                {itemForWarning?.message}
              </label>
              <div className='text-center pt-15'>
                <button
                  type='reset'
                  onClick={() => cancel()}
                  className='btn btn-light me-3'
                  data-kt-users-modal-action='cancel'
                >
                  {messages['USER.PROFILE.CANCEL'].toString()}
                </button>

                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                  onClick={itemForWarning?.onClick ? itemForWarning?.onClick : () => {}}
                >
                  <span className='indicator-label'>{itemForWarning?.submitButtonName}</span>
                </button>
              </div>
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {RoleWarningModal}

import { useMutation, useQueryClient  } from "@tanstack/react-query";
import {toast} from "react-hot-toast"
import ApiClient from "../../../../modules/api-client/ApiClient";
import { PutCustomerRepresentation } from "../../../../modules/api-client/generated";
import { AxiosError } from "axios";

export const usePutCustomer = () => {  

    const queryClient = useQueryClient();
    
    const mutation = useMutation(
        (customer: PutCustomerRepresentation) => {
          return ApiClient.Crm.Customer
          .crmCustomerIdPut(customer.id || "", undefined, customer, undefined)
          ;
        },        
        {
          onSuccess: (_data, {id}) => {
            queryClient.invalidateQueries([`v1/crm/customer/${id}`])
            queryClient.invalidateQueries(['v1/crm/customer/list'])
            queryClient.invalidateQueries(['v1/crm/customer/archived'])
            toast.success("Klant gewijzigd.");
          },
          onError: (error: AxiosError) => {                        
            toast.error(error?.message);
            // toast.error(error?.response?.data?.title || 'Onbekende fout!');
          }
        },
      );
    
      return mutation.mutateAsync;
};

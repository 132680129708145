import {useQueryClient} from "@tanstack/react-query";
import {useEkosietMutation} from "../../../../../shared/hooks/useEkosietMutation";
import {SillDetails, UpdateSillContraProfileRequest} from "../../../../../modules/api-client/generated";
import {NotFound, ValidationFailed} from "../../../../../modules/api-client/Responses";
import ApiClient from "../../../../../modules/api-client/ApiClient";

export const useSillUpdateContraProfileMutation = (id: string) => {

    const queryClient = useQueryClient();

    return useEkosietMutation<SillDetails, NotFound | ValidationFailed, string>(async (contraProfile: string) => {
            const command: UpdateSillContraProfileRequest = {
                contraProfile: contraProfile,
            };

            const response = await ApiClient.Pim.Sill.pimSillsSillIdContraProfilePut(id, undefined, command);
            return response.data;
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['ApiClient.Pim.Sill.getSill', id]).then();
            },
        },
    );
};
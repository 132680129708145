import React from "react";
import {OrderLineType} from "../../modules/api-client/generated";
export interface Props {
    type?: OrderLineType
}

const OrderlineTypeIcon: React.FC<Props> = ({type}) => {
        let icon;
        switch (type) {
            case OrderLineType.ShippingCostLine: {
                icon = 'truck';
                break;
            }
            case OrderLineType.ProductLine: {
                icon = 'cart-shopping';
                break;
            }
            case OrderLineType.TextLine: {
                icon = 'quote-right';
                break;
            }
            case OrderLineType.ConfigurationLine: {
                icon = 'wand-magic-sparkles';
                break;
            }
            default: {
                icon = 'trash'
            }
        }
        return (
            <i className={`fas fa-` + icon}></i>
        )
    }
;

export default OrderlineTypeIcon;

import {FC} from 'react'
import { WithChildren} from '../../../../helpers'
import {SidebarMenuItem} from "./SidebarMenuItem";
import {ProductionItemOperations} from "../../../../../app/modules/api-client/generated";
import {stationLookup} from "../../../../../app/backoffice/modules/pm/models/stations";

type Props = {
  stationCode:ProductionItemOperations,
  to: string

}

const ProductionStationMenuItem: FC<Props & WithChildren> = ({
                                                               stationCode,to
}) => {

  const station = stationLookup.get(stationCode);
  if (!station) {
    throw new Error(`Station not found`);
  }
  return (
      <SidebarMenuItem to={to} title={station.title} hasBullet={true} bulletColor={station.color}/>
  )
}

export {ProductionStationMenuItem}

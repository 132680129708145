import {useQuery, useQueryClient} from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import {NotFound, ValidationFailed} from "../../../../modules/api-client/Responses";
import {useEkosietMutation} from "../../../../shared/hooks/useEkosietMutation";
import {
    DiscountGroup, CreateDiscountGroupCommand, UpdateDiscountGroupModel
} from "../../../../modules/api-client/generated";
import {FormValues} from "./DiscountGroupUpsert";


export const useDiscountGroupUpsertMutation = (id?: string | undefined) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<DiscountGroup, NotFound | ValidationFailed, FormValues>((values) => {
            const command: CreateDiscountGroupCommand | UpdateDiscountGroupModel = {
                code: values.code,
                name: values.name,
            };

            if (id) {
                return ApiClient.Sm.DiscountGroup.smDiscountGroupsIdPut(id, undefined, command).then(response => response.data);
            }
            return ApiClient.Sm.DiscountGroup.smDiscountGroupsPost(undefined, command).then(response => response.data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['pimDiscountGroupIdGet', id]).then();
                queryClient.invalidateQueries(['pimDiscountGroupGet']).then();
            }
        }
    );
};

export const useDiscountGroupQuery = (id: string | undefined) => {
    return useQuery(['pimDiscountGroupIdGet', id],
        () => {
            return ApiClient.Sm.DiscountGroup.smDiscountGroupsIdGet(id!).then(res => res.data);
        }, {
            useErrorBoundary: true,
            enabled: !!id,
        });
}

export const useDiscountGroupDeleteMutation = (id: string) => {
    const queryClient = useQueryClient()
    return useEkosietMutation<void, NotFound | ValidationFailed, string>((id) => {
            return ApiClient.Sm.DiscountGroup.smDiscountGroupsIdDelete(id, undefined).then(response => response.data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['pimDiscountGroupIdGet', id]).then();
                queryClient.invalidateQueries(['pimDiscountGroupGet']).then();
            }
        }
    );
};




import React from "react";
import {PageTitle} from "../../../../../_metronic/layout/core";
import {ProductionItemOperations} from "../../../../modules/api-client/generated";
import {ProductionOperation} from "../components/ProductionOperation";
import {stationLookup} from "../models/stations";

const LattenMonteren: React.FC = () => {
    var station = stationLookup.get(ProductionItemOperations.LattenGemonteerd);
    if (!station) {
        throw new Error(`Station not found`);
    }
    return (
        <>
            <PageTitle
                color={station.color}
                breadcrumbs={[]}
            >
                {station.title}
            </PageTitle>

            <ProductionOperation scope={[`LattenGemonteerd:lm-0`,`Status:Started`]} operation={ProductionItemOperations.LattenGemonteerd} />
        </>
    )
}

export default LattenMonteren

// Description: Get the color of the year based on the year string.
const GetYearColor: (year: string) => string = (year) => {
        let hash = 0;
        for (let i = 0; i < year.length; i++) {
            hash = year.charCodeAt(i) + ((hash << 5) - hash);
        }
        const r = (hash & 0xFF0000) >> 16;
        const g = (hash & 0x00FF00) >> 8;
        const b = hash & 0x0000FF;
        return `rgb(${r},${g},${b})`;
};

export default GetYearColor;

import {
	ConfiguredSillAerationHole,
	ConfiguredSillDeurBinnendraaiendCompartment,
	ConfiguredSillDeurBuitendraaiendCompartment,
	ConfiguredSillDraaiKiepCompartment,
	ConfiguredSillDrainageHole,
	ConfiguredSillGlazingBar,
	ConfiguredSillGlazingProfile,
	ConfiguredSillHefSchuifACompartment,
	ConfiguredSillHefSchuifCCompartment,
	ConfiguredSillHefSchuifDCompartment, ConfiguredSillHefSchuifECompartment,
	ConfiguredSillLProfile,
	ConfiguredSillSluitpot,
	ConfiguredSillStopProfile,
	ConfiguredSillVastglasBinnenCompartment,
	ConfiguredSillVastglasBuitenCompartment,
	ConfiguredSillVouwwandBinnenCompartment,
	ConfiguredSillVouwwandBuitenCompartment,
	ConfiguredSillZijlichtCompartment,
} from '../../../modules/api-client/generated';

export const DestructCompartment = (c: ConfiguredSillDeurBinnendraaiendCompartment | ConfiguredSillDeurBuitendraaiendCompartment | ConfiguredSillDraaiKiepCompartment | ConfiguredSillHefSchuifACompartment | ConfiguredSillHefSchuifCCompartment | ConfiguredSillHefSchuifDCompartment | ConfiguredSillHefSchuifECompartment | ConfiguredSillVastglasBinnenCompartment | ConfiguredSillVastglasBuitenCompartment | ConfiguredSillVouwwandBinnenCompartment | ConfiguredSillVouwwandBuitenCompartment | ConfiguredSillZijlichtCompartment) => {

    return {
        glazingBar: (c as { glazingBar: ConfiguredSillGlazingBar }).glazingBar,
        glazingProfile: (c as {
            glazingProfile: ConfiguredSillGlazingProfile
        }).glazingProfile,

        lProfile: (c as { lProfile: ConfiguredSillLProfile }).lProfile,
        stopProfile: (c as { stopProfile: ConfiguredSillStopProfile }).stopProfile,
        aerationHoles: (c as {
            aerationHoles: ConfiguredSillAerationHole[]
        }).aerationHoles,
        drainageHoles: (c as {
            drainageHoles: ConfiguredSillDrainageHole[]
        }).drainageHoles,
        sluitpotten: (c as { sluitpotten: ConfiguredSillSluitpot[] }).sluitpotten,
    };
};
import React from "react";
import {EkoCard, EkoCardBody} from "../../../../../_metronic/helpers";

export interface SillCardProps {
    id: string;
    code: string;
    title: string;
    description: string | undefined;
    onClick: (id:string)=>void,
}

const SillCard: React.FC<SillCardProps> = ({id,code,title,description,onClick}) => {

    return (<>

        <EkoCard shadow={true} className={'mb-5'}>
            <EkoCardBody className="position-relative">
                    <h1 className={'text-center mt-5 mb-10'}>
                        <img src={'/media/dorpels/'+code+'.png'} className={'img-fluid'} alt="dorpel" />
                    </h1>
                    <h5 className="card-title">{title}</h5>
                    <p className="card-text">{description}</p>
                    <button type="button" className="btn btn-primary btn-sm stretched-link" onClick={()=>onClick(id)}>Configureer</button>
            </EkoCardBody>
        </EkoCard>
    </>);
}

export default SillCard;
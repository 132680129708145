import React, {useLayoutEffect} from 'react';
import {Formik, FormikHelpers, useFormikContext} from 'formik';
import SubmitButton from '../../../../shared/components/SubmitButton';
import {ValidationErrors} from '../../../../modules/api-client/Responses';
import TextField from '../../../../shared/components/TextField';
import {useSluitpotQuery, useSluitpotUpsertMutation} from './hooks';
import Loading from '../../../../shared/components/Loading';
import ProfileDropzone from '../components/ProfileDropzone';
import SluitpotBeslagSvg from '../../../../shared/components/SluitpotBeslagSvg';
import {SluitpotBeslagPicker} from '../sluitpotbeslag/SluitpotBeslagPicker';
import {SluitpotBlockTypePicker} from "../sluitpot-block-type/SluitpotBlockTypePicker";

export interface FormValues {
	code: string;
	active: boolean;
	name: string;
	angle: number;
	contour: string;
	height: number;
	sluitpotBeslagId: string;
	sluitpotBlockTypeId: string;
	operationName?: string;
}

type Props = {
	handleClose: () => void;
	id?: string;
};

const SluitpotUpsert: React.FC<Props> = ({handleClose, id}) => {
	const {mutate, serverValidationErrors} = useSluitpotUpsertMutation(id);

	const onSubmit = (values: FormValues, {setSubmitting}: FormikHelpers<FormValues>) => {
		return new Promise<void>((resolve, reject) => {
			mutate(values, {
				onSuccess: () => {
					resolve();
					handleClose();
				},
				onError: () => {
					reject();
				},
			});
		}).finally(() => setSubmitting(false));
	};

	const {data: sluitpot, isInitialLoading, isError} = useSluitpotQuery(id);
	if (isInitialLoading) return <Loading />;
	if (isError) return <>Error...</>;
	if (id && !sluitpot) return <>'{id}' niet gevonden.</>;

	const initialValues: FormValues = {
		code: sluitpot?.code ?? '',
		active: sluitpot?.active ?? false,
		name: sluitpot?.name ?? '',
		angle: sluitpot?.angle ?? 0,
		contour: sluitpot?.contour ?? '',
		height: sluitpot?.height ?? 0,
		sluitpotBlockTypeId: sluitpot?.sluitpotBlockType?.id ?? '',
		sluitpotBeslagId: sluitpot?.sluitpotBeslag.id ?? '',
		operationName: sluitpot?.operationName ?? undefined
	};

	return (
		<>
			<Formik initialValues={initialValues} onSubmit={onSubmit}>
				<ColorUpsertForm id={id} serverValidationErrors={serverValidationErrors} />
			</Formik>
		</>
	);
};

interface ColorUpsertFormProps {
	id: string | undefined;
	serverValidationErrors: ValidationErrors | null;
}

export const ColorUpsertForm: React.FC<ColorUpsertFormProps> = (props) => {
	const {values, handleSubmit, handleChange, isSubmitting, errors, setFieldValue, setErrors} = useFormikContext<FormValues>();
	const mergeServerErrors = () => {
		if (props.serverValidationErrors) {
			setErrors({...errors, ...props.serverValidationErrors});
		}
	};
	useLayoutEffect(mergeServerErrors, [props.serverValidationErrors, errors, setErrors]);
	return (
		<form onSubmit={handleSubmit}>

			<TextField row={true} name={'code'} label={'Unieke code'} required />
			<TextField row={true} name={'name'} label={'Naam'} required />
			<TextField row={true} name={'angle'} label={'Hoek'} required/>
			<TextField row={true} name={'height'} label={'Hoogte (hart)'} required />

			<div className="row mb-4">
				<div className="col-4">
					<label className="form-label" htmlFor="type">
						Type
					</label>
				</div>
				<div className="col-8">
					<SluitpotBlockTypePicker name={'sluitpotBlockTypeId'} required/>
				</div>
			</div>

			<div className="row mb-4">
				<div className="col-4">
					<label className="form-label" htmlFor="type">
						Contour
					</label>
				</div>
				<div className="col-8">
					<ProfileDropzone onSelect={(selected) => setFieldValue('contour', selected)} preview={(svg) => <SluitpotBeslagSvg contour={svg} />} show={'drag'}>
						{({openFileDialog}) => (
							<>
								<div
									className={`form-control d-flex justify-content-center align-items-center ${values.contour ? '' : 'cursor-pointer'}`}
									onClick={() => {
										if (!values.contour) openFileDialog();
									}}
									onDoubleClick={openFileDialog}
									style={{height: '200px'}}
								>
									{values.contour && <SluitpotBeslagSvg contour={values.contour} />}
									{!values.contour && (
										<div className="d-flex flex-column justify-content-center align-items-center">
											<button type="button" className="btn btn-light-primary me-3" onClick={openFileDialog}>
												<i className="fa-regular fa-folder-open"></i> Blader
											</button>
											<div className="mt-2">of sleep een .DXF bestand hier</div>
										</div>
									)}
								</div>
								{values.contour && (
									<div className="py-3 d-flex justify-content-start">
										<button type="button" className="btn btn-light-primary me-3" onClick={openFileDialog}>
											Wijzig contour
										</button>
										{/*<button type="button" className="btn btn-link"*/}
										{/*        onClick={() => console.log('reset')*/}
										{/*        }>Wissen*/}
										{/*</button>*/}
									</div>
								)}
							</>
						)}
					</ProfileDropzone>
				</div>
			</div>
			<div className="row mb-4">
				<div className="col-4">
					<label className="form-label" htmlFor="type">
						Voor sluitpot
					</label>
				</div>
				<div className="col-8">
					<SluitpotBeslagPicker name={'sluitpotBeslagId'} />
				</div>
			</div>

			<TextField row={true} name={'operationName'} label={'Operation'} help="De naam van de operation in 3DMaster." />

			<div className="row mb-4">
				<div className="col-4">
					<label className="form-label" htmlFor="type">
						Actief
					</label>
				</div>
				<div className="col-8">
					<div className="form-check form-switch">
						<input className="form-check-input" type="checkbox" role="switch" name="active" value="true" onChange={handleChange} checked={values.active} id="active" />
						<label className="form-check-label" htmlFor="active">
							Actief
						</label>
					</div>
				</div>
			</div>

			<div className="row pt-5">
				<div className="offset-4 col-8 d-flex justify-content-end">
					<SubmitButton type="submit" className="btn btn-primary" isSubmitting={isSubmitting}>
						Opslaan
					</SubmitButton>
				</div>
			</div>
		</form>
	);
};

export default SluitpotUpsert;

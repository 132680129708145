import React from "react";


export interface BooleanTextProps {
    value: boolean | undefined | null;

    trueText?: string;
    falseText?: string;
}

export const BooleanText: React.FC<BooleanTextProps> = ({...props}) => {

    if (typeof props.value === 'undefined' || props.value === null) {
        return <></>;
    }

    props.trueText ??= 'Ja';
    props.falseText ??= 'Nee';

    return <>{props.value ? props.trueText : props.falseText}</>;
};


import { useAuth0 } from '@auth0/auth0-react'
import clsx from 'clsx'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { HeaderUserMenu, ThemeModeSwitcher } from '../../../partials'
import { useLayout } from '../../core'
import { useEkosietPrinter } from '../../../../app/shared/context/PrinterContext'

const itemClass = 'ms-1 ms-lg-3'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'

const Navbar = () => {
  const { config } = useLayout()
  const { user } = useAuth0()
  const printerContext = useEkosietPrinter();
  const { status, showPrinterModal } = printerContext;
  const printerClass = status === "error" ? "text-danger" : status === "success" ? "text-success" : "text-light";

  return (
    <div className='app-navbar flex-shrink-0'>

      {/* {Object.keys(printerContext).length !== 0 &&
        <div className={clsx('app-navbar-item', itemClass)}>
          <i className={clsx('fa fa-print', printerClass)} style={{ fontSize: '16px' }} aria-hidden="true" onClick={() => showPrinterModal()}></i>
        </div>
      } */}

      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img
            src={user?.picture ? user.picture : toAbsoluteUrl('/media/avatars/300-1.jpg')}
            alt=''
          />
        </div>
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export { Navbar }

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, {PropsWithChildren} from 'react';
import {usePutValidationResolve} from '../../hooks/use-put-validation-resolve';
import {modalsRoot} from '../../../../global-helpers';
import {Button, Modal} from 'react-bootstrap';
import {
	GroeneveldHefSchuifSchemaLayoutImportValidation,
	HefschuifFixedDoorCutImportValidation, HefSchuifSlidingDirection,
	HefschuifSlidingDirectionMissingImportValidation,
	IgnoreImportValidationCommand,
	ResolveHefschuifFixedDoorCutImportValidationCommand,
	ResolveHefSchuifSchemaLayoutImportValidationCommand, ResolveHefschuifSlidingDirectionMissingImportValidationCommand,
	ResolveImportValidationCommandType,
} from '../../../../modules/api-client/generated';
import {ValidationResolverProps} from '../../pages/ValidationResolverPage';
import {Form, Formik, FormikHelpers} from 'formik';
import SpinnerButton from '../../../components/SpinnerButton';
import {
	HefSchuifSchemaPickerField
} from "../../../../backoffice/modules/pim/compartment-layout-type/HefSchuifSchemaPicker";
import TextField from "../../../components/TextField";
import clsx from "clsx";

type FormValues = {slidingDirection: HefSchuifSlidingDirection|undefined};
export type SlidingDirectionResolverProps = Omit<ValidationResolverProps, 'validation'> & {
	validation: HefschuifSlidingDirectionMissingImportValidation
};
export const SlidingDirectionResolver = ({show, validation, handleClose, children}: PropsWithChildren<SlidingDirectionResolverProps>) => {
	var resolveMutation = usePutValidationResolve();

	const initialFormValues: FormValues = {slidingDirection: undefined};
	const resolve = (values: FormValues, {setSubmitting}: FormikHelpers<FormValues>) => {
		return new Promise<void>((resolve, reject) => {
			var command: ResolveHefschuifSlidingDirectionMissingImportValidationCommand = {
				commandType: ResolveImportValidationCommandType.ResolveHefschuifSlidingDirectionMissingImportValidationCommand,
				slidingDirection: values.slidingDirection!,
				id: validation.id,
				importType: validation.type,
			};
			resolveMutation.mutate(command, {
				onSuccess: (model) => {
					resolve();
					handleClose();
				},
				onError: () => {
					reject();
				},
			});
		}).finally(() => setSubmitting(false));
	};

	const ignore = async () => {

		var command: IgnoreImportValidationCommand = {
			commandType: ResolveImportValidationCommandType.IgnoreImportValidationCommand,
			id: validation.id,
			importType: validation.type,
		};

		await resolveMutation.mutateAsync(command);

		handleClose();
	};

	return (
		<Formik initialValues={initialFormValues} onSubmit={resolve}>
			{({values, getFieldProps, isSubmitting}) => (
				<Modal
					container={modalsRoot}
					tabIndex={-1}
					aria-hidden="true"
					autoFocus={true}
					className=""
					dialogClassName={'modal-dialog-centered'}
					contentClassName={'shadow-lg'}
					show={show}
					size="lg"
					onHide={handleClose}
					animation={false}
					backdrop={'static'}
				>
					<Form>
						<Modal.Header closeButton>
							<Modal.Title>Kies een schuifrichting</Modal.Title>
						</Modal.Header>

						<Modal.Body className="flex-fill">
							<p className={'lead'}>{validation.message} </p>
							<div className="d-flex my-5 text-start ">
								<div className="form-check form-switch d-flex justify-content-start align-items-center">
									<input
										{...getFieldProps('slidingDirection')}
										className={clsx('form-check-input')}
										type="radio"
										name="slidingDirection"
										id="slidingDirection"
										value={HefSchuifSlidingDirection.LeftSliding}
										checked={values.slidingDirection == HefSchuifSlidingDirection.LeftSliding}
										disabled={isSubmitting}
									/>
									<label className="fw-bold ms-4 fs-6 mb-2 form-check-label"
										   htmlFor="slidingDirection">
										Links schuivend
										<br/>
										<span className={'text-muted'}>
											<small>Gezien vanuit binnenshuis</small>
										</span>
									</label>
								</div>
							</div>
							<div className="d-flex my-5 text-start ">
								<div className="form-check form-switch d-flex justify-content-start align-items-center">
									<input
										{...getFieldProps('slidingDirection')}
										className={clsx('form-check-input')}
										type="radio"
										name="slidingDirection"
										id="slidingDirectionRight"
										value={HefSchuifSlidingDirection.RightSliding}
										checked={values.slidingDirection == HefSchuifSlidingDirection.RightSliding}
										disabled={isSubmitting}
									/>
									<label className="fw-bold ms-4 fs-6 mb-2 form-check-label"
										   htmlFor="slidingDirectionRight">
										Rechts schuivend
										<br/>
										<span className={'text-muted'}>
											<small>Gezien vanuit binnenshuis</small>
										</span>
									</label>
								</div>
							</div>

						</Modal.Body>

						<Modal.Footer>
							<Button variant="link" className="mx-4" onClick={handleClose}>
								Sluit
							</Button>
							<Button
								className="btn-light-danger mx-4"
								onClick={() => {
									ignore().then();
								}}
							>
								Negeer
							</Button>
							<SpinnerButton type="submit" className="btn btn-primary" spinning={isSubmitting}
										   disabled={isSubmitting}>
								Toepassen
							</SpinnerButton>
						</Modal.Footer>
					</Form>
				</Modal>
			)}
		</Formik>
	);
};


import React, {useId} from "react";
import {HefSchuifDoorBarCutoutField} from "./HefSchuifDoorBarCutoutField";
import {useField} from "formik";
import FieldErrors from "../FieldErrors";
import {SillConfigurationFixedDoorBarSegment} from "../../../modules/api-client/generated";


export interface HefSchuifFixedDoorBarSegmentProps {
    fieldName: string;
    label?: string
}

export const HefSchuifFixedDoorBarSegmentRow: React.FC<HefSchuifFixedDoorBarSegmentProps> = (props) => {

    const id = useId();

    const [field, meta, helper] = useField<SillConfigurationFixedDoorBarSegment | undefined>(props.fieldName);

    const label = props.label ?? 'Lat vastedeur';

    return <>
        <div className="row">
            <div className={"col-4"}>
                <label htmlFor={`${id}_length`} className='form-label'>
                    <small className='text-uppercase text-muted'>Lengte {label}</small>
                </label>
                <div className="input-group">
                    <input type="number"
                           id={`${id}_length`}
                           pattern={"[0-9]+([,\\.][0-9]+)?"}
                           name={`${props.fieldName}.length`}
                           value={field.value?.length ?? ''}
                           onChange={field.onChange}
                           onBlur={field.onBlur}
                           className="form-control"
                           min="0" step="0.1"
                           placeholder="Lengte"
                           required={true}/>
                    <span className="input-group-text">mm</span>
                </div>
                <small className="text-muted"><i>Vanuit de dagkant</i></small>
                <FieldErrors field={`${props.fieldName}.length`}/>
            </div>

            <div className={"col"}>
                <label className='form-label'>
                    <small className='text-uppercase text-muted'>Uitsparing rubberblok</small>
                </label>
                <HefSchuifDoorBarCutoutField fieldName={`${field.name}.cutout`}/>
            </div>
        </div>
    </>
}

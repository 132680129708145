import React, {ReactNode, useState} from "react";
import {createPortal} from "react-dom";
import {Modal} from "react-bootstrap";
import {DfxScan} from "./ProfileDropzone";


interface ProfileScanDialogProps {
    filename: string | null | undefined;
    scanning: boolean;
    scans: DfxScan[] | null;
    error: string | null;

    show: boolean;
    onHide: () => void;
    rescan?: () => void;
    onConfirm: (profile: string) => void;

    preview: (profile: string)  => ReactNode;
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ProfileScanDialog: React.FC<ProfileScanDialogProps> = (props) => {

    const [selected, setSelected] = useState<string | null>(null);

    const onShow = () => {
        setSelected(null);
    };
    const onHide = () => {
        props.onHide();
    };

    return createPortal(
        <Modal
            id='kt_addedit_profile_upload_dialog'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog'
            contentClassName='shadow'
            size="lg"
            show={props.show}
            onShow={onShow}
            onHide={onHide}
            animation={false}
            scrollable={true}
            backdrop={true}>
            <Modal.Header closeButton>
                <Modal.Title>Profielen in {props.filename ?? 'DFX bestand'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                {!props.scanning && props.scans && <>

                    <strong>
                        Selecteer een profiel
                    </strong>
                    <div className="table-responsive">

                        <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                            <tbody>
                            {/*</tr>*/}
                            {props.scans.map((r, index) =>
                                <tr key={`profile-${index}`}
                                    className="cursor-pointer"
                                    onClick={() => setSelected(r.profile)}>
                                    <td className="fit-content">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                   id={`profile-${index}-selected`} name="selected"
                                                   checked={selected === r.profile}
                                                   onChange={() => setSelected(r.profile)}

                                            />
                                            <label className="form-check-label"
                                                   htmlFor={`profile-${index}-selected`}></label>
                                        </div>
                                    </td>
                                    <td>
                                        <div style={{height: '100px', width: '200px'}}>
                                            {props.preview(r.profile)}
                                        </div>
                                    </td>
                                    <td className="fit-content text-end">
                                        {Math.round(r.height / 0.1) * 0.1} x {Math.round(r.width / 0.1) * 0.1}mm
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </>}


                {!props.scans && <>

                    <div className="h-100 d-flex flex-column justify-content-center">

                        {props.scanning &&
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <div className="spinner-border text-primary"
                                     style={{width: '6rem', height: '6rem', borderWidth: '.5rem'}} role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                <div className="mt-3">Bestand scannen...</div>
                            </div>
                        }

                        {!props.scanning && !props.scans && <>

                            {props.error && <>
                                <div className="">
                                    <div className="alert alert-danger">
                                        <div>
                                            <strong>{props.error}</strong>
                                        </div>
                                    </div>
                                </div>
                            </>}
                        </>}
                    </div>
                </>}
            </Modal.Body>
            <Modal.Footer className="justify-content-between">

                <button type="button" className="btn btn-light me-3" onClick={props.rescan}
                        disabled={props.scanning}>Rescan
                </button>
                <div>
                    <button type="button" className="btn btn-link me-5" onClick={props.onHide}>Annuleren</button>
                    <button type="button" className="btn btn-primary" onClick={() => props.onConfirm(selected!)}
                            disabled={!selected || props.scanning || !!props.error}>Bevestigen
                    </button>
                </div>
            </Modal.Footer>
        </Modal>,
        modalsRoot
    );
}

export default ProfileScanDialog;
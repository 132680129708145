import { useCalendar, isSameDate } from "@h6s/calendar";
import classNames from "classnames";
import { format, setHours, setMilliseconds, setMinutes, setSeconds } from "date-fns";
import { useEffect } from "react";
import { getLocale, formatDate } from "../date";

interface Props {
  value?: Date;
  onChange: (value: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  dontExcludeWeekendDays?: boolean;
}

export const resetTimeOfDate = (date: Date) => {
  return setHours(setMinutes(setSeconds(setMilliseconds(date, 0), 0), 0), 0);
};

export const Calendar = ({ value, onChange, minDate, maxDate, dontExcludeWeekendDays }: Props) => {
  
  const { headers, body, cursorDate, navigation } = useCalendar();
  
  useEffect(() => {
    if (value) {
      navigation.setDate(value);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);


return (
    <>
      <div className="w-100">
        <div className="d-flex align-items-center">
          <button
            type="button"
            className="btn btn-sm m-1"
            onClick={navigation.toPrev}
          >
            <span className="sr-only">Vorige maand</span>            
            <i className="fa-solid fa-chevron-left"></i>
          </button>
          
          <div className="">{formatDate(cursorDate, "MMMM yyyy")}</div>

          <button
            type="button"
            className="btn btn-sm m-1"
            onClick={navigation.toNext}
          >
            <span className="sr-only">Volgende maand</span>
            <i className="fa-solid fa-chevron-right"></i>
          </button>          
        </div>

        <div className="container row calendar-header-row mt-5">
          {headers.weekDays.map(({ key, value }) => {
            return (
              <div key={key} className="col text-center">
                {format(value, "E", { locale: getLocale() })}
              </div>
            );
          })}
        </div>
        
          {body.value.map((week, weekIdx) => {
            
            const { value: days, key } = week;

            var isBeginOrEndRow = weekIdx !== 0 || weekIdx === body.value.length - 1 
            return (
                <div
                key={weekIdx}
                className={classNames(
                    "container",                              
                    "row",                    
                    isBeginOrEndRow
                      ? "calendar-begin-or-end-row"
                      : "calendar-row"
                      )
                  }                
                >
                  {
                     days.map((day, dayIdx) => {
                        const { key, date, isCurrentDate, isCurrentMonth, value: val } = day;
                        const isSelected = value && isSameDate(val, value);
                        const isBeforeMinDate = minDate !== undefined && resetTimeOfDate(val) < resetTimeOfDate(minDate);
                        const isAfterMaxDate = maxDate !== undefined && resetTimeOfDate(val) > resetTimeOfDate(maxDate);          
                        const isWeekendDay = dayIdx === days.length - 7 || dayIdx === days.length - 1 
                                              
                        return (
                        <>
                          <button
                            key={key}
                            type="button"
                            onClick={() => onChange(resetTimeOfDate(val))}
                            disabled={isBeforeMinDate || isAfterMaxDate || (dontExcludeWeekendDays !== true && isWeekendDay)}
                            className={classNames(
                              "col",                              
                              "calendar-cell",
                              isSelected
                                ? "text-green rounded-md font-semibold"
                                : isCurrentDate
                                ? "font-semibold"
                                : isCurrentMonth
                                ? "text-zinc-900"
                                : "text-zinc-500",
                              isSelected
                                ? "bg-success"
                                : isCurrentMonth
                                ? "bg-white hover:bg-zinc-100"
                                : "bg-light hover:bg-zinc-100"                              
                            )}
                          >
                            <time className={classNames("mx-auto flex h-7 w-7 items-center justify-center")}>{date}</time>
                          </button>
                        </>
                        );
                     })
                  }
                </div>
            );
          })}
        
      </div>
    </>
  );  
};

import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useEkosietAuth0 } from '../../../../modules/auth0/core/useEkosietAuth0'
import { SmRoutePath } from '../SmRoutes'
import { usePostDraftOrder } from '../orders/hooks/use-post-draft-order'
import {CustomerRoutePath} from "../../../../customer-portal/routing/RoutePath";

const StartOrder = () => {
  
  const navigate = useNavigate();
  const { customerId: customerIdParam } = useParams<{ customerId: string }>(); //needs to be set when user is employee
  let { customerId, isKlant } = useEkosietAuth0(); // customer id can be empty when user is employee 
  
  if (!customerId) {
    customerId = customerIdParam || "";
  }

  if (!customerId) {
    if (isKlant) {
      //todo change to customer link
      navigate(CustomerRoutePath.orderStart);
    } else {
      navigate(SmRoutePath.link(SmRoutePath.orderList));
    }    
  }

  // data mutations
  const { mutateAsync: mutateDraft } = usePostDraftOrder();
    
  useEffect(() => {
    async function draftOrder() {
      const draftOrder = await mutateDraft(customerId);
      navigate(SmRoutePath.link(SmRoutePath.orderAddWithIdLink(draftOrder.id)));
    }  
    draftOrder();    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <span>Nieuwe order wordt aangemaakt...</span>
    </>
  )
}

export { StartOrder }
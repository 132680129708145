import React from 'react';
import {useQuery} from '@tanstack/react-query';
import ApiClient from '../../../../modules/api-client/ApiClient';
import {WeeklyProductionStatsRow, WeeklySalesStatsRow} from '../../../../modules/api-client/generated';
import Loading from '../../../../shared/components/Loading';
import {EkoTable} from "../../../../shared/components/table/EkoTable";
import {TableHeader} from "../../../../shared/components/table/TableHeader";
import Euro from "../../../../shared/components/Euro";
import {NumericFormat} from "react-number-format";
import {bool} from "yup";
import {Link} from "react-router-dom";
import {SmRoutePath} from "../../sm/SmRoutes";
import {MgtRoutePath} from "../../mgt/MgtRoutes";

export const ProductionStats: React.FC = () => {

    const {isInitialLoading, data} = useQuery<{ [key: string]: { [key: string]: WeeklyProductionStatsRow; }; }>(
        ['weeklyProductionStats'],
        () => ApiClient.Pm.Reporting.weeklyProductionStats().then((res) => res.data));
    if (!data) return <Loading/>

    if (isInitialLoading) return <Loading/>
    return (
        <>
            {Object.keys(data).reverse().map((year) => {
                return (
                    <>
                        <h1>
                            {year}
                        </h1>
                        <EkoTable className={'table-row-dashed table-row-gray-300 gy-7 '}>
                            <TableHeader>
                                <th>Week</th>
                                <th>Dorpels</th>
                                <th>Doorlooptijd <i className={'fas fa-info-circle'}
                                                    title={'Tijd tussen Productie item start - gereed. Start is wanneer batch wordt geaccepteerd! Hoe lean wordt er geproduceerd?'}></i>
                                </th>
                                <th>Timing <i className={'fas fa-info-circle'}
                                              title={'Gemiddelde tijd (in dagen) tussen geplande gerealiseerde productie datum. Loopt productie voor of achter op schema?'}></i>
                                </th>
                                <th>Post productie buffer <i className={'fas fa-info-circle'}
                                                             title={'Gerealiseerde en geplande tijd tussen productiedatum en geplande expeditie. Hoeveel marge/buffer is er in productie?'}></i>
                                </th>

                                <th>Neuten</th>
                                <th>Lengte</th>
                            </TableHeader>
                            <tbody>
                            {Object.keys(data[year]).reverse().map((week) => {
                                return (
                                    <tr key={week} className=" fs-6 text-gray-800">
                                        <td className={'text-center'}>{week}</td>
                                        <td className={'text-end'}>
                                            <strong>{data[year][week].geproduceerdeDorpels}</strong>/
                                            <span
                                                className={'text-muted fs-7'}>{data[year][week].geplandeDorpels}</span>
                                            <br/>
                                            <span className={'text-muted fs-7'}>
                                                <Euro
                                                value={data[year][week].omzet}/>/<Euro
                                                value={data[year][week].geplandeOmzet}/></span>
                                        </td>
                                        <td className={'text-end '}>
                                            {data[year][week].doorlooptijd ?
                                            <NumericFormat className={'me-1 text-nowrap'} decimalScale={2}
                                                           value={data[year][week].doorlooptijd} displayType={'text'}
                                                           thousandSeparator={true} suffix={''}/>
                                                :
                                                "-"
                                            }

                                            <br/>
                                            <span className={'text-muted fs-7'}> dag(en)</span>
                                        </td>

                                        <td className={'text-end '}>
                                            {data[year][week].timing ?
                                            <NumericFormat className={'me-1 text-nowrap'} decimalScale={2}
                                                           value={data[year][week].timing} displayType={'text'}
                                                           thousandSeparator={true} suffix={''}/>
                                                :
                                                "-"
                                            }
                                            <br/>
                                            <span className={'text-muted fs-7'}> dag(en)</span>
                                        </td>
                                        <td className={'text-end '}>

                                            <strong>
                                                {data[year][week].gerealiseerdeBuffertijd ?
                                                    <NumericFormat className={'me-1 text-nowrap'} decimalScale={2}
                                                                   value={data[year][week].gerealiseerdeBuffertijd} displayType={'text'}
                                                                   thousandSeparator={true} suffix={''}/>
                                                    :
                                                    "-"
                                                }</strong>
                                            /
                                            <span
                                                className={'text-muted fs-7'}>
                                                 <NumericFormat className={'me-1 text-nowrap'} decimalScale={2}
                                                                value={data[year][week].geplandeBuffertijd} displayType={'text'}
                                                                thousandSeparator={true} suffix={''}/>
                                                </span>
                                            <br/>
                                            <span className={'text-muted fs-7'}> dag(en)</span>
                                        </td>
                                        <td className={'text-end'}>
                                            <NumericFormat className={'me-1 text-nowrap'} decimalScale={0}
                                                           value={data[year][week].neuten} displayType={'text'}
                                                           thousandSeparator={true} suffix={''}/>
                                            <br/>
                                            <span className={'text-muted fs-7'}> stuks
                                        </span>
                                        </td>
                                        <td className={'text-end'}>
                                            <NumericFormat className={'me-1 text-nowrap'} decimalScale={2}
                                                           value={data[year][week].meter} displayType={'text'}
                                                           thousandSeparator={true} suffix={''}/><br/>
                                            <span className={'text-muted fs-7'}> meter
                                        </span>
                                        </td>
                                    </tr>
                                )
                            })
                            }
                            </tbody>
                        </EkoTable>
                    </>
                )

            })}
        </>
    );
};
import {useQueryClient} from '@tanstack/react-query';
import {toast} from 'react-hot-toast';
import ApiClient from '../../../../../../modules/api-client/ApiClient';
import {OrderLineRepresentation, SbomModel} from '../../../../../../modules/api-client/generated';
import {useEkosietMutation} from '../../../../../../shared/hooks/useEkosietMutation';
import {NotFound, ValidationFailed} from '../../../../../../modules/api-client/Responses';

export const usePutSbom = () => {

		const queryClient = useQueryClient();

		return useEkosietMutation<OrderLineRepresentation, NotFound | ValidationFailed, {
			orderLineId: string,
			sbom: SbomModel
		}>
		(({orderLineId, sbom}) => {
				return ApiClient.Sm.Orderline.smOrderlineSbomIdPut(orderLineId, undefined, sbom).then(response => response.data);
			},
			{
				onSuccess: (order) => {
					queryClient.invalidateQueries(['smOrderIdGet', order.orderId]).then();
					queryClient.invalidateQueries(['OrderActionLog', order.orderId]).then();

					toast.success('Dorpel prijs aangepast.');

				},
				onError: () => {
					toast.error('Dorpel prijs aanpassing mislukt!');
				},
			},
		);
	}
;

import React, {useLayoutEffect} from "react";
import {Field, Form, Formik, FormikHelpers, useFormikContext} from "formik";
import SubmitButton from "../../../../shared/components/SubmitButton";
import {ValidationErrors} from "../../../../modules/api-client/Responses";
import TextField from "../../../../shared/components/TextField";
import {useDiscountRuleQuery, useDiscountRuleUpsertMutation} from "./hooks";
import {
    PriceListAndDiscountGroupDiscountRule
} from "../../../../modules/api-client/generated";
import {PriceListPicker} from "../pricelist/PriceListPicker";
import {DiscountGroupPicker} from "../discountgroup/DiscountGroupPicker";
import Loading from "../../../../shared/components/Loading";
import {DateField} from "../../../../shared/components/date/DateField";
import moment from "moment/moment";

export interface FormValues {
    code: string;
    name: string;
    discount: number;
    discountRuleType: string;
    effectiveDate: string;
}
type Props = {
    handleClose: () => void,
    id?: string,
}

const DiscountRuleUpsert: React.FC<Props> = ({handleClose,id}) => {

    const {mutate, serverValidationErrors} = useDiscountRuleUpsertMutation(id)
    const onSubmit = (values: FormValues, {setSubmitting}: FormikHelpers<FormValues>) => {

        const payload = { ...values, effectiveDate: moment(values.effectiveDate).format(moment.HTML5_FMT.DATE)
        }
        return new Promise<void>((resolve, reject) => {
            mutate(payload, {
                onSuccess: () => {
                    resolve();
                    handleClose();

                },
                onError: () => {
                    reject();
                }
            });

        }).finally(() => setSubmitting(false));
    };


    const {data: data, isInitialLoading, isError} = useDiscountRuleQuery(id);
    if (isInitialLoading) return <Loading/>
    if (isError) return (<>Error...</>)
    if (id && !data) return (<>'{id}' niet gevonden.</>)

    const defaults = {
        discountRuleType: 'priceListAndDiscountGroup',
    };
    const initialValues: any = {
        ...defaults,
        ...data,
        effectiveDate: data?.effectiveDate ? new Date(data?.effectiveDate!) : new Date()
}
    if (data?.discountRuleType === 'priceListAndDiscountGroup') {
        const x = data as PriceListAndDiscountGroupDiscountRule
        initialValues.priceListId = x?.priceList?.id!
        initialValues.discountGroupId = x?.discountGroup?.id!
    }

    return (
        <>
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                {({values, setFieldValue}) =>
                <>
                <div className="row mb-4">
                    <div className="col-4">
                        <label className="form-label" htmlFor="catalogItemType">Type</label>
                    </div>
                    <div className="col-8">
                        <Field as="select" name={`discountRuleType`}
                               className="form-select"
                               disabled={id}
                               onChange={(e: any) => setFieldValue('discountRuleType', e.target.value)}>
                            <option value='priceListAndDiscountGroup'>Prijslijst + Kortingroep</option>
                        </Field>
                    </div>
                </div>

                <UpsertForm type={values.discountRuleType} id={id} serverValidationErrors={serverValidationErrors}/>
                </>
                }

            </Formik>
        </>

    );
}


interface UpsertFormProps {
    id: string | undefined;
    type: string | undefined;
    serverValidationErrors: ValidationErrors | null;
}

export const UpsertForm: React.FC<UpsertFormProps> = (props) => {
    const { handleSubmit, isSubmitting, errors, setErrors} = useFormikContext<FormValues>();
    const mergeServerErrors = () => {

        if (props.serverValidationErrors) {
            setErrors({...errors, ...props.serverValidationErrors});
        }
    }
    useLayoutEffect(mergeServerErrors, [props.serverValidationErrors, errors, setErrors]);
    return (<Form onSubmit={handleSubmit}>

        <TextField row={true} name={'code'} label={'Unieke code'}/>
        <TextField row={true} name={'name'} label={'Naam'}/>
        <div className="row mb-4">
            <div className="col-4">
                <label className="form-label" htmlFor="effectiveDate">Ingangsdatum</label>
            </div>
            <div className="col-8">
                <DateField fieldName='effectiveDate' placeholder={'Kies een ingangsdatum'}/>

            </div>
        </div>


        <TextField row={true} name={'discount'} label={'Korting'} type={'number'} inputGroupSuffixText={'%'}/>

        {props.type === 'priceListAndDiscountGroup' &&
            <>
                <div className="row mb-4">
                    <div className="col-4">
                        <label className="form-label" htmlFor="type">Prijslijst</label>
                    </div>
                    <div className="col-8">
                        <PriceListPicker name={'priceListId'}/>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-4">
                        <label className="form-label" htmlFor="type">Kortingsgroep</label>
                    </div>
                    <div className="col-8">
                        <DiscountGroupPicker name={'discountGroupId'}/>
                    </div>
                </div>
            </>
        }


        <div className="row pt-5">
            <div className="offset-4 col-8 d-flex justify-content-end">
                <SubmitButton type="submit" className="btn btn-primary"
                              isSubmitting={isSubmitting}>Opslaan</SubmitButton>
            </div>
        </div>

    </Form>);
}

export default DiscountRuleUpsert;
import React, {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {rolesColumns} from './columns/_columns'
import {EkoCard, EkoCardBody} from '../../../../../../_metronic/helpers'
import {RolesListPagination} from '../components/pagination/RolesListPagination'
import {User} from '@auth0/auth0-react'
import {useAuth0RolesData, useAuth0RolesDataLoading} from '../core/QueryResponseProvider'
import {EkoCardHeader} from "../../../../../shared/components/card/EkoCardHeader";
import {useListView} from "../core/ListViewProvider";
import {RolesListSearchComponent} from "../components/header/RolesListSearchComponent";
import {RolesListGrouping} from "../components/header/RolesListGrouping";
import {RolesListToolbar} from "../components/header/RolesListToolbar";

const RolesTable = () => {
  const roles = useAuth0RolesData()
  const {selected} = useListView()
  const isLoading = useAuth0RolesDataLoading()
  const data = useMemo(() => roles, [roles])
  const columns = useMemo(() => rolesColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })
  return (
      <EkoCard>
        <EkoCardHeader>
          <RolesListSearchComponent />
          {/* begin::Card toolbar */}
          <div className='card-toolbar'>
            {/* begin::Group actions */}
            {selected.length > 0 ? <RolesListGrouping /> : <RolesListToolbar />}
            {/* end::Group actions */}
          </div>

        </EkoCardHeader>
        <EkoCardBody className='py-4'>

      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<User>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<User>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    Geen rollen gevonden
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <RolesListPagination />
    </EkoCardBody>
      </EkoCard>
  )
}

export {RolesTable}

import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast"
import ApiClient from "../../../../../../modules/api-client/ApiClient";
import {
    OrderLineRepresentation,CopySillOrderLineModel
} from "../../../../../../modules/api-client/generated";
import { useEkosietMutation } from "../../../../../../shared/hooks/useEkosietMutation";
import { NotFound, ValidationFailed } from "../../../../../../modules/api-client/Responses";

export const useCopyOrderLine = (id: string) => {
    const queryClient = useQueryClient()
    return useEkosietMutation<OrderLineRepresentation, NotFound | ValidationFailed, CopySillOrderLineModel|undefined>
        ((model) => {

            return ApiClient.Sm.Orderline.smOrderlineIdCopyPut(id, undefined, model??undefined).then(response => response.data);
        },
            {
                onSuccess: (order) => {
                    queryClient.invalidateQueries(['smOrderIdGet', order.orderId]).then();
                    queryClient.invalidateQueries(['OrderActionLog', id]).then();

                    toast.success("Orderregel gekopieerd.");

                },
                onError: () => {
                    toast.error("Orderregel kopieren mislukt!");
                }
            }
        );
}
;

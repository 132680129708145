import React, { useCallback, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import ApiClient from '../../../../modules/api-client/ApiClient';
import { Link } from 'react-router-dom';
import { EkoCard, EkoCardBody } from '../../../../../_metronic/helpers';
import { EkoCardHeader, EkoCardToolbar } from '../../../../shared/components/card';
import {
    ContentBlockItem,
    ContentImageItem,
    ContentType, GetContentList200ResponseInner,
    NewsItemListItem, NotificationListItem
} from '../../../../modules/api-client/generated';
import Loading from '../../../../shared/components/Loading';
import Error from "../../../../shared/components/Error";
import { ConfirmDialog } from '../../../../shared/components/ConfirmDialog';
import { useContentDeleteMutation } from './hooks';
import { formatDate } from "../../../../shared/components/date";
import { headingsPlugin, MDXEditor } from "@mdxeditor/editor";

interface ContentListProps {
    contentType: ContentType
}

class ImageContent extends React.Component<{ list: ContentImageItem[] | undefined }> {
    render() {
        return <div className={'row'}>
            {this.props.list?.filter(x => x.image != null).map((ci) => (
                <div className={'col-2'} key={ci.id}>
                    <div className={'card border'}>
                        <img src={ci.image!.blobUri!} alt={ci.image!.altText!} className={'card-img-top'} />
                        <div className={'card-body'}>
                            <h5 className={'card-title'}>{ci.code}</h5>
                            <Link to={`../content/` + ci.id} relative={"path"} className=""><i className={'fas fa-edit'}></i> </Link>
                            <DeleteButton id={ci.id} />
                        </div>
                    </div>
                </div>
            ))}
        </div>;
    }
}
class NewsItems extends React.Component<{ list: NewsItemListItem[] | undefined }> {
    render() {
        return <div className={'row'}>
            {this.props.list?.map((data) => (
                <EkoCard className={'mt-3'} key={data.id}>
                    <EkoCardHeader title={data.code}>
                        <EkoCardToolbar>
                            <div className="fit-content">
                                <Link to={`../content/` + data.id!} relative='path' className={'btn btn-light btn-sm me-1'}><i
                                    className={'fas fa-edit'}></i></Link>
                                <DeleteButton id={data.id!} />
                            </div>
                        </EkoCardToolbar>
                    </EkoCardHeader>
                    <EkoCardBody>
                        <div className={'row'}>
                            <div className={'col-3'}>
                                <img src={data.image?.blobUri ?? ''} alt={data.image?.altText ?? ''} className={'rounded'} />
                            </div>
                            <div className={'col'}>
                                <MDXEditor markdown={data?.message ?? ""} plugins={[headingsPlugin()]} readOnly />
                                <div className={'text-muted mt-2'}>Geplaatst op: {formatDate(data.datePosted ?? "")} </div>
                            </div>
                        </div>
                    </EkoCardBody>
                </EkoCard>
            ))}
        </div>;
    }
}
class GeneralContent extends React.Component<{ list: ContentBlockItem[] | undefined }> {
    render() {
        return <div className={'row'}>
            {this.props.list?.map((data) => (
                <EkoCard className={'mt-3'} key={data.id}>
                    <EkoCardHeader title={data.code}>
                        <EkoCardToolbar>
                            <div className="fit-content">
                                <Link to={`../content/` + data.id!} relative='path' className={'btn btn-light btn-sm me-1'}><i
                                    className={'fas fa-edit'}></i></Link>
                                <DeleteButton id={data.id!} />
                            </div>
                        </EkoCardToolbar>
                    </EkoCardHeader>
                    <EkoCardBody>
                        <MDXEditor markdown={data?.content ?? ""} plugins={[headingsPlugin()]} readOnly />

                    </EkoCardBody>
                </EkoCard>
            ))}
        </div>;
    }
}

class NotificationContent extends React.Component<{ list: NotificationListItem[] | undefined }> {
    render() {
        return <div className={'row'}>
            {this.props.list?.map((data) => (
                <EkoCard className={'mt-3'} key={data.id}>
                    <EkoCardHeader title={data.code}>
                        <EkoCardToolbar>
                            <div className="fit-content">
                                <Link to={`../content/` + data.id!} relative='path' className={'btn btn-light btn-sm me-1'}><i
                                    className={'fas fa-edit'}></i></Link>
                                <DeleteButton id={data.id!} />
                            </div>
                        </EkoCardToolbar>
                    </EkoCardHeader>
                    <EkoCardBody>
                        <MDXEditor markdown={data?.message ?? ""} plugins={[headingsPlugin()]} readOnly />
                        <div className={'text-muted mt-2'}>Geplaatst van: {formatDate(data.validFrom ?? "")} - {formatDate(data.validTo ?? "")} </div>

                    </EkoCardBody>
                </EkoCard>
            ))}
        </div>;
    }
}

const ContentList: React.FC<ContentListProps> = ({ contentType }) => {
    const {
        isInitialLoading,
        data: list,
        isError
    } = useQuery<GetContentList200ResponseInner[]>(['homepageContentListGet', contentType], () => ApiClient.Homepage.getContentList(contentType).then((res) => res.data));

    if (isInitialLoading) return <Loading />;

    if (isError) return <Error />;
    const contentComponent = () => {
        switch (contentType) {
            case ContentType.Image:
                return <ImageContent list={list} />;
            case ContentType.NewsItem:
                return <NewsItems list={list as NewsItemListItem[]} />;
            case ContentType.ContentBlock:
                return <GeneralContent list={list as ContentBlockItem[]} />;
            case ContentType.Notification:
                return <NotificationContent list={list as NotificationListItem[]} />;
            default:
                return <GeneralContent list={list} />;
        }
    }
    return (
        <>
            <div className={'d-flex justify-content-between'}>
                <h4>Content</h4>
                <Link to={`../content/add`} relative={"path"} className='btn btn-primary btn-sm'>Toevoegen</Link>
            </div>
            {contentComponent()}
        </>
    );
};

const DeleteButton: React.FC<{ id: string }> = ({ id }) => {
    const openDeleteDialog = useRef<(show: boolean) => void>();

    const deleteMutation = useContentDeleteMutation(id);
    const deleteCallback = useCallback(async () => {
        await deleteMutation.mutateAsync(id!);
    }, [deleteMutation, id]);
    return (
        <>
            <button type="button" className="text-danger btn btn-link"
                onClick={() => openDeleteDialog.current?.call(this, true)}>Verwijderen
            </button>

            <ConfirmDialog show={openDeleteDialog}
                onConfirm={deleteCallback}
                dialogStyle="danger"
                title="Verwijderen"
                message="Wil je doorgaan?"
                confirmText="Definitief verwijderen"
            />
        </>
    )
}

export default ContentList;
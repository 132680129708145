import instance from './AxiosClient';
import {API_GATEWAY_BASE_URL} from '../../constants';
import {
    CatalogApi,
    CatalogApiInterface,
    CatalogItemApi,
    CatalogItemApiInterface,
    CategoryApi,
    CategoryApiInterface,
    ColorApi,
    ColorApiInterface,
    ConfiguratorApi,
    ConfiguratorApiInterface,
    CornerPieceApi,
    CornerPieceApiInterface,
    CustomerApi,
    CustomerApiInterface,
    // DevSeedApi,
    // DevSeedApiInterface,
    DiscountGroupApi,
    DiscountGroupApiInterface,
    DiscountRuleApi,
    DiscountRuleApiInterface,
    GlazingBarApi,
    GlazingBarApiInterface,
    GlazingProfileApi,
    GlazingProfileApiInterface,
    MountingOptionApi,
    MountingOptionApiInterface,
    OrderApi,
    OrderApiInterface,
    OrderLineApi,
    OrderLineApiInterface,
    PriceApi,
    PriceApiInterface,
    PriceListApi,
    PriceListApiInterface,
    RabbetApi,
    RabbetApiInterface,
    OrderRemarkApi,
    OrderRemarkApiInterface,
    SillApi,
    SillApiInterface,
    SillDrainageOptionApi,
    SillDrainageOptionApiInterface,
    SillWrappingOptionApi,
    SillWrappingOptionApiInterface,
    SluitpotApi,
    SluitpotApiInterface,
    StopProfileApi,
    StopProfileApiInterface,
    ProfileApiInterface,
    ProfileApi,
    PrintApiInterface,
    PrintApi,
    TurboholTypeApiInterface,
    TurboholTypeApi,
    CompartmentLayoutTypeApiInterface,
    CompartmentLayoutTypeApi,
    SystemAdminApi,
    SystemAdminApiInterface,
    InvoiceApi,
    ExpeditionApi,
    InvoiceApiInterface,
    ExpeditionApiInterface,
    EmailApiInterface,
    EmailApi,
    RenderApiInterface,
    RenderApi,
    SluitpotBeslagApiInterface,
    SluitpotBeslagApi,
    ProductionBatchApi,
    ProductionBatchApiInterface,
    ProductionItemApiInterface,
    ProductionItemApi,
    ProductionItemGroupApi,
    ProductionItemGroupApiInterface,
    SluitpotBlockTypeApiInterface,
    SluitpotBlockTypeApi,
    ProductionPlanningApi,
    ProductionPlanningApiInterface,
    ProductionSillApiInterface,
    ProductionSillApi,
    ConfiguredSillApi,
    ConfiguredSillApiInterface,
    LProfileApi,
    LProfileApiInterface,
    ReportApiInterface,
    ReportApi,
    ReportingApiInterface,
    ReportingApi,
    ProfileGenerationApiInterface,
    ProfileGenerationApi,
    NeutApiInterface,
    NeutApi,
    GroeneveldApi,
    GroeneveldApiInterface,
    ImportApiInterface,
    ImportApi,
    SillProfilesApiInterface,
    SillProfilesApi,
    SillNeutPositionApi,
    SillNeutPositionApiInterface,
    ClientConfig,
    ImportMappingApiInterface,
    ImportMappingApi,
    ImportValidationApiInterface,
    ImportValidationApi,
    HomepageApi,
    HomepageApiInterface,
    FileStorageApi,
    FileStorageApiInterface,
    ProductionOperationApiInterface, ProductionOperationApi,
    ContactFormApi,
    NewsletterApi, EmployeeApiInterface, EmployeeApi, DxfApi, DxfApiInterface
} from './generated';

const config = new ClientConfig({
    basePath: API_GATEWAY_BASE_URL,
});

export interface IApiClient {

    Core: {
        ConfiguredSill: ConfiguredSillApiInterface;
    },

    Pim: {
        SystemAdmin: SystemAdminApiInterface;
        Dxf: DxfApiInterface;
        Color: ColorApiInterface;
        Configurator: ConfiguratorApiInterface;
        CornerPiece: CornerPieceApiInterface;
        MountingOption: MountingOptionApiInterface;
        WrappingOption: SillWrappingOptionApiInterface;
        GlazingBar: GlazingBarApiInterface;
        GlazingProfile: GlazingProfileApiInterface;
        LProfile: LProfileApiInterface;

        Neuten: NeutApiInterface;

        StopProfile: StopProfileApiInterface;
        Sluitpot: SluitpotApiInterface;
        SluitpotBeslag: SluitpotBeslagApiInterface;
        SluitpotBlockType: SluitpotBlockTypeApiInterface;
        Sill: SillApiInterface;
        SillProfiles: SillProfilesApiInterface;
        SillNeutPosition: SillNeutPositionApiInterface;
        Rabbet: RabbetApiInterface;
        SillDrainageOption: SillDrainageOptionApiInterface;

        Catalog: CatalogApiInterface;
        CatalogItem: CatalogItemApiInterface;

        Category: CategoryApiInterface;


        Profile: ProfileApiInterface;
        ProfileGeneration: ProfileGenerationApiInterface;
        TurboholType: TurboholTypeApiInterface;
        CompartmentLayoutType: CompartmentLayoutTypeApiInterface;
        Render: RenderApiInterface;
    };
    Crm: {
        Customer: CustomerApiInterface;
        Reporting: ReportingApiInterface;
        Newsletter: NewsletterApi;
        ContactForm: ContactFormApi;
    };
    // Dev: {
    // 	Seed: DevSeedApiInterface;
    // };
    Sm: {
        Order: OrderApiInterface;
        Orderline: OrderLineApiInterface;
        Remark: OrderRemarkApiInterface;
        Print: PrintApiInterface;
        Email: EmailApiInterface;
        Report: ReportApiInterface;
        PriceList: PriceListApiInterface;
        DiscountGroup: DiscountGroupApiInterface;
        DiscountRule: DiscountRuleApiInterface;
        Pricing: PriceApiInterface;
    };
    Fm: {
        Invoice: InvoiceApiInterface;
    };
    Em: {
        Expedition: ExpeditionApiInterface;
        Reporting: ReportingApiInterface;
    };
    Pm: {
        Employee: EmployeeApiInterface;
        Reporting: ReportingApiInterface;
        SystemAdmin: SystemAdminApiInterface;
        ProductionBatch: ProductionBatchApiInterface;
        ProductionItem: ProductionItemApiInterface;
        ProductionItemGroup: ProductionItemGroupApiInterface;
        ProductionPlanning: ProductionPlanningApiInterface;
        ProductionSill: ProductionSillApiInterface;
        ProductionOperation: ProductionOperationApiInterface;
    };


    Acl: {
        Groeneveld: GroeneveldApiInterface;
        Import: ImportApiInterface;
        ImportMapping: ImportMappingApiInterface;
        ImportValidation: ImportValidationApiInterface;
    };

    SystemAdmin: SystemAdminApiInterface;

    Homepage: HomepageApiInterface;

    FileStorage: FileStorageApiInterface;
}

const ApiClientInstance: IApiClient = {
    Core: {
        ConfiguredSill: new ConfiguredSillApi(config, API_GATEWAY_BASE_URL, instance),
    },

    Pim: {
        SystemAdmin: new SystemAdminApi(config, API_GATEWAY_BASE_URL, instance),
        Dxf: new DxfApi(config, API_GATEWAY_BASE_URL, instance),
        Color: new ColorApi(config, API_GATEWAY_BASE_URL, instance),
        CornerPiece: new CornerPieceApi(config, API_GATEWAY_BASE_URL, instance),
        MountingOption: new MountingOptionApi(config, API_GATEWAY_BASE_URL, instance),
        WrappingOption: new SillWrappingOptionApi(config, API_GATEWAY_BASE_URL, instance),
        GlazingBar: new GlazingBarApi(config, API_GATEWAY_BASE_URL, instance),
        GlazingProfile: new GlazingProfileApi(config, API_GATEWAY_BASE_URL, instance),
        StopProfile: new StopProfileApi(config, API_GATEWAY_BASE_URL, instance),
        LProfile: new LProfileApi(config, API_GATEWAY_BASE_URL, instance),

        Neuten: new NeutApi(config, API_GATEWAY_BASE_URL, instance),

        Sluitpot: new SluitpotApi(config, API_GATEWAY_BASE_URL, instance),
        SluitpotBeslag: new SluitpotBeslagApi(config, API_GATEWAY_BASE_URL, instance),
        SluitpotBlockType: new SluitpotBlockTypeApi(config, API_GATEWAY_BASE_URL, instance),
        Sill: new SillApi(config, API_GATEWAY_BASE_URL, instance),
        SillProfiles: new SillProfilesApi(config, API_GATEWAY_BASE_URL, instance),
        SillNeutPosition: new SillNeutPositionApi(config, API_GATEWAY_BASE_URL, instance),
        Rabbet: new RabbetApi(config, API_GATEWAY_BASE_URL, instance),
        SillDrainageOption: new SillDrainageOptionApi(config, API_GATEWAY_BASE_URL, instance),
        Configurator: new ConfiguratorApi(config, API_GATEWAY_BASE_URL, instance),

        Catalog: new CatalogApi(config, API_GATEWAY_BASE_URL, instance),
        CatalogItem: new CatalogItemApi(config, API_GATEWAY_BASE_URL, instance),
        Category: new CategoryApi(config, API_GATEWAY_BASE_URL, instance),

        Profile: new ProfileApi(config, API_GATEWAY_BASE_URL, instance),
        ProfileGeneration: new ProfileGenerationApi(config, API_GATEWAY_BASE_URL, instance),
        TurboholType: new TurboholTypeApi(config, API_GATEWAY_BASE_URL, instance),
        CompartmentLayoutType: new CompartmentLayoutTypeApi(config, API_GATEWAY_BASE_URL, instance),
        Render: new RenderApi(config, API_GATEWAY_BASE_URL, instance),

    },
    // Dev: {
    // 	Seed: new DevSeedApi(config, API_GATEWAY_BASE_URL, instance),
    // },
    Crm: {
        Customer: new CustomerApi(config, API_GATEWAY_BASE_URL, instance),
        Reporting: new ReportingApi(config, API_GATEWAY_BASE_URL, instance),
        Newsletter: new NewsletterApi(config, API_GATEWAY_BASE_URL, instance),
        ContactForm: new ContactFormApi(config, API_GATEWAY_BASE_URL, instance),
    },
    Sm: {
        Order: new OrderApi(config, API_GATEWAY_BASE_URL, instance),
        Orderline: new OrderLineApi(config, API_GATEWAY_BASE_URL, instance),
        Remark: new OrderRemarkApi(config, API_GATEWAY_BASE_URL, instance),
        Print: new PrintApi(config, API_GATEWAY_BASE_URL, instance),
        Email: new EmailApi(config, API_GATEWAY_BASE_URL, instance),
        Report: new ReportApi(config, API_GATEWAY_BASE_URL, instance),
        PriceList: new PriceListApi(config, API_GATEWAY_BASE_URL, instance),
        DiscountGroup: new DiscountGroupApi(config, API_GATEWAY_BASE_URL, instance),
        DiscountRule: new DiscountRuleApi(config, API_GATEWAY_BASE_URL, instance),
        Pricing: new PriceApi(config, API_GATEWAY_BASE_URL, instance),
    },
    Fm: {
        Invoice: new InvoiceApi(config, API_GATEWAY_BASE_URL, instance),
    },
    Em: {
        Expedition: new ExpeditionApi(config, API_GATEWAY_BASE_URL, instance),
        Reporting: new ReportingApi(config, API_GATEWAY_BASE_URL, instance),
    },
    Pm: {
        Employee: new EmployeeApi(config, API_GATEWAY_BASE_URL, instance),
        Reporting: new ReportingApi(config, API_GATEWAY_BASE_URL, instance),
        SystemAdmin: new SystemAdminApi(config, API_GATEWAY_BASE_URL, instance),
        ProductionBatch: new ProductionBatchApi(config, API_GATEWAY_BASE_URL, instance),
        ProductionItem: new ProductionItemApi(config, API_GATEWAY_BASE_URL, instance),
        ProductionItemGroup: new ProductionItemGroupApi(config, API_GATEWAY_BASE_URL, instance),
        ProductionPlanning: new ProductionPlanningApi(config, API_GATEWAY_BASE_URL, instance),
        ProductionSill: new ProductionSillApi(config, API_GATEWAY_BASE_URL, instance),
        ProductionOperation: new ProductionOperationApi(config, API_GATEWAY_BASE_URL, instance),
    },

    SystemAdmin: new SystemAdminApi(config, API_GATEWAY_BASE_URL, instance),

    Acl: {
        Groeneveld: new GroeneveldApi(config, API_GATEWAY_BASE_URL, instance),
        Import: new ImportApi(config, API_GATEWAY_BASE_URL, instance),
        ImportMapping: new ImportMappingApi(config, API_GATEWAY_BASE_URL, instance),
        ImportValidation: new ImportValidationApi(config, API_GATEWAY_BASE_URL, instance)
    },

    Homepage: new HomepageApi(config, API_GATEWAY_BASE_URL, instance),

    FileStorage: new FileStorageApi(config, API_GATEWAY_BASE_URL, instance),
};

export default ApiClientInstance;

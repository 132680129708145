import {FC} from 'react'
import {WithChildren} from '../../../../_metronic/helpers'
import clsx from "clsx";

type Props = {
  className?: string
  scroll?: boolean
  height?: number
  title?: string
}

const EkoCardHeader: FC<Props & WithChildren> = ({className, scroll, height, children,title}) => {
  return (
      <div  className={clsx(
          'card-header',
          className && className,
      )}>
          <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>{title}</h3>
          </div>
          {children}
      </div>
  )
}

export {EkoCardHeader}

import React from 'react';
import BooleanBadge from "./BooleanBadge";

export interface UrgentBadgeProps {
    value: boolean | undefined | null;
}

const UrgentBadge: React.FC<UrgentBadgeProps> = ({...props}) => {

    if (typeof props.value === 'undefined' || props.value === null || !props.value) {
        return <></>;
    }

    return (
        <BooleanBadge 
            value={props.value} 
            trueText={'SPOED'} 
            trueClass={'bg-danger'} 
            falseText={'-'}
            falseClass={'bg-light'}
            />
    )


};

export default UrgentBadge;
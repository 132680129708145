/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from "react";
import {PageTitle} from "../../../../../_metronic/layout/core";
import {EkoCard,EkoCardBody} from "../../../../shared/components/card";
import {FilterContextProvider} from "../../../../shared/context/FilterContext";
import {CustomerTable} from "../components/CustomerTable";
import {CustomerModal} from "../components/CustomerModal";
import {CustomerSearch} from "../components/CustomerSearch";

export function CustomersPage() {

    const [showCustomerModal, setShowCustomerModal] = useState<boolean>(false)
    const [customerId, setCustomerId] = useState<string | undefined | null>(null);
    const handleCloseCustomerModal = () => {
        setShowCustomerModal(false);
    };
    const addCustomer = () => {
        setShowCustomerModal(true);
        setCustomerId(null);
    }

    return (
        <>
            <PageTitle toolbar={
                <div className={'d-flex align-items-center'}>
                <button type='button' className='btn btn-primary' onClick={addCustomer}>
                    <i className={'fas fa-plus'}/>
                    Klant toevoegen
                </button>
            </div>
            }>
                Klanten
            </PageTitle>
                    <FilterContextProvider>
                        <EkoCard className={'mb-5'}>
                            <EkoCardBody>
                        <CustomerSearch />
                            </EkoCardBody>
                        </EkoCard>
                                <EkoCard>
                                    <EkoCardBody>
                        <CustomerTable />
                                    </EkoCardBody>
                                </EkoCard>
                    </FilterContextProvider>

            <CustomerModal
                show={showCustomerModal}
                customerId={customerId}
                handleClose={handleCloseCustomerModal}
            />
        </>
    )
}

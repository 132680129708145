import React, {useId} from 'react';
import {useQuery} from '@tanstack/react-query';
import ApiClient from '../../../../modules/api-client/ApiClient';
import {Link, useSearchParams} from 'react-router-dom';
import {Rabbet, RabbetType} from '../../../../modules/api-client/generated';
import RabbetTypeText from './RabbetTypeText';
import RabbetSvg from '../../../../shared/components/RabbetSvg';
import ActiveBadge from '../../../../shared/components/ActiveBadge';
import RabbetApplicationText from './RabbetApplicationText';
import {EkoCard, EkoCardBody} from '../../../../../_metronic/helpers';
import {EkoTable} from '../../../../shared/components/table/EkoTable';
import {TableHeader} from '../../../../shared/components/table/TableHeader';
import {EkoCardHeader} from '../../../../shared/components/card/EkoCardHeader';
import {EkoCardToolbar} from '../../../../shared/components/card/EkoCardToolbar';
import Loading from '../../../../shared/components/Loading';
import {useQueryParam} from "../../../../shared/hooks/useQueryParam";


const RabbetList: React.FC = () => {

    const id = useId();
    const [searchParams, setSearchParams] = useSearchParams();
    const rabbetTypes = searchParams.getAll('rabbetTypes') as RabbetType[] ?? [];

    const {
        isInitialLoading,
        isLoading,
        data: rabbetList,
        isError,
    } = useQuery<Rabbet[]>(['ApiClient.Pim.Rabbet.listRabbets', rabbetTypes],
        async () => {
            const response = await ApiClient.Pim.Rabbet.listRabbets(rabbetTypes);
            return response.data;
        });


    const toggleRabbetTypeFilter = (rabbetType: RabbetType, selected: boolean) => {

        console.log(rabbetType);
        if (selected && !rabbetTypes.includes(rabbetType)) {
            const params = new URLSearchParams(searchParams);
            params.append('rabbetTypes', rabbetType);
            setSearchParams(params);
        } else if (!selected && rabbetTypes.includes(rabbetType)) {

            const params = new URLSearchParams(searchParams);
            const newRabbetTypes = rabbetTypes.filter(x => x !== rabbetType);

            params.delete('rabbetTypes');

            if (newRabbetTypes.length > 0) {
                newRabbetTypes.forEach(x => params.append('rabbetTypes', x));
            }
            setSearchParams(params);
        }
    }

    return <>
        <EkoCard>
            <EkoCardHeader title={'Sponningen'}>
                <EkoCardToolbar>
                    <Link to={'add'} className='btn btn-primary btn-sm'>Toevoegen</Link>
                </EkoCardToolbar>
            </EkoCardHeader>
            <EkoCardBody>

                <div className="d-flex gap-2">
                    {[RabbetType.Stomp,
                        RabbetType.DraaiKiep,
                        RabbetType.BinnendraaiendStomp,
                        RabbetType.DubbeleSponning,
                        RabbetType.HefSchuif,
                        RabbetType.Groef,
                        RabbetType.HoekProfile
                    ].map(type => {

                        return <React.Fragment key={type}>
                            <input type="checkbox" className="btn-check" id={`${id}-${type}`} autoComplete="off"
                                   checked={rabbetTypes.includes(type)}
                                   onChange={e => toggleRabbetTypeFilter(type, e.target.checked)}
                            />
                            <label className="btn btn-primary" htmlFor={`${id}-${type}`}><RabbetTypeText
                                code={type}/></label>
                        </React.Fragment>;
                    })}
                </div>


                {isInitialLoading && <Loading/> || <EkoTable>
                    <TableHeader>
                        <th></th>
                        <th>Code</th>
                        <th>Naam</th>
                        <th>Type</th>
                        <th>Toepassing</th>
                        <th></th>

                    </TableHeader>
                    <tbody>

                    {(!isLoading && (!rabbetList || rabbetList.length === 0)) &&
                        <tr>
                            <td colSpan={7} className='text-center'>Geen gegevens</td>
                        </tr>}

                    {rabbetList && rabbetList.map((rabbet, index) => <tr key={index}>

                        <td className='fit-content'>
                            <div style={{height: '60px', width: '60px'}} className='d-flex'>
                                <RabbetSvg profileSvg={rabbet.svgPreview}/>
                            </div>
                        </td>
                        <td className='fit-content'>
                            <Link to={rabbet.id!} relative='path'>{rabbet.code}</Link>
                        </td>
                        <td>
                            <div>
                                {rabbet.name}
                            </div>
                            <div className='text-muted'>
                                {
                                    [`${rabbet.width}x${rabbet.depth}mm`,
                                        rabbet.turboholRabbet ? `${rabbet.turboholRabbet.width}x${rabbet.turboholRabbet.depth}mm` : null,
                                        rabbet.turboholRabbet?.turboholType?.name,
                                        rabbet.turboholRabbet?.aanslagNok ? `${rabbet.turboholRabbet.aanslagNok.width}x${rabbet.turboholRabbet.aanslagNok.depth}mm` : null,
                                    ].filter(x => x).join(' | ')
                                }
                            </div>
                        </td>
                        <td className='fit-content'>
                            <RabbetTypeText code={rabbet.type}/>
                        </td>
                        <td className='fit-content'>
                            <RabbetApplicationText code={rabbet.application}/>
                        </td>
                        <td className='fit-content'>
                            <ActiveBadge value={rabbet.active}/>
                        </td>
                    </tr>)}

                    </tbody>
                </EkoTable>

                }
                <div className="text-muted">{rabbetList?.length ?? 0} sponningen</div>
            </EkoCardBody>
        </EkoCard>
    </>;
};

export default RabbetList;
import {NeutListItem} from '../../../../modules/api-client/generated';
import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import {useListNeutenOdata} from '../../pim/neut/use-list-neuten-odata';
import {debounce} from 'lodash';
import {Button, Modal} from 'react-bootstrap';
import {modalsRoot} from '../../../../global-helpers';
import {EkoTable} from '../../../../shared/components/table';
import {Link} from 'react-router-dom';
import ConfiguredNeutSvg from '../../../../shared/components/ConfiguredNeutSvg';
import SpinnerButton from '../../../../shared/components/SpinnerButton';

export type NeutPickerDialogHandle = {
	isOpen: boolean;
	open: () => void;
	close: () => void;
	component: React.ReactNode;
};

export const useNeutPickerDialog = (props: Omit<NeutPickerDialogProps, 'open' | 'onClose'>): NeutPickerDialogHandle => {
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const open = () => {
		setIsOpen(true);
	};

	const onClose = () => {
		setIsOpen(false);
	};
	const onSelectionConfirmed = async (selection: NeutListItem[]) => {
		await props.onSelectionConfirmed(selection);
		onClose();
	};

	const p: NeutPickerDialogProps = {
		...props,
		open: isOpen,
		onClose: onClose,
		onSelectionConfirmed: onSelectionConfirmed,
	};
	const component = <> {isOpen && <NeutPickerDialog {...p} />}</>;

	return {isOpen, open, close: onClose, component};
};

export type NeutPickerDialogProps = {
	open: boolean;
	onClose: () => void;
	onSelectionConfirmed: (selection: NeutListItem[]) => Promise<void>;
	onPick?: (selection: NeutListItem[]) => Promise<void>;
	confirmText?: string;
	mode?: 'single' | 'multi';
};

export const NeutPickerDialog: FC<NeutPickerDialogProps> = (props: NeutPickerDialogProps) => {
	const [selection, setSelection] = useState<{
		items: Map<string, NeutListItem>;
	}>({items: new Map<string, NeutListItem>()});

	const [search, setSearch] = useState<string>('');
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [isSearching, setSearching] = useState<boolean>(false);
	const [isConfirming, setConfirming] = useState<boolean>(false);

	const searchRef = useRef<HTMLInputElement | null>(null);

	const isMulti = props.mode === 'multi';

	const {isInitialLoading, data: list, isError} = useListNeutenOdata(25, 0, undefined, undefined, searchTerm, true, !!searchTerm);

	const onHide = useCallback(() => props.onClose(), []);

	const focusOnSearch = useCallback(() => {
		searchRef.current?.focus();
	}, [searchRef]);

	useEffect(() => focusOnSearch(), [focusOnSearch]);

	const [debounceSearch] = useState(() => {
		return debounce((search: string) => {
			setSearchTerm(search);
		}, 1000);
	});

	const resetSearch = () => {
		setSearchTerm('');
		setSearch('');
	};

	const onSearchChange = (value: string) => {
		setSearch(value);
		debounceSearch.cancel();
		if (!value) {
			setSearchTerm(value);
		} else {
			debounceSearch(value);
		}
	};

	// const handleSelection = (selected: boolean, neut: NeutListItem) => {
	// 	setSelection((current) => {
	// 		let newSelection = {items: current.items};
	// 		if (selected) {
	// 			newSelection.items.set(neut.id, neut);
	// 		} else {
	// 			newSelection.items.delete(neut.id);
	// 		}
	//
	// 		return newSelection;
	// 	});
	// };

	const toggleSelection = (neut: NeutListItem) => {
		setSelection((current) => {
			let newSelection = {items: current.items};
			if (!current.items.has(neut.id)) {
				newSelection.items.set(neut.id, neut);
				resetSearch();
				focusOnSearch();
			} else {
				newSelection.items.delete(neut.id);
			}
			return newSelection;
		});
	};

	const confirmSelection = async () => {
		setConfirming(true);
		try {
			await props.onSelectionConfirmed(Array.from(selection.items.values()));
		} finally {
			setConfirming(false);
		}
	};

	return (
		<Modal
			container={modalsRoot}
			tabIndex={-1}
			aria-hidden="true"
			autoFocus={true}
			dialogClassName={'modal-dialog'}
			scrollable={true}
			show={props.open}
			size="lg"
			onHide={onHide}
			animation={false}
			backdrop={'static'}
		>
			<Modal.Header closeButton>
				<Modal.Title>Selecteer {isMulti ? 'neuten' : 'neut'}</Modal.Title>
			</Modal.Header>
			<Modal.Body className="position-relative p-0">
				<div className="px-5 p-5 sticky-top bg-white">
					<div className="input-group position-relative">
						<input ref={searchRef} value={search} onChange={(e) => onSearchChange(e.target.value)} type="text" className="form-control" placeholder="Type om op neut code te zoeken" />
						{isInitialLoading && (
							<div className="position-absolute top-50 end-0 translate-middle" style={{zIndex: 5}}>
								<div className="spinner-border text-primary" role="status">
									<span className="visually-hidden">Loading...</span>
								</div>
							</div>
						)}
					</div>
				</div>
				<div className="px-5">
					<EkoTable>
						<tbody>
							{isError && (
								<>
									<tr>
										<td colSpan={7}>
											<div className="alert alert-danger" role="alert">
												<h4 className="alert-heading">Error</h4>
												<p>Error loading neuten</p>
											</div>
										</td>
									</tr>
								</>
							)}

							{searchTerm && !isInitialLoading && !isError && (!list || list.value.length === 0) && (
								<>
									<tr>
										<td colSpan={7} className="text-center">
											Geen neuten gevonden
										</td>
									</tr>
								</>
							)}

							{list &&
								list.value.map((neut, index) => (
									<tr key={neut.id} className="cursor-pointer" onClick={() => toggleSelection(neut)}>
										{/*<td className="p-0 fit-content" style={{height: '1px'}}>*/}
										{/*	<label className="w-100 h-100 d-flex justify-content-center align-items-center cursor-pointer">*/}
										{/*		<input*/}
										{/*			className="form-check-input m-0"*/}
										{/*			type="checkbox"*/}
										{/*			value={neut.id}*/}
										{/*			checked={selection.items.has(neut.id)}*/}
										{/*			onChange={(e) => handleSelection(e.target.checked, neut)}*/}
										{/*		/>*/}
										{/*	</label>*/}
										{/*</td>*/}

										<td className="fit-content">
											<Link to={neut.id}>{neut.code}</Link>
										</td>
										<td>
											<ConfiguredNeutSvg svg={neut.svg} svgWidth={'65px'} svgHeight={'95px'} />
										</td>
										<td className="">
											<div>{neut.profileLeft}</div>
											<div>{neut.profileRight}</div>
										</td>
										<td className="text-nowrap">
											{neut.height}x{neut.width}mm
										</td>
										<td className="text-nowrap">{neut.sillModel}</td>
										<td className="text-nowrap">
											<button type="button" className="btn btn-primary">
												Selecteren
											</button>
										</td>
									</tr>
								))}
						</tbody>
					</EkoTable>
				</div>
			</Modal.Body>
			<Modal.Footer className="justify-content-between">
				<div className="d-flex align-items-center">
					{selection.items.size > 0 && (
						<span className="badge bg-success">
							{selection.items.size} {selection.items.size === 1 ? 'neut' : 'neuten'} geselecteerd
						</span>
					)}
				</div>
				<div className="d-flex">
					<Button variant="link" className="" onClick={() => props.onClose()}>
						Annuleren
					</Button>
					<SpinnerButton type="button" className="btn btn-primary ms-4" disabled={isConfirming || selection.items.size === 0} onClick={confirmSelection} spinning={isConfirming}>
						{props.confirmText || 'Selectie bevestigen'}
					</SpinnerButton>
				</div>
			</Modal.Footer>
		</Modal>
	);
};

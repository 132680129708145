import {useQueryClient, useMutation} from "@tanstack/react-query"
import {QUERIES} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteSelectedUsers} from '../../core/_requests'
import {toast} from 'react-hot-toast'

const UsersListGrouping = () => {
  const {selected, clearSelected} = useListView()
  const queryClient = useQueryClient()
  const {query} = useQueryResponse()

  const deleteSelectedItems = useMutation(() => deleteSelectedUsers(selected), {    
    onSuccess: () => {      
      toast.success('Verwijderen van gebruikers gelukt.')
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      clearSelected()
    },
    onError: (err: any) => {
      toast.error('Verwijderen van gebruikers mislukt. Fout: ' + err?.response?.data?.detail)
    },
  })

  return (
    <div className='d-flex justify-content-end align-items-center'>
      <div className='fw-bolder me-5'>
        <span className='me-2'>{selected.length}</span>{' '}
        geselecteerd
      </div>

      <button
        type='button'
        className='btn btn-danger'
        onClick={async () => await deleteSelectedItems.mutateAsync()}
      >
        Verwijder geselecteerde
      </button>
    </div>
  )
}

export {UsersListGrouping}

/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../pages/ErrorsPage'
import {Logout, LoginWidget} from '../auth'
import {App} from '../App'
import Protected from './ProtectedRoute'
import { useEkosietAuth0 } from '../modules/auth0/core/useEkosietAuth0'
import {CustomerRoutePath} from "../customer-portal/routing/RoutePath";
import {BackofficeRoutePath} from "../backoffice/RoutePath";
import { PmRoutePath } from '../backoffice/modules/pm/PmRoutes'
import {EmRoutePath} from "../backoffice/modules/em/EmRoutes";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {

  const { isProductieMedewerker,isTransportMedewerker,isExpeditieMedewerker, isKlant } = useEkosietAuth0();
  const startUrl = isKlant ? CustomerRoutePath.link(CustomerRoutePath.dashboard) :
      isProductieMedewerker ? PmRoutePath.link(PmRoutePath.stationsDashboard) :
          isTransportMedewerker ? EmRoutePath.link(EmRoutePath.planningTransporteur):
              isExpeditieMedewerker ? EmRoutePath.link(EmRoutePath.planning):
          BackofficeRoutePath.dashboard;

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          <Route
            path='/*'
            element={
              <Protected>
                <PrivateRoutes />
              </Protected>
            }
          />
          <Route
            index
            element={
              <Protected>
                <Navigate to={startUrl} />
              </Protected>
            }
          />
          <Route path='auth/*' element={<LoginWidget />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}


export {AppRoutes}

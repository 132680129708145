import React, {FC, useEffect, useState} from 'react';
import {
    ConfiguratorSluitpotBeslag,
    SillConfigurationCompartment,
    SillConfigurationSluitpot,
} from '../../../modules/api-client/generated';
import {useFormikContext} from 'formik';
import {CompartmentEditorOptions} from './CompartmentEditor';
import {SluitpotTable} from './SluitpotTable';
import {SluitpotModal} from './SluitpotModal';
import {SluitpotEditorProps} from './SluitpotEditor';

export const CompartmentSluitpotten: FC<CompartmentEditorOptions> = (props) => {
    const {values, setValues} = useFormikContext<SillConfigurationCompartment>();

    const sluitpotCount = values.sluitpotten?.length ?? 0;

    const [enabled, setEnabled] = useState<boolean>((sluitpotCount ?? 0) > 0);
    const [isAddModalOpen, setAddModalOpen] = useState<boolean>(false);

    const sluitpotOptions = props.compartmentLayoutOptions
        .find(o => o.code === values.compartmentLayoutTypeCode)?.sluitpotten ?? {};

    let hardwareOptions: ConfiguratorSluitpotBeslag[] = [];

    for (let areaId in sluitpotOptions) {
        if (areaId in sluitpotOptions && sluitpotOptions[areaId]) {
            hardwareOptions = sluitpotOptions[areaId]!;
            break;
        }
    }

    useEffect(() => {
        if (enabled && sluitpotCount === 0) {
            setEnabled(false);
        }
    }, [sluitpotCount]);

    const enable = async (enabled: boolean) => {
        setEnabled(enabled);
        if (enabled && sluitpotCount === 0) {
            setAddModalOpen(true);
        }

        if (!enabled) {
            await setValues((current) => {
                return {...current, sluitpotten: []};
            });
        }
    };

    const onModalClose = () => {
        if (sluitpotCount === 0) {
            setEnabled(false);
        }
        setAddModalOpen(false);
    };

    const onSluitpotAdd = async (sluitpot: SillConfigurationSluitpot) => {
        await setValues((current) => {
            return {...current, sluitpotten: [...(current.sluitpotten ?? []), sluitpot]};
        });
        setAddModalOpen(false);
    };

    if (hardwareOptions.length === 0 || !props.areas) return null;

    const baseEditorOptions: Omit<SluitpotEditorProps, 'onChange'> = {
        dagmaat: values.dagmaat,
        sillWidth: props.sillWidth,
        areas: props.areas,
        sluitpotOptions: sluitpotOptions as { [key: string]: Array<ConfiguratorSluitpotBeslag> },
        sluitpotHardware: props.sluitpotHardware
    };

    return (
        <div>
            <label className="form-label">
                <small className="text-uppercase text-muted">Sluitpot</small>
            </label>

            <div className="row mb-4">
                <div className="col-4">
                    <select className="form-select" value={enabled ? 'true' : 'false'}
                            onChange={(e) => enable(e.target.value === 'true')}>
                        <option value="false">Nee</option>
                        <option value="true">Ja</option>
                    </select>
                </div>
            </div>

            {enabled && sluitpotCount > 0 && (
                <>
                    <SluitpotTable {...{...props, baseEditorOptions: baseEditorOptions}} />
                    <div className="mb-4">
                        <button type="button" className="btn btn-link btn-sm" onClick={() => setAddModalOpen(true)}>
                            Extra sluitpot toevoegen
                        </button>
                    </div>
                </>
            )}

            <SluitpotModal show={isAddModalOpen} handleClose={onModalClose} title="Sluitpot toevoegen"
                           editorOptions={{...baseEditorOptions, onChange: onSluitpotAdd}}/>
        </div>
    );
};

import { 
  DeliveryConditions,
  OrderAddressRepresentation,
  OrderContactPersonRepresentation,
  PutOrderReferenceRepresentation
} from '../../../../../../modules/api-client/generated'
import moment from 'moment'
import { resetTimeOfDate } from '../../../../../../shared/components/calendar';

export const OrderType = {
  Quote: 'quote',
  Order: 'order'
} as const;

export type OrderType = typeof OrderType[keyof typeof OrderType];

export type OrderAddressForm = Omit<OrderAddressRepresentation, 'houseNumber'>
  & {
    houseNumber: number | undefined | string;
  };

export interface PutOrderFinancialRepresentationForm {
  'invoiceAddress'?: OrderAddressForm;
  'contactPerson'?: OrderContactPersonRepresentation;
}

export interface PutOrderExpeditionRepresentationForm {  
  'deliveryAddress'?: OrderAddressForm;  
  'contactPerson'?: OrderContactPersonRepresentation;
  'reference'?: PutOrderReferenceRepresentation;
  'preferredExpeditionDate': Date;  
  'deliveryCondition': DeliveryConditions;
  'deliveryDateChoice': 'asap' | 'preferredExpeditionDate'
}

export interface IAddOrderForm {
  id: string,
  customerId: string,
  code: string,
  type: OrderType,
  contactPerson: OrderContactPersonRepresentation | undefined | null,
  financial: PutOrderFinancialRepresentationForm | undefined | null,  
  expedition: PutOrderExpeditionRepresentationForm      
  productCount: number
  remark: string | undefined | null
}

export const addBusinessDays = (originalDate: Date, numDaysToAdd: number) => {
  const Sunday = 0;
  const Saturday = 6;
  let daysRemaining = numDaysToAdd;
  const newDate = moment(originalDate);
  while (daysRemaining > 0) {
    newDate.add(1, 'days');
    if (newDate.day() !== Sunday && newDate.day() !== Saturday) {
      daysRemaining--;
    }
  }

  return newDate.toDate();
}

export const initialFormValues: IAddOrderForm = {
  id: '',
  code: '',
  customerId: '',
  type: 'order',  
  contactPerson: undefined,
  financial: undefined,
  expedition: {
    deliveryDateChoice: 'asap',
    deliveryCondition: 'bezorging',
    deliveryAddress: undefined,
    contactPerson: undefined,
    preferredExpeditionDate: resetTimeOfDate(addBusinessDays(new Date(), 10)),
    reference: undefined
  },
  productCount: 0,
  remark: '',
}
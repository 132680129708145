import {useQuery, useQueryClient} from "@tanstack/react-query";
import * as api from "../../../../modules/api-client/generated";
import ApiClient from "../../../../modules/api-client/ApiClient";
import {NotFound, ValidationFailed} from "../../../../modules/api-client/Responses";
import {useEkosietMutation} from "../../../../shared/hooks/useEkosietMutation";
import {
    CreateSillDrainageOptionCommand, UpdateSillDrainageOptionRequest
} from "../../../../modules/api-client/generated";
import {SillDrainageOptionFormValues} from "./DrainageOptionUpsert";


export const useSillDrainageOptionUpsertMutation = (id?: string | undefined) => {

    const queryClient = useQueryClient()

    return useEkosietMutation<api.SillDrainageOption, NotFound | ValidationFailed, SillDrainageOptionFormValues>((values) => {
            const command: CreateSillDrainageOptionCommand | UpdateSillDrainageOptionRequest = {
                code: values.code,
                active: values.active,
                name: values.name,
            };

            if (id) {
                return ApiClient.Pim.SillDrainageOption.pimSillDrainageOptionsIdPut(id, undefined, command).then(response => response.data);
            }
            return ApiClient.Pim.SillDrainageOption.pimSillDrainageOptionsPost(undefined, command).then(response => response.data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['pimSillDrainageOptionsIdGet', id]).then();
                queryClient.invalidateQueries(['pimSillDrainageOptionsGet']).then();
            }
        }
    );
};

export const useSillDrainageOptionQuery = (id: string | undefined) => {
    return useQuery(['pimSillDrainageOptionsIdGet', id],
        () => {
            return ApiClient.Pim.SillDrainageOption.pimSillDrainageOptionsIdGet(id!).then(res => res.data);
        }, {
            useErrorBoundary: true,
            enabled: !!id,
        });
}

export const useSillDrainageOptionDeleteMutation = (id: string) => {
    const queryClient = useQueryClient()
    return useEkosietMutation<void, NotFound | ValidationFailed, string>((id) => {
            return ApiClient.Pim.SillDrainageOption.pimSillDrainageOptionsIdDelete(id, undefined).then(response => response.data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['pimSillDrainageOptionsIdGet', id]).then();
                queryClient.invalidateQueries(['pimSillDrainageOptionsGet']).then();
            }
        }
    );
};




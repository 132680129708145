import React from 'react'
import {Field, useField} from 'formik';
import FieldErrors from './FieldErrors'
import {FieldHookConfig} from 'formik/dist/Field'

export type TextFieldProps = React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
> & {
    row?: boolean;
    label?: string | null;
    hideFieldErrors?: boolean;
    help?: string | null;
    inputGroupSuffixText?: string;
} & FieldHookConfig<string>

const TextField: React.FC<TextFieldProps> = (props) => {
    const [field, meta] = useField(props)
    let inputProps = {...props,};
    delete inputProps.inputGroupSuffixText;
    delete inputProps.row;
    return <>
        {props.row ? (
            <div className='row mb-4 d-flex align-items-center'>
                <div className='col-4'>
                    {props.label && (
                        <label className='form-label' htmlFor={props.id || props.name}>
                            {props.label}
                        </label>
                    )}
                    {props.help && <small className='d-block text-muted'>{props.help}</small>}
                </div>
                <div className={'col'}>
                    <div className="input-group">
                        <Field className='form-control' {...field} {...inputProps} />
                        {props.inputGroupSuffixText &&
                            <span className='input-group-text'>{props.inputGroupSuffixText}</span>}
                    </div>
                    {!props.hideFieldErrors && <FieldErrors field={props.name}/>}
                </div>
            </div>
        ) : (
            <>
                {props.label && (
                    <label className='form-label' htmlFor={props.id || props.name}>
                        {props.label}
                    </label>
                )}
                <Field className='form-control' {...field} {...inputProps} />
                {props.help && <small className='form-text text-muted'>{props.help}</small>}
                {!props.hideFieldErrors && <FieldErrors field={props.name}/>}
            </>
        )}
    </>
}

export default TextField

import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Modal} from "react-bootstrap";
import {modalsRoot} from "../../../../global-helpers";
import {ProductionItemOperation} from "../components/ProductionItemOperation";

export const ItemOperationPage: React.FC = () => {
    const {oid} = useParams<{ oid: string }>();
    const navigate = useNavigate();
    const cancel = () => navigate(-1);

    return (
        <>
            <Modal
                container={modalsRoot}
                tabIndex={-1}
                aria-hidden='true'
                autoFocus={true}
                dialogClassName={'modal-dialog'}
                scrollable={true}
                show={true}
                size='lg'
                onHide={cancel}
                animation={false}
                >
                <Modal.Header closeButton>
                    <Modal.Title>Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ProductionItemOperation id={oid!}/>
                </Modal.Body>
            </Modal>
        </>
    )
}


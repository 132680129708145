import React, {FC} from 'react'
import {Form, Formik, FormikHelpers} from 'formik'
import SpinnerButton from '../../../../../../../shared/components/SpinnerButton';
import {useFastCopyOrder} from '../../../hooks/use-fast-copy-order';
import {useEkosietAuth0} from "../../../../../../../modules/auth0/core/useEkosietAuth0";

type Props = {
    id: string
    handleClose: () => void,
}


const CopyOrderForm: FC<Props> = ({id, handleClose}) => {

    const mutation = useFastCopyOrder(id);
    const {isMedewerker} = useEkosietAuth0();

    const initialValues: { copyOrderDate: boolean } = {
        copyOrderDate: false,
    }


    const onSubmit = (values: any, {setSubmitting}: FormikHelpers<any>) => {

        return new Promise<void>((resolve, reject) => {
            mutation.mutate(values.copyOrderDate, {
                onSuccess: () => {
                    resolve();
                    handleClose()
                },
                onError: () => {
                    reject();
                }
            });

        }).finally(() => setSubmitting(false));
    };


    return (
        <>
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                {({
                      values,
                      handleChange,
                      handleSubmit,
                      getFieldProps,
                      isSubmitting,
                  }) => (
                    <Form>
                        <p className={"alert alert-info"}>
                            Let op; Alle gegevens van de order worden gekopiëerd. Prijzen worden herberekend. De status
                            van de nieuwe order wordt CONCEPT.
                        </p>

                        {isMedewerker && (
                            <div className='row mb-4'>
                                <div className='col-4'>
                                    <div className={'d-flex flex-column'}>
                                    <label className='form-label' htmlFor='type'>Orderdatum</label>
                                </div>
                                </div>
                                <div className='col-8'>
                                    <div className='form-check form-switch mb-3'>
                                        <input className='form-check-input' type='checkbox' role='switch'
                                               name='copyOrderDate'
                                               value='true'
                                               onChange={handleChange}
                                               checked={values.copyOrderDate}
                                               id='active'/>
                                        <label className='form-check-label' htmlFor='active'>Oorspronkelijke orderdatum behouden?</label>
                                    </div>
                                    <em className={'form-help'}>
                                        Behoud de orderdatum als je een order wil splitsen en de intake van de nieuwe order aan de oorspronkelijke datum moet worden toegewezen.
                                    </em>
                                </div>
                            </div>
                        )}
                        {/* begin::Actions */}
                        <div className='text-center pt-5'>
                            <button
                                type='reset'
                                className='btn btn-light me-3'
                                data-kt-customer-modal-action='cancel'
                                data-dismiss="modal"
                                onClick={() => handleClose()}
                                disabled={isSubmitting}
                            >
                                Annuleren
                            </button>

                            <SpinnerButton
                                type="submit"
                                className='btn btn-primary'
                                spinning={isSubmitting}>
                                Opslaan
                            </SpinnerButton>

                        </div>
                        {/* end::Actions */}

                    </Form>
                )}
            </Formik>

        </>
    )
}

export {CopyOrderForm}

import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { useDymoCheckService, useDymoFetchPrinters } from "../utils/react-dymo";
import { useLocalStorage } from "react-use";
import { PrintersModal } from "../components/printer/PrintersModal";

export interface PrinterContextStore {
    statusDymoService: string;
    statusFetchPrinters: string | undefined | null;
    status: string | undefined | null;
    printers: any
    setPrinter: (printerName: string) => void;
    selectedPrinter: string | null | undefined;
    showPrinterModal: () => void;
}

export const PrinterContext = React.createContext({} as PrinterContextStore);

export const useEkosietPrinter = () => {
    const context = useContext(PrinterContext)
    if (context === undefined) {
      //throw new Error('useEkosietPrinter must be used within a PrinterContextProvider')
      console.warn('useEkosietPrinter must be used within a PrinterContextProvider')      
    }
    return context
};

export type PrinterContextProviderProps = {
    children: React.ReactNode
};

export const PrinterContextProvider: FC<PrinterContextProviderProps> = ({ children }) => {

    const statusDymoService = useDymoCheckService();
    const { statusFetchPrinters, printers } = useDymoFetchPrinters(statusDymoService);
    const [savedSelectedPrinter, saveSelectedPrinter] = useLocalStorage("defaultEkosietPrinter", "");
    const [selectedPrinter, setSelectedPrinter] = useState<string | null | undefined>(savedSelectedPrinter);
    const [compositStatus, setCompositStatus] = useState<string | null | undefined>(null);
    const [showPrinterModalflag, setShowPrinterModalFlag] = useState<boolean>(false);

    const showPrinterModal = useCallback(() => {
        setShowPrinterModalFlag(true);
    }, [setShowPrinterModalFlag]);

    const hidePrinterModal = useCallback(() => {
        setShowPrinterModalFlag(false);
    }, [setShowPrinterModalFlag]);


    const setPrinter = useCallback((printerName: string) => {
        if (printerName && printers) {
            let foundPrinter = false;
            for (let i = 0; i < printers.length; i++) {
                if (printerName === printers[i].name) {
                    foundPrinter = true;
                }
            }

            if (foundPrinter) {
                saveSelectedPrinter(() => printerName)
                setSelectedPrinter(printerName);
            } else {

                if (printers.length > 0) {
                    saveSelectedPrinter(() => printers[0].name)
                    setSelectedPrinter(printers[0].name)
                    console.warn(`printer '${printerName}' not found, setting default printer to: ${printers[0].name}`)
                } else {
                    saveSelectedPrinter(() => "")
                    setSelectedPrinter(null);
                }
            }
        }
    }, [setSelectedPrinter, saveSelectedPrinter, printers]);

    useEffect(() => {
        if (savedSelectedPrinter) {
            console.log(`printer '${savedSelectedPrinter}' saved on this client.`)
        }
    }, [savedSelectedPrinter])

    useEffect(() => {
        if (statusFetchPrinters === "success") {
            setPrinter(selectedPrinter || "")
        }
    }, [statusFetchPrinters, setPrinter, selectedPrinter])


    useEffect(() => {

        if (statusFetchPrinters === "loading" && statusDymoService === "loading") {
            setCompositStatus("loading")
            return;
        }

        if (statusFetchPrinters === "success" && statusDymoService === "success") {
            setCompositStatus("success");
            return;
        }

        if (statusFetchPrinters === "error" || statusDymoService === "error") {
            setCompositStatus("error");
            return;
        }
        
    }, [statusFetchPrinters, statusDymoService])

    const store: PrinterContextStore = {
        printers: printers,
        setPrinter: setPrinter,
        statusDymoService: statusDymoService,
        statusFetchPrinters: statusFetchPrinters,
        selectedPrinter: selectedPrinter,
        status: compositStatus,
        showPrinterModal: showPrinterModal
    };

    return (            
        <PrinterContext.Provider value={store}>                        
            <PrintersModal fullscreen="md-down" size="sm" printers={printers} show={showPrinterModalflag} onHide={hidePrinterModal}  />            
            {children}            
        </PrinterContext.Provider>    
    );
}
import { toast } from "react-hot-toast"
import {useEkosietMutation} from "../../../../shared/hooks/useEkosietMutation";
import {NotFound, ValidationFailed} from "../../../../modules/api-client/Responses";
import ApiClient from "../../../../modules/api-client/ApiClient";
import {
    PutProductionItemStatusRepresentation
} from "../../../../modules/api-client/generated";
import {useQueryClient} from "@tanstack/react-query";

export const usePutProductionItemFinished = () => {
    const queryClient = useQueryClient()
    return useEkosietMutation<void, NotFound | ValidationFailed, PutProductionItemStatusRepresentation>((command) => {
            return ApiClient.Pm.ProductionItem.pmProductionItemFinishedPut(undefined,command).then();
    },
        {
            onSuccess: () => {

                toast.success("Productie item gereed");

                queryClient.invalidateQueries(['pmProductionItemIdGet']).then();
                queryClient.invalidateQueries(['productionItemsList']).then();
                queryClient.invalidateQueries(['pmProductionItemGroupCodeGet']).then();
                queryClient.invalidateQueries(['pmProductionItemBarcodeCodeGet']).then();                
                queryClient.invalidateQueries(['emExpeditionListOdataGet']);
                queryClient.invalidateQueries(['emExpeditionOrderExpeditionByWeekGet']);
                queryClient.invalidateQueries(['emExpeditionByWeekGet']);
                queryClient.invalidateQueries(['emExpeditionTransportPlanningByWeekGet']);
                queryClient.invalidateQueries(['emExpeditionTransportPlanningByOrderGet']);

            },
            onError: () => {
                toast.error("Productie item gereed melden mislukt");
            }
        }
    );
};

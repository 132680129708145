/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {FilterParameter, useEkosietFilter} from "../../../../shared/context/FilterContext";
import {CustomerFilter} from "./CustomerFilter";
import {CustomerCompanyFilter} from "./CustomerCompanyFilter";
import {Mode} from "../../mgt/pages/MgtSillStats";
import {CustomerArchivedFilter} from "./CustomerArchivedFilter";

const CustomerSearch: React.FC = () => {
    const { setFilters, containsFilter, resetCount,reset } = useEkosietFilter()

    return (
        <>
            <div className="row">
                <div className="col col-lg-2">
                    <CustomerCompanyFilter fieldName={'CompanyName'}/>
                </div>
                <div className="col col-lg-2">
                    <div className={'d-flex align-items-center h-100'}>
                        <CustomerArchivedFilter fieldName={'IsArchived'}/>
                    </div>
                </div>
                <div className="col col-lg-2 ms-auto d-flex justify-content-end align-items-center">
                    <button
                        className="btn btn-secondary btn-secondary-icon btn-sm d-flex justify-content-between align-items-center"
                        type='reset' onClick={reset}>
                        <i className="la la-close"></i>
                        <span>
                            <span>Reset filters</span>
                        </span>
                    </button>
                </div>
            </div>
        </>
    )
}

export {CustomerSearch}
import {useQueryClient} from "@tanstack/react-query";
import {useEkosietMutation} from "../../../../../shared/hooks/useEkosietMutation";
import {SillDetails} from "../../../../../modules/api-client/generated";
import {NotFound, ValidationFailed} from "../../../../../modules/api-client/Responses";
import ApiClient from "../../../../../modules/api-client/ApiClient";

export const useSillUpsertImageMutation = (id: string) => {

    const queryClient = useQueryClient();

    return useEkosietMutation<SillDetails, NotFound | ValidationFailed, string>(async (imageId: string) => {
            const response = await ApiClient.Pim.Sill.putImage(id, imageId);
            return response.data;
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['ApiClient.Pim.Sill.getSill', id]).then();
            },
        },
    );
};
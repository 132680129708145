import React, {useLayoutEffect, useState} from "react";
import {Formik, FormikHelpers, useFormikContext} from "formik";
import SubmitButton from "../../../../shared/components/SubmitButton";
import {ValidationErrors} from "../../../../modules/api-client/Responses";
import {useSluitpotBeslagImageUpsertMutation, useSluitpotBeslagQuery} from "./hooks";
import Loading from "../../../../shared/components/Loading";
import ImageDropzone from "../components/ImageDropzone";
import {SluitpotBeslag} from "../../../../modules/api-client/generated";

export interface ImageFormValues {
    image?: File;
}

type Props = {
    handleClose: () => void,
    id: string,
}


const SluitpotBeslagImageUpsert: React.FC<Props> = ({handleClose, id}) => {

    const {mutate, serverValidationErrors} = useSluitpotBeslagImageUpsertMutation(id);

    const onSubmit = (values: ImageFormValues, {setSubmitting}: FormikHelpers<ImageFormValues>) => {

        return new Promise<void>((resolve, reject) => {
            mutate(values, {
                onSuccess: () => {
                    resolve();
                    handleClose();

                },
                onError: () => {
                    reject();
                }
            });

        }).finally(() => setSubmitting(false));
    };


    const {data: beslag, isInitialLoading, isError} = useSluitpotBeslagQuery(id);
    if (isInitialLoading) return <Loading/>
    if (isError) return (<>Error...</>)
    if (id && !beslag) return (<>'{id}' niet gevonden.</>)
    if (!beslag) return (<>'{id}' niet gevonden.</>)

    const initialValues: ImageFormValues = {
        image: undefined
    };

    return (
        <>
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                <SluitpotBeslagImageUpsertForm id={id} serverValidationErrors={serverValidationErrors} beslag={beslag}/>
            </Formik>
        </>

    );
}


interface SluitpotBeslagImageUpsertFormProps {
    id: string | undefined;
    beslag: SluitpotBeslag;
    serverValidationErrors: ValidationErrors | null;
}

export const SluitpotBeslagImageUpsertForm: React.FC<SluitpotBeslagImageUpsertFormProps> = (props) => {
    const {
        values, handleSubmit, handleChange
        , isSubmitting, errors, setFieldValue, setErrors
    } = useFormikContext<ImageFormValues>();
    const mergeServerErrors = () => {
        if (props.serverValidationErrors) {
            setErrors({...errors, ...props.serverValidationErrors});
        }
    }
    const toBase64:any = (file: Blob) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    }).then((res)=>setBase64(res));

    const [base64, setBase64] = useState<any>(null);

    useLayoutEffect(mergeServerErrors, [props.serverValidationErrors, errors, setErrors]);
    return (<form onSubmit={handleSubmit}>

        <div className="row mb-4">
            <div className="col-4">
                <label className="form-label">Afbeelding</label>
            </div>
            <div className="col-8">
                <ImageDropzone
                    onSelect={
                    (selected) => {
                        setFieldValue('image', selected)
                        toBase64(selected)
                    }
                }
                    preview={(base64) =>
                        <img src={`data:image/jpg;base64,` + base64} alt={'tx'}
                             className={'img-fluid'}/>
                    }
                    show={'drag'}>
                    {({openFileDialog}) => <>
                        <div
                            className={`form-control d-flex justify-content-center align-items-center ${values.image ? '' : 'cursor-pointer'}`}
                            onClick={() => {
                                if (!props.beslag.image) openFileDialog();
                            }}
                            onDoubleClick={openFileDialog}
                            style={{height: '200px'}}>

                            {values.image ?
                                    <img src={base64} alt={props.beslag.name}
                                         className={'img-fluid'} style={{maxHeight:'100%'}}/>
                            :
                                <>
                                    {props.beslag.image ?
                                        <img src={`data:image/jpg;base64,` + props.beslag.image} alt={props.beslag.name}
                                             className={'img-fluid'} style={{maxHeight:'100%'}}/>
                                        :
                                        <>
                                            <div
                                                className="d-flex flex-column justify-content-center align-items-center">
                                                <button type="button"
                                                        className="btn btn-light-primary me-3"
                                                        onClick={openFileDialog}><i
                                                    className="fa-regular fa-folder-open"></i> Blader
                                                </button>
                                                <div className="mt-2">of sleep een .jpg bestand hier
                                                </div>
                                            </div>
                                        </>
                                    }
                                </>
                            }

                        </div>
                        {props.beslag.image &&
                            <div className="py-3 d-flex justify-content-start">
                                <button type="button" className="btn btn-light-primary me-3"
                                        onClick={openFileDialog}>Wijzig afbeelding
                                </button>
                            </div>
                        }
                    </>
                    }
                </ImageDropzone>
            </div>
        </div>

        <div className="row pt-5">
            <div className="offset-4 col-8 d-flex justify-content-end">
                <SubmitButton type="submit" className="btn btn-primary"
                              isSubmitting={isSubmitting}>Opslaan</SubmitButton>
            </div>
        </div>

    </form>);
}

export default SluitpotBeslagImageUpsert;
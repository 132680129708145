import clsx from 'clsx';
import {FC} from 'react'
import {useFormikContext, getIn} from "formik";
import {useListCustomers} from '../hooks/use-list-customers';
import {CustomerListRepresentation} from '../../../../modules/api-client/generated';
import Select from "react-select"

type Props = {
    fieldName: string;
    value?: string;
    onChange?: (value: any) => void;
    className?: string
}

export const CustomerPicker: FC<Props> = (props: Props) => {

    const formik = useFormikContext();

    const {
        data: customerListData,
        isLoading: isLoadingListCustomers,
        isError: isErrorListCustomers
    } = useListCustomers(undefined, false);

    let customerListOptions: any[] = []
    if (customerListData) {
        let customerOptions = customerListData.map((item: CustomerListRepresentation) => {
            return {value: item.id, label: item.companyName}
        });

        customerListOptions = [...customerListOptions, ...customerOptions]
    }

    let customerListSelectedValue =
        customerListOptions.find((option: any) => option.value === props.value) || undefined;

    return (
        <>
                    <Select
                        className={clsx(
                            props.className ? props.className : 'w-250px',
                            {'is-invalid': getIn(formik.touched, props.fieldName) && getIn(formik.errors, props.fieldName)},
                            {'is-valid': getIn(formik.touched, props.fieldName) && !getIn(formik.errors, props.fieldName)}
                        )}
                        styles={{
                            // Fixes the overlapping problem of the component
                            menu: provided => ({ ...provided, zIndex: 9999 })
                          }}
                        classNamePrefix="select"
                        value={customerListSelectedValue}
                        placeholder="Selecteer een klant"
                        isClearable={true}
                        isSearchable={true}
                        name={props.fieldName}
                        options={customerListOptions}
                        onChange={(newValue) => {
                            if (props.onChange) {
                                props.onChange(newValue?.value)
                            } else {
                              formik.setFieldValue(props.fieldName, newValue?.value)
                              formik.validateForm();
                            }
                        }}
                    />

                {/*{getIn(formik.touched, props.fieldName) && getIn(formik.errors, props.fieldName) && (*/}
                {/*    <div className='fv-plugins-message-container'>*/}
                {/*        <div className='fv-help-block'>*/}
                {/*            <span role='alert'>{getIn(formik.errors, props.fieldName)}</span>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*)}*/}

        </>
    )
}

import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useMutation, useQuery} from '@tanstack/react-query';
import ApiClient from '../../../../modules/api-client/ApiClient';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';

import {
    ProductionBatchTypes,
    ProductionItemRepresentation,
    ProductionItemRepresentationFacetedSearchResultList
} from '../../../../modules/api-client/generated';
import {EkoCard, EkoCardBody, KTSVG} from '../../../../../_metronic/helpers';
import {TableHeader} from '../../../../shared/components/table/TableHeader';
import {EkoTable} from '../../../../shared/components/table/EkoTable';
import {PageTitle} from '../../../../../_metronic/layout/core';
import Loading from '../../../../shared/components/Loading';
import {PmRoutePath} from "../PmRoutes";
import {
    ProductionItemStationStatusGroup
} from "../../../../shared/components/ProductionItemStatus";
import {formatDate, weekNumber} from "../../../../shared/components/date";
import {StartBatchModal, StartBatchModalProps} from "../components/StartBatchModal";
import BooleanBadge from "../../../../shared/components/BooleanBadge";
import {toast} from "react-hot-toast";
import SpinnerButton from "../../../../shared/components/SpinnerButton";
import BarcodeReader from "react-barcode-reader";
import {SmRoutePath} from "../../sm/SmRoutes";
import UrgentBadge from "../../../../shared/components/UrgentBadge";

const ProductionItemList: React.FC = () => {

    var navigate = useNavigate();
    const [startBatchModal, setStartBatchModal] = useState<Pick<StartBatchModalProps, 'show'>>({
        show: false,
    });

    const [searchParams, setSearchParams] = useSearchParams();
    const [filters, setFilters] = useState<string[]>(() => {
        return searchParams.getAll('f');
    });
    const [list, setList] = useState<ProductionItemRepresentationFacetedSearchResultList | null>(null);

    const {isInitialLoading, refetch, isError} = useQuery<ProductionItemRepresentationFacetedSearchResultList>(
        ['productionItemsList', filters],
        () => ApiClient.Pm.ProductionItem.pmProductionItemGet([`Status:NotStarted`, `Status:Started`, `Status:Failed`], filters).then((res) => res.data),
        {
            onSuccess: (data) => {
                setList(data)
            },
        },
    );
    const facets = useMemo(() => Object
            .values(list?.facets ?? {})
            .sort((a, b) => a.sortOrder - b.sortOrder),
        [list]);

    const handleSelection = (selected: boolean, profile: ProductionItemRepresentation) => {
        setSelection((selection) => {
            let newSelection = new Map(selection);
            if (selected) {
                newSelection.set(profile.id, profile);
            } else {
                newSelection.delete(profile.id);
            }

            return newSelection;
        });
    };

    const [selection, setSelection] = useState<Map<string, ProductionItemRepresentation>>(new Map<string, ProductionItemRepresentation>());

    const [selectPage, setSelectPage] = useState<boolean>(false);

    const selectAll = useCallback(() => {
        setSelection(() => {
            let newSelection = new Map();
            list?.hits.forEach((profile) => {
                if (!newSelection.has(profile.id)) {
                    newSelection.set(profile.id, profile);
                }
            });
            return newSelection;
        });
    }, [list, setSelection]);

    const allSelected = useMemo(() => list?.hits.filter((x) => !selection.has(x.id)).length === 0, [selection, list]);
    const selectAllRef = useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;

    const selectionSize: number = allSelected ? !selectPage ? list?.totalHits! : selection.size : selection.size;


    const selectNone = useCallback(() => {
        setSelection((selection) => {
            if (selection.size > 0) {
                return new Map<string, ProductionItemRepresentation>();
            }
            return selection;
        });
    }, [setSelection]);

    const toggleSelect = useCallback(() => (selectAllRef?.current?.checked ? selectAll() : selectNone()), [selectAllRef, selectAll, selectNone]);

    useEffect(() => {
        if (!selectAllRef?.current) return;

        const current = selectAllRef.current;

        current.addEventListener('change', toggleSelect);

        return () => {
            current.removeEventListener('change', toggleSelect);
        };
    }, [selectAllRef, toggleSelect]);

    useEffect(() => {
        if (!selectAllRef?.current) return;

        selectAllRef.current.indeterminate = !allSelected && selection.size > 0;
    }, [selection, allSelected, selectAllRef]);


    const onResetAllFilters = useCallback(async () => {
        setFilters([]);
        setSearchParams({});
        refetch().then();
        return false;
    }, [refetch, setSearchParams]);

    const onResetFilter = useCallback(
        async (tag: string) => {
            setFilters((filters) => {
                let newFilters = filters.filter((x) => !x.startsWith(`${tag}:`));
                const f: {} = newFilters.length === 0 ? {} : {f: newFilters};
                setSearchParams(f);
                return newFilters;
            });

            refetch().then();
        },
        [setFilters, refetch, setSearchParams],
    );

    const onSelectAllFilter = async (tag: string) => {
        setFilters((newFilters) => {

            Object.values(list?.facets ?? [])
                .filter((x) => x.value === tag)
                .forEach((facet) => {
                    facet.labels.forEach((v) => newFilters.push(`${tag}:${v.value}`));
                });

            const f: {} = newFilters.length === 0 ? {} : {f: newFilters};

            setSearchParams(f);

            return newFilters;
        });

        refetch().then();
    }

    const onInverseFilter = useCallback(
        async (tag: string, searchResult: ProductionItemRepresentationFacetedSearchResultList | null) => {
            setFilters((existingFilters) => {

                let newFilters: string[] = []

                Object.values(searchResult?.facets ?? [])
                    .filter((x) => x.value === tag)
                    .forEach((facet) => {
                        facet.labels.forEach((v) => {
                            if (!existingFilters.includes(`${tag}:${v.value}`)) {
                                newFilters.push(`${tag}:${v.value}`)
                            }
                        });
                    });

                const f: {} = newFilters.length === 0 ? {} : {f: newFilters};

                setSearchParams(f);

                return newFilters;
            });

            refetch().then();
        },
        [setFilters, refetch, setSearchParams],
    );

    const onFilterChange = useCallback(
        async (tag: string, bucketShortId: string, checked: boolean) => {
            setFilters((filters) => {
                const name = `${tag}:${bucketShortId}`;

                let list = filters;
                if (checked && filters.indexOf(name) === -1) {
                    list = [...filters, name];
                } else if (!checked && filters.indexOf(name) !== -1) {
                    list = filters.filter((x) => x !== name);
                }

                const f: {} = list.length === 0 ? {} : {f: list};
                setSearchParams(f);

                return list;
            });
            selectNone();
            refetch().then();
        },
        [refetch, setSearchParams],
    );

    const reindexMutation = useMutation(
        async () => {
            await ApiClient.Pm.SystemAdmin.pmSystemReindexProductionitemsPost();
        },
        {
            onSuccess: async () => {
                await refetch();
            },
            onError: () => {
                toast.error('Fout bij het herindexeren van productie items');
            },
        },
    );

    const handleScan = (code: string) => {
        navigate(PmRoutePath.link(PmRoutePath.productionItemLink(code)));
    }

    const handleScanError = (err: string) => {

        toast.success(
            `Scan error '${err}'`,
            {
                duration: 3000
            }
        );
    }

    if (isInitialLoading && !list) {
        return <Loading/>;
    }

    if (isError) {
        return <>ERROR!</>;
    }

    return (
        <>
            <PageTitle
                toolbar={
                    <>
                        <div className='gap-2 d-flex'>
                            <Link to={PmRoutePath.link(PmRoutePath.createNeutenBatch)}
                                  className={'btn btn-primary me-3'}>Maak losse neuten batch</Link>
                            <SpinnerButton
                                onClick={async () => await reindexMutation.mutateAsync(undefined, undefined)}
                                className='btn btn-primary'
                                spinning={reindexMutation.isLoading}
                                disabled={reindexMutation.isLoading}
                            >
                                <KTSVG path={'/media/icons/duotune/general/gen011.svg'}
                                       className='svg-icon-2 me-2'/>
                                Herindexeren
                            </SpinnerButton>
                        </div>
                    </>
                }
                breadcrumbs={[]}
            >
                Aanmaken batches
            </PageTitle>

            <BarcodeReader
                onError={handleScanError}
                onScan={handleScan}
            />
            {/*<ProfileModal show={showUpsertModal} component={modalComponent} title={modalTitle} size={'lg'}*/}
            {/*			  handleClose={() => setShowUpsertModal(false)} />*/}

            <div className={'row'}>
                <div className={'col-12 col-lg-3'}>
                    <EkoCard>
                        <EkoCardBody>
                            <div className={'d-flex justify-content-between align-items-start mb-3'}>
                                <h3>Filters</h3>
                                {filters.length > 0 && (
                                    <button onClick={onResetAllFilters}
                                            className='btn btn-link btn-sm p-0 link-primary'>
                                        Reset alle filters
                                    </button>
                                )}
                            </div>
                            <div className='row'>
                                {facets.length > 0 && facets.map((t, index) => (
                                    <div key={`tag-${index}`} className='col-12 mb-10 d-flex flex-column '>
                                        <div className={'d-flex justify-content-between align-items-start'}>
                                            <label className='form-label text-nowrap'>{t.name}</label>
                                            <div>
                                                <button onClick={() => onInverseFilter(t.value, list)}
                                                        className='btn btn-link btn-sm p-0 link-primary'>
                                                    <KTSVG path={'/media/icons/duotune/arrows/arr029.svg'}
                                                           className=''/>
                                                </button>
                                                <button onClick={() => onSelectAllFilter(t.value)}
                                                        className='btn btn-link btn-sm p-0 link-primary'>
                                                    <KTSVG path={'/media/icons/duotune/general/gen043.svg'}
                                                           className=''/>
                                                </button>
                                                <button onClick={() => onResetFilter(t.value)}
                                                        className='btn btn-link btn-sm p-0 link-primary'>
                                                    <KTSVG path={'/media/icons/duotune/general/gen040.svg'}
                                                           className=''/>
                                                </button>
                                            </div>
                                        </div>
                                        <div className={'bg-light p-3 border-1 border-dark'}
                                             style={{maxHeight: '200px', overflowY: 'scroll'}}>
                                            {t.labels.map((b) => (
                                                <div key={b.value}>
                                                    <label
                                                        className='w-100 h-100 cursor-pointer text-nowrap d-flex justify-content-between align-items-center'>
                                                        <span className='d-flex align-items-center'>
                                                            <input
                                                                className='form-check-input m-0 my-1'
                                                                type='checkbox'
                                                                checked={filters.indexOf(`${t.value}:${b.value}`) !== -1}
                                                                onChange={(e) => onFilterChange(t.value, b.value, e.target.checked)}
                                                                value={b.value}
                                                                disabled={b.count === 0}
                                                            />
                                                            <span
                                                                className={`ms-2 ${b.count === 0 ? 'text-muted' : ''}`}>{b.name}</span>
                                                        </span>
                                                        <small className='ms-2 text-muted'>({b.count})</small>
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </EkoCardBody>
                    </EkoCard>
                </div>
                <div className={'col-12 col-lg-9'}>
                    <EkoCard>
                        <EkoCardBody>
                            <div className={'d-flex align-items-center text-nowrap'}>
                                {list &&
                                    <div className='text-muted me-3'>{list.totalHits} dorpels</div>}
                                <div className={'d-flex align-items-center justify-content-between w-100'}>
                                    {selection && (
                                        <div className='d-flex align-items-center  '>
                                            {selection.size > 0 && (
                                                <span onClick={() => setSelection(new Map())}>
                                                    <KTSVG path={'/media/icons/duotune/general/gen040.svg'}
                                                           className='svg-icon-2'/>
                                                </span>
                                            )}
                                            <span className={'ms-1 me-2 text-muted'}> {selectionSize} dorpels
                                                geselecteerd</span>
                                            <div>
                                                <StartBatchModal
                                                    {...startBatchModal}
                                                    onClose={() =>
                                                        setStartBatchModal((c) => ({
                                                            ...c,
                                                            show: false,
                                                        }))
                                                    }
                                                    onSuccess={() => refetch()}
                                                    isLoading={isInitialLoading}
                                                    urgent={filters.some(v=>v.includes('Urgent:ja')) && !filters.some(v=>v.includes('Urgent:nee'))}
                                                    type={ProductionBatchTypes.Neuten}
                                                    selection={Array.from(selection, ([k, v]) => v.id)}
                                                    selectionSize={selectionSize}
                                                    selectPage={selectPage}
                                                    selectAll={allSelected}
                                                    selectionFilters={filters}
                                                />
                                                <button
                                                    type='button'
                                                    className='btn btn-light-primary btn-sm mx-1'
                                                    disabled={selectionSize === 0}
                                                    onClick={() =>
                                                        setStartBatchModal((c) => ({
                                                            ...c,
                                                            show: true,
                                                        }))
                                                    }
                                                >
                                                    Start batch
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className={'d-flex justify-content-start align-items-center'}>
                                <label
                                    className='h-100 d-flex justify-content-start align-items-center cursor-pointer'>
                                    <input
                                        ref={selectAllRef}
                                        className='form-check-input m-0'
                                        type='checkbox'
                                        id={`profile-select-all`}
                                        checked={allSelected}
                                        onChange={(e) => (e.target.checked ? selectAll() : selectNone())}
                                    />
                                </label>
                                {allSelected &&
                                    <span className={'ms-4'}>
                                        <a href={'#'} className={` ${!selectPage ? "fw-bold" : " text-dark"}`}
                                           onClick={() => setSelectPage(false)}>selecteer alle</a>
                                        &nbsp;|&nbsp;
                                        <a href={'#'} className={` ${selectPage ? "fw-bold" : " text-dark"}`}
                                           onClick={() => setSelectPage(true)}>selecteer op pagina</a>
                                    </span>
                                }
                            </div>
                            <EkoTable>
                                <TableHeader>
                                    <th className='p-0 fit-content' style={{height: '1px'}}>
                                    </th>
                                    <th>Dorpel</th>
                                    <th>Week</th>
                                    <th>Datum</th>
                                    <th>Order</th>
                                    <th>Spoed?</th>
                                    <th>Neut</th>
                                    <th>Dorpel</th>
                                    <th>Lat</th>
                                    <th>Status</th>
                                </TableHeader>
                                <tbody>
                                {(!list || list.hits.length === 0) && (
                                    <tr>
                                        <td colSpan={5} className='text-center'>
                                            Geen gegevens
                                        </td>
                                    </tr>
                                )}
                                {list &&
                                    list.hits.map((pi) => (
                                        <tr key={pi.id}>
                                            <td className='p-0 fit-content' style={{height: '1px'}}>
                                                <label
                                                    className='w-100 h-100 d-flex justify-content-center align-items-center cursor-pointer'>
                                                    <input
                                                        className='form-check-input m-0'
                                                        type='checkbox'
                                                        id={`profile-${pi.id}`}
                                                        value={pi.id}
                                                        checked={selection.has(pi.id)}
                                                        onChange={(e) => handleSelection(e.target.checked, pi)}
                                                    />
                                                </label>
                                            </td>
                                            <td>
                                                <div>
                                                    <Link to={PmRoutePath.link(PmRoutePath.productionItemLink(pi.id!))}
                                                          className='text-hover-primary fs-6'>
                                                        {pi.title}
                                                    </Link>
                                                </div>
                                                <div>
                                                    <Link
                                                        to={PmRoutePath.link(PmRoutePath.productionItemGroupLink(pi.code!))}
                                                        className='text-hover-primary fs-6'>
                                                        {pi.code}
                                                        {pi.sequence}
                                                    </Link>
                                                </div>
                                            </td>
                                            <td>
                                                {weekNumber(pi.plannedProductionDate)}
                                            </td>
                                            <td>
                                                {formatDate(pi.plannedProductionDate)}
                                            </td>
                                            <td>
                                                <Link to={SmRoutePath.link(SmRoutePath.orderEditLink(pi.order?.id!))}
                                                      className='text-hover-primary'>
                                                    {pi.order?.code}<br/>
                                                    <span>{pi.customer?.name}</span>
                                                </Link>
                                            </td>
                                            <td>
                                                <UrgentBadge value={pi.urgent}/>
                                            </td>
                                            <td>
                                                {pi.needsNeutenBatch ?
                                                    <>
                                                        <BooleanBadge value={pi.hasNeutenBatch}/>
                                                    </>
                                                    :
                                                    <>
                                                        nvt
                                                    </>
                                                }
                                            </td>
                                            <td>
                                                {pi.needsDorpelBatch ?
                                                    <>
                                                        <BooleanBadge value={pi.hasDorpelBatch}/>
                                                    </>
                                                    :
                                                    <>
                                                        nvt
                                                    </>
                                                }
                                            </td>
                                            <td>
                                                {pi.needsLattenBatch ?
                                                    <>
                                                        <BooleanBadge value={pi.hasLattenBatch}/>
                                                    </>
                                                    :
                                                    <>
                                                        nvt
                                                    </>
                                                }
                                            </td>
                                            <td>
                                                <div className={'d-flex flex-column text-nowrap'}>
                                                    <ProductionItemStationStatusGroup pi={pi}/>
                                                </div>

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </EkoTable>
                        </EkoCardBody>
                    </EkoCard>
                </div>
            </div>
        </>
    );
};

export default ProductionItemList;

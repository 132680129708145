import { FC, useEffect, useState } from 'react'
import { DateInput } from '../../../../../shared/components/date/DateInput';
import moment from 'moment';
import { FilterParameter, useEkosietFilter } from '../../../../../shared/context/FilterContext';
import classNames from "classnames";

type Props = {
    fieldName: string;
}

export const ExpeditionDateFilter: FC<Props> = (props: Props) => {

    const { setFilters, containsFilter, resetCount } = useEkosietFilter()
    const [zsm, setZsm] = useState<boolean>(false)
    const [fromDate, setFromDate] = useState<Date | undefined>()
    const [toDate, setToDate] = useState<Date | undefined>()
    const minDateToDate = fromDate ? moment(fromDate).add(1, 'days').toDate() : undefined;

    const updateZsmFilter = (zsm: boolean) => {

        if (zsm) {
            const filterZsmExpression = { PreferredExpeditionDate: null }

            const filterZsm: FilterParameter = {
                filterExpression: filterZsmExpression,
                filterName: props.fieldName,
                values: [zsm]
            }

            setFilters(props.fieldName, [filterZsm]);
        } else {
            if (containsFilter(props.fieldName)) {
                setFilters(props.fieldName, []);
            }
        }
    }

    const updateDateFilter = (fromDateValue: Date | undefined, toDateValue: Date | undefined) => {

        if (fromDateValue && toDateValue) {

            const filterExpression = { PreferredExpeditionDate: { ge: fromDateValue, le: toDateValue } }

            const filter: FilterParameter = {
                filterExpression: filterExpression,
                filterName: props.fieldName,
                values: [fromDateValue, toDateValue]
            }

            setFilters(props.fieldName, [filter]);
        } else {
            if (containsFilter(props.fieldName)) {
                setFilters(props.fieldName, []);
            }
        }
    }

    const toggleZsm = () => {

        setZsm(!zsm);
        if (!zsm) {
            setFromDate(undefined);
            setToDate(undefined);
        }
        updateZsmFilter(!zsm);
    }

    const setFromDateValue = (val: Date) => {
        setZsm(false);
        setFromDate(val);
        updateDateFilter(val, toDate);
    }

    const setToDateValue = (val: Date) => {
        setZsm(false);
        setToDate(val);
        updateDateFilter(fromDate, val);
    }

    const reset = () => {
        setFromDate(undefined);
        setToDate(undefined);
        setZsm(false);
        if (containsFilter(props.fieldName)) {
            setFilters(props.fieldName, []);
        }
    }

    useEffect(() => {
        if (resetCount) {
            setFromDate(undefined);
            setToDate(undefined);
            setZsm(false);
        }
    }, [resetCount])

    return (
        <>
                <div className="input-group ">
                    <DateInput value={fromDate} onChange={setFromDateValue} placeholder='van datum' dateFormat='dd-MM-yyyy' />

                    <DateInput value={toDate} onChange={setToDateValue} minDate={minDateToDate} placeholder='tot datum' dateFormat='dd-MM-yyyy' />

                    <button
                        className={classNames(
                            "btn ms-1",
                            zsm ? 'btn-outline-success' : 'btn-outline-secondary'
                        )
                        }
                        onClick={toggleZsm}>
                        ZSM
                    </button>

                    <button type='reset' className="btn m-0" onClick={reset}>
                        <span>
                            <i className="la la-close"></i>
                        </span>
                    </button>
                </div>
        </>
    )
}



import React, {useCallback, useRef} from "react";
import {useQuery} from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import {useOutletContext} from "react-router-dom";
import {EkoCard, EkoCardBody} from "../../../../../_metronic/helpers";
import {TableHeader} from "../../../../shared/components/table/TableHeader";
import {EkoTable} from "../../../../shared/components/table/EkoTable";
import {EkoCardHeader} from "../../../../shared/components/card/EkoCardHeader";
import {EkoCardToolbar} from "../../../../shared/components/card/EkoCardToolbar";
import {MasterdataModalContext} from "../../../pages/MasterData";
import TurboholTypeUpsert from "./TurboholTypeUpsert";
import {ConfirmDialog} from "../../../../shared/components/ConfirmDialog";
import {useTurboholTypeDeleteMutation} from "./hooks";
import {TurboholType} from "../../../../modules/api-client/generated";
import Loading from "../../../../shared/components/Loading";


const TurboholTypeList: React.FC = () => {

    const {setShowModal, setModalTitle, setModalComponent} = useOutletContext<MasterdataModalContext>();

    const add = () => {
        setModalComponent(<TurboholTypeUpsert handleClose={() => setShowModal(false)}/>)
        setModalTitle('Toevoegen')
        setShowModal(true)
    }
    const edit = (data: TurboholType) => {
        setModalComponent(<TurboholTypeUpsert id={data.id!} handleClose={() => setShowModal(false)}/>)
        setModalTitle(data.name + ' aanpassen')
        setShowModal(true)
    }

    const {
        isInitialLoading,
        data: list,
        isError
    } = useQuery<TurboholType[]>(['pimTurboholTypesGet'], () => ApiClient.Pim.TurboholType.pimTurboholTypesGet().then((res) => res.data));

    if (isInitialLoading) {
        return <Loading />;
    }

    if (isError) {
        return <>ERROR!</>;
    }

    if (!list) return <></>;

    return <>

        <EkoCard>
            <EkoCardHeader title={'Turbohol type'}>
                <EkoCardToolbar>
                    <button onClick={() => add()} className="btn btn-primary btn-sm">Toevoegen</button>
                </EkoCardToolbar>
            </EkoCardHeader>
            <EkoCardBody>
                <EkoTable>
                    <TableHeader>
                        <th>Code</th>
                        <th>Naam</th>
                        <th></th>
                    </TableHeader>
                    <tbody>
                    {(!list || list.length === 0) &&
                        <tr>
                            <td colSpan={5} className="text-center">Geen gegevens</td>
                        </tr>}

                    {list && list.map((data, index) => <tr key={data.id}>

                        <td className="fit-content">
                            <a href='#' onClick={() => edit(data)}>{data.code}</a>
                        </td>

                        <td>
                            {data?.name}
                        </td>
                        <td className="fit-content">
                        </td>
                        <td className="fit-content">
                            <DeleteButton id={data.id!}/>
                        </td>
                    </tr>)}
                    </tbody>
                </EkoTable>
            </EkoCardBody>
        </EkoCard>
    </>
}
const DeleteButton: React.FC<{id:string}>= ({id}) => {
    const openDeleteDialog = useRef<(show: boolean) => void>();

    const deleteMutation = useTurboholTypeDeleteMutation(id);
    const deleteCallback = useCallback(async () => {
        await deleteMutation.mutateAsync(id!);
    }, [deleteMutation, id]);
    return (
        <>
            <button type="button" className="btn btn-light btn-sm" disabled={false}
                    onClick={() => openDeleteDialog.current?.call(this, true)}><i className={'fas fa-trash'}></i>
            </button>
            <ConfirmDialog show={openDeleteDialog}
                           onConfirm={deleteCallback}
                           dialogStyle="danger"
                           title="Verwijderen"
                           message="Wil je doorgaan?"
                           confirmText="Definitief verwijderen"

            />
        </>

    )
}


export default TurboholTypeList;